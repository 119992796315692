<script setup lang="ts">
import { computed } from 'vue';
import AtAvatar from '@/components/atoms/AtAvatar.vue';
import type { Assignee } from '../types';

type Props = {
  assignee: Assignee;
};

const props = defineProps<Props>();

const userName = computed(
  () =>
    `${props.assignee.firstName} ${props.assignee.lastName}`.trim() ||
    props.assignee.email ||
    '',
);
</script>

<template>
  <AtAvatar
    class="!h-7 !w-7"
    :url="assignee.picture?.downloadUrl"
    :userName="userName"
    :title="userName"
  />
</template>
