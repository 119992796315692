import { provideApolloClient, useLazyQuery } from '@vue/apollo-composable';
import {
  type GenerateGuidanceAiSuggestionQuery,
  type GenerateGuidanceAiSuggestionQueryVariables,
  type LibraryGuidanceDocumentEnum,
} from '@/__generated__/types';
import waitForApolloQuery from '@/utils/helpers/waitForApolloQuery';
import { apolloClient } from '@/api/apollo/client';
import GENERATE_GUIDANCE_AI_SUGGESTION_QUERY from './generateGuidanceAiSuggestion.query';

export const useGenerateGuidanceAiSuggestion = () => {
  const query = provideApolloClient(apolloClient)(() =>
    useLazyQuery<
      GenerateGuidanceAiSuggestionQuery,
      GenerateGuidanceAiSuggestionQueryVariables
    >(GENERATE_GUIDANCE_AI_SUGGESTION_QUERY, undefined, {
      fetchPolicy: 'network-only',
    }),
  );

  const generateAiSuggestion = async (
    guidanceKeys: LibraryGuidanceDocumentEnum[],
    aiQuery: string,
  ) => {
    const requestData: GenerateGuidanceAiSuggestionQueryVariables = {
      aiGuidanceSuggestionInput: {
        guidanceKeys,
        aiQuery,
      },
    };
    // eslint-disable-next-line no-unused-expressions
    query.load(undefined, requestData) || query.refetch(requestData);
    const result = await waitForApolloQuery(query);
    if (result && !(result instanceof Error)) {
      return result?.generateGuidanceAiSuggestion;
    }
  };

  return {
    generateAiSuggestionLoading: query.loading,
    generateAiSuggestionError: query.error,
    generateAiSuggestion,
  };
};
