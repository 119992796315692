<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { Component } from 'vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

interface TProps {
  title: string;
  description: string;
  buttonText: string;
  buttonIcon?: Component;
  loading?: boolean;
}

const props = withDefaults(defineProps<TProps>(), {
  loading: false,
  buttonIcon: undefined,
});

const emit = defineEmits<{
  (e: 'buttonClick'): void;
}>();

const { t } = useI18n();
</script>

<template>
  <div class="flex w-full flex-col justify-between rounded bg-gray-100 p-6">
    <div>
      <h3 class="text-xl font-normal leading-7 text-gray-900">
        {{ t(props.title) }}
      </h3>
      <p class="mb-12 mt-1 text-base font-normal leading-6 text-gray-500">
        {{ t(props.description) }}
      </p>
    </div>
    <div class="flex justify-end">
      <AtButton
        :loading="loading"
        :icon="props.buttonIcon"
        @click="emit('buttonClick')"
      >
        {{ t(props.buttonText) }}
      </AtButton>
    </div>
  </div>
</template>
