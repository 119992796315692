import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UnsuspendDataPointTypeByLocationMutation,
  UnsuspendDataPointTypeByLocationMutationVariables,
} from '@/__generated__/types';

export const UNSUSPENDED_DATAPOINT_TYPE_BY_LOCATION = gql`
  mutation UnsuspendDataPointTypeByLocation(
    $locationId: String!
    $dataPointTypeId: String!
  ) {
    unsuspendDataPointTypeByLocation(
      locationId: $locationId
      dataPointTypeId: $dataPointTypeId
    ) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  UnsuspendDataPointTypeByLocationMutation,
  UnsuspendDataPointTypeByLocationMutationVariables
>;

const useUnsuspendDataPointTypeByLocationMutation = (options?: TOptions) =>
  useMutation<
    UnsuspendDataPointTypeByLocationMutation,
    UnsuspendDataPointTypeByLocationMutationVariables
  >(UNSUSPENDED_DATAPOINT_TYPE_BY_LOCATION, options);

export default useUnsuspendDataPointTypeByLocationMutation;
