<script setup lang="ts">
import type { Component } from 'vue';
import { ListboxButton } from '@headlessui/vue';
import { SelectorIcon } from '@heroicons/vue/solid';

type Props = {
  disabled: boolean;
  hasErrors: boolean;
  placeholder: string;
  value: string;
  icon?: Component;
  truncate?: boolean;
};

const props = defineProps<Props>();
</script>

<template>
  <ListboxButton
    class="relative w-full cursor-pointer rounded-md border py-2 pl-3 pr-10 text-left focus:outline-primary text-sm"
    :class="{
      'pointer-events-none opacity-50': disabled,
      'border-error bg-rose-50 text-error outline-error': hasErrors,
      'border-gray-400 bg-white': !hasErrors,
    }"
    data-cy="ListboxButton"
  >
    <span class="line-clamp-1">
      <span
        v-if="value"
        class="inline-flex items-center"
        :class="{ truncate: props.truncate }"
      >
        {{ value }}
        <component :is="icon" v-if="icon" class="h-4 w-4 ml-1.5" />
      </span>
      <span v-else class="opacity-50">{{ placeholder }}</span>
    </span>
    <span
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
    >
      <SelectorIcon
        class="h-5 w-5 text-gray-400"
        :class="{ 'text-error': hasErrors }"
        aria-hidden="true"
      />
    </span>
  </ListboxButton>
</template>
