<template>
  <input
    :id="($attrs.id as string) ?? ''"
    ref="inputRef"
    :disabled="props.disabled"
    :class="$attrs.class"
    :data-cy="$attrs['data-cy']"
    :placeholder="props.placeholder"
    @focusin="emit('changeMaskedInputFocus', true)"
    @focusout="emit('changeMaskedInputFocus', false)"
    @keyup.enter="emit('enterKeyPressed')"
    @blur="emit('blur', $event)"
  />
</template>

<script lang="ts" setup>
import { computed, nextTick, watch } from 'vue';
import {
  type CurrencyInputOptions,
  useCurrencyInput,
} from 'vue-currency-input';
import { useI18n } from 'vue-i18n';
import { currencyDefaultOptions } from './types';

const emit = defineEmits([
  'update:modelValue',
  'changeMaskedInputFocus',
  'enterKeyPressed',
  'change',
  'blur',
]);
const props = withDefaults(
  defineProps<{
    modelValue?: string | number | null;
    options?: CurrencyInputOptions;
    percent?: boolean;
    placeholder?: string;
    disabled?: boolean;
  }>(),
  {
    modelValue: '',
    options: () => currencyDefaultOptions,
    placeholder: '',
    disabled: false,
  },
);

const { locale } = useI18n();
const { inputRef, setOptions, setValue } = useCurrencyInput(
  props.options,
  true,
);
const maskLocale = computed(() => {
  switch (locale.value) {
    case 'en':
      return 'en-US';
    case 'de':
    default:
      return 'de-DE';
  }
});

watch(
  () => props.modelValue,
  () => {
    if (
      (typeof props.modelValue === 'number' || props.modelValue === null) &&
      inputRef.value.value !== props.modelValue
    ) {
      setValue(props.modelValue);
    }
  },
);

watch(
  [() => props.options, maskLocale],
  () => {
    nextTick(() =>
      setOptions({
        ...props.options,
        locale: maskLocale.value,
        ...(props.percent ? { valueRange: { min: 0, max: 100 } } : {}),
      }),
    );
  },
  { immediate: true },
);

defineOptions({ inheritAttrs: false });

watch(
  () => props.disabled,
  (oldValue, newValue) => {
    if (oldValue === false && newValue === true) {
      nextTick(() => inputRef.value.focus());
    }
  },
);
</script>
