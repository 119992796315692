import { gql } from '@apollo/client/core';

export default gql`
  query PgKPI($goal: String!, $target: String!, $kpi: String!) {
    getGoal(_id: $goal) {
      _id
      name
      number
      description
    }

    getTarget(_id: $target) {
      _id
      name
      number
      isRecommended

      goal {
        _id
        number
        name
        reportingFramework
      }

      suggestedDataPointTypes {
        _id
        friendlyName
        question
        name
        entity {
          _id
        }
        activeReportingFramework {
          _id
          category
          subcategory
        }
        valueDataType
        valueUnit
        valueUnitDivisor
      }
    }

    getEntityKPI(_id: $kpi) {
      _id
      comparisonOperator
      comparisonValue
      comparisonValueType
      startingDate
      startingValue
      dueDate
      description
      status

      comments {
        _id
        text
        createdAt

        user {
          _id
          firstName
          lastName

          picture {
            _id
            downloadUrl
          }
        }
      }

      dataPointType {
        _id
        friendlyName
        name
        question
        activeReportingFramework {
          _id
          category
          subcategory
        }
        valueDataType
        valueUnit
        valueUnitDivisor

        reportingFrameworks {
          _id
          framework
          groups
        }

        suggestedTargets {
          _id
          name
          number

          goal {
            _id
            name
            number
            reportingFramework
          }
        }
      }

      user {
        _id
        firstName
        lastName
      }
    }
  }
`;
