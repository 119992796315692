<script setup lang="ts">
import { ref, type HTMLAttributes } from 'vue';
import { v4 } from 'uuid';
import { useI18n } from 'vue-i18n';
import type { ErrorObject } from '@vuelidate/core';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import unwrapVuelidateErrorMessages from '@/utils/helpers/unwrapVuelidateErrorMessages';
import AtTooltipIcon, {
  type TTooltipOptions,
} from '@/components/atoms/AtTooltipIcon.vue';
import { getUnitFormats } from '@/utils/helpers/getUnitFormats';
import { currencyDefaultOptions } from '@/components/atoms/AtInput/types';
import AtMaskedInput from './AtMaskedInput.vue';

interface TProps {
  label?: string;
  wrapperClass?: HTMLAttributes['class'];
  labelClass?: string;
  unit?: string | null;
  unitDivisor?: string | null;
  modelValue?: string | number | null;
  errors?: string[] | ErrorObject[];
  tooltip?: TTooltipOptions;
  type?:
    | 'text'
    | 'number'
    | 'password'
    | 'tel'
    | 'email'
    | 'url'
    | 'percent'
    | 'emissionFactor';
  onEnter?: () => void;
  disabled?: boolean;
}

const props = withDefaults(defineProps<TProps>(), {
  label: '',
  wrapperClass: '',
  labelClass: '',
  unit: null,
  unitDivisor: null,
  modelValue: '',
  errors: () => [],
  tooltip: undefined,
  type: 'text',
  onEnter: undefined,
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();
const id = v4();

const inputEl = ref<HTMLInputElement>();
defineExpose({ inputEl });

const isFocus = ref(false);

defineOptions({ inheritAttrs: false });
</script>

<template>
  <div :class="wrapperClass">
    <slot name="label" v-bind="{ id }">
      <label
        v-if="props.label"
        :for="id"
        :class="['mb-1 block text-sm font-medium text-gray-700', labelClass]"
      >
        {{ t(props.label) }}

        <AtTooltipIcon
          v-if="props.tooltip"
          class="ml-1 cursor-pointer"
          :tooltip="props.tooltip"
          :triggers="['click', 'touch']"
          autoHide
        />
      </label>
    </slot>
    <div
      class="relative flex"
      :title="t(getUnitFormats(props.unit ?? '').longFormat)"
    >
      <component
        :is="
          ['number', 'percent', 'emissionFactor'].includes(props.type)
            ? AtMaskedInput
            : 'input'
        "
        :id="id"
        ref="inputEl"
        :type="props.type === 'password' ? 'password' : 'input'"
        class="block w-full border border-gray-400 px-3 py-2 shadow-sm disabled:pointer-events-none disabled:opacity-60 sm:text-sm"
        :class="{
          'border-rose-600 bg-rose-50 outline outline-[0.5px] !outline-rose-600':
            props.errors.length,
          'rounded-l-md border-r-0': props.unit,
          'rounded-md': !props.unit,
          'pr-9': $slots.icon,
          'outline outline-[0.5px] outline-primary border-primary': isFocus,
        }"
        :options="{
          ...(props.type === 'emissionFactor'
            ? {
                ...currencyDefaultOptions,
                precision: {
                  min: 0,
                  max: 10,
                },
              }
            : currencyDefaultOptions),
        }"
        :disabled="props.disabled"
        :value="props.modelValue"
        :modelValue="props.modelValue"
        v-bind="$attrs"
        :percent="props.type === 'percent'"
        @changeMaskedInputFocus="isFocus = $event"
        @focusin="isFocus = true"
        @focusout="isFocus = false"
        @update:modelValue="emit('update:modelValue', $event)"
        @input="
          emit('update:modelValue', ($event.target as HTMLInputElement).value)
        "
        @enterKeyPressed="props.onEnter"
      />

      <div class="absolute right-0 top-0 flex h-full p-[0.6rem] text-gray-400">
        <slot name="icon" />
      </div>

      <span
        v-if="props.unit"
        class="inline-flex items-center rounded-r-md border border-l-0 border-gray-400 bg-gray-50 px-3 text-sm text-gray-500"
        :class="{
          'outline outline-[0.5px] outline-primary border-primary': isFocus,
          'outline outline-[0.5px] outline-rose-600 border-rose-600':
            props.errors.length,
          'opacity-60': props.disabled,
        }"
      >
        <slot name="unit">
          <span>
            {{ t(getUnitFormats(props.unit).shortFormat || props.unit) }}
          </span>
          <span v-if="props.unitDivisor">
            /
            {{
              t(
                getUnitFormats(props.unitDivisor).shortFormat ||
                  props.unitDivisor,
              )
            }}
          </span>
        </slot>
      </span>
    </div>
    <p
      v-for="error in unwrapVuelidateErrorMessages(props.errors)"
      :key="error"
      class="mt-1 text-xs text-error"
    >
      <span class="flex">
        <InformationCircleIcon class="mr-1 w-3" />
        {{ t(error) }}
      </span>
    </p>
  </div>
</template>
