<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import DotsHorizontalIcon from '@heroicons/vue/outline/DotsHorizontalIcon';
import InformationCircleIcon from '@heroicons/vue/outline/InformationCircleIcon';
import QuestionMarkCircleIcon from '@heroicons/vue/outline/QuestionMarkCircleIcon';
import ReplayIcon from '@heroicons/vue/outline/ReplyIcon';
import { TaxonomyBusinessActivityDelegationTypeEnum } from '@/__generated__/types';
import useOpenHelpDocumentation from '@/utils/composables/useOpenHelpDocumentation';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import MlRequestHelp from '@/components/molecules/MlRequestHelp.vue';
import type { BusinessActivity } from '../types';
import OgForwardingDialog from '../OgForwardingDialog/OgForwardingDialog.vue';

defineProps<{
  assessmentId: string;
  businessActivity: BusinessActivity;
}>();

const { t } = useI18n();
const openHelpDocumentation = useOpenHelpDocumentation();

const showForwardAssessment = ref(false);
const requestHelp = ref(false);

function handleCloseRequestHelp() {
  requestHelp.value = false;
}

function handleForwarding() {
  showForwardAssessment.value = true;
}

function handleCloseForwardingDialog() {
  showForwardAssessment.value = false;
}

function handleShowRequestHelp() {
  requestHelp.value = true;
}
</script>

<template>
  <MlMenu>
    <button type="button" class="flex items-center">
      <DotsHorizontalIcon class="h-5 w-5 text-gray-400" />
    </button>
    <template #menuItems>
      <AtMenuItem class="justify-between !w-44" @click="handleForwarding">
        {{ t('Forward') }}
        <ReplayIcon class="h-5 w-5 text-gray-400" />
      </AtMenuItem>
      <AtMenuItem
        class="justify-between !w-44"
        @click.stop="openHelpDocumentation"
      >
        {{ t('Help center') }}
        <InformationCircleIcon class="h-5 w-5 text-gray-400" />
      </AtMenuItem>
      <AtMenuItem
        class="justify-between !w-44"
        @click.stop="handleShowRequestHelp"
      >
        {{ t('Request help') }}
        <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400" />
      </AtMenuItem>
    </template>
  </MlMenu>

  <OgForwardingDialog
    v-if="showForwardAssessment"
    :type="TaxonomyBusinessActivityDelegationTypeEnum.MinimumStandards"
    :assessmentId="assessmentId"
    :businessActivityId="businessActivity._id"
    :delegation="businessActivity.minimumStandardsDelegation ?? null"
    @close="handleCloseForwardingDialog"
  />

  <MlRequestHelp v-if="requestHelp" @close="handleCloseRequestHelp" />
</template>
