import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '@/__generated__/types';

export const UPDATE_CURRENT_USER = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(user: $updateUserInput) {
      _id
      firstName
      lastName
      jobTitle
      jobDepartment
      email
      role
      language
      assignments {
        _id
        doubleMateriality
      }
      invitedBy {
        _id
      }
      entities {
        _id
        name
      }
      onboardingStatus
      getStartedStepsCompleted
      picture {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

const useUpdateUserMutation = (options?: TOptions) =>
  useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UPDATE_CURRENT_USER,
    options,
  );

export default useUpdateUserMutation;
