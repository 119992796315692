<script setup lang="ts">
import { ref, type HTMLAttributes } from 'vue';
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtAvatar from '@/components/atoms/AtAvatar.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import useCreateEntityCommentKPIMutation from '@/api/mutations/EntityKPI/createEntityKpiComment.mutation';
import type { PgKpiQuery } from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';

const { t } = useI18n();
const { currentUserPictureUrl, currentUserName } = useCurrentUser();

const props = defineProps<{
  kpi: PgKpiQuery['getEntityKPI'];
  wrapperClass?: HTMLAttributes['class'];
}>();

const emit = defineEmits(['created']);

const comment = ref('');

const { mutate: createComment, loading } = useCreateEntityCommentKPIMutation();

const handlePostComment = async () => {
  if (!comment.value) return;

  await createComment({
    createEntityKPICommentInput: {
      entityKPI: props.kpi._id,
      text: comment.value,
    },
  });

  comment.value = '';

  emit('created');
};

defineOptions({ inheritAttrs: false });
</script>

<template>
  <div
    class="flex items-start gap-3 border bg-gray-50 rounded-md px-4 py-2"
    :class="props.wrapperClass"
  >
    <div class="flex gap-3 items-center">
      <AtAvatar
        class="!h-6 !w-6"
        :url="currentUserPictureUrl"
        :userName="currentUserName"
      />
    </div>
    <div class="w-full">
      <MlTextarea
        v-model="comment"
        wrapperClass="bg-white w-full p-2 text-sm"
        v-bind="$attrs"
      />
      <AtButton class="mt-2" :loading="loading" @click="handlePostComment">
        {{ t('Comment') }}
      </AtButton>
    </div>
  </div>
</template>
