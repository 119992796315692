<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { notify } from '@kyvg/vue3-notification';
import { useRoute } from 'vue-router';
import { MinusIcon, PlusIcon } from '@heroicons/vue/solid';
import {
  DataPointRequestStatusEnum,
  ValueDataTypeEnum,
  type PgExternalDataEntryQuery,
} from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import OgPdfS3FilePicker from '@/components/molecules/MlFilePicker/OgPdfS3FilePicker.vue';
import useAnswerDelegatedDataPointRequestMutation from '@/api/mutations/DataPointRequest/answerDelegatedDataPointRequest.mutation';
import AtInfoBox from '@/components/atoms/AtInfoBox/AtInfoBox.vue';
import type {
  TFile,
  TDataPointTypeOverride,
  DataPointRequestWithValueSourceNames,
} from '@/components/pages/PgProjects/types';
import { InfoBoxType } from '@/components/atoms/AtInfoBox/types';
import MlDataPointRequestInput from '@/components/pages/PgProjects/PgProject/TmDataEntry/OgQuestionList/MlQuestionInput.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import type { DataPointRequest } from './types';

const props = defineProps<{
  dataPointRequest: DataPointRequest;
  dataPointRequestsWithValueSourceNames: DataPointRequestWithValueSourceNames[];
  isGroup: boolean;
  currentExternalUserId?: PgExternalDataEntryQuery['getCurrentExternalUserId'];
}>();

const emit = defineEmits<{
  (e: 'closeCollapse'): void;
}>();

const { t } = useI18n();
const delegationEmailAddress = window.localStorage.getItem(
  'delegationEmailAddress',
);
const route = useRoute();

const {
  mutate: submitDPR,
  loading,
  error: answerDataPointRequestError,
} = useAnswerDelegatedDataPointRequestMutation();

const dpt = computed(() => props.dataPointRequest.dataPointType);
const isUpload = computed(
  () => dpt.value.valueDataType === ValueDataTypeEnum.UPLOAD,
);
const wasPreviouslySubmitted = computed(
  () =>
    !!props.dataPointRequest.file ||
    !!props.dataPointRequest.value ||
    (props.dataPointRequest.valueSource &&
      props.dataPointRequest.valueSource.length > 0 &&
      props.dataPointRequest.dataPointType.valueDataType ===
        ValueDataTypeEnum.TEXT),
);
const isAccepted = computed(
  () => props.dataPointRequest.status === DataPointRequestStatusEnum.ACCEPTED,
);
const isProofAllowed = computed(
  () => dpt.value.allowUploadProof && !isUpload.value && !isAccepted.value,
);
const dataPointTypeOverrides = inject(
  'dataPointTypeOverrides',
  computed(() => [] as TDataPointTypeOverride[]),
);
const override = computed(() =>
  dataPointTypeOverrides.value.find((_override) => {
    return (
      _override.datapointtype._id === props.dataPointRequest.dataPointType._id
    );
  }),
);
const isOverrideSplit = computed(() => override.value?.isSplit);

const dprFile = ref<TFile | undefined | null>(props.dataPointRequest.file);
const dprValue = ref(
  props.dataPointRequest.originalValue ?? props.dataPointRequest.value,
);
const dprComment = ref(props.dataPointRequest.comment ?? '');

const isSubmitDisabled = computed(() => {
  const isFileInput =
    props.dataPointRequest.dataPointType.valueDataType ===
    ValueDataTypeEnum.UPLOAD;
  const isNumericSplitInput =
    props.dataPointRequest.dataPointType.valueDataType ===
      ValueDataTypeEnum.NUMERIC && isOverrideSplit.value;

  const isEmpty =
    !isFileInput &&
    (Array.isArray(dprValue.value)
      ? !dprValue.value.length
      : !dprValue.value && dprValue.value !== 0);
  const isFileEmpty = isFileInput && !dprFile.value;
  const isInvalid =
    !isNumericSplitInput &&
    !!v.value.$silentErrors.length &&
    !['tooLow', 'tooHigh'].includes(v.value.$silentErrors[0].$validator);
  let isEmptyHTMLText = false;

  if (typeof dprValue.value === 'string') {
    isEmptyHTMLText = !dprValue.value.replace(/<\/?[^>]+(>|$)/g, '').trim();
  }
  return isEmpty || isFileEmpty || isInvalid || isEmptyHTMLText;
});
const overrideInputType = computed(() => {
  const isAllowedValueDataType =
    props.dataPointRequest.dataPointType.valueDataType ===
      ValueDataTypeEnum.NUMERIC ||
    props.dataPointRequest.dataPointType.valueDataType ===
      ValueDataTypeEnum.TEXT;

  const isOpenStatus =
    props.dataPointRequest.status === DataPointRequestStatusEnum.OPEN;
  const hasValueSource = !!props.dataPointRequest.valueSource?.length;

  const hasMultipleValueSources =
    props.dataPointRequest.valueSource &&
    props.dataPointRequest.valueSource?.length >= 2;

  const shouldUseSplit =
    (isOverrideSplit.value && isOpenStatus && isAllowedValueDataType) ||
    (hasValueSource &&
      isAllowedValueDataType &&
      !hasMultipleDelegations.value) ||
    (hasValueSource &&
      isAllowedValueDataType &&
      hasMultipleDelegations.value &&
      hasMultipleValueSources); // show all values for users having access to all valuesources

  if (
    shouldUseSplit &&
    props.dataPointRequest.dataPointType.valueDataType ===
      ValueDataTypeEnum.NUMERIC
  ) {
    return ValueDataTypeEnum.NUMERIC_SPLIT;
  }
  if (
    shouldUseSplit &&
    props.dataPointRequest.dataPointType.valueDataType ===
      ValueDataTypeEnum.TEXT
  ) {
    return ValueDataTypeEnum.TEXT_SPLIT;
  }

  return props.dataPointRequest.dataPointType.valueDataType;
});

const dataPointTypeValueUnit = computed(
  () =>
    props.dataPointRequest.originalValueUnit ??
    override.value?.valueUnit ??
    props.dataPointRequest.dataPointType.valueUnit ??
    undefined,
);

const trimmedDprComment = computed(() =>
  dprComment.value.replace(/<\/?[^>]+(>|$)/g, '').trim(),
);

async function handleSubmitDPR(
  dpr: DataPointRequest,
  _delegationEmailAddress: string,
  shareToken: string,
) {
  try {
    const response = await submitDPR({
      answerDataPointRequestDto: {
        dataPointRequestId: dpr._id,
        value: dprValue.value,
        comment: trimmedDprComment.value.length
          ? dprComment.value
          : trimmedDprComment.value,
        fileId: dprFile.value?._id,
        ...(dataPointTypeValueUnit.value
          ? {
              valueUnit: dataPointTypeValueUnit.value,
            }
          : {}),
        ...(overrideInputType.value === ValueDataTypeEnum.NUMERIC_SPLIT ||
        overrideInputType.value === ValueDataTypeEnum.TEXT_SPLIT ||
        dpr.dataPointType.valueDataType === ValueDataTypeEnum.NUMERIC_SPLIT ||
        dpr.dataPointType.valueDataType === ValueDataTypeEnum.TEXT_SPLIT ||
        dpr.dataPointType.valueDataType === ValueDataTypeEnum.EMISSIONS_SPLIT
          ? {
              value: undefined,
              valueSource: !Array.isArray(dprValue.value)
                ? undefined
                : dprValue.value,
            }
          : {}),
      },
      delegationEmailAddress: _delegationEmailAddress,
      shareToken,
    });
    const isSingleDPR =
      !response?.data?.answerDelegatedDataPointRequest.childs.length;
    if (isSingleDPR && !props.isGroup) emit('closeCollapse');
    notify({ type: 'success', text: t('Your answer has been submitted.') });
    dprValueChanged.value = false;
  } catch (err) {
    if (answerDataPointRequestError.value)
      notify({
        type: 'error',
        text: t(answerDataPointRequestError.value?.message),
      });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

const handleInputEnter = () => {
  if (isAccepted.value) {
    return; // return unapproveDataPointRequest(props.dataPointRequest._id);
  }
  if (!isAccepted.value) {
    return handleSubmitDPR(
      props.dataPointRequest,
      delegationEmailAddress ?? '',
      route.params.dataEntryShareToken.toString(),
    );
  }
};

const v = useVuelidate();

const dprValueChanged = ref(false);
const dprUpdateWarningShown = computed(() => {
  return (
    dprValueChanged.value &&
    props.dataPointRequest.status !== DataPointRequestStatusEnum.OPEN &&
    props.dataPointRequest.childs?.some(
      (child) => child.status !== DataPointRequestStatusEnum.OPEN,
    )
  );
});
watch(dprValue, () => {
  dprValueChanged.value = true;
});

const dprDelegationInput = computed(() => ({
  entityId: props.dataPointRequest.entity._id ?? '',
  userId: props.currentExternalUserId,
}));

const isFilePickerShown = ref(false);
const isCommentBoxOpen = ref(false);

function toggleCommentBox() {
  isCommentBoxOpen.value = !isCommentBoxOpen.value;
  isFilePickerShown.value = false;
}

function toggleFilePicker() {
  isFilePickerShown.value = !isFilePickerShown.value;
  isCommentBoxOpen.value = false;
}

const hasSplitInput = computed(
  () =>
    [
      ValueDataTypeEnum.EMISSIONS_SPLIT,
      ValueDataTypeEnum.NUMERIC_SPLIT,
      ValueDataTypeEnum.TEXT_SPLIT,
    ].includes(props.dataPointRequest.dataPointType.valueDataType) ||
    [
      ValueDataTypeEnum.EMISSIONS_SPLIT,
      ValueDataTypeEnum.NUMERIC_SPLIT,
      ValueDataTypeEnum.TEXT_SPLIT,
    ].includes(overrideInputType.value) ||
    isOverrideSplit.value,
);

const hasMultipleDelegations = computed(
  () =>
    !!(
      props.dataPointRequest.delegations &&
      props.dataPointRequest.delegations?.length >= 2
    ),
);
</script>

<template>
  <form class="flex flex-col h-full items-start justify-between gap-4 w-full">
    <AtInfoBox v-if="dprUpdateWarningShown" :type="InfoBoxType.Warning">
      {{
        t(
          "By clicking 'Update', your follow-up questions will change. Any responses you've entered in conditional fields will be removed.",
        )
      }}
    </AtInfoBox>
    <MlDataPointRequestInput
      v-model="dprValue"
      v-model:file="dprFile"
      class="!w-full"
      :dataPointRequest="dataPointRequest"
      :disabled="isAccepted"
      :valueUnit="dataPointTypeValueUnit"
      :valueUnitDivisor="dpt.valueUnitDivisor ?? undefined"
      :override="override"
      :onEnter="handleInputEnter"
      :overrideInputType="overrideInputType"
      :dataPointRequestsWithValueSourceNames="
        dataPointRequestsWithValueSourceNames
      "
    />
    <div class="flex gap-x-4">
      <div
        v-if="hasSplitInput"
        :id="`actionAddRow_${dataPointRequest._id}`"
        class="shrink-0"
      />
      <div
        v-if="!isAccepted && !hasMultipleDelegations && isProofAllowed"
        class="shrink-0"
      >
        <button
          aria-label="add document"
          class="flex text-gray-400 text-xs hover:text-primary"
          @click.prevent="toggleFilePicker"
        >
          <MinusIcon v-if="isFilePickerShown" class="h-4 mr-2" />
          <PlusIcon v-else class="h-4 mr-2" />
          {{ t('Add document') }}
        </button>
      </div>
      <button
        v-if="!isAccepted && !hasMultipleDelegations"
        aria-label="add comment"
        class="flex cursor-pointer items-center text-gray-400 hover:text-primary"
        :class="{ 'pointer-events-none': isSubmitDisabled }"
        @click.prevent="toggleCommentBox"
      >
        <PlusIcon
          v-if="!isCommentBoxOpen"
          :class="{ 'text-gray-200': isSubmitDisabled }"
          class="w-4"
        />
        <MinusIcon
          v-else
          :class="{ 'text-gray-200': isSubmitDisabled }"
          class="w-4"
        />
        <span
          class="ml-2 text-xs whitespace-nowrap"
          :class="{ 'text-gray-200': isSubmitDisabled }"
        >
          {{ t('Add comment') }}
        </span>
      </button>
    </div>
    <OgPdfS3FilePicker
      v-if="isFilePickerShown"
      v-model="dprFile"
      v-model:uploading="loading"
      :dprDelegationInput="dprDelegationInput"
      class="row-span-2 max-w-xs"
      :placeholder="t('Select or drop proof document')"
      showRecentFiles
      compact
    />
    <div v-show="isCommentBoxOpen">
      <p class="text-xs text-gray-700">
        <i18n-t
          keypath="Leave a comment for explanation that is going to be shown on the {homeResults} page."
        >
          <template #homeResults>
            <router-link
              :to="{ name: 'homeResults' }"
              variant="virgin"
              class="text-primary underline"
            >
              {{ t('Results') }}
            </router-link>
          </template>
        </i18n-t>
      </p>
      <MlTextarea v-model="dprComment" :placeholder="t('Enter a comment.')" />
    </div>
    <div class="flex w-full mt-4">
      <AtButton
        v-if="!isAccepted"
        :disabled="isSubmitDisabled"
        :loading="loading"
        data-cy="MlQuestionForm-submitButton"
        @click.stop.prevent="
          handleSubmitDPR(
            dataPointRequest,
            delegationEmailAddress ?? '',
            route.params.dataEntryShareToken.toString(),
          )
        "
      >
        {{ wasPreviouslySubmitted ? t('Update') : t('Submit') }}
      </AtButton>
    </div>
  </form>
</template>
