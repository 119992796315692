import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdateDataPointCustomSubcategoriesMutation,
  UpdateDataPointCustomSubcategoriesMutationVariables,
} from '@/__generated__/types';

export const UPDATE_DATAPOINT_CUSTOM_SUBCATEGORIES = gql`
  mutation UpdateDataPointCustomSubcategories(
    $categoryId: String!
    $subcategories: [UpdateSubcategoryInput!]!
  ) {
    updateDataPointCustomSubcategories(
      categoryId: $categoryId
      subcategories: $subcategories
    ) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  UpdateDataPointCustomSubcategoriesMutation,
  UpdateDataPointCustomSubcategoriesMutationVariables
>;

const useUpdateDataPointCustomSubcategoriesMutation = (options?: TOptions) =>
  useMutation<
    UpdateDataPointCustomSubcategoriesMutation,
    UpdateDataPointCustomSubcategoriesMutationVariables
  >(UPDATE_DATAPOINT_CUSTOM_SUBCATEGORIES, options);

export default useUpdateDataPointCustomSubcategoriesMutation;
