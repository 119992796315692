<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import { calculateIroRiskAndOpportunitySurveysRating } from '../../utils';
import { useStore } from '../../store';
import OgTable from '../../OgTable.vue';

type RowItem = {
  _id: string;
  riskAndOpportunity: string;
  rating: number | null;
};

const { t } = useI18n();
const store = useStore();
const { formatNumber } = useFormatNumber();

const headers = computed(() => [
  { text: t('Risks and opportunities'), value: 'riskAndOpportunity' },
  { text: t('Average rating'), value: 'rating', columnClasses: 'w-[200px]' },
]);

const items = computed<RowItem[]>(() => {
  return store.value.iroRisksAndOpportunities
    .filter((iroRiskAndOpportunity) => iroRiskAndOpportunity.riskAndOpportunity)
    .map((iroRiskAndOpportunity) => ({
      _id: iroRiskAndOpportunity._id,
      riskAndOpportunity: iroRiskAndOpportunity.riskAndOpportunity,
      rating: calculateIroRiskAndOpportunitySurveysRating(
        iroRiskAndOpportunity._id,
        store.value.surveys,
      ),
    }));
});
</script>

<template>
  <h3 class="text-lg font-medium mb-3">
    {{ t('Results on risks and opportunities') }}
  </h3>
  <OgTable :headers="headers" :items="items" :isRowRemovable="false">
    <template #item-riskAndOpportunity="row: RowItem">
      <p>
        {{ row.riskAndOpportunity }}
      </p>
    </template>
    <template #item-rating="row: RowItem">
      <p>
        {{
          typeof row.rating !== 'number'
            ? t('n/a')
            : formatNumber(row.rating, 0, 1)
        }}
      </p>
    </template>
  </OgTable>
</template>
