import { gql } from '@apollo/client/core';

export default gql`
  query PgDataRoom(
    $offset: Int!
    $limit: PaginationLimitEnum!
    $sortBy: String!
    $sortOrder: PaginationSortingEnum!
    $filterBy: [TaxonomyDocumentsFilterByInput!]!
  ) {
    getTaxonomyDocuments(
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortOrder: $sortOrder
      filterBy: $filterBy
    ) {
      totalCount
      items {
        _id
        category
        addedBy {
          _id
          firstName
          lastName
          email
        }
        project {
          _id
          name
        }
        file {
          _id
          filename
          downloadUrl
        }
        dataPoint {
          _id
          name
        }
        updatedAt
      }
    }
  }
`;
