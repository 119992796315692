import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DeleteEntityMutation,
  DeleteEntityMutationVariables,
} from '@/__generated__/types';

export const DELETE_ENTITY = gql`
  mutation DeleteEntity($entityInput: EntityInput!) {
    deleteEntity(entityInput: $entityInput) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  DeleteEntityMutation,
  DeleteEntityMutationVariables
>;

const useDeleteEntityMutation = (options?: TOptions) =>
  useMutation<DeleteEntityMutation, DeleteEntityMutationVariables>(
    DELETE_ENTITY,
    options,
  );

export default useDeleteEntityMutation;
