import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DisableUserMutation,
  DisableUserMutationVariables,
} from '@/__generated__/types';

export const DISABLE_USER = gql`
  mutation DisableUser($disableUserInput: DisableUserInput!) {
    disableUser(user: $disableUserInput) {
      _id
      role
      email
    }
  }
`;

type TOptions = UseMutationOptions<
  DisableUserMutation,
  DisableUserMutationVariables
>;

const useDisableUserMutation = (options?: TOptions) =>
  useMutation<DisableUserMutation, DisableUserMutationVariables>(
    DISABLE_USER,
    options,
  );

export default useDisableUserMutation;
