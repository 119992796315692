<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { TrashIcon } from '@heroicons/vue/outline';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import { UserRole } from '@/__generated__/types';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import { useDeleteRepositoryFile } from './useDeleteRepositoryFile';
import type { TaxonomyProject } from './types';

const { t } = useI18n();

const props = defineProps<Props>();
const { deleteRepositoryFile } = useDeleteRepositoryFile();
type Props = {
  project: TaxonomyProject;
};

const documents = computed(() => props.project.assessments[0].documents ?? []);

const headers = computed(() => [
  { text: t('File'), value: 'file' },
  { text: t(''), value: 'crud' },
]);
</script>

<template>
  <OgDataTable :headers="headers" :items="documents" controlsHidden>
    <template #item-file="row">
      <a
        :href="row.file.downloadUrl"
        class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ row.file.filename }}
      </a>
      <div class="text-xs text-gray-400">{{ row.file.filesize / 1000 }}kb</div>
    </template>
    <template #item-crud="row">
      <span
        v-if="true"
        class="grid grid-cols-1 place-items-center gap-x-2 justify-self-end"
      >
        <AtIconButton
          v-rolefrom="UserRole.ADMIN"
          :icon="TrashIcon"
          class="w-7 text-gray-400"
          :title="t('Delete')"
          @click.stop="deleteRepositoryFile(row._id)"
        />
      </span>
    </template>
  </OgDataTable>
</template>
