<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ChevronLeftIcon } from '@heroicons/vue/outline';
import { useRoute, useRouter } from 'vue-router';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import MlTabs from '@/components/molecules/MlTabs/MlTabs.vue';
import type { Project } from '../types';
import OgProjectHeader from './OgProjectHeader/OgProjectHeader.vue';
import OgProjectSidebar from './OgProjectSidebar/OgProjectSidebar.vue';

type Props = {
  projectId: string;
  projects: Project[];
};

defineProps<Props>();

const isSidePanelHidden = inject('isSidePanelHidden', ref(false));

const route = useRoute();
const { t } = useI18n();
const router = useRouter();
const { isAdminOrSuperAdmin } = useCurrentUser();

const activeTab = computed(() =>
  route.name && !Array.isArray(route.name) ? route.name.toString() : '',
);
const tabs = computed(() => [
  {
    value: 'projectsProjectDataEntry',
    name: t('Data Entry'),
    query: route.query,
  },
  ...(isAdminOrSuperAdmin.value
    ? [
        {
          value: 'projectsProjectArchive',
          name: t('Archive'),
          query: route.query,
        },
      ]
    : []),
]);
</script>

<template>
  <OgProjectSidebar :projectId="projectId" />
  <div class="sticky top-0 bg-white z-10">
    <div class="flex items-center gap-6 px-6 border-gray-200">
      <OgProjectHeader :projectId="projectId" />
      <button
        class="ml-auto text-sm text-nowraprounded mt-2.5 p-1.5 rounded border-gray-200 text-gray-700 border cursor-pointer items-center hover:border-primary hover:text-primary"
        type="button"
        :class="{ hidden: !isSidePanelHidden }"
        @click="isSidePanelHidden = false"
      >
        <ChevronLeftIcon class="w-3 inline-block mb-0.5" />
        {{ t('Open details') }}
      </button>
    </div>
    <div class="border-b flex justify-between mx-6">
      <MlTabs
        :modelValue="activeTab"
        :tabs="tabs"
        isEdgeToEdge
        class="mt-4"
        :hasWideBorderBottom="false"
        @update:modelValue="
          router.push({ name: $event.value, query: $event.query })
        "
      />

      <div id="projectLayoutControls" class="flex items-end" />
    </div>
    <div id="projectSelectActions" />
  </div>
  <router-view ref="pgProjectDataEntry" :projects="projects" />
</template>
