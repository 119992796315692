<script setup lang="ts">
import { computed, inject, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import AtDownloadButton from '@/components/atoms/AtDownloadButton.vue';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import MlDataPointValue from '@/components/molecules/MlDataPointValue.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import { getPerDate } from '../../services/prepareStandardData';
import type { DataPoint, PreparedPerDataPointTypeItem } from '../../types';
import type { CommentsPerDate, Header, TableData } from './types';
import { prepareForTable } from './utils';
import MlCategoryValue from './MlCategoryValue.vue';

type Props = {
  dataPointType: PreparedPerDataPointTypeItem['type'];
  frameworks: PreparedPerDataPointTypeItem['frameworks'];
  allDataPoints: PreparedPerDataPointTypeItem['allDataPoints'];
  projects: PreparedPerDataPointTypeItem['dataPointsPerProject'];
  shouldDisableExport: boolean;
  showTotal: boolean;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  export: [];
}>();

const { t } = useI18n();
const adminMode = inject(
  'adminMode',
  computed(() => false),
);

const hiddenRows = reactive(new Set<string>());

const showCategories = computed(() =>
  props.allDataPoints.some(
    (dataPoint) => dataPoint.valueSource && dataPoint.valueSource.length > 0,
  ),
);
const perDate = computed(() =>
  getPerDate(props.projects, props.dataPointType, showCategories.value),
);

const showWorkspaces = computed(() => adminMode.value);
const showSublevels = computed(() => !adminMode.value);

const headers = computed<Header[]>(() => {
  return [
    {
      text: t(showWorkspaces.value ? 'Workspace' : 'Project'),
      value: 'project',
      styling: 'w-[125px]',
    },
    ...(showSublevels.value
      ? [
          {
            text: t('Sublevels'),
            value: 'sublevels',
            styling: 'w-[125px]',
          },
        ]
      : []),
    ...(showCategories.value
      ? [
          {
            text: t('Categories'),
            value: 'categories',
            styling: 'w-[200px]',
          },
        ]
      : []),
    ...perDate.value.dates.map((date) => ({
      text: getDPRDateString(
        date.from ? date.from.toString() : null,
        date.to ? date.to.toString() : null,
      ),
      value: date.key,
      styling: '',
    })),
  ];
});

const allTableData = computed(() =>
  prepareForTable(
    perDate.value.projects,
    perDate.value.dates,
    props.dataPointType,
    showCategories.value,
    props.showTotal,
    t,
  ),
);

// All sublevels which are collapsible should be collapsed initially.
watch(
  allTableData,
  (newAllTableData) => {
    hiddenRows.clear();

    newAllTableData.projects.forEach((project) => {
      project.rows.forEach((row) => {
        if (row.isCollapsible) {
          hiddenRows.add(row.id);
        }
      });
    });
  },
  { immediate: true },
);

const comments = computed<CommentsPerDate[]>(() => {
  const map: Record<string, DataPoint[]> = {};
  props.allDataPoints
    .filter((dataPoint) => !!dataPoint.comment)
    .forEach((dataPoint) => {
      const date = getDPRDateString(dataPoint.from, dataPoint.to);
      if (!map[date]) {
        map[date] = [];
      }
      map[date].push(dataPoint);
    });

  return Object.entries(map).map(([date, dataPoints]) => ({
    date,
    comments: dataPoints.map((dataPoint) => ({
      name: showWorkspaces.value
        ? (dataPoint.entity?.name ?? '')
        : (dataPoint.location?.name ?? ''),
      text: dataPoint.comment ?? '',
    })),
  }));
});

// Show only some rows depending on if user has extended or collapsed some levels.
const visibleTableData = computed<TableData>(() => {
  return {
    ...allTableData.value,
    projects: allTableData.value.projects.map((project) => ({
      ...project,
      rows: project.rows.filter((row) => {
        for (const hiddenRow of hiddenRows) {
          if (row.keys.has(hiddenRow)) {
            return false;
          }
        }

        return true;
      }),
    })),
  };
});

function handleDownloadClick() {
  emit('export');
}

function handleSublevelsToggle(id: string) {
  if (hiddenRows.has(id)) {
    hiddenRows.delete(id);
  } else {
    hiddenRows.add(id);
  }
}
</script>

<template>
  <div class="mb-6 grid overflow-auto rounded-md bg-gray-50 p-4">
    <div class="flex justify-between mb-10">
      <div class="relative">
        <h3 class="max-w-[75vw] py-2 text-sm font-medium sm:max-w-lg">
          {{ t(dataPointType.friendlyName) }}
        </h3>
        <h4 class="text-sm font-normal text-gray-500">
          {{ t(dataPointType.question) }}
          <AtTooltipIcon
            v-if="dataPointType.questionHelp"
            class="cursor-pointer"
            :triggers="['click', 'touch']"
            :delay="0"
            autoHide
            :tooltip="t(dataPointType.questionHelp)"
          />
          <AtBadge
            v-for="framework in frameworks"
            :key="framework.framework"
            class="mr-2 py-0 text-xs font-light"
            type="neutral"
          >
            <span v-if="framework?.framework">{{
              t(framework?.framework)
            }}</span
            ><template v-if="framework?.groups?.length"> - </template>
            <template v-for="(group, i) in framework?.groups">
              {{ group }}
              <template v-if="i + 1 < (framework?.groups?.length ?? 0)">
                ,
              </template>
            </template>
          </AtBadge>
        </h4>
      </div>
      <div class="absolute right-12">
        <AtDownloadButton
          v-if="!adminMode"
          :disabled="shouldDisableExport"
          @click="handleDownloadClick"
        />
      </div>
    </div>

    <table class="text-left">
      <colgroup>
        <col
          v-for="header in headers"
          :key="header.value"
          :class="header.styling"
        />
      </colgroup>
      <thead class="uppercase text-sm text-gray-400">
        <tr class="bg-gray-200">
          <th
            v-for="header in headers"
            :key="header.value"
            class="p-3 first:rounded-l-md last:rounded-r-md"
          >
            {{ header.text }}
          </th>
          <th />
        </tr>
      </thead>

      <tbody class="text-xs">
        <template
          v-for="(project, projectIndex) in visibleTableData.projects"
          :key="project.id"
        >
          <tr
            v-for="(row, rowIndex) in project.rows"
            :key="rowIndex"
            :class="{
              'border-t-2 border-gray-400': rowIndex === 0 && projectIndex > 0,
              'border-t border-gray-100': rowIndex > 0,
            }"
          >
            <th
              v-if="rowIndex === 0"
              scope="row"
              class="align-top p-3 font-normal"
              :rowspan="project.rows.length"
            >
              {{ project.name }}
            </th>
            <td
              v-if="showSublevels && row.sublevelSpan > 0"
              :rowspan="row.sublevelSpan"
              class="align-top p-3"
            >
              <button
                v-if="row.isCollapsible"
                class="inline-flex space-x-0.5 text-left"
                type="button"
                @click="handleSublevelsToggle(row.id)"
              >
                <span class="shrink-0 w-4 h-4"
                  ><ChevronRightIcon
                    v-if="hiddenRows.has(row.id)" /><ChevronDownIcon v-else
                /></span>
                <span>{{ row.name }}</span>
              </button>
              <div v-else class="pl-4">
                {{ row.name }}
              </div>
            </td>
            <td v-if="showCategories" class="align-top p-3">
              {{ row.categoryName }}
            </td>
            <td
              v-for="date in perDate.dates"
              :key="date.key"
              class="align-top p-3"
            >
              <template v-if="showCategories">
                <MlCategoryValue
                  v-if="row.categoryValues[date.key]"
                  :value="row.categoryValues[date.key]"
                  :dataPointType="dataPointType"
                />
                <span v-else>-</span>
              </template>
              <template v-else>
                <MlDataPointValue
                  v-if="row.values[date.key]"
                  :dataPointValueAndType="row.values[date.key]"
                />
                <span v-else>-</span>
              </template>
            </td>
          </tr>
          <tr v-if="project.total" class="border-gray-400 border-t">
            <th class="align-top p-3 font-normal">
              {{ project.total.name }}
            </th>
            <td v-if="showSublevels" />
            <td v-if="showCategories" />
            <td
              v-for="date in perDate.dates"
              :key="date.key"
              class="align-top p-3"
            >
              <MlDataPointValue
                v-if="project.total.values[date.key]"
                :dataPointValueAndType="project.total.values[date.key]"
              />
              <span v-else>-</span>
            </td>
          </tr>
          <template v-else-if="project.categoriesTotal">
            <tr
              v-for="(row, rowIndex) in project.categoriesTotal"
              :key="rowIndex"
              :class="{
                'border-gray-400 border-t': rowIndex === 0,
                'border-t border-gray-100': rowIndex > 0,
              }"
            >
              <th
                v-if="rowIndex === 0"
                scope="row"
                class="align-top p-3 font-normal"
                :rowspan="project.categoriesTotal.length"
              >
                {{ row.name }}
              </th>
              <td
                v-if="rowIndex === 0 && showSublevels"
                :rowspan="project.categoriesTotal.length"
              />
              <td class="align-top p-3">
                {{ row.categoryName }}
              </td>
              <td
                v-for="date in perDate.dates"
                :key="date.key"
                class="align-top p-3"
              >
                <MlCategoryValue
                  v-if="row.values[date.key]"
                  :value="row.values[date.key]"
                  :dataPointType="dataPointType"
                />
                <span v-else>-</span>
              </td>
            </tr>
          </template>
        </template>
      </tbody>

      <tfoot v-if="visibleTableData.categoriesTotal" class="text-xs font-bold">
        <tr
          v-for="(row, rowIndex) in visibleTableData.categoriesTotal"
          :key="rowIndex"
          :class="{
            'border-t-[3px] border-double border-gray-400': rowIndex === 0,
            'border-t border-gray-100': rowIndex > 0,
          }"
        >
          <th
            v-if="rowIndex === 0"
            scope="row"
            class="align-top p-3 uppercase"
            :rowspan="visibleTableData.categoriesTotal.length"
          >
            {{ row.name }}
          </th>
          <td
            v-if="rowIndex === 0 && showSublevels"
            :rowspan="visibleTableData.categoriesTotal.length"
          />
          <td class="align-top p-3">
            {{ row.categoryName }}
          </td>
          <td
            v-for="date in perDate.dates"
            :key="date.key"
            class="align-top p-3"
          >
            <MlCategoryValue
              v-if="row.values[date.key]"
              :value="row.values[date.key]"
              :dataPointType="dataPointType"
            />
            <span v-else>-</span>
          </td>
        </tr>
      </tfoot>
      <tfoot v-else-if="visibleTableData.total" class="text-xs font-bold">
        <tr class="border-t-[3px] border-double border-gray-400">
          <th scope="row" class="align-top p-3 uppercase">
            {{ visibleTableData.total.name }}
          </th>
          <td v-if="showSublevels" />
          <td v-if="showCategories" />
          <td
            v-for="date in perDate.dates"
            :key="date.key"
            class="align-top p-3"
          >
            <MlDataPointValue
              v-if="visibleTableData.total.values[date.key]"
              :dataPointValueAndType="visibleTableData.total.values[date.key]"
            />
            <span v-else>-</span>
          </td>
        </tr>
      </tfoot>
    </table>
    <div
      v-for="commentsPerDate of comments"
      :key="commentsPerDate.date"
      class="mb-1"
    >
      <span class="ml-2 text-xs">{{ commentsPerDate.date }}</span>
      <div
        v-for="(comment, commentIndex) of commentsPerDate.comments"
        :key="commentIndex"
        class="ml-2 text-xs"
      >
        <span class="col-auto mr-2 whitespace-nowrap font-semibold"
          >{{ comment.name }}:</span
        >
        <MlHtmlContent
          class="col-auto line-clamp-4 hover:line-clamp-none"
          :html="comment.text"
        />
      </div>
    </div>
  </div>
</template>
