<script setup lang="ts">
import { computed, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import UserAddIcon from '@heroicons/vue/outline/UserAddIcon';
import {
  type PgDoubleMaterialityTeamQuery,
  type PgDoubleMaterialityTeamQueryVariables,
  UserRole,
} from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import AtEmptyCard from '../../AtEmptyCard.vue';
import type { User } from './types';
import OgActionsPanel from './OgActionsPanel.vue';
import PG_TEAM_QUERY from './PgTeam.query';

type UserRow = {
  _id: string;
  user: User;
  isCurrentUser: boolean;
};

const { t } = useI18n();
const { isAdminOrSuperAdmin, currentUser } = useCurrentUser();

const { result } = useQuery<
  PgDoubleMaterialityTeamQuery,
  PgDoubleMaterialityTeamQueryVariables
>(PG_TEAM_QUERY, () => ({
  allTeamsUsers: isAdminOrSuperAdmin.value,
}));

const showUserForm = ref(false);
const userToEdit = ref<User | null>(null);

const headers = computed(() => [
  { text: t('Name'), value: 'name' },
  { text: t('Title'), value: 'title' },
  { text: t('Department'), value: 'department' },
  { text: '', value: 'edit' },
]);

const allUsers = computed(() => {
  if (isAdminOrSuperAdmin.value) {
    return result.value?.getAllTeamsUsers || [];
  }

  return result.value?.getTeamUsers || [];
});
const assignedUsers = computed(() =>
  allUsers.value.filter((user) => user.assignments.doubleMateriality),
);
const noAccessUsers = computed(() =>
  allUsers.value.filter((user) => !user.assignments.doubleMateriality),
);

const tableData = computed<UserRow[]>(() =>
  assignedUsers.value.map((user) => ({
    _id: user._id,
    user,
    isCurrentUser: currentUser.value?._id === user._id,
  })),
);

function canEditUser(user: User) {
  if (isAdminOrSuperAdmin.value) {
    return true;
  }

  return (
    currentUser.value?.role === UserRole.MANAGER &&
    user.role !== UserRole.ADMIN &&
    user.role !== UserRole.SUPERADMIN &&
    user.invitedBy?._id === currentUser.value?._id
  );
}

function handleAddUserClick() {
  showUserForm.value = true;
}

function handleCloseUserForm() {
  showUserForm.value = false;
  userToEdit.value = null;
}

function handleEditUserClick(user: User) {
  userToEdit.value = user;
  showUserForm.value = true;
}
</script>

<template>
  <AtEmptyCard
    v-if="tableData.length === 0"
    variant="default"
    :icon="UserAddIcon"
    :title="t('Start your assessment here by inviting your team')"
    :primaryAction="{
      label: t('Add first team member(s)'),
      onClick: handleAddUserClick,
    }"
  >
    <p>
      {{
        t(
          'To perform a double materiality assessment, you will need the support of internal team - people who work with you on selection of topics, scoring of impacts, risks and opportunities, and collaborate with stakeholders to get to the final results. You can invite your team members here, and add or manage them any time from this page.',
        )
      }}
    </p>
  </AtEmptyCard>

  <template v-else>
    <div>
      <p>
        {{
          t(
            'Invite and manage the team members who need to collaborate on your double materiality assessment.',
          )
        }}
      </p>
      <OgDataTable
        :headers="headers"
        :items="tableData"
        controlsHidden
        noPagination
      >
        <template #item-name="row: UserRow">
          <span
            >{{ row.user.firstName }} {{ row.user.lastName
            }}<span v-if="row.isCurrentUser"> ({{ t('you') }})</span></span
          >
        </template>
        <template #item-title="row: UserRow">
          <span>{{ row.user.jobTitle }}</span>
        </template>
        <template #item-department="row: UserRow">
          <span>{{ row.user.jobDepartment }}</span>
        </template>
        <template #item-edit="row: UserRow">
          <AtButton
            v-if="canEditUser(row.user)"
            type="button"
            variant="text"
            @click="handleEditUserClick(row.user)"
          >
            {{ t('Edit') }}
          </AtButton>
        </template>
      </OgDataTable>
    </div>

    <div class="mt-auto flex justify-end">
      <AtButton type="button" @click="handleAddUserClick">
        {{ t('Add more team members') }}
      </AtButton>
    </div>
  </template>

  <OgActionsPanel
    v-if="showUserForm"
    :user="userToEdit"
    :allExistingUsers="allUsers"
    :noAccessUsers="noAccessUsers"
    @close="handleCloseUserForm"
  />
</template>
