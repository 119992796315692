<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import capitalize from 'lodash/capitalize';
import { DataPointRequestStatusEnum } from '@/__generated__/types';
import AtLoader from '@/components/atoms/AtLoader/AtLoader.vue';
import type { TLocationReportingProgress } from './types';
import MlTaskTitle from './MlTaskTitle.vue';
import MlCategoryDprs from './MlCategoryDprs.vue';

const props = defineProps<Props>();
type Props = {
  locations: TLocationReportingProgress[];
  status: DataPointRequestStatusEnum;
  categories: string[];
  dprCount: number;
  isOpen: boolean;
  isHovered: boolean;
  isAllExpanded: boolean;
};

const emit = defineEmits<Emits>();
type Emits = {
  (e: 'updateStatusHovered', status: DataPointRequestStatusEnum | null): void;
  (e: 'updateStatusToggled', status: DataPointRequestStatusEnum): void;
};

const { t } = useI18n();
const { DRAFT, OPEN, PENDING, REJECTED, ACCEPTED, OVERDUE, CLOSED } =
  DataPointRequestStatusEnum;

const locationsLoading = ref<string[]>([]);
const taskOpenLocationIds = ref<string[]>([]);
const openCategories = ref<string[]>([]);

watch(
  () => props.isAllExpanded,
  () => {
    taskOpenLocationIds.value = [];
    openCategories.value = [];
    if (props.isAllExpanded) {
      props.locations.forEach((location) => {
        taskOpenLocationIds.value.push(
          `${location.location._id}${props.status}`,
        );
        location.location.assignments.categories.forEach((category) => {
          openCategories.value.push(
            `${location.location._id}${props.status}${category.category}`,
          );
        });
      });
    }
  },
);

const getLocationDprCount = (
  status: DataPointRequestStatusEnum,
  locationId: string,
) => {
  switch (status) {
    case DRAFT:
      return (
        props.locations.find((location) => location.location._id === locationId)
          ?.draft ?? 0
      );
    case OPEN:
      return (
        props.locations.find((location) => location.location._id === locationId)
          ?.open ?? 0
      );
    case PENDING:
      return (
        props.locations.find((location) => location.location._id === locationId)
          ?.pending ?? 0
      );
    case REJECTED:
      return (
        props.locations.find((location) => location.location._id === locationId)
          ?.rejected ?? 0
      );
    case ACCEPTED:
      return (
        props.locations.find((location) => location.location._id === locationId)
          ?.accepted ?? 0
      );
    case CLOSED:
      return (
        props.locations.find((location) => location.location._id === locationId)
          ?.closed ?? 0
      );
    case OVERDUE:
      return (
        props.locations.find((location) => location.location._id === locationId)
          ?.overDue ?? 0
      );
    default:
      return 0;
  }
};

const badgeClass = computed(() => {
  if (props.isHovered || props.isOpen) {
    switch (props.status) {
      case OPEN:
        return 'bg-blue-50 text-blue-500';
      case PENDING:
        return 'bg-orange-50 text-orange-400';
      case REJECTED:
        return 'bg-rose-50 text-rose-400';
      case ACCEPTED:
        return 'bg-teal-50 text-teal-600';
      case OVERDUE:
        return 'bg-violet-50 text-violet-500';
      default:
        return 'bg-gray-50 text-gray-500';
    }
  }
  return '';
});

const isLocationToggled = (
  locationId: string,
  status: DataPointRequestStatusEnum,
) => {
  return taskOpenLocationIds.value.includes(`${locationId}${status}`);
};
const isCategoryToggled = (
  locationId: string,
  status: DataPointRequestStatusEnum,
  category: string,
) => {
  return openCategories.value.includes(`${locationId}${status}${category}`);
};

function toggleLocationOpenDprs(locationIdAndStatus: string) {
  const taskToCloseIndex =
    taskOpenLocationIds.value.indexOf(locationIdAndStatus);
  if (taskToCloseIndex >= 0) {
    taskOpenLocationIds.value.splice(taskToCloseIndex, 1);
  } else {
    taskOpenLocationIds.value.push(locationIdAndStatus);
  }
}
function toggleOpenCategories(locationIdAndStatusAndCategory: string) {
  const index = openCategories.value.indexOf(locationIdAndStatusAndCategory);
  if (index >= 0) {
    openCategories.value.splice(index, 1);
  } else {
    openCategories.value.push(locationIdAndStatusAndCategory);
  }
}

function updateLocationsLoading(locationId: string) {
  const locationLoadingIndex = locationsLoading.value.indexOf(locationId);
  if (locationLoadingIndex >= 0) {
    locationsLoading.value.splice(locationLoadingIndex, 1);
  } else {
    locationsLoading.value.push(locationId);
  }
}
</script>

<template>
  <li
    class="py-[6px] border-b-[1px] border-gray-200 text-sm mb-[6px]"
    @mouseenter="emit('updateStatusHovered', status)"
    @focus="emit('updateStatusHovered', status)"
    @mouseleave="emit('updateStatusHovered', null)"
    @focusout="emit('updateStatusHovered', null)"
  >
    <MlTaskTitle
      :isOpen="isOpen"
      :title="t('Open')"
      :wrapperClass="{ 'mb-1': props.isOpen }"
      :count="props.dprCount"
      @toggle="emit('updateStatusToggled', status)"
    >
      <template #title>
        <div
          class="bg-gray-50 px-1.5 mr-2.5 rounded-sm font-bold text-center justify-center"
          :class="badgeClass"
        >
          {{ t(capitalize(props.status)) }}
        </div>
      </template>
    </MlTaskTitle>

    <Transition name="vertical-fade-slide">
      <ul v-show="isOpen" class="pl-4">
        <li v-for="location in props.locations" :key="location.location._id">
          <div class="flex mb-1">
            <MlTaskTitle
              :isOpen="isLocationToggled(location.location._id, props.status)"
              :title="location.location.name"
              :count="getLocationDprCount(props.status, location.location._id)"
              wrapperClass=""
              titleClass="text-gray-900 pl-1 pr-3 rounded-sm font-semibold text-sm hover:text-blue-600"
              @toggle="
                toggleLocationOpenDprs(
                  `${location.location._id}${props.status}`,
                )
              "
            />
            <AtLoader
              v-if="locationsLoading.includes(location.location._id)"
              class="ml-2 mt-0.5"
              size="xs"
            />
          </div>
          <Transition name="vertical-fade-slide">
            <ul
              v-show="isLocationToggled(location.location._id, props.status)"
              class="pl-4"
            >
              <TransitionGroup name="vertical-fade-slide">
                <div
                  v-for="locationCategory in location.location.assignments.categories.filter(
                    (category) => props.categories.includes(category.category),
                  ) ?? []"
                  :key="locationCategory.category"
                >
                  <MlCategoryDprs
                    :locationId="location.location._id"
                    :category="locationCategory.category"
                    :status="props.status"
                    :isLocationToggled="
                      isLocationToggled(location.location._id, props.status)
                    "
                    :isCategoryToggled="
                      isCategoryToggled(
                        location.location._id,
                        props.status,
                        locationCategory.category,
                      )
                    "
                    @loading="updateLocationsLoading"
                    @toggleCategory="toggleOpenCategories"
                  />
                </div>
              </TransitionGroup>
            </ul>
          </Transition>
        </li>
      </ul>
    </Transition>
  </li>
</template>
