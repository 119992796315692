<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import {
  DataPointRequestStatusEnum,
  type PgRepositoryQuery,
  type RepositoryFile,
} from '@/__generated__/types';
import useGenerateFileDownloadTokenMutation from '@/api/mutations/RepositoryFile/generateFileDownloadToken.mutation';
import fileImg from '@/assets/file.svg';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import PG_REPOSITORY_QUERY from './PgRepository.query';

const router = useRouter();

const adminMode = inject(
  'adminMode',
  computed(() => false),
);

const { result, loading: queryLoading } = useQuery<PgRepositoryQuery>(
  PG_REPOSITORY_QUERY,
  () => ({
    adminMode: adminMode.value,
  }),
  {
    fetchPolicy: 'network-only',
  },
);

const { t } = useI18n();

const { mutate: generateFileDownloadURLMutation } =
  useGenerateFileDownloadTokenMutation();

const downloadFile = async (file: Pick<RepositoryFile, '_id'>) => {
  const mutationResult = await generateFileDownloadURLMutation({
    repositoryFileId: file._id,
  });

  window.open(mutationResult?.data?.generateFileDownloadToken);
};

const repositoryFiles = computed(
  () =>
    (adminMode.value
      ? result.value?.getEntityRepositoryFilesAdmin
      : result.value?.getEntityRepositoryFiles) ?? [],
);
const filteredRepositoryFiles = computed(() =>
  repositoryFiles.value.filter((file) =>
    file.dataPointRequests.some((dpr) => {
      const isApproved = dpr.status === DataPointRequestStatusEnum.ACCEPTED;
      return isApproved;
    }),
  ),
);

const headers = computed(() => [
  { text: t('Datapoint'), value: 'friendlyName' },
  { text: t('Category'), value: 'category', sortable: true, filterable: true },
  {
    text: t('Subcategory'),
    value: 'subcategory',
    sortable: true,
    filterable: true,
  },

  ...(adminMode.value
    ? [
        {
          text: t('Workspace'),
          value: 'entity',
          sortable: true,
          filterable: true,
        },
      ]
    : []),

  { text: t('Project'), value: 'location', sortable: true, filterable: true },
  { text: t('Period'), value: 'period', sortable: true, filterable: true },
  { text: t('Download'), value: 'download' },
]);

const items = computed(() =>
  filteredRepositoryFiles.value.flatMap((file) => {
    return file.dataPointRequests.map((dpr) => {
      return {
        friendlyName: t(dpr.dataPointType.friendlyName),
        category: t(dpr.dataPointTypeFlat.category),
        subcategory: t(dpr.dataPointTypeFlat.subcategory),
        ...(adminMode.value
          ? {
              entity: t(file.entity?.name ?? ''),
            }
          : {}),
        location: t(dpr.location.name),
        period: getDPRDateString(dpr?.from, dpr?.to),
        fileName: file.filename,
        onFileClick: () => {
          if (!file._id) return;
          return downloadFile(file);
        },
      };
    });
  }),
);
</script>

<template>
  <div class="pt-5 px-6 flex flex-col">
    <div class="flex-1">
      <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
        {{ t('File Repository') }}
      </h1>
      <p class="mb-6 mt-3 text-sm text-gray-500">
        {{
          t('Find all uploaded proof documents and files from your team here.')
        }}
      </p>

      <template v-if="!queryLoading">
        <MlEmptyStateCard
          v-if="!filteredRepositoryFiles.length"
          :title="t('No files yet')"
          :description="
            t('Start by entering data and uploading proof documents.')
          "
          :buttonText="t('Enter data')"
          @buttonClick="router.push({ name: 'projects' })"
        >
          <img class="mb-7" :src="fileImg" alt="Graph" />
        </MlEmptyStateCard>

        <OgDataTable v-else :headers="headers" :items="items">
          <template #item-download="{ fileName, onFileClick }">
            <AtButton
              variant="link"
              data-cy="dataPointFile"
              @click="onFileClick"
            >
              {{ fileName }}
            </AtButton>
          </template>
        </OgDataTable>
      </template>
    </div>
  </div>
</template>
