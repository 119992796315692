import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdateExternallyTaxonomyAssessmentBusinessActivityMutation,
  UpdateExternallyTaxonomyAssessmentBusinessActivityMutationVariables,
} from '@/__generated__/types';

const UPDATE_EXTERNALLY_TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITY_MUTATION = gql`
  mutation updateExternallyTaxonomyAssessmentBusinessActivity(
    $email: String!
    $shareToken: String!
    $input: UpdateExternallyTaxonomyAssessmentBusinessActivityInput!
  ) {
    updateExternallyTaxonomyAssessmentBusinessActivity(
      email: $email
      shareToken: $shareToken
      input: $input
    )
  }
`;

type TOptions = UseMutationOptions<
  UpdateExternallyTaxonomyAssessmentBusinessActivityMutation,
  UpdateExternallyTaxonomyAssessmentBusinessActivityMutationVariables
>;

const useExternallyUpdateTaxonomyAssessmentBusinessActivity = (
  options?: TOptions,
) =>
  useMutation<
    UpdateExternallyTaxonomyAssessmentBusinessActivityMutation,
    UpdateExternallyTaxonomyAssessmentBusinessActivityMutationVariables
  >(UPDATE_EXTERNALLY_TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITY_MUTATION, options);

export default useExternallyUpdateTaxonomyAssessmentBusinessActivity;
