import { DataPointTypeValueUnitEnum } from '@/__generated__/types';

export const unitGroups: Record<string, string>[] = [
  {
    [DataPointTypeValueUnitEnum.LITER]: 'Liter (l)',
    [DataPointTypeValueUnitEnum.MEGA_LITER]: 'MegaLiter (Ml)',
    [DataPointTypeValueUnitEnum.CUBIC_METER]: 'Cubic meter (m3)',
  },
  {
    [DataPointTypeValueUnitEnum.KILOGRAM]: 'Kilogram (kg)',
    [DataPointTypeValueUnitEnum.TONS]: 'Ton (t)',
  },
  {
    [DataPointTypeValueUnitEnum.KILOWATTHOURS]: 'Kilowatt-hour (kWh)',
    [DataPointTypeValueUnitEnum.MEGAWATTHOURS]: 'Megawatt-hour (MWh)',
  },
  {
    [DataPointTypeValueUnitEnum.KILOWATT]: 'Kilowatt (kW)',
  },
  {
    [DataPointTypeValueUnitEnum.METER]: 'Meter (m)',
    [DataPointTypeValueUnitEnum.KILOMETER]: 'Kilometer (km)',
  },
  {
    [DataPointTypeValueUnitEnum.HOURS]: 'Hour (h)',
    [DataPointTypeValueUnitEnum.DAYS]: 'Day (d)',
  },
  {
    [DataPointTypeValueUnitEnum.EUR]: 'Euro (EUR)',
    [DataPointTypeValueUnitEnum.GBP]: 'British pound sterling (GBP)',
    [DataPointTypeValueUnitEnum.CHF]: 'Swiss Franc (CHF)',
    [DataPointTypeValueUnitEnum.USD]: 'US Dollar (USD)',
  },
];

export const getAlternativeUnits = (unit: string) => {
  return (
    unitGroups.find((group) =>
      Object.keys(group).some((valueUnit) => valueUnit === unit),
    ) ?? {}
  );
};
