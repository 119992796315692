<script setup lang="ts">
import { XIcon } from '@heroicons/vue/solid';
import { useI18n } from 'vue-i18n';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import type { TTooltipOptions } from '@/components/atoms/AtTooltipIcon.vue';

const { t } = useI18n();

const props = defineProps<Props>();
type Props = {
  modelValue: string | boolean;
  optionA: string | boolean;
  optionB: string | boolean;
  label?: string;
  tooltip?: TTooltipOptions;
  wrapperClass?: string;
};

const emit = defineEmits<{
  (e: 'update:modelValue', optionSelected: string | boolean): void;
}>();

function select(optionSelected: string | boolean) {
  emit('update:modelValue', optionSelected);
}
</script>

<template>
  <div :class="props.wrapperClass">
    <h5 v-if="props.label" class="mb-1 text-sm font-medium text-gray-700">
      <span>
        {{ props.label }}
      </span>
      <AtTooltipIcon
        v-if="props.tooltip"
        class="ml-1 cursor-pointer"
        :triggers="['click', 'touch']"
        :delay="0"
        autoHide
        @click.stop
      >
        <template #tooltip="{ hide }">
          <div class="whitespace-pre-line">
            <AtIconButton
              class="float-right"
              :icon="XIcon"
              :title="t('Close')"
              @click="hide"
            />
            {{ props.tooltip }}
          </div>
        </template>
      </AtTooltipIcon>
    </h5>
    <div class="flex cursor-pointer p-0 text-sm">
      <button
        class="rounded-l"
        :class="{
          'border-primary text-primary bg-blue-50 py-2 px-6 border':
            props.modelValue === props.optionA,
          'text-gray-900 border-gray-400 bg-white py-2 px-6 border':
            props.modelValue === props.optionB,
        }"
        @click.stop="select(props.optionA)"
      >
        <slot name="optionA">
          {{ t(typeof props.optionA === 'string' ? props.optionA : '') }}
        </slot>
      </button>
      <button
        class="rounded-r"
        :class="{
          'border-primary text-primary bg-blue-50 py-2 px-6 border':
            props.modelValue === props.optionB,
          'text-gray-900 border-gray-400 bg-white py-2 px-6 border':
            props.modelValue === props.optionA,
        }"
        @click.stop="select(props.optionB)"
      >
        <slot name="optionB">
          {{ t(typeof props.optionB === 'string' ? props.optionB : '') }}
        </slot>
      </button>
    </div>
  </div>
</template>
