<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  FilterIcon,
  XIcon,
} from '@heroicons/vue/outline';
import uniqBy from 'lodash/uniqBy';
import capitalize from 'lodash/capitalize';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import { DataPointRequestStatusEnum } from '@/__generated__/types';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import { useFetchDprs } from './composables/useFetchDprs';
import { useRoutesToDataEntry } from './composables/useRoutesToDataEntry';
import type { EntityAssignment } from './types';

const { t } = useI18n();
const props = defineProps<{
  wrapperClass: string;
  entityAssignments: EntityAssignment[];
}>();

const {
  getPathToLocation: getDataEntryDataEntryPathToLocation,
  getPathToCategory: getDataEntryPathToCategory,
  getPathToSubcategory: getDataEntryPathToSubcategory,
  getPathToDpr: getDataEntryPathToDpr,
} = useRoutesToDataEntry();

const {
  dprs,
  resetDprs,
  fetchDprs,
  canFetchMore,
  fetchMoreDprs,
  isLoadingDprs,
} = useFetchDprs();

const rowsPerPage = ref(10);
const activeRows = reactive<Record<string, boolean>>({});

const isActiveRow = (
  userId: string,
  locationId: string,
  userSubcategoryKey: string,
) => {
  return activeRows[`${userId}__${locationId}__${userSubcategoryKey}`];
};

function resetActiveRows() {
  resetDprs();
  for (const [k, v] of Object.entries(activeRows)) {
    if (v) {
      activeRows[k] = false;
    }
  }
}

const items = computed(() =>
  props.entityAssignments?.map((assignment) => {
    return {
      userId: assignment.assignee._id,
      userName: assignment.assignee.firstName
        ? `${assignment.assignee.firstName} ${assignment.assignee.lastName}`
        : `${assignment.assignee.email}`,
      userProjectId: assignment.location._id,
      userProject: assignment.location.name,
      userCategory: t(assignment.category),
      userCategoryKey: assignment.category,
      userSubcategory: t(assignment.subcategory),
      userSubcategoryKey: assignment.subcategory,
      hasOpenDprs:
        assignment.statuses?.includes(DataPointRequestStatusEnum.OPEN) ||
        assignment.statuses?.includes(DataPointRequestStatusEnum.DRAFT),
      onClickOpenData: (locationId: string) => {
        if (locationId !== assignment.location._id) return;

        resetActiveRows();
        activeRows[
          `${assignment.assignee._id}__${assignment.location._id}__${assignment.subcategory}`
        ] = true;

        fetchDprs(
          assignment.assignee._id,
          assignment.location._id,
          assignment.category,
          assignment.subcategory,
          DataPointRequestStatusEnum.OPEN,
        );
      },

      onClickOpenMoreData: () => {
        fetchMoreDprs(
          10,
          assignment.assignee._id,
          assignment.location._id,
          assignment.category,
          assignment.subcategory,
          DataPointRequestStatusEnum.OPEN,
        );
      },
    };
  }),
);

const filteredAndSortedItems = ref(items.value);
watch(items, () => {
  filteredAndSortedItems.value = items.value;
});

const formattedItems = ref(filteredAndSortedItems.value);
watch(filteredAndSortedItems, () => {
  resetDprs();
  formattedItems.value = filteredAndSortedItems.value.map(
    (assignment, index) => {
      const previousAssignment = filteredAndSortedItems.value[index - 1];
      const isPrevSameAssignee =
        previousAssignment && index % rowsPerPage.value !== 0 // modulo expression to always show first row regardless of pagination
          ? previousAssignment.userId === assignment.userId
          : false;

      const isPrevSameAssigneeAndLocation =
        isPrevSameAssignee && previousAssignment
          ? previousAssignment.userProjectId === assignment.userProjectId
          : false;

      const isPrevSameAssigneeAndLocationAndCategory =
        isPrevSameAssigneeAndLocation && previousAssignment
          ? previousAssignment.userCategory === assignment.userCategory
          : false;

      return {
        ...assignment,
        ...(isPrevSameAssignee ? { userName: '' } : {}),
        ...(isPrevSameAssigneeAndLocation ? { userProject: '' } : {}),
        ...(isPrevSameAssigneeAndLocationAndCategory
          ? { userCategory: '' }
          : {}),
      };
    },
  );
});

const headers = [
  {
    text: t('User'),
    value: 'userName',
    sortable: false,
    filterable: true,
    width: 150,
  },
  {
    text: t('Project'),
    value: 'userProject',
    sortable: false,
    filterable: true,
    width: 150,
  },
  {
    text: t('Topic'),
    value: 'userCategory',
    sortable: false,
    filterable: true,
    width: 200,
  },
  {
    text: t('Sub-topic'),
    value: 'userSubcategory',
    sortable: false,
    filterable: true,
  },
];

const userNameCriteria = ref<string[]>([]);
const userProjectCriteria = ref<string[]>([]);
const userCategoryCriteria = ref<string[]>([]);
const userSubcategoryCriteria = ref<string[]>([]);
const showUserNameFilter = ref(false);
const showUserProjectFilter = ref(false);
const showUserCategoryFilter = ref(false);
const showUserSubcategoryFilter = ref(false);
watch(
  [
    userNameCriteria,
    userProjectCriteria,
    userCategoryCriteria,
    userSubcategoryCriteria,
    showUserNameFilter,
    showUserProjectFilter,
    showUserCategoryFilter,
    showUserSubcategoryFilter,
  ],
  () => {
    filteredAndSortedItems.value = items.value.filter((item) => {
      return (
        (userNameCriteria.value.includes(item.userName) ||
          !userNameCriteria.value.length) &&
        (userProjectCriteria.value.includes(item.userProject) ||
          !userProjectCriteria.value.length) &&
        (userCategoryCriteria.value.includes(item.userCategory) ||
          !userCategoryCriteria.value.length) &&
        (userSubcategoryCriteria.value.includes(item.userSubcategory) ||
          !userSubcategoryCriteria.value.length)
      );
    });

    if (
      userNameCriteria.value.length ||
      userProjectCriteria.value.length ||
      userCategoryCriteria.value.length ||
      userSubcategoryCriteria.value.length
    ) {
      const paginationButtons = document.querySelectorAll(
        '.buttons-pagination',
      )[0];
      (paginationButtons.firstElementChild as HTMLButtonElement).click();
    }
  },
);
</script>

<template>
  <OgDataTable
    :headers="headers"
    :items="formattedItems"
    :wrapperClass="wrapperClass"
    :rowsPerPage="rowsPerPage"
    class="!mt-0"
    variant="light-gray"
    controlsHidden
    hasSubRows
    hasInnerHeaderFilters
    :preventContextMenuRow="false"
  >
    <template #header-userName="header">
      <div class="flex relative items-center">
        <div class="">
          {{ header.text }}
        </div>
        <button
          class="flex items-center ml-2 hover:text-primary hover:cursor-pointer"
          :class="{
            'text-primary': userNameCriteria.length,
          }"
          :title="t('Filter')"
          @click.stop="showUserNameFilter = !showUserNameFilter"
        >
          <FilterIcon class="w-4 hover:scale-105" />
        </button>
        <MlSelect
          v-if="showUserNameFilter"
          v-model="userNameCriteria"
          multiple
          usePortal
          class="text-xs font-normal min-w-60 max-w-80 ml-2 truncate"
          :class="{
            'text-primary border-primary': userNameCriteria.length,
          }"
          :options="
            items.reduce(
              (acc, curr) => {
                acc[curr.userName] = curr.userName;
                return acc;
              },
              {} as Record<string, string>,
            )
          "
        />
        <button
          v-if="showUserNameFilter || userNameCriteria.length"
          :title="t('Clear')"
          @click.stop="
            userNameCriteria = [];
            showUserNameFilter = false;
          "
        >
          <XIcon class="w-4 ml-2 cursor-pointer hover:text-primary" />
        </button>
      </div>
    </template>
    <template #header-userProject="header">
      <div class="flex relative items-center">
        <div class="">
          {{ header.text }}
        </div>
        <button
          class="flex items-center ml-2 hover:text-primary hover:cursor-pointer"
          :class="{
            'text-primary': userProjectCriteria.length,
          }"
          :title="t('Filter')"
          @click.stop="showUserProjectFilter = !showUserProjectFilter"
        >
          <FilterIcon class="w-4 hover:scale-105" />
        </button>
        <MlSelect
          v-if="showUserProjectFilter"
          v-model="userProjectCriteria"
          multiple
          usePortal
          class="text-xs font-normal min-w-60 max-w-80 ml-2 truncate"
          :class="{
            'text-primary border-primary': userProjectCriteria.length,
          }"
          :options="
            items.reduce(
              (acc, curr) => {
                acc[curr.userProject] = curr.userProject;
                return acc;
              },
              {} as Record<string, string>,
            )
          "
        />
        <button
          v-if="showUserProjectFilter || userProjectCriteria.length"
          :title="t('Clear')"
          @click.stop="
            userProjectCriteria = [];
            showUserProjectFilter = false;
          "
        >
          <XIcon class="w-4 ml-2 cursor-pointer hover:text-primary" />
        </button>
      </div>
    </template>
    <template #header-userCategory="header">
      <div class="flex relative items-center">
        <div class="">
          {{ header.text }}
        </div>
        <button
          class="flex items-center ml-2 hover:text-primary hover:cursor-pointer"
          :class="{
            'text-primary': userCategoryCriteria.length,
          }"
          :title="t('Filter')"
          @click.stop="showUserCategoryFilter = !showUserCategoryFilter"
        >
          <FilterIcon class="w-4 hover:scale-105" />
        </button>
        <MlSelect
          v-if="showUserCategoryFilter"
          v-model="userCategoryCriteria"
          multiple
          usePortal
          class="text-xs font-normal min-w-60 max-w-80 ml-2 truncate"
          :class="{
            'text-primary border-primary': userCategoryCriteria.length,
          }"
          :options="
            items.reduce(
              (acc, curr) => {
                acc[curr.userCategory] = curr.userCategory;
                return acc;
              },
              {} as Record<string, string>,
            )
          "
        />
        <button
          v-if="showUserCategoryFilter || userCategoryCriteria.length"
          :title="t('Clear')"
          @click.stop="
            userCategoryCriteria = [];
            showUserCategoryFilter = false;
          "
        >
          <XIcon class="w-4 ml-2 cursor-pointer hover:text-primary" />
        </button>
      </div>
    </template>
    <template #header-userSubcategory="header">
      <div class="flex relative items-center">
        <div class="">
          {{ header.text }}
        </div>
        <button
          class="flex items-center ml-2 hover:text-primary hover:cursor-pointer"
          :class="{
            'text-primary': userSubcategoryCriteria.length,
          }"
          :title="t('Filter')"
          @click.stop="showUserSubcategoryFilter = !showUserSubcategoryFilter"
        >
          <FilterIcon class="w-4 hover:scale-105" />
        </button>
        <MlSelect
          v-if="showUserSubcategoryFilter"
          v-model="userSubcategoryCriteria"
          multiple
          usePortal
          class="text-xs font-normal min-w-60 max-w-xl ml-2 truncate"
          :class="{
            'text-primary border-primary': userSubcategoryCriteria.length,
          }"
          :options="
            items.reduce(
              (acc, curr) => {
                acc[curr.userSubcategory] = curr.userSubcategory;
                return acc;
              },
              {} as Record<string, string>,
            )
          "
        />
        <button
          v-if="showUserSubcategoryFilter || userSubcategoryCriteria.length"
          :title="t('Clear')"
          @click.stop="
            userSubcategoryCriteria = [];
            showUserSubcategoryFilter = false;
          "
        >
          <XIcon class="w-4 ml-2 cursor-pointer hover:text-primary" />
        </button>
      </div>
    </template>
    <template #item-userName="{ userName }">
      {{ userName }}
    </template>
    <template #item-userProject="{ userProject, userProjectId }">
      <a
        v-if="userProject"
        class="hover:text-primary text-justify w-fully"
        rel="noopener noreferrer"
        :href="getDataEntryDataEntryPathToLocation(userProjectId)"
      >
        {{ userProject }}
      </a>
    </template>
    <template
      #item-userCategory="{ userCategory, userProjectId, userCategoryKey }"
    >
      <a
        v-if="userCategory"
        class="hover:text-primary text-justify w-full break-words"
        rel="noopener noreferrer"
        :href="getDataEntryPathToCategory(userProjectId, userCategoryKey)"
      >
        {{ t(userCategory) }}
      </a>
    </template>
    <template
      #item-userSubcategory="{
        userId,
        userProjectId,
        userCategoryKey,
        userSubcategoryKey,
        userSubcategory,
        onClickOpenData,
        onClickOpenMoreData,
        hasOpenDprs,
      }"
    >
      <a
        v-if="userSubcategory"
        class="hover:text-primary text-justify w-full break-words"
        rel="noopener noreferrer"
        :href="
          getDataEntryPathToSubcategory(
            userProjectId,
            userCategoryKey,
            userSubcategoryKey,
          )
        "
      >
        <span>
          {{ userSubcategory }}
        </span>

        <AtBadge v-if="!hasOpenDprs" type="EMERALD" class="ml-2">
          {{ capitalize(t('Done')) }}
        </AtBadge>
      </a>
      <div v-show="hasOpenDprs">
        <div class="flex w-full items-end justify-end">
          <AtButton
            variant="text"
            :loading="
              isLoadingDprs &&
              isActiveRow(userId, userProjectId, userSubcategoryKey)
            "
            :icon="
              isActiveRow(userId, userProjectId, userSubcategoryKey)
                ? ChevronUpIcon
                : ChevronDownIcon
            "
            class="text-primary"
            @click.stop="
              isActiveRow(userId, userProjectId, userSubcategoryKey)
                ? resetActiveRows()
                : onClickOpenData(userProjectId)
            "
          >
            {{
              isActiveRow(userId, userProjectId, userSubcategoryKey)
                ? t('Close all open data')
                : t('Show all open data')
            }}
          </AtButton>
        </div>
        <ul
          v-if="isActiveRow(userId, userProjectId, userSubcategoryKey)"
          class="w-full"
        >
          <li
            v-for="dpr in uniqBy(dprs, 'dataPointType._id')"
            :key="dpr._id"
            class="mb-3 text-xs"
          >
            <a
              class="flex items-center cursor-pointer"
              rel="noopener noreferrer"
              :href="
                getDataEntryPathToDpr(
                  dpr.location._id,
                  dpr.dataPointTypeFlat.category,
                  dpr.dataPointTypeFlat.subcategory,
                  dpr.dataPointType._id,
                  dpr._id,
                )
              "
            >
              <div class="w-1 h-1 bg-gray-900 rounded inline-block mx-2" />
              <div
                class="text-gray-900 hover:text-blue-600 hover:underline rounded-sm"
              >
                {{ t(dpr.dataPointType.friendlyName) }}
                {{ dpr.isHistoric ? new Date(dpr.from).getFullYear() : '' }}
              </div>
            </a>
          </li>
        </ul>
        <button
          v-if="
            isActiveRow(userId, userProjectId, userSubcategoryKey) &&
            canFetchMore
          "
          class="bg-gray-50 hover:bg-gray-200 rounded cursor-pointer text-center py-1 w-full"
          @click.prevent="onClickOpenMoreData"
        >
          {{ t('Load more...') }}
        </button>
      </div>
    </template>
  </OgDataTable>
</template>
