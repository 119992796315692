import { gql } from '@apollo/client/core';

export default gql`
  query PgAuditTrailGetHistory(
    $entityIds: [String!]
    $dateFrom: String
    $dateTo: String
  ) {
    getHistoryAsXSLX(
      entityIds: $entityIds
      dateFrom: $dateFrom
      dateTo: $dateTo
    )
  }
`;
