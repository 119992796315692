<script setup lang="ts">
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import { InformationCircleIcon } from '@heroicons/vue/solid';
import type { TaxonomyAssessmentBusinessActivityObjectiveEnum } from '@/__generated__/types';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import useUpdateTaxonomyAssessmentBusinessActivity from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import OgDocumentComment from '../OgDocumentComment.vue';

const props = defineProps<{
  assessmentId: string;
  businessActivityId: string;
  objective: TaxonomyAssessmentBusinessActivityObjectiveEnum | null;
  description: string;
  initialFile: TPartialRepositoryFile | null;
  initialComment: string | null;
}>();

const emit = defineEmits<{
  close: [];
  forward: [];
  answered: [];
}>();

const { t, locale } = useI18n();

const { loading: isSaving, mutate: updateTaxonomyAssessmentBusinessActivity } =
  useUpdateTaxonomyAssessmentBusinessActivity();

const comment = ref('');
const file = ref<TPartialRepositoryFile | null>(null);

watch(
  () => props.initialFile,
  (newInitialFile) => {
    file.value = newInitialFile;
  },
  { immediate: true },
);
watch(
  () => props.initialComment,
  (newInitialComment) => {
    comment.value = newInitialComment ?? '';
  },
  { immediate: true },
);

async function handleAnswer(isConfirmed: boolean) {
  try {
    await updateTaxonomyAssessmentBusinessActivity({
      assessmentId: props.assessmentId,
      businessActivityId: props.businessActivityId,
      input: {
        objective: isConfirmed ? props.objective : null,
        objectiveComment: comment.value ?? null,
        objectiveFileId: file.value?._id ?? null,
      },
    });
    emit('answered');
  } catch {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
}

function handleClose() {
  emit('close');
}

function handleForwarding() {
  emit('forward');
}

const isOriginalDescriptionShown = ref(false);
</script>

<template>
  <MlDialog isOpen :closeOnOutside="false" @close="handleClose">
    <template #title>
      {{ t('Confirm conditions for significant contribution') }}
    </template>

    <template #default>
      <div
        v-if="locale === 'de'"
        class="bg-blue-50 p-2 mt-10 rounded text-gray-900"
      >
        <div
          class="whitespace-pre-line break-normal group hover:cursor-pointer p-0 text-xs"
          @click.stop="isOriginalDescriptionShown = !isOriginalDescriptionShown"
        >
          <section class="border-blue-600 border p-2 rounded -m-2">
            <InformationCircleIcon
              class="w-5 float-left text-primary shrink-0 mr-2 self-start"
            />
            <span class="group-hover:text-primary">
              Bitte beachten Sie, dass der folgende Text maschinell übersetzt
              wurde. Wenn Sie den Originaltext auf Englisch sehen möchten,
              klicken Sie bitte hier.
            </span>
          </section>
          <section
            v-if="isOriginalDescriptionShown"
            class="whitespace-pre-line break-normal mt-3 text-gray-700 p-2"
          >
            <MlHtmlContent
              :html="
                description.replace(
                  /([0-9]+\.|[0-9]+\.[0-9]+\.|[0-9]+\.[0-9]+\.[0-9]+\.)\s/g,
                  '\n$&',
                )
              "
            />
          </section>
        </div>
      </div>
      <div class="pt-10 mb-6" :class="{ 'pt-4': locale === 'de' }">
        <p class="whitespace-pre-line break-normal">
          <MlHtmlContent
            :html="
              t(description).replace(
                /([0-9]+\.|[0-9]+\.[0-9]+\.|[0-9]+\.[0-9]+\.[0-9]+\.)\s/g,
                '\n$&',
              )
            "
          />
        </p>
      </div>
      <div class="mb-6">
        <OgDocumentComment
          v-model:comment="comment"
          v-model:file="file"
          showRecentFiles
        />
      </div>
      <div class="flex items-center justify-between space-x-4">
        <div class="flex-1 flex items-center space-x-4">
          <AtButton
            type="button"
            variant="outline"
            @click="handleAnswer(false)"
          >
            {{ t("No, I don't confirm") }}
          </AtButton>
          <MlMenu>
            <AtButton type="button" variant="text" :disabled="isSaving">
              {{ t('More actions') }}
            </AtButton>
            <template #menuItems>
              <AtMenuItem
                class="!w-44 text-left"
                :disabled="isSaving"
                @click="handleForwarding"
              >
                {{ t('Forward or assign as questionnaire') }}
              </AtMenuItem>
            </template>
          </MlMenu>
        </div>
        <div class="flex-0">
          <AtButton
            type="button"
            variant="default"
            :loading="isSaving"
            :disabled="isSaving"
            @click="handleAnswer(true)"
          >
            {{ t('Yes, I confirm') }}
          </AtButton>
        </div>
      </div>
    </template>
  </MlDialog>
</template>
