<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { PlusIcon } from '@heroicons/vue/solid';
import { FilterIcon } from '@heroicons/vue/outline';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import type { TDataTableFilter, TServerDataTableHeader } from './types';
import MlServerFilterItem from './MlServerFilterItem.vue';

type Props = {
  modelValue: TDataTableFilter[];
  headers: TServerDataTableHeader[];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: Props['modelValue']];
}>();

const { t } = useI18n();

const selectedFiltersValues = computed(() =>
  props.modelValue
    .flatMap(({ criteria, field }) => {
      const headerItem = props.headers.find((item) => item.value === field);
      if (headerItem?.filterable) {
        return criteria.map((item) => headerItem.filterOptions[item] ?? item);
      }

      return criteria;
    })
    .join(', '),
);
const canFilter = computed(() =>
  props.modelValue.some((filter) => filter.criteria.length > 0),
);
const selectedFilters = computed<string[]>(() =>
  props.modelValue.map((item) => item.field),
);
const isEveryFilterSelected = computed(
  () =>
    selectedFilters.value.length ===
    props.headers.filter((item) => item.filterable).length,
);

function handleFilterChange(newValue: TDataTableFilter, index: number) {
  emit(
    'update:modelValue',
    props.modelValue.map((item, itemIndex) => {
      if (itemIndex === index) {
        return newValue;
      }

      return item;
    }),
  );
}

function handleAddFilter() {
  emit('update:modelValue', [
    ...props.modelValue,
    {
      field: '',
      criteria: [],
    },
  ]);
}

function handleRemoveFilter(toRemoveIndex: number) {
  emit(
    'update:modelValue',
    props.modelValue.filter((_, index) => index !== toRemoveIndex),
  );
}

function resetFilters() {
  emit('update:modelValue', [
    {
      field: '',
      criteria: [],
    },
  ]);
}
</script>

<template>
  <MlMenu>
    <div>
      <AtButton v-if="!canFilter" v-close-popper variant="text" class="pl-0">
        <FilterIcon class="h-4 pr-2" />
        {{ t('Filter') }}
      </AtButton>
      <button
        v-else
        v-close-popper
        class="flex items-center rounded bg-blue-50 px-3 py-1 text-sm text-blue-600"
        type="submit"
      >
        <FilterIcon class="h-4 pr-2" />
        {{ t('Filter') }}:
        {{ selectedFiltersValues }}
      </button>
    </div>

    <template #menuItems>
      <div class="p-6">
        <MlServerFilterItem
          v-for="(filter, index) in modelValue"
          :key="index"
          :modelValue="filter"
          :headers="headers"
          :selectedFilters="selectedFilters"
          sortedOptions
          @remove="handleRemoveFilter(index)"
          @update:modelValue="handleFilterChange($event, index)"
        />
        <div class="flex gap-6">
          <AtButton
            class="mr-auto"
            variant="text"
            :icon="PlusIcon"
            :disabled="isEveryFilterSelected"
            @click="handleAddFilter"
          >
            {{ t('Add Filter') }}
          </AtButton>
          <AtButton v-close-popper variant="text" @click="resetFilters">
            {{ t('Clear') }}
          </AtButton>
        </div>
      </div>
    </template>
  </MlMenu>
</template>
