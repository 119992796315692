<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import UserAddIcon from '@heroicons/vue/outline/UserAddIcon';
import { DoubleMaterialityDownloadDocumentEnum } from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { useStore, useStoreMethods } from '../../store';
import { useDocumentDownload } from '../../composables/useDocumentDownload';
import AtEmptyCard from '../../AtEmptyCard.vue';
import OgStakeholders from './OgStakeholders.vue';

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const {
  addInitialStakeholders,
  addDefaultStakeholderOptions,
  addDefaultStakeholderEngageOptions,
} = useStoreMethods();

const stakeholderEngagementDownload = useDocumentDownload(
  DoubleMaterialityDownloadDocumentEnum.STAKEHOLDER_ENGAGEMENT,
);

const showTable = computed(() => store.value.stakeholders.length > 0);

function handleCreateClick() {
  if (store.value.stakeholderOptions.length === 0) {
    addDefaultStakeholderOptions();
  }

  if (store.value.stakeholderEngageOptions.length === 0) {
    addDefaultStakeholderEngageOptions();
  }

  addInitialStakeholders();
}
</script>

<template>
  <template v-if="!showTable">
    <AtEmptyCard
      variant="default"
      :icon="UserAddIcon"
      :title="t('No stakeholders yet')"
      :primaryAction="{
        label: t('Create list of stakeholders'),
        onClick: handleCreateClick,
      }"
      :secondaryActions="[
        {
          label: t('Download supporting guidance'),
          loading: stakeholderEngagementDownload.loading.value,
          onClick: stakeholderEngagementDownload.exportFile,
        },
      ]"
    >
      <p>
        {{
          t(
            'Stakeholders are individuals, groups, or organizations that can influence or be influenced by the company and its actions. They can be both internal and external to the company. The ESRS divides stakeholders into two main categories:',
          )
        }}
      </p>
      <p>
        {{
          t(
            "(1) affected stakeholders - those whose interests are impacted, either positively or negatively, by the company's activities and business relationships throughout its value chain, and",
          )
        }}
      </p>
      <p class="mb-6">
        {{
          t(
            '(2) users of sustainability statements - including primary users of general financial reports (like investors, asset managers, insurance entities) and other users like business partners, trade unions, civil society, NGOs, governments, academics, and more.',
          )
        }}
      </p>
      <p>
        {{
          t(
            'Create your list of relevant stakeholders for the double materiality assessment by clicking below.',
          )
        }}
      </p>
    </AtEmptyCard>

    <div class="mt-auto flex items-center justify-between">
      <AtButton
        type="button"
        variant="outline"
        @click="
          router.push({
            name: 'doubleMaterialityIROsScoreRisksAndOpportunities',
          })
        "
      >
        {{ t('Go back') }}
      </AtButton>
      <AtButton
        type="button"
        @click="router.push({ name: 'doubleMaterialityStakeholdersSurveys' })"
      >
        {{ t('Next') }}
      </AtButton>
    </div>
  </template>

  <OgStakeholders v-else />
</template>
