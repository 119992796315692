import { notify } from '@kyvg/vue3-notification';
import { ApolloError } from '@apollo/client/core';
import { useI18n } from 'vue-i18n';
import useConfirmViaDialog, {
  type TDialogData,
} from '@/utils/composables/useConfirmViaDialog';
import useDeleteTaxonomyDocumentMutation from '@/api/mutations/Taxonomy/useDeleteTaxonomyDocument.mutation';

export const useDeleteRepositoryFile = (
  ...mutationOptions: Parameters<typeof useDeleteTaxonomyDocumentMutation>
) => {
  const { t } = useI18n();

  const { mutate, loading: deleteDocumentLoading } =
    useDeleteTaxonomyDocumentMutation(...mutationOptions);

  const { confirmViaDialog } = useConfirmViaDialog();

  const deleteRepositoryFile = async (
    id: string,
    dialogOptions = {} as TDialogData,
  ) => {
    const isConfirmed = await confirmViaDialog({
      title: t('Are you sure you want to delete this document?'),
      confirmLabel: t('Delete'),
      cancelLabel: t('Cancel'),
      confirmButtonVariant: 'destructive',
      ...dialogOptions,
    });

    if (!isConfirmed) return;

    try {
      return mutate(
        { taxonomyDocumentId: id },
        {
          update: (store) => store.evict({ fieldName: 'getTaxonomyProjects' }),
        },
      );
    } catch (e) {
      if (e instanceof ApolloError) {
        notify({
          type: 'error',
          text: t(e.message),
          duration: 10000,
        });
      }
    }
  };

  return { deleteRepositoryFile, deleteDocumentLoading };
};
