import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DeleteDataPointRequestsMutation,
  DeleteDataPointRequestsMutationVariables,
} from '@/__generated__/types';

export const DELETE_DATAPOINT_REQUESTS = gql`
  mutation DeleteDataPointRequests(
    $deleteDataPointRequestsInput: DeleteDataPointRequestsInput!
  ) {
    deleteDataPointRequests(
      deleteDataPointRequestsInput: $deleteDataPointRequestsInput
    )
  }
`;

type TOptions = UseMutationOptions<
  DeleteDataPointRequestsMutation,
  DeleteDataPointRequestsMutationVariables
>;

const useDeleteDataPointRequestsMutation = (options?: TOptions) =>
  useMutation<
    DeleteDataPointRequestsMutation,
    DeleteDataPointRequestsMutationVariables
  >(DELETE_DATAPOINT_REQUESTS, options);

export default useDeleteDataPointRequestsMutation;
