<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { UserLanguageEnum } from '@/__generated__/types';
import { i18n } from '@/lib/i18n';
import switchTranslation from '@/utils/helpers/switchTranslation';

const { locale } = useI18n();

function handleLocaleChange(
  newLocale: ArrayElement<typeof i18n.global.availableLocales>,
) {
  switchTranslation(locale, newLocale.toUpperCase() as UserLanguageEnum);
}
</script>

<template>
  <ul class="flex justify-center">
    <li
      v-for="availableLocale in i18n.global.availableLocales"
      :key="availableLocale"
      class="ml-2 h-full cursor-pointer border-l border-gray-200 pl-2 first:border-0"
      :class="{ 'text-primary': locale === availableLocale }"
      @click="handleLocaleChange(availableLocale)"
    >
      {{ availableLocale.toUpperCase() }}
    </li>
  </ul>
</template>
