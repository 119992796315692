<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { PgKpiQuery } from '@/__generated__/types';
import MlComment from './MlComment.vue';
import MlCommentInput from './MlCommentInput.vue';

const { t } = useI18n();

const props = defineProps<{
  kpi: PgKpiQuery['getEntityKPI'];
}>();

const emit = defineEmits(['created']);
</script>

<template>
  <div class="flex flex-col gap-3">
    {{ t('Comments') }}
    <MlComment
      v-for="comment in kpi.comments"
      :key="comment._id"
      :comment="comment"
    />
    <MlCommentInput
      :placeholder="t('Write a comment.')"
      :kpi="props.kpi"
      @created="emit('created')"
    />
  </div>
</template>
