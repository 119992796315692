<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { ErrorObject } from '@vuelidate/core';
import MlCountryInput from '@/components/molecules/MlCountryInput.vue';

type Props = {
  modelValue: string;
  errors?: string[] | ErrorObject[];
};

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
});
const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();
</script>

<template>
  <MlCountryInput
    :label="t('Country of Headquarters')"
    :errors="props.errors"
    :modelValue="props.modelValue"
    data-cy="companyDprCountrySelect"
    @update:modelValue="emit('update:modelValue', $event)"
  />
</template>
