<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { SortType } from 'vue3-easy-data-table';
import {
  SortAscendingIcon,
  SortDescendingIcon,
  SwitchVerticalIcon,
} from '@heroicons/vue/outline';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import type { TDataTableHeader, TDataTableSort } from './types';

class Sort {
  by = '';
  type: SortType | '' = '';
}

const props = defineProps<{
  headers: TDataTableHeader[];
  modelValue: TDataTableSort;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', sort: TDataTableSort): void;
}>();

const sortOptions = computed(() =>
  props.headers
    .filter((header) => header.sortable)
    .reduce<
      Record<string, string>
    >((acc, { text, value }) => ({ ...acc, [value]: text }), {}),
);

const sort = ref(new Sort());
watch(sort, () => emit('update:modelValue', sort.value), { deep: true });

const { t } = useI18n();
</script>

<template>
  <MlMenu>
    <div>
      <button
        v-if="!sort.type"
        v-close-popper
        class="text-sm font-medium items-end text-primary cursor-pointer flex"
      >
        <SwitchVerticalIcon class="h-4 mr-1 mb-0.5" />
        {{ t('Sort') }}
      </button>
      <button
        v-else
        v-close-popper
        class="flex items-center font-medium rounded bg-blue-50 px-3 py-1 text-sm text-primary"
        type="submit"
      >
        <SortDescendingIcon
          v-if="sort.type === 'desc'"
          class="h-4 pr-2 mb-0.5"
        />
        <SortAscendingIcon v-else class="h-4 pr-2 mb-0.5" />
        {{ t('Sort') }}:
        {{ sortOptions[sort.by] }}
      </button>
    </div>

    <template #menuItems>
      <div class="flex flex-col items-end p-6">
        <div class="mb-6 flex gap-6">
          <MlSelect
            v-model="sort.by"
            wrapperClass="w-56"
            :options="sortOptions"
          />
          <MlSelect
            v-model="sort.type"
            wrapperClass="w-56"
            :options="{ asc: 'Ascending', desc: 'Descending' }"
            :disabled="!sort.by"
          />
        </div>
        <AtButton v-close-popper variant="text" @click="sort = new Sort()">
          {{ t('Clear') }}
        </AtButton>
      </div>
    </template>
  </MlMenu>
</template>
