<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  TaxonomyAssessmentBusinessActivityObjectiveEnum,
  TaxonomyBusinessActivityDelegationTypeEnum,
} from '@/__generated__/types';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import type { BusinessActivity } from '../types';
import OgForwardingDialog from '../OgForwardingDialog/OgForwardingDialog.vue';
import OgConfirmationDialog from './OgConfirmationDialog.vue';

const props = defineProps<{
  assessmentId: string;
  businessActivity: BusinessActivity;
}>();

const { t } = useI18n();

const showConfirmation = ref(false);
const showForwardAssessment = ref(false);

const objectiveValue = ref<
  TaxonomyAssessmentBusinessActivityObjectiveEnum | undefined
>();
watch(
  () => props.businessActivity.objective,
  (newObjective) => {
    objectiveValue.value = newObjective ?? undefined;
  },
  { immediate: true },
);

const availableObjectives = computed(() =>
  props.businessActivity.activity.descriptions
    .filter((d) => d.key === 'contribution' && d.value)
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr.objective ?? '']: t(curr.objective ?? ''),
      }),
      {},
    ),
);

const description = computed(
  () =>
    props.businessActivity?.activity.descriptions.find(
      (d) => d.objective === objectiveValue.value && d.key === 'contribution',
    )?.value,
);
const file = computed(
  () => props.businessActivity.objectiveDocument?.file ?? null,
);
const comment = computed(() => props.businessActivity.objectiveComment ?? null);

function handleChange(
  newValue: TaxonomyAssessmentBusinessActivityObjectiveEnum,
) {
  const hasDescription = props.businessActivity.activity.descriptions.find(
    (d) => d.objective === newValue && d.key === 'contribution',
  )?.value;
  if (hasDescription) {
    objectiveValue.value = newValue;
    showConfirmation.value = true;
  } else {
    objectiveValue.value = undefined;
    showConfirmation.value = false;
  }
}

function handleConfirmationClose() {
  showConfirmation.value = false;
  objectiveValue.value = props.businessActivity.objective ?? undefined;
}

function handleConfirmationAnswered() {
  showConfirmation.value = false;
}

function handleForwardAssessment() {
  showForwardAssessment.value = true;
}

function handleCloseForwardingDialog() {
  showForwardAssessment.value = false;
}
</script>

<template>
  <MlSelect
    :modelValue="objectiveValue"
    :placeholder="t('Select objective')"
    :options="availableObjectives"
    :multiple="false"
    :allowSelectNone="false"
    @update:modelValue="handleChange"
  />
  <OgConfirmationDialog
    v-if="showConfirmation && description && !showForwardAssessment"
    :assessmentId="assessmentId"
    :businessActivityId="businessActivity._id"
    :objective="objectiveValue ?? null"
    :description="description"
    :initialFile="file"
    :initialComment="comment"
    @close="handleConfirmationClose"
    @answered="handleConfirmationAnswered"
    @forward="handleForwardAssessment"
  />
  <OgForwardingDialog
    v-else-if="showForwardAssessment"
    :type="TaxonomyBusinessActivityDelegationTypeEnum.SubstantialContribution"
    :assessmentId="assessmentId"
    :businessActivityId="businessActivity._id"
    :delegation="businessActivity.substantialContributionDelegation ?? null"
    :objective="objectiveValue"
    @close="handleCloseForwardingDialog"
  />
</template>
