import { gql } from '@apollo/client/core';

export default gql`
  query AtEmissionSplitInput($emissionSubcategory: String) {
    getEmissionFactorSuggestions(emissionSubcategory: $emissionSubcategory) {
      _id
      key
      name
      valueUnit
      country

      emissionFactors {
        _id
        year
        factor
      }
    }
  }
`;
