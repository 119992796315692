import { gql } from '@apollo/client/core';

export default gql`
  query PgAnalyticsDashboard {
    getUserCreatedAnalytics {
      numberOfCreatedUsers
      date
    }

    getDatapointsCreatedAnalytics {
      numberOfCreatedDatapoints
      date
    }

    getDataPointRequestAnalytics {
      numberOfNonOpenDataPointsRequests
      date
    }

    getEntityLocationAnalytics {
      numberOfCreatedProjects
      date
    }
  }
`;
