<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import { router } from '@/router';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import { TaxonomyAssessmentStatusEnum } from '@/__generated__/types';
import MlEligibleAlignedCell from '../MlEligibleAlignedCell.vue';
import { getAssessmentStep } from '../util';
import type { TaxonomyProject } from './types';

const { t } = useI18n();
const { confirmViaDialog } = useConfirmViaDialog();

const props = defineProps<Props>();
type Props = {
  taxonomyProjects: TaxonomyProject[];
};

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();

const hasAssessments = computed(
  () =>
    !!props.taxonomyProjects.find((project) => !!project.assessments.length),
);
const items = computed(() => props.taxonomyProjects ?? []);

const headers = computed(() => [
  { text: t('Project'), value: 'name', sortable: true, filterable: true },
  { text: t('Assignee(s)'), value: 'assignees', sortable: false },
  { text: t('Eligible'), value: 'eligible', sortable: true, filterable: true },
  { text: t('Aligned'), value: 'aligned', sortable: true, filterable: true },
  { text: t('Status'), value: 'status', sortable: true, filterable: true },
  { text: t('Action'), value: 'action', sortable: true, filterable: true },
]);

async function handleMarkCompletedClick(assessmentId: string) {
  const isConfirmed = await confirmViaDialog({
    title: t('Mark this project as complete'),
    text: `<p>${t('This means you will not be able to update the assessment any longer.')}</p><br /><p>${t('Would you like to continue?')}</p>`,
    renderHTML: true,
    confirmLabel: t('Confirm'),
    cancelLabel: t('Go back'),
  });

  if (isConfirmed) {
    await updateTaxonomyAssessment(
      {
        assessmentId,
        input: {
          status:
            TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED_FINAL,
        },
      },
      {
        update: (store) => {
          store.evict({ fieldName: 'getTaxonomyProjects' });
        },
      },
    );
  }
}
</script>

<template>
  <div>
    <h3 class="font-semibold leading-5 text-gray-900">
      {{ t('Recent assessments') }}
    </h3>
    <MlEmptyStateCard
      v-if="!hasAssessments"
      class="!items-start"
      :title="t('See where you last left-off')"
      :description="
        t(
          'There are no recent assessments. Start your first assessment and come back at any time to complete all Taxonomy questions.',
        )
      "
      :buttonText="t('Start assessment')"
      @buttonClick="router.push({ name: 'taxonomyProjects' })"
    />
    <OgDataTable v-else :headers="headers" :items="items" controlsHidden>
      <template #item-assignees="{ assignees }: TaxonomyProject">
        <div v-for="assignee in assignees" :key="assignee._id">
          {{ assignee.firstName }} {{ assignee.lastName }}
        </div>
      </template>
      <template #item-eligible="row">
        <template v-if="row.assessments[0]">
          <MlEligibleAlignedCell
            :value="row.assessments[0].eligibleTurnover"
            :turnover="row.assessments[0].turnover"
            type="eligible"
          />
        </template>
        <template v-else> - </template>
      </template>
      <template #item-aligned="row">
        <template v-if="row.assessments[0]">
          <MlEligibleAlignedCell
            :value="row.assessments[0].alignedTurnover"
            :turnover="row.assessments[0].turnover"
            type="aligned"
          />
        </template>
        <template v-else> - </template>
      </template>
      <template #item-status="row">
        <div
          v-if="
            row.assessments[0] &&
            (row.assessments[0].status ===
              TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_DOCUMENTS ||
              row.assessments[0].status ===
                TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED ||
              row.assessments[0].status ===
                TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED_FINAL)
          "
        >
          <AtBadge class="mr-1 py-0 text-xs font-light" type="success">
            {{ t('Completed') }}
          </AtBadge>
          <div>
            {{ getDPRDateString(row.assessments[0].updatedAt) }}
          </div>
        </div>
        <AtBadge
          v-else-if="row.assessments[0]"
          class="mr-1 py-0 text-xs font-light"
          type="warning"
        >
          {{ t('In progress') }}
        </AtBadge>
        <AtBadge v-else class="mr-1 py-0 text-xs font-light" type="OPEN">
          {{ t('Open') }}
        </AtBadge>
      </template>
      <template #item-action="row">
        <router-link
          v-if="
            row.assessments[0] &&
            row.assessments[0].status ===
              TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED_FINAL
          "
          :to="{
            name: 'taxonomyResults',
            params: {
              projectId: row._id,
              assessmentId: row.assessments[0]._id,
            },
          }"
          class="underline-offset-2 text-primary hover:underline ml-4"
        >
          {{ t('View result') }}
        </router-link>
        <template
          v-else-if="
            row.assessments[0] &&
            row.assessments[0].status ===
              TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED
          "
        >
          <router-link
            :to="{
              name: 'taxonomyAssessmentBusinessActivitiesSectorActivities',
              params: {
                projectId: row._id,
                assessmentId: row.assessments[0]._id,
              },
            }"
            class="block underline-offset-2 text-primary hover:underline ml-4"
          >
            {{ t('Update') }}
          </router-link>
          <AtButton
            class="text-primary hover:bg-blue-50 hover:text-primary"
            variant="ghost"
            @click="handleMarkCompletedClick(row.assessments[0]._id)"
          >
            {{ t('Mark completed') }}
          </AtButton>
        </template>
        <router-link
          v-else-if="row.assessments[0]"
          :to="{
            name: getAssessmentStep(row.assessments[0].status),
            params: {
              projectId: row._id,
              assessmentId: row.assessments[0]._id,
            },
          }"
          class="underline-offset-2 text-primary hover:underline ml-4"
        >
          {{ t('Continue') }}
        </router-link>
        <router-link
          v-else
          :to="{
            name: 'taxonomyAssessmentCreate',
            params: { projectId: row._id },
          }"
          class="underline-offset-2 text-primary hover:underline ml-4"
        >
          {{ t('Start') }}
        </router-link>
      </template>
    </OgDataTable>
  </div>
</template>
