<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import isNil from 'lodash/isNil';
import {
  DoubleMaterialityIroImpactType,
  DoubleMaterialityIroRiskAndOpportunityType,
  DoubleMaterialitySurveyStatus,
  DoubleMaterialitySurveyType,
  type PgExternalDoubleMaterialitySurveyAnswerQuery,
} from '@/__generated__/types';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import { useStore } from '../../store';

type Item =
  | {
      _id: string;
      surveyType: DoubleMaterialitySurveyType.IMPACT;
      type: DoubleMaterialityIroImpactType;
      text: string;
      initialValue: number | null;
    }
  | {
      _id: string;
      surveyType: DoubleMaterialitySurveyType.RISKS_OPPORTUNITIES;
      type: DoubleMaterialityIroRiskAndOpportunityType;
      text: string;
      initialValue: number | null;
    };

const props = defineProps<{
  data: PgExternalDoubleMaterialitySurveyAnswerQuery['getExternallyDoubleMateriality'];
}>();

const emit = defineEmits<{
  saved: [];
}>();

const { t } = useI18n();
const store = useStore();

const survey = computed(
  () =>
    store.value.surveys.find((item) => item._id === props.data.surveyId) ??
    null,
);

const form = reactive<Record<string, number | null>>({});

const message = computed(() => {
  if (!survey.value || !survey.value.type) {
    return null;
  }

  switch (survey.value.type) {
    case DoubleMaterialitySurveyType.IMPACT:
      return (
        store.value.surveySettings.impactsSurveyTemplate?.body[
          props.data.userLanguage
        ] ?? null
      );
    case DoubleMaterialitySurveyType.RISKS_OPPORTUNITIES:
      return (
        store.value.surveySettings.risksAndOpportunitiesSurveyTemplate?.body[
          props.data.userLanguage
        ] ?? null
      );
    default:
      return null;
  }
});

const options = computed(() => ({
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '0': 'n/a',
}));

const items = computed<Item[]>(() => {
  if (!survey.value || !survey.value.type) {
    return [];
  }

  switch (survey.value.type) {
    case DoubleMaterialitySurveyType.IMPACT:
      return store.value.iroImpacts
        .filter((item) => item.impact)
        .map((item) => ({
          _id: item._id,
          surveyType: DoubleMaterialitySurveyType.IMPACT,
          type: item.type,
          text: item.impact,
          initialValue: survey.value?.answers[item._id] ?? null,
        }));
    case DoubleMaterialitySurveyType.RISKS_OPPORTUNITIES:
      return store.value.iroRisksAndOpportunities
        .filter((item) => item.riskAndOpportunity)
        .map((item) => ({
          _id: item._id,
          surveyType: DoubleMaterialitySurveyType.RISKS_OPPORTUNITIES,
          type: item.type,
          text: item.riskAndOpportunity,
          initialValue: survey.value?.answers[item._id] ?? null,
        }));
    default:
      return [];
  }
});

const isValid = computed(() =>
  items.value.every((item) => !isNil(form[item._id])),
);

watch(
  items,
  (newItems) => {
    Object.keys(form).forEach((key) => {
      delete form[key];
    });

    newItems.forEach((item) => {
      form[item._id] = item.initialValue;
    });
  },
  { immediate: true },
);

function handleSelectChange(itemId: string, event: string) {
  form[itemId] = event ? parseInt(event, 10) : null;
}

function handleSubmit() {
  const surveyIndex = store.value.surveys.findIndex(
    (item) => item._id === props.data.surveyId,
  );
  if (surveyIndex === -1) {
    return;
  }

  items.value.forEach((item) => {
    store.value.surveys[surveyIndex].answers[item._id] = form[item._id] ?? null;
  });

  store.value.surveys[surveyIndex].status =
    DoubleMaterialitySurveyStatus.COMPLETED;

  emit('saved');
}
</script>

<template>
  <div class="bg-white rounded-md border p-3">
    <p v-if="message" class="whitespace-pre-line mb-5">
      {{ message }}
    </p>
    <form class="px-3" @submit.prevent="handleSubmit">
      <p class="font-bold text-lg mb-3">
        {{
          t(
            'Please rate the importance of these statements to {companyName} on a scale from 1 (being the lowest) to 5 (the highest)',
            { companyName: data.organizationName },
          )
        }}
      </p>

      <div
        v-for="item in items"
        :key="item._id"
        class="flex items-center space-x-6 mb-3 last:mb-6"
      >
        <p class="flex-1">
          {{ item.text }}
        </p>
        <div class="flex-none w-52 text-center">
          <AtBadge
            v-if="item.type === DoubleMaterialityIroImpactType.ACTUAL_POSITIVE"
            type="TEAL"
            size="sm"
          >
            {{ t('Positive actual impact') }}
          </AtBadge>
          <AtBadge
            v-else-if="
              item.type === DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE
            "
            type="EMERALD"
            size="sm"
          >
            {{ t('Positive potential impact') }}
          </AtBadge>
          <AtBadge
            v-else-if="
              item.type === DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE
            "
            type="error"
            size="sm"
          >
            {{ t('Negative actual impact') }}
          </AtBadge>
          <AtBadge
            v-else-if="
              item.type === DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE
            "
            type="warning"
            size="sm"
          >
            {{ t('Negative potential impact') }}
          </AtBadge>
          <AtBadge
            v-if="
              item.type ===
              DoubleMaterialityIroRiskAndOpportunityType.OPPORTUNITY
            "
            type="VIOLET"
            size="sm"
          >
            {{ t('Opportunity') }}
          </AtBadge>
          <AtBadge
            v-else-if="
              item.type === DoubleMaterialityIroRiskAndOpportunityType.RISK
            "
            type="YELLOW"
            size="sm"
          >
            {{ t('Risk') }}
          </AtBadge>
        </div>
        <div class="flex-none w-24">
          <MlSelect
            :modelValue="form[item._id]?.toString() ?? undefined"
            :options="options"
            :placeholder="t('Select')"
            @update:modelValue="handleSelectChange(item._id, $event)"
          />
        </div>
      </div>

      <div class="flex justify-end items-center space-x-3">
        <AtButton type="submit" :disabled="!isValid">
          {{ t('Submit') }}
        </AtButton>
      </div>
    </form>
  </div>
</template>
