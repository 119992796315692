<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { UsersIcon } from '@heroicons/vue/outline';
import { notify } from '@kyvg/vue3-notification';
import { email as emailValidator, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import { getUserName } from '@/utils/helpers/getUserName';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtAvatar from '@/components/atoms/AtAvatar.vue';
import {
  type DisableUserInput,
  OnboardingStatusEnum,
  type TmSettingsTeamQuery,
  type TmSettingsTeamQueryVariables,
  type User,
  type UserFieldsFragment,
  UserLanguageEnum,
  UserRole,
} from '@/__generated__/types';
import useCreateUserMutation from '@/api/mutations/User/createUser.mutation';
import useUpdateUserMutation from '@/api/mutations/User/updateUser.mutation';
import useDisableUserMutation from '@/api/mutations/User/disableUser.mutation';
import OgActionPanel from '@/components/organisms/OgActionPanel/OgActionPanel.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import useAddEntityToUsersMutation from '@/api/mutations/User/addEntityToUsers.mutation';
import useReassignSuperadminMutation from '@/api/mutations/User/reassignSuperadmin.mutation';
import OgUserActionModal from './OgUserActionModal.vue';
import TM_SETTINGS_TEAM_QUERY from './TmSettingsTeam.query';

const { t } = useI18n();
const { isAdminOrSuperAdmin, currentUser, currentUserName, activeEntity } =
  useCurrentUser();
const { confirmViaDialog } = useConfirmViaDialog();

const route = useRoute();

const isAdminWorkspace = route.name === 'adminTeamSettings';

type TeamUser = UserFieldsFragment;

const { result } = useQuery<TmSettingsTeamQuery, TmSettingsTeamQueryVariables>(
  TM_SETTINGS_TEAM_QUERY,
  () => ({
    allTeamsUsers: isAdminOrSuperAdmin.value,
  }),
);

const allTeamUsers = computed(
  () => (result.value?.getAllTeamsUsers || result.value?.getTeamUsers) ?? [],
);
const teamUsers = computed(
  () =>
    (isAdminWorkspace
      ? result.value?.getAllTeamsUsers
      : result.value?.getTeamUsers) ?? [],
);

type TUserDto = {
  _id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  jobDepartment: string;
  email: string;
  role: UserRole;
  language: UserLanguageEnum;
  entities: string[];
};

const userDto = reactive<TUserDto>({
  _id: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  jobDepartment: '',
  email: '',
  role: UserRole.ADMIN,
  language: UserLanguageEnum.EN,
  entities: [],
});

const validationRules = {
  firstName: { required },
  lastName: { required },
  email: { required, email: emailValidator },
};

const v$ = useVuelidate(validationRules, userDto);

const isInvalid = computed(() => v$.value.$invalid);

const resetDto = () => {
  userDto._id = '';
  userDto.firstName = '';
  userDto.lastName = '';
  userDto.jobTitle = '';
  userDto.jobDepartment = '';
  userDto.email = '';
  userDto.language = UserLanguageEnum.EN;
  userDto.role = UserRole.ADMIN;
  userDto.entities = [];
};

const isEditingUser = ref(false);

const usersIdsSelected = ref<string[]>([]);

const resetUsersIdsSelected = () => {
  usersIdsSelected.value = [];
};

const existingUsersOptions = computed(() =>
  allTeamUsers.value.reduce<Record<string, string>>(
    (acc, { _id, firstName, lastName }) => ({
      ...acc,
      [_id]: `${firstName} ${lastName}`,
    }),
    {},
  ),
);

const {
  mutate: createUserMutation,
  loading: createUserLoading,
  error: createUserError,
} = useCreateUserMutation();

const {
  mutate: updateUserMutation,
  loading: updateUserLoading,
  error: updateUserError,
} = useUpdateUserMutation();

const { mutate: disableUserMutation, loading: disableUserLoading } =
  useDisableUserMutation();

const {
  mutate: reassignSuperadminMutation,
  loading: reassignSuperadminLoading,
  error: reassignSuperadminError,
} = useReassignSuperadminMutation();

const {
  mutate: addEntityToUsersMutation,
  error: addEntityToUsersError,
  loading: addEntityToUsersLoading,
} = useAddEntityToUsersMutation();

const isActionPanelOpened = ref(false);

const userRoleOptionsArray = [
  UserRole.ADMIN,
  UserRole.MANAGER,
  UserRole.CONTRIBUTOR,
  UserRole.AUDITOR,
];

const userRoleOptions = userRoleOptionsArray.reduce<Record<string, string>>(
  (acc, role) => {
    if (
      currentUser.value?.role === UserRole.MANAGER &&
      role === UserRole.ADMIN
    ) {
      return acc;
    }

    acc[role] = role;
    return acc;
  },
  {},
);

const editUserRoleOptions = [
  ...userRoleOptionsArray,
  UserRole.SUPERADMIN,
].reduce<Record<string, string>>((acc, role) => {
  if (currentUser.value?.role === UserRole.MANAGER && role === UserRole.ADMIN) {
    return acc;
  }

  acc[role] = role;
  return acc;
}, {});

const userLanguageOptions = [UserLanguageEnum.EN, UserLanguageEnum.DE].reduce<
  Record<string, string>
>((acc, role) => {
  acc[role] = role;
  return acc;
}, {});

const userWorkspaceOptions = computed(() =>
  teamUsers.value.reduce<Record<string, string>>((acc, user) => {
    user.entities.forEach((e) => {
      acc[e._id] = e.name ?? '';
    });
    return acc;
  }, {}),
);

const currentUserWorkspaces = computed(() => {
  return currentUser.value?.entities.map((e) => e._id);
});

const disabledWorkspaces = computed(() => {
  const disabledWorkspacesIds: string[] = [];

  Object.keys(userWorkspaceOptions.value).forEach((workspaceId) => {
    if (!currentUserWorkspaces.value?.includes(workspaceId)) {
      disabledWorkspacesIds.push(workspaceId);
    }
  });

  return disabledWorkspacesIds;
});

const openActionPanel = (isEditing = false) => {
  isEditingUser.value = isEditing;
  isActionPanelOpened.value = true;
};

const closeActionPanel = () => {
  isActionPanelOpened.value = false;
  isEditingUser.value = false;
  resetDto();
};

const isSuperAdminWarningModalOpen = ref(false);

const openSuperAdminWarningModal = () => {
  isSuperAdminWarningModalOpen.value = true;
};

const closeSuperAdminWarningModal = () => {
  isSuperAdminWarningModalOpen.value = false;
};

let userUnchanged = { ...userDto };

const editUser = (user: TeamUser) => {
  userDto._id = user._id;
  userDto.firstName = user.firstName || '';
  userDto.lastName = user.lastName || '';
  userDto.jobTitle = user.jobTitle || '';
  userDto.jobDepartment = user.jobDepartment || '';
  userDto.email = user.email || '';
  userDto.role = user.role || UserRole.ADMIN;
  userDto.language = user.language;
  userDto.entities = user.entities.map((e) => e._id);

  userUnchanged = { ...userDto };

  openActionPanel(true);
};

const hasUserChanged = computed(
  () =>
    userUnchanged.firstName !== userDto.firstName ||
    userUnchanged.lastName !== userDto.lastName ||
    userUnchanged.jobTitle !== userDto.jobTitle ||
    userUnchanged.jobDepartment !== userDto.jobDepartment ||
    userUnchanged.email !== userDto.email ||
    userUnchanged.role !== userDto.role ||
    userUnchanged.language !== userDto.language ||
    userUnchanged.entities !== userDto.entities,
);

const disableUser = async () => {
  const disableUserInput: DisableUserInput = { _id: userDto._id };
  await disableUserMutation({ disableUserInput });
  closeModal();
};

const numberOfPendingInvites = computed(
  () =>
    teamUsers.value.filter(
      (user) => user.onboardingStatus === OnboardingStatusEnum.INCOMPLETE,
    ).length,
);

const numberOfUsers = computed(() => teamUsers.value.length);

const numberOfExternalUsers = computed(() => 0);

const isModalOpen = ref(false);

function openModal() {
  isModalOpen.value = true;
}
function closeModal() {
  isModalOpen.value = false;
}

function getUserType(user: Pick<User, 'role' | 'onboardingStatus'>) {
  switch (user.role) {
    case UserRole.NONE:
      return t('Disabled User');
    case UserRole.SUPERADMIN:
    case UserRole.ADMIN:
    case UserRole.MANAGER:
    case UserRole.CONTRIBUTOR:
      return user.onboardingStatus === OnboardingStatusEnum.COMPLETED
        ? t('Registered')
        : t('Pending Invitee');
    default:
      return '';
  }
}

const workspaceHeader = {
  text: t('Workspace'),
  value: 'workspace',
  sortable: true,
  filterable: true,
};

const headers = computed(() => [
  { text: t('Name'), value: 'name', sortable: true },
  { text: t('Title'), value: 'title', sortable: true, filterable: true },
  {
    text: t('Department'),
    value: 'department',
    sortable: true,
    filterable: true,
  },
  ...(isAdminWorkspace ? [workspaceHeader] : []),
  { text: t('Role'), value: 'role', sortable: true, filterable: true },
  { text: t('Type'), value: 'type', sortable: true, filterable: true },
  { text: '', value: 'edit' },
]);

const items = computed(() =>
  teamUsers.value.map((teamUser) => ({
    teamUser,
    currentUserName: currentUserName.value,
    title: t(teamUser.jobTitle),
    department: t(teamUser.jobDepartment),
    workspace: teamUser.entities.map((e) => e.name),
    role: t(teamUser.role),
    type: getUserType(teamUser),
  })),
);

async function addEntityToUsers(entityId: string | undefined) {
  try {
    if (!entityId) throw new Error(`entity id is ${entityId}`);
    const updatedUsers = await addEntityToUsersMutation(
      { usersIds: usersIdsSelected.value, entityId },
      {
        update: (store) => {
          store.evict({ fieldName: 'getTeamUsers' });
          store.evict({ fieldName: 'getAllTeamsUsers' });
        },
      },
    );
    resetUsersIdsSelected();
    notify({
      type: 'success',
      text: t('Users have been added to this workspace: {emails}', {
        emails: updatedUsers?.data?.addEntityToUsers
          .map((user) => user.email)
          .join(', '),
      }),
    });
  } catch (err) {
    if (addEntityToUsersError.value)
      notify({ type: 'error', text: t(addEntityToUsersError.value?.message) });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

async function updateUser() {
  try {
    const updatedUser = await updateUserMutation(
      {
        updateUserInput: {
          ...userDto,
          entity: userDto.entities.at(0),
        },
      },
      {
        update: (store) => {
          store.evict({ fieldName: 'getTeamUsers' });
          store.evict({ fieldName: 'getAllTeamsUsers' });
        },
      },
    );
    closeActionPanel();
    notify({
      type: 'success',
      text: t('User {email} has been updated.', {
        email: updatedUser?.data?.updateUser.email,
      }),
    });
  } catch (err) {
    if (updateUserError.value)
      notify({ type: 'error', text: t(updateUserError.value?.message) });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

async function reassignSuperadmin() {
  try {
    const updatedUser = await reassignSuperadminMutation(
      {
        updateUserInput: {
          ...userDto,
          entity: userDto.entities.at(0),
        },
      },
      {
        update: (store) => {
          store.evict({ fieldName: 'getTeamUsers' });
          store.evict({ fieldName: 'getAllTeamsUsers' });
        },
      },
    );
    closeActionPanel();
    notify({
      type: 'success',
      text: t('User {email} has been assigned as superadmin.', {
        email: updatedUser?.data?.reassignSuperadmin.email,
      }),
    });
  } catch (err) {
    if (reassignSuperadminError.value)
      notify({
        type: 'error',
        text: t(reassignSuperadminError.value?.message),
      });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

async function createUser() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _id: _, ...createUserDto } = userDto;
  try {
    const createdUser = await createUserMutation(
      {
        createUserInput: {
          ...createUserDto,
          entity: createUserDto.entities.at(0) ?? '',
          ...(isAdminWorkspace
            ? {}
            : {
                entity: activeEntity.value?._id ?? '',
                ...(activeEntity.value
                  ? { entities: [activeEntity.value._id] }
                  : { entities: [] }),
              }),
        },
      },
      {
        update: (store) => {
          store.evict({ fieldName: 'getTeamUsers' });
          store.evict({ fieldName: 'getAllTeamsUsers' });
        },
      },
    );
    closeActionPanel();
    notify({
      type: 'success',
      text: t('Invitation email has been sent to {email}.', {
        email: createdUser?.data?.createUser.email,
      }),
    });
  } catch (err) {
    if (createUserError.value)
      notify({ type: 'error', text: t(createUserError.value?.message) });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

const canEditUser = (teamUser: User) => {
  const isManager = currentUser.value?.role === UserRole.MANAGER;
  const isInvitedByCurrentUser =
    teamUser.invitedBy?._id === currentUser.value?._id;
  const isManagerPermissionAllowsEditing =
    isManager && teamUser.role !== UserRole.ADMIN && isInvitedByCurrentUser;

  return isAdminOrSuperAdmin.value || isManagerPermissionAllowsEditing;
};

async function handleRoleChange(newRole: UserRole) {
  if (!isEditingUser.value || userUnchanged.role === newRole) {
    userDto.role = newRole;
  } else {
    const isConfirmed = await confirmViaDialog({
      text: t(
        'When you change the user role, all existing assignments for that user will be removed. Please make sure to check assignments afterwards.',
      ),
      confirmLabel: t('Change'),
      cancelLabel: t('Cancel'),
      confirmButtonVariant: 'destructive',
    });
    if (isConfirmed) {
      userDto.role = newRole;
    }
  }
}
</script>

<template>
  <div class="pt-5 px-6">
    <section class="flex justify-between">
      <div>
        <h2 class="text-xl font-medium text-gray-900">
          {{ t('Team') }}
        </h2>
        <p class="mt-3 text-sm text-gray-500">
          {{ t('Invite and manage team members to assign permissions.') }}
        </p>
        <div class="flex py-6">
          <div class="mr-4 border-r pr-4 text-center">
            <div class="text-4xl">
              {{ numberOfUsers }}
            </div>
            <p class="text-sm">
              {{ t('Users') }}
            </p>
          </div>
          <div class="mr-4 border-r border-gray-200 pr-4 text-center">
            <div class="text-4xl">
              {{ numberOfPendingInvites }}
            </div>
            <p class="text-sm">
              {{ t('Pending invites') }}
            </p>
          </div>
          <div class="border-gray-200 pr-4 text-center">
            <div class="text-4xl">
              {{ numberOfExternalUsers }}
            </div>
            <p class="text-sm">
              {{ t('External users') }}
            </p>
          </div>
        </div>
      </div>
      <AtButton
        v-rolefrom="UserRole.MANAGER"
        class="mt-2 self-start"
        type="submit"
        data-cy="AtButtonAddUser"
        @click="openActionPanel()"
      >
        {{ t('Add user') }}
      </AtButton>
    </section>
    <section>
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto" />
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none" />
      </div>
      <div>
        <div class="py-2">
          <OgDataTable :headers="headers" :items="items">
            <template #item-name="{ teamUser }">
              <div class="flex items-center" data-cy="templateItemName">
                <AtAvatar
                  class="!h-8 !w-8"
                  :url="teamUser.picture?.downloadUrl"
                  :userName="getUserName(teamUser)"
                />
                <div class="ml-2 flex flex-col">
                  <span>
                    {{ teamUser.firstName }} {{ teamUser.lastName }}
                  </span>
                  <span class="text-gray-400" data-cy="templateItemNameEmail">
                    {{ teamUser.email }}
                  </span>
                </div>
              </div>
            </template>
            <template #item-workspace="{ teamUser }">
              <li
                v-for="(entity, i) in teamUser.entities"
                :key="entity._id"
                class="list-none"
              >
                {{ entity.name
                }}<span v-if="i !== teamUser.entities.length - 1">,</span>
              </li>
            </template>
            <template #item-edit="{ teamUser }">
              <AtButton
                v-if="canEditUser(teamUser)"
                class="text-primary hover:text-primary"
                variant="link"
                data-cy="AtButtonEditUser"
                @click="editUser(teamUser)"
              >
                {{ t('Edit') }}
                <span class="sr-only">
                  {{ teamUser.firstName }} {{ teamUser.lastName }}
                </span>
              </AtButton>
            </template>
          </OgDataTable>
        </div>
      </div>
    </section>
    <OgActionPanel
      class="min-w-[24rem]"
      :isOpened="isActionPanelOpened"
      hasIcon
      hasPaddingX
      data-cy="OgActionPanelSettingsTeam"
      @closePanel="closeActionPanel()"
    >
      <template #title>
        <div class="flex gap-x-2">
          <UsersIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
          <div v-if="isEditingUser">
            {{ t('Edit team members') }}
          </div>
          <div v-else>
            {{ t('Add team members') }}
          </div>
        </div>
      </template>

      <div v-if="!isEditingUser">
        <div class="py-2.5">
          {{ t('Add existing team members to workspace.') }}
        </div>

        <div class="flex items-end py-6">
          <MlSelect
            v-model="usersIdsSelected"
            :label="t('Name')"
            data-cy="MlSelectExisitingUser"
            :options="existingUsersOptions"
            multiple
            wrapperClass="w-full"
          />

          <AtButton
            class="ml-2 h-[34px] align-bottom"
            data-cy="AtButtonAddExistingUser"
            :disabled="!usersIdsSelected.length"
            :loading="addEntityToUsersLoading"
            @click.stop="addEntityToUsers(activeEntity?._id)"
          >
            {{ t('Add') }}
          </AtButton>
        </div>
      </div>

      <div>
        <div
          v-if="!isEditingUser"
          class="flex w-full items-center justify-center text-center"
        >
          <div class="h-px w-1/6 bg-gray-200" />
          <div class="w-3/4">
            {{ t('Or invite new team members.') }}
          </div>
          <div class="h-px w-1/6 bg-gray-200" />
        </div>

        <form id="userForm" class="my-6 flex flex-col gap-3" @submit.prevent>
          <AtInput
            v-model.trim="userDto.firstName"
            :label="t('First Name')"
            data-cy="AtInputFirstName"
            required="required"
          />
          <AtInput
            v-model.trim="userDto.lastName"
            :label="t('Last Name')"
            data-cy="AtInputLastName"
            required="required"
          />
          <AtInput
            v-show="isEditingUser"
            v-model.trim="userDto.jobTitle"
            :label="t('Job Title')"
          />
          <AtInput
            v-show="isEditingUser"
            v-model.trim="userDto.jobDepartment"
            :label="t('Job Department')"
          />
          <AtInput
            v-model.trim="userDto.email"
            :label="t('E-Mail')"
            type="email"
            data-cy="AtInputEmail"
            required="required"
          />
          <MlSelect
            v-if="userUnchanged.role !== UserRole.SUPERADMIN || !isEditingUser"
            :modelValue="userDto.role"
            :label="t('Role')"
            data-cy="MlSelectRole"
            :options="
              isEditingUser && currentUser?.role === UserRole.SUPERADMIN
                ? editUserRoleOptions
                : userRoleOptions
            "
            sortedOptions
            @update:modelValue="handleRoleChange"
          />
          <MlSelect
            v-if="isAdminWorkspace"
            v-model="userDto.entities"
            :label="t('Workspaces')"
            data-cy="MlSelectWorkspaces"
            :options="userWorkspaceOptions"
            multiple
            :disabledOptions="
              currentUser?.role === UserRole.SUPERADMIN
                ? []
                : disabledWorkspaces
            "
            :disabledOptionText="
              t(
                'Only admins responsible for this workspace or the superadmin can add this user.',
              )
            "
          />
          <MlSelect
            v-model.trim="userDto.language"
            :label="t('Language')"
            data-cy="MlSelectLanguage"
            :options="userLanguageOptions"
          />
        </form>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <AtButton
            v-if="!userDto._id"
            form="userForm"
            data-cy="AtButtonCreateUser"
            :loading="createUserLoading"
            :disabled="!hasUserChanged || isInvalid"
            @click="createUser()"
          >
            {{ t('Send invitation') }}
          </AtButton>
          <AtButton
            v-if="userDto._id"
            form="userForm"
            type="submit"
            class="ml-2"
            data-cy="AtButtonUpdateUser"
            :loading="updateUserLoading"
            :disabled="!hasUserChanged || isInvalid"
            @click="
              userDto.role === UserRole.SUPERADMIN &&
              currentUser?.role === UserRole.SUPERADMIN &&
              userUnchanged.role !== UserRole.SUPERADMIN
                ? openSuperAdminWarningModal()
                : updateUser()
            "
          >
            {{ t('Update User') }}
          </AtButton>
          <AtButton
            v-if="
              userDto._id &&
              isAdminOrSuperAdmin &&
              userDto.role !== UserRole.NONE &&
              userDto.role !== UserRole.SUPERADMIN &&
              currentUser?._id !== userDto._id
            "
            form="userForm"
            type="submit"
            variant="destructive"
            data-cy="AtButtonOpenModalDisableUser"
            @click.stop="openModal()"
          >
            {{ t('Disable User') }}
          </AtButton>
        </div>
      </div>
    </OgActionPanel>
    <OgUserActionModal
      :isRevealed="isModalOpen && isAdminOrSuperAdmin"
      @closeModal="closeModal"
    >
      <template #title>
        {{ t('Disable team member') }}
      </template>
      <template #description>
        <i18n-t
          keypath="You are about to disable {fullName} from Codio Impact. Do you wish to continue?"
          tag="span"
        >
          <template #fullName>
            <strong>{{ `${userDto.firstName} ${userDto.lastName}` }}</strong>
          </template>
        </i18n-t>
      </template>
      <template #buttons>
        <div class="flex justify-end pt-2">
          <AtButton
            class="mr-4"
            variant="destructive"
            data-cy="AtButtonDisableUser"
            :loading="disableUserLoading"
            @click.stop="disableUser()"
          >
            {{ t('Disable user') }}
          </AtButton>
          <AtButton variant="text" @click.stop="closeModal">
            {{ t('Cancel') }}
          </AtButton>
        </div>
      </template>
    </OgUserActionModal>

    <OgUserActionModal
      :isRevealed="isSuperAdminWarningModalOpen"
      @closeModal="closeSuperAdminWarningModal"
    >
      <template #title>
        {{ t('Assign new superadmin') }}
      </template>
      <template #description>
        <i18n-t
          keypath="By clicking 'Continue', {fullName} will become the new superadmin, and your role will switch to admin. This action cannot be undone. Only the new superadmin can reverse this change. Do you want to assign a new super admin?"
          tag="span"
        >
          <template #fullName>
            <strong>{{ `${userDto.firstName} ${userDto.lastName}` }}</strong>
          </template>
        </i18n-t>
      </template>
      <template #buttons>
        <div class="flex justify-between pt-2">
          <AtButton variant="text" @click.stop="closeSuperAdminWarningModal">
            {{ t('Cancel') }}
          </AtButton>
          <AtButton
            class="mr-4"
            :loading="reassignSuperadminLoading"
            @click.stop="
              async () => {
                await reassignSuperadmin();
                closeSuperAdminWarningModal();
              }
            "
          >
            {{ t('Assign superadmin') }}
          </AtButton>
        </div>
      </template>
    </OgUserActionModal>
  </div>
</template>
