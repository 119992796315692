<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { ErrorObject } from '@vuelidate/core';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';

type Props = {
  modelValue: string;
  errors?: string[] | ErrorObject[];
};

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
});
const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();
</script>

<template>
  <AtInput
    :value="props.modelValue"
    :label="t('Company name')"
    data-cy="companyDprText"
    :errors="props.errors"
    @update:modelValue="emit('update:modelValue', $event)"
  />
</template>

<style scoped lang="postcss"></style>
