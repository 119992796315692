import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  AddDataPointTypeOverrideMutation,
  AddDataPointTypeOverrideMutationVariables,
} from '@/__generated__/types';

export const ADD_DATAPOINTTYPE_OVERRIDE = gql`
  mutation AddDataPointTypeOverride(
    $dataPointTypeOverrideInput: DataPointTypeOverrideInput!
    $entityLocationInput: EntityLocationInput!
  ) {
    addDataPointTypeOverride(
      dataPointTypeOverride: $dataPointTypeOverrideInput
      entityLocation: $entityLocationInput
    ) {
      _id
      name
      overrides {
        _id
        datapointtype {
          _id
        }
        refreshInterval
        valueUnit
        isSplit
        customConversionFactor
        tags {
          _id
          name
          color
          entity {
            _id
          }
        }
        guidanceComments {
          _id
          text
          createdAt
          user {
            _id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  AddDataPointTypeOverrideMutation,
  AddDataPointTypeOverrideMutationVariables
>;

const useAddDataPointTypeOverrideMutation = (options?: TOptions) =>
  useMutation<
    AddDataPointTypeOverrideMutation,
    AddDataPointTypeOverrideMutationVariables
  >(ADD_DATAPOINTTYPE_OVERRIDE, options);

export default useAddDataPointTypeOverrideMutation;
