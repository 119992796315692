<script setup lang="ts">
import { computed, inject, reactive, ref } from 'vue';
import groupBy from 'lodash/groupBy';
import { useI18n } from 'vue-i18n';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import {
  DataPointRequestStatusEnum,
  ValueDataTypeEnum,
} from '@/__generated__/types';
import type {
  Project,
  TDataPointRequest,
  TQuestionDataTableItem,
} from '../../../types';
import OgQuestion from './OgQuestion.vue';

const { t } = useI18n();
const emit = defineEmits<{
  updateActiveQuestion: [item: TQuestionDataTableItem | undefined];
}>();
const props = defineProps<{
  items: TQuestionDataTableItem[];
  project?: Project;
  isChilds?: boolean;
  parent?: TDataPointRequest;
}>();

const itemsGroupedByDPT = computed(() =>
  groupBy<TQuestionDataTableItem>(props.items, 'dpr.dataPointType._id'),
);
const itemsGroupedByConditionValue = computed(() =>
  groupBy<TQuestionDataTableItem>(
    props.items,
    'dpr.dataPointTypeFlat.conditionValue',
  ),
);
const activeConditionValues = inject(
  'activeConditionValues',
  reactive<Record<string, boolean>>({}),
);

const hoveredItem = inject('hoveredItem', ref(''));

function updateActiveQuestion(event: TQuestionDataTableItem | undefined) {
  emit('updateActiveQuestion', event);
}

const activeChilds = computed(() => (props.isChilds ? props.items : []));
defineExpose({ activeChilds });

const emptyFunction = () => {}; // needed to make events happen as focusout is required
</script>

<template>
  <div
    v-if="project"
    data-cy="OgQuestionList"
    @mouseleave="hoveredItem = ''"
    @focusout="emptyFunction"
  >
    <div
      v-if="
        parent &&
        parent?.dataPointType.valueDataType ===
          ValueDataTypeEnum.MULTIPLE_CHOICE &&
        parent.status !== DataPointRequestStatusEnum.ACCEPTED
      "
    >
      <div v-for="conditionValue of parent.value" :key="conditionValue">
        <div v-if="itemsGroupedByConditionValue[conditionValue]">
          <button
            class="p-3 cursor-pointer flex bg-white w-full"
            :class="{
              '!bg-gray-50':
                hoveredItem ===
                itemsGroupedByConditionValue[conditionValue][0]?.dpr._id,
            }"
            @mouseover.stop="
              hoveredItem =
                itemsGroupedByConditionValue[conditionValue][0]?.dpr._id ?? ''
            "
            @focus.stop="
              hoveredItem =
                itemsGroupedByConditionValue[conditionValue][0]?.dpr._id ?? ''
            "
            @click.prevent.stop="
              activeConditionValues[conditionValue] =
                !activeConditionValues[conditionValue]
            "
          >
            <ChevronRightIcon
              v-show="!activeConditionValues[conditionValue]"
              class="mr-1 w-5"
            />
            <ChevronDownIcon
              v-show="activeConditionValues[conditionValue]"
              class="mr-1 w-5"
            />
            {{ t(conditionValue) }}
          </button>

          <OgQuestion
            v-for="(itemGroup, key, index) in groupBy<TQuestionDataTableItem>(
              itemsGroupedByConditionValue[conditionValue],
              'dpr.dataPointType._id',
            )"
            v-show="activeConditionValues[conditionValue]"
            :key="itemGroup[0].dpr._id"
            :index="index"
            :itemGroup="itemGroup"
            :project="project"
            :isChilds="props.isChilds"
            @mouseover.stop="hoveredItem = itemGroup[0].dpr._id"
            @focus.stop="hoveredItem = itemGroup[0].dpr._id"
            @updateActiveQuestion="updateActiveQuestion"
          />
        </div>
      </div>
    </div>
    <OgQuestion
      v-for="(itemGroup, key, index) in itemsGroupedByDPT"
      v-else
      :key="itemGroup[0].dpr._id"
      :index="index"
      :itemGroup="itemGroup"
      :project="project"
      :isChilds="props.isChilds"
      @mouseover.stop="hoveredItem = itemGroup[0].dpr._id"
      @focus.stop="hoveredItem = itemGroup[0].dpr._id"
      @updateActiveQuestion="updateActiveQuestion"
    />
  </div>
</template>
