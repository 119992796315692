<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { i18n } from '@/lib/i18n';
import logo from '@/assets/logo.svg';
import type { UserLanguageEnum } from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtTopBar from '../../atoms/AtTopBar.vue';

const { t, locale } = useI18n();
const { updateCurrentUser } = useCurrentUser();

const triggerSwitchTranslation = (selectedLocale: string) => {
  updateCurrentUser({
    language: selectedLocale.toUpperCase() as UserLanguageEnum,
  });
};
</script>
<template>
  <AtTopBar isShown class="justify-between border-b p-8 text-gray-900">
    <img :src="logo" class="flex h-9 justify-between" alt="logo" />
    <div class="flex items-center text-gray-500">
      <div>
        <ul class="mr-6 flex h-full justify-center">
          <li
            v-for="availableLocale in i18n.global.availableLocales"
            :key="availableLocale"
            class="ml-2 h-full cursor-pointer border-l border-gray-200 pl-2 first:border-0"
            :class="{ 'text-primary': locale === availableLocale }"
            @click="triggerSwitchTranslation(availableLocale)"
          >
            {{ availableLocale.toUpperCase() }}
          </li>
        </ul>
      </div>
      <router-link :to="{ name: 'logout' }" class="text-gray-900">
        {{ t('Log out') }}
      </router-link>
    </div>
  </AtTopBar>
</template>
