import { createSharedComposable, useConfirmDialog } from '@vueuse/core';
import { ref } from 'vue';
import type { ButtonVariants } from '@/components/ui/button';

export type TDialogData = {
  title?: string;
  text?: string;
  renderHTML?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmButtonVariant?: ButtonVariants['variant'];
  titleClass?: string;
  textClass?: string;
  modalClass?: string;
};

const useConfirmViaDialog = () => {
  const { isRevealed, reveal, confirm } = useConfirmDialog();

  const dialogData = ref<TDialogData>({});

  const confirmViaDialog = async (newDialogData?: TDialogData) => {
    dialogData.value = newDialogData ?? {};

    const { data } = await reveal();

    return data as boolean;
  };

  return { confirmViaDialog, dialogData, confirm, isRevealed };
};

export default createSharedComposable(useConfirmViaDialog);
