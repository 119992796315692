<script setup lang="ts">
import { ref, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import cloneDeep from 'lodash/cloneDeep';
import { ReportingFrameworkEnum } from '@/__generated__/types';
import MlCompanyNameInput from '@/components/molecules/MlCompanyInputs/MlCompanyNameInput.vue';
import MlIndustrySelect from '@/components/molecules/MlCompanyInputs/MlIndustrySelect.vue';
import MlHeadquartersInput from '@/components/molecules/MlCompanyInputs/MlHeadquartersInput.vue';
import MlHeadquarterNameInput from '@/components/molecules/MlCompanyInputs/MlHeadquarterNameInput.vue';
import MlEmployeeNumberSelect from '@/components/molecules/MlCompanyInputs/MlEmployeeNumberSelect.vue';
import type { TEntitySettings } from './types';

type TProps = {
  entitySettings: TEntitySettings;
  reportingFrameworksSelected: ReportingFrameworkEnum[];
};

const props = defineProps<TProps>();
const emit = defineEmits(['updateValue']);

const { t } = useI18n();

const entitySettingsFormData = ref(cloneDeep(props.entitySettings));

function updateValue(e: TEntitySettings, validationPath: string) {
  switch (validationPath) {
    case 'companyName':
      formDataValidation.companyName = e.companyName;
      v$.value.companyName.$touch();
      if (v$.value.companyName.$error) return;
      break;
    case 'industries':
      formDataValidation.industries = e.industry;
      v$.value.industries.$touch();
      if (v$.value.industries.$error) return;
      break;
    case 'headquartersCountry':
      formDataValidation.headquartersCountry = e.locationOfHeadquarter;
      v$.value.headquartersCountry.$touch();
      if (v$.value.headquartersCountry.$error) return;
      break;
    case 'headquartersName':
      formDataValidation.headquartersName = e.nameOfHeadquarter;
      v$.value.headquartersName.$touch();
      if (v$.value.headquartersName.$error) return;
      break;
    case 'totalAllEmployees':
      formDataValidation.totalAllEmployees = e.numberOfEmployees;
      v$.value.totalAllEmployees.$touch();
      if (v$.value.totalAllEmployees.$error) return;
      break;
    default:
      return;
  }
  emit('updateValue', e);
}

function updateIndustry(payload: string, validationPath: string) {
  entitySettingsFormData.value.industry = payload;
  updateValue(entitySettingsFormData.value, validationPath);
}

function updateLocationOfHeadquarter(payload: string, validationPath: string) {
  entitySettingsFormData.value.locationOfHeadquarter = payload;
  updateValue(entitySettingsFormData.value, validationPath);
}

function updateTotalAllEmployees(payload: string, validationPath: string) {
  entitySettingsFormData.value.numberOfEmployees = payload;
  updateValue(entitySettingsFormData.value, validationPath);
}

const formDataValidation = reactive({
  companyName: entitySettingsFormData.value?.companyName,
  industries: entitySettingsFormData.value?.industry,
  headquartersCountry: entitySettingsFormData.value?.locationOfHeadquarter,
  headquartersName: entitySettingsFormData.value?.nameOfHeadquarter,
  totalAllEmployees: entitySettingsFormData.value?.numberOfEmployees,
});

const v$ = useVuelidate(
  {
    companyName: {
      required: helpers.withMessage('Please fill out this field.', required),
    },
    industries: {
      required: helpers.withMessage(
        'Please select the industry your company operates in.',
        required,
      ),
    },
    headquartersCountry: {
      required: helpers.withMessage('Please fill out this field.', required),
    },
    headquartersName: {
      required: helpers.withMessage('Please fill out this field.', required),
    },
    totalAllEmployees: {
      required: helpers.withMessage('Please fill out this field.', required),
    },
  },
  formDataValidation,
);
</script>
<template>
  <div>
    <section class="my-2 px-6 py-9">
      <div class="mb-9">
        <h2 class="mb-0.5 mt-0 font-semibold">
          {{ t('Add details about your company') }}
        </h2>
        <p class="text-sm text-gray-700">
          {{
            t('This information will be included in your regulatory exports.')
          }}
        </p>
      </div>
      <div class="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-7">
        <div class="col-span-2">
          <MlCompanyNameInput
            v-model="entitySettingsFormData.companyName"
            :errors="v$.companyName.$errors"
            @focusout="
              updateValue(entitySettingsFormData, v$.companyName.$path)
            "
          />
        </div>
        <div class="col-span-4">
          <MlIndustrySelect
            :modelValue="entitySettingsFormData.industry"
            :errors="v$.industries.$errors"
            @update:modelValue="updateIndustry($event, v$.industries.$path)"
          />
        </div>
      </div>
      <div class="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-7">
        <div class="col-span-2">
          <MlHeadquartersInput
            :modelValue="entitySettingsFormData.locationOfHeadquarter"
            :errors="v$.headquartersCountry.$errors"
            @update:modelValue="
              updateLocationOfHeadquarter($event, v$.headquartersCountry.$path)
            "
          />
        </div>
        <div class="col-span-2">
          <MlHeadquarterNameInput
            v-model="entitySettingsFormData.nameOfHeadquarter"
            :errors="v$.headquartersName.$errors"
            @focusout="
              updateValue(entitySettingsFormData, v$.headquartersName.$path)
            "
          />
        </div>
        <div class="col-span-2">
          <MlEmployeeNumberSelect
            :modelValue="entitySettingsFormData.numberOfEmployees"
            :errors="v$.totalAllEmployees.$errors"
            @update:modelValue="
              updateTotalAllEmployees($event, v$.totalAllEmployees.$path)
            "
          />
        </div>
      </div>
    </section>
  </div>
</template>
