import type { ChartData } from 'chart.js';
import type { PartialDeep } from 'type-fest';
import type { chartColors } from '@/styles/theme';

export enum SupportedChartType {
  BAR = 'bar',
  LINE = 'line',
  PIE = 'pie',
}

export type TUniversalChartData =
  | ChartData<'bar'>
  | ChartData<'line'>
  | ChartData<'pie'>
  | ChartData<'bar', { x: string; y: number }[]>;

export const isBar = (
  chartData: PartialDeep<TUniversalChartData>,
  type: `${SupportedChartType}`,
): chartData is ChartData<'bar'> => type === 'bar';
export const isLine = (
  chartData: PartialDeep<TUniversalChartData>,
  type: `${SupportedChartType}`,
): chartData is ChartData<'line'> => type === 'line';
export const isPie = (
  chartData: PartialDeep<TUniversalChartData>,
  type: `${SupportedChartType}`,
): chartData is ChartData<'pie'> => type === 'pie';

export interface ColorMap {
  name: string;
  color: (typeof chartColors)[number];
}
