<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import type { Tab } from '@/components/molecules/MlTabs/MlTabs.vue';
import MlTabs from '@/components/molecules/MlTabs/MlTabs.vue';

const router = useRouter();

const { t } = useI18n();

const route = useRoute();

const activeTab = computed(() => String(route.name));
const tabs = computed(() => {
  const quidanceTab = { name: t('Guidance / Help'), value: 'libraryGuidances' };
  const uploadsTab = { name: t('User uploads'), value: 'libraryUploads' };
  const sourcesTab = { name: t('Source documents'), value: 'librarySources' };
  return [quidanceTab, uploadsTab, sourcesTab];
});
</script>

<template>
  <div class="pt-5 px-6 flex flex-col">
    <div class="flex-1">
      <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
        {{ t('Codio Impact library') }}
      </h1>

      <div class="border-b flex justify-between">
        <MlTabs
          :modelValue="activeTab"
          :tabs="tabs"
          isEdgeToEdge
          class="mt-3"
          :hasWideBorderBottom="false"
          @update:modelValue="
            router.push({ name: $event.value, query: $event.query })
          "
        >
          <template #default="{ tab }">
            <span class="flex items-center space-x-2">
              {{ t((tab as Tab).name) }}
            </span>
          </template>
        </MlTabs>
        <div id="libraryLayoutControls" class="flex items-end" />
      </div>
      <section>
        <router-view />
      </section>
    </div>
  </div>
</template>
