import { watch, type Directive, type DirectiveBinding } from 'vue';
import { UserRole } from '@/__generated__/types';
import { currentUser } from '@/utils/composables/useCurrentUser/useCurrentUser';
import { hasRequiredRole } from '../helpers/hasRequiredRole';

const role: Directive = {
  mounted: (element, binding: DirectiveBinding<UserRole | UserRole[]>) => {
    if (!binding.value) return;

    watch(
      currentUser,
      (user) => {
        const roleRequirement = Array.isArray(binding.value)
          ? binding.value
          : [binding.value];
        const hasRole =
          roleRequirement.length === 0 ||
          roleRequirement.includes(user?.role as UserRole);

        if (!hasRole) {
          element['data-vrole-display'] = element.style.display;
          element.style.display = 'none';
        } else if (element.style.display === 'none') {
          element.style.display = element['data-vrole-display'] ?? 'initial';
        }
      },
      {
        immediate: true,
      },
    );
  },
};

const rolefrom: Directive = {
  mounted: (element, binding: DirectiveBinding<UserRole>) => {
    if (!binding.value) return;

    watch(
      currentUser,
      (user) => {
        const hasRole = user && hasRequiredRole(binding.value, user.role);

        if (!hasRole) {
          element['data-vrolefrom-display'] = element.style.display;
          element.style.display = 'none';
        } else if (element.style.display === 'none') {
          element.style.display =
            element['data-vrolefrom-display'] ?? 'initial';
        }
      },
      {
        immediate: true,
      },
    );
  },
};

export { role, rolefrom };
