<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { XIcon } from '@heroicons/vue/outline';
import { computed } from 'vue';
import { ActionPanelPosition } from './types';

const { t } = useI18n();

interface TProps {
  title?: string;
  description?: string;
  isOpened: boolean;
  hasIcon?: boolean;
  hasPaddingX?: boolean;
  position?: `${ActionPanelPosition}`;
  is4xlPanel?: boolean;
}

const props = withDefaults(defineProps<TProps>(), {
  title: '',
  description: '',
  position: ActionPanelPosition.FIXED,
  hasIcon: false,
  hasPaddingX: false,
});
const emit = defineEmits(['closePanel']);

const orientationClass = computed(
  () =>
    `${props.hasPaddingX ? 'px-6' : ''} ${props.is4xlPanel ? 'max-w-4xl' : 'max-w-lg'} top-0 pb-8 h-full border-l w-full right-0`,
);

defineOptions({ inheritAttrs: false });
</script>

<template>
  <div
    v-if="isOpened"
    :class="[
      orientationClass,
      position,
      'overflow-auto border-gray-200 bg-white flex flex-col',
    ]"
  >
    <div class="flex flex-col flex-1 mt-5">
      <h3 class="text-lg leading-6 text-gray-900">
        <slot name="icon" />
        <slot name="title">
          {{ t(props.title) }}
        </slot>
        <XIcon
          class="absolute w-4 top-3 right-3 hover:text-primary cursor-pointer"
          :title="t('Close')"
          @click="emit('closePanel')"
        />
      </h3>
      <p v-if="props.description" class="mt-2 text-xs text-gray-500">
        <slot name="description">
          {{ t(props.description) }}
        </slot>
      </p>
      <slot />
    </div>
  </div>
</template>
