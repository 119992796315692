<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  TaxonomyAssessmentBusinessActivityObjectiveEnum,
  type UpdateExternallyTaxonomyAssessmentBusinessActivityInput,
} from '@/__generated__/types';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import OgDocumentComment from '../../Assessment/OgDocumentComment.vue';
import OgRequestHelp from './OgRequestHelp.vue';

const props = defineProps<{
  userId: string;
  isSaving: boolean;
  projectName: string;
  activityName: string;
  objective: TaxonomyAssessmentBusinessActivityObjectiveEnum;
  objectiveDescription: string;
}>();

const emit = defineEmits<{
  save: [input: UpdateExternallyTaxonomyAssessmentBusinessActivityInput];
}>();

const { t } = useI18n();

const comment = ref('');
const file = ref<TPartialRepositoryFile | null>(null);

function handleConfirm() {
  emit('save', {
    objective: props.objective,
    objectiveComment: comment.value ?? null,
    objectiveFileId: file.value?._id ?? null,
  });
}

function handleDecline() {
  emit('save', {
    objective: null,
    objectiveComment: null,
    objectiveFileId: null,
  });
}
</script>

<template>
  <div>
    <p class="font-bold mb-2">{{ t('Project') }} / {{ projectName }}</p>

    <p class="mb-1 text-gray-500">
      {{ t('Business activity') }}: {{ activityName }}
    </p>

    <p class="mb-4 text-gray-500">
      {{ t('EU-Taxonomy objective') }}: {{ t(objective) }}
    </p>

    <p class="text-lg font-medium mb-3">
      {{ t('Confirm conditions for significant contribution') }}
    </p>

    <div
      class="max-h-56 overflow-y-auto px-3 pt-5 pb-3 shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] mb-4"
    >
      <p class="whitespace-pre-line break-normal">
        <MlHtmlContent :html="objectiveDescription" />
      </p>
    </div>

    <OgDocumentComment
      v-model:comment="comment"
      v-model:file="file"
      :showRecentFiles="false"
      :userId="userId"
    />
  </div>

  <div class="flex justify-end items-center space-x-3">
    <OgRequestHelp />
    <AtButton
      variant="outline"
      type="button"
      :disabled="isSaving"
      @click="handleDecline"
    >
      {{ t('No, I don’t confirm') }}
    </AtButton>
    <AtButton type="button" :disabled="isSaving" @click="handleConfirm">
      {{ t('Confirm') }}
    </AtButton>
  </div>
</template>
