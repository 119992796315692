<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <ul class="list-disc px-2">
    <li>
      {{
        t(
          'If you leave this option blank, your goal will automatically use the given datapoint from your start date as its starting value.',
        )
      }}
    </li>
    <li>
      {{
        t(
          'If you enter a number, your goal will either begin with that value or replace the value that was previously entered using Data Entry.',
        )
      }}
    </li>
  </ul>
</template>
