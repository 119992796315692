<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { computed, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import AtProgressNav, {
  type Item as ProgressNavItem,
} from '@/components/atoms/AtProgressNav.vue';
import PG_ASSESSMENT_QUERY from '@/components/pages/Modules/Taxonomy/Assessment/PgAssessment.query';
import {
  TaxonomyAssessmentStatusEnum,
  type PgTaxonomyAssessmentQuery,
  type PgTaxonomyAssessmentQueryVariables,
} from '@/__generated__/types';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const { result } = useQuery<
  PgTaxonomyAssessmentQuery,
  PgTaxonomyAssessmentQueryVariables
>(PG_ASSESSMENT_QUERY, {
  id: String(route.params.assessmentId),
});

watch(
  () => result.value?.getTaxonomyAssessment?.status,
  (newStatusValue) => {
    // Redirect to projects page because assessment can't be edited anymore.
    if (
      newStatusValue ===
      TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED_FINAL
    ) {
      router.replace({
        name: 'taxonomyProjects',
      });
    }
  },
  { immediate: true },
);

const {
  TAXONOMY_ASSESSMENT_DOCUMENTS,
  TAXONOMY_ASSESSMENT_COMPLETED,
  TAXONOMY_ASSESSMENT_DO_NO_SIGNIFICANT_HARM,
  TAXONOMY_ASSESSMENT_MINIMUM_SAFEGUARDS,
  TAXONOMY_ASSESSMENT_SUBSTANTIAL_CONTRIBUTION,
} = TaxonomyAssessmentStatusEnum;

const assessmentStatus = computed(
  () => result.value?.getTaxonomyAssessment.status,
);
const stepCompleted = computed(() => {
  switch (assessmentStatus.value) {
    case TAXONOMY_ASSESSMENT_COMPLETED:
      return 5;
    case TAXONOMY_ASSESSMENT_DOCUMENTS:
      return 4;
    case TAXONOMY_ASSESSMENT_MINIMUM_SAFEGUARDS:
      return 3;
    case TAXONOMY_ASSESSMENT_DO_NO_SIGNIFICANT_HARM:
      return 2;
    case TAXONOMY_ASSESSMENT_SUBSTANTIAL_CONTRIBUTION:
      return 1;
    default:
      return 0;
  }
});

const progressItems = computed<ProgressNavItem[]>(() => {
  // Initially only set the route that is currently active as "current" and other routes to "past".
  function getInitialStatus(name: string): ProgressNavItem['status'] {
    if (route.matched.some((item) => item.name === name)) {
      return 'current';
    }

    return 'past';
  }

  const items: ProgressNavItem[] = [
    {
      name: 'taxonomyAssessmentBusinessActivities',
      label: t('Business activities'),
      status: getInitialStatus('taxonomyAssessmentBusinessActivities'),
      hasActiveChild: false,
      children: [],
    },
    {
      name: 'taxonomyAssessmentSubstantialContribution',
      label: t('Substantial contribution'),
      status: getInitialStatus('taxonomyAssessmentSubstantialContribution'),
      hasActiveChild: false,
      children: [],
    },
    {
      name: 'taxonomyAssessmentDoNoSignificantHarm',
      label: t('DNSH'),
      status: getInitialStatus('taxonomyAssessmentDoNoSignificantHarm'),
      hasActiveChild: false,
      children: [],
    },
    {
      name: 'taxonomyAssessmentMinimumSafeguards',
      label: t('Minimum safeguards'),
      status: getInitialStatus('taxonomyAssessmentMinimumSafeguards'),
      hasActiveChild: false,
      children: [],
    },
    {
      name: 'taxonomyAssessmentDocuments',
      label: t('Optional documentation'),
      status: getInitialStatus('taxonomyAssessmentDocuments'),
      hasActiveChild: false,
      children: [],
    },
  ];

  // Because routes are ordered sequentially, item after the item with status set to "current" gets its status set to "next".
  // Items after "next" item are set to "future".
  for (let index = 0; index < items.length; index += 1) {
    if (items[index].status === 'current' && index < items.length - 1) {
      for (let bar = index + 1; bar < items.length; bar += 1) {
        items[bar].status = bar === index + 1 ? 'next' : 'future';
      }
    }
  }

  return items;
});

const taxonomyCoreDataMinimumSafeguards = computed(() => {
  return (
    result.value?.getOwnUser?.entity?.organization?.taxonomyCoreData
      ?.minimumSafeguards ?? null
  );
});
</script>

<template>
  <div class="flex flex-col min-h-full">
    <div class="sticky top-0 full-width bg-white p-5 border-b">
      <p class="text-xl">
        {{ t('Projects') }}
        <template v-if="result?.getTaxonomyAssessment">
          / {{ result.getTaxonomyAssessment.project.name }}
        </template>
      </p>
    </div>
    <div class="flex-1 flex space-x-8 px-6">
      <div class="flex-0 pt-5">
        <AtProgressNav :items="progressItems" />
      </div>
      <div
        v-if="result?.getTaxonomyAssessment"
        class="flex-1 flex flex-col relative pt-6"
      >
        <div class="absolute inset-x-0 top-0 bg-gray-200">
          <div
            class="h-1 bg-blue-600"
            :class="{
              'w-0': stepCompleted === 0,
              'w-1/5': stepCompleted === 1,
              'w-2/5': stepCompleted === 2,
              'w-3/5': stepCompleted === 3,
              'w-4/5': stepCompleted === 4,
              'w-full': stepCompleted === 5,
            }"
          />
        </div>

        <router-view
          :assessment="result.getTaxonomyAssessment"
          :taxonomyCoreDataMinimumSafeguards="taxonomyCoreDataMinimumSafeguards"
        />
      </div>
    </div>
  </div>
</template>
