<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import AtLoader from '@/components/atoms/AtLoader/AtLoader.vue';

type Props = {
  title: string;
};

defineProps<Props>();

const { t } = useI18n();
</script>

<template>
  <div
    class="progressCardAnimation group flex h-48 w-48 cursor-pointer flex-col rounded-lg bg-gray-200 p-6 ring-primary drop-shadow-md hover:ring-1"
  >
    <p class="mb-4 w-full truncate text-left group-hover:text-primary">
      {{ title }}
    </p>
    <AtLoader class="self-center" />

    <div class="m-auto text-gray-400">
      {{ t('Please wait, this may take a few minutes.') }}
    </div>
    <slot />
  </div>
</template>
