import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdateInsightMutation,
  UpdateInsightMutationVariables,
} from '@/__generated__/types';

export const UPDATE_INSIGHT = gql`
  mutation UpdateInsight($updateInsightInput: UpdateInsightInput!) {
    updateInsight(updateInsightInput: $updateInsightInput) {
      _id
      name
      question
      refreshInterval
      targetAction
      formular
      formularDependencies {
        _id
        name
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  UpdateInsightMutation,
  UpdateInsightMutationVariables
>;

const useUpdateInsightMutation = (options?: TOptions) =>
  useMutation<UpdateInsightMutation, UpdateInsightMutationVariables>(
    UPDATE_INSIGHT,
    options,
  );

export default useUpdateInsightMutation;
