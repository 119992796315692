import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type {
  UpdateTaxonomyAssessmentMutation,
  UpdateTaxonomyAssessmentMutationVariables,
} from '../../../__generated__/types';

const UPDATE_TAXONOMY_ASSESSMENT_MUTATION = gql`
  mutation updateTaxonomyAssessment(
    $assessmentId: String!
    $input: UpdateTaxonomyAssessmentInput!
  ) {
    updateTaxonomyAssessment(assessmentId: $assessmentId, input: $input) {
      _id

      turnover
      turnoverCompleteLater
      capex
      capexCompleteLater
      opex
      opexCompleteLater

      status

      businessActivities {
        _id
        activityFit
        activityTurnover
        doesNotHarm {
          circular
          adaptation
          mitigation
          pollution
          biodiversity
          water
        }
        minimumStandardsRespected
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  UpdateTaxonomyAssessmentMutation,
  UpdateTaxonomyAssessmentMutationVariables
>;

const useUpdateTaxonomyAssessment = (options?: TOptions) =>
  useMutation<
    UpdateTaxonomyAssessmentMutation,
    UpdateTaxonomyAssessmentMutationVariables
  >(UPDATE_TAXONOMY_ASSESSMENT_MUTATION, options);

export default useUpdateTaxonomyAssessment;
