<script setup lang="ts">
import { watchOnce } from '@vueuse/core';
import { useLazyQuery, useQuery } from '@vue/apollo-composable';
import { computed, ref, watch, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/solid';
import { notify } from '@kyvg/vue3-notification';
import { DownloadIcon } from '@heroicons/vue/outline';
import dayjs from '@/lib/dayjs/config';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import generateReport from '@/utils/helpers/generateReport';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import type {
  AdminRawReportQuery,
  TmReportsQuery,
  TmReportsPdfReportQuery,
  TmReportsPdfReportQueryVariables,
  TmReportsCustomQuestionnaireQuery,
  TmReportsCustomQuestionnaireQueryVariables,
} from '@/__generated__/types';
import {
  ReportingFrameworkEnum,
  PdfReportTypeEnum,
} from '@/__generated__/types';
import useRequestEsgReportMutation from '@/api/mutations/requestEsgReport.mutation';
import saveFile from '@/utils/helpers/saveFile';
import { useReportingPeriod } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import useEmissionFactorsCsv from '@/utils/composables/useEmissionFactorsCsv/useEmissionFactorsCsv';
import MlCard from '@/components/molecules/MlCard.vue';
import OgDateRangeInput from '@/components/organisms/OgDateRangeInput.vue';
import MlStepper from '@/components/molecules/MlStepper.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import useRequestCustomReportMutation from '@/api/mutations/requestCustomReport.mutation';
import OgS3FilePicker from '@/components/molecules/MlFilePicker/OgS3FilePicker.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import OgReportPerWorkspaceTable from './OgReportPerWorkspaceTable.vue';
import TM_REPORTS from './TmReports.query';
import OgReportRequestModal from './OgReportRequestModal.vue';
import CSV_REPORT_QUERY from './CsvReports.query';
import { ModalState, ReportType, type TProgress } from './types';
import TM_REPORTS_PDF_REPORT_QUERY from './TmReportsPDFReport.query';
import TM_REPORTS_CUSTOM_QUESTIONNAIRE from './TmReportsCustomQuestionnaire.query';

const { t } = useI18n();
const { currentUser } = useCurrentUser();
const {
  dateRange: allWorkspacesReportingPeriodDateRange,
  formatForBE: allWorkspacesReportingPeriodFormatForBE,
} = useReportingPeriod();
const {
  dateRange: perWorkspaceReportingPeriodDateRange,
  formatForBE: perWorkspaceReportingPeriodFormatForBE,
} = useReportingPeriod();

const emissionFactorsCsv = useEmissionFactorsCsv();

const { result } = useQuery<TmReportsQuery>(
  TM_REPORTS,
  {
    date: new Date(),
  },
  {
    fetchPolicy: 'network-only',
  },
);
const {
  result: resultRawReport,
  loading: loadingRawReport,
  load: fetchRawReport,
  error: errorRawReport,
} = useLazyQuery<AdminRawReportQuery>(CSV_REPORT_QUERY, null, {
  fetchPolicy: 'network-only',
});

const pdfReportQuery = useLazyQuery<TmReportsPdfReportQuery>(
  TM_REPORTS_PDF_REPORT_QUERY,
  undefined,
  {
    fetchPolicy: 'network-only',
  },
);

const encodedReport = computed(
  () => resultRawReport.value?.getDataPointsEncodedReport ?? '',
);
function initiateExcelReportDownload() {
  const entityIds = Array.from(
    new Set(result.value?.getReportingProgress.map((r) => r.entity._id)),
  );
  fetchRawReport(undefined, { entityIds });
}
watch(encodedReport, () =>
  generateReport(encodedReport.value, currentUser.value?.lastName, 'xlsx'),
);

const { mutate: requestEsgReport, error: requestEsgReportError } =
  useRequestEsgReportMutation();
const { mutate: requestCustomReport, error: requestCustomReportError } =
  useRequestCustomReportMutation();

const isDNK = computed(
  () =>
    result.value?.getOwnUser.entity.reportingFrameworks.includes(
      ReportingFrameworkEnum.DNK,
    ) ?? false,
);

const isOldestDataPointMoreThan2MonthOld = computed(() => {
  const oldestDataPoints = result.value?.getOldestESGDataPoint ?? [];

  return (
    oldestDataPoints.length > 0 &&
    dayjs
      .duration(
        new Date().getTime() -
          new Date(oldestDataPoints[0].createdAt).getTime(),
      )
      .asMonths() > 2
  );
});

const modalState = ref(ModalState.NO_DATA);
const modalKey = ref(ReportType.ESG);

const allReportTypes = computed(() => [
  {
    title: t('CO2 Footprint Report'),
    type: ReportType.CO2,
    description: t(
      'Get your company’s carbon footprint report covering scope 1, scope 2 and scope 3 emissions.',
    ),
    buttonIcon: DownloadIcon,
    buttonText: t('Download CO2 report'),
    action: () =>
      initiatePDFReportDownload([PdfReportTypeEnum.CARBON_FOOTPRINT]),
    loading:
      activePDFDownload.reportTypes?.includes(
        PdfReportTypeEnum.CARBON_FOOTPRINT,
      ) && !activePDFDownload.workspaceId,
  },
  {
    title: t('Emission Factors'),
    type: ReportType.EMISSION_FACTORS,
    description: t(
      'Get the complete list of emission factors that are used to calculate the CO2 footprint of your company.',
    ),
    buttonIcon: DownloadIcon,
    buttonText: t('Download emission factors'),
    action: () => emissionFactorsCsv.download(),
    loading: emissionFactorsCsv.loading.value,
  },
  {
    title: t('ESG Report'),
    type: ReportType.ESG,
    description: t(
      'Get a holistic report about your company’s environmental, social and governance (ESG) impact.',
    ),
    buttonIcon: DownloadIcon,
    buttonText: t(isDNK.value ? 'Request ESG report' : 'Download ESG Report'),
    action: () =>
      isDNK.value
        ? openEsgDNKReportModal()
        : initiatePDFReportDownload([
            PdfReportTypeEnum.STANDARD,
            PdfReportTypeEnum.QUALITATIVE,
          ]),
    loading:
      (activePDFDownload.reportTypes?.includes(PdfReportTypeEnum.STANDARD) ||
        activePDFDownload.reportTypes?.includes(
          PdfReportTypeEnum.QUALITATIVE,
        )) &&
      !activePDFDownload.workspaceId,
  },
  {
    title: t('Custom questionnaires'),
    type: ReportType.CUSTOM,
    description: t(
      'If you created custom questionnaires, this is where you can download your results.',
    ),
    buttonText: t('Request custom data'),
    action: openCustomReportModal,
    loading: loadingRawReport.value && isModalRevealed.value,
  },
]);

const isModalRevealed = ref(false);
const isPerWorkspaceTableOpen = ref(false);

function openEsgDNKReportModal() {
  modalKey.value = ReportType.ESG;
  isOldestDataPointMoreThan2MonthOld.value
    ? (modalState.value = ModalState.REQUEST)
    : (modalState.value = ModalState.NO_DATA);
  isModalRevealed.value = true;
}

function openCustomReportModal() {
  modalKey.value = ReportType.CUSTOM;
  modalState.value = ModalState.SELECT;
  isModalRevealed.value = true;
}

const organizationCustomQuestionnaires = computed(
  () => result.value?.getOrganizationQuestionnaires ?? [],
);
const customQuestionnaireIdSelected = ref('');

const customQuestionnaireSelected = computed(() =>
  organizationCustomQuestionnaires.value.find(
    (customQuestionnaire) =>
      customQuestionnaire._id === customQuestionnaireIdSelected.value,
  ),
);
const organizationCustomQuestionnairesOptions = computed(() =>
  organizationCustomQuestionnaires.value.reduce(
    (acc, questionnaire) => ({
      ...acc,
      [questionnaire._id]: `${questionnaire.name} - ${questionnaire.entity.name}`,
    }),
    {},
  ),
);

const locationsForCustomQuestionnaire = computed(
  () => resultCustomQuestionnaire.value?.getLocationsForCustomCategory ?? [],
);
const locationsOptions = computed(() => {
  // const entity = customQuestionnaireSelected.value?.entity;
  // if (!entity) return {} as Record<string, string>;
  const locations = locationsForCustomQuestionnaire.value;
  return locations.reduce((acc, curr) => ({ ...acc, [curr._id]: curr.name }), {
    // [entity._id]: entity.name
  } as Record<string, string>);
});

const locationIdsSelectedForCustomQuestionnaire = ref<string[]>([]);

type Format = 'standard' | 'special';
const formatOptions: { [k in Format]: string } = {
  standard: t('Standardized xslx export'),
  special: t('Special xlsx, csv or docx format'),
};

const formatSelected = ref<Format>('standard');
const formatDescription = ref('');
const file = defineModel<TPartialRepositoryFile | null>('file', {
  required: true,
});

const customReportSteps = computed(() => [
  ModalState.SELECT,
  ModalState.REQUEST,
]);
const customReportStep = computed(() =>
  customReportSteps.value.findIndex((step) => step === modalState.value),
);

function initiateExcelReportDownloadForCustomQuestionnaire() {
  try {
    watchOnce(resultRawReport, () => {
      isModalRevealed.value = false;
    });
    fetchRawReport(undefined, {
      entityIds: [customQuestionnaireSelected.value?.entity._id],
      locationIds: locationIdsSelectedForCustomQuestionnaire.value,
      dataPointCategoryIds: [customQuestionnaireSelected.value?.category?._id],
    });
  } catch (err) {
    if (errorRawReport.value)
      notify({ type: 'error', text: t(errorRawReport.value?.message) });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

async function handleRequestCustomReport() {
  if (formatSelected.value === 'standard') {
    initiateExcelReportDownloadForCustomQuestionnaire();
  } else if (formatSelected.value === 'special') {
    try {
      const locationIds = locationIdsSelectedForCustomQuestionnaire.value;
      await requestCustomReport({
        locationIds,
        format: formatSelected.value,
        fileId: file.value?._id,
        fileName: file.value?.filename,
        description: formatDescription.value,
      });
      modalState.value = ModalState.SUCCESS;
    } catch (err) {
      if (requestCustomReportError.value)
        notify({
          type: 'error',
          text: t(requestCustomReportError.value?.message),
        });
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}

const { result: resultCustomQuestionnaire, load: fetchCustomQuestionnaire } =
  useLazyQuery<
    TmReportsCustomQuestionnaireQuery,
    TmReportsCustomQuestionnaireQueryVariables
  >(
    TM_REPORTS_CUSTOM_QUESTIONNAIRE,
    {
      dataPointCategoryId:
        customQuestionnaireSelected.value?.category?._id ?? '',
    },
    { fetchPolicy: 'network-only' },
  );

watch(customQuestionnaireIdSelected, () =>
  fetchCustomQuestionnaire(TM_REPORTS_CUSTOM_QUESTIONNAIRE, {
    dataPointCategoryId: customQuestionnaireSelected.value?.category?._id ?? '',
  }),
);

async function requestEsgDNKReport(entityId?: string) {
  const entityIds = entityId
    ? [entityId]
    : Array.from(
        new Set(result.value?.getReportingProgress.map((r) => r.entity._id)),
      );
  try {
    await requestEsgReport({ entityIds });
  } catch (err) {
    if (requestEsgReportError.value)
      notify({ type: 'error', text: t(requestEsgReportError.value?.message) });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

// Check if button was clicked at least once.
// Used to know if `load` or `refetch` should be called.
const downloadPDFReportCalled = ref(false);
const activePDFDownload = reactive<{
  reportTypes: PdfReportTypeEnum[] | null;
  workspaceId: string | null;
}>({
  reportTypes: null,
  workspaceId: null,
});
async function initiatePDFReportDownload(
  reportTypes: PdfReportTypeEnum[],
  workspaceId?: string,
) {
  watchOnce(pdfReportQuery.result, () => {
    const pdfData = pdfReportQuery.result.value?.downloadPDFReport;
    activePDFDownload.reportTypes = null;
    activePDFDownload.workspaceId = null;
    if (pdfData) {
      const company =
        result.value?.getOwnUser.entity.entitySettings.companyName || '';
      const workspace = workspaceId
        ? result.value?.getReportingProgress.find(
            (item) => item.entity._id === workspaceId,
          )?.entity.name
        : 'all';
      const date = dayjs().format('DD-MM-YYYY');
      const name = `${company}_${reportTypes.join('_')}_report_${workspace}_${date}.pdf`;

      saveFile(pdfData, name, 'application/pdf');
    } else if (pdfData === '') {
      // If returned string is empty, it means there is no data.
      modalKey.value = reportTypes.includes(PdfReportTypeEnum.CARBON_FOOTPRINT)
        ? ReportType.CO2
        : ReportType.ESG;
      modalState.value = ModalState.NO_DATA;
      isModalRevealed.value = true;
    }
  });

  const variables: TmReportsPdfReportQueryVariables = {
    reportTypes,
    workspaceIds: workspaceId ? [workspaceId] : null,
    ...(workspaceId
      ? perWorkspaceReportingPeriodFormatForBE()
      : allWorkspacesReportingPeriodFormatForBE()),
  };
  if (!downloadPDFReportCalled.value) {
    pdfReportQuery.load(undefined, variables);
    downloadPDFReportCalled.value = true;
  } else {
    pdfReportQuery.refetch(variables);
  }
  activePDFDownload.reportTypes = reportTypes;
  activePDFDownload.workspaceId = workspaceId ?? null;
}

function closeModal() {
  isModalRevealed.value = false;
}

const workspaceProgress = computed<TProgress[]>(() =>
  Object.values(
    result.value?.getReportingProgress.reduce(
      (acc, curr) => {
        const entityIdString = curr.entity._id.toString();

        acc[entityIdString] ??= {
          id: String(curr.entity._id),
          name: curr.entity.name ?? '',
          pending: 0,
          accepted: 0,
          overDue: 0,
          total: 0,
          open: 0,
        };

        acc[entityIdString].pending += curr.pending;
        acc[entityIdString].accepted += curr.accepted;
        acc[entityIdString].overDue += curr.overDue;
        acc[entityIdString].total += curr.total;
        acc[entityIdString].open += curr.open;

        return acc;
      },
      {} as Record<string, TProgress>,
    ) ?? [],
  ),
);

function next() {
  if (
    customQuestionnaireIdSelected.value.length &&
    locationIdsSelectedForCustomQuestionnaire.value.length
  ) {
    modalState.value = ModalState.REQUEST;
  }
}
</script>

<template>
  <div class="pt-5 px-6 grid grid-cols-1 gap-6 xl:grid-cols-5">
    <div class="xl:col-span-4">
      <h2 class="text-lg font-medium leading-6 text-gray-900 sm:truncate mb-3">
        {{ t('Reports') }}
      </h2>
      <p class="mb-10 text-gray-500 text-sm">
        {{ t('Request or download your relevant sustainability reports.') }}
      </p>
      <OgDateRangeInput
        v-model="allWorkspacesReportingPeriodDateRange"
        class="mb-10"
      />
      <div class="mb-12 flex gap-6">
        <MlCard
          v-for="reportType in allReportTypes"
          :key="reportType.type"
          :title="reportType.title"
          :description="reportType.description"
          :buttonIcon="reportType.buttonIcon"
          :buttonText="reportType.buttonText"
          :loading="reportType.loading"
          @buttonClick="reportType.action()"
        />
      </div>
    </div>

    <div class="xl:col-span-3">
      <div
        class="flex cursor-pointer text-sm text-gray-500 items-end"
        :class="{ 'mb-2': isPerWorkspaceTableOpen }"
        @click="isPerWorkspaceTableOpen = !isPerWorkspaceTableOpen"
      >
        <p>
          <span class="flex font-bold text-gray-900">
            {{ t('Report per workspace') }}
          </span>
          {{ t('Request the CO2 and ESG reports per workspace.') }}
        </p>
        <AtIconButton
          v-if="isPerWorkspaceTableOpen"
          :title="t('Open workspace table')"
          class="ml-2 w-10 text-gray-500 hover:text-primary"
          :icon="ChevronDownIcon"
        />
        <AtIconButton
          v-else
          :title="t('Open workspace table')"
          class="ml-2 w-10 text-gray-500 hover:text-primary"
          :icon="ChevronUpIcon"
        />
      </div>
      <OgDateRangeInput
        v-show="isPerWorkspaceTableOpen"
        v-model="perWorkspaceReportingPeriodDateRange"
      />
    </div>

    <OgReportPerWorkspaceTable
      v-if="isPerWorkspaceTableOpen"
      :workspaces="workspaceProgress"
      :isDNK="isDNK"
      :activePDFDownload="activePDFDownload"
      class="xl:col-span-full"
      @requestCo2Report="
        initiatePDFReportDownload([PdfReportTypeEnum.CARBON_FOOTPRINT], $event)
      "
      @requestEsgReport="
        isDNK
          ? requestEsgDNKReport($event)
          : initiatePDFReportDownload([PdfReportTypeEnum.STANDARD], $event)
      "
    />
    <p class="text-sm text-gray-500 xl:col-span-3">
      <span class="block font-bold text-gray-900">
        {{ t('Download platform data') }}
      </span>
      {{
        t(
          'Get your whole platform data as Excel file. It includes your company information and all submitted data points.',
        )
      }}
    </p>
    <div class="xl:col-span-3">
      <AtButton
        variant="outline"
        :loading="loadingRawReport && !isModalRevealed"
        :icon="DownloadIcon"
        @click.stop="initiateExcelReportDownload"
      >
        {{ t('Download all data') }}
      </AtButton>
    </div>
    <OgReportRequestModal
      :isRevealed="isModalRevealed"
      :modalKey="modalKey"
      :modalState="modalState"
      :formatSelected="formatSelected"
      class="min-w-[700px] gap-10 px-10"
      @closeModal="closeModal"
      @next="next"
      @requestCustomReport="handleRequestCustomReport"
      @requestEsgDNKReport="requestEsgDNKReport"
    >
      <template
        v-if="
          (modalState === ModalState.SELECT ||
            modalState === ModalState.REQUEST) &&
          modalKey === ReportType.CUSTOM
        "
      >
        <MlStepper
          :value="customReportStep"
          :steps="['Choose data', 'Select format']"
          @stepClicked="
            $event === 'Choose data'
              ? (modalState = ModalState.SELECT)
              : (modalState = modalState)
          "
        />
        <div v-if="modalState === ModalState.SELECT">
          <MlSelect
            v-model="customQuestionnaireIdSelected"
            :label="t('Choose the custom questionnaire:')"
            wrapperClass="mb-5 max-w-[400px]"
            :options="organizationCustomQuestionnairesOptions"
            :placeholder="t('Choose the created custom questionnaire')"
          />
          <MlSelect
            v-model="locationIdsSelectedForCustomQuestionnaire"
            multiple
            :label="t('Choose the projects:')"
            wrapperClass="mb-5 max-w-[400px]"
            :options="locationsOptions"
            :placeholder="
              t('Choose projects affiliated to this custom questionnaire')
            "
          />
        </div>
        <div v-if="modalState === ModalState.REQUEST" class="flex flex-col">
          <MlSelect
            v-model="formatSelected"
            variant="inline"
            wrapperClass="mb-6"
            :label="t('Do you need a specific format for your report?')"
            :options="formatOptions"
            sortedOptions
          />
          <div v-if="formatSelected === 'special'" class="flex flex-col gap-5">
            <OgS3FilePicker
              v-model="file"
              class="max-w-xs"
              :placeholder="t('Select or drop your file')"
              :showRecentFiles="true"
              compact
            />
            <MlTextarea
              v-model="formatDescription"
              hideMenueBar
              wrapperClass="max-w-[600px]"
              :label="t('Description')"
              :placeholder="
                t(
                  'Explain if you need anything specific for this format. For example, if xlsx or docx file: in which column should the answer be inserted.',
                )
              "
            />
          </div>
        </div>
      </template>
    </OgReportRequestModal>
  </div>
</template>
