import {
  DoubleMaterialityIroImpactType,
  DoubleMaterialitySurveyType,
} from '@/__generated__/types';
import { notEmpty } from '@/utils/helpers/notEmpty';
import type { ResultCalculation, Survey } from './types';

export type Materiality = boolean | null;

export const STAKEHOLDER_ENGAGE_SURVEY_NAME = 'Surveys';

export function calculateMaterialityForActualNegative(
  scale: number | null,
  scope: number | null,
  irremediability: number | null,
): Materiality {
  if (
    typeof scale !== 'number' ||
    typeof scope !== 'number' ||
    typeof irremediability !== 'number'
  ) {
    return null;
  }

  return scale >= 4.5 || scope >= 4.5 || irremediability >= 4.5;
}

export function calculateMaterialityForPotentialNegative(
  scale: number | null,
  scope: number | null,
  irremediability: number | null,
  likelihood: number | null,
): Materiality {
  if (
    typeof scale !== 'number' ||
    typeof scope !== 'number' ||
    typeof irremediability !== 'number' ||
    typeof likelihood !== 'number'
  ) {
    return null;
  }

  const max = Math.max(scale, scope, irremediability);

  return max >= 4.5 || max + likelihood >= 7;
}

export function calculateMaterialityForActualPositive(
  scale: number | null,
  scope: number | null,
): Materiality {
  if (typeof scale !== 'number' || typeof scope !== 'number') {
    return null;
  }

  return scale >= 4.5 || scope >= 4.5;
}

export function calculateMaterialityForPotentialPositive(
  scale: number | null,
  scope: number | null,
  likelihood: number | null,
): Materiality {
  if (
    typeof scale !== 'number' ||
    typeof scope !== 'number' ||
    typeof likelihood !== 'number'
  ) {
    return null;
  }

  const max = Math.max(scale, scope);

  return max >= 4.5 || max + likelihood >= 7;
}

export function calculateMaterialityForImpact(
  scale: number | null,
  scope: number | null,
  irremediability: number | null,
  likelihood: number | null,
  type: DoubleMaterialityIroImpactType | null,
): Materiality {
  switch (type) {
    case DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE:
      return calculateMaterialityForActualNegative(
        scale,
        scope,
        irremediability,
      );
    case DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE:
      return calculateMaterialityForPotentialNegative(
        scale,
        scope,
        irremediability,
        likelihood,
      );
    case DoubleMaterialityIroImpactType.ACTUAL_POSITIVE:
      return calculateMaterialityForActualPositive(scale, scope);
    case DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE:
      return calculateMaterialityForPotentialPositive(scale, scope, likelihood);
    default:
      return null;
  }
}

export function calculateMaterialityForRiskAndOpportunity(
  likelihood: number | null,
  potentialMagnitude: number | null,
): boolean | null {
  if (
    typeof likelihood !== 'number' ||
    typeof potentialMagnitude !== 'number'
  ) {
    return null;
  }

  return likelihood >= 4.5 || potentialMagnitude >= 4.5;
}

export function calculateSeverityForImpact(
  scale: number | null,
  scope: number | null,
  irremediability: number | null,
  type: DoubleMaterialityIroImpactType | null,
): number | null {
  if (typeof scale !== 'number' || typeof scope !== 'number') {
    return null;
  }

  switch (type) {
    case DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE:
      return typeof irremediability !== 'number'
        ? null
        : (scale + scope + irremediability) / 3;
    case DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE:
      return typeof irremediability !== 'number'
        ? null
        : (scale + scope + irremediability) / 3;
    case DoubleMaterialityIroImpactType.ACTUAL_POSITIVE:
      return (scale + scope) / 2;
    case DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE:
      return (scale + scope) / 2;
    default:
      return null;
  }
}

export function calculateTotalScoreForImpact(
  severity: number | null,
  likelihood: number | null,
  type: DoubleMaterialityIroImpactType | null,
): number | null {
  switch (type) {
    case DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE:
    case DoubleMaterialityIroImpactType.ACTUAL_POSITIVE:
      return severity;
    case DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE:
    case DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE:
      return typeof likelihood !== 'number' || typeof severity !== 'number'
        ? null
        : (severity + likelihood) / 2;
    default:
      return null;
  }
}

export function calculateTotalScoreForRiskAndOpportunity(
  likelihood: number | null,
  potentialMagnitude: number | null,
): number | null {
  if (
    typeof likelihood !== 'number' ||
    typeof potentialMagnitude !== 'number'
  ) {
    return null;
  }

  return (likelihood + potentialMagnitude) / 2;
}

export function calculateIroImpactSurveysRating(
  iroImpactId: string,
  surveys: Survey[],
) {
  const surveysWithValues = surveys.filter(
    (survey) =>
      notEmpty(survey.answers[iroImpactId]) &&
      survey.type === DoubleMaterialitySurveyType.IMPACT &&
      survey.answers[iroImpactId]! > 0,
  );

  if (surveysWithValues.length > 0) {
    return (
      surveysWithValues.reduce(
        (acc, item) => acc + item.answers[iroImpactId]!,
        0,
      ) / surveysWithValues.length
    );
  }

  return null;
}

export function calculateIroRiskAndOpportunitySurveysRating(
  iroRiskAndOpportunityId: string,
  surveys: Survey[],
) {
  const surveysWithValues = surveys.filter(
    (survey) =>
      notEmpty(survey.answers[iroRiskAndOpportunityId]) &&
      survey.type === DoubleMaterialitySurveyType.RISKS_OPPORTUNITIES &&
      survey.answers[iroRiskAndOpportunityId]! > 0,
  );

  if (surveysWithValues.length > 0) {
    return (
      surveysWithValues.reduce(
        (acc, item) => acc + item.answers[iroRiskAndOpportunityId]!,
        0,
      ) / surveysWithValues.length
    );
  }

  return null;
}

export function calculateFinal(
  internal: number | null,
  stakeholders: number | null,
  resultCalculation: Partial<ResultCalculation>,
) {
  if (
    notEmpty(internal) &&
    notEmpty(stakeholders) &&
    notEmpty(resultCalculation.internal) &&
    notEmpty(resultCalculation.stakeholders)
  ) {
    return (
      internal * resultCalculation.internal +
      stakeholders * resultCalculation.stakeholders
    );
  }

  return null;
}

export function calculateFinalMateriality(final: number | null): Materiality {
  if (notEmpty(final)) {
    return final >= 4.5;
  }

  return null;
}
