import { gql } from '@apollo/client/core';

export default gql`
  query PgDoubleMaterialityTeam($allTeamsUsers: Boolean!) {
    getTeamUsers(includeInactive: true) {
      _id
      ...doubleMaterialityTeamUserFields
    }

    getAllTeamsUsers @include(if: $allTeamsUsers) {
      _id
      ...doubleMaterialityTeamUserFields
    }
  }

  fragment doubleMaterialityTeamUserFields on User {
    _id
    firstName
    lastName
    jobTitle
    jobDepartment
    email
    role
    language
    assignments {
      _id
      doubleMateriality
    }
    entities {
      _id
      name
    }
    invitedBy {
      _id
    }
  }
`;
