import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  CreateEntityLocationMutation,
  CreateEntityLocationMutationVariables,
} from '@/__generated__/types';

export const CREATE_ENTITY_LOCATION = gql`
  mutation CreateEntityLocation(
    $createEntityLocationInput: CreateEntityLocationInput!
  ) {
    createEntityLocation(entityLocation: $createEntityLocationInput) {
      _id
      name
    }
  }
`;

type TOptions = UseMutationOptions<
  CreateEntityLocationMutation,
  CreateEntityLocationMutationVariables
>;

const useCreateEntityLocationMutation = (options?: TOptions) =>
  useMutation<
    CreateEntityLocationMutation,
    CreateEntityLocationMutationVariables
  >(CREATE_ENTITY_LOCATION, options);

export default useCreateEntityLocationMutation;
