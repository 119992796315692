import { useLazyQuery } from '@vue/apollo-composable';
import type { UseEmissionFactorsCsvQuery } from '@/__generated__/types';
import waitForApolloQuery from '@/utils/helpers/waitForApolloQuery';
import saveFile from '../../helpers/saveFile';
import USE_EMISSION_FACTORS_CSV_QUERY from './useEmissionFactorsCsv.query';

export default function useEmissionFactorsCsv() {
  const query = useLazyQuery<UseEmissionFactorsCsvQuery>(
    USE_EMISSION_FACTORS_CSV_QUERY,
    undefined,
    { fetchPolicy: 'network-only' },
  );

  const download = async () => {
    // eslint-disable-next-line no-unused-expressions
    query.load() || query.refetch();
    const result = await waitForApolloQuery(query);

    if (result && !(result instanceof Error)) {
      saveFile(
        result.getEmissionFactorsCsv ?? '',
        'emission_factors.xlsx',
        'application/vnd.ms-excel;charset=utf-8',
      );
    }
  };

  return {
    loading: query.loading,
    download,
  };
}
