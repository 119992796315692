import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type {
  ToggleCategoriesMutation,
  ToggleCategoriesMutationVariables,
} from '../../../__generated__/types';

const TOGGLE_CATEGORIES_MUTATION = gql`
  mutation ToggleCategories(
    $locationId: String!
    $enable: [String!]!
    $disable: [String!]!
  ) {
    toggleCategories(
      locationId: $locationId
      enable: $enable
      disable: $disable
    ) {
      _id
      assignments {
        _id
        categories {
          _id
          category
          isEnabled
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  ToggleCategoriesMutation,
  ToggleCategoriesMutationVariables
>;

const useToggleCategoriesMutation = (options?: TOptions) =>
  useMutation<ToggleCategoriesMutation, ToggleCategoriesMutationVariables>(
    TOGGLE_CATEGORIES_MUTATION,
    options,
  );

export default useToggleCategoriesMutation;
