<script setup lang="ts">
interface TProps {
  total: string[];
  current: string[];
}

const props = defineProps<TProps>();
</script>

<template>
  <div
    class="absolute flex h-3.5 w-3.5 -translate-y-1.5 translate-x-3.5 items-center rounded-full bg-rose-400 text-white"
  >
    <span class="w-full text-center text-[10px]">
      {{ props.total.length - props.current.length }}
    </span>
  </div>
</template>
