<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { Component } from 'vue';
import { useI18n } from 'vue-i18n';
import { ListboxOption, ListboxOptions } from '@headlessui/vue';
import { ChevronRightIcon } from '@heroicons/vue/outline';

type Props = {
  allowSelectNone: boolean;
  isOpen: boolean;
  multiple: boolean;
  options: Map<string, string>;
  usePortal: boolean;
  disabledOptionText?: string;
  disabledOptions?: string[];
  nestedOptions?: Record<string, { label: string; value: string }[]>;
  nestedOptionsModelValue?: Record<string, string>;
  groupedOptions?: {
    label: string;
    options: { label: string; value: string }[];
  }[];
  noneLabel?: string;
  optionIcons?: Record<string, Component>;
};

const props = withDefaults(defineProps<Props>(), {
  disabledOptionText: '',
  disabledOptions: () => [],
  nestedOptions: undefined,
  noneLabel: undefined,
  optionIcons: () => ({}),
  nestedOptionsModelValue: undefined,
  groupedOptions: () => [],
});

const emit = defineEmits<{
  'update:nestedOptionsModelValue': [
    value: Required<Props['nestedOptionsModelValue']>,
  ];
  heightChanged: [value: number];
}>();

const { t } = useI18n();

watch(
  () => props.isOpen,
  (isOpen) => {
    Array.from(document.getElementsByClassName('dialog-panel')).forEach(
      (element) => {
        element.classList.toggle('overflow-y-hidden', isOpen);
      },
    );
  },
);

const listboxOptions = ref<typeof ListboxOptions>();
const listboxOptionsHeight = computed(
  () => listboxOptions.value?.el?.clientHeight ?? 0,
);
watch(listboxOptionsHeight, () =>
  emit('heightChanged', listboxOptionsHeight.value),
);

const isDisabledOption = (option: string) =>
  props.disabledOptions.includes(option);
const hasNestedOptions = (option: string) => !!props.nestedOptions?.[option];
</script>

<template>
  <ListboxOptions
    ref="listboxOptions"
    class="max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg focus:outline-primary sm:text-sm"
    :class="{
      'absolute -bottom-1 z-50 translate-y-full': !usePortal,
    }"
  >
    <template v-if="groupedOptions.length > 0">
      <template v-for="option in groupedOptions">
        {{ t(option.label) }}
        <ListboxOption
          v-for="o in option.options"
          :key="o.value"
          v-slot="{ active, selected }"
          class="group cursor-pointer break-words"
          :value="o.value"
          as="template"
          data-cy="ListboxOption"
        >
          <li
            class="relative cursor-default select-none px-4 py-2"
            :class="{
              'bg-primary text-white': active,
              'text-gray-900': !active,
            }"
          >
            <div class="flex items-center gap-3">
              <input
                :type="multiple ? 'checkbox' : 'radio'"
                class="checked:animate-none hover:border-white checked:hover:border-primary group-hover:border-white"
                :class="
                  multiple
                    ? 'checkbox-xs checkbox-primary'
                    : 'radio-xs radio-primary'
                "
                :checked="selected"
              />
              <span class="block">
                {{ t(o.label) }}
              </span>
            </div>
          </li>
        </ListboxOption>
      </template>
    </template>
    <template v-else>
      <ListboxOption
        v-if="allowSelectNone"
        v-slot="{ active, selected }"
        :value="undefined"
        class="group"
        as="template"
        data-cy="ListboxOptionNone"
      >
        <li
          class="relative cursor-default select-none px-4 py-2"
          :class="{
            'bg-primary text-white': active,
            'text-gray-900': !active,
          }"
        >
          <div class="flex items-center gap-3">
            <input
              :type="multiple ? 'checkbox' : 'radio'"
              class="checked:animate-none hover:border-white checked:hover:border-primary group-hover:border-white"
              :class="
                multiple
                  ? 'checkbox checkbox-xs checkbox-primary'
                  : 'radio radio-xs radio-primary'
              "
              :checked="selected"
            />
            <span class="block">
              {{ t(noneLabel ?? 'None') }}
            </span>
          </div>
        </li>
      </ListboxOption>
      <ListboxOption
        v-for="[key, option] in options"
        :key="key"
        v-slot="{ active, selected }"
        class="group cursor-pointer break-words"
        :value="key"
        as="template"
        data-cy="ListboxOption"
        :disabled="isDisabledOption(key)"
      >
        <VDropdown
          v-if="hasNestedOptions(key)"
          placement="right-start"
          :distance="0"
          :triggers="['hover']"
          :popperTriggers="['hover']"
        >
          <li
            class="relative cursor-default select-none px-4 py-2"
            :class="{
              'bg-primary text-white': active,
              'text-gray-900': !active,
              'opacity-40': isDisabledOption(key),
            }"
          >
            <div class="flex items-center gap-3">
              <input
                :type="multiple ? 'checkbox' : 'radio'"
                class="checked:animate-none"
                :class="
                  multiple
                    ? 'checkbox checkbox-xs checkbox-primary'
                    : 'radio radio-xs radio-primary'
                "
                :checked="selected"
                :disabled="isDisabledOption(key)"
              />
              <span class="flex w-full items-center justify-between">
                <span
                  class="inline-flex items-center"
                  :title="
                    isDisabledOption(key) ? disabledOptionText : undefined
                  "
                >
                  {{ t(option) }}
                  <component :is="optionIcons[key]" class="h-4 w-4 ml-1.5" />
                </span>
                <ChevronRightIcon class="w-5" />
              </span>
            </div>
          </li>

          <template #popper>
            <ul
              tabindex="0"
              class="max-h-60 w-full overflow-auto bg-white text-base shadow-lg focus:outline-primary sm:text-sm"
            >
              <li
                v-for="nestedOption in nestedOptions?.[key]"
                :key="nestedOption.value"
                class="relative cursor-default select-none px-4 py-2"
                :class="{
                  'text-gray-900 hover:bg-primary hover:text-white':
                    nestedOptionsModelValue?.[key] !== nestedOption.value,
                  'bg-primary text-white':
                    nestedOptionsModelValue?.[key] === nestedOption.value,
                }"
                @click.stop="
                  $emit('update:nestedOptionsModelValue', {
                    ...nestedOptionsModelValue,
                    [key]: nestedOption.value,
                  })
                "
              >
                <a>{{ t(nestedOption.label) }}</a>
              </li>
            </ul>
          </template>
        </VDropdown>
        <li
          v-else
          class="relative cursor-default select-none px-4 py-2"
          :class="{
            'bg-primary text-white': active,
            'text-gray-900': !active,
            'opacity-40': isDisabledOption(key),
          }"
        >
          <div class="flex items-center gap-3">
            <input
              :type="multiple ? 'checkbox' : 'radio'"
              class="checked:animate-none"
              :class="
                multiple
                  ? 'checkbox checkbox-xs checkbox-primary'
                  : 'radio radio-xs radio-primary'
              "
              :checked="selected"
              :disabled="isDisabledOption(key)"
            />
            <span class="flex w-full items-center justify-between">
              <span
                class="inline-flex items-center"
                :title="isDisabledOption(key) ? disabledOptionText : undefined"
              >
                {{ t(option) }}
                <component :is="optionIcons[key]" class="h-4 w-4 ml-1.5" />
              </span>
            </span>
          </div>
        </li>
      </ListboxOption>
    </template>
  </ListboxOptions>
</template>
