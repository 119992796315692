import { gql } from '@apollo/client/core';

export default gql`
  query CsvReport(
    $dataPointTypeIds: [String!]
    $dateFrom: String!
    $dateTo: String!
    $locationId: String
  ) {
    getDataPointsEncodedReport(
      dataPointTypeIds: $dataPointTypeIds
      dateFrom: $dateFrom
      dateTo: $dateTo
      locationId: $locationId
    )
  }
`;
