import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DeleteEntityKpiMutation,
  DeleteEntityKpiMutationVariables,
} from '@/__generated__/types';

export const DELETE_ENTITY_KPI = gql`
  mutation DeleteEntityKPI($deleteEntityKPIInput: DeleteEntityKPIInput!) {
    deleteEntityKPI(entityKPI: $deleteEntityKPIInput) {
      _id
    }
  }
`;

export type TOptions = UseMutationOptions<
  DeleteEntityKpiMutation,
  DeleteEntityKpiMutationVariables
>;

const useDeleteEntityKPIMutation = (options?: TOptions) =>
  useMutation<DeleteEntityKpiMutation, DeleteEntityKpiMutationVariables>(
    DELETE_ENTITY_KPI,
    options,
  );

export default useDeleteEntityKPIMutation;
