<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { XIcon } from '@heroicons/vue/outline';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtTopBar from '@/components/atoms/AtTopBar.vue';

type TProps = {
  shown: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  shown: false,
});
const emit = defineEmits(['close']);

const { t } = useI18n();
const impersonatedAs = window.localStorage.getItem('X-Impersonate-As-Email');
const isImpersonated = !!impersonatedAs;

const impersonateAsEmail = ref('');

const impersonate = () => {
  window.localStorage.setItem(
    'X-Impersonate-As-Email',
    impersonateAsEmail.value,
  );
  window.location.reload();
};

const reset = () => {
  window.localStorage.removeItem('X-Impersonate-As-Email');
  window.location.reload();
};
</script>

<template>
  <AtTopBar :isShown="isImpersonated || props.shown" class="bg-rose-400">
    <div v-if="isImpersonated">
      {{ t('Impersonated as:') }} {{ impersonatedAs }}
      <span class="cursor-pointer" @click="reset"> (Go back) </span>
    </div>
    <div v-else-if="props.shown">
      <form @submit.prevent="impersonate">
        <AtInput
          v-model="impersonateAsEmail"
          type="email"
          label="Enter the email address of the user that you want to impersonate as"
        />
      </form>
    </div>

    <div v-if="!isImpersonated" class="flex flex-row items-center">
      <XIcon class="ml-6 h-6 w-6 cursor-pointer" @click="emit('close')" />
    </div>
  </AtTopBar>
</template>
