<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';

const props = defineProps<{
  initialComment: string;
}>();

const emit = defineEmits<{
  close: [];
  save: [event: string];
}>();

const { t } = useI18n();

const explanation = ref('');

watch(
  () => props.initialComment,
  (newInitialComment) => {
    explanation.value = newInitialComment;
  },
  { immediate: true },
);

const isValid = computed(() => explanation.value.trim().length > 0);

function handleClose() {
  emit('close');
}

async function handleSubmit() {
  if (isValid.value) {
    emit('save', explanation.value);
  }
}
</script>

<template>
  <MlDialog isOpen :closeOnOutside="false" @close="handleClose">
    <template #title>
      {{ t('Insert explanation on choosing this sustainability matter') }}
    </template>

    <template #default="{ close }">
      <form
        :key="initialComment"
        class="min-w-[600px] pt-9"
        @submit.prevent="handleSubmit"
      >
        <hr class="mb-4" />
        <p class="text-xs mb-8">
          {{
            t(
              'This does not need to be long, but helps your team and your auditor determine reasons for including this sustainability matter.',
            )
          }}
        </p>
        <textarea
          v-model="explanation"
          class="mb-10 border w-full rounded py-2 px-3 resize-none min-h-[80px]"
          :placeholder="t('Type here')"
        />
        <div class="flex space-x-2 justify-between">
          <AtButton type="button" variant="outline" @click="close">
            {{ t('Cancel') }}
          </AtButton>
          <AtButton type="submit" :disabled="!isValid">
            {{ t('Save') }}
          </AtButton>
        </div>
      </form>
    </template>
  </MlDialog>
</template>
