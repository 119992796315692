<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { DownloadIcon } from '@heroicons/vue/outline';
import {
  DataPointTypeEmissionTypeEnum,
  PdfReportTypeEnum,
  ReportingFrameworkEnum,
} from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import MlTabs, { type Tab } from '@/components/molecules/MlTabs/MlTabs.vue';
import { useCsvExport, usePdfExport } from './composables';

export type DataReadyEvent = {
  type: 'carbonFootprint' | 'standard' | 'qualitative';
  data: {
    dataPointTypeIds: string[];
    dataPointTypeNames: string[];
    dateFrom: string;
    dateTo: string;
    project: string;
    categories: string[];
    workspaces: string[];
    scope2EmissionTypeExcluded?:
      | DataPointTypeEmissionTypeEnum.SCOPE_2_LOCATION_BASED
      | DataPointTypeEmissionTypeEnum.SCOPE_2_MARKET_BASED;
  };
};

export type SingleStandardTableExportEvent = {
  dataPointTypeId: string;
  dataPointTypeName: string;
};

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const { currentUser } = useCurrentUser();

const adminMode = inject(
  'adminMode',
  computed(() => false),
);

const carbonFootprintTabValue = computed(() =>
  adminMode.value
    ? 'adminResultsCarbonFootprintView'
    : 'resultsCarbonFootprintView',
);
const standardTabValue = computed(() =>
  adminMode.value ? 'adminResultsStandardView' : 'resultsStandardView',
);

const activeTab = computed(() => String(route.name));
const tabs = computed(() => {
  const numericalTab = {
    name: t('All data'),
    value: standardTabValue.value,
  };
  const carbonFootprintTab = {
    name: t('Carbon footprint'),
    value: carbonFootprintTabValue.value,
  };

  return [numericalTab, carbonFootprintTab];
});

const isExportingAllCsv = ref(false);
const isExportingAllDocx = ref(false);
const carbonFootprintData = ref<DataReadyEvent['data']>();
const standardData = ref<DataReadyEvent['data']>();

const { loading: isCsvLoading, exportFile: exportAsCsvFile } = useCsvExport();
const { loading: isPdfLoading, exportFile: exportAsPdfFile } = usePdfExport();

const hasResultsExport = computed(() => {
  return (
    !adminMode.value &&
    !currentUser.value?.entity.reportingFrameworks.includes(
      ReportingFrameworkEnum.DNK,
    )
  );
});

function handleExportAllAsCsv() {
  isExportingAllCsv.value = true;

  if (
    activeTab.value === 'adminResultsCarbonFootprintView' ||
    activeTab.value === 'resultsCarbonFootprintView'
  ) {
    if (carbonFootprintData.value) {
      exportAsCsvFile(
        carbonFootprintData.value.dataPointTypeIds,
        carbonFootprintData.value.dataPointTypeNames,
        carbonFootprintData.value.dateFrom,
        carbonFootprintData.value.dateTo,
        carbonFootprintData.value.project,
      );
    }
  } else if (standardData.value) {
    exportAsCsvFile(
      standardData.value.dataPointTypeIds,
      standardData.value.dataPointTypeNames,
      standardData.value.dateFrom,
      standardData.value.dateTo,
      standardData.value.project,
    );
  }
}

function getStyles(doc: Document) {
  let styles = '';
  for (let i = 0; i < doc.styleSheets.length; i += 1) {
    const rules = doc.styleSheets[i].cssRules;
    for (let j = 0; j < rules.length; j += 1) {
      styles += `${rules[j].cssText}\n`;
    }
  }
  return styles;
}

const sectionRef = ref<HTMLElement>();

function handleExportSectionAsHtml() {
  const a = document.createElement('a');

  const style = document.createElement('style');
  style.innerHTML = getStyles(document);

  const html = `<!DOCTYPE html>
  <html lang="en" class="h-full bg-white" translate="no">
  <head>
    <meta charset="UTF-8" />
    <link rel="icon" href="/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="robots" content="noindex" />
  </head>
  <body class="h-full">
  <div class="flex w-full justify-center p-2 bg-emerald-500 text-white">
  ${t('Please copy the text by selecting all text (either by using mouse pad or ctrl+A). Then open an empty Word document, and paste the values (by either using mouse pad - right click and “Paste”, or pressing ctrl+V).')}
  </div>
  ${sectionRef.value?.outerHTML.replace(/<svg[^>]*>[\s\S]*?<\/svg>/g, '')}</body><html>`;

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  doc.querySelector('head')?.appendChild(style);

  const pgStandardViewControlId = doc.querySelector('#pgStandardViewControlId');
  const pgCarbonFootprintViewControlId = doc.querySelector(
    '#pgCarbonFootprintViewControlId',
  );
  pgStandardViewControlId?.parentNode?.removeChild(pgStandardViewControlId);
  pgCarbonFootprintViewControlId?.parentNode?.removeChild(
    pgCarbonFootprintViewControlId,
  );

  const blob = new Blob([doc.documentElement.outerHTML], { type: 'text/html' });
  a.href = window.URL.createObjectURL(blob);
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();

  a.parentNode?.removeChild(a);
  style.parentNode?.removeChild(style);
}

function handleExportAllAsPdf() {
  if (
    activeTab.value === 'adminResultsCarbonFootprintView' ||
    activeTab.value === 'resultsCarbonFootprintView'
  ) {
    if (carbonFootprintData.value) {
      exportAsPdfFile(
        PdfReportTypeEnum.CARBON_FOOTPRINT,
        carbonFootprintData.value.dataPointTypeIds,
        carbonFootprintData.value.dateFrom,
        carbonFootprintData.value.dateTo,
        carbonFootprintData.value.categories,
        carbonFootprintData.value.project,
        carbonFootprintData.value.workspaces,
        carbonFootprintData.value.scope2EmissionTypeExcluded,
      );
    }
  } else if (standardData.value) {
    exportAsPdfFile(
      PdfReportTypeEnum.STANDARD,
      standardData.value.dataPointTypeIds,
      standardData.value.dateFrom,
      standardData.value.dateTo,
      standardData.value.categories,
      standardData.value.project,
      standardData.value.workspaces,
    );
  }
}

function handleDataReady({ type, data }: DataReadyEvent) {
  if (type === 'carbonFootprint') {
    carbonFootprintData.value = data;
  } else {
    standardData.value = data;
  }
}

function handleExportSingleStandardTableAsCsv(
  event: SingleStandardTableExportEvent,
) {
  if (standardData.value) {
    exportAsCsvFile(
      [event.dataPointTypeId],
      [event.dataPointTypeName],
      standardData.value.dateFrom,
      standardData.value.dateTo,
      standardData.value.project,
    );
  }
}
</script>

<template>
  <div class="pt-5 px-6 grid">
    <div class="sticky top-0 z-10 bg-white pb-4">
      <div class="flex items-center">
        <div class="flex-1">
          <h1 class="text-lg font-medium leading-6">
            {{ t('Results') }}
          </h1>
        </div>
        <div class="flex flex-initial items-center space-x-2">
          <AtButton
            v-if="!adminMode"
            :loading="isExportingAllCsv && isCsvLoading"
            :icon="DownloadIcon"
            @click.stop="handleExportAllAsCsv"
          >
            {{ t('Export as Excel') }}
          </AtButton>
          <AtButton
            v-if="hasResultsExport"
            variant="outline"
            :icon="DownloadIcon"
            :loading="isPdfLoading"
            @click.stop="handleExportAllAsPdf"
          >
            {{ t('Export as PDF') }}
          </AtButton>
          <AtButton
            variant="outline"
            :loading="isExportingAllDocx"
            :icon="DownloadIcon"
            @click.stop="handleExportSectionAsHtml"
          >
            {{ t('Copy as Word') }}
          </AtButton>
        </div>
      </div>
      <p class="text-sm text-gray-500">
        {{
          t(
            'View all data entry results and download report-ready sustainability data.',
          )
        }}
      </p>
    </div>

    <MlTabs
      :modelValue="activeTab"
      :tabs="tabs"
      isEdgeToEdge
      @update:modelValue="
        router.push({ name: $event.value, query: $event.query })
      "
    >
      <template #default="{ tab }">
        <span class="flex items-center space-x-2">
          <span>{{ t((tab as Tab).name) }}</span>
          <AtTooltipIcon
            v-if="
              (tab as Tab).value === standardTabValue &&
              standardTabValue === 'resultsStandardView'
            "
            :tooltip="
              t('The results of all of your datapoints are listed here.')
            "
            :delay="0"
          />
        </span>
      </template>
    </MlTabs>

    <section ref="sectionRef" class="pt-6">
      <router-view
        @dataReady="handleDataReady"
        @export="handleExportSingleStandardTableAsCsv"
      />
    </section>
  </div>
</template>
