<script setup lang="ts">
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlModal from '@/components/molecules/MlModal.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import type { ButtonVariants } from '../ui/button';

const props = withDefaults(
  defineProps<{
    isRevealed: boolean;
    title?: string;
    text?: string;
    confirmLabel?: string;
    cancelLabel?: string;
    confirmButtonVariant?: ButtonVariants['variant'];
    confirmDisabled?: boolean;
    confirmLoading?: boolean;
    textClass?: string;
    titleClass?: string;
    modalClass?: string;
    teleportTo?: string;
    renderHTML?: boolean;
  }>(),
  {
    title: 'Are you sure?',
    text: '',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    confirmButtonVariant: 'default',
    disableUserLoading: false,
    titleClass: '',
    textClass: '',
    modalClass: '',
    teleportTo: '#app',
    renderHTML: false,
  },
);

const emit = defineEmits<TEmits>();
type TEmits = {
  (e: 'confirm', isConfirmed: boolean): void;
  (e: 'cancel'): void;
};
</script>

<template>
  <MlModal
    class="m-6 min-w-[24rem] max-w-4xl"
    :class="props.modalClass"
    :isRevealed="props.isRevealed"
    :hasCloseButton="false"
    :teleportTo="props.teleportTo"
    @close="emit('confirm', false)"
  >
    <slot name="title">
      <h3 class="text-xl font-medium" :class="titleClass">
        {{ props.title }}
      </h3>
    </slot>
    <slot name="text">
      <p class="mt-4" :class="props.textClass">
        <MlHtmlContent v-if="props.renderHTML" :html="props.text" />
        <template v-else>
          {{ props.text }}
        </template>
      </p>
    </slot>
    <slot />
    <slot name="actions">
      <div class="modal-action flex justify-between">
        <AtButton
          variant="outline"
          data-cy="OgConfirmationDialog-cancelButton"
          @click="(emit('confirm', false), emit('cancel'))"
        >
          {{ props.cancelLabel }}
        </AtButton>
        <AtButton
          :variant="props.confirmButtonVariant"
          :disabled="props.confirmDisabled"
          :loading="props.confirmLoading"
          data-cy="OgConfirmationDialog-confirmButton"
          @click="emit('confirm', true)"
        >
          {{ props.confirmLabel }}
        </AtButton>
      </div>
    </slot>
  </MlModal>
</template>
