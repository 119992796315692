<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { computed, type HTMLAttributes } from 'vue';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import { UserRole } from '@/__generated__/types';
import type { TLocationReportingProgress } from './types';

const { t } = useI18n();
const router = useRouter();

type TProps = {
  title: string;
  wrapperClass?: HTMLAttributes['class'];
  projectId: string;
  locationReportingProgress?: TLocationReportingProgress;
};

const props = defineProps<TProps>();

const getAnsweredPercentage = computed(() => {
  if (props.locationReportingProgress) {
    const { accepted, pending, total } = props.locationReportingProgress;
    return Number((((accepted + pending) * 100) / total).toFixed());
  }
  return 0;
});
const getAcceptedPercentage = computed(() => {
  if (props.locationReportingProgress) {
    const { accepted, total } = props.locationReportingProgress;
    return Number(((accepted * 100) / total).toFixed());
  }
  return 0;
});
</script>

<template>
  <div
    class="project justify-center text-center w-1/3 text-sm"
    :class="props.wrapperClass"
  >
    <div class="el pt-6 pb-16 px-16 my-4">
      <h4
        class="font-semibold mb-5 cursor-pointer hover:text-blue-600 hover:underline"
        @click="router.push(`/projects/${props.projectId}/data-entry`)"
      >
        {{ props.title }}
      </h4>
      <div
        v-rolefrom="UserRole.MANAGER"
        class="flex gap-2 text-gray-500 mb-3 cursor-pointer hover:text-blue-600"
        @click="router.push('/approval')"
      >
        <span>
          {{ t('Answered') }}
        </span>
        <AtProgress
          class="w-full"
          showPercentNumber
          :percent="getAnsweredPercentage"
        />
      </div>
      <div v-role="UserRole.CONTRIBUTOR" class="flex gap-2 text-gray-500 mb-3">
        <span>
          {{ t('Answered') }}
        </span>
        <AtProgress
          class="w-full"
          showPercentNumber
          :percent="getAnsweredPercentage"
        />
      </div>
      <div
        v-rolefrom="UserRole.MANAGER"
        class="flex gap-2 text-gray-500 cursor-pointer hover:text-blue-600"
        @click="router.push('/approval/approved')"
      >
        {{ t('Accepted') }}
        <AtProgress
          class="w-full"
          showPercentNumber
          :percent="getAcceptedPercentage"
        />
      </div>
      <div v-role="UserRole.CONTRIBUTOR" class="flex gap-2 text-gray-500">
        {{ t('Accepted') }}
        <AtProgress
          class="w-full"
          showPercentNumber
          :percent="getAcceptedPercentage"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.project:nth-of-type(3n + 2) .el {
  @apply border-x-[1px];
}
</style>
