<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ArrowNarrowRightIcon, CheckIcon } from '@heroicons/vue/solid';
import type { GetStartedStatusEnum } from '@/__generated__/types';

const router = useRouter();
const { t } = useI18n();

interface TProps {
  stepIndex: number;
  isActive: boolean;
  step: {
    stepNumber: number;
    name: GetStartedStatusEnum;
    title: string;
    description: string;
    path: string;
    isCompleted: boolean;
    disabled: boolean;
  };
}

const props = defineProps<TProps>();
const emit = defineEmits(['completeStep']);

function goToPath() {
  if (props.step.disabled) return;
  emit('completeStep');
  router.push(props.step.path);
}
</script>

<template>
  <div
    class="flex cursor-pointer items-start justify-between rounded-lg bg-gray-200 p-6 shadow-[0px_0px_14px_rgba(156,163,175,0.33)]"
    :class="{ 'hover:cursor-default': props.step.disabled }"
    @click.stop="goToPath"
  >
    <div class="flex items-center">
      <div
        class="mr-6 flex h-6 w-6 items-center justify-center rounded-full border-2 border-gray-400 bg-white text-center"
        :class="{
          'border-2 !border-emerald-500': props.step.isCompleted,
          '!border-0 !bg-blue-600': props.isActive,
        }"
      >
        <div
          class="text-gray-400"
          :class="{
            'text-white': props.isActive,
          }"
        >
          <span
            v-if="props.step.isCompleted"
            :data-cy="`MlStepCardCheckIcon-${props.step.name}`"
          >
            <CheckIcon
              class="h-5 w-5"
              :class="{
                'text-emerald-500': props.step.isCompleted,
                'bg-blue-600': props.isActive,
              }"
            />
          </span>
          <span v-else> {{ props.step.stepNumber }} </span>
        </div>
      </div>
      <div>
        <h3
          class="text-xl"
          :class="props.step.disabled ? 'text-gray-400' : 'text-gray-700'"
        >
          {{ t(props.step.title) }}
        </h3>
        <p :class="props.step.disabled ? 'text-gray-400' : 'text-gray-700'">
          {{ t(props.step.description) }}
        </p>
      </div>
    </div>
    <div class="flex flex-col justify-center">
      <ArrowNarrowRightIcon class="h-6 w-6 lg:mt-3" aria-hidden="true" />
    </div>
  </div>
</template>
