<script setup lang="ts">
import { LoaderSize } from './types';

const props = withDefaults(defineProps<TProps>(), {
  size: LoaderSize.BASE,
});
interface TProps {
  withOverlay?: boolean;
  size?: `${LoaderSize}`;
}
</script>

<template>
  <div
    :class="{
      'absolute inset-0 z-50 flex items-center justify-center bg-black/25':
        props.withOverlay,
    }"
  >
    <div
      :class="`size-${props.size} rounded-full border-2 border-t-blue-500 animate-spin text-`"
    />
  </div>
</template>

<style lang="postcss" scoped>
.size-xs {
  @apply w-4 h-4;
}
.size-sm {
  @apply w-5 h-5;
}
.size-base {
  @apply w-8 h-8;
}
.size-lg {
  @apply w-12 h-12;
}
.size-xl {
  @apply w-16 h-16;
}
</style>
