<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { DownloadIcon } from '@heroicons/vue/outline';
import { PdfReportTypeEnum } from '@/__generated__/types';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import type { TProgress } from '@/components/pages/PgAdmin/Home/TmReports/types';

type TProps = {
  workspaces: TProgress[];
  isDNK: boolean;
  activePDFDownload: {
    reportTypes: PdfReportTypeEnum[] | null;
    workspaceId: string | null;
  };
};

type TEmits = {
  (e: 'requestCo2Report', id: string): void;
  (e: 'requestEsgReport', id: string): void;
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmits>();
const { t } = useI18n();

const items = computed(() =>
  props.workspaces.map((workspace) => ({ workspace })),
);

const headers = computed(() => [
  { text: t('WORKSPACE'), value: 'name', sortable: true, filterable: true },
  // { text: t('CO2 PROGRESS'), value: 'co2Progress', sortable: true },
  { text: t(''), value: 'co2ReportLink' },
  { text: t('Reporting PROGRESS'), value: 'esgProgress', sortable: true },
  { text: t(''), value: 'esgReportLink' },
]);

const isCo2DownloadActive = computed(() =>
  props.activePDFDownload.reportTypes?.includes(
    PdfReportTypeEnum.CARBON_FOOTPRINT,
  ),
);
const isEsgDownloadActive = computed(
  () =>
    props.activePDFDownload.reportTypes?.includes(PdfReportTypeEnum.STANDARD) ||
    props.activePDFDownload.reportTypes?.includes(
      PdfReportTypeEnum.QUALITATIVE,
    ),
);
</script>

<template>
  <div class="mb-6 grid overflow-auto rounded-md">
    <OgDataTable
      :headers="headers"
      :items="items"
      controlsHidden
      wrapperClass="!py-0"
    >
      <template #item-name="{ workspace }">
        <div class="flex items-center" data-cy="templateItemName">
          <span>
            {{ workspace.name }}
          </span>
        </div>
      </template>
      <template #item-co2Progress="{ workspace }">
        <AtProgress
          showProgressionColors
          :percent="Number((workspace.accepted / workspace.total) * 100)"
          showPercentNumber
        />
      </template>
      <template #item-co2ReportLink="{ workspace }">
        <AtButton
          variant="text"
          :icon="DownloadIcon"
          :loading="
            isCo2DownloadActive &&
            activePDFDownload.workspaceId === workspace.id
          "
          @click.prevent="emit('requestCo2Report', workspace.id)"
        >
          {{ t('Download CO2 Report') }}
        </AtButton>
      </template>
      <template #item-esgProgress="{ workspace }">
        <AtProgress
          showProgressionColors
          :percent="Number((workspace.accepted / workspace.total) * 100)"
          showPercentNumber
        />
      </template>
      <template #item-esgReportLink="{ workspace }">
        <AtButton
          variant="text"
          :icon="DownloadIcon"
          :loading="
            isEsgDownloadActive &&
            activePDFDownload.workspaceId === workspace.id
          "
          @click.prevent="emit('requestEsgReport', workspace.id)"
        >
          {{ t(isDNK ? 'Request ESG Report' : 'Download ESG Report') }}
        </AtButton>
      </template>
    </OgDataTable>
  </div>
</template>
