import { gql } from '@apollo/client/core';

export default gql`
  query PgGoalsOverview {
    getEntityKPIs {
      _id
      startingDate
      dueDate
      startingValue
      completionPercent
      comparisonValue
      comparisonOperator
      comparisonValueType
      status

      user {
        _id
        firstName
        lastName
      }

      targets {
        _id
        name
        number

        goal {
          _id
          name
          number
          description
          reportingFramework
        }
      }

      dataPointType {
        _id
        friendlyName
        question
        name
        entity {
          _id
        }
        activeReportingFramework {
          _id
          category
          subcategory
        }
        valueDataType
        valueUnit
        valueUnitDivisor
      }
    }

    getGoals {
      _id
      name
      number
      description
      reportingFramework
    }

    getKPIDataPoints {
      value
      from
      to

      location {
        _id
        name
      }

      dataPointType {
        _id
        name
        friendlyName
        valueUnit

        reportingFrameworks {
          _id
          framework
          groups
        }
      }
    }
  }
`;
