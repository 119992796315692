import { gql } from '@apollo/client/core';

export default gql`
  query TmPendingDataPointRequestsFilters(
    $status: DataPointRequestStatusEnum!
  ) {
    getApprovalDataPointRequestsFilters(status: $status) {
      assignees {
        _id
        firstName
        lastName
      }
      categories
      dataPointTypes {
        _id
        friendlyName
      }
      locations {
        _id
        name
      }
      subcategories {
        category
        subcategory
      }
      periods {
        from
        to
      }
    }
  }
`;
