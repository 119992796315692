<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import uniqBy from 'lodash/uniqBy';
import { formatDateRangeForBE } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import chartImg from '@/assets/chart.svg';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlSelectLevel3 from '@/components/molecules/MlSelectLevel3/MlSelectLevel3.vue';
import OgDateRangeInput from '@/components/organisms/OgDateRangeInput.vue';
import { DataPointTypeEmissionTypeEnum } from '@/__generated__/types';
import type { DataReadyEvent } from '../PgResults.vue';
import { usePgCarbonFootprintView } from './composables';
import OgCarbonFootprintTable from './OgCarbonFootprintTable/OgCarbonFootprintTable.vue';

const emit = defineEmits<{
  dataReady: [
    event: {
      type: 'carbonFootprint';
      data: DataReadyEvent['data'];
    },
  ];
}>();

const router = useRouter();
const { t } = useI18n();

const adminMode = inject(
  'adminMode',
  computed(() => false),
);

const { filters, filtersData, noData, projects, dataPoints } =
  usePgCarbonFootprintView();

const { SCOPE_2_MARKET_BASED, SCOPE_2_LOCATION_BASED } =
  DataPointTypeEmissionTypeEnum;
const scope2EmissionTypeExcluded = ref<
  typeof SCOPE_2_MARKET_BASED | typeof SCOPE_2_LOCATION_BASED
>(SCOPE_2_MARKET_BASED);
watch(
  [
    dataPoints,
    () => filters.dateRange,
    () => filters.project,
    () => filters.workspaces,
    scope2EmissionTypeExcluded,
  ],
  ([
    newDataPoints,
    newDateRangeFilter,
    newProject,
    newWorkspaces,
    newScope2EmissionTypeExcluded,
  ]) => {
    const dataPointTypes = uniqBy(
      newDataPoints.map((dataPoint) => dataPoint.dataPointType),
      (dataPointType) => dataPointType._id,
    );
    const { dateFrom, dateTo } = formatDateRangeForBE(
      newDateRangeFilter.from,
      newDateRangeFilter.to,
    );

    emit('dataReady', {
      type: 'carbonFootprint',
      data: {
        dataPointTypeIds: dataPointTypes.map(
          (dataPointType) => dataPointType._id,
        ),
        dataPointTypeNames: dataPointTypes.map(
          (dataPointType) => dataPointType.name,
        ),
        dateFrom,
        dateTo,
        project: newProject,
        categories: [],
        workspaces: newWorkspaces,
        scope2EmissionTypeExcluded: newScope2EmissionTypeExcluded,
      },
    });
  },
);
</script>

<template>
  <div>
    <div id="pgCarbonFootprintViewControlId" class="mb-6 flex gap-2.5">
      <MlSelectLevel3
        v-if="!adminMode"
        v-model="filters.project"
        size="md"
        :placeholder="t('All projects')"
        :items="filtersData.projectsOptions.value"
      />

      <MlSelect
        v-if="adminMode"
        v-model="filters.workspaces"
        :title="t('Workspace')"
        :placeholder="t('All workspaces')"
        :options="filtersData.workspaceOptions.value"
        wrapperClass="w-full"
        multiple
      />

      <MlSelectLevel3
        v-model="filters.scope"
        size="md"
        :placeholder="t('All scopes')"
        :items="filtersData.scopeOptions.value"
      />

      <MlSelect
        v-model="filters.emissionSource"
        :title="t('Emission sources')"
        :placeholder="t('All emission sources')"
        :options="filtersData.emissionSourceOptions.value"
        wrapperClass="w-full"
      />

      <OgDateRangeInput v-model="filters.dateRange" isFull />
    </div>

    <MlEmptyStateCard
      v-if="noData"
      :title="t('No data yet')"
      :description="t('Start by entering numeric data')"
      :buttonText="t('Enter data')"
      @buttonClick="router.push({ name: 'projects' })"
    >
      <img class="mb-7" :src="chartImg" alt="Graph" />
    </MlEmptyStateCard>

    <OgCarbonFootprintTable
      v-else-if="dataPoints.length > 0"
      :dataPoints="dataPoints"
      :projects="projects"
      @changeScope2EmissionTypeExcluded="scope2EmissionTypeExcluded = $event"
    />
  </div>
</template>
