import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import type {
  DoubleMaterialitySendSurveysMutation,
  DoubleMaterialitySendSurveysMutationVariables,
} from '@/__generated__/types';

export const SEND_SURVEYS = gql`
  mutation DoubleMaterialitySendSurveys(
    $doubleMaterialityId: String!
    $sendSurveys: [SendSurveyInput!]!
  ) {
    sendSurveys(
      doubleMaterialityId: $doubleMaterialityId
      sendSurveys: $sendSurveys
    )
  }
`;

type Options = UseMutationOptions<
  DoubleMaterialitySendSurveysMutation,
  DoubleMaterialitySendSurveysMutationVariables
>;

const useDoubleMaterialitySendSurveysMutation = (options?: Options) =>
  useMutation<
    DoubleMaterialitySendSurveysMutation,
    DoubleMaterialitySendSurveysMutationVariables
  >(SEND_SURVEYS, options);

export default useDoubleMaterialitySendSurveysMutation;
