<script setup lang="ts">
import type { ApolloError } from '@apollo/client/core';
import { computed, onMounted, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlModal from '@/components/molecules/MlModal.vue';
import type { OgCreateEntityModalQuery } from '@/__generated__/types';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import OG_CREATE_ENTITY_MODAL_QUERY from './OgCreateEntityModal.query';

interface TProps {
  isRevealed: boolean;
  text?: string;
  loading: boolean;
  error: ApolloError | null;
  confirmDisabled?: boolean;
  onlyAddUsersStep?: boolean;
}

const props = withDefaults(defineProps<TProps>(), {
  text: '',
  onlyAddUsersStep: false,
});

const { t } = useI18n();
const { result } = useQuery<OgCreateEntityModalQuery>(
  OG_CREATE_ENTITY_MODAL_QUERY,
);

const allTeamsUsers = computed(() => result.value?.getAllTeamsUsers ?? []);

const usersIdsSelected = ref<string[]>([]);
const allTeamsUsersOptions = computed(() =>
  allTeamsUsers.value.reduce<Record<string, string>>(
    (acc, { _id, firstName, lastName }) => ({
      ...acc,
      [_id]: `${firstName} ${lastName}`,
    }),
    {},
  ),
);

const hasBeenConfirmedWithUsers = ref(false);
const firstStepCompleted = ref(false);
onMounted(() => {
  if (props.onlyAddUsersStep) firstStepCompleted.value = true;
  else firstStepCompleted.value = false;
});

const emit = defineEmits<TEmits>();
type TEmits = {
  (e: 'confirm', selectedUsers?: string[]): void;
  (e: 'cancel'): void;
};

function handleConfirmWithUsers() {
  hasBeenConfirmedWithUsers.value = true;
  emit('confirm', usersIdsSelected.value);
}

const optionsHeight = ref(0);
const ButtonsMarginTop = computed(() => {
  if (optionsHeight.value > 320) return 'mt-[400px]';
  if (optionsHeight.value > 280 && optionsHeight.value <= 320)
    return 'mt-[356px]';
  if (optionsHeight.value > 240 && optionsHeight.value <= 280)
    return 'mt-[316px]';
  if (optionsHeight.value > 200 && optionsHeight.value <= 240)
    return 'mt-[276px]';
  if (optionsHeight.value > 160 && optionsHeight.value <= 200)
    return 'mt-[236px]';
  if (optionsHeight.value > 120 && optionsHeight.value <= 160)
    return 'mt-[196px]';
  if (optionsHeight.value > 80 && optionsHeight.value <= 120)
    return 'mt-[156px]';
  if (optionsHeight.value > 40 && optionsHeight.value <= 80)
    return 'mt-[116px]';
  if (optionsHeight.value > 0 && optionsHeight.value <= 40) return 'mt-[76px]';
  return 'mt-[36px]';
});
</script>

<template>
  <MlModal
    class="m-6 min-w-[24rem] max-w-4xl"
    :isRevealed="props.isRevealed"
    :hasCloseButton="false"
    @close="emit('cancel')"
  >
    <slot name="title">
      <h3 class="text-xl font-medium">
        <span v-if="!firstStepCompleted">
          {{ t('Add new workspace') }}
        </span>
        <span v-else>
          {{ t('Add existing team members to workspace') }}
        </span>
      </h3>
    </slot>
    <slot name="text">
      <p class="mt-4">
        {{ props.text }}
      </p>
    </slot>
    <slot v-if="!firstStepCompleted" />

    <MlSelect
      v-else
      v-model="usersIdsSelected"
      label="Name"
      data-cy="MlSelectExisitingUserCreateEntityModal"
      :options="allTeamsUsersOptions"
      multiple
      @heightChanged="optionsHeight = $event"
    />
    <div
      class="modal-action flex items-end justify-between align-bottom"
      :class="ButtonsMarginTop"
    >
      <AtButton
        variant="outline"
        data-cy="AtButtonCreateEntityModalCancel"
        @click="emit('cancel')"
      >
        {{ t('Cancel') }}
      </AtButton>
      <AtButton
        v-if="!firstStepCompleted"
        :disabled="props.confirmDisabled"
        data-cy="AtButtonCreateEntityModalNext"
        @click.stop="firstStepCompleted = true"
      >
        {{ t('Next') }}
      </AtButton>
      <div v-if="firstStepCompleted" class="flex items-end justify-between">
        <AtButton
          variant="outline"
          :disabled="props.confirmDisabled"
          data-cy="AtButtonCreateEntityModalSkipAndSave"
          :loading="props.loading && !hasBeenConfirmedWithUsers"
          @click.stop="emit('confirm')"
        >
          {{ t('Skip and save') }}
        </AtButton>
        <AtButton
          variant="default"
          class="ml-2"
          :disabled="!usersIdsSelected.length"
          data-cy="AtButtonCreateEntityModalAdd"
          :loading="props.loading && hasBeenConfirmedWithUsers"
          @click.stop="handleConfirmWithUsers"
        >
          {{ t('Add') }}
        </AtButton>
      </div>
    </div>
  </MlModal>
</template>
