<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { TTargetCommon } from '../../../types';

const props = defineProps<{
  modelValue?: TTargetCommon;
  targets: TTargetCommon[];
  isGoalSelected: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', target?: TTargetCommon): void;
}>();

const { t } = useI18n();
</script>

<template>
  <div class="w-full divide-y">
    <p class="mt-8 mb-2 text-sm font-semibold">
      {{ t('Select a Sustainable Development Goal.') }}
    </p>
    <p v-if="!isGoalSelected" class="text-sm pt-4">
      {{ t('Please select one above.') }}
    </p>
    <p v-else-if="!props.targets.length" class="text-sm pt-4">
      {{ t('No targets available for this goal.') }}
    </p>
    <label
      v-for="target in props.targets"
      :key="target._id"
      class="grid grid-flow-col grid-cols-[0fr_0fr_1fr_0fr] items-center gap-6 p-4 text-sm"
    >
      <input
        type="radio"
        class="radio-primary radio"
        :checked="props.modelValue?._id === target._id"
        :value="target._id"
        @input="emit('update:modelValue', target)"
      />
      <p v-if="target.number">
        {{ target.number }}
      </p>
      <p>{{ t(target.name) }}</p>
      <div class="w-36">
        <span
          v-if="false && target.isRecommended"
          class="inline-flex items-center justify-center rounded bg-gray-200 px-2 py-1 text-sm font-bold text-gray-400"
        >
          {{ t('Recommended') }}
        </span>
      </div>
    </label>
  </div>
</template>
