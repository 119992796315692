import { gql } from '@apollo/client/core';

export default gql`
  query PgCarbonFootprintView(
    $dateFrom: String!
    $dateTo: String!
    $projectId: String
    $workspaceIds: [String!]
    $adminMode: Boolean!
  ) {
    entityLocationSummaries {
      _id
      name
      parent
      hasParentInfo
    }

    getCurrentUserEntities @include(if: $adminMode) {
      _id
      name
    }

    getCarbonFootprintDataPointsByProjectAndDateRange(
      dateFrom: $dateFrom
      dateTo: $dateTo
      projectId: $projectId
    ) @skip(if: $adminMode) {
      _id
      value
      valueUnit
      displayValue
      displayValueUnit

      valueSource {
        name
        value
        originalValueUnit
        originalValue
      }

      type
      from
      to
      comment

      entity {
        _id
        name
      }

      location {
        _id
        name
      }

      dataPointRequest {
        _id
        delegations {
          _id
        }
      }

      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        valueUnitDivisor
        valueDataType
        valueDataTypeOptions
        refreshInterval
        summarizingMethod

        emissionType
        emissionCategory
        emissionSubcategory

        emissionFactors {
          _id
          year
          factor
        }

        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
          order
        }
        reportingFrameworks {
          _id
          framework
          groups
          order
        }
      }
    }

    getCarbonFootprintDataPointsByProjectAndDateRangeAdmin(
      dateFrom: $dateFrom
      dateTo: $dateTo
      workspaceIds: $workspaceIds
    ) @include(if: $adminMode) {
      value
      valueUnit
      displayValue
      displayValueUnit

      valueSource {
        name
        value
        originalValueUnit
        originalValue
      }
      valueUnit

      from
      to
      comment

      entity {
        _id
        name
      }

      location {
        _id
        name
      }

      dataPointRequest {
        _id
        delegations {
          _id
        }
      }

      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        valueUnitDivisor
        valueDataType
        valueDataTypeOptions
        refreshInterval
        summarizingMethod

        emissionType
        emissionCategory
        emissionSubcategory

        emissionFactors {
          _id
          year
          factor
        }

        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
          order
        }
        reportingFrameworks {
          _id
          framework
          groups
          order
        }
      }
    }
  }
`;
