import { useI18n } from 'vue-i18n';
import type {
  PreparedPerYear,
  PreparedPerYearProjectItem,
  PreparedPerYearScopeItem,
  PreparedPerYearScopeSublevelItem,
  PreparedPerYearSourceItem,
} from '../../types';
import type {
  Row,
  TableData,
  RowsPerScope,
  RowsPerScopeSourceSection,
  RowsPerScopeSublevelSection,
} from './types';

type TranslateCallback = ReturnType<typeof useI18n>['t'];

function prepareRow(
  sublevel: PreparedPerYearProjectItem,
  path: string,
  parentIds: string[],
  isTopLevel: boolean,
): Row {
  return {
    id: `${path}.${sublevel.id}`,
    name: sublevel.name,
    keys: new Set(parentIds),
    values: sublevel.dataPoints || sublevel.total || {},
    isCollapsible: !!sublevel.sublevels,
    isTopLevel,
  };
}

function prepareRows(
  projects: PreparedPerYearProjectItem[],
  path: string,
): Row[] {
  const result: Row[] = [];

  const prepareSublevels = (
    sublevels: PreparedPerYearProjectItem[] | null,
    initialPath: string,
    parentIds: string[],
    isTopLevel: boolean,
  ) => {
    if (sublevels) {
      sublevels.forEach((sublevel) => {
        const currentPath = `${initialPath}.${sublevel.id}`;

        result.push(prepareRow(sublevel, initialPath, parentIds, isTopLevel));

        prepareSublevels(
          sublevel.sublevels,
          currentPath,
          [...parentIds, currentPath],
          false,
        );
      });
    }
  };
  prepareSublevels(projects, path, [], true);

  return result;
}

function prepareScopeSublevelSections(
  sources: PreparedPerYearSourceItem[],
  path: string,
  t: TranslateCallback,
): RowsPerScopeSourceSection[] {
  return sources.map((source) => {
    const rows = prepareRows(source.projects, `${path}.${source.name}`);
    const span = rows.length || 1;

    return {
      id: source.name,
      name: t(source.name),
      span,
      rows,
    };
  });
}

function prepareScopeSections(
  sublevels: PreparedPerYearScopeSublevelItem[],
  initialSpan: number,
  path: string,
  t: TranslateCallback,
): RowsPerScopeSublevelSection[] {
  return sublevels.map((sublevel) => {
    const currentPath = `${path}.${sublevel.name}`;

    const sections = prepareScopeSublevelSections(
      sublevel.sources,
      currentPath,
      t,
    );
    const span = sections.reduce(
      (sum, section) => sum + section.span,
      initialSpan,
    );

    const totalRows = prepareRows(
      sublevel.totalPerProject,
      `total.${currentPath}`,
    );
    totalRows.push(
      prepareRow(
        {
          id: `total.${currentPath}.allProjects`,
          name: t('All projects'),
          sublevels: null,
          dataPoints: null,
          total: sublevel.total,
        },
        currentPath,
        [],
        true,
      ),
    );

    return {
      id: sublevel.name,
      name: t(sublevel.name),
      span,
      sections,
      total: {
        name: t('Total {category}', {
          category: t(sublevel.name),
        }),
        span: totalRows.length,
        rows: totalRows,
      },
      ifCollapsed: totalRows[totalRows.length - 1],
    };
  });
}

function prepareScope(
  scope: PreparedPerYearScopeItem,
  t: TranslateCallback,
): RowsPerScope {
  const currentPath = scope.scope;

  const totalRows = prepareRows(scope.totalPerProject, `total.${currentPath}`);
  totalRows.push(
    prepareRow(
      {
        id: `total.${currentPath}.allProjects`,
        name: t('All projects'),
        sublevels: null,
        dataPoints: null,
        total: scope.total,
      },
      currentPath,
      [],
      true,
    ),
  );

  return {
    id: scope.scope,
    name: t(scope.scope),
    sections: prepareScopeSections(
      scope.sublevels,
      totalRows.length,
      currentPath,
      t,
    ),
    total: {
      name: t('Total {scope}', {
        scope: t(scope.scope),
      }),
      span: totalRows.length,
      rows: totalRows,
    },
    ifCollapsed: totalRows[totalRows.length - 1],
  };
}

export function prepareScopesForTable(
  scopes: PreparedPerYear['scopes'],
  total: PreparedPerYear['total'],
  t: TranslateCallback,
): TableData {
  return {
    scopes: scopes.map((scope) => {
      return prepareScope(scope, t);
    }),
    total,
  };
}
