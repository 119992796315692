<script setup lang="ts">
const props = defineProps<{
  title: string;
  subtitle?: string;
}>();
</script>

<template>
  <div>
    <h2 class="font-bold">
      {{ props.title }}
    </h2>
    <p v-if="props.subtitle" class="mt-2 text-sm">
      {{ props.subtitle }}
    </p>
  </div>
</template>
