<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DoubleMaterialityIroRiskAndOpportunityType } from '@/__generated__/types';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlInfo from '../../MlInfo.vue';
import { useStore } from '../../store';

export type SaveEvent = {
  subject: string;
  body: string;
};

const props = defineProps<{
  initialSubject: string;
  initialBody: string;
}>();

const emit = defineEmits<{
  close: [];
  save: [event: SaveEvent];
}>();

const { t } = useI18n();
const store = useStore();

const subject = ref('');
const body = ref('');

watch(
  () => props.initialSubject,
  (newInitialSubject) => {
    subject.value = newInitialSubject;
  },
  { immediate: true },
);
watch(
  () => props.initialBody,
  (newInitialBody) => {
    body.value = newInitialBody;
  },
  { immediate: true },
);

const formKey = computed(() => props.initialSubject + props.initialBody);
const isValid = computed(
  () => subject.value.trim().length > 0 && body.value.trim().length > 0,
);

const risksAndOpportunities = computed(() => {
  return store.value.iroRisksAndOpportunities.filter(
    (iroRiskAndOpportunity) => iroRiskAndOpportunity.type,
  );
});

const scaleOptions = computed(() => ({}));

function handleClose() {
  emit('close');
}

function handleSubmit() {
  if (isValid.value) {
    emit('save', {
      subject: subject.value.trim(),
      body: body.value.trim(),
    });
  }
}
</script>

<template>
  <MlDialog isOpen :closeOnOutside="false" @close="handleClose">
    <template #title>
      {{ t('Risks and opportunities survey template preview') }}
    </template>

    <template #default="{ close }">
      <form
        :key="formKey"
        class="min-w-[600px] pt-9"
        @submit.prevent="handleSubmit"
      >
        <hr class="mb-8" />
        <AtInput
          v-model="subject"
          wrapperClass="mb-4"
          :placeholder="t('Subject')"
        />
        <textarea
          v-model="body"
          class="mb-5 border border-gray-400 w-full rounded-md shadow-sm py-2 px-3 resize-none min-h-[372px] sm:text-sm focus:outline focus:outline-[0.5px] focus:outline-primary focus:border-primary"
          :placeholder="t('Type here')"
        />
        <MlInfo type="warning" class="mb-6">
          <p>
            {{
              t(
                'Please rate each of the following items on a scale from 1 to 5 based on how relevant you believe they are to the company, with 1 indicating "not relevant" and 5 indicating "very relevant".',
              )
            }}
          </p>
        </MlInfo>
        <p class="mb-4 font-medium">
          {{ t('Identified risks and opportunities:') }}
        </p>
        <div class="mb-16">
          <div
            v-for="riskAndOpportunity in risksAndOpportunities"
            :key="riskAndOpportunity._id"
            class="flex items-center space-x-6 mb-8"
          >
            <p class="flex-1">
              {{ riskAndOpportunity.riskAndOpportunity }}
            </p>
            <div class="flex-none w-52 text-center">
              <AtBadge
                v-if="
                  riskAndOpportunity.type ===
                  DoubleMaterialityIroRiskAndOpportunityType.OPPORTUNITY
                "
                type="VIOLET"
                size="sm"
              >
                {{ t('Opportunity') }}
              </AtBadge>
              <AtBadge
                v-else-if="
                  riskAndOpportunity.type ===
                  DoubleMaterialityIroRiskAndOpportunityType.RISK
                "
                type="YELLOW"
                size="sm"
              >
                {{ t('Risk') }}
              </AtBadge>
            </div>
            <div class="flex-none w-32">
              <MlSelect
                modelValue=""
                disabled
                :options="scaleOptions"
                :placeholder="t('Select')"
              />
            </div>
          </div>
        </div>
        <div class="flex space-x-2 justify-between">
          <AtButton type="button" variant="outline" @click="close">
            {{ t('Close') }}
          </AtButton>
          <AtButton type="submit" :disabled="!isValid">
            {{ t('Save changes') }}
          </AtButton>
        </div>
      </form>
    </template>
  </MlDialog>
</template>
