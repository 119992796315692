import { useLazyQuery } from '@vue/apollo-composable';
import {
  type DoubleMaterialityDocumentDownloadQuery,
  type DoubleMaterialityDocumentDownloadQueryVariables,
  DoubleMaterialityDownloadDocumentEnum,
} from '@/__generated__/types';
import saveFile from '@/utils/helpers/saveFile';
import waitForApolloQuery from '@/utils/helpers/waitForApolloQuery';
import DOWNLOAD_DOCUMENT_QUERY from './documentDownload.query';

export function useDocumentDownload(
  file: DoubleMaterialityDownloadDocumentEnum,
) {
  const query = useLazyQuery<
    DoubleMaterialityDocumentDownloadQuery,
    DoubleMaterialityDocumentDownloadQueryVariables
  >(DOWNLOAD_DOCUMENT_QUERY, undefined, { fetchPolicy: 'network-only' });

  const exportFile = async () => {
    const requestData: DoubleMaterialityDocumentDownloadQueryVariables = {
      file,
    };

    // eslint-disable-next-line no-unused-expressions
    query.load(undefined, requestData) || query.refetch(requestData);
    const result = await waitForApolloQuery(query);

    if (result && !(result instanceof Error)) {
      const documentData = result.downloadDoubleMaterialityDocument.file;
      if (documentData) {
        saveFile(
          documentData,
          result.downloadDoubleMaterialityDocument.name,
          result.downloadDoubleMaterialityDocument.type,
        );
      }
    }
  };

  return {
    loading: query.loading,
    exportFile,
  };
}
