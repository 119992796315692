import orderBy from 'lodash/orderBy';
import mapValues from 'lodash/mapValues';
import { useI18n } from 'vue-i18n';
import {
  SummarizingMethodEnum,
  ValueDataTypeEnum,
} from '@/__generated__/types';
import type {
  DataPointType,
  PreparedPerDate,
  PreparedPerDateSublevelItem,
} from '../../types';
import {
  calculate,
  summarize,
  summarizeCategories,
} from '../../services/summarize';
import type { RowsPerProject, TableData, Total } from './types';

type TranslateCallback = ReturnType<typeof useI18n>['t'];

export function prepareForTable(
  projects: PreparedPerDate['projects'],
  dates: PreparedPerDate['dates'],
  dataPointType: DataPointType,
  showCategories: boolean,
  showTotal: boolean,
  t: TranslateCallback,
): TableData {
  const canSummarize =
    !!dataPointType.summarizingMethod &&
    dataPointType.valueDataType === ValueDataTypeEnum.NUMERIC;

  const preparedProjects: TableData['projects'] = projects.map((project) => {
    const rowsPerProject: RowsPerProject = {
      id: project.id,
      name: project.name,
      rows: [],
      total: null,
      categoriesTotal: null,
    };

    if (!project.sublevels) {
      if (showCategories) {
        project.categories.forEach((category, categoryIndex) => {
          rowsPerProject.rows.push({
            id: project.id,
            name: '',
            // No need for parent ID because projects aren't collapsable.
            keys: new Set(),
            values: {},
            isCollapsible: false,
            categoryName: t(
              category.isUncategorized ? 'Uncategorized' : category.name,
            ),
            categoryValues: category.values,
            // Only show <td> when it is the fist mention of sublevel (first category).
            sublevelSpan: categoryIndex === 0 ? project.categories.length : 0,
          });
        });
      } else {
        rowsPerProject.rows.push({
          id: project.id,
          name: '',
          // No need for parent ID because projects aren't collapsable.
          keys: new Set(),
          values: project.dataPoints || project.total || {},
          isCollapsible: false,
          categoryName: '',
          categoryValues: {},
          sublevelSpan: 1,
        });
      }
    } else {
      const getSublevelsRows = (
        sublevels: PreparedPerDateSublevelItem[] | null,
        parentIds: string[],
      ) => {
        if (sublevels) {
          sublevels.forEach((sublevel) => {
            if (showCategories) {
              sublevel.categories.forEach((category, categoryIndex) => {
                rowsPerProject.rows.push({
                  id: sublevel.id,
                  name: sublevel.name,
                  keys: new Set(parentIds),
                  values: {},
                  isCollapsible: !!sublevel.sublevels,
                  categoryName: t(
                    category.isUncategorized ? 'Uncategorized' : category.name,
                  ),
                  categoryValues: category.values,
                  // Only show <td> when it is the fist mention of sublevel (first category).
                  sublevelSpan:
                    categoryIndex === 0 ? sublevel.categories.length : 0,
                });
              });
            } else {
              rowsPerProject.rows.push({
                id: sublevel.id,
                name: sublevel.name,
                keys: new Set(parentIds),
                values: sublevel.dataPoints || sublevel.total || {},
                isCollapsible: !!sublevel.sublevels,
                categoryName: '',
                categoryValues: {},
                sublevelSpan: 1,
              });
            }
            // It's not possible to be sublevel without parent ID.
            getSublevelsRows(sublevel.sublevels, [...parentIds, sublevel.id]);
          });
        }
      };

      project.sublevels.forEach((firstSublevel) => {
        if (showCategories) {
          firstSublevel.categories.forEach((category, categoryIndex) => {
            rowsPerProject.rows.push({
              id: firstSublevel.id,
              name: categoryIndex === 0 ? firstSublevel.name : '',
              keys: new Set([project.id]),
              values: {},
              isCollapsible: !!firstSublevel.sublevels,
              categoryName: t(
                category.isUncategorized ? 'Uncategorized' : category.name,
              ),
              categoryValues: category.values,
              // Only show <td> when it is the fist mention of sublevel (first category).
              sublevelSpan:
                categoryIndex === 0 ? firstSublevel.categories.length : 0,
            });
          });
        } else {
          rowsPerProject.rows.push({
            id: firstSublevel.id,
            name: firstSublevel.name,
            keys: new Set([project.id]),
            values: firstSublevel.dataPoints || firstSublevel.total || {},
            isCollapsible: !!firstSublevel.sublevels,
            categoryName: '',
            categoryValues: {},
            sublevelSpan: 1,
          });
        }
        // It's not possible to be sublevel without parent ID.
        getSublevelsRows(firstSublevel.sublevels, [
          project.id,
          firstSublevel.id,
        ]);
      });

      // Project Total
      const { summarizingMethod } = dataPointType;
      if (canSummarize && project.sublevels) {
        if (!project.categoriesTotal) {
          rowsPerProject.total = {
            name: t(
              summarizingMethod === SummarizingMethodEnum.SUM
                ? 'Total {project} (Sum)'
                : 'Total {project} (Avg)',
              {
                project: project.name,
              },
            ),
            values: dates.reduce<Total['values']>((values, date) => {
              const [sum, count] = summarize(project.sublevels || [], date.key);
              return {
                ...values,
                [date.key]: {
                  dataPointType,
                  value: calculate(sum, count, summarizingMethod!),
                  calculation: {
                    sum,
                    count,
                  },
                },
              };
            }, {}),
          };
        } else {
          rowsPerProject.categoriesTotal = [];
          project.categoriesTotal.forEach((categoryTotal) => {
            let finalCategoryName: string;
            if (categoryTotal.isFullTotal) {
              finalCategoryName = t(
                summarizingMethod === SummarizingMethodEnum.SUM
                  ? 'Total (Sum)'
                  : 'Total (Avg)',
              );
            } else if (categoryTotal.isUncategorized) {
              finalCategoryName = t('Uncategorized');
            } else {
              finalCategoryName = t(categoryTotal.name);
            }

            rowsPerProject.categoriesTotal?.push({
              name: t(
                summarizingMethod === SummarizingMethodEnum.SUM
                  ? 'Total {project} (Sum)'
                  : 'Total {project} (Avg)',
                {
                  project: project.name,
                },
              ),
              categoryName: finalCategoryName,
              isUncategorized: categoryTotal.isUncategorized,
              isFullTotal: categoryTotal.isFullTotal,
              values: mapValues(categoryTotal.values, (value) => ({
                name: categoryTotal.name,
                originalValueUnit: value.unit,
                valueUnit: value.unit,
                originalValue: value.value,
                value: value.value,
              })),
            });
          });
        }
      }
    }

    return rowsPerProject;
  });

  // Total for the whole table.
  let total: TableData['total'];
  let categoriesTotal: TableData['categoriesTotal'];
  if (showTotal && canSummarize) {
    const { summarizingMethod } = dataPointType;
    const name = t(
      summarizingMethod === SummarizingMethodEnum.SUM
        ? 'Total (Sum)'
        : 'Total (Avg)',
    );

    total = {
      name,
      values: dates.reduce<Total['values']>((values, date) => {
        const [sum, count] = summarize(projects, date.key);

        return {
          ...values,
          [date.key]: {
            dataPointType,
            value: calculate(sum, count, summarizingMethod!),
            calculation: {
              sum,
              count,
            },
          },
        };
      }, {}),
    };
    categoriesTotal = orderBy(
      Object.entries(summarizeCategories(projects, dates)).map(
        ([categoryName, item]) => {
          let finalCategoryName: string;
          if (item.isFullTotal) {
            finalCategoryName = t(
              summarizingMethod === SummarizingMethodEnum.SUM
                ? 'Total (Sum)'
                : 'Total (Avg)',
            );
          } else if (item.isUncategorized) {
            finalCategoryName = t('Uncategorized');
          } else {
            finalCategoryName = t(categoryName);
          }

          return {
            name,
            categoryName: finalCategoryName,
            isUncategorized: item.isUncategorized,
            isFullTotal: item.isFullTotal,
            values: mapValues(item.values, ({ sum, count }) => {
              const value = calculate(
                sum,
                count,
                dataPointType.summarizingMethod!,
              );

              return {
                name: categoryName,
                originalValueUnit: item.unit,
                valueUnit: item.unit,
                originalValue: value,
                value,
              };
            }),
          };
        },
      ),
      [
        (item) => item.isFullTotal,
        (item) => item.isUncategorized,
        (item) => item.name,
      ],
      ['asc', 'asc', 'asc'],
    );
    if (categoriesTotal?.length === 0) {
      categoriesTotal = null;
    }
  } else {
    total = null;
    categoriesTotal = null;
  }

  return {
    projects: preparedProjects,
    total,
    categoriesTotal,
  };
}
