import { gql } from '@apollo/client/core';

export default gql`
  query OgSidePanel($entityId: String!, $dataPointTypeId: String!) {
    getRecommendations(entityId: $entityId, dataPointTypeId: $dataPointTypeId) {
      _id
      text
      tags
      updatedAt
      averageRating
      utilizationCount
    }
  }
`;
