<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import {
  ChevronRightIcon,
  PencilAltIcon,
  NewspaperIcon,
} from '@heroicons/vue/outline';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import type { TemplateType } from './OgAddProject.vue';

export type Values = {
  template: TemplateType;
};

type TemplateForm = {
  template: TemplateType | null;
};

type Props = {
  initialTemplate: TemplateType | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  previous: [event: Values];
  next: [event: Values];
}>();

const { t } = useI18n();

const form = reactive<TemplateForm>({
  template: null,
});
watch(
  () => props.initialTemplate,
  (newInitialTemplate) => {
    form.template = newInitialTemplate;
  },
  { immediate: true },
);

const validationRules = computed(() => ({
  template: {
    required: helpers.withMessage(t('Choosing template is required'), required),
  },
}));
const v$ = useVuelidate(validationRules, form);

const choosingTemplateOptions = computed(() => [
  {
    id: 'custom' as const,
    title: t('Start from scratch: '),
    subTitle: t('Custom questionnaire'),
    text: t('Upload your own questionnaire and add or additional questions'),
  },
  {
    id: 'default' as const,
    title: t('Start from a template: '),
    subTitle: t('Default template'),
    text: t('Use your company’s default template'),
  },
]);

function handleTemplateOptionClick(templateOption: TemplateType) {
  form.template = templateOption;
}

function handlePreviousClick() {
  emit('previous', {
    template: form.template!,
  });
}

function handleNextClick() {
  emit('next', {
    template: form.template!,
  });
}
</script>

<template>
  <h4 class="text-lg mb-8">
    {{ t('Choose template from the data library') }}
  </h4>

  <div>
    <div class="mb-48 pr-48">
      <div
        v-for="option in choosingTemplateOptions"
        :key="option.id"
        class="mb-5"
        :class="
          form.template === option.id
            ? 'border-primary bg-blue-50'
            : 'border-gray-400'
        "
      >
        <div
          class="rounded-md border p-3 flex items-center space-x-4 cursor-pointer"
          :class="
            form.template === option.id ? 'border-blue-600' : 'border-gray-400'
          "
          @click="handleTemplateOptionClick(option.id)"
        >
          <div
            :class="
              form.template === option.id ? 'text-blue-600' : 'border-gray-400'
            "
          >
            <PencilAltIcon v-if="option.id === 'custom'" class="w-5" />
            <NewspaperIcon v-else class="w-5" />
          </div>
          <div class="flex-1">
            <p class="text-sm mb-1">
              <span class="font-semibold">
                {{ option.title }}
              </span>
              <span>
                {{ option.subTitle }}
              </span>
            </p>
            <p class="text-xs">
              {{ option.text }}
            </p>
          </div>
          <div class="flex-0">
            <ChevronRightIcon class="text-blue-600 h-5 w-5" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-x-4 justify-between items-center">
      <div class="flex-0">
        <AtButton type="button" variant="outline" @click="handlePreviousClick">
          {{ t('Previous') }}
        </AtButton>
      </div>
      <div class="flex-0">
        <AtButton
          type="button"
          :disabled="v$.$invalid"
          @click="handleNextClick"
        >
          {{ t('Next') }}
        </AtButton>
      </div>
    </div>
  </div>
</template>
