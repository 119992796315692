<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PlusIcon } from '@heroicons/vue/outline';
import OgDataPointTypeSelect, {
  type OgDataPointTypeSelectModelValue,
} from '@/components/organisms/OgDataPointTypeSelect/OgDataPointTypeSelect.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import type { TKpi, TTargetCommon } from '../../../types';
import AtCreateKPIModalTitle from '../../AtCreateKPIModalTitle.vue';
import MlKPISelect from './MlKPISelect.vue';

const props = defineProps<{
  modelValue: OgDataPointTypeSelectModelValue[];
  customDataPointTypes: OgDataPointTypeSelectModelValue[];
  target?: TTargetCommon;
  kpis: TKpi[];
}>();

const emit = defineEmits<{
  (
    e: 'update:modelValue',
    dataPointTypes?: OgDataPointTypeSelectModelValue[],
  ): void;
  (
    e: 'update:customDataPointTypes',
    dataPointTypes?: OgDataPointTypeSelectModelValue[],
  ): void;
}>();

const isCustomDataPointSelectVisible = ref(false);

const mergedDataPointTypes = computed(() => [
  ...(props.target?.suggestedDataPointTypes ?? []),
  ...props.customDataPointTypes,
]);
const filterModelValue = (
  customDataPointTypes?: OgDataPointTypeSelectModelValue[],
) =>
  props.modelValue.filter((dpt) =>
    [
      ...(props.target?.suggestedDataPointTypes ?? []),
      ...(customDataPointTypes ?? []),
    ].find(({ _id }) => _id === dpt._id),
  );

const { t } = useI18n();
</script>

<template>
  <div v-if="target">
    <AtCreateKPIModalTitle
      class="mt-6"
      :title="t('Select KPI')"
      :subtitle="
        t(
          'KPIs are a component of the goal that need to be achieved. Select a KPI as your starting point to measure success.',
        )
      "
    />
    <MlKPISelect
      :modelValue="props.modelValue"
      class="mt-4"
      :options="mergedDataPointTypes"
      @update:modelValue="emit('update:modelValue', $event)"
    />
    <div class="flex items-end mx-1 mb-10 h-20">
      <AtButton
        v-if="!isCustomDataPointSelectVisible"
        variant="text"
        :icon="PlusIcon"
        class="flex items-center gap-2 mb-1 mr-4 text-sm font-medium text-info"
        @click="isCustomDataPointSelectVisible = true"
      >
        {{ t('Add custom datapoint') }}
      </AtButton>
      <OgDataPointTypeSelect
        v-if="isCustomDataPointSelectVisible"
        :multipleValue="props.customDataPointTypes"
        class="w-full max-w-[38rem]"
        customOnly
        multiple
        @update:multipleValue="
          (emit('update:customDataPointTypes', $event),
          emit('update:modelValue', filterModelValue($event)))
        "
      />
    </div>
  </div>
</template>
