<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import { useLazyQuery } from '@vue/apollo-composable';
import type { ApolloError } from '@apollo/client/core';
import { useStorage } from '@vueuse/core';
import type { Auth0ParseHashError } from 'auth0-js';
import {
  OnboardingStatusEnum,
  type PgLoginCallbackQuery,
} from '@/__generated__/types';
import useSignUpMutation from '@/api/mutations/User/signUp.mutation';
import { auth0, auth } from '@/api/auth/auth';
import { isGetStartedCompleted } from '@/utils/composables/useCurrentUser/useCurrentUser';
import { setActiveEntity } from '@/utils/services/activeEntity';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import PG_LOGIN_WORKSPACES_QUERY from './PgLoginCallback.query';

const router = useRouter();
const { t } = useI18n();

const { mutate: signup } = useSignUpMutation();

const shownErrorMessage = ref('');
const shownErrorDescription = ref('');

const sub = ref('');
const token = ref('');

const availableEntities = ref<string[]>([]);
const doSignupLoading = ref(false);
const isLoading = ref(true);
const isNavbarExpanded = useStorage('isCodioNavbarExpanded', true);

const {
  onResult: onLoginWorkspacesResult,
  load: loadLoginWorkspaces,
  onError: onLoginWorkspacesError,
} = useLazyQuery<PgLoginCallbackQuery>(PG_LOGIN_WORKSPACES_QUERY);

const tosChecked = ref(false);

onMounted(() => {
  localStorage.removeItem('X-Impersonate-As-Email');

  auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
    if (err) {
      handleAuth0Error(err);
      return;
    }

    if (!authResult?.accessToken) {
      // eslint-disable-next-line no-console
      console.error('invalid auth0 accessToken: ', token.value);
      return;
    }

    token.value = authResult.accessToken;

    auth0.client.userInfo(token.value, async (errUserinfo, auth0User) => {
      sub.value = auth0User.sub;

      onLoginWorkspacesResult((result) => {
        if (result.loading) return;

        if (result.data && result.data.getLoginWorkspaces.isNewUser) {
          availableEntities.value = result.data.getLoginWorkspaces.workspaces;
          isLoading.value = false;
        } else {
          doSignup();
        }
      });

      onLoginWorkspacesError((error) => {
        handleCodioError(error);
      });

      loadLoginWorkspaces(undefined, { token: token.value });
    });
  });
});

const doSignup = async (isSignup = false) => {
  if (!tosChecked.value && isSignup) {
    notify({
      type: 'error',
      text: t(
        'Please accept Terms and Conditions, Privacy Policy and Cookies Policy.',
      ),
    });
    return;
  }

  doSignupLoading.value = true;

  try {
    const signupResponse = await signup({
      signupInput: {
        token: token.value,
        sub: sub.value,
        ...(!isSignup || !tosChecked.value
          ? {}
          : { tosAcceptedAt: new Date() }),
      },
    });

    const nextUrl = localStorage.getItem('nextUrl');
    if (nextUrl) {
      localStorage.removeItem('nextUrl');
    }

    if (signupResponse && signupResponse.data) {
      const user = signupResponse.data.signup;
      setActiveEntity(user.entity._id);
      isNavbarExpanded.value = true;

      auth.setToken(token.value);

      const anyWindow = window as unknown as {
        posthog: { identify: (email: string) => void };
      };
      if (anyWindow.posthog && anyWindow.posthog.identify) {
        anyWindow.posthog.identify(user.email);
      }

      switch (user.onboardingStatus) {
        case OnboardingStatusEnum.INCOMPLETE:
          router.push('/onboarding');
          break;
        case OnboardingStatusEnum.COMPLETED:
          isGetStartedCompleted
            ? router.push(nextUrl ?? '/')
            : router.push('/get-started');
          break;

        default:
          break;
      }
    }
  } catch (e) {
    handleCodioError(e as ApolloError);
  }
};

const handleCodioError = (error: ApolloError) => {
  let errorMessage = '';
  // eslint-disable-next-line no-console
  console.error(error);

  switch (error.message) {
    case 'all_entities_disabled':
      errorMessage = t(
        'Sorry, all related entities to your account are disabled! Please contact the administrator of your organization.',
      );
      break;

    case 'account_deactivated':
      errorMessage = t(
        'Sorry, your account is disabled! Please contact the administrator of your organization.',
      );
      break;

    default:
      errorMessage = t('Unknown authentication error.');
      break;
  }

  shownErrorMessage.value = errorMessage;
  notify({ type: 'error', text: errorMessage });
};

const handleAuth0Error = (error: Auth0ParseHashError) => {
  // eslint-disable-next-line no-console
  console.error('handling', error);

  const interval = window.setInterval(() => {
    if (auth.getToken()) {
      const nextUrl = localStorage.getItem('nextUrl');
      if (nextUrl) {
        localStorage.removeItem('nextUrl');
      }
      clearInterval(interval);
      router.push(nextUrl ?? '/');
    }
  }, 1000);

  let errorMessage = '';

  switch (error.errorDescription) {
    case 'email_not_verified':
      errorMessage = t('E-Mail is not verified!');

      shownErrorDescription.value = t(
        'A verification email has been sent to your email address. Please click on the link in the email, then log in again.',
      );
      shownErrorMessage.value = errorMessage;
      notify({ type: 'error', text: errorMessage });

      // once the user clicked on the link, we want to redirect him to the dashboard in this tab.

      break;

    case 'emaildomain_not_whitelisted':
      errorMessage = t('E-Mail domain is not whitelisted!');

      shownErrorMessage.value = errorMessage;
      notify({ type: 'error', text: errorMessage });
      break;

    default:
      errorMessage = t('Unknown authentication error.');

      shownErrorMessage.value = errorMessage;
      notify({ type: 'error', text: errorMessage });
      break;
  }
};

const hyperlinkStyle =
  'underline text-blue-600 hover:text-blue-800 visited:text-purple-600';
</script>

<template>
  <div>
    <div v-if="shownErrorMessage">
      <div v-if="shownErrorMessage" class="text-xl text-red-600">
        {{ shownErrorMessage }}
      </div>

      <div v-if="shownErrorDescription" class="mt-3">
        {{ shownErrorDescription }}
      </div>

      <div v-if="shownErrorDescription || shownErrorMessage" class="mt-3">
        <router-link to="/logout" class="cursor-pointer">
          <i18n-t keypath="Click {here} to logout and try again.">
            <template #here>
              <u>{{ t('here') }}</u>
            </template>
          </i18n-t>
        </router-link>
      </div>
    </div>

    <div
      v-else-if="availableEntities.length && !isLoading"
      class="mt-5 grid gap-4"
    >
      <p>{{ t("You've been invited to the following workspace(s):") }}</p>

      <div
        v-for="entity in availableEntities"
        :key="entity"
        class="border-gray mt-5 grid grid-cols-4 rounded border p-4 shadow"
      >
        <p class="col-span-3 flex items-center font-semibold">
          {{ entity }}
        </p>
      </div>

      <AtButton
        :loading="doSignupLoading"
        :disabled="doSignupLoading"
        class="mt-5 w-full"
        @click="doSignup(true)"
      >
        {{ t('Continue') }}
      </AtButton>
    </div>
  </div>

  <div v-if="!availableEntities.length && !isLoading">
    <p>
      {{
        t(
          'You are about to create a new organisation at Codio Impact. If you want to join an existing organisation, please contact your administrator.',
        )
      }}
    </p>

    <AtButton
      :loading="doSignupLoading"
      :disabled="doSignupLoading"
      class="mt-5 w-full"
      @click="doSignup(true)"
    >
      {{ t('Create a new organisation') }}
    </AtButton>
  </div>

  <div v-if="!isLoading" class="mt-3">
    <AtCheckbox
      :required="true"
      :checked="tosChecked"
      @toggleCheckbox="(checked) => (tosChecked = checked)"
    >
      <template #label>
        <!--
          Links for ToS and Cookies are set to the same one of Privacy Policy.
          ToS needs its own page, and Cookie Policy is present on the Privacy Policy page (maybe add anchor on the page so it scrolls to the Cookie Policies).
          Needs DE translation.
        -->
        <p class="ml-3">
          <i18n-t keypath="I agree to the {ToS}, {Privacy} and {Cookies}.">
            <template #ToS>
              <a
                href="https://www.codioimpact.com/privacy-policy"
                rel="noopener noreferrer"
                target="_blank"
                :class="hyperlinkStyle"
                >{{ t('checkbox_Terms & Conditions') }}</a
              >
            </template>
            <template #Privacy>
              <a
                href="https://www.codioimpact.com/privacy-policy"
                rel="noopener noreferrer"
                target="_blank"
                :class="hyperlinkStyle"
                >{{ t('checkbox_Privacy Policy') }}</a
              >
            </template>
            <template #Cookies>
              <a
                href="https://www.codioimpact.com/privacy-policy"
                rel="noopener noreferrer"
                target="_blank"
                :class="hyperlinkStyle"
                >{{ t('checkbox_Cookies Policy') }}</a
              >
            </template>
          </i18n-t>
        </p>
      </template>
    </AtCheckbox>
  </div>

  <div v-if="false" class="text-center">
    <h1 class="my-5 text-2xl">
      {{ t('Please wait... Authentication in progress') }}
    </h1>
  </div>
</template>
