<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { notify } from '@kyvg/vue3-notification';
import useCreateEntityKPIMutation from '@/api/mutations/EntityKPI/createEntityKpi.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlStepper from '@/components/molecules/MlStepper.vue';
import MlModal from '@/components/molecules/MlModal.vue';
import type {
  TGoal,
  TKpi,
  TTargetCommon,
  TCreateKPIFormData,
  TDataPointType,
} from '../../types';
import AtCreateKPIModalTitle from '../AtCreateKPIModalTitle.vue';
import OgSelectGoalStep from './OgSelectGoalStep.vue';
import OgSelectTargetStep from './OgSelectTargetStep/OgSelectTargetStep.vue';
import OgSelectKPIStep from './OgSelectKPIStep/OgSelectKPIStep.vue';
import OgKPIForm from './OgKPIForm/OgKPIForm.vue';

enum Step {
  GOAL,
  TARGET,
  KPI,
  KPI_FORM,
}

const props = defineProps<{
  isRevealed: boolean;
  goals: TGoal[];
  kpis: TKpi[];
  preselected?: {
    goal?: TGoal;
    target?: TTargetCommon;
  };
}>();

const emit = defineEmits<{
  (e: 'update:isRevealed', isRevealed: boolean): void;
  (e: 'created', kpiIds: string[]): void;
}>();

const { t } = useI18n();
const v = useVuelidate();

const { mutate: createKPIs, loading } = useCreateEntityKPIMutation({
  fetchPolicy: 'no-cache',
});

const step = ref(Step.GOAL);
const goal = ref<TGoal>();
const target = ref<TTargetCommon>();
const dataPointTypes = ref<TDataPointType[]>([]);
const customDataPointTypes = ref<TDataPointType[]>([]);
const kpiFormData = ref<TCreateKPIFormData[]>([]);

const goalProgress = computed(() => ({
  [Step.GOAL]: !!goal.value,
  [Step.TARGET]: !!target.value,
  [Step.KPI]: !!dataPointTypes.value.length,
  [Step.KPI_FORM]: false,
}));

const handleCreateKPI = async (isRepeated = false) => {
  v.value.$touch();

  if (v.value.$error) return;

  try {
    const response = await createKPIs({
      createEntityKPIInputs: dataPointTypes.value.map((dpt, i) => ({
        targets: [target.value?._id ?? ''],
        ...kpiFormData.value[i],
        dataPointType: dpt._id,
      })),
    });

    emit(
      'created',
      response?.data?.createEntityKPI.map((kpi) => kpi._id) ?? [],
    );
    notify({ type: 'success', text: t('KPI has been created.') });
    if (isRepeated) {
      reset();
    } else {
      emit('update:isRevealed', false);
    }
  } catch {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
};

const reset = () => {
  step.value = Step.GOAL;
  goal.value = undefined;
  target.value = undefined;
  dataPointTypes.value = [];
  customDataPointTypes.value = [];
  kpiFormData.value = [];
  v.value.$reset();
};

// Temporal hack because I can't properly unmount component with v-if without losing animation. Remove after modal rework.
const modalKey = ref(0);
watch(
  () => props.isRevealed,
  () => {
    if (!props.isRevealed) {
      reset();
      setTimeout(() => {
        modalKey.value += 1;
      }, 1000);
    }
  },
);

watch(props, () => {
  if (!props.preselected) return;

  if (props.preselected.goal) {
    goal.value = props.preselected.goal;
    step.value = Step.TARGET;
  }

  if (props.preselected.target) {
    target.value = props.preselected.target;
    step.value = Step.KPI;
  }
});
</script>

<template>
  <MlModal
    :key="modalKey"
    class="!w-[90%] !max-w-[83rem] px-28 py-8"
    :overlayDismiss="false"
    :isRevealed="props.isRevealed"
    v-bind="$attrs"
    @update:isRevealed="emit('update:isRevealed', $event)"
  >
    <MlStepper
      :value="step"
      :steps="[
        'Select a goal',
        'Select a target',
        'Select a KPI',
        'Set up a KPI',
      ]"
    />

    <OgSelectGoalStep
      v-if="step === Step.GOAL"
      v-model="goal"
      :goals="props.goals"
    />
    <OgSelectTargetStep
      v-if="step === Step.TARGET"
      v-model="target"
      :goal="goal"
    />
    <OgSelectKPIStep
      v-if="step === Step.KPI"
      v-model="dataPointTypes"
      v-model:customDataPointTypes="customDataPointTypes"
      :target="target"
      :kpis="props.kpis"
    />

    <template v-if="step === Step.KPI_FORM && dataPointTypes.length">
      <AtCreateKPIModalTitle
        class="my-6"
        :title="t('Set up KPI')"
        :subtitle="
          t(
            'Create and set up the details of your KPI with the different options.',
          )
        "
      />
      <div v-for="(dpt, i) in dataPointTypes" :key="dpt._id">
        <div v-if="i > 0" class="divider" />
        <div>
          <p class="font-medium">
            {{ t(dpt.friendlyName) }}
          </p>
          <p class="mt-2 text-sm">
            {{ t(dpt.question) }}
          </p>
        </div>

        <OgKPIForm v-model="kpiFormData[i]" :dpt="dpt" :loading="loading" />
      </div>
    </template>

    <div class="mt-20 flex justify-between">
      <AtButton v-if="step !== 0" variant="text" @click="step -= 1">
        {{ t('Previous') }}
      </AtButton>
      <AtButton
        v-if="step < Step.KPI_FORM"
        class="ml-auto"
        :disabled="!goalProgress[step]"
        @click="step += 1"
      >
        {{ t('Next') }}
      </AtButton>
      <div v-else class="ml-auto">
        <AtButton
          variant="text"
          :disabled="v.$error || loading"
          @click="handleCreateKPI(true)"
        >
          {{ t('Finish and create next') }}
        </AtButton>
        <AtButton
          class="ml-4"
          :disabled="v.$error || loading"
          @click="handleCreateKPI()"
        >
          {{ t('Finish') }}
        </AtButton>
      </div>
    </div>
  </MlModal>
</template>
