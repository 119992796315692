import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  RequestReopenDataPointRequestMutation,
  RequestReopenDataPointRequestMutationVariables,
} from '@/__generated__/types';

export const REQUEST_REOPEN_DATAPOINT_REQUEST = gql`
  mutation RequestReopenDataPointRequest(
    $reopenRequestedDataPointRequestInput: ReopenRequestedDataPointRequestInput!
  ) {
    requestReopenDataPointRequest(
      reopenRequestedDataPointRequestInput: $reopenRequestedDataPointRequestInput
    ) {
      _id
      isReopenRequestPending
      childs {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  RequestReopenDataPointRequestMutation,
  RequestReopenDataPointRequestMutationVariables
>;

const useRequestReopenDataPointRequestMutation = (options?: TOptions) =>
  useMutation<
    RequestReopenDataPointRequestMutation,
    RequestReopenDataPointRequestMutationVariables
  >(REQUEST_REOPEN_DATAPOINT_REQUEST, options);

export default useRequestReopenDataPointRequestMutation;
