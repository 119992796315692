<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import capitalize from 'lodash/capitalize';
import { computed } from 'vue';
import dayjs from '@/lib/dayjs/config';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import type { TDataPointRequest } from '../../../types';

const props = defineProps<{
  dataPointRequest: TDataPointRequest;
  isChilds?: boolean;
}>();
const { currentUser } = useCurrentUser();

const delegations = computed(() => props.dataPointRequest.delegations ?? []);
const isDelegationAssignee = computed(
  () =>
    !!delegations.value.find(
      (delegation) => delegation?.user._id === currentUser.value?._id,
    ),
);

const { t } = useI18n();
</script>

<template>
  <div class="grid gap-1 w-full justify-end">
    <AtBadge
      v-if="!delegations.length || isDelegationAssignee"
      :type="props.dataPointRequest.status"
    >
      {{ capitalize(t(dataPointRequest.status)) }}
    </AtBadge>
    <AtBadge
      v-else-if="delegations.find((delegation) => delegation?.user.isExternal)"
      :type="dataPointRequest.status"
    >
      {{ capitalize(t('Forwarded')) }}
    </AtBadge>
    <AtBadge v-else :type="dataPointRequest.status">
      {{ capitalize(t('Assigned')) }}
    </AtBadge>
  </div>
  <span
    class="text-gray-400 text-right w-full text-sm"
    :title="props.dataPointRequest.dueDate"
  >
    <template v-if="!props.isChilds">{{
      dayjs(props.dataPointRequest.dueDate).fromNow()
    }}</template>
  </span>
</template>
