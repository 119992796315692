export enum BadgeType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
  NEUTRAL = 'neutral',
  CLEAR = 'clear',
  UP = 'up',
  DOWN = 'down',
  RIGHT = 'right',
}

export enum BadgeSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
}
