<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import {
  ChevronRightIcon,
  ChevronDownIcon,
  TrashIcon,
} from '@heroicons/vue/outline';
import { useI18n } from 'vue-i18n';
import intersection from 'lodash/intersection';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import type { TTargetItem } from '../types';
import MlKpi from './MlKpi.vue';
import { useDeleteKPIs } from './useDeleteKPIs';
import PG_GOALS_OVERVIEW_QUERY from './PgGoalsOverview.query';

const props = defineProps<{
  target: TTargetItem;
  goalId: string;
  newKpiIds: string[];
}>();

const { t } = useI18n();

const isTargetExpanded = ref(false);

const kpiIds = computed(() => props.target.kpis.map(({ kpi }) => kpi._id));
const hasKPIs = computed(() => !!props.target.kpis?.length);

const { deleteKPIs } = useDeleteKPIs({
  refetchQueries: [{ query: PG_GOALS_OVERVIEW_QUERY }],
});
const handleDeleteTarget = () =>
  deleteKPIs(kpiIds.value, {
    title: t('Are you sure you want to delete this target?'),
    text: t('This will automatically delete all subordinate KPIs as well.'),
  });

watch(
  () => props.newKpiIds,
  (newKpiIds) => {
    if (intersection(newKpiIds, kpiIds.value).length) {
      isTargetExpanded.value = true;
    }
  },
  { immediate: true },
);
</script>

<template>
  <div
    class="grid grid-cols-[1fr_3fr_1fr_1fr_1fr_0.7fr] gap-2 items-center justify-center border-b px-4 py-6 cursor-pointer hover:bg-gray-200"
    :class="{ 'cursor-pointer': hasKPIs }"
    @click="hasKPIs ? (isTargetExpanded = !isTargetExpanded) : null"
  >
    <div class="flex items-center ml-2">
      {{ t('Target') }}
      <template v-if="hasKPIs">
        <ChevronDownIcon v-if="isTargetExpanded" class="h-5 ml-4" />
        <ChevronRightIcon v-else class="h-5 ml-4" />
      </template>
    </div>
    <span>{{ t(props.target.title) }}</span>
    <span />
    <span />
    <AtProgress
      class="w-full max-w-[6rem]"
      :percent="props.target.statusPercentage"
      :status="props.target.statusPercentage >= 90 ? 'success' : 'warning'"
      showPercentNumber
    />
    <AtIconButton
      class="w-fit ml-auto text-gray-400"
      :icon="TrashIcon"
      :title="t('Delete')"
      @click.stop="handleDeleteTarget"
    />
  </div>
  <template v-if="isTargetExpanded">
    <MlKpi
      v-for="kpi in props.target.kpis"
      :key="kpi.title"
      :kpi="kpi"
      :goalId="goalId"
      :targetId="target.target._id ?? ''"
    />
  </template>
</template>
