<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { DoubleMaterialityIroImpactType } from '@/__generated__/types';
import { calculateMaterialityForActualNegative } from '../../../utils';
import { useStore } from '../../../store';
import OgScore, { type RowItem } from '../OgScore.vue';

const { t } = useI18n();
const store = useStore();

const headers = computed(() => [
  { text: t('Impacts'), value: 'impact' },
  { text: t('Scale'), value: 'scale', columnClasses: 'w-[150px]' },
  { text: t('Scope'), value: 'scope', columnClasses: 'w-[150px]' },
  {
    text: t('Irremediability'),
    value: 'irremediability',
    columnClasses: 'w-[150px]',
  },
  { text: t('Materiality'), value: 'materiality', columnClasses: 'w-[100px]' },
]);

const items = computed<RowItem[]>(() =>
  store.value.iroImpacts
    .filter(
      (iroImpact) =>
        iroImpact.type === DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE,
    )
    .map((iroImpact) => ({
      _id: iroImpact._id,
      impact: iroImpact,
      riskAndOpportunity: null,
      topic: store.value.topics.find(
        (topic) => topic._id === iroImpact.topicId,
      )!,
      materiality: calculateMaterialityForActualNegative(
        iroImpact.scale,
        iroImpact.scope,
        iroImpact.irremediability,
      ),
    })),
);
</script>

<template>
  <OgScore
    :headers="headers"
    :items="items"
    :title="t('Identified negative actual impacts:')"
    infoType="error"
    goBackRoute="doubleMaterialityIROsRisksAndOpportunities"
    nextRoute="doubleMaterialityIROsScorePotentialNegative"
  >
    <p class="mb-4">
      {{
        t(
          'For negative actual impacts, materiality is determined by the severity of the impact. This severity consists of three criteria, please rate these from 1 (being the lowest) to 5 (being the highest):',
        )
      }}
    </p>
    <ul class="list-disc pl-6">
      <li>
        {{
          t(
            'The scale: how grave or beneficial the impact is, e.g. does the impact lead to non-compliance with laws or regulations?',
          )
        }}
      </li>
      <li>
        {{
          t(
            'The scope: how widespread the impact is, e.g. the number of individuals or countries affected.',
          )
        }}
      </li>
      <li>
        {{
          t(
            'Irremediability: any limits on the ability to restore those affected to a situation at least the same as, or equivalent to, their situation before the impact.',
          )
        }}
      </li>
    </ul>
  </OgScore>
</template>
