import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DeleteTaxonomyDocumentMutation,
  DeleteTaxonomyDocumentMutationVariables,
} from '@/__generated__/types';

export const DELETE_TAXONOMY_FILE = gql`
  mutation DeleteTaxonomyDocument($taxonomyDocumentId: String!) {
    deleteTaxonomyDocument(taxonomyDocumentId: $taxonomyDocumentId)
  }
`;

type TOptions = UseMutationOptions<
  DeleteTaxonomyDocumentMutation,
  DeleteTaxonomyDocumentMutationVariables
>;

const useDeleteTaxonomyDocument = (options?: TOptions) =>
  useMutation<
    DeleteTaxonomyDocumentMutation,
    DeleteTaxonomyDocumentMutationVariables
  >(DELETE_TAXONOMY_FILE, options);

export default useDeleteTaxonomyDocument;
