import { gql } from '@apollo/client/core';

export default gql`
  query OgDataPointTypeSelect(
    $withActiveReportingFramework: Boolean
    $withExistingDataPoints: Boolean
  ) {
    getCategoriesWithSubcategories {
      _id
      slug
      entity {
        _id
      }
      location {
        _id
      }
      organization {
        _id
      }
      subcategories {
        _id
        slug
        orderPosition
      }
    }
    getNumericDataPointTypes(
      withActiveReportingFramework: $withActiveReportingFramework
      withExistingDataPoints: $withExistingDataPoints
    ) {
      _id
      friendlyName
      name
      question
      entity {
        _id
      }

      activeReportingFramework {
        _id
        category
        subcategory
      }
      valueDataType
      valueUnit
      valueUnitDivisor
    }
  }
`;
