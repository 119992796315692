import { computed, inject, reactive } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import type {
  PgStandardViewQuery,
  PgStandardViewQueryVariables,
} from '@/__generated__/types';
import { ReportingFrameworkEnum } from '@/__generated__/types';
import {
  type DateRange,
  formatDateRangeForBE,
  useReportingPeriod,
} from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import type {
  DataPoint,
  DataPointQualitativeAdmin,
  DataPointSummaryAdmin,
  MultiLevelSelectItem,
  Project,
} from '../types';
import { getProjectsTrees, type ProjectsTree } from '../services/projectsTree';
import { getPerDataPointType } from '../services/prepareStandardData';
import PG_STANDARD_VIEW_QUERY from './PgStandardView.query';

export function usePgStandardView() {
  const adminMode = inject(
    'adminMode',
    computed(() => false),
  );

  const { dateRange } = useReportingPeriod();
  const filters: {
    project: string;
    workspaces: string[];
    standards: string[];
    categories: string[];
    subcategories: string[];
    dateRange: DateRange;
  } = reactive({
    project: '',
    workspaces: [],
    standards: [],
    categories: [],
    subcategories: [],
    dateRange,
  });

  const variables = computed<PgStandardViewQueryVariables>(() => ({
    ...formatDateRangeForBE(filters.dateRange.from, filters.dateRange.to),
    adminMode: adminMode.value,
    projectId: filters.project,
    workspaceIds: filters.workspaces,
  }));

  const { result, loading } = useQuery<
    PgStandardViewQuery,
    PgStandardViewQueryVariables
  >(PG_STANDARD_VIEW_QUERY, variables, { fetchPolicy: 'cache-and-network' });

  const allDataPoints = computed<
    (DataPointQualitativeAdmin | DataPointSummaryAdmin | DataPoint)[]
  >(() => {
    return adminMode.value
      ? [
          ...(result?.value?.getStandardDataPointsByProjectAndDateRangeAdmin ??
            []),
          ...(result?.value
            ?.getQualitativeDataPointsByProjectAndDateRangeAdmin ?? []),
        ]
      : (result?.value?.getStandardDataPointsByProjectAndDateRange ?? []);
  });

  const summaryDataPoints = computed(
    () => result?.value?.getSummaryDataPointsByProjectAndDateRangeAdmin ?? [],
  );

  const categoriesPresentInDataPoints = computed(() => {
    const categories: Record<string, Set<string>> = {};

    allDataPoints.value.forEach((dataPoint) => {
      categories[dataPoint.dataPointType.activeReportingFramework.category] ??=
        new Set<string>();

      categories[dataPoint.dataPointType.activeReportingFramework.category].add(
        dataPoint.dataPointType.activeReportingFramework.subcategory,
      );
    });

    return categories;
  });

  const filteredDataPoints = computed(() => {
    return allDataPoints.value
      .filter((dataPoint) => {
        if (filters.standards.length === 0) {
          return true;
        }

        return dataPoint.dataPointType.activeReportingFramework.groups.some(
          (group) => {
            return filters.standards.some((standard) => {
              const isMatched = !!group.match(`^${standard}-`);
              const isDNKMatched = !!group.match(`^${standard}, Aspect`);
              const isSomeESRSMatched = !!group.match(`^${standard},`); // for some ESRS cases like "ESRS 2, para. 5-a"

              return isMatched || isDNKMatched || isSomeESRSMatched;
            });
          },
        );
      })
      .filter((dataPoint) => {
        if (filters.subcategories.length === 0) {
          return true;
        }

        return filters.subcategories.some((subcategory) => {
          return (
            subcategory ===
            dataPoint.dataPointType.activeReportingFramework.subcategory
          );
        });
      })
      .filter((dataPoint) => {
        if (filters.categories.length === 0) {
          return true;
        }

        return filters.categories.some((category) => {
          return (
            category ===
            dataPoint.dataPointType.activeReportingFramework.category
          );
        });
      })
      .map((dataPoint) => ({
        ...dataPoint,
        ...(dataPoint.displayValue ? { value: dataPoint.displayValue } : {}),
        ...(dataPoint.displayValueUnit
          ? { valueUnit: dataPoint.displayValueUnit }
          : {}),
        ...(dataPoint.displayValueUnit
          ? {
              dataPointType: {
                ...dataPoint.dataPointType,
                valueUnit: dataPoint.displayValueUnit,
              },
            }
          : {}),
      }));
  });

  // If in admin workspace, show entities, otherwise show locations.
  const projects = computed<Project[]>(() => {
    if (adminMode.value) {
      return (result.value?.entitySummaries || []).map(
        (location) =>
          ({
            _id: location._id,
            name: location.name,
            entityId: location.entityId,
            entityName: location.entityName,
            parent: location.parent,
            hasParentInfo: location.hasParentInfo,
          }) as Project,
      );
    }

    return (result.value?.entityLocationSummaries || []).map(
      (location) =>
        ({
          _id: location._id,
          name: location.name,
          parent: location.parent,
          hasParentInfo: location.hasParentInfo,
        }) as Project,
    );
  });

  const dataPointTypesData = computed(() =>
    getPerDataPointType(
      filteredDataPoints.value,
      projects.value,
      summaryDataPoints.value,
    ),
  );

  const noData = computed(
    () => allDataPoints.value.length === 0 && !loading.value,
  );

  const projectsOptions = computed(() => {
    const treeToSelect = (trees: ProjectsTree[]) => {
      return trees.map((tree) => {
        const selectItem: MultiLevelSelectItem = {
          id: tree.id,
          title: tree.name,
        };

        if (tree.children.length > 0) {
          selectItem.children = treeToSelect(tree.children);
        }

        return selectItem;
      });
    };
    const projectsTrees = getProjectsTrees(projects.value);

    return treeToSelect(projectsTrees);
  });
  const workspaceOptions = computed<Record<string, string>>(() => {
    if (result.value?.getCurrentUserEntities) {
      return result.value.getCurrentUserEntities.reduce(
        (acc, { _id, name }) => ({
          ...acc,
          [_id]: name,
        }),
        {},
      );
    }

    return {};
  });

  // filters categories and their subcategories to only list ones available in the datapoints result list
  const categoriesOptions = computed(
    () =>
      result.value?.getCategoriesWithSubcategoriesForUser
        .filter((c) =>
          Object.keys(categoriesPresentInDataPoints.value).includes(c.slug),
        )
        .map((category) => ({
          ...category,
          subcategories: category.subcategories.filter((subcategory) =>
            categoriesPresentInDataPoints.value[category.slug].has(
              subcategory.slug,
            ),
          ),
        })) ?? [],
  );

  const standardsOptions = computed<Record<string, string>>(() => {
    const options: Record<string, string> = {};

    filteredDataPoints.value
      .filter((dataPoint) => {
        if (
          dataPoint.dataPointType.activeReportingFramework.groups.length > 0
        ) {
          return true;
        }

        return (
          dataPoint.dataPointType.activeReportingFramework.framework ===
          ReportingFrameworkEnum.CUSTOM
        );
      })
      .forEach((dataPoint) => {
        dataPoint.dataPointType.activeReportingFramework.groups.forEach(
          (group) => {
            const match = group.match(/^([0-9]+)/);
            const matchSomeESRS = group.match(/^(E\d+|ESRS \d+|S\d+)/);
            const name = match || matchSomeESRS;

            if (name && name[0]) {
              options[name[0]] =
                `${dataPoint.dataPointType.activeReportingFramework.framework}-${name[0]}`;
            }
          },
        );
      });

    return options;
  });

  return {
    isLoading: loading,
    filters,
    filtersData: {
      projectsOptions,
      workspaceOptions,
      categoriesOptions,
      standardsOptions,
    },
    noData,
    dataPointTypesData,
  };
}
