import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DeleteDataPointRequestMutation,
  DeleteDataPointRequestMutationVariables,
} from '@/__generated__/types';

export const DELETE_DATAPOINT_REQUEST = gql`
  mutation DeleteDataPointRequest($dataPointRequestId: String!) {
    deleteDataPointRequest(dataPointRequestId: $dataPointRequestId)
  }
`;

type TOptions = UseMutationOptions<
  DeleteDataPointRequestMutation,
  DeleteDataPointRequestMutationVariables
>;

const useDeleteDataPointRequestMutation = (options?: TOptions) =>
  useMutation<
    DeleteDataPointRequestMutation,
    DeleteDataPointRequestMutationVariables
  >(DELETE_DATAPOINT_REQUEST, options);

export default useDeleteDataPointRequestMutation;
