<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { boxed } from '@syncedstore/core';
import PlusIcon from '@heroicons/vue/solid/PlusIcon';
import {
  DoubleMaterialityDownloadDocumentEnum,
  DoubleMaterialityIroRiskAndOpportunityType,
} from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlCommentBox from '@/components/molecules/MlCommentBox.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import { useDocumentDownload } from '../../../composables/useDocumentDownload';
import { useStore, useStoreMethods } from '../../../store';
import MlInfo from '../../../MlInfo.vue';
import OgDocument, { type UploadedDocument } from '../../../OgDocument.vue';
import OgTable from '../../../OgTable.vue';

type RowItem = {
  _id: string;
  index: number;
  topicId: string;
};

type RiskAndOpportunityTopic = {
  _id: string;
  topic: string;
  subTopic: string;
  subSubTopic: string;
};

const { t } = useI18n();
const router = useRouter();
const guidanceDownload = useDocumentDownload(
  DoubleMaterialityDownloadDocumentEnum.RISKS_AND_OPPORTUNITIES_SCORING,
);
const store = useStore();
const {
  addIroRiskAndOpportunity,
  removeIroRiskAndOpportunity,
  changeIroRiskAndOpportunityRiskAndOpportunity,
  changeIroRiskAndOpportunityType,
} = useStoreMethods();

const { currentUser } = useCurrentUser();

const headers = computed(() => [
  { text: t('Standard'), value: 'topic', columnClasses: 'w-[240px]' },
  { text: t('Sub-topic'), value: 'subTopic', columnClasses: 'w-[200px]' },
  {
    text: t('Sub-sub-topic'),
    value: 'subSubTopic',
    columnClasses: 'w-[200px]',
  },
  { text: t('Risks and opportunities'), value: 'risksAndOpportunities' },
  { text: t('Type'), value: 'type', columnClasses: 'w-[200px]' },
]);
const items = computed<RowItem[]>(() => {
  const iroRisksAndOpportunities = store.value.iroRisksAndOpportunities.map(
    (iroRiskAndOpportunity, index) => ({
      _id: iroRiskAndOpportunity._id,
      index,
      topicId: iroRiskAndOpportunity.topicId,
    }),
  );

  return store.value.topics
    .filter((topic) => topic.topic && topic.subTopic)
    .flatMap((topic) =>
      iroRisksAndOpportunities.filter(
        (iroRiskAndOpportunity) => iroRiskAndOpportunity.topicId === topic._id,
      ),
    );
});
const topics = computed(() =>
  store.value.topics.reduce<Record<string, RiskAndOpportunityTopic>>(
    (acc, topic) => {
      acc[topic._id.toString()] = {
        _id: topic._id.toString(),
        topic: `${topic.esrs} - ${t(topic.topic)}`,
        subTopic: t(topic.subTopic.toString()),
        subSubTopic: t(topic.subSubTopic.toString()),
      };

      return acc;
    },
    {},
  ),
);
const typeOptions = computed(() => ({
  [DoubleMaterialityIroRiskAndOpportunityType.RISK]: t('Risk'),
  [DoubleMaterialityIroRiskAndOpportunityType.OPPORTUNITY]: t('Opportunity'),
}));

function handledRiskAndOpportunityChange(index: number, event: string) {
  changeIroRiskAndOpportunityRiskAndOpportunity(index, event);
}

function handleTypeChange(
  index: number,
  event: DoubleMaterialityIroRiskAndOpportunityType | null,
) {
  changeIroRiskAndOpportunityType(index, event);
}

function handleRemoveRow(event: number) {
  removeIroRiskAndOpportunity(event);
}

function handleAddRowClick(index: number, topicId: string) {
  addIroRiskAndOpportunity(index, topicId);
}

function handleFileChange(index: number, event: UploadedDocument) {
  store.value.iroRisksAndOpportunities[index].document = boxed(event);
}
</script>

<template>
  <MlInfo type="warning" class="mb-4">
    <p>
      {{
        // eslint-disable-next-line vue/max-len
        t(
          `Risks and opportunities refer to the potential effects that a sustainability matter can have on a company's cash flow and financial value creation. Identify risks or opportunities for each sustainability matter on your shortlist (typically up to three risks or opportunities per matter). Click "add rows" to add more for the same sustainability matter. If you can't identify any risks/opportunities for one of the sustainability matters, simply leave it blank for now.`,
        )
      }}
    </p>
  </MlInfo>

  <OgTable :headers="headers" :items="items" @removeRow="handleRemoveRow">
    <template #item-topic="row: RowItem">
      <AtInput
        type="text"
        readonly
        disabled
        :value="topics[row.topicId].topic"
      />
    </template>
    <template #item-subTopic="row: RowItem">
      <AtInput
        type="text"
        readonly
        disabled
        :value="topics[row.topicId].subTopic"
      />
    </template>
    <template #item-subSubTopic="row: RowItem">
      <AtInput
        type="text"
        readonly
        disabled
        :value="topics[row.topicId].subSubTopic"
      />
    </template>
    <template #item-risksAndOpportunities="row: RowItem">
      <AtInput
        :modelValue="
          store.iroRisksAndOpportunities[row.index].riskAndOpportunity
        "
        type="text"
        :placeholder="t('Define your risk/opportunities...')"
        @update:modelValue="handledRiskAndOpportunityChange(row.index, $event)"
      />
    </template>
    <template #item-type="row: RowItem">
      <MlSelect
        :modelValue="
          store.iroRisksAndOpportunities[row.index].type ?? undefined
        "
        :options="typeOptions"
        @update:modelValue="handleTypeChange(row.index, $event)"
      />
    </template>
    <template #expand="row: RowItem">
      <div class="mb-4 flex justify-between items-center">
        <div class="flex space-x-2.5">
          <div>
            <button
              type="button"
              aria-label="add rows"
              class="flex text-gray-400 text-xs space-x-2 hover:text-primary"
              @click.prevent="handleAddRowClick(row.index, row.topicId)"
            >
              <PlusIcon class="h-4" />
              {{ t('Add rows') }}
            </button>
          </div>
          <OgDocument
            :userId="currentUser?._id!"
            :file="
              (store.iroRisksAndOpportunities[row.index].document ??
                null) as unknown as UploadedDocument
            "
            @update:file="handleFileChange(row.index, $event)"
          />
          <MlCommentBox
            v-model="store.iroRisksAndOpportunities[row.index].comment"
            iconSize="sm"
            class="text-xs text-gray-400"
          />
        </div>
      </div>
    </template>
  </OgTable>

  <div class="mt-auto flex items-center justify-between">
    <div class="flex space-x-3">
      <AtButton
        type="button"
        variant="outline"
        @click="router.push({ name: 'doubleMaterialityIROsImpacts' })"
      >
        {{ t('Go back') }}
      </AtButton>
      <AtButton
        type="button"
        variant="outline"
        :loading="guidanceDownload.loading.value"
        @click="guidanceDownload.exportFile"
      >
        {{ t('Download guidance') }}
      </AtButton>
    </div>
    <AtButton
      type="button"
      @click="router.push({ name: 'doubleMaterialityIROsScoreActualNegative' })"
    >
      {{ t('Next') }}
    </AtButton>
  </div>
</template>
