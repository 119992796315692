import { gql } from '@apollo/client/core';

export default gql`
  query PgStandardView(
    $dateFrom: String!
    $dateTo: String!
    $projectId: String
    $workspaceIds: [String!]
    $adminMode: Boolean!
  ) {
    entityLocationSummaries {
      _id
      name
      parent
      hasParentInfo
    }

    getCurrentUserEntities @include(if: $adminMode) {
      _id
      name
    }

    getCategoriesWithSubcategoriesForUser {
      _id
      slug

      subcategories {
        _id
        slug
        orderPosition
      }
    }

    getStandardDataPointsByProjectAndDateRange(
      dateFrom: $dateFrom
      dateTo: $dateTo
      projectId: $projectId
    ) @skip(if: $adminMode) {
      _id
      value
      valueUnit
      displayValue
      displayValueUnit

      valueSource {
        name
        value
        originalValueUnit
        originalValue
      }

      from
      to
      comment

      entity {
        _id
        name
      }

      location {
        _id
        name
      }

      dataPointRequest {
        _id
        delegations {
          _id
        }
      }

      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        valueUnitDivisor
        valueDataType
        valueDataTypeOptions
        refreshInterval
        summarizingMethod

        emissionType
        emissionCategory
        emissionSubcategory

        emissionFactors {
          _id
          year
          factor
        }

        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
          order
        }
        reportingFrameworks {
          _id
          framework
          groups
          order
        }
      }
    }

    getStandardDataPointsByProjectAndDateRangeAdmin(
      dateFrom: $dateFrom
      dateTo: $dateTo
      workspaceIds: $workspaceIds
    ) @include(if: $adminMode) {
      value
      valueUnit
      displayValue
      displayValueUnit

      valueSource {
        name
        value
        originalValueUnit
        originalValue
      }

      from
      to
      comment

      entity {
        _id
        name
      }

      location {
        _id
        name
      }

      dataPointRequest {
        _id
        delegations {
          _id
        }
      }

      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        valueUnitDivisor
        valueDataType
        valueDataTypeOptions
        refreshInterval
        summarizingMethod

        emissionType
        emissionCategory
        emissionSubcategory

        emissionFactors {
          _id
          year
          factor
        }

        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
          order
        }
        reportingFrameworks {
          _id
          framework
          groups
          order
        }
      }
    }

    entitySummaries {
      _id
      name
      entityId
      entityName
      parent
      hasParentInfo
    }

    getSummaryDataPointsByProjectAndDateRangeAdmin(
      dateFrom: $dateFrom
      dateTo: $dateTo
      workspaceIds: $workspaceIds
    ) @include(if: $adminMode) {
      value
      valueUnit
      displayValue
      displayValueUnit

      valueSource {
        name
        originalValueUnit
        originalValue
      }

      type
      from
      to
      comment

      dataPointRequest {
        _id
        delegations {
          _id
        }
      }

      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        valueUnitDivisor
        valueDataType
        valueDataTypeOptions
        refreshInterval
        summarizingMethod

        emissionType
        emissionCategory
        emissionSubcategory

        emissionFactors {
          _id
          year
          factor
        }

        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
          order
        }
        reportingFrameworks {
          _id
          framework
          groups
          order
        }
      }
    }

    getQualitativeDataPointsByProjectAndDateRangeAdmin(
      dateFrom: $dateFrom
      dateTo: $dateTo
      workspaceIds: $workspaceIds
    ) @include(if: $adminMode) {
      value
      valueUnit
      displayValue
      displayValueUnit

      valueSource {
        name
        value
        originalValueUnit
        originalValue
      }
      valueUnit

      type
      from
      to
      comment

      entity {
        _id
        name
      }

      location {
        _id
        name
      }

      dataPointRequest {
        _id
        delegations {
          _id
        }
      }

      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        valueUnitDivisor
        valueDataType
        valueDataTypeOptions
        refreshInterval
        summarizingMethod

        emissionType
        emissionCategory
        emissionSubcategory

        emissionFactors {
          _id
          year
          factor
        }

        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
          order
        }
        reportingFrameworks {
          _id
          framework
          groups
          order
        }
      }
    }
  }
`;
