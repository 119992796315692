<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import dayjs from '@/lib/dayjs/config';
import { type DateRange } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import type { OgDateRangePickerQuery } from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlDatePicker, {
  type MlDatePickerModelValue,
} from '@/components/molecules/MlDatePicker.vue';
import OG_DATE_RANGE_PICKER_QUERY from './OgDateRangePicker.query';

export type OgDateRangePickerModelValue = DateRange;

type Props = {
  modelValue: OgDateRangePickerModelValue;
  secondaryModel?: OgDateRangePickerModelValue;
  inline?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  inline: false,
  secondaryModel: undefined,
});

const emit = defineEmits<{
  'update:modelValue': [value: OgDateRangePickerModelValue];
  'update:secondaryModel': [value: OgDateRangePickerModelValue];
}>();

const { t } = useI18n();

const { result } = useQuery<OgDateRangePickerQuery>(OG_DATE_RANGE_PICKER_QUERY);
const reportingPeriod = computed(
  () => result.value?.getOwnUser.entity.entitySettings.activeReportingPeriod,
);

// In order to use input value on blur, "autoApply" prop is used on vue-datepicker.
// To use Apply button, vue-datepicker values are stored in internalValue. On Apply click, new value is emitted.
const internalValue = ref<MlDatePickerModelValue>([
  props.modelValue.from.toISOString(),
  props.modelValue.to.toISOString(),
]);
watch(props.modelValue, (newValue) => {
  internalValue.value = [
    newValue.from.toISOString(),
    newValue.to.toISOString(),
  ];
});

const presetRanges = computed<{ label: string; range: [string, string] }[]>(
  () => {
    const today = dayjs.utc().hour(0).minute(0).second(0).millisecond(0);
    const getLastMonth: () => [string, string] = () => {
      const dateFrom = today.set('date', 1).subtract(1, 'month');
      const dateTo = dateFrom.add(1, 'month').subtract(1, 'day');

      return [dateFrom.toISOString(), dateTo.toISOString()];
    };
    const getLastQuarter: () => [string, string] = () => {
      const dateFrom = today
        .set('date', 1)
        .set('month', 3 * Math.floor(today.month() / 3) - 3);
      const dateTo = dateFrom.add(3, 'months').subtract(1, 'day');

      return [dateFrom.toISOString(), dateTo.toISOString()];
    };
    const getLastYear: () => [string, string] = () => {
      if (reportingPeriod.value) {
        return [
          dayjs(reportingPeriod.value.from).toISOString(),
          dayjs(reportingPeriod.value.to).toISOString(),
        ];
      }

      const dateFrom = today.set('date', 1).subtract(1, 'year');
      const dateTo = dateFrom.add(1, 'year').subtract(1, 'day');

      return [dateFrom.toISOString(), dateTo.toISOString()];
    };

    return [
      {
        label: 'Last reporting month',
        range: getLastMonth(),
      },
      {
        label: 'Last reporting quarter',
        range: getLastQuarter(),
      },
      {
        label: 'Last reporting year',
        range: getLastYear(),
      },
    ];
  },
);

function selectRange(range: [string, string]) {
  internalValue.value = [range[0], range[1]];
  handleApply();
}

function handleApply() {
  const value = internalValue.value as [string, string];
  const dateFrom = dayjs
    .utc(value[0])
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
  const dateTo = dayjs.utc(value[1]).hour(0).minute(0).second(0).millisecond(0);
  const newValue = {
    from: dateFrom,
    to: dateTo,
  };

  emit('update:modelValue', newValue);

  if (props.secondaryModel) {
    emit('update:secondaryModel', newValue);
  }
}
</script>

<template>
  <div class="flex flex-wrap gap-2 overflow-hidden">
    <div class="mr-5 mt-2 flex flex-col items-start gap-2">
      <AtButton
        v-for="presetRange in presetRanges"
        :key="presetRange.label"
        type="button"
        variant="text"
        @click="selectRange(presetRange.range)"
      >
        {{ t(presetRange.label) }}
      </AtButton>
    </div>
    <MlDatePicker
      v-model="internalValue"
      class="dateRangePicker"
      menuClassName="!border-none mt-4"
      keepActionRow
      noToday
      range
      :inline="props.inline && { input: true }"
      :multiCalendars="{ solo: true }"
      :partialRange="false"
      :showLastInRange="false"
      @apply="handleApply"
    />
  </div>
</template>
