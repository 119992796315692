import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  RequestSupportMutation,
  RequestSupportMutationVariables,
} from '@/__generated__/types';

export const REQUEST_SUPPORT = gql`
  mutation RequestSupport($requestSupportInput: RequestSupportInput!) {
    requestSupport(input: $requestSupportInput) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  RequestSupportMutation,
  RequestSupportMutationVariables
>;

const useRequestSupportMutation = (options?: TOptions) =>
  useMutation<RequestSupportMutation, RequestSupportMutationVariables>(
    REQUEST_SUPPORT,
    options,
  );

export default useRequestSupportMutation;
