<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { getDisplayedFrameworks } from '@/utils/helpers/getDisplayedFrameworks';
import {
  type DataPointTypeReportingFramework,
  ReportingFrameworkEnum,
} from '@/__generated__/types';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';

type RequiredData = Pick<
  DataPointTypeReportingFramework,
  'framework' | 'groups'
>;

const props = defineProps<{
  activeReportingFramework: RequiredData;
  reportingFrameworks: RequiredData[];
}>();

const { t } = useI18n();

const frameworks = computed(() =>
  getDisplayedFrameworks(
    props.activeReportingFramework,
    props.reportingFrameworks,
  ),
);
</script>

<template>
  <AtBadge
    v-for="framework in frameworks"
    :key="framework.framework"
    class="mr-2 py-0 text-xs font-light"
    type="neutral"
  >
    <span
      v-if="
        framework.framework &&
        framework.framework !== ReportingFrameworkEnum.DERIVED
      "
      >{{ t(framework.framework) }}</span
    >

    <div v-if="framework.framework !== ReportingFrameworkEnum.CUSTOM">
      <template
        v-if="
          framework.groups?.length &&
          framework.framework !== ReportingFrameworkEnum.DERIVED
        "
      >
        -
      </template>
      <template v-for="(group, i) in framework.groups">
        {{ group }}
        <template v-if="i + 1 < (framework.groups?.length ?? 0)"> ; </template>
      </template>
    </div>
  </AtBadge>
</template>
