import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import type { PartialDeep } from 'type-fest';
import type { DataPoint } from '@/__generated__/types';
import { getDPRDateString } from '@/utils/helpers/dprDates';

export default (
  dataPoints: PartialDeep<
    Pick<DataPoint, 'from' | 'to' | 'value' | 'location'>
  >[],
) => {
  const totalsGrouped = groupBy(
    dataPoints,
    (_dp: (typeof dataPoints)[number]) => getDPRDateString(_dp.to),
  );

  const fillTotalsGaps = (
    groupedDps: typeof totalsGrouped,
  ): typeof totalsGrouped => {
    let previousDataPoints: (typeof totalsGrouped)[0] = [];

    const filledTotals = Object.entries(groupedDps).reduce(
      (acc, [key, dataPoint]) => {
        const missingLocationDps = previousDataPoints.filter(
          (prevDp) =>
            !dataPoint.some((dp) => prevDp.location?._id === dp.location?._id),
        );
        previousDataPoints = [...dataPoint, ...missingLocationDps];

        return { ...acc, [key]: [...dataPoint, ...missingLocationDps] };
      },
      {},
    );

    return filledTotals;
  };

  return Object.values(fillTotalsGaps(totalsGrouped)).map((_totalsGrouped) => ({
    from: _totalsGrouped[0].from,
    to: _totalsGrouped[0].to,
    value: sumBy(_totalsGrouped, (_r) => parseFloat(_r.value)),
    location: { ..._totalsGrouped[0].location },
    isTotal: true,
  })) as typeof dataPoints;
};
