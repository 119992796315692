import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdateEntityAssignmentMutation,
  UpdateEntityAssignmentMutationVariables,
} from '@/__generated__/types';

const UPDATE_ENTITY_ASSIGNMENT_MUTATION = gql`
  mutation UpdateEntityAssignment(
    $locationId: String!
    $updateEntityAssignmentInput: [UpdateEntityAssignmentInput!]!
  ) {
    updateEntityAssignment(
      locationId: $locationId
      updateEntityAssignmentInput: $updateEntityAssignmentInput
    ) {
      _id
      assignments {
        _id
        categories {
          _id
          category
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  UpdateEntityAssignmentMutation,
  UpdateEntityAssignmentMutationVariables
>;

const useUpdateEntityAssignmentMutation = (options?: TOptions) =>
  useMutation<
    UpdateEntityAssignmentMutation,
    UpdateEntityAssignmentMutationVariables
  >(UPDATE_ENTITY_ASSIGNMENT_MUTATION, options);

export default useUpdateEntityAssignmentMutation;
