<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { ExclamationIcon } from '@heroicons/vue/solid';
import VOtpInput from 'vue3-otp-input';
import { notify } from '@kyvg/vue3-notification';
import { auth0 } from '@/api/auth/auth';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const { t } = useI18n();
const route = useRoute();

const email = route.query.email as string;
const code = ref('');
const errorMessage = ref('');
const otpInput = ref(null);
const isLoading = ref(false);

const handleOnComplete = (value: string) => {
  code.value = value;
};
const handleOnChange = (value: string) => {
  code.value = value;
};

const next = (e: Event) => {
  if (!(e.target as HTMLFormElement).reportValidity()) {
    return;
  }

  isLoading.value = true;

  auth0.passwordlessLogin(
    {
      email,
      verificationCode: code.value,
      connection: 'email',
      responseType: 'token',
      redirectUri: `${document.location.origin}/loginCallback`,
    },
    (err) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        if (err.description) {
          errorMessage.value = err.description;
        }
      }

      isLoading.value = false;
    },
  );

  return false;
};

const resendCode = () => {
  auth0.passwordlessStart(
    {
      connection: 'email',
      send: 'code',
      email,
      authParams: {
        redirectUri: `${document.location.origin}/loginCallback`,
      },
    },
    (err) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return;
      }

      notify({ type: 'success', text: t('The code has been sent again.') });
      errorMessage.value = '';
    },
  );
};
</script>

<template>
  <div id="box-3" class="box mt-8">
    <div>
      <form @submit.prevent="next">
        <div v-if="errorMessage" class="rounded-md bg-red-50 p-4">
          <div class="flex">
            <div class="shrink-0">
              <ExclamationIcon
                class="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div class="ml-3">
              <div class="text-sm text-red-700">
                <p>{{ t(errorMessage) }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <p>
            <b>{{ t('Please check your email inbox.') }}</b>
          </p>

          <p>
            {{
              t(
                "We've sent a code to {email}. It might take a few seconds until you receive it.",
                { email },
              )
            }}
          </p>

          <div id="code-box" class="my-7 space-y-1">
            <label
              for="password"
              class="mb-5 block text-sm font-medium text-gray-700"
              >{{ t('Login code (from your email)') }}</label
            >
            <!--              <input-->
            <!--                id="login-code"-->
            <!--                v-model="code"-->
            <!--                name="login-code"-->
            <!--                type="text"-->
            <!--                maxlength="6"-->
            <!--                autocomplete="off"-->
            <!--                class="block py-2 px-3 w-full placeholder:text-gray-400 rounded-md border border-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 shadow-sm appearance-none sm:text-sm"-->
            <!--              >-->
            <div style="display: flex; flex-direction: row">
              <v-otp-input
                ref="otpInput"
                inputClasses="otp-input"
                separator=""
                :numInputs="6"
                :shouldAutoFocus="true"
                :isInputNum="true"
                @onComplete="handleOnComplete"
                @onChange="handleOnChange"
              />
            </div>
          </div>
        </div>

        <p class="mt-3 text-center font-medium text-gray-400">
          {{ t("Didn't get a code?") }}
          <a href="#" class="text-blue-600" @click="resendCode">{{
            t('Click to resend.')
          }}</a>
        </p>

        <div class="mt-8">
          <AtButton
            :disabled="code.length !== 6"
            :loading="isLoading"
            class="w-full"
          >
            {{ t('Sign in') }}
          </AtButton>
        </div>
      </form>
    </div>
  </div>
</template>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
