<script setup lang="ts">
import sanitizeHtml from 'sanitize-html';

import { computed } from 'vue';

interface TProps {
  html: string;
}

const props = defineProps<TProps>();

const options: sanitizeHtml.IOptions = {
  allowedTags: [
    'p',
    'h1',
    'h2',
    'ul',
    'ol',
    'li',
    'strong',
    'u',
    'i',
    's',
    'hr',
    'br',
    'span',
  ],
  allowedAttributes: { span: ['class'] },
};

const sanitizedHtml = computed(() => {
  return sanitizeHtml(props.html, options);
});
</script>

<template>
  <div class="html-content" v-html="sanitizedHtml" />
</template>

<style>
.html-content ul,
.html-content ol {
  margin-left: 25px;
}

.html-content ul {
  @apply list-disc;
}

.html-content ol {
  @apply list-decimal;
}

.html-content h1 {
  @apply text-xl font-normal leading-7 text-gray-900;
}

.html-content h2 {
  @apply text-sm font-medium text-gray-900;
}

.mention {
  color: #2563eb;
  font-size: 0.8rem;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
</style>
