import { gql } from '@apollo/client/core';

export default gql`
  query TmDashboardShareModal {
    getCurrentEntity {
      _id

      entitySettings {
        _id
        shareToken
        sharePassword
      }
    }
  }
`;
