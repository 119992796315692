import { gql } from '@apollo/client/core';

export default gql`
  query PgOnboarding {
    getOwnUser {
      _id
      entity {
        _id
        reportingFrameworks

        entitySettings {
          _id
          companyName
          industry
          locationOfHeadquarter
          nameOfHeadquarter
          numberOfEmployees
        }
      }
    }
  }
`;
