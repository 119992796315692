<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import OgImageS3FilePicker from '@/components/molecules/MlFilePicker/OgImageS3FilePicker.vue';
import useGenerateFileDownloadTokenMutation from '@/api/mutations/RepositoryFile/generateFileDownloadToken.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtAvatar from '@/components/atoms/AtAvatar.vue';

const { t } = useI18n();

interface TProps {
  pictureUrl: string;
  userName: string;
}

const props = defineProps<TProps>();
const isUploadAreaOpen = ref(false);

const { mutate: generateFileDownloadURLMutation } =
  useGenerateFileDownloadTokenMutation();

const emit = defineEmits(['pictureUploaded', 'pictureRemoved']);

const removePicture = () => {
  emit('pictureRemoved', null);
};

async function emitPictureIdAndUrl(id?: string | null) {
  if (!id) {
    emit('pictureRemoved', null);
    return;
  }

  const newPictureUrlResult = await generateFileDownloadURLMutation({
    repositoryFileId: id,
  });
  const url = newPictureUrlResult?.data?.generateFileDownloadToken;

  emit('pictureUploaded', { id, url });
}
</script>
<template>
  <div class="grid grid-cols-3">
    <div class="col-span-1 flex items-center">
      <AtAvatar
        class="!h-20 !w-20 cursor-pointer text-3xl"
        role="button"
        :url="props.pictureUrl"
        :userName="props.userName"
        @click.stop="isUploadAreaOpen = !isUploadAreaOpen"
      />
      <div class="px-4">
        <div class="block">
          <AtButton
            class="mb-2 w-36"
            @click.stop="isUploadAreaOpen = !isUploadAreaOpen"
          >
            {{ t('Upload Picture') }}
          </AtButton>
        </div>
        <div class="block">
          <AtButton variant="outline" @click.stop="removePicture">
            {{ t('Remove Picture') }}
          </AtButton>
        </div>
      </div>
    </div>
    <OgImageS3FilePicker
      v-show="isUploadAreaOpen"
      class="col-span-2"
      @update:modelValue="emitPictureIdAndUrl($event?._id)"
    />
  </div>
</template>
