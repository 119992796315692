import { i18n } from '@/lib/i18n';
import useFormatNumber from '@/utils/composables/useFormatNumber';

const { t } = i18n.global;
const { formatNumber } = useFormatNumber();

type TFormularDependency = {
  name: string;
  friendlyName: string;
};

export const translateFormular = (
  formular: string,
  formularDependencies: TFormularDependency[],
) => {
  let translatedFormular = formular;

  // the sorting is needed to make sure we don't break a formular with similar datapoint names (otherwise we might replace half of the datapointname)
  for (const formularDependency of formularDependencies.sort(
    (a, b) => b.name.length - a.name.length,
  )) {
    translatedFormular = translatedFormular.replace(
      formularDependency.name,
      t(formularDependency.friendlyName),
    );
  }

  return translatedFormular;
};

export const replaceFormularVariables = (
  formular: string,
  variables: Record<string, string>,
) => {
  let translatedFormular = formular;

  // the sorting is needed to make sure we don't break a formular with similar datapoint names (otherwise we might replace half of the datapointname)
  for (const [variable, result] of Object.entries(variables).sort(
    ([aVariable], [bVariable]) => bVariable.length - aVariable.length,
  )) {
    translatedFormular = translatedFormular.replace(
      variable,
      String(formatNumber(result)) ?? variable,
    );
  }

  return translatedFormular;
};
