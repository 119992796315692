import type {
  PgGoalsOverviewQuery,
  OgSelectTargetStepQuery,
  ValueDataTypeEnum,
  ReportingFrameworkEnum,
  DataPointTypeRefreshIntervalEnum,
  DataPointTypeValueUnitEnum,
  DataPointTypeEmissionFactorInput,
} from '@/__generated__/types';

export type Crud = 'create' | 'update' | 'read' | 'delete';

export type TGoalPartial = PgGoalsOverviewQuery['getGoals'][number];
export type TTargetPartial = OgSelectTargetStepQuery['getTargets'][number];
export type TKPIPartial = PgGoalsOverviewQuery['getEntityKPIs'][number];
export type TDataPointTypePartial =
  TTargetPartial['suggestedDataPointTypes'][number];

export type CustomDataPointTypeFormData = {
  title: string;
  question: string;
  questionHelp?: string;
  framework?:
    | CreateDatapointTypeReportingFrameworkEnum
    | ReportingFrameworkEnum
    | string;
  frameworkGroup?: string;
  emissionFactors: DataPointTypeEmissionFactorInput[];
  emissionFactorSubcategory: string;
  valueUnit?: DataPointTypeValueUnitEnum;
  allowUploadProof: boolean;
  isGlobal: boolean;
  valueDataType: ValueDataTypeEnum;
  valueDataTypeOptions: string[];
  refreshInterval: DataPointTypeRefreshIntervalEnum;
};

export enum CreateDatapointTypeReportingFrameworkEnum {
  CERTIFICATION_DISCLOSURE = 'CERTIFICATION_DISCLOSURE',
}

export enum NumericUnitEnum {
  NONE = 'NONE',
  T = 'T',
  HOURS = 'HOURS',
  L = 'L',
  KM = 'KM',
  M3 = 'M3',
  KG = 'KG',
  TR = 'TR',
  KW = 'KW',
  KWH = 'KWH',
  EUR = 'EUR',
  PRODUCTION_UNIT = 'PRODUCTION_UNIT',
}
