<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import MinusIcon from '@heroicons/vue/solid/MinusIcon';
import PlusIcon from '@heroicons/vue/solid/PlusIcon';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import OgPdfExcelS3FilePicker from '@/components/molecules/MlFilePicker/OgPdfExcelS3FilePicker.vue';

export type UploadedDocument = TPartialRepositoryFile | null;

const props = defineProps<{
  userId: string;
}>();

const file = defineModel<UploadedDocument>('file', { required: true });

const { t } = useI18n();

const isFilePickerShown = ref(!!file.value);
watch(
  [file, isFilePickerShown],
  ([newFileValue, newIsFIlePickerShownValue]) => {
    if (newFileValue && !newIsFIlePickerShownValue) {
      isFilePickerShown.value = true;
    }
  },
);

const dprDelegationInput = computed(() => ({
  userId: props.userId,
  entityId: null,
}));
</script>

<template>
  <div>
    <button
      type="button"
      aria-label="add document"
      class="flex text-gray-400 text-xs hover:text-primary"
      @click.prevent="isFilePickerShown = !isFilePickerShown"
    >
      <MinusIcon v-if="isFilePickerShown" class="h-4 mr-2" />
      <PlusIcon v-else class="h-4 mr-2" />
      {{ t('Add document') }}
    </button>
    <OgPdfExcelS3FilePicker
      v-if="isFilePickerShown"
      v-model="file"
      class="row-span-2 max-w-xs"
      :placeholder="t('Select or drop proof document')"
      showRecentFiles
      :dprDelegationInput="dprDelegationInput"
      compact
    />
  </div>
</template>
