import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UploadedFileForReviewMutation,
  UploadedFileForReviewMutationVariables,
} from '@/__generated__/types';

export const UPLOADED_FILE_FOR_REVIEW = gql`
  mutation UploadedFileForReview(
    $repositoryFileId: String!
    $locationId: String!
  ) {
    uploadedFileForReview(fileId: $repositoryFileId, locationId: $locationId)
  }
`;

type TOptions = UseMutationOptions<
  UploadedFileForReviewMutation,
  UploadedFileForReviewMutationVariables
>;

const useUploadedFileForReview = (options?: TOptions) =>
  useMutation<
    UploadedFileForReviewMutation,
    UploadedFileForReviewMutationVariables
  >(UPLOADED_FILE_FOR_REVIEW, options);

export default useUploadedFileForReview;
