import { gql } from '@apollo/client/core';

export default gql`
  query PgTaxonomyAssessment($id: String!) {
    getTaxonomyAssessment(_id: $id) {
      _id
      status

      project {
        _id
        name
      }

      turnover
      turnoverCompleteLater
      capex
      capexCompleteLater
      opex
      opexCompleteLater

      sectors {
        _id
        name
      }

      documents {
        _id
        file {
          _id
          filename
          downloadUrl
          filesize
        }
      }

      businessActivities {
        _id

        activityTurnover
        activityCapex
        activityOpex

        objective
        isObjectiveAnswered
        objectiveDocument {
          _id
          file {
            _id
            filename
          }
        }
        objectiveComment

        activityFit

        minimumStandardsRespected
        minimumStandardsRespectedDocument {
          _id
          file {
            _id
            filename
          }
        }
        minimumStandardsRespectedComment
        minimumStandardsDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
        }

        activity {
          _id
          number
          name

          descriptions {
            _id
            key
            value
            objective
          }
        }

        doesNotHarm {
          _id
          circular
          circularDocument {
            _id
            file {
              _id
              filename
            }
          }
          circularComment
          circularDelegation {
            _id
            user {
              _id
              firstName
              lastName
              email
            }
            shareToken
            objective
          }

          adaptation
          adaptationDocument {
            _id
            file {
              _id
              filename
            }
          }
          adaptationComment
          adaptationDelegation {
            _id
            user {
              _id
              firstName
              lastName
              email
            }
            shareToken
            objective
          }

          mitigation
          mitigationDocument {
            _id
            file {
              _id
              filename
            }
          }
          mitigationComment
          mitigationDelegation {
            _id
            user {
              _id
              firstName
              lastName
              email
            }
            shareToken
            objective
          }

          pollution
          pollutionDocument {
            _id
            file {
              _id
              filename
            }
          }
          pollutionComment
          pollutionDelegation {
            _id
            user {
              _id
              firstName
              lastName
              email
            }
            shareToken
            objective
          }

          biodiversity
          biodiversityDocument {
            _id
            file {
              _id
              filename
            }
          }
          biodiversityComment
          biodiversityDelegation {
            _id
            user {
              _id
              firstName
              lastName
              email
            }
            shareToken
            objective
          }

          water
          waterDocument {
            _id
            file {
              _id
              filename
            }
          }
          waterComment
          waterDelegation {
            _id
            user {
              _id
              firstName
              lastName
              email
            }
            shareToken
            objective
          }
        }

        substantialContributionDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }
      }
    }

    getOwnUser {
      _id
      entity {
        _id
        organization {
          _id
          taxonomyCoreData {
            _id
            minimumSafeguards
          }
        }
      }
    }
  }
`;
