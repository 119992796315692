<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { CheckIcon } from '@heroicons/vue/outline';
import {
  CustomQuestionnaireStatus,
  type OgProjectsQuery,
} from '@/__generated__/types';
import type { CustomQuestionnaire } from '../../types';
import OG_PROJECTS_QUERY from '../../OgProjects.query';
import MlProjectsBreadcrumbs from '../../common/MlProjectsBreadcrumbs.vue';

type Step = {
  id: 'upload' | 'check' | 'edit' | 'share';
  order: 1 | 2 | 3 | 4;
  title: string;
  isActive: boolean;
  isDone: boolean;
};

type Props = {
  customQuestionnaire: CustomQuestionnaire | null;
};

const props = defineProps<Props>();

const { t } = useI18n();
const route = useRoute();

const { result } = useQuery<OgProjectsQuery>(OG_PROJECTS_QUERY);

const allProjects = computed(() => result.value?.entityLocationSummaries || []);

const steps = computed<Step[]>(() => [
  {
    id: 'upload',
    order: 1,
    title: t('Upload file'),
    isActive: route.name === 'projectsCustomQuestionnaireUpload',
    isDone: props.customQuestionnaire
      ? [
          CustomQuestionnaireStatus.CHECK_MAPPING,
          CustomQuestionnaireStatus.EDIT_QUESTIONS,
          CustomQuestionnaireStatus.SHARE,
          CustomQuestionnaireStatus.DONE,
        ].includes(props.customQuestionnaire.status)
      : false,
  },
  // Check mapping page is temporarily disabled.
  // {
  //   id: 'check',
  //   order: 2,
  //   title: t('Check mapping'),
  //   isActive: route.name === 'projectsCustomQuestionnaireCheckMapping',
  //   isDone: props.customQuestionnaire
  //     ? [
  //       CustomQuestionnaireStatus.EDIT_QUESTIONS,
  //       CustomQuestionnaireStatus.SHARE,
  //       CustomQuestionnaireStatus.DONE,
  //     ].includes(props.customQuestionnaire.status)
  //     : false,
  // },
  {
    id: 'edit',
    // Check mapping page is temporarily disabled.
    // order: 3,
    order: 2,
    title: t('Edit questionnaire'),
    isActive: route.name === 'projectsCustomQuestionnaireEditQuestionnaire',
    isDone: props.customQuestionnaire
      ? [
          CustomQuestionnaireStatus.SHARE,
          CustomQuestionnaireStatus.DONE,
        ].includes(props.customQuestionnaire.status)
      : false,
  },
  {
    id: 'share',
    // Check mapping page is temporarily disabled.
    // order: 4,
    order: 3,
    title: t('Share'),
    isActive: route.name === 'projectsCustomQuestionnaireShare',
    isDone: props.customQuestionnaire
      ? [CustomQuestionnaireStatus.DONE].includes(
          props.customQuestionnaire.status,
        )
      : false,
  },
]);
</script>

<template>
  <div
    class="flex items-center justify-between pb-3.5 border-b border-b-gray-200"
  >
    <div class="flex-1 flex items-center gap-6">
      <MlProjectsBreadcrumbs
        class="pb-2 ml-6"
        :activeProjectId="
          customQuestionnaire?.initiallyCreatedForEntityLocation._id || null
        "
        :allProjects="allProjects"
        :category="customQuestionnaire?.category?.slug"
        :subcategory="customQuestionnaire?.subcategory?.slug"
      />
    </div>

    <div class="flex-0">
      <ol class="flex flex-wrap">
        <li
          v-for="step in steps"
          :key="step.id"
          class="flex flex-col items-center w-36 relative px-1"
        >
          <div
            class="flex items-center justify-center w-6 h-6 rounded-full border-2 text-sm font-bold before:content-[''] before:absolute before:h-px before:bg-gray-200 before:left-0 before:right-[calc(50%+12px)] after:content-[''] after:absolute after:h-px after:bg-gray-200 after:right-0 after:left-[calc(50%+12px)]"
            :class="{
              'text-blue-600 border-blue-600': step.isActive && !step.isDone,
              'text-teal-600 border-teal-600': step.isDone,
              'text-gray-200 border-gray-200': !step.isActive && !step.isDone,
            }"
          >
            <template v-if="step.isDone">
              <CheckIcon class="w-4 h-4" />
            </template>
            <template v-else>
              {{ step.order }}
            </template>
          </div>
          <h3 class="text-sm">
            {{ step.title }}
          </h3>
        </li>
      </ol>
    </div>
  </div>
</template>
