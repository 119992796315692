import { gql } from '@apollo/client/core';

export default gql`
  query OgProjectSidebar($projectId: String!) {
    getCategoriesWithSubcategoriesForUser(locationId: $projectId) {
      _id
      slug
      framework
      organization {
        _id
      }
      location {
        _id
      }
      entity {
        _id
      }
      createdAt
      createdBy {
        _id
        firstName
        lastName
      }
      subcategories {
        _id
        slug
        orderPosition
        topic
      }
    }

    getUserAssignedDataPointRequestsSubcategories(locationId: $projectId) {
      subcategory
      location {
        _id
      }
    }

    entityLocationSummaries {
      _id
      name
    }
    getDelegatedSubcategories {
      subcategory
      role
      location {
        _id
      }
    }

    entityLocationSummary(entityLocationId: $projectId) {
      _id
      name
      isHeadquarters
      progress
      hasParentInfo
      hasChildren
      depth
      assignees {
        _id
        firstName
        lastName
        email
        assignments {
          _id
          subcategories {
            role
            subcategory
          }
        }
        picture {
          _id
          downloadUrl
        }
      }
      suspensions {
        datapointtypes {
          _id
          name
          friendlyName
          question
          questionHelp

          activeReportingFramework {
            _id
            framework
            groups
            subcategory
          }
        }
      }
      assignments {
        _id
        categories {
          _id
          category
          isEnabled
          subcategories {
            _id
            subcategory
          }
        }
      }
    }
  }
`;
