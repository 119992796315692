import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  GenerateFileDownloadTokenMutation,
  GenerateFileDownloadTokenMutationVariables,
} from '@/__generated__/types';

export const GENERATE_FILE_DOWNLOAD_TOKEN = gql`
  mutation GenerateFileDownloadToken(
    $repositoryFileId: String!
    $repositoryFileType: String
  ) {
    generateFileDownloadToken(
      repositoryFileId: $repositoryFileId
      repositoryFileType: $repositoryFileType
    )
  }
`;

type TOptions = UseMutationOptions<
  GenerateFileDownloadTokenMutation,
  GenerateFileDownloadTokenMutationVariables
>;

const useGenerateFileDownloadTokenMutation = (options?: TOptions) =>
  useMutation<
    GenerateFileDownloadTokenMutation,
    GenerateFileDownloadTokenMutationVariables
  >(GENERATE_FILE_DOWNLOAD_TOKEN, options);

export default useGenerateFileDownloadTokenMutation;
