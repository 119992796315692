import { gql } from '@apollo/client/core';

export default gql`
  query TmChartSelection(
    $dataPointTypeNames: [String!]!
    $allEntities: Boolean
    $isAdmin: Boolean!
  ) {
    getAvailableDataPointsGroupedByType {
      _id
      dataPointType {
        _id
        name
        friendlyName
        valueUnit
        valueUnitDivisor
        valueDataType
        summarizingMethod
        reportingFrameworks {
          _id
          framework
          groups
          category
          subcategory
        }
      }
    }

    getEsgDataPointsLength

    getDataPointsByType(dataPointTypeNames: $dataPointTypeNames)
      @skip(if: $isAdmin) {
      _id
      value
      from
      to
      entity {
        _id
        name
      }
      location {
        _id
        name
      }
      dataPointType {
        _id
        friendlyName
        valueUnit
        valueUnitDivisor
        valueDataType
        summarizingMethod
        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
        }
        reportingFrameworks {
          _id
          framework
          groups
        }
      }
    }

    getAdminDataPointsByType(
      dataPointTypeNames: $dataPointTypeNames
      allEntities: $allEntities
    ) @include(if: $isAdmin) {
      _id
      value
      from
      to
      entity {
        _id
        name
      }
      location {
        _id
        name
      }
      dataPointType {
        _id
        name
        friendlyName
        valueUnit
        valueUnitDivisor
        valueDataType
        summarizingMethod
        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
        }
        reportingFrameworks {
          _id
          framework
          groups
        }
      }
    }

    getCurrentUserEntities {
      _id
      name
      entitySettings {
        _id
        companyName
        dashboardPinnedDataPointTypes {
          _id
          dataPointType {
            friendlyName
            name
            _id
          }
        }
      }
      kpis {
        _id
        startingDate
        comparisonValue
        comparisonValueType
        comparisonOperator
        dueDate
        dataPointType {
          _id
        }
      }
      locations {
        _id
        name
      }
    }

    getOwnUser {
      _id
      pinnedDataPointTypes {
        _id
        entity {
          _id
        }
        dataPointType {
          _id
          name
          friendlyName
        }
      }
      entity {
        _id
        kpis {
          _id
          startingDate
          comparisonValue
          comparisonValueType
          comparisonOperator
          dueDate
          dataPointType {
            _id
          }
        }
      }
    }
  }
`;

export const REPORT_QUERY = gql`
  query DetailedReport(
    $dataPointTypeNames: [String!]
    $dateFrom: String
    $dateTo: String
  ) {
    getDataPointsEncodedReport(
      dataPointTypeNames: $dataPointTypeNames
      dateFrom: $dateFrom
      dateTo: $dateTo
    )
  }
`;
