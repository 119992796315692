import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import type {
  UsePinChartQuery,
  UsePinChartQueryVariables,
} from '@/__generated__/types';
import { getActiveEntity } from '@/utils/services/activeEntity';
import useUpdatePinnedDataPointTypesMutation from '@/api/mutations/User/updatePinnedDataPointTypes.mutation';
import USE_PIN_CHART_QUERY from './usePinChart.query';

const usePinChart = () => {
  const { result, refetch } = useQuery<
    UsePinChartQuery,
    UsePinChartQueryVariables
  >(USE_PIN_CHART_QUERY);
  const { mutate: updatePinnedDataPointTypes, loading } =
    useUpdatePinnedDataPointTypesMutation({
      update: (store) => {
        store.evict({ id: 'ROOT_QUERY', fieldName: 'getOwnUser' });
      },
    });

  const pinnedDataPointTypes = computed(
    () =>
      result.value?.getOwnUser.pinnedDataPointTypes.map(
        ({ entity, dataPointType }) => ({
          entity: entity?._id,
          dataPointType: dataPointType._id,
        }),
      ) ?? [],
  );
  const pinnedChartIDs = computed(
    () =>
      pinnedDataPointTypes.value
        ?.filter(({ entity }) => entity === getActiveEntity())
        .map(({ dataPointType }) => dataPointType) ?? [],
  );

  const pinChart = (dataPointTypeId: string) =>
    updatePinnedDataPointTypes({
      updateUserInput: {
        _id: result.value?.getOwnUser._id,
        pinnedDataPointTypes: [
          ...pinnedDataPointTypes.value,
          {
            entity: getActiveEntity() ?? undefined,
            dataPointType: dataPointTypeId,
          },
        ],
      },
    });

  const unpinChart = (dataPointTypeId: string) =>
    updatePinnedDataPointTypes({
      updateUserInput: {
        _id: result.value?.getOwnUser._id,
        pinnedDataPointTypes: pinnedDataPointTypes.value.filter(
          ({ dataPointType }) => dataPointType !== dataPointTypeId,
        ),
      },
    });

  const togglePinChart = (dataPointTypeId: string) => {
    pinnedChartIDs.value.includes(dataPointTypeId)
      ? unpinChart(dataPointTypeId)
      : pinChart(dataPointTypeId);
    refetch();
  };

  return { pinnedChartIDs, pinChart, unpinChart, togglePinChart, loading };
};

export default usePinChart;
