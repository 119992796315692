import {
  breakpointsTailwind,
  useBreakpoints as useBreakpointsCore,
} from '@vueuse/core';

const useBreakpoints = () => {
  const breakpoints = useBreakpointsCore(breakpointsTailwind);

  const sm = breakpoints.smaller('sm');
  const md = breakpoints.smaller('md');
  const lg = breakpoints.smaller('lg');
  const xl = breakpoints.smaller('xl');
  const xxl = breakpoints.smaller('2xl');

  return { sm, md, lg, xl, xxl };
};

export default useBreakpoints;
