<script setup lang="ts">
import { ref, watch, computed, inject } from 'vue';
import 'vue3-easy-data-table/dist/style.css';
import { useI18n } from 'vue-i18n';
import get from 'lodash/get';
import { CursorClickIcon } from '@heroicons/vue/outline';
import sortBy from 'lodash/sortBy';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { UserRole } from '@/__generated__/types';
import type { TButtons } from '../types';
import MlFilterMenu from './MlFilterMenu.vue';
import MlSortMenu from './MlSortMenu.vue';
import type {
  TDataTableFilter,
  TDataTableHeader,
  TDataTableItem,
  TDataTableSort,
} from './types';

const { t } = useI18n();

const props = defineProps<{
  headers: TDataTableHeader[];
  items: TDataTableItem[];
  hover?: boolean;
  controlsHidden?: boolean;
  modelValue: TDataTableItem[];
  customFilters?: TDataTableFilter[];
  filterHidden?: boolean;
  sortHidden?: boolean;
  showMultiSelect?: boolean;
  checkedItems?: TDataTableItem[];
  buttons?: TButtons<TDataTableItem[]>;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', modifiedItems: TDataTableItem[]): void;
  (e: 'openCreateDataPointTypeModal'): void;
  (e: 'updateCheckedItems', modifiedItems: TDataTableItem[]): void;
}>();

const modifiedItems = ref(props.modelValue);
watch(
  () => props.modelValue,
  () => {
    modifiedItems.value = props.modelValue;
  },
);

const dataEntrySelectMode = inject('dataEntrySelectMode', ref(false));

const sort = ref<TDataTableSort>({ by: '', type: '' });
const sortItems = (items: TDataTableItem[]) => {
  if (props.sortHidden || !sort.value.type) return items;

  const sortedItems = sortBy(items, sort.value.by);

  return sort.value.type === 'asc' ? sortedItems : sortedItems.reverse();
};

const filters = ref<TDataTableFilter[]>([]);
const filterItems = (items: TDataTableItem[]) => {
  if (props.filterHidden || !filters.value.length) return items;

  return items.filter((item) =>
    filters.value.every((filter) => {
      if (!filter.criteria.length) return true;

      const applyComparison = filter.comparison
        ? () => filter.comparison?.(get(item, filter.field), filter.criteria)
        : (criteriaValue: string) => criteriaValue === get(item, filter.field);

      return filter.criteria.some(applyComparison);
    }),
  );
};

watch(
  [sort, filters],
  () => {
    emit('update:modelValue', sortItems(filterItems(props.items)));
  },
  { deep: true },
);

watch(
  () => props.items,
  () => {
    emit('update:modelValue', sortItems(filterItems(props.items)));
  },
);

const numberOfCheckedItems = computed(() => props?.checkedItems?.length ?? 0);
const checkedItems = computed(() => props?.checkedItems ?? []);

watch(
  () => props.checkedItems,
  () => {
    emit('updateCheckedItems', checkedItems.value);
  },
  { deep: true },
);

const numberOfCheckedItemsText = computed(
  () =>
    `${numberOfCheckedItems.value} ${numberOfCheckedItems.value === 1 ? t('item selected') : t('items selected')}`,
);
</script>
<template>
  <div class="flex justify-between">
    <div class="flex w-full items-end gap-x-4 ml-4 mb-1">
      <div
        v-if="showMultiSelect"
        v-rolefrom="UserRole.CONTRIBUTOR"
        class="text-sm items-end text-primary cursor-pointer flex font-medium"
        @click="dataEntrySelectMode = !dataEntrySelectMode"
      >
        <CursorClickIcon class="h-4 mr-1 mb-0.5" />
        <span class="text-sm">
          {{ t('Select') }}
        </span>
      </div>
      <MlFilterMenu
        v-if="!props.filterHidden"
        v-model="filters"
        :headers="props.headers"
        :items="props.items"
        :customFilters="props.customFilters"
        :placement="'bottom-end'"
      />
      <MlSortMenu
        v-if="!props.sortHidden"
        v-model="sort"
        :headers="props.headers"
        :items="props.items"
      />
      <div
        v-if="numberOfCheckedItems > 0 || props.buttons"
        class="flex gap-4 ml-auto items-center"
      >
        <p v-if="numberOfCheckedItems > 0" class="text-sm">
          {{ numberOfCheckedItemsText }}
        </p>
        <template v-if="props.buttons?.secondary">
          <AtButton
            v-for="(btn, idx) in Array.isArray(props.buttons?.secondary)
              ? props.buttons?.secondary
              : [props.buttons?.secondary]"
            :key="idx"
            type="submit"
            variant="destructive"
            :disabled="btn.disabled"
            :loading="btn.loading"
            @click="btn.onClick(checkedItems)"
          >
            {{ btn.text }}
          </AtButton>
        </template>
        <template v-if="props.buttons?.primary">
          <AtButton
            v-for="(btn, idx) in Array.isArray(props.buttons?.primary)
              ? props.buttons?.primary
              : [props.buttons?.primary]"
            :key="idx"
            type="submit"
            :disabled="btn.disabled"
            :loading="btn.loading"
            @click="btn.onClick(checkedItems)"
          >
            {{ btn.text }}
          </AtButton>
        </template>
      </div>
    </div>
  </div>
</template>
