import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import type {
  UpsertCustomQuestionnaireQuestionMutation,
  UpsertCustomQuestionnaireQuestionMutationVariables,
} from '@/__generated__/types';

export const UPSERT_CUSTOM_QUESTIONNAIRE_QUESTION = gql`
  mutation UpsertCustomQuestionnaireQuestion(
    $customQuestionnaireId: String!
    $questionData: UpsertCustomQuestionnaireQuestionInput!
  ) {
    upsertCustomQuestionnaireQuestion(
      customQuestionnaireId: $customQuestionnaireId
      questionData: $questionData
    ) {
      _id
    }
  }
`;

type Options = UseMutationOptions<
  UpsertCustomQuestionnaireQuestionMutation,
  UpsertCustomQuestionnaireQuestionMutationVariables
>;

const useUpsertCustomQuestionnaireQuestionMutation = (options?: Options) =>
  useMutation<
    UpsertCustomQuestionnaireQuestionMutation,
    UpsertCustomQuestionnaireQuestionMutationVariables
  >(UPSERT_CUSTOM_QUESTIONNAIRE_QUESTION, options);

export default useUpsertCustomQuestionnaireQuestionMutation;
