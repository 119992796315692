<script setup lang="ts">
import { computed, ref, watch, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import groupBy from 'lodash/groupBy';
import { useI18n } from 'vue-i18n';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import { type OgProjectsQuery } from '@/__generated__/types';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import useCreateEntityLocationMutation from '@/api/mutations/EntityLocation/createEntityLocation.mutation';
import useCreateCustomQuestionnaireMutation from '@/api/mutations/CustomQuestionnaire/createQuestionnaire.mutation';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import OgProjectsRestrictionsModal from '@/components/organisms/OgRestrictionsModal/OgProjectsRestrictionsModal.vue';
import grayBarsImg from '@/assets/grayBars.svg';
import OG_PROJECTS_QUERY from './OgProjects.query';
import OgAddProject, {
  type AddProjectData,
} from './OgAddProject/OgAddProject.vue';
import MlProjectCardSkeleton from './MlProjectCard/MlProjectCardSkeleton.vue';
import MlProjectCard from './MlProjectCard/MlProjectCard.vue';
import OgProjectsHeader from './OgProjectsHeader/OgProjectsHeader.vue';

type Props = {
  projectId?: string;
};

const props = defineProps<Props>();

// General hooks
const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const adminMode = inject(
  'adminMode',
  computed(() => false),
);
const { restrictions, loading: loadingRestrictions } = useRestrictions();

const showRestrictionsDialog = ref(false);
const showAddProjectDialog = ref(false);
// Name of the project being created. Used to show indication of creation.
const creatingProject = ref('');

const { result, loading, onResult } =
  useQuery<OgProjectsQuery>(OG_PROJECTS_QUERY);
// Hide creating project skeleton when projects data is loaded.
onResult(() => {
  creatingProject.value = '';
});
const { mutate: createLocation } = useCreateEntityLocationMutation({
  update: (store) => {
    // Refetch projects list after new project is created.
    store.evict({
      id: 'ROOT_QUERY',
      fieldName: 'entityLocationSummaries',
    });
  },
});
const { mutate: createQuestionnaire } = useCreateCustomQuestionnaireMutation({
  update: (store) => {
    store.evict({
      id: 'ROOT_QUERY',
      fieldName: 'getAllCategoriesForEntity',
    });
  },
});

const delegatedAssignmentsSubcategories = computed(
  () => result.value?.getDelegatedSubcategories ?? [],
);

const allProjects = computed(() => result.value?.entityLocationSummaries ?? []);
const activeProject = computed(
  () => allProjects.value.find((item) => item._id === props.projectId) || null,
);
const projects = computed(() => {
  const projectIds = new Set(allProjects.value.map((item) => item._id));
  const byParent = groupBy(
    allProjects.value,
    (item) => item.parent || 'noParent',
  );

  const projectsWithDelegatedDprs = allProjects.value.filter(
    (project) =>
      !!delegatedAssignmentsSubcategories.value.find(
        (delegatedSubcategory) =>
          delegatedSubcategory.location._id === project._id,
      ),
  );

  if (!props.projectId) {
    if (byParent.noParent) {
      // if parent projects are assigned, show them AND any subproject where the parent is unavailable
      return uniqBy(
        [
          ...flatten(Object.values(byParent)).filter(
            (project) => !project.parent || !projectIds.has(project.parent),
          ),
          ...projectsWithDelegatedDprs,
        ],
        '_id',
      );
    }

    // go through all parent projects, find the ones that are not assigned (the parents). Then return all childs directly, as the parents are not shown
    const parentIds = Object.keys(byParent).filter((item) => {
      if (item === 'noParent') {
        return false;
      }

      return !projectIds.has(item);
    });
    if (parentIds.length) {
      return uniqBy(
        [
          ...flatten(
            Object.entries(byParent)
              .filter(([k]) => parentIds.includes(k))
              .map(([, v]) => v),
          ),
          ...projectsWithDelegatedDprs,
        ],
        '_id',
      );
    }

    return [];
  }

  if (byParent[props.projectId]) {
    return byParent[props.projectId];
  }

  return [];
});
const disableAddProjectButton = computed(
  () => loading.value || !!creatingProject.value || loadingRestrictions.value,
);
const allProjectsNames = computed(() =>
  allProjects.value.map((project) => project.name),
);

watch(
  [activeProject, projects, loading],
  ([newActiveProject, newProjects, newLoading]) => {
    if (!newLoading && newProjects.length === 0) {
      if (!newActiveProject && route.name !== 'projects') {
        router.push({
          name: 'projects',
        });
      } else if (newActiveProject && route.name === 'projectsProject') {
        router.push({
          name: 'projectsProjectDataEntry',
          params: {
            project: props.projectId,
          },
        });
      }
    }
  },
);

const numberOfLocations = computed(
  () => result.value?.getNumberOfLocations ?? 0,
);

function handleAddProjectClick() {
  if (
    restrictions.value &&
    restrictions.value?.numberOfProjects > 0 &&
    numberOfLocations.value >= restrictions.value?.numberOfProjects &&
    projects.value.length > 0
  ) {
    showRestrictionsDialog.value = true;
  } else {
    showAddProjectDialog.value = true;
  }
}

function handleAddProjectCancel() {
  showAddProjectDialog.value = false;
}

async function handleAddProjectSave(data: AddProjectData) {
  try {
    showAddProjectDialog.value = false;
    creatingProject.value = data.name;
    let parent: string | null;
    if (data.parent) {
      parent = data.parent;
    } else if (props.projectId) {
      parent = props.projectId;
    } else {
      parent = null;
    }

    const createdLocation = await createLocation({
      createEntityLocationInput: {
        name: data.name,
        parent,
      },
    });
    if (data.template === 'custom') {
      const createdQuestionnaire = await createQuestionnaire({
        customQuestionnaireData: {
          questionnaireName: data.questionnaireName!,
          category: data.category,
          entityLocationId: createdLocation?.data?.createEntityLocation[0]._id,
        },
      });
      router.push({
        name: 'projectsCustomQuestionnaireUpload',
        params: {
          customQuestionnaireId:
            createdQuestionnaire?.data?.createCustomQuestionnaire._id,
        },
      });
    }
  } catch (error) {
    showAddProjectDialog.value = true;
    throw error;
  } finally {
    creatingProject.value = '';
  }
}
</script>

<template>
  <OgProjectsHeader
    :activeProject="activeProject"
    :allProjects="allProjects"
    :canAdd="disableAddProjectButton"
    @add="handleAddProjectClick"
  />
  <section class="px-6 flex flex-wrap gap-6">
    <MlProjectCard
      v-for="project in projects"
      :key="project._id"
      :project="project"
    />
    <MlProjectCardSkeleton v-if="creatingProject" :title="creatingProject" />
    <MlEmptyStateCard
      v-if="!projects.length && !loading && !creatingProject && !adminMode"
      :title="t('No projects yet')"
      :description="
        t(`It appears that you haven't been assigned to any projects to add data for yet.
      To be allocated to projects and sustainability-related topics, please get in touch with your administrator.`)
      "
      noButton
    >
      <img class="mb-7" :src="grayBarsImg" alt="Graph" />
    </MlEmptyStateCard>
  </section>

  <OgAddProject
    :isOpen="showAddProjectDialog"
    :projects="projects"
    :existingProjectsNames="allProjectsNames"
    @cancel="handleAddProjectCancel"
    @save="handleAddProjectSave"
  />

  <OgProjectsRestrictionsModal
    :showModal="showRestrictionsDialog"
    @closeModal="showRestrictionsDialog = false"
  />
</template>
