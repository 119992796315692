<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import useRequestHelpForExternalTaxonomy from '@/api/mutations/Help/requestHelpForExternalTaxonomy.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';

type FormData = {
  message: string;
  name: string;
  phone: string;
};

const { t } = useI18n();
const route = useRoute();

const email = window.localStorage.getItem('taxonomyAssessmentEmail') ?? '';
const shareToken = route.params.shareToken.toString();

const { loading: isSubmitting, mutate } = useRequestHelpForExternalTaxonomy();

const formData = reactive<FormData>({
  message: '',
  name: '',
  phone: '',
});

const validationRules = computed(() => ({
  message: {
    required: helpers.withMessage(t('Message is required.'), required),
  },
  name: {
    required: helpers.withMessage(t('Name is required.'), required),
  },
  phone: {
    required: helpers.withMessage(t('Phone number is required.'), required),
  },
}));
const v$ = useVuelidate(validationRules, formData);

async function handleSubmit(hide: () => void) {
  try {
    await mutate({
      email,
      shareToken,
      message: formData.message,
      name: formData.name,
      phone: formData.phone,
    });
    hide();
  } catch {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
}
</script>

<template>
  <VTooltip
    :triggers="['click', 'touch']"
    :distance="-20"
    :skidding="250"
    autoHide
  >
    <AtButton variant="text" type="button">
      {{ t('Help') }}
    </AtButton>
    <template #popper="{ hide }">
      <form class="max-w-[256px]" @submit.prevent="handleSubmit(hide)">
        <MlTextarea
          v-model="formData.message"
          wrapperClass="mb-3"
          :editor="false"
          :placeholder="
            t(
              'Enter text of your message here or send an email to support@codioimpact.com',
            )
          "
          :errors="v$.message.$errors"
          @blur="v$.message.$touch"
        />
        <AtInput
          v-model="formData.name"
          wrapperClass="mb-3"
          :placeholder="t('Enter your name')"
          :errors="v$.name.$errors"
          @blur="v$.name.$touch"
        />
        <AtInput
          v-model="formData.phone"
          wrapperClass="mb-5"
          :placeholder="t('Enter your phone number')"
          :errors="v$.phone.$errors"
          @blur="v$.phone.$touch"
        />
        <div class="flex justify-end">
          <AtButton
            type="submit"
            :loading="isSubmitting"
            :disabled="v$.$invalid"
          >
            {{ t('Request help') }}
          </AtButton>
        </div>
      </form>
    </template>
  </VTooltip>
</template>
