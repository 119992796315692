import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  ValidateDoubleMaterialitySurveyParticipantShareTokenMutation,
  ValidateDoubleMaterialitySurveyParticipantShareTokenMutationVariables,
} from '@/__generated__/types';

export const VALIDATE_DOUBLE_MATERIALITY_SURVEY_PARTICIPANT_SHARE_TOKEN = gql`
  mutation ValidateDoubleMaterialitySurveyParticipantShareToken(
    $email: String!
    $shareToken: String!
  ) {
    validateDoubleMaterialitySurveyParticipantShareToken(
      email: $email
      shareToken: $shareToken
    )
  }
`;

type TOptions = UseMutationOptions<
  ValidateDoubleMaterialitySurveyParticipantShareTokenMutation,
  ValidateDoubleMaterialitySurveyParticipantShareTokenMutationVariables
>;

const useValidateDoubleMaterialitySurveyParticipantShareToken = (
  options?: TOptions,
) =>
  useMutation<
    ValidateDoubleMaterialitySurveyParticipantShareTokenMutation,
    ValidateDoubleMaterialitySurveyParticipantShareTokenMutationVariables
  >(VALIDATE_DOUBLE_MATERIALITY_SURVEY_PARTICIPANT_SHARE_TOKEN, options);

export default useValidateDoubleMaterialitySurveyParticipantShareToken;
