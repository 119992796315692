import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  SuspendDataPointTypeByLocationMutation,
  SuspendDataPointTypeByLocationMutationVariables,
} from '@/__generated__/types';

export const SUSPEND_DATAPOINT_TYPE_BY_LOCATION = gql`
  mutation SuspendDataPointTypeByLocation(
    $locationId: String!
    $dataPointTypeId: String!
  ) {
    suspendDataPointTypeByLocation(
      locationId: $locationId
      dataPointTypeId: $dataPointTypeId
    ) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  SuspendDataPointTypeByLocationMutation,
  SuspendDataPointTypeByLocationMutationVariables
>;

const useSuspendDataPointTypeByLocationMutation = (options?: TOptions) =>
  useMutation<
    SuspendDataPointTypeByLocationMutation,
    SuspendDataPointTypeByLocationMutationVariables
  >(SUSPEND_DATAPOINT_TYPE_BY_LOCATION, options);

export default useSuspendDataPointTypeByLocationMutation;
