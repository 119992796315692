<script setup lang="ts">
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import type { TDataPointTypeOverride } from '../pages/PgProjects/types';

const props = defineProps<{
  tags?: TDataPointTypeOverride['tags'];
}>();
</script>

<template>
  <AtBadge
    v-for="tag in props.tags"
    :key="tag.name"
    class="mr-2 py-0 text-xs"
    :type="tag.color"
  >
    <span>{{ tag.name }}</span>
  </AtBadge>
</template>
