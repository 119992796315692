import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpsertDataPointMutation,
  UpsertDataPointMutationVariables,
} from '@/__generated__/types';

export const UPSERT_DATAPOINT = gql`
  mutation UpsertDataPoint($upsertDataPointInput: UpsertDataPointInput!) {
    upsertDataPoint(upsertDataPointInput: $upsertDataPointInput) {
      _id
      value
    }
  }
`;

type TOptions = UseMutationOptions<
  UpsertDataPointMutation,
  UpsertDataPointMutationVariables
>;

const useUpsertDataPointMutation = (options?: TOptions) =>
  useMutation<UpsertDataPointMutation, UpsertDataPointMutationVariables>(
    UPSERT_DATAPOINT,
    options,
  );

export default useUpsertDataPointMutation;
