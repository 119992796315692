<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';

const props = defineProps<{
  options: string[];
}>();

const emit = defineEmits<{
  created: [event: string];
}>();

const model = defineModel<string | null>({ required: true });

const { t } = useI18n();

const options = computed(() =>
  props.options.reduce<Record<string, string>>((acc, item) => {
    acc[item] = t(item);
    return acc;
  }, {}),
);

function handleChange(event: string) {
  if (!props.options.find((item) => item === event)) {
    emit('created', event);
  }

  model.value = event;
}
</script>

<template>
  <MlAutocomplete
    :modelValue="model"
    :placeholder="t('Enter or select')"
    :options="options"
    canCreate
    hideSearchIcon
    @update:modelValue="handleChange"
  />
</template>
