<script setup lang="ts">
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import OgRestrictionsModal from './OgRestrictionsModal.vue';

const { t } = useI18n();

const props = defineProps<{
  showModal: boolean;
}>();

const showRestrictionsModal = ref(props.showModal);
const emit = defineEmits(['closeModal']);

watch(
  () => props.showModal,
  (value) => {
    showRestrictionsModal.value = value;
  },
);
</script>
<template>
  <OgRestrictionsModal
    :title="t('Workspace limit reached')"
    :text="
      t(
        'You have reached the maximum number of workspaces in your current plan. Upgrade to be able to add new workspaces.',
      )
    "
    :isRevealed="showRestrictionsModal"
    @closeModal="emit('closeModal')"
  />
</template>
