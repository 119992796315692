<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { ApolloError } from '@apollo/client/core';
import { notify } from '@kyvg/vue3-notification';
import { watchOnce } from '@vueuse/core';
import sortBy from 'lodash/sortBy';
import {
  ReportingFrameworkEnum,
  type TmStepTopicsQuery,
  type UpdateEntityAssignmentInput,
} from '@/__generated__/types';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import useUpdateEntityAssignmentMutation from '@/api/mutations/EntityAssignment/updateEntityAssignment.mutation';
import { getSuggestedSubcategories } from '@/utils/helpers/getSuggestedSubcategories';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import PG_ONBOARDING_QUERY from './PgOnboarding.query';
import TM_STEP_TOPICS_QUERY from './TmStepTopics.query';

const { t } = useI18n();

type TProps = {
  selectedIndustry: string;
  reportingFrameworksSelected: ReportingFrameworkEnum[];
  triggerSave: boolean;
};
const props = defineProps<TProps>();
const emit = defineEmits([
  'updateHasTopicsSelected',
  'entityAssignmentsComplete',
]);

const { result } = useQuery<TmStepTopicsQuery>(
  TM_STEP_TOPICS_QUERY,
  undefined,
  { fetchPolicy: 'no-cache' },
);

const {
  mutate: updateEntityAssignment,
  loading: loadingUpdateEntityAssignment,
} = useUpdateEntityAssignmentMutation({
  refetchQueries: [{ query: PG_ONBOARDING_QUERY }],
});

const locations = computed(
  () => result.value?.getOwnUser.entity.locations ?? [],
); // get locations through getOwnUser and not entitySummaries (which returns empty array if no subcategory is selected)
const locationsHeadquarters = computed(() =>
  locations.value.find((location) => location.isHeadquarters),
);

const dnkSelected = computed(() =>
  props.reportingFrameworksSelected.includes(ReportingFrameworkEnum.DNK),
);

const suggestedSubcategories = computed(() =>
  getSuggestedSubcategories(props.selectedIndustry),
);

const subcategoriesSelectable = computed(() => {
  return (
    result.value?.getCategoriesWithSubcategories
      .filter((category) =>
        props.reportingFrameworksSelected.includes(category.framework),
      )
      .flatMap((category) => {
        return category.subcategories
          .map((subcategory) => ({
            name: subcategory.slug,
            category: category.slug,
            orderPosition: subcategory.orderPosition,
          }))
          .sort((a, b) => a.orderPosition - b.orderPosition);
      }) ?? []
  );
});

const dnkSubcategories = computed(
  () =>
    result.value?.getCategoriesWithSubcategories
      .filter((category) => category.framework === ReportingFrameworkEnum.DNK)
      .flatMap((category) =>
        category.subcategories.map((subcategory) => ({
          name: subcategory.slug,
          category: category.slug,
        })),
      )
      .sort((a, b) =>
        t(a.name).toLowerCase() > t(b.name).toLowerCase() ? 1 : -1,
      ) ?? [],
);

const subcategoriesAssigned = computed(() =>
  locationsHeadquarters.value?.assignments.categories
    .map((category) =>
      category.subcategories.map((subcategory) => subcategory.subcategory),
    )
    .flat(),
);

type TSubcategory = {
  name: string;
  category: string;
  isChecked: boolean;
  isSuggested: boolean;
};
const subcategoriesData = ref([] as TSubcategory[]);
watchOnce(subcategoriesSelectable, initSubcategoriesData);
onMounted(initSubcategoriesData);

function initSubcategoriesData() {
  subcategoriesData.value = !dnkSelected.value
    ? subcategoriesSelectable.value.map((subcategory) => ({
        name: subcategory.name,
        category: subcategory.category,
        isChecked:
          subcategoriesAssigned.value?.some((sc) => sc === subcategory.name) ??
          false,
        isSuggested: suggestedSubcategories.value.some(
          (sc) => sc === subcategory.name,
        ),
      }))
    : [];
}
const dnkSubcategoriesData = computed(() =>
  dnkSelected.value
    ? (dnkSubcategories.value.map((dnkSubcategory) => ({
        name: dnkSubcategory.name,
        category: dnkSubcategory.category,
        isChecked: true,
        isSuggested: true,
      })) as TSubcategory[])
    : [],
);

function updateSubcategory(
  isChecked: boolean,
  category: string,
  subcategory: string,
) {
  const subcategoryIndex = subcategoriesData.value?.findIndex(
    (_subcategory) =>
      _subcategory.name === subcategory && _subcategory.category === category,
  );
  if (subcategoryIndex === undefined)
    throw new Error(`subcategoryIndex is ${subcategoryIndex}`);
  if (subcategoriesData.value) {
    subcategoriesData.value[subcategoryIndex].isChecked = isChecked;
  }
  emit(
    'updateHasTopicsSelected',
    subcategoriesData.value.some((_subcategory) => _subcategory.isChecked),
  );
}

async function updateEntityAssignmentByCategory(
  categorySubcategories: TSubcategory[],
) {
  if (categorySubcategories.length === 0) return;

  const updateEntityAssignmentDto: UpdateEntityAssignmentInput = {
    category: categorySubcategories[0].category,
    isEnabled: true,
    subcategories: categorySubcategories
      .filter((_subcategory) => _subcategory.isChecked)
      .map((__subcategory) => __subcategory.name),
  };
  try {
    await updateEntityAssignment({
      locationId: locationsHeadquarters.value?._id ?? '',
      updateEntityAssignmentInput: [updateEntityAssignmentDto],
    });
  } catch (e) {
    if (e instanceof ApolloError) {
      notify({
        type: 'error',
        text: t(e.message),
        duration: 10000,
      });
    }
  }
}

const categories = computed(
  () =>
    new Set([
      ...subcategoriesSelectable.value.map((s) => s.category),
      ...dnkSubcategoriesData.value.map((s) => s.category),
    ]),
);

async function updateSubcategories() {
  for await (const category of categories.value) {
    await updateEntityAssignmentByCategory([
      ...subcategoriesData.value.filter(
        (subcategory) =>
          subcategory.category === category && subcategory.isChecked,
      ),
      ...dnkSubcategoriesData.value.filter(
        (subcategory) =>
          subcategory.category === category && subcategory.isChecked,
      ),
    ]);
  }

  emit('entityAssignmentsComplete');
}

watch(() => props.triggerSave, updateSubcategories);
</script>
<template>
  <div>
    <AtProgress v-if="loadingUpdateEntityAssignment" foreground />
    <section class="my-2 px-6 py-9">
      <div class="mb-9">
        <h2 class="mb-0.5 mt-0 font-semibold">
          {{
            !dnkSelected
              ? t('Choose sustainability topics')
              : t('Your sustainability topics')
          }}
        </h2>
        <p class="text-sm text-gray-700">
          {{
            !dnkSelected
              ? t(
                  'This selection determines the set of questions shown in the app.',
                )
              : t(
                  'These topics are reflected by the set of questions shown in the app.',
                )
          }}
        </p>
      </div>
      <div v-if="!dnkSelected" class="mb-7">
        <h3 class="mb-1 mt-0 text-sm font-medium leading-5 text-gray-700">
          {{ t('Topics') }}
        </h3>
        <p class="mb-4 mt-0 text-sm leading-5 text-gray-700">
          {{
            t(
              'While we recommend you to complete a company-specific double materiality analysis before issuing a sustainability report, we found the below highlighted topics as likely relevant for you based on your selected industry and size of your operations.',
            )
          }}
        </p>
        <div class="">
          <div v-for="category in categories" :key="category">
            <h2>
              {{ t(category) }}
            </h2>

            <div class="my-3 break-all">
              <AtCheckbox
                v-for="subcategory in sortBy(
                  subcategoriesData.filter((s) => s.category === category),
                  (s) =>
                    s.name.match(/^[a-zA-Z\-_]+[0-9]+/)
                      ? Number(s.name.substring(0, 8).replaceAll(/\D+/g, ''))
                      : s.name,
                )"
                :key="subcategory.name"
                class="mb-1 mr-5 rounded px-1"
                :class="{ 'bg-blue-50': subcategory.isSuggested }"
                :checked="subcategory.isChecked"
                :disabled="loadingUpdateEntityAssignment"
                :label="subcategory.name"
                data-cy="griEsrsTopic"
                @toggleCheckbox="
                  updateSubcategory($event, category, subcategory.name)
                "
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="dnkSelected" class="mb-4">
        <h3 class="mb-1 mt-0 text-sm font-medium leading-5 text-gray-700">
          {{ t('DNK Criteria') }}
        </h3>
        <p class="mb-4 mt-0 text-sm leading-5 text-gray-700">
          {{
            t('Below is the full set of DNK criteria that need to be covered.')
          }}
        </p>
        <div
          class="grid auto-rows-fr grid-cols-2 items-center lg:grid-cols-3 xl:grid-cols-4"
        >
          <div
            v-for="(subcategory, i) in dnkSubcategories"
            :key="subcategory.name"
            class="mb-4 rounded px-2 text-sm text-gray-700"
            data-cy="dnkCriteria"
            :label="subcategory.name"
          >
            <span>
              {{ `${i + 1}. ` }}
            </span>
            {{ t(`${subcategory.name}`) }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="postcss" scoped>
ol {
  list-style-type: decimal;
}
</style>
