<script setup lang="ts">
import { useGlobalQueryLoading } from '@vue/apollo-composable';
import OgFooter from '@/components/organisms/OgFooter.vue';
import OgConfirmationDialog from '@/components/organisms/OgConfirmationDialog.vue';
import OgDrawer from '@/components/organisms/OgDrawer.vue';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import useBreakpoints from '@/utils/composables/useBreakpoints';
import OgPageNav from '@/components/organisms/OgPageNav/OgPageNav.vue';

const { dialogData, confirm, isRevealed } = useConfirmViaDialog();
const loading = useGlobalQueryLoading();
const { lg } = useBreakpoints();

const emit = defineEmits(['showImpersonateBar', 'showRestrictions']);
</script>

<template>
  <div class="relative bg-white">
    <AtProgress v-if="loading" foreground />

    <div id="topbar" />

    <OgConfirmationDialog
      v-bind="dialogData"
      :isRevealed="isRevealed"
      @confirm="confirm"
    />

    <div v-if="lg" class="flex items-center justify-between p-2">
      <OgDrawer>
        <template #content="{ hide }">
          <OgPageNav class="h-full" @itemClick="hide" />
        </template>
      </OgDrawer>
    </div>

    <div class="flex h-full min-h-screen flex-col">
      <div class="mx-auto flex w-full flex-1">
        <OgPageNav
          v-if="!lg"
          class="fixed"
          @showImpersonateBar="emit('showImpersonateBar')"
          @showRestrictions="emit('showRestrictions')"
        />
        <div
          id="page-subnav"
          class="ml-[50px] sticky h-screen top-0 border-gray-200"
        />
        <div class="flex flex-1 flex-col justify-between z-0">
          <main class="flex flex-col">
            <router-view />
          </main>
          <OgFooter />
        </div>
      </div>
    </div>
  </div>
</template>
