<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import {
  TaxonomyAssessmentStatusEnum,
  type PgTaxonomyAssessmentQuery,
} from '@/__generated__/types';
import useUpdateTaxonomyAssessmentBusinessActivity from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';
import OgSimpleTable from '@/components/organisms/OgSimpleTable/OgSimpleTable.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';

type TProps = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment'];
};

const { t } = useI18n();
const router = useRouter();
const props = defineProps<TProps>();
const { formatNumber } = useFormatNumber();

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();
const { mutate: updateTaxonomyAssessmentBusinessActivity } =
  useUpdateTaxonomyAssessmentBusinessActivity();

const formData = reactive({
  activityFits: {} as Record<string, number>,
});

const activityTurnovers = computed(() =>
  props.assessment.businessActivities
    .filter((ba) => ba.activityTurnover > 0 && ba.objective)
    .reduce(
      (acc, curr) => ({ ...acc, [curr._id]: curr.activityTurnover }),
      {} as Record<string, number>,
    ),
);

watch(
  props.assessment,
  () => {
    if (Object.keys(formData.activityFits).length === 0) {
      formData.activityFits = props.assessment.businessActivities
        .filter((ba) => ba.activityTurnover > 0 && ba.objective)
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr._id]: Math.max(0, curr.activityFit),
          }),
          {},
        );
    }
  },
  { immediate: true },
);

const isLoading = ref(false);

const next = async () => {
  isLoading.value = true;

  for await (const [key, value] of Object.entries(formData.activityFits)) {
    await updateTaxonomyAssessmentBusinessActivity({
      assessmentId: props.assessment._id,
      businessActivityId: key,
      input: {
        activityFit: value,
      },
    });
  }

  const result = await updateTaxonomyAssessment(
    {
      assessmentId: props.assessment._id,
      input: {
        status:
          TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_DO_NO_SIGNIFICANT_HARM,
      },
    },
    {
      update: (store) => {
        store.evict({ fieldName: 'getTaxonomyProjects' });
        store.evict({ fieldName: 'getTaxonomyAssessment' });
      },
    },
  );

  const isAssessmentCompleted =
    result?.data?.updateTaxonomyAssessment.businessActivities.every(
      (activity) => activity.activityFit === 0,
    );
  if (isAssessmentCompleted) {
    await updateTaxonomyAssessment(
      {
        assessmentId: props.assessment._id,
        input: {
          status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED,
        },
      },
      {
        update: (store) => {
          store.evict({ fieldName: 'getTaxonomyProjects' });
          store.evict({ fieldName: 'getTaxonomyAssessment' });
        },
      },
    );
    router.push({ name: 'taxonomyAssessmentDocuments' });
  } else {
    router.push({ name: 'taxonomyAssessmentDoNoSignificantHarmChecklist' });
  }

  isLoading.value = false;
};

const prev = () => {
  router.push({ name: 'taxonomyAssessmentSubstantialContributionObjectives' });
};

const fields = ['Activity', 'Activity Fit (%)', 'Activity Turnover'];
const items = computed(() =>
  props.assessment.businessActivities
    .filter((ba) => ba.activityTurnover > 0 && ba.objective)
    .map((activity) => ({
      _id: activity._id,
      Activity: `${activity.activity.number}: ${t(activity.activity.name)}`,
    })),
);
</script>

<template>
  <div>
    <p class="text-sm font-medium mb-3">
      {{
        t(
          'To what percentage does your activity fit with the substantial contribution criteria defined in the EU Taxonomy?',
        )
      }}
    </p>

    <OgSimpleTable :fields="fields" :items="items">
      <template #item-activity="item">
        {{ t(item.Activity) }}
      </template>
      <template #item-activity-fit="item">
        <AtInput
          v-model.number="formData.activityFits[item._id]"
          unit="%"
          type="percent"
        />
      </template>
      <template #item-activity-turnover="item">
        {{
          formatNumber(
            (activityTurnovers[item._id] / 100) *
              formData.activityFits[item._id],
          )
        }}
        Euro
      </template>
    </OgSimpleTable>

    <p class="font-semibold">
      {{
        t(
          'Your total that is Taxonomy-aligned with the substantial contribution criteria is {number} Euro.',
          {
            number: formatNumber(
              Object.entries(formData.activityFits).reduce(
                (curr, acc) =>
                  curr + (activityTurnovers[acc[0]] / 100) * acc[1],
                0,
              ),
            ),
          },
        )
      }}
    </p>
  </div>

  <div class="flex justify-between">
    <AtButton
      class="my-4 float-right"
      :disabled="isLoading"
      variant="outline"
      @click="prev"
    >
      {{ t('Back') }}
    </AtButton>

    <AtButton class="my-4 float-right" :loading="isLoading" @click="next">
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
