<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import isNil from 'lodash/isNil';
import { DotsHorizontalIcon } from '@heroicons/vue/outline';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import OgSimpleTable from '@/components/organisms/OgSimpleTable/OgSimpleTable.vue';
import {
  TaxonomyAssessmentStatusEnum,
  type PgTaxonomyAssessmentQuery,
} from '@/__generated__/types';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import useUpdateTaxonomyAssessmentBusinessActivity from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';
import MlRadio from '@/components/molecules/MlRadio.vue';

type CapexFormData = {
  totalCapex: number | null;
  totalCapexCompleteLater: boolean;
  activityCapexs: Record<string, number>;
};

const { t } = useI18n();

type TProps = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment'];
};

const router = useRouter();
const props = defineProps<TProps>();
const { formatNumber } = useFormatNumber();
const { confirmViaDialog } = useConfirmViaDialog();

const formData = reactive<CapexFormData>({
  totalCapex: -1,
  totalCapexCompleteLater: false,
  activityCapexs: {},
});
const hasCapex = ref(false);

watch(
  () => props.assessment,
  () => {
    if (Object.keys(formData.activityCapexs).length === 0) {
      formData.activityCapexs = props.assessment.businessActivities.reduce(
        (acc, curr) => ({
          ...acc,
          [curr._id]: Math.max(0, curr.activityCapex),
        }),
        {},
      );
    }

    formData.totalCapexCompleteLater = props.assessment.capexCompleteLater;

    if (formData.totalCapexCompleteLater) {
      formData.totalCapex = null;
    } else if (formData.totalCapex === -1) {
      formData.totalCapex = Math.max(0, props.assessment.capex);
    }

    if (props.assessment.capex >= 0 || props.assessment.capexCompleteLater) {
      hasCapex.value = true;
    }
  },
  { immediate: true },
);

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();
const { mutate: updateTaxonomyAssessmentBusinessActivity } =
  useUpdateTaxonomyAssessmentBusinessActivity();

watch(
  () => formData.totalCapex,
  (newTotalCapexValue) => {
    if (typeof newTotalCapexValue === 'number') {
      formData.totalCapexCompleteLater = false;
    }
  },
);

const taxonomyActivitiesCapexSum = computed(() => {
  return Object.values(formData.activityCapexs).reduce(
    (acc, curr) => acc + curr,
    0,
  );
});
const taxonomyActivitiesCapexPercent = computed(() => {
  if (formData.totalCapexCompleteLater || isNil(formData.totalCapex)) {
    return null;
  }

  return (taxonomyActivitiesCapexSum.value / formData.totalCapex) * 100;
});
const activitiesNotIncludedSum = computed(() => {
  if (formData.totalCapexCompleteLater || isNil(formData.totalCapex)) {
    return null;
  }

  return formData.totalCapex - taxonomyActivitiesCapexSum.value;
});
const activitiesNotIncludedPercent = computed(() => {
  if (
    formData.totalCapexCompleteLater ||
    isNil(taxonomyActivitiesCapexPercent.value)
  ) {
    return null;
  }

  return 100 - taxonomyActivitiesCapexPercent.value;
});

const fields = ['Activity', 'Capex', 'Capex Proportion'];
const items = computed(() => [
  ...props.assessment.businessActivities.map((activity) => ({
    _id: activity._id,
    Activity: `${activity.activity.number}: ${t(activity.activity.name)}`,
    Capex: activity.activityCapex,
    'Capex Proportion':
      !isNil(formData.totalCapex) && !formData.totalCapexCompleteLater
        ? ((formData.activityCapexs[activity._id] ?? 0) /
            Math.max(1, formData.totalCapex)) *
          100
        : null,
  })),
  {
    Activity: t('Activities eligible under the EU Taxonomy'),
    Capex: taxonomyActivitiesCapexSum.value,
    'Capex Proportion': taxonomyActivitiesCapexPercent.value,
  },
  {
    Activity: t('Activities not eligible under the EU Taxonomy'),
    Capex: activitiesNotIncludedSum.value,
    'Capex Proportion': activitiesNotIncludedPercent.value,
  },
  {
    Activity: t('TOTAL'),
    Capex: !isNil(activitiesNotIncludedSum.value)
      ? taxonomyActivitiesCapexSum.value + activitiesNotIncludedSum.value
      : null,
    'Capex Proportion':
      !isNil(activitiesNotIncludedPercent.value) &&
      !isNil(taxonomyActivitiesCapexPercent.value)
        ? activitiesNotIncludedPercent.value +
          taxonomyActivitiesCapexPercent.value
        : null,
  },
]);

const canGoNext = computed(() => {
  if (!hasCapex.value) {
    return true;
  }

  if (formData.totalCapexCompleteLater) {
    return true;
  }

  if (isNil(formData.totalCapex)) {
    return false;
  }

  if (!isNil(taxonomyActivitiesCapexPercent.value)) {
    return taxonomyActivitiesCapexPercent.value <= 100;
  }

  return true;
});

const isLoading = ref(false);
const radioOptions = [
  { name: 'yes', label: 'Yes', value: true },
  { name: 'no', label: 'No', value: false },
];

const next = async () => {
  isLoading.value = true;

  await updateTaxonomyAssessment(
    {
      assessmentId: props.assessment._id,
      input: {
        capex:
          hasCapex.value &&
          !isNil(formData.totalCapex) &&
          formData.totalCapex >= 0
            ? formData.totalCapex
            : -1,
        capexCompleteLater: formData.totalCapexCompleteLater,
        status:
          TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES_OPEX,
      },
    },
    {
      update: (store) => {
        store.evict({ fieldName: 'getTaxonomyProjects' });
        store.evict({ fieldName: 'getTaxonomyAssessment' });
      },
    },
  );

  for await (const [key, value] of Object.entries(formData.activityCapexs)) {
    await updateTaxonomyAssessmentBusinessActivity(
      {
        assessmentId: props.assessment._id,
        businessActivityId: key,
        input: {
          activityCapex:
            hasCapex.value && typeof value === 'number' && value >= 0
              ? value
              : -1,
        },
      },
      {
        update: (store) => {
          store.evict({ fieldName: 'getTaxonomyProjects' });
          store.evict({ fieldName: 'getTaxonomyAssessment' });
        },
      },
    );
  }

  router.push({ name: 'taxonomyAssessmentBusinessActivitiesOpex' });

  isLoading.value = false;
};

const prev = () => {
  router.push({ name: 'taxonomyAssessmentBusinessActivitiesTurnover' });
};

async function handleCompleteLaterClick() {
  const isConfirmed = await confirmViaDialog({
    title: t('Complete this input later'),
    text: `<p>${t('Marking this field means you want to add your financial data later, while going through other stages of your EU Taxonomy assessment. You will be reminded to add the data and you will not be able to complete the assessments without it.')}</p><br /><p>${t('Would you like to continue?')}</p>`,
    renderHTML: true,
    confirmLabel: t('Confirm'),
    cancelLabel: t('Go back'),
  });

  if (isConfirmed) {
    formData.totalCapex = null;
    formData.totalCapexCompleteLater = true;
  }
}
</script>

<template>
  <div class="mb-4">
    <p class="mb-2">
      {{ t('Do you want to assign CapEx to your activities?') }}
    </p>
    <MlRadio v-model="hasCapex" class="ml-6" :options="radioOptions" />
  </div>

  <div v-if="hasCapex">
    <AtInput
      v-model.number="formData.totalCapex"
      type="number"
      :placeholder="
        t(
          formData.totalCapexCompleteLater
            ? 'Saved for later - complete now by typing in this field'
            : 'EUR',
        )
      "
      unit="EUR"
    >
      <template #label="{ id }">
        <div class="flex items-center space-x-4 mb-1">
          <label :for="id" class="block text-sm font-medium text-gray-700">
            {{ t('What is the total amount of company’s capex?') }}
          </label>
          <MlMenu>
            <button type="button" class="group flex items-center">
              <DotsHorizontalIcon class="h-5 w-5 group-disabled:opacity-50" />
            </button>
            <template #menuItems>
              <AtMenuItem
                class="justify-center"
                @click="handleCompleteLaterClick"
              >
                {{ t('Complete later') }}
              </AtMenuItem>
            </template>
          </MlMenu>
        </div>
      </template>
    </AtInput>

    <p class="my-4">
      {{ t('State your capex from each of the selected activities') }}
    </p>

    <OgSimpleTable :fields="fields" :items="items">
      <template #item-activity="item">
        {{ t(item.Activity) }}
      </template>
      <template #item-capex="item">
        <AtInput
          v-if="item._id"
          v-model.number="formData.activityCapexs[item._id]"
          unit="EUR"
          type="number"
        />
        <template v-else> {{ formatNumber(item.Capex) }} EUR </template>
      </template>
      <template #item-capexProportion="item">
        <template v-if="item['Capex Proportion'] >= 0">
          {{ formatNumber(item['Capex Proportion']) }}%
        </template>
      </template>
    </OgSimpleTable>

    <p class="font-semibold">
      {{
        t('Your total Taxonomy-eligible capex is {number} Euro.', {
          number: formatNumber(taxonomyActivitiesCapexSum),
        })
      }}
    </p>
  </div>

  <div class="flex justify-between">
    <AtButton
      class="my-4 float-right"
      :disabled="isLoading"
      variant="outline"
      @click="prev"
    >
      {{ t('Back') }}
    </AtButton>

    <AtButton
      class="my-4 float-right"
      :loading="isLoading"
      :disabled="!canGoNext"
      @click="next"
    >
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
