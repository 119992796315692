import { gql } from '@apollo/client/core';

export default gql`
  query PgRepository($adminMode: Boolean!) {
    getEntityRepositoryFiles @skip(if: $adminMode) {
      _id
      ...fields
    }

    getEntityRepositoryFilesAdmin @include(if: $adminMode) {
      _id
      ...fields
    }
  }

  fragment fields on RepositoryFile {
    _id
    filename

    entity {
      _id
      name
    }

    dataPointRequests {
      _id
      from
      to
      status

      location {
        _id
        name
      }

      dataPointType {
        _id
        friendlyName
        name
      }

      dataPointTypeFlat {
        category
        subcategory
      }
    }
  }
`;
