<script setup lang="ts">
// TODO: make custom design

import { useI18n } from 'vue-i18n';

const emit = defineEmits(['stepClicked']);
const props = defineProps<{
  value: number;
  steps: string[];
  vertical?: boolean;
}>();

const { t } = useI18n();
</script>

<template>
  <ul class="steps" :class="{ 'steps-vertical': props.vertical }">
    <li
      v-for="(step, i) in props.steps"
      :key="i"
      class="step text-sm"
      :class="{ 'step-primary': i <= props.value }"
      @click.stop="emit('stepClicked', step)"
    >
      {{ t(step) }}
    </li>
  </ul>
</template>
