import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DeleteTaxonomyProjectMutation,
  DeleteTaxonomyProjectMutationVariables,
} from '@/__generated__/types';

export const DELETE_TAXONOMY_PROJECT_MUTATION = gql`
  mutation DeleteTaxonomyProject($_id: String!) {
    deleteTaxonomyProject(_id: $_id)
  }
`;

type TOptions = UseMutationOptions<
  DeleteTaxonomyProjectMutation,
  DeleteTaxonomyProjectMutationVariables
>;

const useDeleteTaxonomyProjectMutation = (options?: TOptions) =>
  useMutation<
    DeleteTaxonomyProjectMutation,
    DeleteTaxonomyProjectMutationVariables
  >(DELETE_TAXONOMY_PROJECT_MUTATION, options);

export default useDeleteTaxonomyProjectMutation;
