<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <div>
    <p class="mb-3">
      {{ t('Thank you!') }}
    </p>
    <p class="text-xs">
      {{ t('You successfully submitted your answer.') }}
    </p>
  </div>
  <div>
    <p class="text-sm mb-1 font-medium">
      {{ t('About Codio Impact') }}
    </p>
    <p class="text-xs mb-3">
      {{
        t(
          'We automate the way you manage and report your Environmental, Social, Governance (ESG) data, while remaining fully compliant with the newest regulation and leading standards.',
        )
      }}
    </p>
    <p class="text-xs">
      {{ t('Do you want to create an account?') }}
      <a
        class="text-blue-600 hover:text-blue-800 visited:text-purple-600"
        href="/login/signup"
        >{{ t('Sign up here.') }}</a
      >
    </p>
  </div>
</template>
