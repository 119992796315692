import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import type {
  RejectDataPointRequestMutation,
  RejectDataPointRequestMutationVariables,
} from '@/__generated__/types';

export const REJECT_DATAPOINT_REQUEST = gql`
  mutation RejectDataPointRequest(
    $rejectDataPointRequestInput: RejectDataPointRequestInput!
  ) {
    rejectDataPointRequest(
      RejectDataPointRequestInput: $rejectDataPointRequestInput
    ) {
      _id
      status
      rejectedBy {
        _id
        firstName
        lastName
      }
      rejectedAt
    }
  }
`;

type TOptions = UseMutationOptions<
  RejectDataPointRequestMutation,
  RejectDataPointRequestMutationVariables
>;

const useRejectDataPointRequestMutation = (options?: TOptions) =>
  useMutation<
    RejectDataPointRequestMutation,
    RejectDataPointRequestMutationVariables
  >(REJECT_DATAPOINT_REQUEST, options);

export default useRejectDataPointRequestMutation;
