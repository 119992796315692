<script setup lang="ts">
import { HeadingType } from './types';

interface TProps {
  type: `${HeadingType}`;
}
const props = defineProps<TProps>();

const classObject = {
  [HeadingType.H1]: 'text-xl font-normal leading-7 text-gray-900',
  [HeadingType.H2]: 'text-sm font-medium text-gray-900',
  [HeadingType.H3]: 'font-normal text-gray-700',
  [HeadingType.H4]: 'font-normal text-gray-500',
  [HeadingType.H5]: 'font-normal text-gray-900 text-sm',
};
</script>

<template>
  <component :is="props.type" :class="classObject[props.type]">
    <slot />
  </component>
</template>
