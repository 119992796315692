import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { setActiveEntity } from '../services/activeEntity';

const useSwitchActiveEntity = () => {
  const route = useRoute();
  const isAdminPath = computed(() => route.path.includes('/admin/'));
  const isModulesPath = computed(() => route.path.includes('/modules'));

  const switchActiveEntity = async (
    newActiveEntityId: string,
    path?: string,
  ) => {
    if (isAdminPath.value || isModulesPath.value) {
      setActiveEntity(newActiveEntityId);
      return window.location.assign('/');
    }
    setActiveEntity(newActiveEntityId);
    if (path) {
      window.location.assign(`${import.meta.env.VITE_APP_URL}${path ?? ''}`);
    } else if (route.path.startsWith('/projects')) {
      // If on some projects page redirect to home page.
      window.location.href = window.location.origin;
    } else {
      window.location.reload();
    }
  };

  return { switchActiveEntity };
};

export default useSwitchActiveEntity;
