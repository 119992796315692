import { gql } from '@apollo/client/core';

export default gql`
  query MlCategoryDprs(
    $statusFilter: DataPointRequestStatusEnum!
    $categoryFilter: [String!]
    $locationId: String!
    $limit: Int!
    $sortedByUpdate: Boolean!
    $dateFrom: String
    $dateTo: String
    $assignedOnly: Boolean
    $includeChilds: Boolean
  ) {
    getMyDataPointRequests(
      statusFilter: $statusFilter
      categoryFilter: $categoryFilter
      location: $locationId
      limit: $limit
      sortedByUpdate: $sortedByUpdate
      dateFrom: $dateFrom
      dateTo: $dateTo
      assignedOnly: $assignedOnly
      includeChilds: $includeChilds
    ) {
      _id

      parent {
        _id
      }

      ...dprFieldsHomepage
      ...childsHomepage
    }
  }

  fragment childsHomepage on DataPointRequest {
    _id

    childs {
      _id

      ...dprFieldsHomepage
      childs {
        _id

        ...dprFieldsHomepage
      }
    }
  }
  fragment dprFieldsHomepage on DataPointRequest {
    _id
    status
    dueDate
    from
    isHistoric
    location {
      _id
      name
    }
    dataPointType {
      _id
      friendlyName
    }
    dataPointTypeFlat {
      category
      subcategory
    }
  }
`;
