import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  SignUpMutation,
  SignUpMutationVariables,
} from '@/__generated__/types';

export const SIGN_UP = gql`
  mutation SignUp($signupInput: SignupInput!) {
    signup(user: $signupInput) {
      _id
      entity {
        _id
      }
      firstName
      lastName
      jobTitle
      jobDepartment
      email
      phone
      role
      onboardingStatus
      getStartedStepsCompleted
    }
  }
`;

type TOptions = UseMutationOptions<SignUpMutation, SignUpMutationVariables>;

const useSignUpMutation = (options?: TOptions) =>
  useMutation<SignUpMutation, SignUpMutationVariables>(SIGN_UP, options);

export default useSignUpMutation;
