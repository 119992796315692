import dayjs from '@/lib/dayjs/config';
import type { DataPoint, SublevelItem } from '../types';
import type { ProjectsTree } from './projectsTree';

function getProjectIdFromDataPoint(
  dataPoint: DataPoint,
  locationAsProject: boolean,
) {
  return locationAsProject ? dataPoint.location?._id : dataPoint.entity?._id;
}

function mapProject(
  projectTree: ProjectsTree,
  allDataPoints: DataPoint[],
  locationAsProject: boolean,
): SublevelItem {
  const projectId = projectTree.id;
  const projectName = projectTree.name;
  const { entityId } = projectTree;
  const { entityName } = projectTree;

  if (projectTree.children.length === 0) {
    return {
      id: projectId,
      name: projectName,
      entityId,
      entityName,
      dataPoints: sortDataPoints(
        allDataPoints.filter((dataPoint) => {
          return (
            getProjectIdFromDataPoint(dataPoint, locationAsProject) ===
            projectId
          );
        }),
      ),
      sublevels: null,
    };
  }

  return {
    id: projectId,
    name: projectName,
    entityId,
    entityName,
    dataPoints: [],
    sublevels: getSublevels(
      allDataPoints,
      projectTree.children,
      locationAsProject,
    ),
  };
}

function getSublevels(
  allDataPoints: DataPoint[],
  projectsTrees: ProjectsTree['children'],
  locationAsProject: boolean,
): SublevelItem[] {
  return projectsTrees.map((projectTree) => {
    const projectId = projectTree.id;
    const projectName = projectTree.name;
    const { entityId } = projectTree;
    const { entityName } = projectTree;
    const sublevels =
      projectTree.children.length === 0
        ? null
        : getSublevels(allDataPoints, projectTree.children, locationAsProject);
    const dataPoints = sortDataPoints(
      allDataPoints.filter((dataPoint) => {
        return (
          getProjectIdFromDataPoint(dataPoint, locationAsProject) === projectId
        );
      }),
    );

    return {
      id: projectId,
      name: projectName,
      dataPoints,
      sublevels,
      entityId,
      entityName,
    };
  });
}

function filterOutEmptyLevels(
  result: SublevelItem[],
  sublevel: SublevelItem,
): SublevelItem[] {
  if (!sublevel.sublevels) {
    if (sublevel.dataPoints.length > 0) {
      result.push(sublevel);
    }

    return result;
  }

  const sublevels = sublevel.sublevels.reduce(filterOutEmptyLevels, []);
  if (sublevels.length > 0) {
    result.push({
      ...sublevel,
      sublevels,
    });
  }

  return result;
}

function sortDataPoints(dataPoints: DataPoint[]) {
  return [...dataPoints].sort((dataPointA, dataPointB) => {
    const aTo = dayjs(dataPointA.to);
    const bTo = dayjs(dataPointB.to);
    if (aTo.isAfter(bTo, 'day')) {
      return -1;
    }
    if (aTo.isBefore(bTo, 'day')) {
      return 1;
    }

    const aFrom = dayjs(dataPointA.from);
    const bFrom = dayjs(dataPointB.from);
    if (aFrom.isAfter(bFrom, 'day')) {
      return 1;
    }
    if (aFrom.isBefore(bFrom, 'day')) {
      return -1;
    }
    return 0;
  });
}

export function getDataPointsProjectsTrees(
  projectsTrees: ProjectsTree[],
  allDataPoints: DataPoint[],
  locationAsProject: boolean,
): SublevelItem[] {
  return projectsTrees
    .map((projectTree) =>
      mapProject(projectTree, allDataPoints, locationAsProject),
    )
    .reduce(
      (result, sublevel) => filterOutEmptyLevels(result, sublevel),
      [] as SublevelItem[],
    );
}
