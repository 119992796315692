import { gql } from '@apollo/client/core';

export default gql`
  query MlQuestionInput($dataPointTypeIds: [String!]!, $locationId: String!) {
    getDataPointsByTypeAndLocation(
      dataPointTypeIds: $dataPointTypeIds
      locationId: $locationId
    ) {
      _id
      value
      from
      to

      valueSource {
        name
        originalValue
        originalValueUnit
      }

      dataPointRequest {
        _id
      }

      dataPointType {
        _id
        friendlyName
        valueUnit
        valueUnitDivisor
        valueDataType
        valueDataTypeOptions
        activeReportingFramework {
          _id
          category
          subcategory
        }
        summarizingMethod
      }
    }
  }
`;
