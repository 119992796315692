import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  AddDataPointTypeOverrideByEntityMutation,
  AddDataPointTypeOverrideByEntityMutationVariables,
} from '@/__generated__/types';

export const ADD_DATAPOINTTYPE_OVERRIDE_BY_ENTITY = gql`
  mutation AddDataPointTypeOverrideByEntity(
    $dataPointTypeOverrideInput: DataPointTypeOverrideInput!
  ) {
    addDataPointTypeOverrideByEntity(
      dataPointTypeOverride: $dataPointTypeOverrideInput
    ) {
      _id
      name
      overrides {
        _id
        datapointtype {
          _id
        }
        refreshInterval
        valueUnit
        isSplit
        customConversionFactor
        tags {
          _id
          name
          color
          entity {
            _id
          }
        }
        guidanceComments {
          _id
          text
          createdAt
          user {
            _id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  AddDataPointTypeOverrideByEntityMutation,
  AddDataPointTypeOverrideByEntityMutationVariables
>;

const useAddDataPointTypeOverrideByEntityMutation = (options?: TOptions) =>
  useMutation<
    AddDataPointTypeOverrideByEntityMutation,
    AddDataPointTypeOverrideByEntityMutationVariables
  >(ADD_DATAPOINTTYPE_OVERRIDE_BY_ENTITY, options);

export default useAddDataPointTypeOverrideByEntityMutation;
