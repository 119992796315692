<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import ViewListIcon from '@heroicons/vue/outline/ViewListIcon';
import { DoubleMaterialitySurveyStatus } from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlInfo from '@/components/pages/Modules/DoubleMateriality/MlInfo.vue';
import { useStore } from '../../store';
import AtEmptyCard from '../../AtEmptyCard.vue';
import OgImpactsTable from './OgImpactsTable.vue';
import OgRisksAndOpportunitiesTable from './OgRisksAndOpportunitiesTable.vue';

const { t } = useI18n();
const router = useRouter();
const store = useStore();

const showTables = computed(() =>
  store.value.surveys.some(
    (survey) => survey.status === DoubleMaterialitySurveyStatus.COMPLETED,
  ),
);
</script>

<template>
  <AtEmptyCard
    v-if="!showTables"
    variant="center"
    :icon="ViewListIcon"
    :title="t('Material impacts, risks and opportunities')"
  >
    <p>
      {{
        t(
          'Once your stakeholders respond to sent out surveys, the results of the stakeholder assessment will show here.',
        )
      }}
    </p>
  </AtEmptyCard>

  <template v-else>
    <MlInfo type="warning" class="mb-6">
      {{
        t(
          'Below you see the feedback of your key stakeholder groups, on your double materiality assessment. This input  are the results that will be taken into account when finalizing your double materiality assessment. Please keep in mind that this is a cumulative score of all surveys. If you want to learn more about our methodology, please go to the “Guidance” site on the main overview.',
        )
      }}
    </MlInfo>

    <div class="mb-16">
      <OgImpactsTable />
    </div>

    <OgRisksAndOpportunitiesTable />
  </template>

  <div class="mt-auto flex items-center justify-between">
    <AtButton
      type="button"
      variant="outline"
      @click="router.push({ name: 'doubleMaterialityStakeholdersSurveys' })"
    >
      {{ t('Go back') }}
    </AtButton>
    <AtButton
      type="button"
      @click="router.push({ name: 'doubleMaterialityResultsList' })"
    >
      {{ t('Next') }}
    </AtButton>
  </div>
</template>
