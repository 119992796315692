<script setup lang="ts">
import {
  computed,
  reactive,
  ref,
  toRaw,
  useAttrs,
  type VNode,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { PlusIcon, XIcon } from '@heroicons/vue/solid';
import { useQuery } from '@vue/apollo-composable';
import { useMounted } from '@vueuse/core';
import orderBy from 'lodash/orderBy';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import type {
  DataPointValueSource,
  DataPointTypeValueUnitEnum,
  AtEmissionSplitInputQuery,
} from '@/__generated__/types';
import type { TDataPointRequest } from '@/components/pages/PgProjects/types';
import MlCombobox from '@/components/molecules/MlCombobox.vue';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';
import EMISSION_SPLIT_INPUT_QUERY from './EmissionSplitInput.query';

interface TProps {
  component: VNode;
  valueSource?: TDataPointRequest['valueSource'];
  emissionSubcategory: string | null | undefined;
  dataPointRequestId?: string;
  dataPointRequestYear?: number;
}

const props = withDefaults(defineProps<TProps>(), {
  valueSource: () => [] as DataPointValueSource[],
  dataPointRequestId: () => '0',
  dataPointRequestYear: undefined,
});

const isMounted = useMounted();

const { result, loading } = useQuery<AtEmissionSplitInputQuery>(
  EMISSION_SPLIT_INPUT_QUERY,
  {
    emissionSubcategory: props.emissionSubcategory,
  },
);
const emissionFactorSuggestions = computed(
  () => result.value?.getEmissionFactorSuggestions ?? [],
);

const emit = defineEmits(['update:modelValue']);
const { t } = useI18n();
const categoryOptions = ref<Record<string, string>>({});

const countryOptions = computed(() => {
  return emissionFactorSuggestions.value.reduce(
    (acc, cur) => {
      if (cur.country) {
        acc[cur.country] = cur.country;
      }

      return acc;
    },
    {} as Record<string, string>,
  );
});

watch(
  [emissionFactorSuggestions],
  () => {
    categoryOptions.value = {};

    for (const emissionFactorSuggestion of orderBy(
      emissionFactorSuggestions.value,
      ['name'],
      ['asc'],
    )) {
      categoryOptions.value[emissionFactorSuggestion.key] = t(
        emissionFactorSuggestion.key,
      );
    }

    for (const valueSource of props.valueSource ?? []) {
      categoryOptions.value[valueSource.name] = t(valueSource.name);
    }
  },
  { immediate: true },
);

export interface TElement {
  name: string;
  value: string | null;
  unit?: DataPointTypeValueUnitEnum;
  emissionFactor?: number | null;
  emissionFactorCountry?: string | null;
}

const elements = reactive<TElement[]>([
  {
    name: '',
    value: null,
    unit: undefined,
    emissionFactor: null,
    emissionFactorCountry: null,
  },
]);
const elementsCount = ref(1);

watch(
  [() => props.valueSource, emissionFactorSuggestions],
  ([newValueSource, newEmissionFactorSuggestions]) => {
    if (newValueSource?.length) {
      elements.length = 0;

      elements.push(
        ...newValueSource.map((singleValueSource) => ({
          name: singleValueSource.name,
          value: singleValueSource.originalValue,
          unit:
            singleValueSource.originalValueUnit ??
            newEmissionFactorSuggestions.find(
              (item) => item.key === singleValueSource.name,
            )?.valueUnit ??
            undefined,
          emissionFactor: singleValueSource.emissionFactor,
          emissionFactorCountry: singleValueSource.emissionFactorCountry,
        })),
      );

      elementsCount.value = elements.length;
    }
  },
  { immediate: true },
);

const addElement = () => {
  elements.push({
    name: '',
    value: null,
    unit: undefined,
    emissionFactor: null,
  });
  elementsCount.value += 1;
};

const removeElement = (index: number) => {
  elements.splice(index, 1);
  elementsCount.value -= 1;
};

watch(elements, (newElements) => {
  emit('update:modelValue', toRaw(newElements));
});

const hasCountryFactors = computed(() => {
  return (
    result.value?.getEmissionFactorSuggestions.find((efs) => efs.country) !==
    undefined
  );
});

const updateEmissionFactor = (
  inputIndex: number,
  emissionFactorSuggestionKey: string | string[],
) => {
  const emissionFactorSuggestion =
    result.value?.getEmissionFactorSuggestions.find(
      (efs) =>
        efs.key === emissionFactorSuggestionKey &&
        (elements[inputIndex].emissionFactorCountry === efs.country ||
          !efs.country),
    );

  if (
    typeof emissionFactorSuggestionKey === 'object' &&
    emissionFactorSuggestionKey.length === 0
  ) {
    elements[inputIndex].name = '';
    return;
  }

  if (
    typeof emissionFactorSuggestionKey === 'string' &&
    !emissionFactorSuggestion
  ) {
    categoryOptions.value[emissionFactorSuggestionKey] =
      emissionFactorSuggestionKey;
    elements[inputIndex].name = emissionFactorSuggestionKey;
    return;
  }

  if (emissionFactorSuggestion) {
    if (props.dataPointRequestYear) {
      elements[inputIndex].emissionFactor =
        emissionFactorSuggestion.emissionFactors.find(
          (emissionFactor) =>
            emissionFactor.year === props.dataPointRequestYear,
        )?.factor;
    } else {
      elements[inputIndex].emissionFactor =
        emissionFactorSuggestion.emissionFactors[
          emissionFactorSuggestion.emissionFactors.length - 1
        ].factor;
    }
    elements[inputIndex].unit = emissionFactorSuggestion.valueUnit;
  }
};

const resetFactor = (index: number) => {
  elements[index].emissionFactor = null;
};

const attrs = useAttrs();
const isDisabled = ref<boolean>(attrs.disabled === true);
</script>

<template>
  <div>
    <TransitionGroup name="vertical-fade-slide">
      <template v-if="!loading">
        <div
          v-for="number in elementsCount"
          :key="number"
          class="relative grid grid-cols-12 gap-x-1 mb-2 rounded p-2 pt-5"
        >
          <a
            v-if="!isDisabled"
            href=""
            aria-label="remove item"
            class="absolute top-1 right-1"
            @click.prevent="removeElement(number - 1)"
            ><XIcon class="h-4 text-gray-400 hover:text-primary"
          /></a>
          <div class="col-span-12 2xl:col-span-4 h-full flex">
            <MlCombobox
              v-model="elements[number - 1].name"
              v-model:options="categoryOptions"
              class="w-full"
              :placeholder="t('Select or create a new value')"
              :disabled="isDisabled"
              :allowCreate="!isDisabled"
              @update:modelValue="updateEmissionFactor(number - 1, $event)"
            />
            <MlAutocomplete
              v-if="hasCountryFactors"
              :options="
                Object.fromEntries(
                  Object.entries(countryOptions).filter(([, v]) =>
                    emissionFactorSuggestions.find(
                      (ems) =>
                        ems.key === elements[number - 1].name &&
                        (ems.country === v || !ems.country),
                    ),
                  ),
                )
              "
              sortedOptions
              :hideSearchIcon="true"
              :placeholder="t('Country')"
              wrapperClass="ml-1 w-3"
              :disabled="isDisabled"
              :modelValue="elements[number - 1].emissionFactorCountry"
              @update:modelValue="
                ($event) => {
                  elements[number - 1].emissionFactorCountry = $event;
                  if ('' === $event) {
                    resetFactor(number - 1);
                  } else {
                    updateEmissionFactor(number - 1, elements[number - 1].name);
                  }
                }
              "
            />

            <label :for="`value-${number}`" />
          </div>
          <div class="col-span-12 2xl:col-span-4 h-full flex">
            <component
              :is="props.component"
              v-model="elements[number - 1].value"
              wrapperClass="self-end w-full"
              :placeholder="t('Enter numeric value')"
              :unit="elements[number - 1].unit"
              :disabled="isDisabled"
              type="number"
            />
          </div>
          <div class="col-span-12 2xl:col-span-4 h-full flex">
            <AtInput
              v-model="elements[number - 1].emissionFactor"
              type="emissionFactor"
              wrapperClass="self-end w-full"
              :placeholder="t('Enter emission factor in tCO2e')"
              :disabled="isDisabled"
            />
          </div>
        </div>
      </template>
    </TransitionGroup>
    <Teleport
      v-if="isMounted"
      :to="`#actionAddRow_${props.dataPointRequestId ?? '0'}`"
    >
      <a
        v-if="!isDisabled"
        href=""
        aria-label="add row"
        class="justify-center text-xs flex text-gray-400 hover:text-primary items-center"
        @click.prevent="addElement"
      >
        <PlusIcon class="h-4 mr-2" />
        {{ t('Add row') }}
      </a>
    </Teleport>
  </div>
</template>
