import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DeleteInsightMutation,
  DeleteInsightMutationVariables,
} from '@/__generated__/types';

export const DELETE_INSIGHT = gql`
  mutation DeleteInsight($deleteInsightInput: DeleteInsightInput!) {
    deleteInsight(deleteInsightInput: $deleteInsightInput)
  }
`;

type TOptions = UseMutationOptions<
  DeleteInsightMutation,
  DeleteInsightMutationVariables
>;

const useDeleteInsightMutation = (options?: TOptions) =>
  useMutation<DeleteInsightMutation, DeleteInsightMutationVariables>(
    DELETE_INSIGHT,
    options,
  );

export default useDeleteInsightMutation;
