<script setup lang="ts">
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import { notify } from '@kyvg/vue3-notification';
import useValidateDoubleMaterialitySurveyParticipantShareToken from '@/api/mutations/DoubleMateriality/validateDoubleMaterialitySurveyParticipantShareToken.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import OgLocaleChange from '@/components/organisms/OgLocaleChange.vue';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const { mutate: validate } =
  useValidateDoubleMaterialitySurveyParticipantShareToken();

const form = reactive({
  email: '',
});
const v$ = useVuelidate(
  {
    email: {
      required: helpers.withMessage(t('Email is required.'), required),
      email: helpers.withMessage(t('Email must be valid email.'), email),
    },
  },
  form,
);

async function handleSubmit() {
  try {
    const shareToken = route.params.shareToken.toString();

    await validate({
      email: form.email,
      shareToken,
    });

    window.localStorage.setItem(
      'doubleMaterialitySurveyParticipantEmail',
      form.email,
    );

    router.push({
      name: 'externalDoubleMaterialitySurveyAnswer',
      params: {
        shareToken,
      },
    });
  } catch {
    notify({
      type: 'error',
      text: t(
        'The email address or the link used might be wrong or outdated. Please try again.',
      ),
    });
  }
}
</script>

<template>
  <div class="flex h-screen justify-center items-center">
    <div class="bg-white w-1/3 p-8 py-16 rounded-md text-center">
      <OgLocaleChange class="mr-6 h-full" />
      <AtHeading type="h1" class="mb-5 font-semibold">
        {{ t('Help us making sustainability measurable.') }}
      </AtHeading>

      <p class="text-sm mb-5">
        {{
          t(
            'You have been asked to give your ratings on certain impacts, risks and/or opportunities in the context of double materiality assessment.',
          )
        }}
      </p>

      <form @submit.prevent="handleSubmit">
        <AtInput
          v-model="form.email"
          labelClass="text-left"
          :label="t('Verify your email address')"
          class="mb-4"
          :placeholder="t('Enter email address')"
          type="email"
          required
          :errors="v$.email.$errors"
        />

        <AtButton type="submit" class="w-full">
          {{ t('Verify') }}
        </AtButton>
      </form>
    </div>
  </div>
</template>
