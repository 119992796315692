<script setup lang="ts">
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

defineProps<{
  title: string;
  action: string;
  disabled?: boolean;
  loading?: boolean;
}>();

const emit = defineEmits<{
  click: [];
}>();

function handleClick() {
  emit('click');
}
</script>

<template>
  <div
    class="bg-gray-50 rounded-md px-7 pt-5 pb-10 flex flex-col justify-between"
  >
    <div>
      <p class="font-bold mb-6 text-gray-900">
        {{ title }}
      </p>
      <div class="mb-9">
        <slot />
      </div>
    </div>
    <div>
      <AtButton
        type="button"
        :disabled="disabled ?? false"
        :loading="loading ?? false"
        @click="handleClick"
      >
        {{ action }}
      </AtButton>
    </div>
  </div>
</template>
