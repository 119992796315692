<script setup lang="ts">
import type { TDataTableHeader } from './types';

const props = defineProps<{
  headers: TDataTableHeader[];
  lastColumnAlignLeft?: boolean;
}>();
</script>

<template>
  <tr class="mb-4 grid grid-flow-col justify-items-end border-y px-4 py-6">
    <th
      v-for="header in props.headers"
      :key="header.value"
      class="block text-sm uppercase text-gray-400 first-of-type:mr-auto"
      :class="{ 'last:justify-self-end': !props.lastColumnAlignLeft }"
    >
      {{ header.text }}
    </th>
  </tr>
</template>
