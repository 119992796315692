<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';

const emit = defineEmits<{
  start: [];
}>();

const { t } = useI18n();

function handleStartClick() {
  emit('start');
}
</script>

<template>
  <div>
    <AtHeading type="h1" class="mb-4">
      {{ t('Help us making sustainability measurable.') }}
    </AtHeading>

    <p class="mb-3">
      {{
        t(
          'In general, the EU Taxonomy is one of the cornerstones for both the European Green Deal and the EU Action Plan on Sustainable Finance. The ultimate goal is to help shift the capital flow towards more sustainable investments.',
        )
      }}
    </p>

    <p class="mb-3">
      {{
        t(
          'To achieve that, a clear definition of what is “sustainable” is needed. That is the reason for EU Taxonomy to aim at providing clear guidance on when an activity can be deemed sustainable.',
        )
      }}
    </p>

    <p class="mb-3">
      <span class="font-medium">{{
        t(
          'You have now been asked to confirm that a certain business activity performed by your organization fulfills conditions set out under the EU-Taxonomy.',
        )
      }}</span>
      {{ t('While doing that, please make sure you:') }}
    </p>

    <ul class="mb-3">
      <li>
        {{ t('read the conditions carefully;') }}
      </li>
      <li>
        {{
          t(
            'understand to which project or business activity they relate to - you will have the name of the project on top of your survey;',
          )
        }}
      </li>
      <li>
        {{
          t('confirm only if you are certain the project meets the criteria;')
        }}
      </li>
      <li>
        {{
          t(
            'attach proof documents or any other document that can help verify your confirmation (in case of audit);',
          )
        }}
      </li>
      <li>
        {{
          t(
            'enter a comment - in case of any doubts or any specificity of the activity that needs to be passed on to the person responsible for this project.',
          )
        }}
      </li>
    </ul>

    <p class="mb-10">
      {{
        t('Note that you can always request help by clicking on “Help” button.')
      }}
    </p>

    <AtButton type="button" @click="handleStartClick">
      {{ t('Get started') }}
    </AtButton>
  </div>

  <div>
    <p class="text-sm mb-1 font-medium">
      {{ t('About Codio Impact') }}
    </p>
    <p class="text-xs">
      {{
        t(
          'We automate the way you manage and report your Environmental, Social, Governance (ESG) data, while remaining fully compliant with the newest regulation and leading standards.',
        )
      }}
    </p>
  </div>
</template>
