<script setup lang="ts">
import { computed, useSlots } from 'vue';

defineProps<{
  items: {
    _id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }[];
}>();

const slots = useSlots();

const itemSlots = computed(() =>
  Object.keys(slots).filter((name) => name.startsWith('item-')),
);
</script>

<template>
  <table class="w-full border-collapse">
    <tbody>
      <template v-for="item in items" :key="item._id">
        <tr class="border-y odd:hover:bg-gray-50 cursor-pointer">
          <td v-for="name in itemSlots" :key="name" class="p-4 align-middle">
            <slot :name="name" v-bind="item" />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
