<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { ErrorObject } from '@vuelidate/core';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';

type Props = {
  modelValue: string;
  errors?: string[] | ErrorObject[];
};

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
});
const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

const options = {
  agriculture_agriculture_and_farming_forestry:
    'Agriculture (Agriculture and Farming, Forestry)',
  construction_construction_engineering:
    'Construction (Construction & Engineering)',
  energy_energy_production_utilities: 'Energy (Energy Production & Utilities)',
  financial_institutions_financial_services_capital_markets_banking_insurance:
    'Financial Institutions (Financial Services, Capital Markets, Banking & Insurance)',
  health_care_services_healthcare: 'Health Care & Services (Healthcare)',
  hospitality_food_beverage_services_hotels_lodging_leisure_travel:
    'Hospitality (Food & Beverage Services, Hotels & Lodging, Leisure Travel)',
  manufacturing_aerospace_defence_automobiles_construction_materials_electronics_etc:
    'Manufacturing (Aerospace & Defence, Automobiles, Construction Materials, Electronics, etc.)',
  mining_coal_operations_mining_oil_gas:
    'Mining (Coal Operations, Mining, Oil & Gas)',
  real_estate_real_estate_services: 'Real Estate (Real Estate & Services)',
  services_advertising_marketing_media_creative_industries_education_professional_commerical_services:
    'Services (Advertising & Marketing, Media, Creative Industries, Education, Professional & Commerical Services)',
  technology_media_communications_telecommunications:
    'Technology (Media & Communications, Telecommunications)',
  transportation_transport_logistics: 'Transportation (Transport, Logistics)',
  waste_management_utilities_water_waste_services:
    'Waste Management & Utilities (Water & Waste Services)',
  wholesale_and_retail_wholesale_retail_trade_e_commerce:
    'Wholesale and Retail (Wholesale & Retail Trade, E-Commerce)',
  other: 'Other',
};
</script>

<template>
  <MlSelect
    :label="t('Industries')"
    :options="options"
    :modelValue="props.modelValue"
    :errors="props.errors"
    data-cy="companyDpr"
    @update:modelValue="emit('update:modelValue', $event)"
  />
</template>

<style scoped lang="postcss"></style>
