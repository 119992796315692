<script setup lang="ts">
// Documentation: https://hc200ok.github.io/vue3-easy-data-table-doc

import { nextTick, onMounted, ref, watch, type HTMLAttributes } from 'vue';
import EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import { useI18n } from 'vue-i18n';
import { colors } from '@/styles/theme';
import type { TButtons } from '../types';
import type {
  TDataTableFilter,
  TDataTableHeader,
  TDataTableItem,
} from './types';
import OgDataTableControls from './OgDataTableControls.vue';

const props = withDefaults(
  defineProps<{
    wrapperClass?: HTMLAttributes['class'];
    headers: TDataTableHeader[];
    items: TDataTableItem[];
    hover?: boolean;
    controlsHidden?: boolean;
    variant?: 'default' | 'dark-gray' | 'light-gray';
    hasSubRows?: boolean;
    customFilters?: TDataTableFilter[];
    hasTotal?: boolean;
    showMultiSelect?: boolean;
    buttons?: TButtons<TDataTableItem[]>;
    checkedItems?: TDataTableItem[];
    noPagination?: boolean;
  }>(),
  {
    variant: 'default',
    wrapperClass: undefined,
    customFilters: undefined,
    buttons: undefined,
    checkedItems: undefined,
  },
);

const { t } = useI18n();

const filteredAndSortedItems = ref(props.items);
const checkedItems = props.showMultiSelect
  ? ref(props.checkedItems)
  : undefined;
watch(
  () => props.items,
  () => {
    filteredAndSortedItems.value = props.items;
  },
);
watch(
  () => props.checkedItems,
  () => {
    if (checkedItems) {
      checkedItems.value = props.checkedItems;
    }
  },
);

const getHeaderClasses = () => {
  return props.variant === 'default' ? 'text-left' : '';
};

defineOptions({ inheritAttrs: false });
const emit = defineEmits(['updateCheckedItems']);

const updateCheckedItems = (items: TDataTableItem[]) => {
  emit('updateCheckedItems', items);
};

// no better way. applying css on easydatatable is not reliable
const adjustBorders = () => {
  document
    .querySelectorAll('.og-data-table tr')
    .forEach((row, rowIndex, rows) => {
      const cells = row.querySelectorAll('td');

      cells.forEach((cell, colIndex) => {
        if (rowIndex < rows.length - 1) {
          // Skip the last row
          const cellBelow = rows[rowIndex + 1].querySelectorAll('td')[colIndex];
          if (cellBelow && cellBelow.innerHTML.trim() === '') {
            (cell as HTMLElement).style.borderBottom = '0';
          } else {
            (cell as HTMLElement).style.borderBottom = '1px solid #e5e7eb'; // gray-200
          }
        }
      });
    });
};

onMounted(() => {
  if (props.hasSubRows) {
    nextTick().then(adjustBorders);
  }
});

function onUpdatePageItems() {
  if (props.hasSubRows) {
    nextTick().then(adjustBorders);
  }
}
</script>

<template>
  <div class="py-6" :class="props.wrapperClass">
    <OgDataTableControls
      v-if="!props.controlsHidden"
      v-model="filteredAndSortedItems"
      :headers="props.headers"
      :items="props.items"
      :customFilters="props.customFilters"
      :checkedItems="checkedItems"
      :buttons="props.buttons"
      @updateCheckedItems="updateCheckedItems"
    />
    <slot name="tableTitle" />
    <EasyDataTable
      v-model:items-selected="checkedItems"
      class="og-data-table"
      :class="[
        { 'og-data-table--variant-1': props.variant === 'default' },
        { 'og-data-table--variant-2': props.variant === 'dark-gray' },
        { 'og-data-table--variant-3': props.variant === 'light-gray' },
        { 'og-data-table--has-total': props.hasTotal },
      ]"
      :headerItemClassName="getHeaderClasses"
      :headers="props.headers"
      :items="filteredAndSortedItems"
      :themeColor="colors.primary.DEFAULT"
      :bodyRowClassName="props.hover ? 'cursor-pointer' : ''"
      :noHover="!props.hover"
      :rowsPerPageMessage="t('rows per page:')"
      :fixedHeader="false"
      :hideFooter="noPagination"
      buttonsPagination
      data-cy="dataTable"
      v-bind="$attrs"
      @updatePageItems="onUpdatePageItems"
    >
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </EasyDataTable>
  </div>
</template>

<style lang="postcss" scoped>
.og-data-table {
  --easy-table-row-border: 1px solid theme(colors.gray.200);
  --easy-table-header-font-size: theme(fontSize.sm);
  --easy-table-header-height: theme(spacing.14);
  --easy-table-body-row-height: theme(spacing.16);

  --easy-table-footer-font-size: theme(fontSize.sm);
  --easy-table-footer-height: theme(spacing.20);
  --easy-table-rows-per-page-selector-width: theme(spacing.14);
  --easy-table-rows-per-page-selector-option-padding: theme(spacing.2);
}
.og-data-table--variant-1 {
  @apply border-x-0 border-b-0 mt-4;

  --easy-table-header-font-color: theme(colors.gray.400);
  --easy-table-body-row-font-size: theme(fontSize.sm);
}

.og-data-table--variant-2 {
  @apply border-0 mt-4;

  --easy-table-header-background-color: theme(colors.gray.200);
  --easy-table-header-height: theme(spacing.12);
  --easy-table-body-row-height: theme(spacing.12);
  --easy-table-header-font-color: theme(colors.gray.400);

  /* Prevent vue3-easy-data-table bug (css variable background-color incorrectly overflows on borders)*/
  --easy-table-body-row-background-color: var(
    --easy-table-body-row-background-color,
    unset
  );
}

.og-data-table--variant-3 {
  @apply border-0 mt-4;

  --easy-table-header-background-color: theme(colors.gray.50);
  --easy-table-header-height: theme(spacing.12);
  --easy-table-body-row-height: theme(spacing.12);
  --easy-table-header-font-color: theme(colors.gray.400);

  /* Prevent vue3-easy-data-table bug (css variable background-color incorrectly overflows on borders)*/
  --easy-table-body-row-background-color: var(
    --easy-table-body-row-background-color,
    unset
  );
}

.og-data-table--variant-2:deep(.vue3-easy-data-table__header tr th) {
  @apply border-b-0;
}
.og-data-table--variant-2:deep(
    .vue3-easy-data-table__header tr th:first-child
  ) {
  @apply rounded-l-md;
}
.og-data-table--variant-2:deep(.vue3-easy-data-table__header tr th:last-child) {
  @apply rounded-r-md;
}

.og-data-table--variant-2:deep(.vue3-easy-data-table__body tr td) {
  @apply py-2;
}

.og-data-table--variant-3:deep(.vue3-easy-data-table__header tr th) {
  @apply border-b-0;
}
.og-data-table--variant-3:deep(
    .vue3-easy-data-table__header tr th:first-child
  ) {
  @apply rounded-l-md;
}
.og-data-table--variant-3:deep(.vue3-easy-data-table__header tr th:last-child) {
  @apply rounded-r-md;
}

.og-data-table--variant-3:deep(.vue3-easy-data-table__body tr td) {
  @apply py-2;
}

.og-data-table:deep(.vue3-easy-data-table__main) {
  @apply overflow-visible;
}

.og-data-table:deep(th:first-child),
.og-data-table:deep(td:first-child) {
  @apply pl-4;
}

.og-data-table:deep(td) {
  @apply py-2;
}

.og-data-table:deep(.select-items li) {
  @apply hover:bg-gray-200;
}

.og-data-table:deep(.pagination__rows-per-page) {
  @apply capitalize;
}

.og-data-table:deep(.vue3-easy-data-table__message) {
  @apply text-sm;
}

.og-data-table:deep(.sortable) {
  @apply pointer-events-none;
}

.og-data-table:deep(th.sortable .sortType-icon) {
  @apply hidden;
}

.og-data-table:deep(th) {
  @apply uppercase;
}

.og-data-table--has-total:deep(tr:last-child > td) {
  @apply font-semibold !border-b-0 border-double border-r-0 border-l-0;
}
</style>
