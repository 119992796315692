<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { DoubleMaterialityIroRiskAndOpportunityType } from '@/__generated__/types';
import { notEmpty } from '@/utils/helpers/notEmpty';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import { BadgeType } from '@/components/atoms/AtBadge/types';
import type { IroRiskAndOpportunity, Topic } from '../../types';
import {
  calculateMaterialityForRiskAndOpportunity,
  calculateTotalScoreForRiskAndOpportunity,
} from '../../utils';
import { useStore } from '../../store';
import OgTable from '../../OgTable.vue';

type RowItem = {
  _id: string;
  iroRiskAndOpportunity: IroRiskAndOpportunity;
  topic: Topic;
  likelihood: number | null;
  potentialMagnitude: number | null;
  totalScore: number | null;
  materiality: boolean | null;
};

const { t } = useI18n();
const store = useStore();
const { formatNumber } = useFormatNumber();

const headers = computed(() => [
  { text: t('Standard'), value: 'esrs', columnClasses: 'w-[200px]' },
  { text: t('Sustainability matter'), value: 'sustainabilityMatter' },
  { text: t('IRO'), value: 'iro' },
  { text: t('IRO type'), value: 'iroType' },
  { text: t('Likelihood'), value: 'likelihood', columnClasses: 'w-[150px]' },
  {
    text: t('Potential magnitude'),
    value: 'potentialMagnitude',
    columnClasses: 'w-[150px]',
  },
  { text: t('Total score'), value: 'totalScore', columnClasses: 'w-[150px]' },
  { text: t('Materiality'), value: 'materiality', columnClasses: 'w-[100px]' },
]);

const items = computed<RowItem[]>(() => {
  return store.value.iroRisksAndOpportunities
    .map((iroRiskAndOpportunity) => {
      const topic = store.value.topics.find(
        (item) => item._id === iroRiskAndOpportunity.topicId,
      )!;

      if (!topic.topic || !topic.subTopic) {
        return null;
      }

      const totalScore = calculateTotalScoreForRiskAndOpportunity(
        iroRiskAndOpportunity.likelihood,
        iroRiskAndOpportunity.potentialMagnitude,
      );
      const materiality = calculateMaterialityForRiskAndOpportunity(
        iroRiskAndOpportunity.likelihood,
        iroRiskAndOpportunity.potentialMagnitude,
      );

      return {
        _id: iroRiskAndOpportunity._id,
        topic,
        iroRiskAndOpportunity,
        likelihood: iroRiskAndOpportunity.likelihood,
        potentialMagnitude: iroRiskAndOpportunity.potentialMagnitude,
        totalScore,
        materiality,
      };
    })
    .filter((item) => notEmpty(item));
});
</script>

<template>
  <h3 class="text-lg font-medium mb-3">
    {{ t('Financial materiality') }}
  </h3>
  <OgTable
    :headers="headers"
    :items="items"
    :isRowRemovable="false"
    tableClass="!mb-16"
  >
    <template #item-esrs="row: RowItem">
      <p>{{ row.topic.esrs }} {{ t(row.topic.topic) }}</p>
    </template>
    <template #item-sustainabilityMatter="row: RowItem">
      <p>
        {{ t(row.topic.subSubTopic) || t(row.topic.subTopic) || '' }}
      </p>
    </template>
    <template #item-iro="row: RowItem">
      <p>
        {{ row.iroRiskAndOpportunity.riskAndOpportunity }}
      </p>
    </template>
    <template #item-iroType="row: RowItem">
      <p>
        <template
          v-if="
            row.iroRiskAndOpportunity.type ===
            DoubleMaterialityIroRiskAndOpportunityType.OPPORTUNITY
          "
        >
          {{ t('Opportunity') }}
        </template>
        <template
          v-else-if="
            row.iroRiskAndOpportunity.type ===
            DoubleMaterialityIroRiskAndOpportunityType.RISK
          "
        >
          {{ t('Risk') }}
        </template>
      </p>
    </template>
    <template #item-likelihood="row: RowItem">
      <p>
        {{ row.likelihood ?? t('n/a') }}
      </p>
    </template>
    <template #item-potentialMagnitude="row: RowItem">
      <p>
        {{ row.potentialMagnitude ?? t('n/a') }}
      </p>
    </template>
    <template #item-totalScore="row: RowItem">
      <p>
        {{
          typeof row.totalScore !== 'number'
            ? t('n/a')
            : formatNumber(row.totalScore, 0, 2)
        }}
      </p>
    </template>
    <template #item-materiality="row: RowItem">
      <AtBadge
        :type="
          row.materiality === true
            ? BadgeType.SUCCESS
            : row.materiality === false
              ? BadgeType.WARNING
              : BadgeType.DEFAULT
        "
      >
        {{
          row.materiality === true
            ? t('Material')
            : row.materiality === false
              ? t('Not material')
              : t('Start')
        }}
      </AtBadge>
    </template>
  </OgTable>
</template>
