<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { RestrictionsFeaturesEnum, UserRole } from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import OgRestrictionsLanding from '@/components/organisms/OgRestrictionsLanding.vue';
import OgDoubleMaterialityLayout from './OgDoubleMaterialityLayout.vue';

const { t, locale } = useI18n();

const { restrictions, loading: loadingRestrictions } = useRestrictions();
const { currentUser } = useCurrentUser();

const isRestricted = computed(() => {
  if (
    restrictions.value?.restrictedFeatures.includes(
      RestrictionsFeaturesEnum.DOUBLE_MATERIALITY,
    ) &&
    !loadingRestrictions.value
  ) {
    return true;
  }

  if (currentUser.value?.role === UserRole.SUPERADMIN) {
    return false;
  }

  return !currentUser.value?.assignments.doubleMateriality;
});
</script>

<template>
  <template v-if="isRestricted">
    <OgRestrictionsLanding backToWorkspace>
      <template #pageTitle>
        {{ t('Double Materiality Assessment') }}
      </template>
      <template #title>
        {{
          t(
            'Engage your team and stakeholders to jointly decide on what really matters to your company',
          )
        }}
      </template>
      <template #text>
        <p class="max-w-3xl">
          {{
            t(
              'Undertake an assessment in compliance with European Sustainability Reporting Standards. You can involve as many teams as you need through our extensive collaboration functionalities.',
            )
          }}
        </p>
      </template>
      <template #image>
        <img
          v-if="locale === 'de'"
          alt="Double Materiality"
          src="@/assets/images/double-materiality-en.png"
        />
        <img
          v-else
          alt="Double Materiality"
          src="@/assets/images/double-materiality-en.png"
        />
      </template>
    </OgRestrictionsLanding>
  </template>
  <OgDoubleMaterialityLayout v-else />
</template>
