import { gql } from '@apollo/client/core';

export default gql`
  query TmDashboardChartSelection(
    $dataPointTypeNames: [String!]!
    $type: String!
    $allEntities: Boolean
  ) {
    getEsgDataPointsLength
    getAdminDataPointsByType(
      dataPointTypeNames: $dataPointTypeNames
      allEntities: $allEntities
    ) {
      _id
      value
      from
      to
      entity {
        _id
        name
      }
      location {
        _id
        name
      }
      dataPointType {
        _id
        name
        friendlyName
        valueUnit
        valueUnitDivisor
        valueDataType
        summarizingMethod
        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
        }
        reportingFrameworks {
          _id
          framework
          groups
        }
      }
    }
    getCurrentUserEntities {
      _id
      name
      entitySettings {
        _id
        companyName
        dashboardPinnedDataPointTypes {
          _id
          dataPointType {
            friendlyName
            name
            _id
            valueUnit
            valueDataType
            summarizingMethod
            _id
          }
        }
      }
      kpis {
        _id
        startingDate
        comparisonValue
        comparisonValueType
        comparisonOperator
        dueDate
        dataPointType {
          _id
        }
      }
      locations {
        _id
        name
      }
    }
    getCurrentEntity {
      _id
      entitySettings {
        _id
        companyName
        dashboardPinnedDataPointTypes {
          _id
          dataPointType {
            friendlyName
            name
            _id
          }
        }
      }
      kpis {
        _id
        startingDate
        comparisonValue
        comparisonValueType
        comparisonOperator
        dueDate
        dataPointType {
          _id
        }
      }
      locations {
        _id
        name
      }
    }
    getPinnedDataPointsAdmin(type: $type) {
      value
      from
      to
      entity {
        _id
        name
      }
      location {
        _id
        name
      }
      dataPointType {
        _id
        name
        friendlyName
        valueUnit
        valueDataType
        summarizingMethod
        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
        }
        reportingFrameworks {
          _id
          framework
          groups
          category
          subcategory
        }
      }
    }
  }
`;
