// TODO: rework this component to work with arrays instead of objects

<script setup lang="ts">
import { computed, type Component, type HTMLAttributes } from 'vue';
import { Listbox, ListboxLabel } from '@headlessui/vue';
import { Float } from '@headlessui-float/vue';
import { useI18n } from 'vue-i18n';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import type { ErrorObject } from '@vuelidate/core';
import { sortOptions } from '@/utils/helpers/sortOptions';
import AtTooltipIcon, {
  type TTooltipOptions,
} from '@/components/atoms/AtTooltipIcon.vue';
import unwrapVuelidateErrorMessages from '@/utils/helpers/unwrapVuelidateErrorMessages';
import MlRadio from '../MlRadio.vue';
import AtSelectButton from './AtSelectButton.vue';
import MlSelectOptions from './MlSelectOptions.vue';

interface Props {
  label?: string | null;
  modelValue?: string | string[];
  options?: Record<string, string>;
  disabledOptions?: string[];
  disabledOptionText?: string; // text that displays on hover
  description?: string;
  sortedOptions?: boolean; // whether options are already sorted or should be auto-sorted here
  multiple?: boolean;
  disabled?: boolean;
  allowSelectNone?: boolean;
  errors?: string[] | ErrorObject[];
  noneLabel?: string;
  wrapperClass?: HTMLAttributes['class'];
  placeholder?: string;
  tooltip?: TTooltipOptions;
  nestedOptions?: Record<string, { label: string; value: string }[]>;
  nestedOptionsModelValue?: Record<string, string>;
  groupedOptions?: {
    label: string;
    options: { label: string; value: string }[];
  }[];
  optionIcons?: Record<string, Component>;
  usePortal?: boolean;
  truncate?: boolean;
  variant?: 'dropdown' | 'inline';
}

const props = withDefaults(defineProps<Props>(), {
  options: () => ({}),
  disabledOptions: () => [],
  disabledOptionText: '',
  label: '',
  modelValue: undefined,
  noneLabel: undefined,
  wrapperClass: '',
  description: '',
  placeholder: 'Please select',
  tooltip: undefined,
  nestedOptions: undefined,
  groupedOptions: undefined,
  nestedOptionsModelValue: undefined,
  optionIcons: () => ({}),
  errors: () => [],
  usePortal: false,
  variant: 'dropdown',
});
const emit = defineEmits([
  'update:modelValue',
  'update:nestedOptionsModelValue',
  'heightChanged',
]);
const { t } = useI18n();

const sortedOptions = computed(() => {
  const options = Object.entries(props.options);
  return new Map(
    props.sortedOptions
      ? options
      : sortOptions(options.map((v) => [v[0], t(v[1] || '')])),
  );
});

const selectedValueDisplay = computed(() => {
  if (props.groupedOptions) {
    return props.groupedOptions
      .map((group) => {
        const selectedValue = group.options.find(
          (option) => option.value === props.modelValue,
        )?.label;

        return selectedValue || '';
      })
      .filter((s) => s)
      .join(', ');
  }
  if (
    props.nestedOptionsModelValue &&
    Object.keys(props.nestedOptionsModelValue).length > 0
  ) {
    return Object.entries(props.nestedOptionsModelValue)
      .map(
        ([optionKey, nestedOptionKey]) =>
          `${t(props.options[optionKey] ?? '')} ${t(props.nestedOptions?.[optionKey]?.find((item) => item.value === nestedOptionKey)?.label ?? '')}`,
      )
      .join(', ');
  }

  if (!props.multiple) {
    return t(props.options[props.modelValue as string] || '');
  }

  return ((props.modelValue as string[]) || [])
    .reduce((acc, val) => {
      const option = props.options[val];
      if (option) {
        acc.push(t(option));
      }
      return acc;
    }, [] as string[])
    .join(', ');
});

defineOptions({ inheritAttrs: false });
</script>

<template>
  <div :class="wrapperClass">
    <div v-if="props.variant === 'inline' && !props.multiple" class="flex">
      <MlRadio
        :modelValue="props.modelValue"
        :label="props.label === null ? undefined : props.label"
        :disabled="props.disabled"
        :options="
          Array.from(sortedOptions, ([name, value]) => ({
            name,
            value: name,
            label: value,
          }))
        "
        @update:modelValue="emit('update:modelValue', $event)"
      />
    </div>
    <div v-else class="relative">
      <Listbox
        v-slot="{ open: listOpen }"
        :disabled="props.disabled"
        :multiple="props.multiple"
        :modelValue="props.modelValue"
        @update:modelValue="emit('update:modelValue', $event)"
      >
        <ListboxLabel
          v-if="label"
          class="mb-1 flex text-sm font-medium text-gray-700"
        >
          {{ t(label) }}
          <AtTooltipIcon
            v-if="props.tooltip"
            class="ml-1"
            :tooltip="props.tooltip"
          />
        </ListboxLabel>
        <p v-if="description" class="mb-4 text-sm text-gray-700">
          {{ t(description) }}
        </p>

        <Float v-if="usePortal" portal adaptiveWidth placement="bottom" flip>
          <AtSelectButton
            :disabled="!!disabled"
            :hasErrors="errors.length > 0"
            :icon="optionIcons[String(props.modelValue)]"
            :placeholder="t(placeholder)"
            :value="selectedValueDisplay"
            v-bind="$attrs"
            :truncate="props.truncate"
          />

          <MlSelectOptions
            :allowSelectNone="!!allowSelectNone"
            :disabledOptionText="disabledOptionText"
            :disabledOptions="disabledOptions"
            :isOpen="listOpen"
            :multiple="!!multiple"
            :nestedOptions="nestedOptions"
            :nestedOptionsModelValue="nestedOptionsModelValue"
            :noneLabel="noneLabel"
            :optionIcons="optionIcons"
            :options="sortedOptions"
            :usePortal="usePortal"
            @heightChanged="emit('heightChanged', $event)"
            @update:nestedOptionsModelValue="
              emit('update:nestedOptionsModelValue', $event)
            "
          />
        </Float>
        <template v-else>
          <AtSelectButton
            :disabled="!!disabled"
            :hasErrors="errors.length > 0"
            :icon="optionIcons[String(props.modelValue)]"
            :placeholder="t(placeholder)"
            :value="selectedValueDisplay"
            v-bind="$attrs"
            :truncate="props.truncate"
          />

          <transition
            leaveActiveClass="transition duration-100 ease-in"
            leaveFromClass="opacity-100"
            leaveToClass="opacity-0"
          >
            <MlSelectOptions
              :allowSelectNone="!!allowSelectNone"
              :disabledOptionText="disabledOptionText"
              :disabledOptions="disabledOptions"
              :isOpen="listOpen"
              :multiple="!!multiple"
              :nestedOptions="nestedOptions"
              :groupedOptions="groupedOptions"
              :noneLabel="noneLabel"
              :optionIcons="optionIcons"
              :options="sortedOptions"
              :usePortal="usePortal"
              @heightChanged="emit('heightChanged', $event)"
            />
          </transition>
        </template>
      </Listbox>
    </div>
    <p
      v-for="error in unwrapVuelidateErrorMessages(props.errors)"
      :key="error"
      class="mt-1 text-xs text-error"
    >
      <span class="flex">
        <InformationCircleIcon class="mr-1 w-3" />
        {{ t(error) }}
      </span>
    </p>
  </div>
</template>
