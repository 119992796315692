<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { XIcon } from '@heroicons/vue/outline';
import AtTopBar from '@/components/atoms/AtTopBar.vue';
import AtIconButton from '../atoms/AtIconButton.vue';

const { t } = useI18n();

type TProps = {
  isShown: boolean;
  buttonText?: string;
  buttonClass?: string;
  description?: string;
  closeButtonClass?: string;
  closeIconClass?: string;
};

const props = withDefaults(defineProps<TProps>(), {
  isShown: false,
  closeButtonClass: 'bg-primary absolute right-3',
  closeIconClass: 'h-6 text-white',
  description: '',
  buttonText: '',
  buttonClass: 'font-bold text-white underline',
});

const emit = defineEmits(['handleClick', 'close']);
</script>

<template>
  <AtTopBar
    :isShown="props.isShown"
    class="cursor-pointer bg-primary p-3 text-white"
    @handleClick="emit('handleClick')"
  >
    <p class="text-sm">
      {{ `${t(props.description)} ` }}
      <button :class="props.buttonClass" type="submit">
        {{ t(props.buttonText) }}
      </button>
    </p>
    <AtIconButton
      :icon="XIcon"
      :title="t('Close')"
      :class="props.closeButtonClass"
      @click.stop="emit('close')"
    />
  </AtTopBar>
</template>
