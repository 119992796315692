import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  CreateEntityKpiMutation,
  CreateEntityKpiMutationVariables,
} from '@/__generated__/types';

export const CREATE_ENTITY_KPI = gql`
  mutation CreateEntityKPI($createEntityKPIInputs: [CreateEntityKPIInput!]!) {
    createEntityKPI(entityKPIs: $createEntityKPIInputs) {
      _id
      description
      startingDate
      dueDate
      comparisonValue
      comparisonOperator
      comparisonValueType
      status

      dataPointType {
        _id
        name
        friendlyName
        question
      }

      user {
        _id
        firstName
        lastName
        email
      }

      targets {
        _id
        name
        number

        goal {
          _id
          name
          number
          description
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  CreateEntityKpiMutation,
  CreateEntityKpiMutationVariables
>;

const useCreateEntityKPIMutation = (options?: TOptions) =>
  useMutation<CreateEntityKpiMutation, CreateEntityKpiMutationVariables>(
    CREATE_ENTITY_KPI,
    options,
  );

export default useCreateEntityKPIMutation;
