<script setup lang="ts">
import useVuelidate from '@vuelidate/core';
import { minLength, required, requiredIf } from '@vuelidate/validators';
import { computed, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { PlusIcon, XIcon } from '@heroicons/vue/outline';
import { notify } from '@kyvg/vue3-notification';
import { useQuery } from '@vue/apollo-composable';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import {
  type UpsertDataPointTypeInput,
  type OgCustomDataPointTypeFormQuery,
  DataPointTypeRefreshIntervalEnum,
  DataPointTypeValueUnitEnum,
  ReportingFrameworkEnum,
  ValueDataTypeEnum,
} from '@/__generated__/types';
import { sortArrayByProperty } from '@/utils/helpers/sortArrayByProperty';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import useUpsertDataPointTypeMutation from '@/api/mutations/DataPointType/upsertDataPointType.mutation';
import MlSelectLevel3 from '@/components/molecules/MlSelectLevel3/MlSelectLevel3.vue';
import {
  CreateDatapointTypeReportingFrameworkEnum,
  type Crud,
  type CustomDataPointTypeFormData,
} from '../types';
import TargetQuestionTooltip from './tooltip/targetQuestion.tooltip.vue';
import OG_CUSTOM_DATATPOINTTYPE_FORM from './OgCustomDataPointTypeForm.query';

const { t } = useI18n();

const props = defineProps<Props>();
type Props = {
  crud: Crud;
  category: string;
  subcategory: string;
  locationId: string;
  dataPointTypeId?: string;
};

const emit = defineEmits<Emits>();
type Emits = {
  (e: 'close'): void;
  (e: 'created', isRepeated: boolean): void;
  (e: 'updated', isRepeated: boolean): void;
};

const queryVariables = computed(() => ({
  dataPointTypeId: props.dataPointTypeId,
  isUpdate: props.crud === 'update',
}));
const { result } = useQuery<OgCustomDataPointTypeFormQuery>(
  OG_CUSTOM_DATATPOINTTYPE_FORM,
  queryVariables,
);

const { mutate: upsertCustomDataPointType, loading } =
  useUpsertDataPointTypeMutation();

const initialCustomDataPointTypeFormData: CustomDataPointTypeFormData = {
  title: '',
  question: '',
  questionHelp: '',
  framework: '',
  frameworkGroup: '',
  emissionFactors: [],
  emissionFactorSubcategory: '',
  valueUnit: undefined,
  allowUploadProof: true,
  valueDataType: ValueDataTypeEnum.TEXT_LONG,
  valueDataTypeOptions: ['', ''],
  refreshInterval: DataPointTypeRefreshIntervalEnum.MONTHLY,
  isGlobal: false,
};

const formData = ref({
  ...initialCustomDataPointTypeFormData,
  emissionFactors: [...initialCustomDataPointTypeFormData.emissionFactors],
  valueDataTypeOptions: [
    ...initialCustomDataPointTypeFormData.valueDataTypeOptions,
  ],
});
const emissionFactorYears = ref<string[]>([]);
watch(
  () => emissionFactorYears.value,
  (arr: string[], oldArr: string[]) => {
    if (arr.length > oldArr.length) {
      const yearAdded = arr.find((val) => !oldArr.includes(val));
      const existingCustomEmissionFactor =
        customDataPointType.value?.emissionFactors.find(
          (ef) => ef.year.toString() === yearAdded,
        );
      const newEmissionFactor = {
        _id: existingCustomEmissionFactor?._id,
        year: existingCustomEmissionFactor?.year ?? Number(yearAdded),
        factor: existingCustomEmissionFactor?.factor ?? 0,
      };
      formData.value.emissionFactors.push(newEmissionFactor);
      if (newEmissionFactor._id) {
        // prevent duplicate on first mount
        const emissionFactors = formData.value.emissionFactors.filter(
          (ef) => ef._id === newEmissionFactor._id,
        );
        if (emissionFactors.length > 1) {
          const idx = formData.value.emissionFactors.findIndex(
            (ef) => ef._id === newEmissionFactor._id,
          );
          formData.value.emissionFactors.splice(idx, 1);
        }
      }
    } else {
      const yearRemoved = oldArr.find((val) => !arr.includes(val));
      const idx = formData.value.emissionFactors.findIndex(
        (ef) => yearRemoved === ef.year.toString(),
      );
      formData.value.emissionFactors.splice(idx, 1);
    }
  },
);

const customDataPointType = computed(() => result.value?.getDataPointType);
watchEffect(() => {
  if (customDataPointType.value) {
    emissionFactorYears.value = customDataPointType.value.emissionFactors.map(
      (ef) => ef.year.toString(),
    );
    formData.value = {
      title: customDataPointType.value.friendlyName,
      question: customDataPointType.value.question,
      questionHelp: customDataPointType.value.questionHelp,
      framework:
        customDataPointType.value.reportingFrameworks[0].framework ?? '',
      frameworkGroup:
        customDataPointType.value.reportingFrameworks[0].groups[0] ?? '',
      emissionFactors: customDataPointType.value.emissionFactors.map(
        ({ _id, factor, year }) => ({ _id, factor, year }),
      ),
      emissionFactorSubcategory:
        customDataPointType.value.emissionSubcategory ?? '',
      valueUnit: customDataPointType.value.valueUnit
        ? customDataPointType.value.valueUnit
        : undefined,
      allowUploadProof: customDataPointType.value.allowUploadProof,
      valueDataType: customDataPointType.value.valueDataType,
      valueDataTypeOptions: customDataPointType.value.valueDataTypeOptions
        ? Object.values(customDataPointType.value.valueDataTypeOptions)
        : ['', ''],
      refreshInterval: customDataPointType.value.refreshInterval,
      isGlobal: false, // TODO: store isGlobale on dpt
    };
  }
});

const emissionFactors = computed(
  () =>
    uniqBy(
      result.value?.getEmissionsDataPointTypes,
      (emissionDpt) =>
        `${emissionDpt.emissionType}${emissionDpt.emissionCategory}${emissionDpt.emissionSubcategory}`,
    ) ?? [],
);
const emissionsFactorsByCategory = computed(() =>
  groupBy(emissionFactors.value, 'emissionCategory'),
);
const emissionsFactorCategories = computed(() =>
  Object.keys(emissionsFactorsByCategory.value),
);
const emissionFactorCategoryOptions = computed(() => {
  const preSortArray = emissionsFactorCategories.value.map((category) => {
    const children = emissionFactors.value
      .filter(
        (dpt) => dpt.emissionCategory === category && dpt.emissionSubcategory,
      )
      .map((emissionFactor) => ({
        id: emissionFactor.emissionSubcategory ?? '',
        title: emissionFactor.emissionSubcategory ?? '',
      }));
    const sortedChildren = sortArrayByProperty(children, 'title');
    return {
      id: category,
      title: `${t(emissionFactors.value.find((dpt) => dpt.emissionCategory === category)?.emissionType ?? '')}: ${t(category)}`,
      children: sortedChildren,
    };
  });

  return sortArrayByProperty(
    preSortArray.filter((item) => item.id.length),
    'title',
  ); // filter preSortArray to remove empty scope (e.g., "Scope 2: ")
});

let emissionFactorYearOptions = {};
for (let i = new Date().getFullYear(); i >= 2015; i -= 1) {
  emissionFactorYearOptions = {
    [i.toString()]: i.toString(),
    ...emissionFactorYearOptions,
  };
}
const v$ = useVuelidate(
  {
    title: { required },
    question: { required },
    refreshInterval: { required },
    valueDataType: { required },
    valueDataTypeOptions: {
      required: requiredIf(
        [ValueDataTypeEnum.MULTIPLE_CHOICE, ValueDataTypeEnum.CHOICE].includes(
          formData.value.valueDataType,
        ),
      ),
      minLength: minLength(2),
    },
    emissionFactorSubcategory: {
      required: requiredIf(() => !!formData.value.emissionFactors.length),
    },
    emissionFactors: {
      required: requiredIf(() => !!formData.value.emissionFactorSubcategory),
    },
  },
  formData,
);

const frameworkOptions = computed(() => ({
  [ReportingFrameworkEnum.GRI]: t('GRI'),
  [ReportingFrameworkEnum.ESRS]: t('ESRS (CSRD)'),
  [ReportingFrameworkEnum.DNK]: t('DNK'),
  [ReportingFrameworkEnum.TCFD]: t('TCFD'),
  [ReportingFrameworkEnum.GHG]: t('GHG'),
  [CreateDatapointTypeReportingFrameworkEnum.CERTIFICATION_DISCLOSURE]: t(
    'Certification/disclosure requirements (CDP, EcoVadis, EMAS)',
  ),
  [ReportingFrameworkEnum.CUSTOMER_REQUIREMENT]: t('Customer requirement'),
  [ReportingFrameworkEnum.INTERNAL_REQUIREMENT]: t('Internal requirement'),
  [ReportingFrameworkEnum.OTHER]: t('Other'),
}));

const unitOptions = computed(() => ({
  [ValueDataTypeEnum.TEXT]: t('Text'),
  [ValueDataTypeEnum.TEXT_LONG]: t('Long text'),
  [ValueDataTypeEnum.TEXT_SPLIT]: t('Text split'),
  [ValueDataTypeEnum.NUMERIC]: t('Numeric'),
  [ValueDataTypeEnum.NUMERIC_SPLIT]: t('Numeric split'),
  [ValueDataTypeEnum.MULTIPLE_CHOICE]: t('Multiple Choice'),
  [ValueDataTypeEnum.CHOICE]: t('Choice'),
  [ValueDataTypeEnum.BOOLEAN]: t('Yes / No'),
}));

const numericUnitOptions = computed(() => ({
  [DataPointTypeValueUnitEnum.NUMBER]: t('Number'),
  [DataPointTypeValueUnitEnum.TONS]: t('Ton (t)'),
  [DataPointTypeValueUnitEnum.HOURS]: t('Hours'),
  [DataPointTypeValueUnitEnum.LITER]: t('Liter (l)'),
  [DataPointTypeValueUnitEnum.KILOMETER]: t('Kilometer (km)'),
  [DataPointTypeValueUnitEnum.CUBIC_METER]: t('Cubic Meter'),
  [DataPointTypeValueUnitEnum.KILOGRAM]: t('Kilogram (kg)'),
  // [DataPointTypeValueUnitEnum.TR]: t('Tons of refrigeration (tr)'),
  [DataPointTypeValueUnitEnum.KILOWATT]: t('Kilowatt (kW)'),
  [DataPointTypeValueUnitEnum.KILOWATTHOURS]: t('Kilowatt Hours (kWh)'),
  [DataPointTypeValueUnitEnum.EUR]: t('Euro (€)'),
  [DataPointTypeValueUnitEnum.PRODUCTION_UNIT]: t('Production units'),
  [DataPointTypeValueUnitEnum.PERCENT]: t('Percentage (%)'),
}));

const refreshIntervalOptions = computed(() => ({
  [DataPointTypeRefreshIntervalEnum.ONCE]: t('once'),
  [DataPointTypeRefreshIntervalEnum.DAILY]: t('every day'),
  [DataPointTypeRefreshIntervalEnum.WEEKLY]: t('every week'),
  [DataPointTypeRefreshIntervalEnum.MONTHLY]: t('every month'),
  [DataPointTypeRefreshIntervalEnum.QUARTERLY]: t('every quarter'),
  [DataPointTypeRefreshIntervalEnum.HALF_YEARLY]: t('every 6 months'),
  [DataPointTypeRefreshIntervalEnum.YEARLY]: t('every year'),
}));

const unitNestedOptions = computed(() => ({
  [ValueDataTypeEnum.NUMERIC]: Object.entries(numericUnitOptions.value).map(
    ([value, label]) => ({ value, label }),
  ),
}));

const numericOptionSelected = ref('');

function resetNumericOption() {
  if (
    [
      ValueDataTypeEnum.TEXT_LONG,
      ValueDataTypeEnum.MULTIPLE_CHOICE,
      ValueDataTypeEnum.CHOICE,
    ].includes(formData.value.valueDataType)
  ) {
    numericOptionSelected.value = '';
    formData.value.valueUnit = undefined;
    formData.value.valueDataTypeOptions = ['', ''];
  }
}

const unitNestedOptionsValue = computed<Record<string, string>>(() => {
  const nestedOptionsValue: Record<string, string> = {};

  if (numericOptionSelected.value) {
    nestedOptionsValue[ValueDataTypeEnum.NUMERIC] = numericOptionSelected.value;
  }

  if (
    !numericOptionSelected.value &&
    customDataPointType.value?.valueUnit &&
    formData.value.valueDataType.includes(ValueDataTypeEnum.NUMERIC)
  ) {
    nestedOptionsValue[ValueDataTypeEnum.NUMERIC] =
      customDataPointType.value?.valueUnit;
  }

  return nestedOptionsValue;
});

function reset() {
  numericOptionSelected.value = '';
  Object.assign(formData.value, initialCustomDataPointTypeFormData);
  v$.value.$reset();
}

const isRepeating = ref(false);

async function handleUpsertCustomDataPointType(repeat: boolean) {
  isRepeating.value = repeat;
  v$.value.$touch();

  if (v$.value.$error) return;

  const calculateFrameworks = () => {
    if (
      formData.value.framework ===
      CreateDatapointTypeReportingFrameworkEnum.CERTIFICATION_DISCLOSURE
    ) {
      return [
        ReportingFrameworkEnum.CDP,
        ReportingFrameworkEnum.ECOVADIS,
        ReportingFrameworkEnum.EMAS,
        ReportingFrameworkEnum.CUSTOM,
      ];
    }
    if (!formData.value.framework) return [ReportingFrameworkEnum.CUSTOM];
    return [
      formData.value.framework,
      ReportingFrameworkEnum.CUSTOM,
    ] as ReportingFrameworkEnum[];
  };

  const valueUnit =
    formData.value.valueDataType === ValueDataTypeEnum.NUMERIC
      ? formData.value.valueUnit
      : undefined;

  const valueDataTypeOptions = formData.value.valueDataTypeOptions.filter(
    (option) => option.length,
  );

  const upsertDataPointTypeInput: UpsertDataPointTypeInput = {
    _id: customDataPointType.value?._id,
    category: props.category,
    subcategory: props.subcategory,
    locationId: props.locationId,
    frameworks: calculateFrameworks(),
    frameworkGroup: formData.value.frameworkGroup,
    emissionFactors: formData.value.emissionFactors,
    emissionFactorType: emissionFactors.value.find(
      (el) =>
        el.emissionSubcategory === formData.value.emissionFactorSubcategory,
    )?.emissionType,
    emissionFactorCategory: emissionFactors.value.find(
      (el) =>
        el.emissionSubcategory === formData.value.emissionFactorSubcategory,
    )?.emissionCategory,
    emissionFactorSubcategory: formData.value.emissionFactorSubcategory,
    valueUnit,
    valueDataTypeOptions,
    name: formData.value.title,
    isGlobal: formData.value.isGlobal,
    allowUploadProof: formData.value.allowUploadProof,
    friendlyName: formData.value.title,
    question: formData.value.question,
    questionHelp: formData.value.questionHelp,
    valueDataType: formData.value.valueDataType,
    refreshInterval: formData.value.refreshInterval,
  };

  try {
    await upsertCustomDataPointType(
      { upsertDataPointTypeInput },
      {
        update: (store) => {
          store.evict({
            id: 'ROOT_QUERY',
            fieldName: 'getMyDataPointRequests',
          });
        },
      },
    );
    emit('created', isRepeating.value);
    if (props.crud === 'update') {
      notify({
        type: 'success',
        text: t('Custom data point has been updated.'),
      });
    } else {
      notify({
        type: 'success',
        text: t('Custom data point has been created.'),
      });
    }
    reset();
  } catch {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
}

const allowEmissions = computed(
  () =>
    [ValueDataTypeEnum.NUMERIC, ValueDataTypeEnum.NUMERIC_SPLIT].includes(
      formData.value.valueDataType,
    ) && ['carbon_accounting'].includes(props.category),
);

function handleUnitChange(value?: string | string[]) {
  if (value && !Array.isArray(value)) {
    formData.value.valueDataType = value as ValueDataTypeEnum;
    numericOptionSelected.value = '';
    formData.value.valueUnit = undefined;
    formData.value.valueDataTypeOptions = ['', ''];
  }
}

function handleUnitNestedOptionsValueChange(value?: Record<string, string>) {
  if (typeof value?.[ValueDataTypeEnum.NUMERIC] !== 'undefined') {
    const newValue = value[
      ValueDataTypeEnum.NUMERIC
    ] as DataPointTypeValueUnitEnum;

    formData.value.valueDataType = ValueDataTypeEnum.NUMERIC;
    formData.value.valueUnit = newValue;
  }
  numericOptionSelected.value = value?.[ValueDataTypeEnum.NUMERIC] ?? '';
}
</script>

<template>
  <div>
    <div
      class="mt-2 flex grid-flow-row-dense grid-cols-12 flex-col gap-6 xl:grid"
    >
      <AtInput
        v-model.trim="formData.title"
        wrapperClass="col-span-8"
        :class="{ 'border-2 !border-error': v$.title.$error }"
        type="text"
        :placeholder="
          t('Give your data point a short, measurable and actionable title.')
        "
        :disabled="loading"
        :label="t('Title')"
      />
      <AtInput
        v-model.trim="formData.question"
        wrapperClass="col-span-8"
        :class="{ 'border-2 !border-error': v$.question.$error }"
        type="text"
        :placeholder="
          t('Write your data point as an easily understandable question.')
        "
        :disabled="loading"
        :label="t('Question/KPI')"
        :tooltip="TargetQuestionTooltip"
      />
      <MlTextarea
        v-model="formData.questionHelp"
        :initialContent="customDataPointType?.questionHelp"
        wrapperClass="col-span-8"
        type="text"
        :placeholder="t('Add more context to your data point.')"
        :disabled="loading"
        :label="t('Help text (optional)')"
      />
      <MlSelect
        v-model="formData.framework"
        wrapperClass="col-span-4"
        :options="frameworkOptions"
        :disabled="loading"
        sortedOptions
        allowSelectNone
        usePortal
        :label="t('Framework (optional)')"
      />

      <AtInput
        v-model.trim="formData.frameworkGroup"
        wrapperClass="col-span-4"
        type="text"
        :placeholder="t('Write framework group reference')"
        :disabled="loading"
        :label="t('Framework number (optional)')"
      />

      <div
        class="col-span-8 grid grid-cols-12 items-center gap-6 text-sm xl:grid"
      >
        <div
          class="dropdown-right dropdown col-span-6 w-full"
          :class="{ 'dropdown-open': false }"
        >
          <MlSelect
            :modelValue="formData.valueDataType"
            :class="{ 'border-2 !border-error': v$.valueDataType.$error }"
            :options="unitOptions"
            :disabled="loading"
            :label="t('Unit')"
            :nestedOptions="unitNestedOptions"
            :nestedOptionsModelValue="unitNestedOptionsValue"
            tabindex="0"
            usePortal
            @click.stop="resetNumericOption"
            @update:modelValue="handleUnitChange"
            @update:nestedOptionsModelValue="handleUnitNestedOptionsValueChange"
          />
        </div>
        <MlSelect
          v-model="formData.refreshInterval"
          wrapperClass="col-span-6"
          :label="t('Refresh interval')"
          :options="refreshIntervalOptions"
          sortedOptions
          usePortal
          :disabled="loading"
          :class="{ 'border-2 !border-error': v$.refreshInterval.$error }"
        />
        <div
          v-if="allowEmissions"
          class="col-span-12 grid grid-cols-12 gap-x-6 items-end"
        >
          <div class="col-span-12 block my-3 text-sm font-medium text-gray-700">
            {{ t('Add emission factor for this data point (optional):') }}
          </div>

          <MlSelectLevel3
            v-if="allowEmissions"
            v-model="formData.emissionFactorSubcategory"
            wrapperClass="lg:col-span-6 col-span-12"
            :placeholder="t('Select Category')"
            :label="t('Category')"
            size="lg"
            :items="emissionFactorCategoryOptions"
            :class="{
              'border-2 !border-error': v$.emissionFactorSubcategory.$error,
            }"
            :emptyItemsMessage="t('There is no data.')"
          />
          <MlSelect
            v-if="allowEmissions"
            v-model="emissionFactorYears"
            wrapperClass="lg:col-span-6 col-span-12"
            :label="t('Year(s)')"
            :options="emissionFactorYearOptions"
            :placeholder="t('Select Year')"
            multiple
            truncate
            :disabled="loading"
            :class="{ 'border-2 !border-error': v$.refreshInterval.$error }"
          />

          <div v-if="allowEmissions" class="lg:col-span-8 col-span-12 mt-3">
            <div
              v-if="formData.emissionFactors.length"
              class="mb-1 block text-sm font-medium text-gray-700"
            >
              {{ t('Factor(s)') }}
            </div>
            <div
              v-for="ef in formData.emissionFactors.sort((a, b) =>
                a.year > b.year ? -1 : 1,
              )"
              :key="ef.year"
              class="flex items-center mb-2"
            >
              <span class="block text-sm text-gray-700 mr-6">{{
                `${ef.year.toString()}: `
              }}</span>
              <AtInput
                v-model.trim="ef.factor"
                wrapperClass="w-full"
                unit="Units (tCO2eq/x)"
                type="emissionFactor"
                :placeholder="t('Enter')"
                :disabled="loading"
              >
                <template #unit>
                  <span class="truncate">
                    {{ t('Units (tCO2eq/x)') }}
                  </span>
                </template>
              </AtInput>
            </div>
          </div>
        </div>
        <AtCheckbox
          wrapperClass="col-span-12 lg: col-span-6 mt-5"
          :label="t('Add data point to all projects')"
          :checked="formData.isGlobal"
          @toggleCheckbox="formData.isGlobal = !formData.isGlobal"
        />

        <div
          v-if="
            [
              ValueDataTypeEnum.MULTIPLE_CHOICE,
              ValueDataTypeEnum.CHOICE,
            ].includes(formData.valueDataType)
          "
          class="col-span-12"
        >
          <div class="w-1/2">
            <div
              v-for="(_, index) in formData.valueDataTypeOptions"
              :key="index"
              class="flex w-full"
            >
              <AtInput
                v-model="formData.valueDataTypeOptions[index]"
                :class="{
                  'border-2 border-error': v$.valueDataTypeOptions.$error,
                }"
                wrapperClass="w-4/5"
                :disabled="loading"
                :label="t('Option {number}', { number: index + 1 })"
              />
              <AtIconButton
                v-if="formData.valueDataTypeOptions.length > 2"
                :icon="XIcon"
                :title="t('Close')"
                class="ml-2 mt-6 text-gray-500"
                @click.stop="formData.valueDataTypeOptions.splice(index, 1)"
              />
            </div>
            <AtButton
              class="mt-2 rounded border-2 border-primary text-primary"
              variant="outline"
              :icon="PlusIcon"
              @click.stop="
                formData.valueDataTypeOptions[
                  formData.valueDataTypeOptions.length
                ] = ''
              "
            >
              {{ t('Add Option') }}
            </AtButton>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-20 flex justify-between">
      <AtButton variant="text" @click="emit('close')">
        {{ t('Cancel') }}
      </AtButton>

      <div class="ml-auto">
        <AtButton
          v-if="props.crud === 'create'"
          variant="text"
          :disabled="v$.$error || loading"
          :loading="loading && isRepeating"
          @click="handleUpsertCustomDataPointType(true)"
        >
          {{ t('Finish and create next') }}
        </AtButton>
        <AtButton
          class="ml-4"
          :disabled="v$.$error || loading"
          :loading="loading && !isRepeating"
          @click="handleUpsertCustomDataPointType(false)"
        >
          <span v-if="props.crud === 'update'">
            {{ t('Update') }}
          </span>
          <span v-else>
            {{ t('Create') }}
          </span>
        </AtButton>
      </div>
    </div>
  </div>
</template>
