type TUser = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

export const getUserName = (user: TUser | null | undefined) => {
  if (!user) return '';

  return user.firstName || user.lastName
    ? `${user.firstName} ${user.lastName}`.trim()
    : user.email || '';
};
