<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { CustomQuestionnaireStatus } from '@/__generated__/types';
import useStartCustomQuestionnaireFileProcessingMutation from '@/api/mutations/CustomQuestionnaire/startFileProcessing.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInfoBox from '@/components/atoms/AtInfoBox/AtInfoBox.vue';
import { InfoBoxType } from '@/components/atoms/AtInfoBox/types';
import AtLoader from '@/components/atoms/AtLoader/AtLoader.vue';
import type { CustomQuestionnaire } from '../../types';
import OgQuestionnaireName from './OgQuestionnaireName/OgQuestionnaireName.vue';
import OgQuestionnaireFileUpload from './OgQuestionnaireFileUpload.vue';

type Props = {
  isLoading: boolean;
  customQuestionnaire: CustomQuestionnaire | null;
};

const props = defineProps<Props>();

const { t } = useI18n();
const router = useRouter();

const showNameSettings = ref(false);

const { mutate } = useStartCustomQuestionnaireFileProcessingMutation();

watch(
  () => props.customQuestionnaire?.status ?? null,
  (newStatusValue) => {
    if (newStatusValue === CustomQuestionnaireStatus.EDIT_QUESTIONS) {
      router.push({
        // Check mapping page is temporarily disabled.
        // name: 'projectsCustomQuestionnaireCheckMapping',
        name: 'projectsCustomQuestionnaireEditQuestionnaire',
      });
    }
  },
);

const canGoNext = computed(() =>
  props.customQuestionnaire?.status
    ? ![
        CustomQuestionnaireStatus.UPLOAD,
        CustomQuestionnaireStatus.AI_PROCESSING,
        CustomQuestionnaireStatus.AI_FAILED,
      ].includes(props.customQuestionnaire?.status)
    : false,
);

function handleFileUploaded() {
  if (props.customQuestionnaire) {
    if (props.customQuestionnaire.status === CustomQuestionnaireStatus.UPLOAD) {
      mutate(
        {
          customQuestionnaireId: props.customQuestionnaire._id,
        },
        {
          update: (store) => {
            store.evict({
              fieldName: 'getCustomQuestionnaire',
            });
          },
        },
      );
    }
  }
}

function handleNextClick() {
  showNameSettings.value = true;
}

function handleNameCancel() {
  showNameSettings.value = false;
}

function handleNameSaved() {
  router.push({
    // Check mapping page is temporarily disabled.
    // name: 'projectsCustomQuestionnaireCheckMapping',
    name: 'projectsCustomQuestionnaireEditQuestionnaire',
  });
}
</script>

<template>
  <div class="">
    <template v-if="customQuestionnaire">
      <OgQuestionnaireFileUpload
        v-if="customQuestionnaire.status === CustomQuestionnaireStatus.UPLOAD"
        :customQuestionnaireId="customQuestionnaire._id"
        @uploaded="handleFileUploaded"
      />
      <div
        v-else-if="
          customQuestionnaire.status === CustomQuestionnaireStatus.AI_PROCESSING
        "
        class="w-full h-64 relative"
      >
        <AtLoader size="xl" withOverlay />
      </div>
      <AtInfoBox
        v-else-if="
          customQuestionnaire.status === CustomQuestionnaireStatus.AI_FAILED
        "
        :type="InfoBoxType.Warning"
        :content="t('Processing file failed!')"
      >
        <AtButton type="button" variant="outline" @click="handleFileUploaded">
          {{ t('Retry') }}
        </AtButton>
      </AtInfoBox>
    </template>
  </div>

  <div class="flex items-center justify-between gap-4 pb-8">
    <AtButton type="button" variant="outline">
      {{ t('Previous') }}
    </AtButton>
    <AtButton type="button" :disabled="!canGoNext" @click="handleNextClick">
      {{ t('Next') }}
    </AtButton>
  </div>

  <OgQuestionnaireName
    v-if="customQuestionnaire && showNameSettings"
    :customQuestionnaireId="customQuestionnaire._id"
    @saved="handleNameSaved"
    @cancel="handleNameCancel"
  />
</template>
