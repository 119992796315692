<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ArchiveIcon from '@heroicons/vue/outline/ArchiveIcon';
import CheckIcon from '@heroicons/vue/outline/CheckIcon';
import CogIcon from '@heroicons/vue/outline/CogIcon';
import DotsHorizontalIcon from '@heroicons/vue/outline/DotsHorizontalIcon';
import dayjs from '@/lib/dayjs/config';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import MlDatePicker from '@/components/molecules/MlDatePicker.vue';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';

const props = defineProps<{
  id: string;
  period: [string, string];
  deadline: string;
  isCompleted: boolean;
  existingReportingPeriods: { period: [string, string]; id: string }[];
  periodError: string | null;
  deadlineError: string | null;
}>();

const emit = defineEmits<{
  'update:period': [[string, string]];
  'update:deadline': [string];
  delete: [string];
  complete: [string];
}>();

const { t } = useI18n();
const { confirmViaDialog } = useConfirmViaDialog();

const periodDisabledDates = computed(
  () => (date: Date) =>
    props.existingReportingPeriods.some((reportingPeriod) => {
      if (reportingPeriod.id === props.id) {
        return false;
      }

      return dayjs(date).isBetween(
        dayjs(reportingPeriod.period[0]),
        dayjs(reportingPeriod.period[1]),
        'day',
        '[]',
      );
    }),
);

const deadlineMinDate = computed(() =>
  dayjs(props.period[1]).add(1, 'day').toDate(),
);

async function handleDeleteClick() {
  const isConfirmed = await confirmViaDialog({
    title: t('Are you sure you want to delete this reporting period?'),
    text: t(
      'Deleting a reporting period will delete all associated datapoint requests. Deleted data cannot be restored by Codio.',
    ),
    confirmLabel: t('Yes, delete'),
    confirmButtonVariant: 'destructive',
  });

  if (isConfirmed) {
    emit('delete', props.id);
  }
}

async function handleMarkCompletedClick() {
  const isConfirmed = await confirmViaDialog({
    title: t(
      'Are you sure you want to mark this reporting period as completed?',
    ),
    text: `<span class="block mb-4">${t('Marking a reporting period as completed means that it will no longer be possible to enter data in all your open questions and they will be counted as completed, but left blank for your reporting (including in results page and any of your exports).')}</span><span class="block">${t('All of your answered questions will be visible in your Statistics overview, and all pending will still be in the Data entry space.')}</span>`,
    confirmLabel: t('Yes, mark as completed'),
    renderHTML: true,
  });

  if (isConfirmed) {
    emit('complete', props.id);
  }
}
</script>

<template>
  <div
    class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-6 w-full mb-5 last:mb-1"
  >
    <div class="md:col-start-1">
      <MlDatePicker
        :modelValue="period"
        :label="t('Reporting period')"
        :tooltip="
          t(
            'We advise adhering to the same reporting period as the one set in your financial reporting.',
          )
        "
        :inputClassName="`${periodError ? 'border-2 !border-error' : 'border-gray-400'} focus:outline focus:outline-1 focus:outline-primary focus:border-primary`"
        class="dateRangePicker"
        keepActionRow
        noToday
        range
        :disabledDates="periodDisabledDates"
        :yearRange="[
          new Date().getFullYear() - 6,
          new Date().getFullYear() + 3,
        ]"
        :teleport="true"
        :multiCalendars="{ solo: true }"
        :partialRange="true"
        :noDisabledRange="true"
        :showLastInRange="false"
        :disabled="isCompleted"
        :errors="periodError ? [periodError] : []"
        @update:modelValue="$emit('update:period', $event as [string, string])"
      />
    </div>
    <div class="md:col-start-2">
      <MlDatePicker
        :modelValue="deadline"
        :label="t('Data entry deadline')"
        :tooltip="t('This is the due date for data collection.')"
        :yearRange="[new Date().getFullYear(), new Date().getFullYear() + 3]"
        :inputClassName="`${deadlineError ? 'border-2 !border-error' : 'border-gray-400'} focus:outline focus:outline-1 focus:outline-primary focus:border-primary`"
        :disabled="isCompleted"
        :minDate="deadlineMinDate"
        :errors="deadlineError ? [deadlineError] : []"
        @update:modelValue="$emit('update:deadline', $event as string)"
      />
    </div>
    <div class="md:col-start-3">
      <div class="flex items-center h-full px-2 pt-6">
        <MlMenu v-if="!isCompleted">
          <button type="button" class="flex items-center">
            <DotsHorizontalIcon class="h-5 w-5 text-gray-400" />
          </button>
          <template #menuItems>
            <AtMenuItem class="justify-between !w-44" @click.stop="console.log">
              {{ t('Edit') }}
              <CogIcon class="h-5 w-5 text-gray-400" />
            </AtMenuItem>
            <AtMenuItem
              class="justify-between !w-44"
              :disabled="existingReportingPeriods.length === 1"
              @click.stop="handleDeleteClick"
            >
              {{ t('Delete') }}
              <ArchiveIcon class="h-5 w-5 text-gray-400" />
            </AtMenuItem>
            <AtMenuItem
              class="justify-between !w-44"
              @click.stop="handleMarkCompletedClick"
            >
              {{ t('Mark completed') }}
              <CheckIcon class="h-5 w-5 text-gray-400" />
            </AtMenuItem>
          </template>
        </MlMenu>
        <VTooltip v-else :triggers="['click', 'touch']" autoHide>
          <button type="button" class="flex items-center">
            <DotsHorizontalIcon class="h-5 w-5 text-gray-400" />
          </button>
          <template #popper>
            {{
              t(
                'This reporting period has been marked as completed. Please contact support if you need any changes on this.',
              )
            }}
          </template>
        </VTooltip>
      </div>
    </div>
  </div>
</template>
