import { gql } from '@apollo/client/core';

export default gql`
  query PgExternalDoubleMaterialitySurveyAnswer(
    $email: String!
    $shareToken: String!
  ) {
    getExternallyDoubleMateriality(email: $email, shareToken: $shareToken) {
      doubleMaterialityId
      surveyId
      userLanguage
      organizationName
    }
  }
`;
