import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UnapproveDataPointRequestMutation,
  UnapproveDataPointRequestMutationVariables,
} from '@/__generated__/types';

export const UNAPPROVE_DATAPOINT_REQUEST = gql`
  mutation UnapproveDataPointRequest(
    $unapproveDataPointRequestInput: UnapproveDataPointRequestInput!
  ) {
    unapproveDataPointRequest(
      UnapproveDataPointRequestInput: $unapproveDataPointRequestInput
    ) {
      _id
      status
      displayStatus
      childs {
        _id
        status
        displayStatus
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  UnapproveDataPointRequestMutation,
  UnapproveDataPointRequestMutationVariables
>;

const useUnapproveDataPointRequestMutation = (options?: TOptions) =>
  useMutation<
    UnapproveDataPointRequestMutation,
    UnapproveDataPointRequestMutationVariables
  >(UNAPPROVE_DATAPOINT_REQUEST, options);

export default useUnapproveDataPointRequestMutation;
