<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { email, helpers, minLength, required } from '@vuelidate/validators';
import { notify } from '@kyvg/vue3-notification';
import isEqual from 'lodash/isEqual';
import { UserLanguageEnum, UserRole } from '@/__generated__/types';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import useCreateUserMutation from '@/api/mutations/User/createUser.mutation';
import useUpdateUserMutation from '@/api/mutations/User/updateUser.mutation';
import useDisableUserMutation from '@/api/mutations/User/disableUser.mutation';
import useReassignSuperadminMutation from '@/api/mutations/User/reassignSuperadmin.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import OgConfirmationDialog from '@/components/organisms/OgConfirmationDialog.vue';
import type { User } from './types';

type UserForm = {
  firstName: string;
  lastName: string;
  jobTitle: string;
  jobDepartment: string;
  email: string;
  role: UserRole;
  entities: string[];
  language: UserLanguageEnum;
};

const props = defineProps<{
  user: User | null;
  allExistingUsers: User[];
}>();

const emit = defineEmits<{
  close: [];
}>();

const { t } = useI18n();
const { confirmViaDialog } = useConfirmViaDialog();
const { currentUser, isAdminOrSuperAdmin } = useCurrentUser();

const showDisableUserConfirmation = ref(false);
const showSuperAdminConfirmation = ref(false);

const { mutate: createUserMutate, loading: createUserLoading } =
  useCreateUserMutation();
const { mutate: updateUserMutate, loading: updateUserLoading } =
  useUpdateUserMutation();
const { mutate: disableUserMutate, loading: disableUserLoading } =
  useDisableUserMutation();
const { mutate: reassignSuperAdminMutate, loading: reassignSuperAdminLoading } =
  useReassignSuperadminMutation();

const isEditingUser = computed(() => !!props.user);

const userForm = reactive<UserForm>({
  firstName: '',
  lastName: '',
  jobTitle: '',
  jobDepartment: '',
  email: '',
  role: UserRole.ADMIN,
  entities: [],
  language: UserLanguageEnum.EN,
});
const validationRules = computed(() => ({
  firstName: {
    required: helpers.withMessage(t('First name is required.'), required),
  },
  lastName: {
    required: helpers.withMessage(t('Last name is required.'), required),
  },
  email: {
    required: helpers.withMessage(t('Email is required.'), required),
    email: helpers.withMessage(t('Email is invalid.'), email),
  },
  entities: {
    required: helpers.withMessage(
      t('Minimum 1 workspace must be selected.'),
      required,
    ),
    minLength: helpers.withMessage(
      t('Minimum 1 workspace must be selected.'),
      minLength(1),
    ),
  },
}));
const v$ = useVuelidate(validationRules, userForm);

watch(
  () => props.user,
  (newUser) => {
    if (newUser) {
      userForm.firstName = newUser.firstName;
      userForm.lastName = newUser.lastName;
      userForm.jobTitle = newUser.jobTitle;
      userForm.jobDepartment = newUser.jobDepartment;
      userForm.email = newUser.email;
      userForm.role = newUser.role;
      userForm.language = newUser.language;
      userForm.entities = newUser.entities.map((entity) => entity._id);
      v$.value.$reset();
    }
  },
  { immediate: true },
);

const userLanguageOptions = {
  [UserLanguageEnum.EN]: UserLanguageEnum.EN,
  [UserLanguageEnum.DE]: UserLanguageEnum.DE,
};

const workspaceOptions = computed(() =>
  props.allExistingUsers.reduce<Record<string, string>>((acc, user) => {
    user.entities.forEach((entity) => {
      acc[entity._id] = entity.name ?? '';
    });

    return acc;
  }, {}),
);
const disabledWorkspaceOptions = computed(() => {
  if (currentUser.value?.role === UserRole.SUPERADMIN) {
    return [];
  }

  const currentUserWorkspaces = new Set(
    (currentUser.value?.entities || []).map((entity) => entity._id),
  );
  return Object.keys(workspaceOptions.value).filter(
    (id) => !currentUserWorkspaces.has(id),
  );
});

const userRoleOptions = computed<Record<string, string>>(() => {
  if (isEditingUser.value && currentUser.value?.role === UserRole.SUPERADMIN) {
    return {
      [UserRole.ADMIN.toString()]: UserRole.ADMIN.toString(),
      [UserRole.MANAGER.toString()]: UserRole.MANAGER.toString(),
      [UserRole.CONTRIBUTOR.toString()]: UserRole.CONTRIBUTOR.toString(),
      [UserRole.SUPERADMIN.toString()]: UserRole.SUPERADMIN.toString(),
    };
  }

  if (currentUser.value?.role === UserRole.MANAGER) {
    return {
      [UserRole.MANAGER.toString()]: UserRole.MANAGER.toString(),
      [UserRole.CONTRIBUTOR.toString()]: UserRole.CONTRIBUTOR.toString(),
    };
  }

  return {
    [UserRole.ADMIN.toString()]: UserRole.ADMIN.toString(),
    [UserRole.MANAGER.toString()]: UserRole.MANAGER.toString(),
    [UserRole.CONTRIBUTOR.toString()]: UserRole.CONTRIBUTOR.toString(),
  };
});

const isFormChanged = computed(() => {
  if (props.user) {
    return (
      userForm.firstName !== props.user.firstName ||
      userForm.lastName !== props.user.lastName ||
      userForm.jobTitle !== props.user.jobTitle ||
      userForm.jobDepartment !== props.user.jobDepartment ||
      userForm.email !== props.user.email ||
      userForm.role !== props.user.role ||
      userForm.language !== props.user.language ||
      !isEqual(
        [...userForm.entities].sort(),
        props.user.entities.map((entity) => entity._id).sort(),
      )
    );
  }

  return true;
});

const canDisableUser = computed(() => {
  if (!props.user) {
    return false;
  }

  if (props.user._id === currentUser.value?._id) {
    return false;
  }

  return (
    isAdminOrSuperAdmin.value &&
    ![UserRole.NONE, UserRole.SUPERADMIN].includes(props.user.role)
  );
});

async function handleRoleChange(newRole: UserRole) {
  if (!props.user) {
    userForm.role = newRole;
    return;
  }

  if (props.user.role === newRole) {
    return;
  }

  const isConfirmed = await confirmViaDialog({
    text: t(
      'When you change the user role, all existing assignments for that user will be removed. Please make sure to check assignments afterwards.',
    ),
    confirmLabel: t('Change'),
    cancelLabel: t('Cancel'),
    confirmButtonVariant: 'destructive',
  });
  if (isConfirmed) {
    userForm.role = newRole;
  }
}

function handleSubmit() {
  if (isEditingUser.value) {
    if (
      userForm.role === UserRole.SUPERADMIN &&
      currentUser.value?.role === UserRole.SUPERADMIN &&
      props.user?.role !== UserRole.SUPERADMIN
    ) {
      handleSuperAdminConfirmationShow();
    } else {
      handleEdit();
    }
  } else {
    handleCreate();
  }
}

async function handleEdit() {
  try {
    const updatedUser = await updateUserMutate({
      updateUserInput: {
        _id: props.user!._id,
        firstName: userForm.firstName,
        lastName: userForm.lastName,
        jobTitle: userForm.jobTitle,
        jobDepartment: userForm.jobDepartment,
        email: userForm.email,
        role: userForm.role,
        language: userForm.language,
        assignments: {
          doubleMateriality: true,
        },
        entity: userForm.entities[0],
        entities: userForm.entities,
      },
    });
    notify({
      type: 'success',
      text: t('User {email} has been updated.', {
        email: updatedUser?.data?.updateUser.email,
      }),
    });
    emit('close');
  } catch {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
}

async function handleCreate() {
  try {
    const createdUser = await createUserMutate(
      {
        createUserInput: {
          firstName: userForm.firstName,
          lastName: userForm.lastName,
          jobTitle: userForm.jobTitle,
          jobDepartment: userForm.jobDepartment,
          email: userForm.email,
          role: userForm.role,
          language: userForm.language,
          assignments: {
            doubleMateriality: true,
          },
          entity: userForm.entities[0],
          entities: userForm.entities,
        },
      },
      {
        update: (store) => {
          store.evict({ fieldName: 'getTeamUsers' });
          store.evict({ fieldName: 'getAllTeamsUsers' });
        },
      },
    );
    notify({
      type: 'success',
      text: t('Invitation email has been sent to {email}.', {
        email: createdUser?.data?.createUser.email,
      }),
    });
    emit('close');
  } catch {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
}

function handleDisableUserClick() {
  showDisableUserConfirmation.value = true;
}

function handleConfirmationCancel() {
  showDisableUserConfirmation.value = false;
}

async function handleDisableUserConfirmed(isConfirmed: boolean) {
  if (props.user) {
    if (isConfirmed) {
      try {
        await disableUserMutate(
          {
            disableUserInput: {
              _id: props.user._id,
            },
          },
          {
            update: (store) => {
              store.evict({ fieldName: 'getTeamUsers' });
              store.evict({ fieldName: 'getAllTeamsUsers' });
            },
          },
        );
        emit('close');
      } catch {
        notify({
          type: 'error',
          text: t('Something went wrong, try again later :(.'),
        });
      }
      showDisableUserConfirmation.value = false;
    } else {
      showDisableUserConfirmation.value = false;
    }
  }
}

function handleSuperAdminConfirmationShow() {
  showSuperAdminConfirmation.value = true;
}

function handleSuperAdminCancel() {
  showSuperAdminConfirmation.value = false;
}

async function handleSuperAdminConfirmed(isConfirmed: boolean) {
  if (isConfirmed && props.user) {
    try {
      const updatedUser = await reassignSuperAdminMutate(
        {
          updateUserInput: {
            _id: props.user._id,
            firstName: userForm.firstName,
            lastName: userForm.lastName,
            jobTitle: userForm.jobTitle,
            jobDepartment: userForm.jobDepartment,
            email: userForm.email,
            role: userForm.role,
            language: userForm.language,
            assignments: {
              doubleMateriality: true,
            },
            entity: userForm.entities[0],
            entities: userForm.entities,
          },
        },
        {
          update: (store) => {
            store.evict({ fieldName: 'getTeamUsers' });
            store.evict({ fieldName: 'getAllTeamsUsers' });
          },
        },
      );
      notify({
        type: 'success',
        text: t('User {email} has been assigned as superadmin.', {
          email: updatedUser?.data?.reassignSuperadmin.email,
        }),
      });
      emit('close');
    } catch {
      notify({
        type: 'error',
        text: t('Something went wrong, try again later :(.'),
      });
    }
  }
}
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div
      v-if="!isEditingUser"
      class="flex w-full items-center justify-center text-center"
    >
      <div class="h-px w-1/6 bg-gray-200" />
      <div class="w-3/4">
        {{ t('Or invite new team members.') }}
      </div>
      <div class="h-px w-1/6 bg-gray-200" />
    </div>

    <div class="mb-5 sm:mb-4 pt-10 flex flex-col gap-4">
      <AtInput
        v-model.trim="userForm.firstName"
        :label="t('First Name')"
        :errors="v$.firstName.$errors"
        @blur="v$.firstName.$touch"
      />
      <AtInput
        v-model.trim="userForm.lastName"
        :label="t('Last Name')"
        :errors="v$.lastName.$errors"
        @blur="v$.lastName.$touch"
      />
      <AtInput
        v-if="isEditingUser"
        v-model.trim="userForm.jobTitle"
        :label="t('Job Title')"
      />
      <AtInput
        v-show="isEditingUser"
        v-model.trim="userForm.jobDepartment"
        :label="t('Job Department')"
      />
      <AtInput
        v-model.trim="userForm.email"
        :label="t('E-Mail')"
        type="email"
        :errors="v$.email.$errors"
        @blur="v$.email.$touch"
      />
      <MlSelect
        v-if="user?.role !== UserRole.SUPERADMIN || !isEditingUser"
        :modelValue="userForm.role"
        :label="t('Role')"
        :options="userRoleOptions"
        sortedOptions
        @update:modelValue="handleRoleChange"
      />
      <MlSelect
        v-model="userForm.entities"
        multiple
        :label="t('Workspaces')"
        :options="workspaceOptions"
        :disabledOptions="disabledWorkspaceOptions"
        :disabledOptionText="
          t(
            'Only admins responsible for this workspace or the superadmin can add this user.',
          )
        "
        :errors="v$.entities.$errors"
        @blur="v$.entities.$touch"
      />
      <MlSelect
        v-model="userForm.language"
        :label="t('Language')"
        :options="userLanguageOptions"
      />
    </div>

    <div class="flex sm:flex-row-reverse gap-2">
      <AtButton
        v-if="!isEditingUser"
        type="submit"
        :loading="createUserLoading"
        :disabled="v$.$invalid"
      >
        {{ t('Send invitation') }}
      </AtButton>
      <AtButton
        v-else
        type="submit"
        :loading="updateUserLoading"
        :disabled="v$.$invalid || !isFormChanged"
      >
        {{ t('Update User') }}
      </AtButton>
      <AtButton
        v-if="canDisableUser"
        type="button"
        variant="destructive"
        @click.stop="handleDisableUserClick"
      >
        {{ t('Disable User') }}
      </AtButton>
    </div>
  </form>

  <OgConfirmationDialog
    v-if="showDisableUserConfirmation"
    isRevealed
    :title="t('Disable team member')"
    :cancelLabel="t('Cancel')"
    :confirmLabel="t('Disable user')"
    :confirmLoading="disableUserLoading"
    confirmButtonVariant="destructive"
    titleClass="text-rose-600"
    modalClass="bg-rose-50"
    @cancel="handleConfirmationCancel"
    @confirm="handleDisableUserConfirmed"
  >
    <template #text>
      <i18n-t
        keypath="You are about to disable {fullName} from Codio Impact. Do you wish to continue?"
        tag="span"
      >
        <template #fullName>
          <strong>{{ `${user?.firstName} ${user?.lastName}` }}</strong>
        </template>
      </i18n-t>
    </template>
  </OgConfirmationDialog>

  <OgConfirmationDialog
    v-if="showSuperAdminConfirmation"
    isRevealed
    :title="t('Assign new superadmin')"
    :cancelLabel="t('Cancel')"
    :confirmLabel="t('Assign superadmin')"
    :confirmLoading="reassignSuperAdminLoading"
    @cancel="handleSuperAdminCancel"
    @confirm="handleSuperAdminConfirmed"
  >
    <template #text>
      <i18n-t
        keypath="By clicking 'Continue', {fullName} will become the new superadmin, and your role will switch to admin. This action cannot be undone. Only the new superadmin can reverse this change. Do you want to assign a new super admin?"
        tag="span"
      >
        <template #fullName>
          <strong>{{ `${user?.firstName} ${user?.lastName}` }}</strong>
        </template>
      </i18n-t>
    </template>
  </OgConfirmationDialog>
</template>
