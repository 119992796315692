<script setup lang="ts">
import {
  computed,
  ref,
  watch,
  onMounted,
  inject,
  type HTMLAttributes,
} from 'vue';
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxLabel,
  TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/solid';
import { useI18n } from 'vue-i18n';
import type { ErrorObject } from '@vuelidate/core';
import { InformationCircleIcon, SearchIcon } from '@heroicons/vue/outline';
import {
  useDebounceFn,
  onClickOutside,
  type MaybeElementRef,
} from '@vueuse/core';
import { sortOptions } from '@/utils/helpers/sortOptions';
import unwrapVuelidateErrorMessages from '@/utils/helpers/unwrapVuelidateErrorMessages';
import AtButton from '../atoms/AtButton/AtButton.vue';

interface Props {
  label?: string | null;
  modelValue?: string | string[] | null;
  options?: Record<string, string>;
  sortedOptions?: boolean; // whether options are already sorted or should be auto-sorted here
  disabled?: boolean;
  errors?: string[] | ErrorObject[];
  multiple?: boolean;
  allowSelectNone?: boolean;
  wrapperClass?: HTMLAttributes['class'];
  class?: HTMLAttributes['class'];
  placeholder?: string;
  type?: 'search' | 'select';
  dataCy?: string;
  canCreate?: boolean;
  hideSearchIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  options: () => ({}),
  disabled: false,
  sortedOptions: false,
  allowSelectNone: false,
  multiple: false,
  wrapperClass: '',
  class: '',
  label: '',
  placeholder: '',
  errors: () => [],
  type: 'search',
  dataCy: '',
  canCreate: false,
  hideSearchIcon: false,
});

const emit = defineEmits(['update:modelValue', 'refetchOptions', 'create']);
const { t } = useI18n();

const value = ref(props.modelValue ?? []);
const query = ref('');
const createValue = ref('');
const showOptions = ref(false);

const inputRef = ref<typeof ComboboxInput>();

const emitRefetch = useDebounceFn(() => {
  emit('refetchOptions', query.value);
}, 150);

onMounted(() => {
  showOptions.value = !props.canCreate || !!Object.keys(props.options).length;
});

watch(query, () => {
  if (props.canCreate && query.value.length) {
    createValue.value = query.value;
  }
  if (!props.multiple) {
    showOptions.value = showOptions.value || query.value.length > 0;
  }
  if (!query.value.length && !Object.keys(props.options).length) {
    showOptions.value = false;
  }
  emitRefetch();
});

watch(
  () => props.modelValue,
  () => {
    value.value = props.modelValue ?? [];
    if (
      Array.isArray(value.value) &&
      value.value.includes('none_of_the_above')
    ) {
      value.value = ['none_of_the_above'];
    }
    if (!props.multiple) {
      showOptions.value = !showOptions.value || query.value.length > 0;
    }
    if (query.value.length === 0 && Object.keys(props.options).length === 0) {
      showOptions.value = false;
    }
  },
);

const triggerOpen = () => {
  // open combobox on focus
  // headlessUI currently doesn't support it
  // so we dispatch arrow down key on input that actually triggers opening
  // https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/combobox/combobox.ts#L544
  inputRef.value?.el.dispatchEvent(
    new KeyboardEvent('keydown', { key: 'ArrowDown' }),
  );
};
onClickOutside(inputRef as unknown as MaybeElementRef, () => {
  query.value = '';
});

function create() {
  emit('update:modelValue', createValue.value);
  query.value = '';
}

const sortedOptions = computed(() => {
  const options = Object.entries(props.options);
  const sorted = sortOptions(options);
  return new Map(props.sortedOptions ? options : sorted);
});

const filteredOptions = computed(() => {
  if (query.value === '') return sortedOptions.value;
  const filtered = [...sortedOptions.value]
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(
      ([_, v]) =>
        v.toLowerCase().includes(query.value.toLowerCase()) ||
        t(v).toLowerCase().includes(query.value.toLowerCase()),
    );

  return new Map(filtered);
});

watch(filteredOptions, () => {
  value.value = props.modelValue ?? [];
  if (!query.value.length && !Object.keys(props.options).length) {
    showOptions.value = false;
  } else if (
    props.canCreate &&
    !query.value.length &&
    Object.keys(props.options).includes(createValue.value)
  ) {
    showOptions.value = false;
  } else if (
    !filteredOptions.value.size &&
    props.canCreate &&
    !query.value.length
  ) {
    showOptions.value = false;
  } else {
    showOptions.value = true;
  }
});

const displayValue = (option: unknown) => {
  if (!props.multiple) {
    return t(props.options[option as string] ?? '');
  }

  return ((value.value as string[]) || [])
    .reduce((acc, val) => {
      const opt = props.options[val];
      if (opt) {
        acc.push(t(opt));
      }
      return acc;
    }, [] as string[])
    .join(', ');
};

const canToggleQuestions = inject('canToggleQuestions', ref(true));
defineOptions({ inheritAttrs: false });
</script>

<template>
  <div :class="['relative w-full', wrapperClass]" :data-cy="props.dataCy">
    <Combobox
      v-model="value"
      :multiple="multiple"
      :nullable="allowSelectNone"
      :disabled="props.disabled"
      @update:modelValue="emit('update:modelValue', value)"
    >
      <ComboboxLabel
        v-if="label"
        class="mb-1 block text-sm font-medium text-gray-700"
      >
        {{ t(label) }}
      </ComboboxLabel>
      <div
        class="flex w-full cursor-default overflow-hidden rounded-md border border-gray-400 bg-white text-left focus:outline-none sm:text-sm"
        :class="[
          props.class,
          {
            'border-error ': props.errors.length,
          },
        ]"
      >
        <ComboboxButton class="flex items-center">
          <SearchIcon
            v-if="props.type === 'search' && !props.hideSearchIcon"
            class="ml-3 w-5"
          />
        </ComboboxButton>
        <ComboboxInput
          ref="inputRef"
          class="block w-full rounded-md border-gray-400 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
          :class="{
            'pointer-events-none opacity-50': props.disabled,
            'bg-rose-50 text-error': props.errors.length,
          }"
          :placeholder="placeholder"
          :displayValue="displayValue"
          data-cy="ComboboxInput"
          @change="query = $event.target.value"
          @focus="
            triggerOpen();
            canToggleQuestions = false;
          "
          @keyup.enter.prevent="
            props.canCreate && filteredOptions.size === 0 && query !== ''
              ? create()
              : console.log('cannot create')
          "
        />
        <div
          :class="{ 'pt-4': label }"
          class="absolute right-0 top-0 flex translate-y-1/2 cursor-pointer items-center pr-2"
          @click.stop="emit('update:modelValue', props.multiple ? [] : '')"
        >
          <XIcon
            v-if="
              props.type === 'search' &&
              (value?.length || query.length) &&
              !props.disabled
            "
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
            :class="{ 'text-error': props.errors.length }"
          />
        </div>
      </div>
      <TransitionRoot
        v-if="showOptions"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ComboboxOptions
          class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
        >
          <div
            v-if="filteredOptions.size === 0 && query !== ''"
            class="relative cursor-default select-none px-4 py-2 text-gray-700"
          >
            <AtButton
              v-if="props.canCreate"
              class="w-full"
              @click.stop.prevent="create()"
            >
              {{ t('Create: {query}', { query }) }}
            </AtButton>
            <div v-else class="">
              {{ t('Nothing found.') }}
            </div>
          </div>

          <ComboboxOption
            v-for="[key, option] in filteredOptions"
            :key="key"
            v-slot="{ selected, active }"
            class="group"
            as="template"
            :value="key"
          >
            <li
              class="relative select-none px-4 py-2 cursor-pointer"
              :class="{
                'bg-primary text-white': active,
                'text-gray-900': !active,
              }"
              data-cy="ComboboxOption"
            >
              <div class="flex items-center gap-3">
                <input
                  :type="props.multiple ? 'checkbox' : 'radio'"
                  class="checked:animate-none hover:border-white checked:hover:border-primary group-hover:border-white"
                  :class="{
                    'checkbox-primary checkbox checkbox-xs': props.multiple,
                    hidden: !props.multiple,
                    'border-white': active,
                  }"
                  :checked="selected"
                />
                <span class="block" data-cy="ComboboxOptionSpan">
                  {{ t(option) }}
                </span>
              </div>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </Combobox>
    <p
      v-for="error in unwrapVuelidateErrorMessages(props.errors)"
      :key="error"
      class="mt-1 text-xs text-error"
    >
      <span class="flex">
        <InformationCircleIcon class="mr-1 w-3" />
        {{ t(error) }}
      </span>
    </p>
  </div>
</template>
