import type { RouteLocationNormalized } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import { i18n } from '@/lib/i18n';
import { UserRole } from '@/__generated__/types';
import {
  fetchCurrentUser,
  currentUser,
} from '@/utils/composables/useCurrentUser/useCurrentUser';
import { hasRequiredRole } from '../../utils/helpers/hasRequiredRole';

export const authGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) => {
  await fetchCurrentUser();

  const { role, roleFrom } = to.meta;
  let hasRole = false;

  if (role) {
    hasRole = Array.isArray(role)
      ? role.includes(currentUser.value?.role as UserRole)
      : currentUser.value?.role === role;
  } else {
    hasRole = true;
  }

  if (roleFrom) {
    hasRole = currentUser?.value?.role
      ? hasRequiredRole(roleFrom, currentUser?.value.role)
      : false;
  }

  const hasAuth = localStorage.getItem('apiKey') !== null;

  if (!hasAuth) {
    localStorage.setItem(
      'nextUrl',
      `${window.location.pathname}${window.location.search}`,
    );
    return '/login';
  }

  if (!hasRole) {
    if (from.path === '/') {
      return '/home';
    }

    notify({
      type: 'error',
      text: i18n.global.t("Sorry, you don't have access to that page."),
    });
    return false;
  }
};

// TODO: create adminGuard (for specific pages: settingsMateriality, settingsLocations, settingsGoals etc.)
