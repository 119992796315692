<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import { calculateIroImpactSurveysRating } from '../../utils';
import { useStore } from '../../store';
import OgTable from '../../OgTable.vue';

type RowItem = {
  _id: string;
  impact: string;
  rating: number | null;
};

const { t } = useI18n();
const store = useStore();
const { formatNumber } = useFormatNumber();

const headers = computed(() => [
  { text: t('Impacts'), value: 'impact' },
  { text: t('Average rating'), value: 'rating', columnClasses: 'w-[200px]' },
]);

const items = computed<RowItem[]>(() => {
  return store.value.iroImpacts
    .filter((iroImpact) => iroImpact.impact)
    .map((iroImpact) => ({
      _id: iroImpact._id,
      impact: iroImpact.impact,
      rating: calculateIroImpactSurveysRating(
        iroImpact._id,
        store.value.surveys,
      ),
    }));
});
</script>

<template>
  <h3 class="text-lg font-medium mb-3">
    {{ t('Results on impacts') }}
  </h3>
  <OgTable :headers="headers" :items="items" :isRowRemovable="false">
    <template #item-impact="row: RowItem">
      <p>
        {{ row.impact }}
      </p>
    </template>
    <template #item-rating="row: RowItem">
      <p>
        {{
          typeof row.rating !== 'number'
            ? t('n/a')
            : formatNumber(row.rating, 0, 1)
        }}
      </p>
    </template>
  </OgTable>
</template>
