<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { PgTaxonomyOverviewQuery } from '@/__generated__/types';
import PG_OVERVIEW_QUERY from './PgOverview.query';
import OgPerformance from './OgPerformance.vue';
import OgKeyStatistics from './OgKeyStatistics.vue';
import OgAssessments from './OgAssessments.vue';

const { result, loading } =
  useQuery<PgTaxonomyOverviewQuery>(PG_OVERVIEW_QUERY);
const { t } = useI18n();
const projects = computed(() => result.value?.getTaxonomyProjects ?? []);
const projectsWithAssessments = computed(() =>
  projects.value
    .filter((project) => project.assessments.length)
    .sort((a, b) =>
      new Date(a.assessments[0].updatedAt) <
      new Date(b.assessments[0].updatedAt)
        ? 1
        : -1,
    ),
);
</script>

<template>
  <div>
    <div v-if="loading">
      <h1>{{ t('Please wait..') }}</h1>
    </div>
    <div v-else>
      <OgKeyStatistics
        :taxonomyProjects="projectsWithAssessments"
        class="mb-8"
      />
      <OgAssessments
        :taxonomyProjects="projectsWithAssessments.slice(0, 2)"
        class="mb-8"
      />
      <OgPerformance :taxonomyProjects="projects" class="mb-8" />
    </div>
  </div>
</template>
