import { gql } from '@apollo/client/core';

export default gql`
  query AdminRawReport(
    $entityIds: [String!]!
    $locationIds: [String!]
    $dataPointCategoryIds: [String!]
  ) {
    getDataPointsEncodedReport(
      entityIds: $entityIds
      locationIds: $locationIds
      dataPointCategoryIds: $dataPointCategoryIds
    )
  }
`;
