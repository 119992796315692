import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpsertDataPointTypeMutation,
  UpsertDataPointTypeMutationVariables,
} from '@/__generated__/types';

export const UPSERT_DATAPOINTTYPE = gql`
  mutation UpsertDataPointType(
    $upsertDataPointTypeInput: UpsertDataPointTypeInput!
  ) {
    upsertDataPointType(dataPointTypeInput: $upsertDataPointTypeInput) {
      _id
      friendlyName
      name
      question
      questionHelp
      refreshInterval
      valueDataType
      valueDataTypeOptions
      valueUnit
      emissionSubcategory
      emissionFactors {
        _id
        factor
      }
      reportingFrameworks {
        _id
        framework
        groups
      }
      allowUploadProof
    }
  }
`;

type TOptions = UseMutationOptions<
  UpsertDataPointTypeMutation,
  UpsertDataPointTypeMutationVariables
>;

const useUpsertDataPointTypeMutation = (options?: TOptions) =>
  useMutation<
    UpsertDataPointTypeMutation,
    UpsertDataPointTypeMutationVariables
  >(UPSERT_DATAPOINTTYPE, options);

export default useUpsertDataPointTypeMutation;
