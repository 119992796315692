<script setup lang="ts">
import { computed, reactive, ref, type VNode, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import omit from 'lodash/omit';
import { PlusIcon, XIcon } from '@heroicons/vue/solid';
import { useMounted } from '@vueuse/core';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import type {
  DataPointValueSource,
  DataPointTypeValueUnitEnum,
} from '@/__generated__/types';
import type { TDataPointRequest } from '@/components/pages/PgProjects/types';
import { getAlternativeUnits } from '@/utils/helpers/getAlternativeUnits';

interface TProps {
  component: VNode;
  valueSource?: TDataPointRequest['valueSource'];
  categoryNames?: string[];
  isSmall?: boolean;
  dataPointRequestId?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<TProps>(), {
  valueSource: () => [] as DataPointValueSource[],
  categoryNames: () => [],
  isSmall: false,
  dataPointRequestId: undefined,
  disabled: false,
});

const emits = defineEmits(['update:modelValue']);
const { t } = useI18n();

export interface TElement {
  name: string;
  value: string | number | null;
  unit?: DataPointTypeValueUnitEnum;
  prefillDisabled?: boolean;
}

const elements = reactive<TElement[]>([
  { name: '', value: null, unit: undefined },
  { name: '', value: null, unit: undefined },
]);
const elementsCount = ref(2);
const unitOptions = computed(() =>
  props.component.props?.unit
    ? getAlternativeUnits(props.component.props?.unit)
    : {},
);
const isMounted = useMounted();

watch(
  props,
  () => {
    if (!props.valueSource?.length) {
      elements.length = 0;
      elements.push(
        { name: '', value: null, unit: undefined },
        { name: '', value: null, unit: undefined },
      );
    } else if (props.valueSource?.length) {
      elements.length = 0;

      elements.push(
        ...props.valueSource.map((singleValueSource) => ({
          name: singleValueSource.name,
          value: singleValueSource.originalValue,
          unit: singleValueSource.originalValueUnit ?? undefined,
        })),
      );

      elementsCount.value = elements.length;
    } else if (
      props.categoryNames.length &&
      !elements.some((el) => el.prefillDisabled)
    ) {
      elements.length = 0;

      elements.push(
        ...props.categoryNames.map((name) => ({
          name,
          value: null,
          unit: undefined,
        })),
      );
      elementsCount.value = elements.length;
    }
  },
  { immediate: true },
);

const addElement = () => {
  elements.push({ name: '', value: null, unit: undefined });
  elementsCount.value += 1;
};

const removeElement = (index: number) => {
  elements.splice(index, 1);
  elementsCount.value -= 1;
};

watch(elements, (newElements) => {
  newElements.forEach((el) => {
    if (el.name || el.value) {
      el.prefillDisabled = true;
    }
  });

  emits(
    'update:modelValue',
    newElements.map((el) => omit(el, ['prefillDisabled'])),
  );
});
</script>

<template>
  <div>
    <div
      v-for="number in elementsCount"
      :key="number"
      class="my-3 xl:flex gap-5 p-2 rounded relative"
      :class="{
        'flex-wrap flex': isSmall,
      }"
    >
      <div
        :class="
          !isSmall && Object.keys(unitOptions).length > 0 ? 'w-4/12' : 'w-full'
        "
      >
        <AtInput
          v-model="elements[number - 1].name"
          type="text"
          :label="`${t('Category')} ${number}`"
          :disabled="props.disabled"
        />
      </div>

      <div
        :class="
          !isSmall && Object.keys(unitOptions).length > 0 ? 'w-4/12' : 'w-full'
        "
      >
        <label :for="`value-${number}`" />
        <component
          :is="props.component"
          v-model="elements[number - 1].value"
          :label="`${t('Value')} ${number}`"
          :unit="undefined"
          :disabled="props.disabled"
        />
      </div>

      <div
        v-if="Object.keys(unitOptions).length > 0"
        :class="isSmall ? 'w-full' : 'w-3/12'"
      >
        <MlSelect
          v-model="elements[number - 1].unit"
          :label="`${t('Unit')} ${number}`"
          :options="unitOptions"
          :disabled="props.disabled"
        />
      </div>

      <button
        v-if="number > 1"
        aria-label="remove item"
        class="absolute top-1 right-1 hover:text-primary"
        @click.prevent="removeElement(number - 1)"
      >
        <XIcon class="h-4 text-gray-400 hover:text-primary" />
      </button>
    </div>

    <Teleport
      v-if="props.dataPointRequestId && isMounted"
      :to="`#actionAddRow_${props.dataPointRequestId}`"
    >
      <button
        aria-label="add row"
        class="flex text-gray-400 text-xs hover:text-primary justify-center items-center"
        @click.prevent="addElement"
      >
        <PlusIcon class="h-4 mr-2" />
        {{ t('Add row') }}
      </button>
    </Teleport>
    <button
      v-else-if="!props.dataPointRequestId && isMounted"
      aria-label="add row"
      class="flex text-gray-400 text-xs hover:text-primary justify-start"
      @click.prevent="addElement"
    >
      <PlusIcon class="h-4 mr-2" />
      {{ t('Add row') }}
    </button>
  </div>
</template>
