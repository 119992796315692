<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { CheckIcon } from '@heroicons/vue/solid';

const { t } = useI18n();
interface TProps {
  currentStepIndex: number;
  steps: {
    stepNumber: number;
    title: string;
    description: string;
    isCompleted: boolean;
    isClickable: boolean;
  }[];
}

const props = defineProps<TProps>();
const emit = defineEmits(['changeCurrentStepIndex']);
</script>

<template>
  <div
    v-show="props.currentStepIndex <= steps.length"
    class="h-full w-1/3 border-r p-9"
    data-cy="stepper"
  >
    <div class="mb-20">
      <h1 class="mb-2 mt-0 text-4xl font-semibold" data-cy="welcome-to-codio">
        {{ t('Welcome To Codio') }}
      </h1>
      <p>
        {{
          t(
            'Codio Impact automates the way you manage and report your Environmental, Social, Governance (ESG) data, while remaining fully compliant with the newest regulation.',
          )
        }}
      </p>
    </div>
    <div class="grid grid-flow-col">
      <div class="py-2">
        <h2 class="mb-4 mt-0 text-lg font-semibold leading-normal">
          {{ t('Onboarding Steps') }}
        </h2>
        <ul class="w-11/12 border-r py-2">
          <li
            v-for="(step, index) in steps"
            :key="step.stepNumber"
            class="mb-9 flex items-center justify-between last:mb-0"
          >
            <div
              :class="{ 'cursor-pointer': step.isClickable }"
              :data-cy="`step${step.stepNumber}`"
              @click.stop="
                step.isClickable && emit('changeCurrentStepIndex', index)
              "
            >
              <div class="text-base font-normal leading-6">
                {{ step.title }}
              </div>
              <div class="text-sm font-normal leading-5 text-gray-400">
                {{ step.description }}
              </div>
            </div>
            <div
              class="flex h-6 w-6 translate-x-[13px] items-center justify-center rounded-full border-2 border-gray-400 bg-white text-center"
              :class="{
                'border-0 !border-none !bg-blue-600':
                  currentStepIndex === index,
                'border-2 !border-emerald-500': step.isCompleted,
                'cursor-pointer': step.isClickable,
              }"
              @click.stop="
                step.isClickable && emit('changeCurrentStepIndex', index)
              "
            >
              <div
                :class="[
                  currentStepIndex === index ? 'text-white' : 'text-gray-400',
                ]"
              >
                <span v-if="step.isCompleted">
                  <CheckIcon
                    class="h-5 w-5"
                    :class="{ 'text-emerald-500': currentStepIndex !== index }"
                  />
                </span>
                <span v-else> {{ step.stepNumber }} </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
