import orderBy from 'lodash/orderBy';
import { ReportingFrameworkEnum } from '@/__generated__/types';
import type { PreparedPerDataPointTypeItem } from '../types';

export function sortDataPointTypes(
  preparedPerDataPointTypes: PreparedPerDataPointTypeItem[],
) {
  return orderBy(preparedPerDataPointTypes, [
    ({ type }) => {
      const activeFramework = type.activeReportingFramework.framework;
      return type.reportingFrameworks.find(
        ({ framework }) =>
          framework === activeFramework ||
          framework === ReportingFrameworkEnum.CUSTOM,
      )?.order;
    },
    ({ type }) => type.friendlyName,
  ]);
}
