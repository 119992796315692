<script setup lang="ts">
import type { PgTaxonomyAssessmentQuery } from '@/__generated__/types';

type TProps = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment'];
};

const props = defineProps<TProps>();
</script>

<template>
  <router-view :assessment="props.assessment" />
</template>
