export const sortOptions = (options: [string, string][]) =>
  [...options].sort(([keyA, valueA], [keyB, valueB]) => {
    const keyANumber = keyA.split('_')[0].toLowerCase();
    const keyBNumber = keyB.split('_')[0].toLowerCase();

    if (keyANumber.match(/^\d+$/) && keyBNumber.match(/^\d+$/)) {
      // this will automatically sort options like 0_100 ; 101_250 ; 251_500
      return parseInt(keyANumber, 10) - parseInt(keyBNumber, 10);
    }

    if (valueA.toLowerCase() < valueB.toLowerCase()) {
      return -1;
    }

    if (valueB.toLowerCase() > valueA.toLowerCase()) {
      return 1;
    }

    return 0;
  });
