<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import dayjs from '@/lib/dayjs/config';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import { DataPointRequestStatusEnum } from '@/__generated__/types';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import type {
  TDataPointRequest,
  Project,
  TQuestionDataTableItem,
} from '../../../types';
import MlQuestionForm from './MlQuestionForm.vue';
import OgQuestionList from './OgQuestionList.vue';
import MlQuestionInfo from './MlQuestionInfo.vue';

const props = defineProps<{
  dataPointRequests: TDataPointRequest[];
  isChilds?: boolean;
  project?: Project;
  isQuestionHovered?: boolean;
}>();

const emit = defineEmits<{
  (e: 'closeCollapse'): void;
  (e: 'updateActiveQuestion', item: TQuestionDataTableItem | undefined): void;
}>();

const { t } = useI18n();

const formatToItem = (dpr: TDataPointRequest): TQuestionDataTableItem => ({
  dpr,
  question: t(dpr.dataPointType.question),
  tasks: dpr.childs?.length,
  status: t(dpr.displayStatus),
  period: Math.abs(dayjs(dpr.from).diff(dpr.to, 'day')),
  dueDate: dayjs(dpr.dueDate).format('DD.MM.YYYY'),
  dueDateFromNow: dayjs(dpr.dueDate).fromNow(),
  year: dpr.to ? dayjs(dpr.to).subtract(1, 'days').year() : dayjs().year(),
});

const activeIntervals = reactive<Record<string, boolean>>({});
onMounted(() => {
  const from =
    props.dataPointRequests[props.dataPointRequests.length - 1]?.from;
  const to = props.dataPointRequests[props.dataPointRequests.length - 1]?.to;
  activeIntervals[getDPRDateString(from, to)] = true;
});

const isGroup = computed(() => props.dataPointRequests.length > 1);
const hasChildren = ({ childs }: TDataPointRequest) => childs?.length;
// const isOverdue = ({ dueDate }: TDataPointRequest) => dayjs(dueDate).isSameOrBefore(new Date());

const dprValues = reactive<Record<string, string>>({});

const dprDateStrings = computed(() =>
  props.dataPointRequests.reduce(
    (acc, curr) => ({
      ...acc,
      [curr._id]: getDPRDateString(curr.from, curr.to),
    }),
    {} as Record<string, string>,
  ),
);

const questionLists = ref<InstanceType<typeof OgQuestionList>[] | null[]>([]);
const activeChilds = computed(() =>
  questionLists.value?.flatMap(
    (questionList) => questionList?.activeChilds ?? [],
  ),
);
defineExpose({ activeChilds });

const setDefaultValues = () =>
  props.dataPointRequests.forEach(({ _id, value }) => {
    dprValues[_id] = value;
  });
watch(() => props.dataPointRequests, setDefaultValues, { immediate: true });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore no idea what that typing issue is or how to resolve it
const getItems = ({ childs }: TDataPointRequest): TQuestionDataTableItem[] =>
  childs?.map((dpr) => ({
    dpr,
    question: t(dpr.dataPointType.question),
    status: t(dpr.displayStatus),
    dueDate: dayjs(dpr.dueDate).format('DD.MM.YYYY'),
    dueDateFromNow: dayjs(dpr.dueDate).fromNow(),
  })) ?? [];

const dataPointRequestsWithValueSourceNames = computed(() =>
  props.dataPointRequests
    .filter((dpr) => dpr.valueSource)
    .map((dpr) => ({
      id: dpr._id,
      fromDate: `${dpr.from}`,
      valueSourceNames: dpr.valueSource?.map((vs) => vs.name) ?? [],
    })),
);
</script>

<template>
  <div
    class="flex flex-col"
    :class="{
      'bg-gray-50': props.isQuestionHovered, // could fix rerender issue in OgQuestion l.622 ???
    }"
  >
    <div
      v-for="(dataPointRequest, i) in props.dataPointRequests"
      :key="dataPointRequest._id"
      :data-datapointrequest="dataPointRequest._id"
    >
      <div v-if="i" class="bg-gray-200 h-[1px]" />
      <div>
        <div
          v-if="dataPointRequest.status === DataPointRequestStatusEnum.REJECTED"
          class="grid grid-cols-[7.5fr_1fr_1.4fr_1.6fr_0.6fr] gap-2"
        >
          <div
            class="mb-2 ml-12 grid w-[34rem] grid-flow-col rounded-lg border border-rose-600 bg-rose-50 p-2 text-xs text-gray-700"
          >
            <span class="col-span-5 mr-2">
              <span class="font-semibold"
                >{{ dataPointRequest.rejectedBy?.firstName }}
                {{ dataPointRequest.rejectedBy?.lastName }}
              </span>
              <template v-if="dataPointRequest.rejectReason"
                >&nbsp;{{
                  t(
                    'rejected your provided datapoint with the following reason:',
                  )
                }}
                <MlHtmlContent :html="dataPointRequest.rejectReason" />
              </template>
              <template v-else>
                &nbsp;{{ t('rejected your provided datapoint') }}
              </template>
            </span>
            <span class="text-right">{{
              getDPRDateString(dataPointRequest.rejectedAt)
            }}</span>
          </div>
        </div>

        <div
          class="grid grid-cols-[1fr] items-start justify-between justify-items-start gap-y-2 gap-x-0"
        >
          <div class="w-full">
            <div
              v-if="!props.isChilds"
              class="grid grid-cols-[7.5fr_1fr_1.4fr_1.6fr_0.6fr] items-start px-3 cursor-pointer my-3"
            >
              <div
                class="flex"
                @click.stop="
                  activeIntervals[dprDateStrings[dataPointRequest._id]] =
                    !activeIntervals[dprDateStrings[dataPointRequest._id]]
                "
              >
                <ChevronRightIcon
                  v-show="
                    !props.isChilds &&
                    !activeIntervals[dprDateStrings[dataPointRequest._id]]
                  "
                  class="mr-1 w-5"
                />
                <ChevronDownIcon
                  v-show="
                    !props.isChilds &&
                    activeIntervals[dprDateStrings[dataPointRequest._id]]
                  "
                  class="mr-1 w-5"
                />
                <AtDataPointRequestDate
                  class=""
                  :from="dataPointRequest.from"
                  :to="dataPointRequest.to"
                />
              </div>
              <AtBadge v-if="dataPointRequest.isHistoric" type="neutral">
                {{ t('Past data') }}
              </AtBadge>
              <div v-else />
              <MlQuestionInfo
                :dataPointRequest="dataPointRequest"
                :isChilds="isChilds"
              />
            </div>
            <MlQuestionForm
              v-show="activeIntervals[dprDateStrings[dataPointRequest._id]]"
              :dataPointRequest="dataPointRequest"
              :dataPointRequestsWithValueSourceNames="
                dataPointRequestsWithValueSourceNames
              "
              :isGroup="isGroup"
              :canBeDeleted="
                (props.dataPointRequests.length > 1 ||
                  props.dataPointRequests[0].status ===
                    DataPointRequestStatusEnum.REJECTED) &&
                activeIntervals[dprDateStrings[dataPointRequest._id]]
              "
              @closeCollapse="emit('closeCollapse')"
              @click.stop="
                emit('updateActiveQuestion', formatToItem(dataPointRequest))
              "
            />
          </div>
        </div>
        <OgQuestionList
          v-show="activeIntervals[dprDateStrings[dataPointRequest._id]]"
          v-if="hasChildren(dataPointRequest)"
          ref="questionLists"
          :items="getItems(dataPointRequest)"
          :project="props.project"
          :parent="dataPointRequest"
          isChilds
          @updateActiveQuestion="emit('updateActiveQuestion', $event)"
        />
      </div>
    </div>
  </div>
</template>
