import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import type {
  UploadQuestionsJsonMutation,
  UploadQuestionsJsonMutationVariables,
} from '../../../__generated__/types';

export const UPLOAD_QUESTIONS_JSON = gql`
  mutation UploadQuestionsJson(
    $customQuestionnaireId: String!
    $questions: String!
  ) {
    uploadQuestionsJSON(
      customQuestionnaireId: $customQuestionnaireId
      questions: $questions
    ) {
      _id
      status
    }
  }
`;

type Options = UseMutationOptions<
  UploadQuestionsJsonMutation,
  UploadQuestionsJsonMutationVariables
>;

const useUploadQuestionsJsonMutation = (options?: Options) =>
  useMutation<
    UploadQuestionsJsonMutation,
    UploadQuestionsJsonMutationVariables
  >(UPLOAD_QUESTIONS_JSON, options);

export default useUploadQuestionsJsonMutation;
