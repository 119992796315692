import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  ReassignSuperadminMutation,
  ReassignSuperadminMutationVariables,
} from '@/__generated__/types';

export const REASSIGN_SUPERADMIN = gql`
  mutation ReassignSuperadmin($updateUserInput: UpdateUserInput!) {
    reassignSuperadmin(user: $updateUserInput) {
      _id
      firstName
      lastName
      email
      role
    }
  }
`;

type TOptions = UseMutationOptions<
  ReassignSuperadminMutation,
  ReassignSuperadminMutationVariables
>;

const useReassignSuperadminMutation = (options?: TOptions) =>
  useMutation<ReassignSuperadminMutation, ReassignSuperadminMutationVariables>(
    REASSIGN_SUPERADMIN,
    options,
  );

export default useReassignSuperadminMutation;
