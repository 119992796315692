import { ApolloError, type OperationVariables } from '@apollo/client/core';
import { type UseQueryReturn } from '@vue/apollo-composable';
import { watch } from 'vue';

/**
 * Allow to be possible to await query fetching (including lazy loading and refetching).
 * It resolves with data or an error.
 * @see [https://github.com/vuejs/apollo/issues/1486]
 */
export default function waitForApolloQuery<
  TResult,
  TVariables extends OperationVariables,
>({
  onResult,
  onError,
  result: apolloResult,
}: Pick<
  UseQueryReturn<TResult, TVariables>,
  'onResult' | 'onError' | 'result'
>) {
  return new Promise<TResult | ApolloError | undefined>((res) => {
    const { off: offResult } = onResult((result) => {
      res(result.data);
      setTimeout(offResult, 1);
    });

    // for some reason onResult is not called on initial load when using onLazyQuery, so we need to watch the result as well
    watch(apolloResult, () => {
      res(apolloResult.value);
      setTimeout(offResult, 1);
    });

    const { off: offError } = onError((error) => {
      res(error);
      setTimeout(offError, 1);
    });
  });
}
