import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UploadFileMutation,
  UploadFileMutationVariables,
} from '@/__generated__/types';

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFile($csvContent: String!, $locationId: String!) {
    uploadCsvForHistoricImport(
      csvContent: $csvContent
      locationId: $locationId
    ) {
      _id

      dataPointType {
        _id
        name
        friendlyName
        valueDataType
        valueUnit
        valueUnitDivisor
      }
      from
      to
      value
    }
  }
`;

type TOptions = UseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;

const useUploadFileMutation = (options?: TOptions) =>
  useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UPLOAD_FILE_MUTATION,
    options,
  );

export default useUploadFileMutation;
