import { gql } from '@apollo/client/core';

export default gql`
  query TmReportsPDFReport(
    $reportTypes: [PdfReportTypeEnum!]
    $workspaceIds: [String!]
    $dateFrom: DateTime!
    $dateTo: DateTime!
  ) {
    downloadPDFReport(
      reportTypes: $reportTypes
      workspaceIds: $workspaceIds
      dateFrom: $dateFrom
      dateTo: $dateTo
    )
  }
`;
