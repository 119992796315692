import { i18n } from '@/lib/i18n';

const { locale } = i18n.global;

const useFormatNumber = () => {
  const formatNumber = (
    value: string | number | null,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  ) => {
    if (!Number.isNaN(Number(value))) {
      return Number(value).toLocaleString(
        locale.value === 'en' ? 'en-US' : 'de-DE',
        {
          minimumFractionDigits,
          maximumFractionDigits,
        },
      );
    }
    return value;
  };
  return { formatNumber };
};

export default useFormatNumber;
