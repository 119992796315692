import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  RemindUsersAdminMutation,
  RemindUsersAdminMutationVariables,
} from '@/__generated__/types';

export const REMIND_USERS_ADMIN_MUTATION = gql`
  mutation RemindUsersAdmin($type: RemindUsersAdminType!, $_id: String!) {
    remindUsersAdmin(type: $type, _id: $_id)
  }
`;

type TOptions = UseMutationOptions<
  RemindUsersAdminMutation,
  RemindUsersAdminMutationVariables
>;

const useRemindUsersAdminMutation = (options?: TOptions) =>
  useMutation<RemindUsersAdminMutation, RemindUsersAdminMutationVariables>(
    REMIND_USERS_ADMIN_MUTATION,
    options,
  );

export default useRemindUsersAdminMutation;
