import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdateEntityKpiMutation,
  UpdateEntityKpiMutationVariables,
} from '@/__generated__/types';

export const UPDATE_ENTITY_KPI = gql`
  mutation UpdateEntityKPI($updateEntityKPIInput: UpdateEntityKPIInput!) {
    updateEntityKPI(entityKPI: $updateEntityKPIInput) {
      _id
      comparisonValue
      comparisonOperator
      comparisonValueType
      startingDate
      dueDate
      status

      user {
        _id
      }

      dataPointType {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  UpdateEntityKpiMutation,
  UpdateEntityKpiMutationVariables
>;

const useUpdateEntityKPIMutation = (options?: TOptions) =>
  useMutation<UpdateEntityKpiMutation, UpdateEntityKpiMutationVariables>(
    UPDATE_ENTITY_KPI,
    options,
  );

export default useUpdateEntityKPIMutation;
