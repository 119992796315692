<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMounted } from '@vueuse/core';
import { DataPointTypeRefreshIntervalEnum } from '@/__generated__/types';
import dayjs from '@/lib/dayjs/config';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import MlStandardsBadges from '@/components/molecules/MlStandardsBadges.vue';
import type { QuestionForm } from './types';
import { getReportingFrameworks } from './utils';

type Props = {
  question: QuestionForm['question'];
  framework: QuestionForm['framework'];
  frameworkGroup: QuestionForm['frameworkGroup'];
  refreshInterval: QuestionForm['refreshInterval'];
};

const props = defineProps<Props>();

const { t } = useI18n();
const { activeEntity } = useCurrentUser();
const isMounted = useMounted();

const reportingFrameworksData = computed(() => {
  return activeEntity.value
    ? getReportingFrameworks(
        activeEntity.value,
        props.framework,
        props.frameworkGroup,
      )
    : null;
});

const date = computed(() => {
  const fromDate = dayjs();
  let toDate: dayjs.Dayjs | null;
  switch (props.refreshInterval) {
    case DataPointTypeRefreshIntervalEnum.DAILY:
      toDate = fromDate.add(1, 'day');
      break;
    case DataPointTypeRefreshIntervalEnum.WEEKLY:
      toDate = fromDate.add(1, 'week');
      break;
    case DataPointTypeRefreshIntervalEnum.MONTHLY:
      toDate = fromDate.add(1, 'month');
      break;
    case DataPointTypeRefreshIntervalEnum.QUARTERLY:
      toDate = fromDate.add(1, 'quarter');
      break;
    case DataPointTypeRefreshIntervalEnum.HALF_YEARLY:
      toDate = fromDate.add(6, 'months');
      break;
    case DataPointTypeRefreshIntervalEnum.YEARLY:
      toDate = fromDate.add(1, 'year');
      break;
    default:
      toDate = null;
      break;
  }

  return {
    from: fromDate.toISOString(),
    to: toDate?.toISOString(),
  };
});
</script>

<template>
  <Teleport v-if="isMounted" to="#customQuestionnairePreviewSection">
    <div>
      <div class="mb-6">
        <p class="text-sm text-blue-600">
          {{ question }}
        </p>
        <MlStandardsBadges
          v-if="reportingFrameworksData"
          :activeReportingFramework="
            reportingFrameworksData.activeReportingFramework
          "
          :reportingFrameworks="reportingFrameworksData.reportingFrameworks"
        />
      </div>
      <div class="flex gap-2">
        <div class="flex-initial w-1/4 text-gray-400 text-sm pt-2">
          <AtDataPointRequestDate :from="date.from" :to="date.to" />
        </div>
        <div class="flex-1 w-3/4">
          <div class="mb-16">
            <slot />
          </div>
          <div class="text-right">
            <AtButton type="button" class="">
              {{ t('Submit') }}
            </AtButton>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
