<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import CheckIcon from '@heroicons/vue/outline/CheckIcon';
import ChevronDownIcon from '@heroicons/vue/solid/ChevronDownIcon';
import ChevronRightIcon from '@heroicons/vue/solid/ChevronRightIcon';
import XCircleIcon from '@heroicons/vue/outline/XCircleIcon';
import {
  type PgTaxonomyAssessmentQuery,
  TaxonomyAssessmentBusinessActivityObjectiveEnum,
} from '@/__generated__/types';
import useUpdateTaxonomyAssessmentBusinessActivity from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtYesNoInput from '@/components/atoms/AtYesNoInput.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import type { BusinessActivity } from '../../types';
import OgDocumentComment from '../../OgDocumentComment.vue';
import OgObjectiveMenu from './OgObjectiveMenu.vue';

type DoesNotHarm =
  PgTaxonomyAssessmentQuery['getTaxonomyAssessment']['businessActivities'][0]['doesNotHarm'];

type DNSHFormData = {
  adaptation: {
    value: boolean | null;
    file: TPartialRepositoryFile | null;
    comment: string;
  };
  biodiversity: {
    value: boolean | null;
    file: TPartialRepositoryFile | null;
    comment: string;
  };
  circular: {
    value: boolean | null;
    file: TPartialRepositoryFile | null;
    comment: string;
  };
  mitigation: {
    value: boolean | null;
    file: TPartialRepositoryFile | null;
    comment: string;
  };
  pollution: {
    value: boolean | null;
    file: TPartialRepositoryFile | null;
    comment: string;
  };
  water: {
    value: boolean | null;
    file: TPartialRepositoryFile | null;
    comment: string;
  };
};

type ObjectiveItem = {
  key: TaxonomyAssessmentBusinessActivityObjectiveEnum;
  label: string;
};

const props = defineProps<{
  assessmentId: string;
  businessActivity: BusinessActivity;
  isExpended: boolean;
}>();

const emit = defineEmits<{
  toggle: [id: string];
  saved: [id: string];
}>();

const { t } = useI18n();
const { confirmViaDialog } = useConfirmViaDialog();

const {
  loading: isSaving,
  mutate: updateActivity,
  error,
} = useUpdateTaxonomyAssessmentBusinessActivity();

const formData = reactive<DNSHFormData>({
  adaptation: { value: null, file: null, comment: '' },
  biodiversity: { value: null, file: null, comment: '' },
  circular: { value: null, file: null, comment: '' },
  mitigation: { value: null, file: null, comment: '' },
  pollution: { value: null, file: null, comment: '' },
  water: { value: null, file: null, comment: '' },
});

watch(
  () => props.businessActivity.doesNotHarm,
  (newDoesNotHarm) => {
    if (newDoesNotHarm) {
      formData.adaptation.value = newDoesNotHarm.adaptation ?? null;
      formData.adaptation.file =
        newDoesNotHarm.adaptationDocument?.file ?? null;
      formData.adaptation.comment = newDoesNotHarm.adaptationComment ?? '';

      formData.biodiversity.value = newDoesNotHarm.biodiversity ?? null;
      formData.biodiversity.file =
        newDoesNotHarm.biodiversityDocument?.file ?? null;
      formData.biodiversity.comment = newDoesNotHarm.biodiversityComment ?? '';

      formData.circular.value = newDoesNotHarm.circular ?? null;
      formData.circular.file = newDoesNotHarm.circularDocument?.file ?? null;
      formData.circular.comment = newDoesNotHarm.circularComment ?? '';

      formData.mitigation.value = newDoesNotHarm.mitigation ?? null;
      formData.mitigation.file =
        newDoesNotHarm.mitigationDocument?.file ?? null;
      formData.mitigation.comment = newDoesNotHarm.mitigationComment ?? '';

      formData.pollution.value = newDoesNotHarm.pollution ?? null;
      formData.pollution.file = newDoesNotHarm.pollutionDocument?.file ?? null;
      formData.pollution.comment = newDoesNotHarm.pollutionComment ?? '';

      formData.water.value = newDoesNotHarm.water ?? null;
      formData.water.file = newDoesNotHarm.waterDocument?.file ?? null;
      formData.water.comment = newDoesNotHarm.waterComment ?? '';
    }
  },
  { immediate: true },
);
watch(error, (newError) => {
  if (newError) {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
});

const objectives = computed<ObjectiveItem[]>(() => [
  {
    key: TaxonomyAssessmentBusinessActivityObjectiveEnum.adaptation,
    label: t('Climate change adaptation'),
  },
  {
    key: TaxonomyAssessmentBusinessActivityObjectiveEnum.mitigation,
    label: t('Climate change mitigation'),
  },
  {
    key: TaxonomyAssessmentBusinessActivityObjectiveEnum.water,
    label: t('Sustainable use and protection of water and marine resources'),
  },
  {
    key: TaxonomyAssessmentBusinessActivityObjectiveEnum.circular,
    label: t('Transition to a circular economy'),
  },
  {
    key: TaxonomyAssessmentBusinessActivityObjectiveEnum.pollution,
    label: t('Pollution prevention and control'),
  },
  {
    key: TaxonomyAssessmentBusinessActivityObjectiveEnum.biodiversity,
    label: t('Protection and restoration of biodiversity and ecosystems'),
  },
]);

const isAnswered = computed(() =>
  (Object.keys(props.businessActivity.doesNotHarm) as Array<keyof DoesNotHarm>)
    .filter(
      (key) =>
        key !== props.businessActivity.objective &&
        [
          'circular',
          'adaptation',
          'mitigation',
          'pollution',
          'biodiversity',
          'water',
        ].includes(key),
    )
    .every((key) => props.businessActivity.doesNotHarm[key] !== null),
);
const isApproved = computed(() =>
  (Object.keys(props.businessActivity.doesNotHarm) as Array<keyof DoesNotHarm>)
    .filter(
      (key) =>
        key !== props.businessActivity.objective &&
        [
          'circular',
          'adaptation',
          'mitigation',
          'pollution',
          'biodiversity',
          'water',
        ].includes(key),
    )
    .every((key) => props.businessActivity.doesNotHarm[key]),
);

function handleToggle() {
  emit('toggle', props.businessActivity._id);
}

async function handleSave() {
  let isConfirmed: boolean;

  const areAllAnswered = (Object.keys(formData) as Array<keyof DNSHFormData>)
    .filter(
      (key) =>
        key !== props.businessActivity.objective &&
        [
          'circular',
          'adaptation',
          'mitigation',
          'pollution',
          'biodiversity',
          'water',
        ].includes(key),
    )
    .every((key) => formData[key].value !== null);

  if (!areAllAnswered) {
    isConfirmed = await confirmViaDialog({
      title: t(
        'You failed to confirm all conditions for “Do No Significant Harm”',
      ),
      text: `<p>${t('In this activity, you did not confirm all conditions for not doing significant harm to all five remaining objectives of the EU-Taxonomy. This means that this activity will no longer be deemed aligned with the EU-Taxonomy assessment and we will remove it from the next steps of the process.')}</p><br /><p>${t('Are you sure you want to proceed without confirming the conditions?')}</p>`,
      renderHTML: true,
      confirmLabel: t('Yes, proceed'),
      cancelLabel: t('No, go back'),
      titleClass: 'text-rose-600',
      modalClass: 'bg-rose-50',
    });
  } else {
    isConfirmed = true;
  }

  if (isConfirmed) {
    await updateActivity({
      assessmentId: props.assessmentId,
      businessActivityId: props.businessActivity._id,
      input: {
        doesNotHarm: {
          adaptation: formData.adaptation.value ?? false,
          adaptationComment: formData.adaptation.comment || null,
          adaptationFileId: formData.adaptation.file?._id ?? null,
          biodiversity: formData.biodiversity.value ?? false,
          biodiversityComment: formData.biodiversity.comment || null,
          biodiversityFileId: formData.biodiversity.file?._id ?? null,
          circular: formData.circular.value ?? false,
          circularComment: formData.circular.comment || null,
          circularFileId: formData.circular.file?._id ?? null,
          mitigation: formData.mitigation.value ?? false,
          mitigationComment: formData.mitigation.comment || null,
          mitigationFileId: formData.mitigation.file?._id ?? null,
          pollution: formData.pollution.value ?? false,
          pollutionComment: formData.pollution.comment || null,
          pollutionFileId: formData.pollution.file?._id ?? null,
          water: formData.water.value ?? false,
          waterComment: formData.water.comment || null,
          waterFileId: formData.water.file?._id ?? null,
          ...(props.businessActivity.objective
            ? {
                [props.businessActivity.objective]: true,
                [`${props.businessActivity.objective}Comment`]: null,
                [`${props.businessActivity.objective}FileId`]: null,
              }
            : {}),
        },
      },
    });
    emit('saved', props.businessActivity._id);
  }
}
</script>

<template>
  <div
    class="grid grid-cols-10"
    :class="{
      'border-b': !isExpended,
    }"
  >
    <div
      class="col-span-8 font-normal py-4"
      :class="{
        'text-gray-500': !isExpended,
        'text-blue-600': isExpended,
      }"
    >
      <div
        class="flex items-center space-x-2 cursor-pointer hover:text-blue-600"
        @click="handleToggle"
      >
        <div class="flex-0">
          <ChevronRightIcon v-if="!isExpended" class="w-5" />
          <ChevronDownIcon v-else class="w-5" />
        </div>
        <div class="flex-1">
          {{ businessActivity.activity.number }}:
          {{ t(businessActivity.activity.name) }}
        </div>
      </div>
    </div>
    <div v-if="isAnswered" class="flex items-center justify-center">
      <CheckIcon v-if="isApproved" class="h-5 text-emerald-500" />
      <XCircleIcon v-else class="h-5 text-rose-600" />
    </div>
  </div>
  <Transition name="vertical-fade-slide">
    <div v-if="isExpended" class="border-b max-h-[426px] overflow-auto">
      <div>
        <div
          v-for="objectiveItem of objectives"
          :key="objectiveItem.key"
          class="group"
        >
          <div
            v-if="objectiveItem.key !== businessActivity.objective"
            class="grid grid-cols-10"
          >
            <div class="col-span-8 pl-10">
              <div class="py-4 border-b group-last:border-b-0">
                <p class="mb-3 text-sm">
                  {{ objectiveItem.label }}
                </p>
                <MlHtmlContent
                  class="text-sm mb-3"
                  :html="
                    t(
                      businessActivity.activity.descriptions.find(
                        (description) =>
                          description.objective ===
                            businessActivity.objective &&
                          description.key === objectiveItem.key,
                      )?.value ?? '<p>N/A</p>',
                    )
                  "
                />
                <OgDocumentComment
                  v-model:comment="formData[objectiveItem.key].comment"
                  v-model:file="formData[objectiveItem.key].file"
                  showRecentFiles
                />
              </div>
            </div>
            <div class="border-b group-last:border-b-0">
              <div class="flex items-center justify-center space-x-6">
                <AtYesNoInput v-model="formData[objectiveItem.key].value" />
              </div>
            </div>
            <div
              class="border-b group-last:border-b-0 pt-3 flex justify-center"
            >
              <OgObjectiveMenu
                :assessmentId="assessmentId"
                :businessActivity="businessActivity"
                :type="objectiveItem.key"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-10">
        <div class="col-start-10 flex justify-center pb-6">
          <AtButton
            type="button"
            class="mx-auto"
            :loading="isSaving"
            @click="handleSave"
          >
            {{ t('Save') }}
          </AtButton>
        </div>
      </div>
    </div>
  </Transition>
</template>
