import { gql } from '@apollo/client/core';

export default gql`
  query useCurrentUser {
    getTotalsForAssigments {
      assignedTopicsTotal
      assigmentsTotal
    }

    getAuthenticatedUser {
      _id
      email
    }

    getOwnUser {
      _id
      firstName
      lastName
      jobTitle
      jobDepartment
      email
      phone
      role
      language
      isExternal
      picture {
        _id
        downloadUrl
      }
      onboardingStatus
      getStartedStepsCompleted
      assignments {
        _id
        categories {
          category
          role
          location {
            _id
            name
          }
        }
        subcategories {
          subcategory
          role
          location {
            _id
            name
          }
        }
        datapointtypes {
          dataPointType {
            _id
            name
          }
          role
          location {
            _id
            name
          }
        }
        doubleMateriality
      }
      entities {
        _id
        name
        reportingFrameworks
        entitySettings {
          _id
          notificationSettings {
            _id
            recommendationsVisibility
          }
        }
      }

      entity {
        _id
        name
        reportingFrameworks

        organization {
          _id
          name
        }

        entitySettings {
          _id
          companyName
          notificationSettings {
            _id
            recommendationsVisibility
          }
        }

        locations {
          _id
          name
          isHeadquarters
          areCategoriesAppliedOnAllProjects
          areCustomCategoriesAppliedOnAllProjects
          assignments {
            _id
            categories {
              _id
              category
              subcategories {
                _id
                subcategory
              }
            }
          }
        }
      }
    }
  }
`;
