<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, reactive, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlModal from '@/components/molecules/MlModal.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useCreateTaxonomyProjectMutation from '@/api/mutations/Taxonomy/createTaxonomyProject.mutation';
import type { MlTaxonomyProjectModalQuery } from '@/__generated__/types';
import useUpdateTaxonomyProjectMutation from '@/api/mutations/Taxonomy/updateTaxonomyProject.mutation';
import ML_TAXONOMY_PROJECT_MODAL_QUERY from './MlTaxonomyProjectModal.query';
import type { TaxonomyProject } from './types';

type TProps = {
  isShown: boolean;
  project?: TaxonomyProject;
};

const props = withDefaults(defineProps<TProps>(), {
  isShown: false,
  project: undefined,
});

const emit = defineEmits(['close']);

const { t } = useI18n();

const { mutate: createProject, loading: addProjectLoading } =
  useCreateTaxonomyProjectMutation({
    update: (store) => {
      store.evict({ fieldName: 'getTaxonomyProjects' });
    },
  });
const { mutate: updateProject, loading: updateProjectLoading } =
  useUpdateTaxonomyProjectMutation({
    update: (store) => {
      store.evict({ fieldName: 'getTaxonomyProjects' });
    },
  });
const { result } = useQuery<MlTaxonomyProjectModalQuery>(
  ML_TAXONOMY_PROJECT_MODAL_QUERY,
);

const ownerOptions = computed(
  () =>
    result.value?.getTeamUsers.reduce(
      (acc, curr) => ({
        ...acc,
        [curr._id]: `${curr.firstName} ${curr.lastName}`,
      }),
      {},
    ) ?? {},
);

const formData = reactive<{ name: string; assignees: string[] }>({
  name: '',
  assignees: [],
});
watch(
  () => props.project,
  () => {
    formData.assignees =
      props.project?.assignees.map((assignee) => assignee._id) || [];
    formData.name = props.project?.name ?? '';
  },
);

const resetForm = () => {
  formData.name = '';
  formData.assignees = [];
};

const addProject = async () => {
  await createProject({
    createTaxonomyProjectInput: {
      name: formData.name,
      assignees: formData.assignees,
    },
  });

  resetForm();
  emit('close');
};

const editProject = async (id: string) => {
  await updateProject({
    updateTaxonomyProjectInput: {
      _id: id,
      assignees: formData.assignees,
      name: formData.name,
    },
  });

  resetForm();
  emit('close');
};
</script>

<template>
  <MlModal
    :isRevealed="props.isShown"
    class="overflow-y-visible"
    @close="emit('close')"
  >
    <AtHeading type="h2" class="mb-3 border-b pb-2">
      {{ t('Add a project') }}
    </AtHeading>
    <div class="mt-2 gap-3 w-96">
      <div class="">
        <AtInput
          v-model="formData.name"
          :label="t('Name of the project')"
          :placeholder="t('My project')"
          class="mb-3"
        />
      </div>
      <div class="">
        <MlSelect
          v-model="formData.assignees"
          :label="t('Project assignees')"
          :placeholder="t('Choose assignees')"
          :options="ownerOptions"
          class="mb-3"
          multiple
        />
      </div>
    </div>
    <div class="modal-action flex items-end justify-between align-bottom">
      <AtButton variant="outline" @click="emit('close')">
        {{ t('Cancel') }}
      </AtButton>
      <AtButton
        v-if="props.project"
        :loading="updateProjectLoading"
        @click="editProject(props.project._id)"
      >
        {{ t('Save changes') }}
      </AtButton>
      <AtButton v-else :loading="addProjectLoading" @click="addProject">
        {{ t('Add') }}
      </AtButton>
    </div>
  </MlModal>
</template>
