<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { RestrictionsFeaturesEnum } from '@/__generated__/types';
import OgRestrictionsLanding from '@/components/organisms/OgRestrictionsLanding.vue';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';

const CARBON_ACCOUNTING = 'carbon_accounting';

const route = useRoute();
const { t, locale } = useI18n();

const { restrictions, loading: restrictionsLoading } = useRestrictions();

const showCarbonAccountingInfo = computed(() => {
  return (
    route.query.category === CARBON_ACCOUNTING &&
    !restrictions.value?.restrictedFeatures.includes(
      RestrictionsFeaturesEnum.CARBON_ACCOUNTING,
    ) &&
    !restrictionsLoading
  );
});
</script>

<template>
  <OgRestrictionsLanding v-if="showCarbonAccountingInfo">
    <template #pageTitle>
      {{ t('Carbon accounting') }}
    </template>
    <template #title>
      {{ t('Assess and manage carbon emissions') }}
    </template>
    <template #text>
      {{
        t('Evaluate your contribution to the global greenhouse gas emissions.')
      }}<br />
      {{ t('Calculate your carbon footprint for scope 1, 2 and 3.') }}
    </template>
    <template #image>
      <img
        v-if="locale === 'de'"
        src="@/assets/images/carbon-accounting-de.png"
        alt="Treibhausgasbilanz"
      />
      <img
        v-else
        alt="Carbon accounting"
        src="@/assets/images/carbon-accounting.png"
      />
    </template>
  </OgRestrictionsLanding>
  <router-view v-else :key="String(route.params.project)" />
</template>
