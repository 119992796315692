<script lang="ts" setup>
interface TProps {
  count?: number;
  accent?: boolean; // Accent the component
}

const props = withDefaults(defineProps<TProps>(), {
  count: 0,
  accent: false,
});

const accentStyle = ['text-primary', 'bg-blue-50'];

const defaultStyle = ['text-gray', 'bg-gray-50'];
</script>

<template>
  <span
    class="rounded-md p-1 h-6 flex items-center font-semibold"
    :class="accent ? accentStyle : defaultStyle"
  >
    {{ props.count }}
  </span>
</template>
