import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  AnswerDataPointRequestMutation,
  AnswerDataPointRequestMutationVariables,
} from '@/__generated__/types';

export const ANSWER_DATAPOINT_REQUEST = gql`
  mutation AnswerDataPointRequest(
    $answerDataPointRequestDto: AnswerDataPointRequestInput!
  ) {
    answerDataPointRequest(
      AnswerDataPointRequestInput: $answerDataPointRequestDto
    ) {
      _id
      status
      displayStatus
      value
      originalValue

      valueSource {
        name
        originalValue
        originalValueUnit
        emissionFactor
        emissionFactorCountry
      }

      dueDate
      comment
      file {
        _id
        filename
      }

      childs {
        _id
        dueDate
        from
        to
        value
        originalValue
        status
        displayStatus

        dataPointType {
          _id
          allowUploadProof
          question
          valueUnit
          valueUnitDivisor
          valueDataType
          valueDataTypeOptions
        }

        dataPointTypeFlat {
          subcategory
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  AnswerDataPointRequestMutation,
  AnswerDataPointRequestMutationVariables
>;

const useAnswerDataPointRequestMutation = (options?: TOptions) =>
  useMutation<
    AnswerDataPointRequestMutation,
    AnswerDataPointRequestMutationVariables
  >(ANSWER_DATAPOINT_REQUEST, options);

export default useAnswerDataPointRequestMutation;
