<script setup lang="ts">
import { computed, type HTMLAttributes } from 'vue';
import { v4 } from 'uuid';
import { useI18n } from 'vue-i18n';
import type { ErrorObject } from '@vuelidate/core';
import unwrapVuelidateErrorMessages from '@/utils/helpers/unwrapVuelidateErrorMessages';
import { CheckboxSize } from './types';

interface TProps {
  label?: string;
  wrapperClass?: HTMLAttributes['class'];
  labelClass?: string;
  errors?: string[] | ErrorObject[];
  checked?: boolean;
  size?: `${CheckboxSize}`;
  disabled?: boolean;
  required?: boolean;
  squared?: boolean;
  id?: string;
  vertical?: boolean;
  inputClass?: string;
}

const props = withDefaults(defineProps<TProps>(), {
  label: '',
  wrapperClass: '',
  labelClass: 'pl-2',
  checked: false,
  disabled: false,
  size: CheckboxSize.SM,
  errors: () => [],
  required: false,
  squared: false,
  id: undefined,
  vertical: false,
  inputClass: '',
});

const { t } = useI18n();

const id = computed(() => props.id ?? v4());

const emit = defineEmits(['toggleCheckbox']);
</script>

<template>
  <div :class="wrapperClass">
    <div class="form-control">
      <label
        class="label"
        :class="{
          'cursor-pointer': !props.disabled,
          'justify-start': !vertical,
          'flex-col-reverse justify-center': vertical,
        }"
      >
        <input
          :id="id"
          type="checkbox"
          :checked="props.checked"
          :disabled="props.disabled"
          :required="props.required"
          :class="[
            size,
            { 'border-error': props.errors.length },
            { 'rounded-sm': props.squared },
            inputClass,
          ]"
          class="checkbox-primary checkbox"
          @click="
            emit('toggleCheckbox', ($event.target as HTMLInputElement).checked)
          "
        />
        <span
          class="label-text break-normal"
          :class="[
            props.labelClass,
            {
              'cursor-not-allowed text-gray-400': props.disabled,
              '!pl-0 mb-0.5': vertical,
            },
          ]"
        >
          <slot name="label">
            {{ t(props.label) }}
          </slot>
        </span>
      </label>
      <slot name="text" />
    </div>
    <p
      v-for="error in unwrapVuelidateErrorMessages(props.errors)"
      :key="error"
      class="mt-2 text-xs text-error"
    >
      {{ error }}
    </p>
  </div>
</template>
