import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  RequestEsgReportMutation,
  RequestEsgReportMutationVariables,
} from '@/__generated__/types';

export const REQUEST_ESG_REPORT = gql`
  mutation RequestEsgReport($entityIds: [String!]!) {
    requestEsgReport(entityIds: $entityIds) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  RequestEsgReportMutation,
  RequestEsgReportMutationVariables
>;

const useRequestEsgReportMutation = (options?: TOptions) =>
  useMutation<RequestEsgReportMutation, RequestEsgReportMutationVariables>(
    REQUEST_ESG_REPORT,
    options,
  );

export default useRequestEsgReportMutation;
