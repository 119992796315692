<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import difference from 'lodash/difference';
import flatMap from 'lodash/flatMap';
import { useRouter } from 'vue-router';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import type {
  TmSectorActivitiesQuery,
  PgTaxonomyAssessmentQuery,
} from '@/__generated__/types';
import useAddTaxonomyAssessmentSector from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentSectors.mutation';
import useAddTaxonomyAssessmentActivity from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentActivities.mutation';
import TM_SECTOR_ACTIVITIES_QUERY from './TmSectorActivities.query';

type TProps = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment'];
};

const { t } = useI18n();
const router = useRouter();
const props = defineProps<TProps>();

const { result } = useQuery<TmSectorActivitiesQuery>(
  TM_SECTOR_ACTIVITIES_QUERY,
);
const { mutate: updateSectors, loading: loadingUpdateSectors } =
  useAddTaxonomyAssessmentSector();
const { mutate: updateActivities, loading: loadingUpdateActivities } =
  useAddTaxonomyAssessmentActivity();

const availableSectors = computed(
  () =>
    result.value?.getTaxonomySectors.reduce(
      (acc, curr) => ({
        ...acc,
        [curr._id]: curr.name,
      }),
      {},
    ) ?? {},
);

const availableActivities = computed(() => ({
  ...selectedSectors.value.reduce(
    (acc, curr) => ({
      ...acc,
      ...(result.value?.getTaxonomySectors
        .find((sector) => sector._id === curr)
        ?.activities.reduce(
          (accActivities, currActivity) => ({
            ...accActivities,
            [currActivity._id]: currActivity.number
              ? `${currActivity.number}: ${t(currActivity.name)}`
              : `${t(currActivity.name)}`,
          }),
          {},
        ) ?? {}),
    }),
    {},
  ),
}));

const selectedSectors = ref<string[]>([]);
const selectedActivities = ref<string[]>([]);

onMounted(() => {
  selectedSectors.value = props.assessment.sectors.map((sector) => sector._id);
  selectedActivities.value = flatMap(
    props.assessment.businessActivities.map(
      (activity) => activity.activity._id,
    ),
  );
});

const next = async () => {
  const persistedSectorIds = Object.values(
    props.assessment.sectors.map((s) => s._id),
  );
  const sectorsTouched =
    difference(selectedSectors.value, persistedSectorIds).length > 0 ||
    selectedSectors.value.length !== persistedSectorIds.length;

  const persistedActivityIds = Object.values(
    props.assessment.businessActivities.map((s) => s.activity._id),
  );
  const activitiesTouched =
    difference(selectedActivities.value, persistedActivityIds).length > 0 ||
    selectedActivities.value.length !== persistedActivityIds.length;

  if (sectorsTouched) {
    await updateSectors({
      sectorIds: selectedSectors.value,
      assessmentId: props.assessment._id,
    });
  }

  if (activitiesTouched) {
    await updateActivities({
      activityIds: selectedActivities.value,
      assessmentId: props.assessment._id,
    });
  }

  router.push({
    name: 'taxonomyAssessmentBusinessActivitiesTurnover',
  });
};
</script>

<template>
  <div>
    <MlSelect
      v-model="selectedSectors"
      :label="t('Select your sector(s)')"
      :placeholder="t('Select sectors')"
      :options="availableSectors"
      :multiple="true"
      :allowSelectNone="false"
      class="mb-5"
    />

    <MlAutocomplete
      v-model="selectedActivities"
      :allowSelectNone="false"
      :multiple="true"
      :label="
        t(
          'Choose one or several business activities which are undertaken in this project',
        )
      "
      :placeholder="t('Search or select activities')"
      :options="availableActivities"
      sortedOptions
    />
  </div>

  <div class="flex justify-end">
    <AtButton
      class="my-4 float-right"
      :disabled="!selectedActivities.length || !selectedSectors.length"
      :loading="loadingUpdateActivities || loadingUpdateSectors"
      @click="next"
    >
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
