import { ref, computed, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { notify } from '@kyvg/vue3-notification';
import { useI18n } from 'vue-i18n';
import { apolloClient } from '@/api/apollo/client';
import { type GetRestrictionsForOrganizationQuery } from '@/__generated__/types';

import GET_RESTRICTIONS_QUERY from './useRestrictions.query';

export const restrictions =
  ref<GetRestrictionsForOrganizationQuery['restrictions']>();

const useRestrictions = () => {
  const {
    result,
    loading: getRestrictionsLoading,
    error: getRestrictionsError,
    ...getRestrictionsReturn
  } = useQuery(GET_RESTRICTIONS_QUERY);

  const loading = computed(() => getRestrictionsLoading.value);
  const error = computed(() => getRestrictionsError.value);

  watch(result, () => {
    restrictions.value = result.value?.restrictions;
  });

  return {
    ...getRestrictionsReturn,
    restrictions,
    loading,
    error,
  };
};

export const fetchRestrictions = async () => {
  const { t } = useI18n();
  try {
    const result = await apolloClient.query({
      query: GET_RESTRICTIONS_QUERY,
    });

    restrictions.value = result.data.restrictions;

    return restrictions.value;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :('),
    });
  }
};

export default useRestrictions;
