<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { ErrorObject } from '@vuelidate/core';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';

type Props = {
  modelValue: string;
  errors?: string[] | ErrorObject[];
};

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
});
const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

const totalAllEmployeesOptions = {
  '0 - 49': '0 - 49',
  '50 - 99': '50 - 99',
  '100 - 249': '100 - 249',
  '250 - 499': '250 - 499',
  '500 - 999': '500 - 999',
  '1000 - 2999': '1000 - 2999',
  '3000+': '3000+',
};
</script>

<template>
  <MlSelect
    :label="t('Employee number')"
    :options="totalAllEmployeesOptions"
    sortedOptions
    :modelValue="props.modelValue"
    :errors="props.errors"
    data-cy="companyDpr"
    @update:modelValue="emit('update:modelValue', $event)"
  />
</template>

<style scoped lang="postcss"></style>
