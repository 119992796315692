import isNaN from 'lodash/isNaN';
import { TaxonomyAssessmentStatusEnum } from '@/__generated__/types';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import type { AssessmentStep } from './types';

const { formatNumber } = useFormatNumber();

export const getAssessmentStep = (
  assessmentStatus: TaxonomyAssessmentStatusEnum,
): AssessmentStep => {
  const {
    TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES,
    TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES_TURNOVER,
    TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES_CAPEX,
    TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES_OPEX,
    TAXONOMY_ASSESSMENT_DOCUMENTS,
    TAXONOMY_ASSESSMENT_DO_NO_SIGNIFICANT_HARM,
    TAXONOMY_ASSESSMENT_MINIMUM_SAFEGUARDS,
    TAXONOMY_ASSESSMENT_SUBSTANTIAL_CONTRIBUTION,
  } = TaxonomyAssessmentStatusEnum;

  switch (assessmentStatus) {
    case TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES:
      return 'taxonomyAssessmentBusinessActivities';
    case TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES_TURNOVER:
      return 'taxonomyAssessmentBusinessActivitiesTurnover';
    case TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES_CAPEX:
      return 'taxonomyAssessmentBusinessActivitiesCapex';
    case TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES_OPEX:
      return 'taxonomyAssessmentBusinessActivitiesOpex';
    case TAXONOMY_ASSESSMENT_DOCUMENTS:
      return 'taxonomyAssessmentDocuments';
    case TAXONOMY_ASSESSMENT_DO_NO_SIGNIFICANT_HARM:
      return 'taxonomyAssessmentDoNoSignificantHarm';
    case TAXONOMY_ASSESSMENT_MINIMUM_SAFEGUARDS:
      return 'taxonomyAssessmentMinimumSafeguards';
    case TAXONOMY_ASSESSMENT_SUBSTANTIAL_CONTRIBUTION:
      return 'taxonomyAssessmentSubstantialContribution';
    default:
      return 'taxonomyAssessmentBusinessActivities';
  }
};

export const formatNumberForTable = (value: number) => {
  if (undefined === value) return '-';

  const formattedNumber = String(formatNumber(value));

  if (formattedNumber.length > 0 && value > 0) {
    return `${formattedNumber} Euro`;
  }

  return '-';
};
export const formatPercentage = (x: number, y: number) => {
  if (y <= 0 || x <= 0) return '-';
  const res = (x / y) * 100;
  return isNaN(res) ? '-' : `${formatNumber(res)}%`;
};
