<script setup lang="ts">
import { computed, ref, useSlots } from 'vue';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/solid';
import { useI18n } from 'vue-i18n';

interface Props {
  fields: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Record<string, any>[];
  expandAllRows?: boolean;
  borders?: boolean;
  showExpandIcon?: boolean;
  isFixedLayout?: boolean;
  secondStyle?: boolean;
  customEmptyStateText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  fields: () => [],
  items: () => [],
  borders: false,
  showExpandIcon: true,
  expandAllRows: false,
  isFixedLayout: false,
  secondStyle: false,
  customEmptyStateText: undefined,
});

const slots = useSlots();

const itemSlots = computed(() =>
  Object.keys(slots).filter((name) => name.startsWith('item-')),
);
const expandableRowSlots = computed(() =>
  Object.keys(slots).filter((name) => !name.startsWith('item-')),
);
const showExpandIcon = computed(
  () =>
    Object.keys(slots).find((name) => name === 'expand') &&
    props.showExpandIcon,
);
const expandedRows = ref<boolean[]>([]);

const { t } = useI18n();
</script>

<template>
  <table
    class="w-full m-0"
    :class="[
      { 'table-fixed': props.isFixedLayout && items.length },
      { 'mt-4 border-0': props.secondStyle },
    ]"
  >
    <thead>
      <tr class="border-y h-16" :class="{ 'bg-gray-200': props.secondStyle }">
        <th
          v-if="showExpandIcon || props.expandAllRows"
          :class="{ 'w-6': props.isFixedLayout }"
        />
        <th
          v-for="field in fields"
          :key="field"
          class="text-gray-400 text-sm text-left p-6 uppercase border-collapse border-t-1 border-b border-color-gray-200"
        >
          {{ t(field) }}
        </th>
      </tr>
    </thead>
    <template v-if="items.length > 0">
      <template v-for="(item, index) in items" :key="item.id">
        <tr
          class="border-collapseborder border-gray-200 h-16"
          :class="[
            { 'border-b': props.borders },
            {
              'last-of-type:border-b-0 last-of-type:border-t-4 last-of-type:border-double last-of-type:border-gray-400':
                props.secondStyle,
            },
          ]"
        >
          <td v-if="showExpandIcon || props.expandAllRows">
            <button
              v-if="showExpandIcon"
              class="h-6 w-6 flex"
              @click="() => (expandedRows[index] = !expandedRows[index])"
            >
              <ChevronDownIcon v-if="expandedRows[index]" />
              <ChevronRightIcon v-else />
            </button>
          </td>
          <td v-for="name in itemSlots" :key="name" class="p-6 text-sm">
            <slot :name="name" v-bind="item" />
          </td>
        </tr>
        <template v-for="name in expandableRowSlots" :key="name">
          <template v-if="expandedRows[index] || props.expandAllRows">
            <slot :name="name" v-bind="item" />
          </template>
        </template>
      </template>
    </template>
    <template v-else>
      <tr>
        <td class="text-center" colspan="100%">
          <p class="mt-4 text-sm">
            {{ customEmptyStateText || t('No available data') }}
          </p>
        </td>
      </tr>
    </template>
  </table>
</template>
