<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { notify } from '@kyvg/vue3-notification';
import { HelpTypeEnum } from '@/__generated__/types';
import useRequestHelp from '@/api/mutations/Help/requestHelp.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';

type FormData = {
  message: string;
  phoneOrEmail: string;
  type: string | undefined;
};

const emit = defineEmits<{
  close: [];
}>();

const { t } = useI18n();

const { loading: isSubmitting, mutate } = useRequestHelp();

const showConfirmation = ref(false);

const typeOptions = computed(() => ({
  technical: t(
    'Technical (anything related to the platform and technical issues)',
  ),
  expert: t('Expert (anything related to the content)'),
}));

const formData = reactive<FormData>({
  message: '',
  phoneOrEmail: '',
  type: undefined,
});

const validationRules = computed(() => ({
  message: {
    required: helpers.withMessage(t('Message is required.'), required),
  },
  phoneOrEmail: {
    required: helpers.withMessage(
      t('Phone number or email address is required.'),
      required,
    ),
  },
  type: {
    required: helpers.withMessage(t('Type is required.'), required),
  },
}));
const v$ = useVuelidate(validationRules, formData);

function handleClose() {
  emit('close');
}

async function handleSubmit() {
  try {
    await mutate({
      message: formData.message,
      phoneOrEmail: formData.phoneOrEmail,
      type:
        formData.type === 'technical'
          ? HelpTypeEnum.TECHNICAL
          : HelpTypeEnum.EXPERT,
    });
    showConfirmation.value = true;
  } catch {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
}
</script>

<template>
  <MlDialog
    v-if="!showConfirmation"
    isOpen
    :closeOnOutside="false"
    @close="handleClose"
  >
    <template #title>
      {{ t('Request help from Codio Impact expert team') }}
    </template>

    <template #default>
      <p class="mb-4">
        {{
          t(
            'Explain your problem in as many details as possible. Depending on complexity, our response times may vary. Please note that our business hours are 9-5pm on work days in the state of Berlin.',
          )
        }}
      </p>
      <form @submit.prevent="handleSubmit">
        <MlTextarea
          v-model="formData.message"
          wrapperClass="mb-3"
          :editor="false"
          :placeholder="
            t(
              'Enter text of your message here or send an email to support@codioimpact.com',
            )
          "
          :errors="v$.message.$errors"
          @blur="v$.message.$touch"
        />
        <AtInput
          v-model="formData.phoneOrEmail"
          wrapperClass="mb-5"
          :placeholder="t('Enter your phone number or email')"
          :errors="v$.phoneOrEmail.$errors"
          @blur="v$.phoneOrEmail.$touch"
        />
        <MlSelect
          v-model="formData.type"
          wrapperClass="mb-5 max-w-[256px]"
          usePortal
          :options="typeOptions"
          :placeholder="t('Choose the type of help needed')"
          :errors="v$.type.$errors"
          @blur="v$.type.$touch"
        />
        <div class="flex justify-end">
          <AtButton
            type="submit"
            :loading="isSubmitting"
            :disabled="v$.$invalid"
          >
            {{ t('Request help') }}
          </AtButton>
        </div>
      </form>
    </template>
  </MlDialog>
  <MlDialog
    v-else
    isOpen
    class="!bg-blue-50"
    :hasCloseButton="false"
    @close="handleClose"
  >
    <template #title>
      <span class="text-blue-600">{{ t('Request for help submitted!') }}</span>
    </template>
    <template #default="{ close }">
      <div class="pt-10">
        <p class="mb-10">
          {{
            t(
              'Depending on complexity, our response time may vary. Please note that our business hours are 9-5pm on work days in the state of Berlin.',
            )
          }}
        </p>
        <div class="flex justify-end">
          <AtButton type="button" @click="close">
            {{ t('Close and continue') }}
          </AtButton>
        </div>
      </div>
    </template>
  </MlDialog>
</template>
