import type { ServerOptions } from 'vue3-easy-data-table';
import type { FilterBy } from '@/components/organisms/OgDataTable/types';
import {
  DataPointRequestFilterableFieldEnum,
  type DataPointRequestFilterByInput,
  PaginationLimitEnum,
  PaginationSortingEnum,
} from '@/__generated__/types';

export function getLimit(
  limit: ServerOptions['rowsPerPage'],
): PaginationLimitEnum {
  switch (limit) {
    case 50:
      return PaginationLimitEnum.FIFTY;
    case 100:
      return PaginationLimitEnum.HUNDRED;
    case 200:
      return PaginationLimitEnum.TWO_HUNDRED;
    default:
      return PaginationLimitEnum.TWENTY_FIVE;
  }
}

export function getSortOrder(
  order: ServerOptions['sortType'],
): PaginationSortingEnum {
  switch (order) {
    case 'asc':
      return PaginationSortingEnum.ASC;
    case 'desc':
      return PaginationSortingEnum.DESC;
    default:
      return PaginationSortingEnum.NONE;
  }
}

export function getSearchBy(searchString: string) {
  return searchString.trim();
}

export function getFilterBy(
  filters: FilterBy,
): DataPointRequestFilterByInput[] {
  const result: DataPointRequestFilterByInput[] = [];

  filters.forEach((filter) => {
    let field: DataPointRequestFilterByInput['field'] | undefined;
    switch (filter.field) {
      case 'friendlyName':
        field = DataPointRequestFilterableFieldEnum.DATA_POINT_TYPE;
        break;
      case 'category':
        field = DataPointRequestFilterableFieldEnum.CATEGORY;
        break;
      case 'subcategory':
        field = DataPointRequestFilterableFieldEnum.SUBCATEGORY;
        break;
      case 'assignee':
        field = DataPointRequestFilterableFieldEnum.ASSIGNEE;
        break;
      case 'location':
        field = DataPointRequestFilterableFieldEnum.LOCATION;
        break;
      case 'period':
        field = DataPointRequestFilterableFieldEnum.PERIOD;
        break;
      default:
    }

    if (field && filter.criteria.length > 0) {
      result.push({
        field,
        criteria: filter.criteria,
      });
    }
  });

  return result;
}
