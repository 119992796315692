<script setup lang="ts">
import { onBeforeUnmount, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import OgProfilePictureManager from '@/components/organisms/OgProfilePictureManager.vue';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';

const { updateCurrentUser, currentUserPictureUrl, currentUserName } =
  useCurrentUser();

type TUserInput = {
  firstName: string;
  lastName: string;
  jobTitle: string;
  jobDepartment: string;
  picture: string | null;
};

type TProps = {
  user: TUserInput;
};

const props = withDefaults(defineProps<TProps>(), {
  user: () => ({
    firstName: '',
    lastName: '',
    jobTitle: '',
    jobDepartment: '',
    picture: null,
  }),
});

const emit = defineEmits(['updateUser']);

const { t } = useI18n();

const userInput = reactive(props.user);
const v$ = useVuelidate(
  {
    firstName: { required },
    lastName: { required },
    jobTitle: { required },
    jobDepartment: { required },
  },
  userInput,
);

const updatePicture = (picture: { id: string; url: string }) => {
  if (!picture.id) return;
  updateCurrentUser({ picture: picture.id });
};

const removePicture = (id: null) => {
  updateCurrentUser({ picture: id });
};

onBeforeUnmount(() => emit('updateUser', userInput));
</script>

<template>
  <div>
    <section class="my-2 px-6 py-9">
      <div class="mb-9">
        <h2 class="mb-0.5 mt-0 font-semibold">
          {{ t('Upload a profile picture') }}
        </h2>
        <p class="text-sm text-gray-700">
          {{ t('Your profile picture helps others to recognize you better.') }}
        </p>
      </div>
      <OgProfilePictureManager
        :pictureUrl="currentUserPictureUrl ?? ''"
        :userName="currentUserName"
        @pictureUploaded="updatePicture"
        @pictureRemoved="removePicture"
      />
    </section>
    <section class="my-2 border-t border-gray-200 px-6 py-9">
      <div class="mb-9">
        <h2 class="mb-0.5 mt-0 font-semibold">
          {{ t('Add your profile Information') }}
        </h2>
        <p class="text-sm text-gray-700">
          {{
            t(
              'This will be shown to your team members and added to all reports.',
            )
          }}
        </p>
      </div>
      <div class="grid w-3/4 grid-cols-2 gap-6">
        <AtInput
          v-model="userInput.firstName"
          :label="t('First Name')"
          data-cy="userFirstName"
          :errors="
            v$.firstName.$error ? [t('Please fill out this field.')] : []
          "
        />
        <AtInput
          v-model="userInput.lastName"
          :label="t('Last Name')"
          data-cy="userLastName"
          :errors="v$.lastName.$error ? [t('Please fill out this field.')] : []"
        />
        <AtInput
          v-model="userInput.jobTitle"
          :label="t('Professional Title')"
          data-cy="userJobTitle"
          :errors="v$.jobTitle.$error ? [t('Please fill out this field.')] : []"
        />
        <AtInput
          v-model="userInput.jobDepartment"
          :label="t('Department')"
          data-cy="userJobDepartment"
          :errors="
            v$.jobDepartment.$error ? [t('Please fill out this field.')] : []
          "
        />
      </div>
    </section>
  </div>
</template>
