<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import orderBy from 'lodash/orderBy';
import {
  DoubleMaterialityIroImpactType,
  DoubleMaterialityIroRiskAndOpportunityType,
} from '@/__generated__/types';
import { notEmpty } from '@/utils/helpers/notEmpty';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import { BadgeType } from '@/components/atoms/AtBadge/types';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import type { IroImpact, IroRiskAndOpportunity, Topic } from '../../types';
import {
  calculateFinal,
  calculateFinalMateriality,
  calculateIroImpactSurveysRating,
  calculateIroRiskAndOpportunitySurveysRating,
  calculateSeverityForImpact,
  calculateTotalScoreForImpact,
  calculateTotalScoreForRiskAndOpportunity,
} from '../../utils';
import { useStore } from '../../store';
import OgTable from '../../OgTable.vue';

type RowItem = {
  _id: string;
  iroImpact: IroImpact | null;
  iroRiskAndOpportunity: IroRiskAndOpportunity | null;
  topic: Topic;
  internal: number | null;
  stakeholders: number | null;
  final: number | null;
  materiality: boolean | null;
};

const { t } = useI18n();
const store = useStore();
const { formatNumber } = useFormatNumber();

const headers = computed(() => [
  { text: t('Standard'), value: 'esrs', columnClasses: 'w-[200px]' },
  { text: t('Sustainability matter'), value: 'sustainabilityMatter' },
  { text: t('IRO'), value: 'iro' },
  { text: t('IRO type'), value: 'iroType', columnClasses: 'w-[215px]' },
  { text: t('Internal'), value: 'internal', columnClasses: 'w-[150px]' },
  {
    text: t('Stakeholders'),
    value: 'stakeholders',
    columnClasses: 'w-[150px]',
  },
  { text: t('Final'), value: 'final', columnClasses: 'w-[150px]' },
  { text: t('Materiality'), value: 'materiality', columnClasses: 'w-[100px]' },
]);

const items = computed<RowItem[]>(() => {
  const impacts: RowItem[] = store.value.iroImpacts
    .map((iroImpact) => {
      const topic = store.value.topics.find(
        (item) => item._id === iroImpact.topicId,
      )!;

      if (!topic.topic || !topic.subTopic) {
        return null;
      }

      const severity = calculateSeverityForImpact(
        iroImpact.scale,
        iroImpact.scope,
        iroImpact.irremediability,
        iroImpact.type,
      );
      const internal = calculateTotalScoreForImpact(
        severity,
        iroImpact.likelihood,
        iroImpact.type,
      );
      const stakeholders = calculateIroImpactSurveysRating(
        iroImpact._id,
        store.value.surveys,
      );
      const final = calculateFinal(
        internal,
        stakeholders,
        store.value.resultCalculation,
      );
      const materiality = calculateFinalMateriality(final);

      return {
        _id: iroImpact._id,
        topic,
        iroImpact,
        iroRiskAndOpportunity: null,
        internal,
        stakeholders,
        final,
        materiality,
      };
    })
    .filter((item) => notEmpty(item));

  const risksAndOpportunities: RowItem[] = store.value.iroRisksAndOpportunities
    .map((iroRiskAndOpportunity) => {
      const topic = store.value.topics.find(
        (item) => item._id === iroRiskAndOpportunity.topicId,
      )!;

      if (!topic.topic || !topic.subTopic) {
        return null;
      }

      const internal = calculateTotalScoreForRiskAndOpportunity(
        iroRiskAndOpportunity.likelihood,
        iroRiskAndOpportunity.potentialMagnitude,
      );
      const stakeholders = calculateIroRiskAndOpportunitySurveysRating(
        iroRiskAndOpportunity._id,
        store.value.surveys,
      );
      const final = calculateFinal(
        internal,
        stakeholders,
        store.value.resultCalculation,
      );
      const materiality = calculateFinalMateriality(final);

      return {
        _id: iroRiskAndOpportunity._id,
        topic,
        iroImpact: null,
        iroRiskAndOpportunity,
        internal,
        stakeholders,
        final,
        materiality,
      };
    })
    .filter((item) => notEmpty(item));

  return orderBy(
    [...impacts, ...risksAndOpportunities],
    [(item: RowItem) => item.topic.esrs],
    ['asc'],
  );
});
</script>

<template>
  <h3 class="text-lg font-medium mb-3">
    {{ t('Final materiality table') }}
  </h3>
  <OgTable
    :headers="headers"
    :items="items"
    :isRowRemovable="false"
    tableClass="!mb-16"
  >
    <template #item-esrs="row: RowItem">
      <p>{{ row.topic.esrs }} {{ t(row.topic.topic) }}</p>
    </template>
    <template #item-sustainabilityMatter="row: RowItem">
      <p>
        {{ t(row.topic.subSubTopic) || t(row.topic.subTopic) || '' }}
      </p>
    </template>
    <template #item-iro="row: RowItem">
      <p>
        {{
          row.iroImpact?.impact ??
          row.iroRiskAndOpportunity?.riskAndOpportunity ??
          ''
        }}
      </p>
    </template>
    <template #item-iroType="row: RowItem">
      <p>
        <template
          v-if="
            row.iroImpact?.type ===
            DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE
          "
        >
          {{ t('Actual negative impact') }}
        </template>
        <template
          v-else-if="
            row.iroImpact?.type ===
            DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE
          "
        >
          {{ t('Potential negative impact') }}
        </template>
        <template
          v-else-if="
            row.iroImpact?.type ===
            DoubleMaterialityIroImpactType.ACTUAL_POSITIVE
          "
        >
          {{ t('Actual positive impact') }}
        </template>
        <template
          v-else-if="
            row.iroImpact?.type ===
            DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE
          "
        >
          {{ t('Potential positive impact') }}
        </template>
        <template
          v-else-if="
            row.iroRiskAndOpportunity?.type ===
            DoubleMaterialityIroRiskAndOpportunityType.OPPORTUNITY
          "
        >
          {{ t('Opportunity') }}
        </template>
        <template
          v-else-if="
            row.iroRiskAndOpportunity?.type ===
            DoubleMaterialityIroRiskAndOpportunityType.RISK
          "
        >
          {{ t('Risk') }}
        </template>
        <template v-else>
          {{ t('n/a') }}
        </template>
      </p>
    </template>
    <template #item-internal="row: RowItem">
      <p>
        {{
          typeof row.internal !== 'number'
            ? t('n/a')
            : formatNumber(row.internal, 0, 2)
        }}
      </p>
    </template>
    <template #item-stakeholders="row: RowItem">
      <p>
        {{
          typeof row.stakeholders !== 'number'
            ? t('n/a')
            : formatNumber(row.stakeholders, 0, 2)
        }}
      </p>
    </template>
    <template #item-final="row: RowItem">
      <p>
        {{
          typeof row.final !== 'number'
            ? t('n/a')
            : formatNumber(row.final, 0, 2)
        }}
      </p>
    </template>
    <template #item-materiality="row: RowItem">
      <AtBadge
        :type="
          row.materiality === true
            ? BadgeType.SUCCESS
            : row.materiality === false
              ? BadgeType.WARNING
              : BadgeType.DEFAULT
        "
      >
        {{
          row.materiality === true
            ? t('Material')
            : row.materiality === false
              ? t('Not material')
              : t('Start')
        }}
      </AtBadge>
    </template>
  </OgTable>
</template>
