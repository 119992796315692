import { gql } from '@apollo/client/core';

export default gql`
  query MlTextarea($dataPointRequestId: String!, $context: String) {
    getSuggestedAnswer(
      dataPointRequestId: $dataPointRequestId
      context: $context
    )
  }
`;
