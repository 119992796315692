<script setup lang="ts">
import OgSimpleTable from './OgSimpleTableChild.vue';

interface Props {
  fields: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Record<string, any>[];
  expandAllRows?: boolean;
  borders?: boolean;
  showExpandIcon?: boolean;
  isFixedLayout?: boolean;
  secondStyle?: boolean;
  customEmptyStateText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  fields: () => [],
  items: () => [],
  borders: false,
  showExpandIcon: true,
  expandAllRows: false,
  isFixedLayout: false,
  secondStyle: false,
  customEmptyStateText: undefined,
});
</script>

<template>
  <OgSimpleTable
    :fields="fields"
    :items="items"
    :borders="borders"
    :showExpandIcon="props.showExpandIcon"
    :expandAllRows="props.expandAllRows"
    :isFixedLayout="props.isFixedLayout"
    :secondStyle="props.secondStyle"
    :customEmptyStateText="props.customEmptyStateText"
  >
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </OgSimpleTable>
</template>
