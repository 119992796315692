<script setup lang="ts">
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { FlagIcon } from '@heroicons/vue/outline';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import AtLoader from '@/components/atoms/AtLoader/AtLoader.vue';
import type { OgSelectTargetStepQuery } from '@/__generated__/types';
import ESGGoal from '@/components/atoms/ESGGoal.vue';
import type { TGoal, TTargetCommon } from '../../../types';
import AtCreateKPIModalTitle from '../../AtCreateKPIModalTitle.vue';
import MlTargetSelect from './MlTargetSelect.vue';
import OG_SELECT_TARGET_STEP from './OgSelectTargetStep.query';

const props = defineProps<{
  modelValue?: TTargetCommon;
  goal?: TGoal;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', target?: TTargetCommon): void;
}>();

const { t } = useI18n();

const { result, loading } = useQuery<OgSelectTargetStepQuery>(
  OG_SELECT_TARGET_STEP,
);

const goalsTargets = computed(() =>
  [...(result.value?.getTargets ?? [])]
    .filter(({ goal }) => goal._id === props.goal?._id)
    .sort(
      (a, b) =>
        Number(`${a.number}`.replace('.', '')) -
        Number(`${b.number}`.replace('.', '')),
    ),
);
</script>
<template>
  <div v-if="props.goal">
    <div
      class="items-around mt-12 grid grid-cols-[auto_1fr] items-center gap-x-5"
    >
      <ESGGoal :goal="props.goal" :size="20" />
      <h3 class="text-lg">
        {{ t(props.goal.name) }}
      </h3>
      <p class="self-start text-sm text-gray-500">
        {{ t(props.goal.description) }}
      </p>
    </div>
    <MlEmptyStateCard
      v-if="!goalsTargets.length"
      class="mt-12"
      :title="t('0 items')"
      :description="
        t('Based on the data you have collected, there is no suitable target.')
      "
      noButton
    >
      <FlagIcon class="mb-6 h-24" />
    </MlEmptyStateCard>

    <template v-else>
      <AtCreateKPIModalTitle
        class="mt-6"
        :title="t('Select Target')"
        :subtitle="
          t(
            'Choose from the list of targets. Based on your materiality, your project and you industry, we pre-selected recommended targets for you to start with.',
          )
        "
      />
      <div class="flex min-h-[10rem] justify-center">
        <AtLoader v-if="loading" />

        <MlTargetSelect
          :modelValue="props.modelValue"
          :options="goalsTargets"
          @update:modelValue="emit('update:modelValue', $event)"
        />
      </div>
    </template>
  </div>
</template>
