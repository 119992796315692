<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { ChartOptions } from 'chart.js';
import capitalize from 'lodash/capitalize';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/outline';
import {
  type TmOverviewViewQuery,
  DataPointRequestStatusEnum,
  UserRole,
} from '@/__generated__/types';
import OgChart from '@/components/organisms/OgChart/OgChart.vue';
import { getUserName } from '@/utils/helpers/getUserName';
import AtAvatar from '@/components/atoms/AtAvatar.vue';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import { chartColors } from '@/styles/theme';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import type { TUniversalChartData } from '@/components/organisms/OgChart/types';
import { formatToSymbolNumber } from '@/utils/helpers/formatToSymbolNumber';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import OgDateRangeInput from '@/components/organisms/OgDateRangeInput.vue';
import { useReportingPeriod } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import OgOverviewUsersDataTable from './OgOverviewUsersDataTable.vue';
import TM_OVERVIEW_VIEW_QUERY from './TmOverviewView.query';
import MlProject from './MlProject.vue';
import MlTasksByStatus from './MlTasksByStatus.vue';

const { t } = useI18n();
const { formatNumber } = useFormatNumber();
const { DRAFT, OPEN, ACCEPTED, OVERDUE, REJECTED, PENDING, CLOSED } =
  DataPointRequestStatusEnum;
const operatingStatuses = [
  DRAFT,
  OPEN,
  PENDING,
  REJECTED,
  ACCEPTED,
  OVERDUE,
  CLOSED,
];
const { currentUser, isAdminOrSuperAdmin } = useCurrentUser();

const adminMode = inject(
  'adminMode',
  computed(() => false),
);
const isExternal = inject(
  'isExternal',
  computed(() => false),
);

const { dateRange, formatForBE } = useReportingPeriod();
const overviewType = ref<'userBased' | 'projectBased'>('projectBased');

const { result, loading, refetch } = useQuery<TmOverviewViewQuery>(
  TM_OVERVIEW_VIEW_QUERY,
  {
    adminMode: adminMode.value,
    isExternal: isExternal.value,
    ...formatForBE(),
    assignedOnly: currentUser.value?.role !== UserRole.SUPERADMIN,
    statusFilter: DataPointRequestStatusEnum.OPEN,
    isAdminOrSuperAdmin,
  },
  {
    fetchPolicy: 'network-only',
  },
);

watch(dateRange, () => {
  refetch({
    adminMode: adminMode.value,
    isExternal: isExternal.value,
    ...formatForBE(),
    assignedOnly: currentUser.value?.role !== UserRole.SUPERADMIN,
    isAdminOrSuperAdmin: isAdminOrSuperAdmin.value,
  });
});

const overviewTypeOptions: Record<'projectBased' | 'userBased', string> = {
  projectBased: t('Project-based overview'),
  userBased: t('User-based overview'),
};

const entityAssignments = computed(
  () => result.value?.getEntityAssignments ?? [],
);
const availableCategories = computed(
  () => result.value?.getCategories.map((category) => category.slug) ?? [],
);
const teamUsers = computed(() => result.value?.getTeamUsers ?? []);
const emissions = computed(() => result.value?.getEmissions ?? []);
const currentUserLocations = computed(() => [
  ...new Set(
    reportingProgress.value
      .map((rp) => rp.location)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
  ),
]);
const currentUserLocationsByStatus = computed(() => ({
  DRAFT: [
    ...new Set(
      reportingProgress.value
        .filter((rp) => rp.draft > 0)
        .sort((a, b) =>
          a.location.name.toLowerCase() > b.location.name.toLowerCase()
            ? 1
            : -1,
        ),
    ),
  ],
  OPEN: [
    ...new Set(
      reportingProgress.value
        .filter((rp) => rp.open > 0)
        .sort((a, b) =>
          a.location.name.toLowerCase() > b.location.name.toLowerCase()
            ? 1
            : -1,
        ),
    ),
  ],
  PENDING: [
    ...new Set(
      reportingProgress.value
        .filter((rp) => rp.pending > 0)
        .sort((a, b) =>
          a.location.name.toLowerCase() > b.location.name.toLowerCase()
            ? 1
            : -1,
        ),
    ),
  ],
  REJECTED: [
    ...new Set(
      reportingProgress.value
        .filter((rp) => rp.rejected > 0)
        .sort((a, b) =>
          a.location.name.toLowerCase() > b.location.name.toLowerCase()
            ? 1
            : -1,
        ),
    ),
  ],
  ACCEPTED: [
    ...new Set(
      reportingProgress.value
        .filter((rp) => rp.accepted > 0)
        .sort((a, b) =>
          a.location.name.toLowerCase() > b.location.name.toLowerCase()
            ? 1
            : -1,
        ),
    ),
  ],
  OVERDUE: [
    ...new Set(
      reportingProgress.value
        .filter((rp) => rp.overDue > 0)
        .sort((a, b) =>
          a.location.name.toLowerCase() > b.location.name.toLowerCase()
            ? 1
            : -1,
        ),
    ),
  ],
  CLOSED: [
    ...new Set(
      reportingProgress.value
        .filter((rp) => rp.closed > 0)
        .sort((a, b) =>
          a.location.name.toLowerCase() > b.location.name.toLowerCase()
            ? 1
            : -1,
        ),
    ),
  ],
}));
const reportingProgress = computed(
  () =>
    result.value?.getReportingProgress ??
    ([] as NonNullable<TmOverviewViewQuery['getReportingProgress']>),
);
const entityReportingProgress = computed(
  () =>
    result.value?.getEntityReportingProgress ??
    ([] as NonNullable<TmOverviewViewQuery['getEntityReportingProgress']>),
);
const entityReportingData = computed(() => [
  {
    statusEnum: DRAFT,
    status: t('Draft'),
    value: entityReportingProgress.value.reduce(
      (acc, curr) => acc + curr.draft,
      0,
    ),
    color: chartColors[0][1],
  },
  {
    statusEnum: OPEN,
    status: t('Open'),
    value: entityReportingProgress.value.reduce(
      (acc, curr) => acc + curr.open,
      0,
    ),
    color: chartColors[1][1],
  },
  {
    statusEnum: PENDING,
    status: t('Pending'),
    value: entityReportingProgress.value.reduce(
      (acc, curr) => acc + curr.pending,
      0,
    ),
    color: chartColors[2][1],
  },
  {
    statusEnum: REJECTED,
    status: t('Rejected'),
    value: entityReportingProgress.value.reduce(
      (acc, curr) => acc + curr.rejected,
      0,
    ),
    color: chartColors[3][1],
  },
  {
    statusEnum: ACCEPTED,
    status: t('Accepted'),
    value: entityReportingProgress.value.reduce(
      (acc, curr) => acc + curr.accepted,
      0,
    ),
    color: chartColors[4][1],
  },
  {
    statusEnum: OVERDUE,
    status: t('Overdue'),
    value: entityReportingProgress.value.reduce(
      (acc, curr) => acc + curr.overDue,
      0,
    ),
    color: chartColors[5][1],
  },
  {
    statusEnum: CLOSED,
    status: t('Closed'),
    value: entityReportingProgress.value.reduce(
      (acc, curr) => acc + curr.closed,
      0,
    ),
    color: chartColors[6][1],
  },
]);

const draftDprCount = computed(() =>
  reportingProgress.value.reduce((acc, curr) => acc + curr.draft, 0),
);
const openDprCount = computed(() =>
  reportingProgress.value.reduce((acc, curr) => acc + curr.open, 0),
);
const pendingDprCount = computed(() =>
  reportingProgress.value.reduce((acc, curr) => acc + curr.pending, 0),
);
const rejectedDprCount = computed(() =>
  reportingProgress.value.reduce((acc, curr) => acc + curr.rejected, 0),
);
const acceptedDprCount = computed(() =>
  reportingProgress.value.reduce((acc, curr) => acc + curr.accepted, 0),
);
const overdueDprCount = computed(() =>
  reportingProgress.value.reduce((acc, curr) => acc + curr.overDue, 0),
);
const totalDprCount = computed(() =>
  reportingProgress.value.reduce((acc, curr) => acc + curr.total, 0),
);
const closedDprCount = computed(() =>
  reportingProgress.value.reduce((acc, curr) => acc + curr.closed, 0),
);

const entityActiveDprCount = computed(() =>
  entityReportingData.value.reduce(
    (acc, curr) =>
      [DRAFT, OPEN, OVERDUE, PENDING, REJECTED].includes(curr.statusEnum)
        ? acc + curr.value
        : acc,
    0,
  ),
);
const entityInactiveDprCount = computed(() =>
  entityReportingData.value.reduce(
    (acc, curr) =>
      [ACCEPTED, CLOSED].includes(curr.statusEnum) ? acc + curr.value : acc,
    0,
  ),
);
const entityTotalDprCount = computed(() =>
  entityReportingProgress.value.reduce((acc, curr) => acc + curr.total, 0),
);
const entityInactiveDprPercentage = computed(() =>
  loading.value || !entityTotalDprCount.value
    ? 0
    : ((entityInactiveDprCount.value ?? 1) / entityTotalDprCount.value) * 100,
);
const entityActiveDprPercentage = computed(() =>
  loading.value || !entityTotalDprCount.value
    ? 0
    : ((entityActiveDprCount.value ?? 1) / entityTotalDprCount.value) * 100,
);

const entityCarbonFootPrint = computed(() => {
  const carbonFootPrint = emissions.value.reduce(
    (acc, curr) => acc + curr.value,
    0,
  );
  return carbonFootPrint < 1000
    ? formatNumber(carbonFootPrint)
    : formatToSymbolNumber(carbonFootPrint, 2);
});

const getLocationReportingProgress = (locationId: string) =>
  reportingProgress.value.find(
    (location) => location.location._id === locationId,
  );

const draftDprPercentage = computed(() =>
  Math.round((draftDprCount.value * 100) / totalDprCount.value),
);
const openDprPercentage = computed(() =>
  Math.round((openDprCount.value * 100) / totalDprCount.value),
);
const pendingDprPercentage = computed(() =>
  Math.round((pendingDprCount.value * 100) / totalDprCount.value),
);
const rejectedDprPercentage = computed(() =>
  Math.round((rejectedDprCount.value * 100) / totalDprCount.value),
);
const acceptedDprPercentage = computed(() =>
  Math.round((acceptedDprCount.value * 100) / totalDprCount.value),
);
const overdueDprPercentage = computed(() =>
  Math.round((overdueDprCount.value * 100) / totalDprCount.value),
);
const closedDprPercentage = computed(() =>
  Math.round((closedDprCount.value * 100) / totalDprCount.value),
);

const isAllTasksExpanded = ref(false);
watch(isAllTasksExpanded, () => {
  statusToggled.value = [];
  if (isAllTasksExpanded.value) {
    statusToggled.value = [...operatingStatuses];
  }
});

const statusToggled = ref<DataPointRequestStatusEnum[]>([]);
const statusHovered = ref<DataPointRequestStatusEnum | null>();
const tasksHovered = ref(false);
const isAllTeamMembersHidden = ref(true);

const entityReportingProgressChartData = computed<TUniversalChartData>(() => ({
  datasets: [
    {
      backgroundColor: ['#0d9488', '#EFF6FF'],
      data: [entityInactiveDprCount.value, entityActiveDprCount.value],
      datalabels: {
        font: {
          size: 10,
        },
        display: false,
      },
    },
  ],
  labels: [
    `${formatNumber(entityInactiveDprPercentage.value)}% ${t('done')}`,
    `${formatNumber(entityActiveDprPercentage.value)}% ${t('in progress')}`,
  ],
}));

const entityReportingProgressChartOptions = computed<ChartOptions>(() => ({
  plugins: {
    legend: {
      display: false,
      position: 'right',
      labels: {
        usePointStyle: true,
      },
    },
    datalabels: {
      display: true,
      color: ['#fff', '#3b82f6'],
    },
    doughnutlabel: {
      labels: [
        { font: { size: 60 }, text: entityTotalDprCount.value },
        { font: { size: 30 }, text: t('questions') },
      ],
    },
  },
}));

function updateStatusToggled(status: DataPointRequestStatusEnum) {
  const statusToggledIndex = statusToggled.value.indexOf(status);
  if (statusToggledIndex >= 0) {
    statusToggled.value.splice(statusToggledIndex, 1);
  } else {
    statusToggled.value.push(status);
  }
}

function updateStatusHovered(status: DataPointRequestStatusEnum | null) {
  statusHovered.value = status;
}

const isClassActive = (status: DataPointRequestStatusEnum) => {
  return (
    tasksHovered.value &&
    statusHovered.value !== status &&
    !statusToggled.value.includes(status)
  );
};

const getStatusClass = (status: DataPointRequestStatusEnum) => {
  switch (status) {
    case OPEN:
      return ['bg-blue-500', { '!bg-gray-200': isClassActive(status) }];
    case PENDING:
      return ['bg-orange-400', { '!bg-gray-200': isClassActive(status) }];
    case REJECTED:
      return ['bg-rose-400', { '!bg-gray-200': isClassActive(status) }];
    case ACCEPTED:
      return ['bg-teal-600', { '!bg-gray-200': isClassActive(status) }];
    case OVERDUE:
      return ['bg-violet-500', { '!bg-gray-200': isClassActive(status) }];
    default:
      return ['bg-blue-500', { '!bg-gray-200': isClassActive(status) }];
  }
};

const getStatusDprPercentage = (status: DataPointRequestStatusEnum) => {
  switch (status) {
    case DRAFT:
      return draftDprPercentage.value;
    case OPEN:
      return openDprPercentage.value;
    case PENDING:
      return pendingDprPercentage.value;
    case REJECTED:
      return rejectedDprPercentage.value;
    case ACCEPTED:
      return acceptedDprPercentage.value;
    case OVERDUE:
      return overdueDprPercentage.value;
    case CLOSED:
      return closedDprPercentage.value;
    default:
      return 0;
  }
};

const getDprsCount = (status: DataPointRequestStatusEnum | null = null) => {
  switch (status) {
    case DRAFT:
      return draftDprCount.value;
    case OPEN:
      return openDprCount.value;
    case PENDING:
      return pendingDprCount.value;
    case REJECTED:
      return rejectedDprCount.value;
    case ACCEPTED:
      return acceptedDprCount.value;
    case OVERDUE:
      return overdueDprCount.value;
    case CLOSED:
      return closedDprCount.value;
    default:
      return totalDprCount.value;
  }
};

const getLocationsByStatus = (
  status: DataPointRequestStatusEnum | null = null,
) => {
  switch (status) {
    case DRAFT:
      return currentUserLocationsByStatus.value.DRAFT;
    case OPEN:
      return currentUserLocationsByStatus.value.OPEN;
    case PENDING:
      return currentUserLocationsByStatus.value.PENDING;
    case REJECTED:
      return currentUserLocationsByStatus.value.REJECTED;
    case ACCEPTED:
      return currentUserLocationsByStatus.value.ACCEPTED;
    case OVERDUE:
      return currentUserLocationsByStatus.value.OVERDUE;
    case CLOSED:
      return currentUserLocationsByStatus.value.CLOSED;
    default:
      return [];
  }
};
</script>

<template>
  <div class="pt-5 px-6 grid grid-cols-1 gap-8 xl:grid-cols-12">
    <div class="xl:col-span-12 flex flex-wrap justify-between items-end gap-5">
      <div class="px-1">
        <h2 class="text-xl leading-7 text-gray-900 sm:truncate mb-3">
          {{
            `${t("{entityName}'s overview", { entityName: currentUser?.entity.name })}`
          }}
        </h2>
        <p class="text-sm text-gray-500">
          {{ t('Here’s what’s currently happening in the workspace.') }}
        </p>
      </div>
      <div class="flex h-full items-start gap-x-2">
        <OgDateRangeInput v-model="dateRange" />
        <MlSelect
          v-if="isAdminOrSuperAdmin"
          v-model="overviewType"
          wrapperClass="w-full"
          class="!bg-blue-50 !text-primary !border-primary w-full"
          :options="overviewTypeOptions"
        />
      </div>
    </div>
    <div class="xl:col-span-12 grid grid-cols-12 gap-4">
      <div
        class="bg-gray-50 rounded-md p-5 justify-center xl:col-span-2 col-span-4"
      >
        <h3 class="font-semibold leading-5 text-gray-900 mb-3">
          {{ t('Reporting progress') }}
        </h3>
        <OgChart
          class="justify-center !p-0 max-h-36"
          type="pie"
          :chartData="entityReportingProgressChartData"
          :chartOptions="entityReportingProgressChartOptions"
        />
      </div>
      <div class="bg-gray-50 rounded-md p-5 xl:col-span-2 col-span-4">
        <h3 class="font-semibold leading-5 text-gray-900 mb-7 justify-center">
          {{ t('Carbon footprint') }}
          <AtTooltipIcon
            class="ml-1 cursor-pointer"
            :tooltip="
              t(
                'Your workspace’s carbon footprint is calculated with scope 1, 2 and 3 emissions expressed in tons of CO2 equivalent (tCO2eq)',
              )
            "
            :triggers="['click', 'touch']"
            autoHide
          />
        </h3>

        <div
          class="text-teal-600 text-6xl font-bold text-center"
          :class="{ 'vertical-bounce': loading }"
        >
          {{ entityCarbonFootPrint }}
        </div>
        <div class="text-gray-700 text-2xl text-center">tCO2eq</div>

        <div v-if="!loading && !emissions.length" class="text-gray-900 mb-5">
          {{ t('(No data yet)') }}
        </div>
      </div>
      <div
        class="bg-gray-50 rounded-md p-5 justify-center xl:col-span-2 col-span-4 break-words"
      >
        <h3 class="font-semibold leading-5 text-gray-900 mb-7">
          {{ t('Team') }}
        </h3>
        <ul class="text-start text-sm max-h-36 custom-scrollbar">
          <li
            v-for="teamUser in teamUsers"
            :key="teamUser._id"
            class="flex items-center mb-3"
          >
            <AtAvatar
              class="!h-8 !w-8"
              :url="teamUser.picture?.downloadUrl"
              :userName="getUserName(teamUser)"
            />
            <div class="ml-2 flex flex-col">
              <span> {{ teamUser.firstName }} {{ teamUser.lastName }} </span>
              <span class="text-gray-400" data-cy="templateItemNameEmail">
                {{ teamUser.jobTitle }}
              </span>
            </div>
          </li>
        </ul>
        <AtIconButton
          v-if="teamUsers.length > 5"
          :icon="isAllTeamMembersHidden ? ChevronDownIcon : ChevronUpIcon"
          title="icon"
          @click="isAllTeamMembersHidden = !isAllTeamMembersHidden"
        />
      </div>
      <div
        class="xl:col-span-6 col-span-12 bg-gray-50 rounded-md p-5 items-center"
      >
        <h3 class="font-semibold leading-5 text-gray-900 mb-3">
          {{ t('Progress') }}
        </h3>
        <div class="flex w-full justify-between h-full items-center">
          <div class="grid w-full">
            <div class="flex w-full items-center justify-center mb-3">
              <TransitionGroup name="horizontal-fade-scale">
                <div
                  v-for="status in operatingStatuses"
                  v-show="loading"
                  :key="status"
                  :class="getStatusClass(status)"
                  :style="{
                    width: `${getStatusDprPercentage(status) > 1 ? getStatusDprPercentage(status) : 1}%`,
                  }"
                  class="h-4 rounded-md mx-2 cursor-pointer vertical-bounce"
                />
                <div
                  v-for="status in operatingStatuses"
                  v-show="!loading"
                  :key="status"
                  class="h-4 rounded-full mx-2 cursor-pointer"
                  :class="getStatusClass(status)"
                  :style="{
                    width: `${getStatusDprPercentage(status) > 1 ? getStatusDprPercentage(status) : getDprsCount(status) >= 1 ? 1 : 0}%`,
                  }"
                  @mouseenter="statusHovered = status"
                  @focus="statusHovered = status"
                  @mouseleave="statusHovered = null"
                  @focusout="statusHovered = null"
                  @click="updateStatusToggled(status)"
                />
              </TransitionGroup>
            </div>
            <ul class="text-gray-500 text-sm w-full">
              <li
                v-for="status in operatingStatuses"
                :key="status"
                class="inline px-2 cursor-pointer"
                @mouseenter="statusHovered = status"
                @focus="statusHovered = status"
                @mouseleave="statusHovered = null"
                @focusout="statusHovered = null"
                @click="updateStatusToggled(status)"
              >
                <div
                  class="h-2 w-2 rounded inline-block"
                  :class="getStatusClass(status)"
                />
                {{ t('{status}: ', { status: t(capitalize(status)) }) }}
                <span class="inline-block">
                  <div v-if="loading" class="shake ml-1">
                    {{ '...' }}
                  </div>
                  <div v-else>
                    {{ getDprsCount(status) }}
                  </div>
                </span>
              </li>
            </ul>
          </div>
          <div class="w-[18%] text-center flex justify-end">
            <div
              class="inline-block -translate-y-2 flex-col justify-between items-stretch"
            >
              <div
                class="font-bold text-2xl"
                :class="{ 'vertical-bounce': loading }"
              >
                {{ getDprsCount() }}
              </div>
              <div class="text-sm text-gray-900">
                {{ t('Total questions') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <OgOverviewUsersDataTable
        v-show="
          overviewType === 'userBased' // using v-show as v-if creates a bug preventing adjustBorder function to work
        "
        wrapperClass="!py-0 col-span-12"
        :entityAssignments="entityAssignments"
      />

      <template v-if="overviewType === 'projectBased'">
        <div
          class="bg-gray-50 rounded-md p-5 xl:col-span-6 col-span-12 h-[460px]"
        >
          <h3 class="font-semibold leading-5 text-gray-900 mb-[10px] h-[30px]">
            {{ t('Projects') }}
          </h3>
          <ul
            v-if="reportingProgress.length"
            class="flex flex-wrap w-full custom-scrollbar h-[calc(100%-30px)]"
          >
            <MlProject
              v-for="location in currentUserLocations"
              :key="location._id"
              :projectId="location._id"
              :title="location.name"
              :locationReportingProgress="
                getLocationReportingProgress(location._id)
              "
              wrapperClass="border-b-[1px] mb-[-1px]"
            />
          </ul>
        </div>
        <div
          class="bg-gray-50 rounded-md p-5 xl:col-span-6 col-span-12 h-[460px]"
        >
          <div class="flex justify-between h-[30px] items-start">
            <h3 class="font-semibold leading-5 text-gray-900 mb-3 w-full">
              {{ t('Tasks') }}
            </h3>
          </div>
          <AtButton
            variant="text"
            class="text-sm !pl-1"
            @click="isAllTasksExpanded = !isAllTasksExpanded"
          >
            <span v-if="isAllTasksExpanded">
              {{ t('Close all') }}
            </span>
            <span v-else>
              {{ t('Expand all') }}
            </span>
          </AtButton>
          <ul
            class="text-gray-500 h-[calc(100%-30px)] custom-scrollbar"
            @mouseenter="tasksHovered = true"
            @focus="tasksHovered = true"
            @mouseleave="tasksHovered = false"
            @focusout="tasksHovered = false"
          >
            <MlTasksByStatus
              v-for="status in operatingStatuses"
              :key="status"
              :status="status"
              :isOpen="statusToggled.includes(status)"
              :dprCount="getDprsCount(status)"
              :locations="getLocationsByStatus(status)"
              :categories="availableCategories"
              :isHovered="statusHovered === status"
              :isAllExpanded="isAllTasksExpanded"
              @updateStatusHovered="updateStatusHovered($event)"
              @updateStatusToggled="updateStatusToggled($event)"
            />
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>
