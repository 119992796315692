<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import useAssignUsersToDoubleMaterialityMutation from '@/api/mutations/User/assignUsersToDoubleMateriality.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import type { User } from './types';

const props = defineProps<{
  users: User[];
}>();

const { t } = useI18n();
const { mutate, loading } = useAssignUsersToDoubleMaterialityMutation();

const assignUsers = ref<string[]>([]);

const assignUsersOptions = computed(() =>
  props.users.reduce<Record<string, string>>((acc, user) => {
    acc[user._id] = `${user.firstName} ${user.lastName}`;

    return acc;
  }, {}),
);

async function handleSubmit() {
  try {
    const updatedUsers = await mutate({
      userIds: assignUsers.value,
    });
    notify({
      type: 'success',
      text: t('Users have been added to double materiality: {emails}.', {
        emails: updatedUsers?.data?.assignUserToDoubleMateriality
          .map((user) => user.email)
          .join(', '),
      }),
    });
    assignUsers.value = [];
  } catch {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
}
</script>

<template>
  <form class="pt-2.5 mb-8" @submit.prevent="handleSubmit">
    <p class="mb-10">
      {{ t('Add existing team members to double materiality.') }}
    </p>
    <div class="flex items-end space-x-2">
      <MlSelect
        v-model="assignUsers"
        :label="t('Name')"
        :options="assignUsersOptions"
        multiple
        wrapperClass="w-full"
      />
      <AtButton
        type="submit"
        class="h-[34px]"
        :disabled="assignUsers.length === 0"
        :loading="loading"
      >
        {{ t('Add') }}
      </AtButton>
    </div>
  </form>
</template>
