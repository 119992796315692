<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import MlTabs from '@/components/molecules/MlTabs/MlTabs.vue';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const tabs = computed(() => [
  { name: t('Guidance'), value: 'doubleMaterialityStartGuidance' },
  { name: t('Team'), value: 'doubleMaterialityStartTeam' },
  {
    name: t('Scales and core data '),
    value: 'doubleMaterialityStartScalesAndCoreData',
  },
]);
</script>

<template>
  <MlTabs
    class="mb-6"
    :modelValue="String(route.name)"
    :tabs="tabs"
    isEdgeToEdge
    @update:modelValue="router.push({ name: $event.value })"
  />

  <router-view />
</template>
