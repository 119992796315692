<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { notify } from '@kyvg/vue3-notification';
import MlModal from '@/components/molecules/MlModal.vue';
import MlStepper from '@/components/molecules/MlStepper.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useCreateEntityKPIMutation from '@/api/mutations/EntityKPI/createEntityKpi.mutation';
import type { OgDataPointTypeSelectModelValue } from '@/components/organisms/OgDataPointTypeSelect/OgDataPointTypeSelect.vue';
import type {
  TGoal,
  TKpi,
  TTargetCommon,
  TCreateKPIFormData,
} from '../../types';
import OgConnectGoalStep from './OgConnectGoalStep/OgConnectGoalStep.vue';
import OgSetupKpiStep from './OgSetupKpiStep/OgSetupKpiStep.vue';

enum Step {
  KPI,
  GOAL,
}

const props = defineProps<{
  isRevealed: boolean;
  goals: TGoal[];
  kpis: TKpi[];
}>();

const emit = defineEmits<{
  (e: 'update:isRevealed', isRevealed: boolean): void;
  (e: 'created', kpiIds: string[]): void;
}>();

const { t } = useI18n();
const v = useVuelidate();

const { mutate: createKPIs, loading } = useCreateEntityKPIMutation({
  fetchPolicy: 'no-cache',
});

const step = ref(Step.KPI);
const goal = ref<TGoal>();
const target = ref<TTargetCommon>();
const kpiFormData = ref<
  TCreateKPIFormData & { dataPointType: OgDataPointTypeSelectModelValue | null }
>();

const goalProgress = computed(() => ({
  [Step.KPI]: !v.value.$error,
  [Step.GOAL]: goal.value && target.value,
}));

const handleNextStep = () => {
  v.value.$touch();

  if (v.value.$error) return;

  step.value += 1;
};

const handleCreateKPI = async (isRepeated = false) => {
  v.value.$touch();

  if (v.value.$error || !kpiFormData.value) return;

  try {
    const response = await createKPIs({
      createEntityKPIInputs: [
        {
          targets: [target.value?._id ?? ''],
          ...kpiFormData.value,
          dataPointType: kpiFormData.value?.dataPointType?._id ?? '',
        },
      ],
    });

    emit(
      'created',
      response?.data?.createEntityKPI.map((kpi) => kpi._id) ?? [],
    );
    notify({ type: 'success', text: t('KPI has been created.') });
    if (isRepeated) {
      reset();
    } else {
      emit('update:isRevealed', false);
    }
  } catch {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
};

const reset = () => {
  step.value = Step.KPI;
  goal.value = undefined;
  target.value = undefined;
  kpiFormData.value = undefined;
  v.value.$reset();
};

// Temporal hack because I can't properly unmount component with v-if without losing animation. Remove after modal rework.
const modalKey = ref(0);
watch(
  () => props.isRevealed,
  () => {
    if (!props.isRevealed) {
      reset();
      setTimeout(() => {
        modalKey.value += 1;
      }, 1000);
    }
  },
);
</script>

<template>
  <MlModal
    :key="modalKey"
    class="!w-[90%] !max-w-[83rem] px-28 py-8"
    :overlayDismiss="false"
    :isRevealed="props.isRevealed"
    v-bind="$attrs"
    @update:isRevealed="emit('update:isRevealed', $event)"
  >
    <MlStepper :value="step" :steps="['Set up a KPI', 'Connect to goal']" />

    <OgSetupKpiStep v-if="step === Step.KPI" v-model="kpiFormData" />
    <OgConnectGoalStep
      v-if="step === Step.GOAL"
      v-model:goal="goal"
      v-model:target="target"
      :goals="props.goals"
      :dataPointTypeId="kpiFormData?.dataPointType?._id ?? ''"
    />

    <div class="mt-20 flex justify-between">
      <AtButton v-if="step !== 0" variant="text" @click="step -= 1">
        {{ t('Previous') }}
      </AtButton>
      <AtButton
        v-if="step < Step.GOAL"
        class="ml-auto"
        :disabled="v.$error || loading || !goalProgress[step]"
        @click="handleNextStep"
      >
        {{ t('Next') }}
      </AtButton>
      <div v-else class="ml-auto">
        <AtButton
          variant="text"
          :disabled="v.$error || loading || !goalProgress[step]"
          @click="handleCreateKPI(true)"
        >
          {{ t('Finish and create next') }}
        </AtButton>
        <AtButton
          class="ml-4"
          :disabled="v.$error || loading || !goalProgress[step]"
          @click="handleCreateKPI()"
        >
          {{ t('Finish') }}
        </AtButton>
      </div>
    </div>
  </MlModal>
</template>
