import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DeleteDataPointTypeMutation,
  DeleteDataPointTypeMutationVariables,
} from '@/__generated__/types';

export const DELETE_DATAPOINT_TYPE = gql`
  mutation DeleteDataPointType($dataPointTypeId: String!) {
    deleteDataPointType(dataPointTypeId: $dataPointTypeId)
  }
`;

type TOptions = UseMutationOptions<
  DeleteDataPointTypeMutation,
  DeleteDataPointTypeMutationVariables
>;

const useDeleteDataPointTypeMutation = (options?: TOptions) =>
  useMutation<
    DeleteDataPointTypeMutation,
    DeleteDataPointTypeMutationVariables
  >(DELETE_DATAPOINT_TYPE, options);

export default useDeleteDataPointTypeMutation;
