import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdateDataPointCustomCategoryNameMutation,
  UpdateDataPointCustomCategoryNameMutationVariables,
} from '@/__generated__/types';

export const UPDATE_DATAPOINT_CUSTOM_CATEGORY_NAME = gql`
  mutation UpdateDataPointCustomCategoryName($input: UpdateCategoryNameInput!) {
    updateDataPointCustomCategoryName(updateCategoryNameInput: $input) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  UpdateDataPointCustomCategoryNameMutation,
  UpdateDataPointCustomCategoryNameMutationVariables
>;

const useUpdateDataPointCustomCategoryNameMutation = (options?: TOptions) =>
  useMutation<
    UpdateDataPointCustomCategoryNameMutation,
    UpdateDataPointCustomCategoryNameMutationVariables
  >(UPDATE_DATAPOINT_CUSTOM_CATEGORY_NAME, options);

export default useUpdateDataPointCustomCategoryNameMutation;
