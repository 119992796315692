<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, reactive, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import dayjs from '@/lib/dayjs/config';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlDatePicker from '@/components/molecules/MlDatePicker.vue';
import {
  type OgKpiFormQuery,
  ComparisonValueTypeEnum,
  ComparisonOperatorEnum,
} from '@/__generated__/types';
import startingValueTooltip from '../../tooltips/startingValue.tooltip.vue';
import measurementTooltip from '../../tooltips/measurement.tooltip.vue';
import type { TCreateKPIFormData, TDataPointType } from '../../../types';
import OG_KPI_FORM_QUERY from './OgKPIForm.query';

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    modelValue: TCreateKPIFormData;
    dpt?: TDataPointType;
    loading?: boolean;
    disabled?: boolean;
  }>(),
  {
    modelValue: () => ({
      description: '',
      user: '',
      comparisonOperator: undefined as ComparisonOperatorEnum | undefined,
      comparisonValue: undefined as number | undefined,
      comparisonValueType: '' as ComparisonValueTypeEnum,
      startingDate: null as Date | null,
      startingValue: undefined as number | undefined,
      dueDate: null as Date | null,
    }),
    dpt: undefined,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', formData: TCreateKPIFormData): void;
}>();

const formData = reactive(props.modelValue);
watch(formData, () => emit('update:modelValue', formData));
const rules = computed(() => ({
  description: { required },
  user: { required },
  comparisonValueType: { required },
  startingDate: { required },
  dueDate: { required },
  ...(formData.comparisonValueType !== ComparisonValueTypeEnum.YES_NO
    ? {
        comparisonOperator: { required },
        comparisonValue: { required },
      }
    : {}),
}));
const v = useVuelidate(rules, formData);

const { result } = useQuery<OgKpiFormQuery>(OG_KPI_FORM_QUERY);

const userOptions = computed(() =>
  result.value?.getTeamUsers?.reduce<Record<string, string>>(
    (acc, { _id, firstName, lastName }) => ({
      ...acc,
      [_id]: `${firstName} ${lastName}`,
    }),
    {},
  ),
);

const comparisonOperatorOptions = computed(() => ({
  [ComparisonOperatorEnum.GT]: t('Increase'),
  [ComparisonOperatorEnum.EQ]: t('Equals'),
  [ComparisonOperatorEnum.LT]: t('Decrease'),
}));

const ComparisonValueTypeOptions = computed(() => ({
  [ComparisonValueTypeEnum.NUMBER]: t('# Numeric'),
  [ComparisonValueTypeEnum.PERCENT]: t('% Percent'),
  [ComparisonValueTypeEnum.YES_NO]: t('Yes/No'),
}));

const targetUnit = computed(() => ({
  unit:
    formData.comparisonValueType === ComparisonValueTypeEnum.PERCENT
      ? '%'
      : props.dpt?.valueUnit,
  unitDivisor:
    formData.comparisonValueType === ComparisonValueTypeEnum.PERCENT
      ? ''
      : props.dpt?.valueUnitDivisor,
}));

const handleComparisonValueChange = (
  comparisonValueType: ComparisonValueTypeEnum,
) => {
  if (comparisonValueType === ComparisonValueTypeEnum.YES_NO) {
    formData.comparisonValue = 0;
    formData.startingValue = 0;
    formData.comparisonOperator = ComparisonOperatorEnum.EQ;
  } else {
    formData.comparisonOperator = '' as ComparisonOperatorEnum;
  }
};
</script>

<template>
  <div v-if="props.dpt">
    <form
      class="mt-6 flex grid-flow-row-dense grid-cols-12 flex-col gap-6 xl:grid"
    >
      <MlTextarea
        v-model.trim="formData.description"
        wrapperClass="col-span-9"
        :class="{ 'ring-2 ring-error': v.description.$error }"
        :label="t('Description')"
        :placeholder="
          t(
            'Add context to this KPI. Why is it important? How will you implement it in your company?',
          )
        "
        :disabled="props.loading || props.disabled"
      />
      <MlSelect
        v-model="formData.user"
        wrapperClass="col-span-3"
        :class="{ 'ring-2 ring-error': v.user.$error }"
        :label="t('Assignee')"
        :options="userOptions"
        :disabled="props.loading || props.disabled"
      />

      <MlSelect
        v-model="formData.comparisonValueType"
        wrapperClass="col-span-3"
        :class="{ 'ring-2 ring-error': v.comparisonValueType.$error }"
        :label="t('Measurement')"
        :options="ComparisonValueTypeOptions"
        :tooltip="measurementTooltip"
        :disabled="props.loading || props.disabled"
        @update:modelValue="handleComparisonValueChange"
      />
      <template
        v-if="formData.comparisonValueType !== ComparisonValueTypeEnum.YES_NO"
      >
        <AtInput
          v-model.number="formData.startingValue"
          wrapperClass="col-span-3"
          type="number"
          :tooltip="startingValueTooltip"
          :disabled="props.loading || props.disabled"
          label="Starting value"
          :unit="props.dpt?.valueUnit"
          :unitDivisor="props.dpt?.valueUnitDivisor"
        />
        <AtInput
          v-model.number="formData.comparisonValue"
          wrapperClass="col-span-3"
          :class="{ 'ring-2 ring-error': v.comparisonValue?.$error }"
          type="number"
          :disabled="props.loading || props.disabled"
          label="Target value"
          :unit="targetUnit.unit"
          :unitDivisor="targetUnit.unitDivisor"
        />
        <MlSelect
          v-model="formData.comparisonOperator"
          wrapperClass="col-span-3"
          :class="{ 'ring-2 ring-error': v.comparisonOperator?.$error }"
          :options="comparisonOperatorOptions"
          :disabled="props.loading || props.disabled"
          :sortedOptions="true"
          label="Target action"
        />
      </template>

      <MlDatePicker
        v-model="formData.startingDate"
        wrapperClass="col-span-3 min-w-sm"
        label="Start date"
        :disabled="props.loading || props.disabled"
        :class="{ 'ring-2 ring-error': v.startingDate.$error }"
        :tooltip="
          t(
            'Select a date from which you want the data to be compared with your goal. You can select a date in the past or in the future.',
          )
        "
      />
      <MlDatePicker
        v-model="formData.dueDate"
        wrapperClass="col-span-3 min-w-sm"
        label="Due date"
        :class="{ 'ring-2 ring-error': v.dueDate.$error }"
        :minDate="dayjs().add(1, 'day').toDate()"
        :disabled="props.loading || props.disabled"
      />
      <span class="col-span-6" />
    </form>
  </div>
</template>
