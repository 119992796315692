<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import MlTabs from '@/components/molecules/MlTabs/MlTabs.vue';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const tabs = computed(() => [
  { name: t('Impacts'), value: 'doubleMaterialityIROsImpacts' },
  {
    name: t('Risks and Opportunities'),
    value: 'doubleMaterialityIROsRisksAndOpportunities',
  },
]);

const showTabs = computed(() => {
  return [
    'doubleMaterialityIROsImpacts',
    'doubleMaterialityIROsRisksAndOpportunities',
  ].includes(String(route.name));
});
</script>

<template>
  <MlTabs
    v-if="showTabs"
    class="mb-6"
    :modelValue="String(route.name)"
    :tabs="tabs"
    isEdgeToEdge
    @update:modelValue="router.push({ name: $event.value })"
  />

  <router-view />
</template>
