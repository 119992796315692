<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { router } from '@/router';

const { t } = useI18n();

const providedPassword = ref('');

const v$ = useVuelidate(
  {
    password: {
      required: helpers.withMessage('A password is required', required),
    },
  },
  {
    password: providedPassword,
  },
);

const validate = () => {
  window.localStorage.setItem('sharePassword', providedPassword.value);
  router.push(`/external/dashboard${window.location.search}`);
};
</script>

<template>
  <div class="flex h-screen justify-center items-center">
    <div class="bg-white w-1/3 p-8 py-16 rounded-md text-center">
      <AtHeading type="h1" class="mb-5 font-semibold">
        {{ t('Password required') }}
      </AtHeading>

      <p class="text-sm mb-5">
        {{ t('You need a password to access this page.') }}
      </p>

      <form @submit.prevent="validate">
        <AtInput
          v-model="providedPassword"
          labelClass="text-left"
          class="mb-4"
          label="Password"
          placeholder="Enter password"
          type="password"
          required
          :errors="v$.password.$errors"
        />
      </form>

      <AtButton class="w-full" @click="validate">
        {{ t('Continue') }}
      </AtButton>
    </div>

    <div class="absolute w-full h-full bg-[#151C34] top-0 left-0 z-[-1]">
      <svg
        width="813"
        height="802"
        viewBox="0 0 813 802"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute inset-0 h-full w-full object-cover"
      >
        <path
          opacity="0.010101"
          d="M392.684 533.293C387.313 527.59 386.148 519.086 389.778 512.086L595.201 115.948C598.961 108.697 606.994 104.73 614.989 106.175L1045.29 183.972C1053.11 185.386 1059.12 191.665 1060.2 199.557L1121.15 644.237C1122.23 652.129 1118.15 659.863 1111.01 663.433L718.29 859.83C710.993 863.479 702.205 861.935 696.641 856.028L392.684 533.293Z"
          stroke="url(#paint0_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.020202"
          d="M387.997 527.84C382.725 522.043 381.705 513.522 385.453 506.59L597.176 114.949C601.059 107.766 609.161 103.942 617.132 105.531L1045.2 190.849C1053 192.403 1058.9 198.787 1059.85 206.696L1113.12 651.539C1114.06 659.448 1109.85 667.106 1102.65 670.548L707.421 859.517C700.061 863.035 691.298 861.335 685.836 855.329L387.997 527.84Z"
          stroke="url(#paint1_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.030303"
          d="M383.429 522.396C378.257 516.507 377.382 507.971 381.246 501.108L599.179 114.07C603.184 106.956 611.352 103.278 619.296 105.009L1045.01 197.792C1052.78 199.486 1058.57 205.974 1059.39 213.897L1104.99 658.763C1105.8 666.687 1101.45 674.267 1094.2 677.58L696.594 859.091C689.174 862.478 680.439 860.621 675.079 854.517L383.429 522.396Z"
          stroke="url(#paint2_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.040404"
          d="M378.981 516.963C373.912 510.983 373.182 502.435 377.162 495.643L601.212 113.31C605.338 106.269 613.568 102.737 621.483 104.611L1044.73 204.801C1052.47 206.633 1058.15 213.223 1058.82 221.159L1096.77 665.908C1097.45 673.844 1092.97 681.344 1085.66 684.527L685.814 858.553C678.337 861.808 669.633 859.795 664.377 853.595L378.981 516.963Z"
          stroke="url(#paint3_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.050505"
          d="M374.652 511.542C369.686 505.473 369.102 496.915 373.197 490.196L603.269 112.67C607.515 105.703 615.806 102.318 623.689 104.334L1044.33 211.871C1052.04 213.841 1057.61 220.531 1058.15 228.477L1088.46 672.971C1089 680.917 1084.4 688.335 1077.04 691.387L675.081 857.904C667.548 861.024 658.879 858.856 653.729 852.563L374.652 511.542Z"
          stroke="url(#paint4_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.0606061"
          d="M370.441 506.134C365.579 499.978 365.142 491.412 369.349 484.768L605.35 112.147C609.714 105.257 618.062 102.021 625.911 104.177L1043.84 218.999C1051.51 221.108 1056.96 227.895 1057.37 235.849L1080.07 679.949C1080.47 687.903 1075.74 695.238 1068.33 698.157L664.396 857.142C656.811 860.128 648.178 857.804 643.136 851.42L370.441 506.134Z"
          stroke="url(#paint5_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.0707071"
          d="M366.349 500.743C361.593 494.501 361.301 485.929 365.621 479.363L607.453 111.744C611.934 104.933 620.337 101.847 628.148 104.143L1043.24 226.186C1050.87 228.431 1056.21 235.315 1056.48 243.274L1071.59 686.843C1071.86 694.802 1067 702.051 1059.54 704.837L653.763 856.271C646.127 859.121 637.535 856.643 632.602 850.169L366.349 500.743Z"
          stroke="url(#paint6_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.0808081"
          d="M362.373 495.372C357.724 489.047 357.578 480.472 362.009 473.984L609.574 111.463C614.17 104.733 622.624 101.797 630.396 104.233L1042.53 233.43C1050.13 235.811 1055.34 242.789 1055.48 250.752L1063.02 693.652C1063.16 701.615 1058.18 708.776 1050.67 711.428L643.181 855.293C635.498 858.006 626.948 855.375 622.126 848.813L362.373 495.372Z"
          stroke="url(#paint7_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.0909091"
          d="M358.516 490.022C353.975 483.614 353.975 475.038 358.516 468.63L611.713 111.3C616.423 104.653 624.927 101.869 632.655 104.444L1041.72 240.726C1049.28 243.244 1054.37 250.314 1054.37 258.278V700.374C1054.37 708.338 1049.28 715.408 1041.72 717.925L632.655 854.207C624.927 856.782 616.423 853.999 611.713 847.352L358.516 490.022Z"
          stroke="url(#paint8_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.10101"
          d="M354.775 484.693C350.344 478.205 350.49 469.63 355.139 463.304L613.868 111.256C618.691 104.695 627.24 102.063 634.924 104.776L1040.8 248.074C1048.31 250.726 1053.29 257.886 1053.15 265.849L1045.64 707.006C1045.51 714.968 1040.29 721.947 1032.69 724.328L622.186 853.014C614.415 855.451 605.96 852.515 601.364 845.785L354.775 484.693Z"
          stroke="url(#paint9_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.111111"
          d="M351.148 479.391C346.828 472.825 347.12 464.253 351.876 458.011L616.034 111.334C620.967 104.86 629.56 102.382 637.196 105.232L1039.77 255.473C1047.24 258.259 1052.09 265.508 1051.82 273.467L1036.83 713.549C1036.56 721.509 1031.23 728.393 1023.59 730.638L611.772 851.719C603.961 854.015 595.558 850.929 591.078 844.118L351.148 479.391Z"
          stroke="url(#paint10_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.121212"
          d="M347.638 474.115C343.43 467.471 343.868 458.905 348.73 452.749L618.213 111.53C623.255 105.145 631.887 102.822 639.473 105.808L1038.64 262.918C1046.06 265.837 1050.79 273.172 1050.38 281.126L1027.95 720C1027.54 727.954 1022.09 734.742 1014.42 736.85L601.42 850.317C593.571 852.474 585.223 849.238 580.859 842.347L347.638 474.115Z"
          stroke="url(#paint11_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.131313"
          d="M344.242 468.87C340.147 462.151 340.731 453.593 345.697 447.524L620.4 111.848C625.55 105.555 634.22 103.386 641.752 106.507L1037.4 270.41C1044.76 273.461 1049.37 280.88 1048.83 288.826L1018.99 726.36C1018.44 734.306 1012.88 740.996 1005.17 742.966L591.128 848.815C583.245 850.831 574.954 847.446 570.709 840.479L344.242 468.87Z"
          stroke="url(#paint12_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.141414"
          d="M340.958 463.655C336.978 456.863 337.708 448.315 342.777 442.335L622.593 112.285C627.849 106.085 636.553 104.072 644.03 107.327L1036.05 277.945C1043.36 281.128 1047.83 288.629 1047.16 296.565L1009.95 732.626C1009.27 740.562 1003.59 747.151 995.854 748.984L580.899 847.212C572.984 849.086 564.753 845.553 560.627 838.512L340.958 463.655Z"
          stroke="url(#paint13_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.151515"
          d="M337.789 458.473C333.925 451.61 334.8 443.074 339.971 437.185L624.792 112.841C630.153 106.737 638.887 104.88 646.307 108.267L1034.58 285.521C1041.84 288.834 1046.19 296.415 1045.38 304.338L1000.84 738.796C1000.03 746.72 994.241 753.207 986.471 754.901L570.735 845.508C562.791 847.24 554.624 843.561 550.618 836.448L337.789 458.473Z"
          stroke="url(#paint14_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.161616"
          d="M334.731 453.326C330.983 446.394 332.003 437.873 337.275 432.076L626.993 113.517C632.456 107.51 641.219 105.81 648.578 109.329L1033.01 293.137C1040.21 296.579 1044.43 304.237 1043.48 312.147L991.664 744.871C990.717 752.78 984.817 759.164 977.02 760.718L560.637 843.707C552.666 845.295 544.564 841.472 540.681 834.288L334.731 453.326Z"
          stroke="url(#paint15_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.171717"
          d="M331.784 448.218C328.154 441.218 329.32 432.714 334.691 427.011L629.194 114.313C634.758 108.406 643.546 106.863 650.843 110.512L1031.33 300.791C1038.47 304.362 1042.56 312.095 1041.47 319.987L982.417 750.85C981.335 758.742 975.328 765.021 967.505 766.435L550.606 841.809C542.612 843.254 534.579 839.287 530.819 832.036L331.784 448.218Z"
          stroke="url(#paint16_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.181818"
          d="M328.948 443.148C325.437 436.082 326.747 427.597 332.216 421.99L631.395 115.228C637.057 109.422 645.868 108.036 653.099 111.814L1029.54 308.479C1036.61 312.177 1040.57 319.984 1039.35 327.857L973.103 756.73C971.887 764.603 965.774 770.774 957.928 772.048L540.645 839.815C532.629 841.116 524.668 837.007 521.032 829.691L328.948 443.148Z"
          stroke="url(#paint17_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.191919"
          d="M326.22 438.12C322.829 430.99 324.285 422.526 329.849 417.016L633.591 116.262C639.35 110.559 648.18 109.331 655.344 113.236L1027.63 316.201C1034.64 320.025 1038.46 327.902 1037.11 335.754L963.724 762.511C962.374 770.363 956.156 776.425 948.291 777.559L530.755 837.727C522.72 838.885 514.833 834.634 511.323 827.255L326.22 438.12Z"
          stroke="url(#paint18_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.20202"
          d="M323.602 433.132C320.331 425.941 321.932 417.5 327.591 412.089L635.782 117.412C641.637 111.814 650.483 110.744 657.576 114.776L1025.61 323.952C1032.56 327.9 1036.24 335.846 1034.76 343.675L954.283 768.19C952.799 776.018 946.478 781.97 938.595 782.962L520.937 835.544C512.886 836.558 505.075 832.168 501.692 824.728L323.602 433.132Z"
          stroke="url(#paint19_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.212121"
          d="M321.09 428.192C317.942 420.942 319.686 412.527 325.439 407.216L637.964 118.684C643.913 113.192 652.772 112.28 659.793 116.437L1023.48 331.735C1030.36 335.806 1033.91 343.819 1032.29 351.622L944.779 773.771C943.162 781.574 936.741 787.413 928.842 788.264L511.192 833.274C503.128 834.143 495.397 829.615 492.141 822.117L321.09 428.192Z"
          stroke="url(#paint20_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.222222"
          d="M318.686 423.297C315.66 415.989 317.549 407.602 323.393 402.393L640.138 120.071C646.178 114.688 655.047 113.934 661.994 118.214L1021.24 339.542C1028.05 343.736 1031.46 351.812 1029.71 359.587L935.217 779.248C933.467 787.023 926.947 792.748 919.036 793.458L501.523 830.911C493.449 831.635 485.799 826.971 482.673 819.418L318.686 423.297Z"
          stroke="url(#paint21_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.232323"
          d="M316.386 418.452C313.484 411.088 315.516 402.731 321.45 397.626L642.299 121.577C648.428 116.304 657.305 115.709 664.175 120.111L1018.88 347.375C1025.62 351.69 1028.89 359.828 1027.01 367.573L925.597 784.624C923.714 792.368 917.097 797.978 909.176 798.546L491.93 828.462C483.848 829.041 476.283 824.243 473.286 816.636L316.386 418.452Z"
          stroke="url(#paint22_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.242424"
          d="M314.191 413.658C311.413 406.24 313.588 397.916 319.611 392.915L644.447 123.201C650.663 118.039 659.545 117.602 666.336 122.124L1016.42 355.231C1023.08 359.666 1026.21 367.863 1024.19 375.575L915.922 789.896C913.906 797.608 907.195 803.1 899.265 803.527L482.415 825.927C474.328 826.361 466.85 821.43 463.983 813.772L314.191 413.658Z"
          stroke="url(#paint23_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.252525"
          d="M312.1 408.914C309.448 401.444 311.766 393.155 317.876 388.261L646.58 124.94C652.882 119.891 661.765 119.612 668.475 124.253L1013.84 363.107C1020.42 367.659 1023.41 375.913 1021.26 383.591L906.194 795.063C904.047 802.74 897.242 808.114 889.307 808.398L472.98 823.305C464.892 823.594 457.503 818.532 454.767 810.826L312.1 408.914Z"
          stroke="url(#paint24_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.262626"
          d="M310.109 404.225C307.584 396.705 310.044 388.453 316.239 383.667L648.694 126.794C655.079 121.86 663.96 121.74 670.587 126.497L1011.14 371C1017.64 375.669 1020.5 383.978 1018.22 391.619L896.413 800.124C894.135 807.765 887.239 813.018 879.301 813.16L463.625 820.599C455.536 820.744 448.239 815.552 445.636 807.8L310.109 404.225Z"
          stroke="url(#paint25_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.272727"
          d="M308.221 399.584C305.823 392.017 308.425 383.804 314.704 379.127L650.789 128.758C657.256 123.94 666.132 123.977 672.674 128.85L1008.33 378.903C1014.76 383.688 1017.47 392.048 1015.06 399.65L886.583 805.073C884.175 812.674 877.19 817.805 869.251 817.805L454.353 817.805C446.267 817.805 439.064 812.486 436.594 804.691L308.221 399.584Z"
          stroke="url(#paint26_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.282828"
          d="M306.432 395.009C304.161 387.395 306.905 379.225 313.266 374.658L652.861 130.843C659.408 126.143 668.277 126.339 674.731 131.325L1005.41 386.828C1011.75 391.726 1014.32 400.137 1011.78 407.697L876.705 809.923C874.166 817.483 867.094 822.491 859.157 822.349L445.163 814.939C437.083 814.795 429.977 809.35 427.64 801.513L306.432 395.009Z"
          stroke="url(#paint27_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.292929"
          d="M304.741 390.493C302.6 382.837 305.484 374.71 311.925 370.256L654.911 133.044C661.535 128.463 670.393 128.816 676.757 133.915L1002.38 394.766C1008.63 399.776 1011.05 408.234 1008.39 415.75L866.781 814.667C864.113 822.183 856.956 827.066 849.024 826.782L436.057 811.995C427.986 811.706 420.979 806.138 418.776 798.263L304.741 390.493Z"
          stroke="url(#paint28_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.30303"
          d="M303.147 386.037C301.135 378.339 304.16 370.258 310.679 365.917L656.934 135.357C663.633 130.897 672.478 131.407 678.751 136.615L999.229 402.711C1005.4 407.833 1007.67 416.335 1004.88 423.805L856.813 819.3C854.016 826.771 846.776 831.528 838.85 831.102L427.037 808.973C418.977 808.54 412.071 802.85 410.003 794.939L303.147 386.037Z"
          stroke="url(#paint29_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.313131"
          d="M301.649 381.645C299.768 373.907 302.933 365.875 309.528 361.65L658.93 137.785C665.702 133.446 674.531 134.113 680.71 139.429L995.968 410.667C1002.05 415.897 1004.18 424.442 1001.25 431.864L846.803 823.828C843.878 831.251 836.558 835.88 828.641 835.313L418.104 805.877C410.058 805.3 403.255 799.491 401.323 791.547L301.649 381.645Z"
          stroke="url(#paint30_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.323232"
          d="M300.246 377.315C298.496 369.54 301.8 361.559 308.47 357.449L660.896 140.323C667.739 136.107 676.549 136.93 682.633 142.352L992.593 418.626C998.582 423.964 1000.56 432.548 997.512 439.921L836.753 828.245C833.701 835.618 826.302 840.118 818.397 839.409L409.259 802.706C401.228 801.986 394.531 796.059 392.735 788.084L300.246 377.315Z"
          stroke="url(#paint31_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.333333"
          d="M298.937 373.052C297.318 365.242 300.76 357.314 307.504 353.322L662.832 142.974C669.743 138.882 678.531 139.861 684.518 145.387L989.105 426.591C995.001 432.034 996.837 440.655 993.658 447.976L826.666 832.554C823.487 839.874 816.011 844.244 808.12 843.394L400.503 799.465C392.491 798.602 385.902 792.56 384.242 784.556L298.937 373.052Z"
          stroke="url(#paint32_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.343434"
          d="M297.72 368.856C296.234 361.013 299.814 353.14 306.629 349.267L664.735 145.734C671.712 141.768 680.476 142.902 686.364 148.531L985.505 434.556C991.308 440.104 992.995 448.759 989.69 456.025L816.543 836.752C813.239 844.018 805.688 848.256 797.814 847.265L391.838 796.154C383.848 795.148 377.368 788.992 375.846 780.963L297.72 368.856Z"
          stroke="url(#paint33_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.353535"
          d="M296.593 364.729C295.239 356.856 298.956 349.041 305.841 345.288L666.602 148.606C673.643 144.767 682.38 146.055 688.166 151.784L981.791 442.522C987.498 448.172 989.036 456.86 985.606 464.069L806.385 840.841C802.956 848.051 795.332 852.156 787.477 851.024L383.262 792.776C375.297 791.628 368.928 785.361 367.543 777.309L296.593 364.729Z"
          stroke="url(#paint34_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.363636"
          d="M295.554 360.672C294.334 352.771 298.188 345.016 305.139 341.384L668.431 151.585C675.534 147.874 684.241 149.316 689.924 155.144L977.964 450.485C983.573 456.236 984.962 464.952 981.408 472.103L796.195 844.819C792.641 851.969 784.948 855.941 777.114 854.669L374.779 789.33C366.84 788.041 360.585 781.665 359.338 773.592L295.554 360.672Z"
          stroke="url(#paint35_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.373737"
          d="M294.605 356.688C293.518 348.76 297.507 341.068 304.525 337.558L670.223 154.674C677.386 151.092 686.059 152.686 691.639 158.611L974.027 458.444C979.536 464.294 980.774 473.036 977.098 480.126L785.975 848.687C782.299 855.776 774.537 859.613 766.727 858.201L366.389 785.821C358.48 784.391 352.34 777.909 351.231 769.818L294.605 356.688Z"
          stroke="url(#paint36_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.383838"
          d="M293.743 352.775C292.79 344.824 296.914 337.196 303.995 333.81L671.975 157.868C679.195 154.416 687.833 156.163 693.307 162.181L969.977 466.395C975.386 472.342 976.473 481.108 972.675 488.135L775.729 852.441C771.93 859.468 764.103 863.168 756.318 861.617L358.095 782.248C350.217 780.678 344.195 774.092 343.224 765.985L293.743 352.775Z"
          stroke="url(#paint37_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.393939"
          d="M292.963 348.938C292.145 340.965 296.403 333.404 303.546 330.143L673.682 161.17C680.957 157.849 689.557 159.747 694.923 165.858L965.814 474.339C971.12 480.381 972.056 489.168 968.136 496.13L765.453 856.086C761.533 863.048 753.643 866.61 745.887 864.92L349.893 778.615C342.049 776.906 336.146 770.218 335.314 762.098L292.963 348.938Z"
          stroke="url(#paint38_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.40404"
          d="M292.268 345.175C291.586 337.183 295.977 329.691 303.179 326.556L675.348 164.575C682.675 161.386 691.234 163.434 696.491 169.635L961.542 482.27C966.743 488.405 967.528 497.211 963.488 504.106L755.156 859.617C751.116 866.511 743.164 869.935 735.439 868.107L341.789 774.922C333.982 773.074 328.2 766.287 327.506 758.156L292.268 345.175Z"
          stroke="url(#paint39_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.414141"
          d="M291.655 341.492C291.109 333.483 295.631 326.062 302.892 323.054L676.967 168.087C684.345 165.03 692.86 167.228 698.007 173.517L957.159 490.19C962.254 496.417 962.887 505.239 958.727 512.065L744.836 863.038C740.677 869.863 732.666 873.148 724.974 871.181L333.782 771.174C326.013 769.188 320.354 762.304 319.799 754.165L291.655 341.492Z"
          stroke="url(#paint40_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.424242"
          d="M291.123 337.887C290.712 329.863 295.366 322.516 302.682 319.636L678.539 171.701C685.965 168.778 694.434 171.124 699.468 177.499L952.664 498.096C957.653 504.412 958.133 513.248 953.856 520.001L734.496 866.347C730.219 873.101 722.151 876.244 714.495 874.141L325.871 767.37C318.144 765.247 312.609 758.268 312.193 750.124L291.123 337.887Z"
          stroke="url(#paint41_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.434343"
          d="M290.669 334.361C290.395 326.324 295.178 319.053 302.548 316.303L680.061 175.417C687.533 172.628 695.953 175.122 700.874 181.58L948.061 505.984C952.94 512.388 953.268 521.234 948.874 527.914L724.139 869.543C719.744 876.223 711.622 879.225 704.004 876.985L318.059 763.512C310.376 761.253 304.967 754.182 304.689 746.035L290.669 334.361Z"
          stroke="url(#paint42_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.444444"
          d="M290.292 330.917C290.155 322.87 295.067 315.678 302.488 313.058L681.532 179.234C689.047 176.581 697.416 179.221 702.222 185.76L943.347 513.855C948.116 520.344 948.291 529.198 943.781 535.802L713.764 872.627C709.254 879.232 701.08 882.091 693.502 879.716L310.346 759.604C302.709 757.21 297.427 750.049 297.288 741.901L290.292 330.917Z"
          stroke="url(#paint43_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.454545"
          d="M289.992 327.556C289.992 319.502 295.031 312.39 302.502 309.901L682.952 183.152C690.507 180.634 698.823 183.42 703.513 190.038L938.526 521.705C943.183 528.278 943.205 537.137 938.58 543.663L703.376 875.6C698.751 882.127 690.528 884.843 682.992 882.332L302.733 755.647C295.145 753.119 289.992 745.87 289.992 737.725L289.992 327.556Z"
          stroke="url(#paint44_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.464646"
          d="M289.766 324.278C289.903 316.218 295.069 309.189 302.587 306.832L684.318 187.167C691.912 184.787 700.171 187.716 704.743 194.412L933.596 529.533C938.14 536.187 938.009 545.048 933.271 551.495L692.977 878.459C688.238 884.906 679.967 887.478 672.477 884.834L295.221 751.641C287.684 748.98 282.661 741.647 282.799 733.506L289.766 324.278Z"
          stroke="url(#paint45_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.474747"
          d="M289.61 321.083C289.884 313.021 295.176 306.077 302.739 303.853L685.625 191.279C693.255 189.036 701.455 192.108 705.909 198.879L928.557 537.335C932.986 544.068 932.702 552.929 927.852 559.294L682.564 881.206C677.714 887.571 669.398 889.998 661.954 887.22L287.807 747.59C280.323 744.797 275.432 737.381 275.709 729.247L289.61 321.083Z"
          stroke="url(#paint46_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.484848"
          d="M289.527 317.975C289.939 309.913 295.354 303.056 302.96 300.966L686.877 195.489C694.541 193.383 702.68 196.597 707.014 203.44L923.412 545.111C927.726 551.921 927.288 560.779 922.327 567.06L672.145 883.84C667.184 890.122 658.826 892.403 651.432 889.492L280.497 743.495C273.068 740.571 268.31 733.076 268.726 724.951L289.527 317.975Z"
          stroke="url(#paint47_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.494949"
          d="M289.513 314.954C290.063 306.893 295.601 300.126 303.248 298.171L688.07 199.792C695.765 197.825 703.841 201.179 708.054 208.092L918.162 552.858C922.359 559.744 921.767 568.595 916.697 574.791L661.721 886.362C656.65 892.558 648.252 894.692 640.909 891.65L273.29 739.358C265.919 736.304 261.295 728.731 261.848 720.619L289.513 314.954Z"
          stroke="url(#paint48_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.50505"
          d="M289.567 312.022C290.255 303.966 295.914 297.29 303.599 295.471L689.203 204.191C696.927 202.363 704.937 205.856 709.028 212.837L912.806 560.577C916.884 567.536 916.14 576.379 910.961 582.487L651.292 888.774C646.113 894.882 637.677 896.869 630.388 893.697L266.185 735.183C258.873 732.001 254.385 724.353 255.076 716.256L289.567 312.022Z"
          stroke="url(#paint49_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.515152"
          d="M289.687 309.179C290.512 301.129 296.291 294.548 304.013 292.865L690.273 208.682C698.024 206.993 705.966 210.624 709.934 217.67L907.345 568.263C911.303 575.293 910.406 584.124 905.121 590.143L640.86 891.073C635.575 897.092 627.103 898.93 619.871 895.629L259.183 730.969C251.933 727.66 247.581 719.941 248.41 711.86L289.687 309.179Z"
          stroke="url(#paint50_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.525253"
          d="M289.871 306.425C290.834 298.384 296.731 291.9 304.487 290.354L691.28 213.263C699.055 211.714 706.927 215.481 710.771 222.591L901.78 575.915C905.618 583.014 904.567 591.83 899.177 597.757L630.428 893.26C625.038 899.187 616.533 900.877 609.359 897.447L252.285 726.72C245.099 723.284 240.885 715.495 241.85 707.434L289.871 306.425Z"
          stroke="url(#paint51_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.535354"
          d="M290.116 303.763C291.216 295.734 297.231 289.348 305.018 287.94L692.221 217.935C700.018 216.525 707.817 220.427 711.536 227.599L896.11 583.532C899.826 590.697 898.622 599.497 893.129 605.33L619.996 895.337C614.502 901.17 605.967 902.71 598.853 899.153L245.489 722.437C238.37 718.877 234.294 711.021 235.396 702.982L290.116 303.763Z"
          stroke="url(#paint52_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.545455"
          d="M290.424 301.192C291.662 293.178 297.792 286.892 305.609 285.623L693.098 222.695C700.914 221.425 708.639 225.461 712.232 232.691L890.341 591.111C893.934 598.341 892.578 607.12 886.982 612.858L609.57 897.302C603.974 903.039 595.411 904.43 588.36 900.746L238.801 718.122C231.75 714.438 227.814 706.518 229.052 698.503L290.424 301.192Z"
          stroke="url(#paint53_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.555556"
          d="M290.789 298.714C292.164 290.716 298.408 284.533 306.252 283.403L693.905 227.541C701.738 226.412 709.386 230.58 712.852 237.866L884.467 598.65C887.936 605.942 886.428 614.699 880.731 620.34L599.147 899.155C593.45 904.796 584.862 906.036 577.876 902.227L232.215 713.777C225.234 709.971 221.439 701.989 222.812 694.001L290.789 298.714Z"
          stroke="url(#paint54_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.565657"
          d="M291.212 296.33C292.725 288.352 299.081 282.274 306.949 281.284L694.643 232.474C702.491 231.486 710.06 235.784 713.398 243.125L878.494 606.149C881.838 613.501 880.177 622.233 874.382 627.774L588.732 900.9C582.936 906.441 574.325 907.53 567.406 903.597L225.735 709.405C218.826 705.479 215.172 697.437 216.681 689.479L291.212 296.33Z"
          stroke="url(#paint55_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.575758"
          d="M291.692 294.04C293.341 286.083 299.808 280.113 307.698 279.262L695.312 237.49C703.172 236.643 710.66 241.07 713.87 248.462L872.422 613.604C875.64 621.014 873.828 629.717 867.935 635.158L578.327 902.532C572.434 907.973 563.803 908.911 556.952 904.855L219.361 705.006C212.526 700.96 209.015 692.862 210.658 684.935L291.692 294.04Z"
          stroke="url(#paint56_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.585859"
          d="M292.224 291.847C294.01 283.914 300.586 278.053 308.496 277.343L695.908 242.59C703.778 241.884 711.183 246.438 714.263 253.88L866.251 621.016C869.341 628.482 867.379 637.154 861.39 642.492L567.932 904.057C561.944 909.395 553.295 910.181 546.515 906.004L213.092 700.585C206.333 696.421 202.965 688.269 204.742 680.376L292.224 291.847Z"
          stroke="url(#paint57_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.59596"
          d="M292.809 289.748C294.731 281.842 301.414 276.092 309.342 275.524L696.432 247.77C704.309 247.205 711.628 251.885 714.579 259.374L859.982 628.381C862.945 635.899 860.831 644.538 854.749 649.771L557.55 905.472C551.468 910.705 542.804 911.339 536.097 907.042L206.929 696.141C200.249 691.86 197.024 683.657 198.935 675.801L292.809 289.748Z"
          stroke="url(#paint58_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.606061"
          d="M293.444 287.747C295.503 279.87 302.291 274.234 310.234 273.807L696.881 253.03C704.764 252.606 711.996 257.411 714.816 264.944L853.617 635.698C856.451 643.268 854.187 651.87 848.013 656.996L547.184 906.778C541.009 911.905 532.333 912.386 525.701 907.97L200.873 691.677C194.272 687.282 191.192 679.03 193.235 671.213L293.444 287.747Z"
          stroke="url(#paint59_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.616162"
          d="M294.128 285.843C296.322 277.997 303.213 272.477 311.169 272.192L697.255 258.368C705.14 258.085 712.283 263.012 714.972 270.588L847.156 642.965C849.86 650.583 847.447 659.146 841.183 664.165L536.833 907.976C530.569 912.994 521.882 913.323 515.327 908.79L194.922 687.195C188.403 682.687 185.468 674.389 187.643 666.612L294.128 285.843Z"
          stroke="url(#paint60_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.626263"
          d="M294.858 284.038C297.187 276.226 304.18 270.823 312.145 270.681L697.551 263.783C705.437 263.642 712.489 268.69 715.046 276.306L840.6 650.183C843.174 657.847 840.612 666.368 834.259 671.276L526.501 909.067C520.148 913.976 511.454 914.151 504.978 909.502L189.077 682.699C182.642 678.079 179.853 669.737 182.159 662.004L294.858 284.038Z"
          stroke="url(#paint61_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.636364"
          d="M295.633 282.332C298.097 274.557 305.189 269.274 313.162 269.274L697.77 269.274C705.654 269.274 712.612 274.441 715.038 282.095L833.951 657.347C836.393 665.055 833.684 673.531 827.244 678.328L516.19 910.051C509.75 914.848 501.051 914.871 494.656 910.107L183.339 678.189C176.989 673.458 174.347 665.075 176.783 657.388L295.633 282.332Z"
          stroke="url(#paint62_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.646465"
          d="M296.452 280.724C299.05 272.988 306.239 267.826 314.217 267.969L697.911 274.836C705.789 274.977 712.654 280.262 714.946 287.952L827.21 664.455C829.52 672.205 826.664 680.634 820.139 685.318L505.901 910.928C499.377 915.612 490.675 915.481 484.363 910.604L177.708 673.666C171.445 668.826 168.949 660.405 171.515 652.766L296.452 280.724Z"
          stroke="url(#paint63_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.656566"
          d="M297.31 279.217C300.041 271.523 307.326 266.484 315.307 266.77L697.969 280.472C705.84 280.754 712.608 286.154 714.768 293.877L820.375 671.509C822.554 679.298 819.551 687.676 812.944 692.246L495.634 911.699C489.027 916.269 480.325 915.985 474.098 910.996L172.181 669.133C166.006 664.187 163.658 655.73 166.352 648.141L297.31 279.217Z"
          stroke="url(#paint64_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.666667"
          d="M298.21 277.808C301.074 270.158 308.452 265.246 316.433 265.675L697.948 286.176C705.81 286.598 712.48 292.113 714.506 299.866L813.453 678.503C815.498 686.329 812.35 694.654 805.662 699.108L485.394 912.364C478.706 916.817 470.008 916.38 463.868 911.281L166.762 664.592C160.678 659.54 158.478 651.05 161.299 643.513L298.21 277.808Z"
          stroke="url(#paint65_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.676768"
          d="M299.147 276.503C302.143 268.899 309.612 264.114 317.59 264.686L697.844 291.95C705.695 292.513 712.264 298.139 714.157 305.922L806.442 685.44C808.353 693.3 805.061 701.57 798.294 705.905L475.182 912.926C468.416 917.261 459.723 916.67 453.671 911.463L161.45 660.046C155.458 654.89 153.405 646.369 156.354 638.887L299.147 276.503Z"
          stroke="url(#paint66_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.686869"
          d="M300.118 275.297C303.246 267.743 310.804 263.087 318.777 263.802L697.655 297.79C705.493 298.493 711.96 304.23 713.719 312.039L799.342 692.315C801.119 700.207 797.683 708.419 790.839 712.635L464.998 913.383C458.154 917.599 449.469 916.855 443.508 911.541L156.242 655.495C150.343 650.237 148.439 641.689 151.513 634.263L300.118 275.297Z"
          stroke="url(#paint67_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.69697"
          d="M301.126 274.194C304.383 266.691 312.028 262.166 319.993 263.024L697.384 303.695C705.205 304.538 711.57 310.384 713.194 318.216L792.157 699.128C793.799 707.05 790.221 715.201 783.302 719.296L454.846 913.737C447.928 917.833 439.254 916.935 433.384 911.516L151.142 650.942C145.338 645.584 143.583 637.01 146.781 629.643L301.126 274.194Z"
          stroke="url(#paint68_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.707071"
          d="M302.163 273.194C305.55 265.745 313.279 261.353 321.234 262.354L697.025 309.665C704.828 310.647 711.087 316.6 712.576 324.454L784.885 705.879C786.392 713.828 782.672 721.915 775.681 725.889L444.724 913.991C437.733 917.965 429.073 916.914 423.297 911.391L146.145 646.391C140.438 640.934 138.831 632.337 142.153 625.032L302.163 273.194Z"
          stroke="url(#paint69_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.717172"
          d="M303.232 272.294C306.748 264.902 314.558 260.644 322.501 261.789L696.582 315.695C704.364 316.816 710.517 322.874 711.871 330.748L777.531 712.563C778.902 720.537 775.042 728.558 767.981 732.408L434.639 914.142C427.577 917.991 418.933 916.787 413.253 911.163L141.255 641.84C135.646 636.286 134.189 627.669 137.634 620.427L303.232 272.294Z"
          stroke="url(#paint70_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.727273"
          d="M304.329 271.499C307.973 264.166 315.863 260.044 323.791 261.332L696.052 321.787C703.811 323.047 709.855 329.207 711.074 337.098L770.094 719.182C771.329 727.178 767.33 735.131 760.2 738.856L424.589 914.193C417.459 917.918 408.834 916.561 403.25 910.836L136.471 637.294C130.961 631.645 129.653 623.01 133.22 615.833L304.329 271.499Z"
          stroke="url(#paint71_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.737374"
          d="M305.454 270.806C309.225 263.535 317.191 259.551 325.101 260.981L695.434 327.936C703.168 329.334 709.102 335.596 710.186 343.501L762.577 725.733C763.676 733.749 759.538 741.631 752.343 745.229L414.577 914.144C407.382 917.743 398.777 916.233 393.293 910.41L131.792 632.754C126.383 627.011 125.225 618.361 128.911 611.251L305.454 270.806Z"
          stroke="url(#paint72_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.747475"
          d="M306.602 270.218C310.498 263.011 318.539 259.166 326.429 260.738L694.726 334.142C702.432 335.678 708.255 342.04 709.203 349.958L754.978 732.216C755.94 740.25 751.667 748.057 744.407 751.528L404.603 913.998C397.344 917.469 388.763 915.807 383.379 909.887L127.216 628.222C121.91 622.388 120.9 613.725 124.706 606.685L306.602 270.218Z"
          stroke="url(#paint73_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.757576"
          d="M307.773 269.731C311.795 262.59 319.908 258.886 327.774 260.601L693.928 340.402C701.605 342.075 707.315 348.534 708.128 356.462L747.302 738.626C748.127 746.675 743.718 754.406 736.397 757.749L394.671 913.752C387.35 917.094 378.796 915.28 373.514 909.265L122.745 623.698C117.543 617.774 116.682 609.1 120.606 602.132L307.773 269.731Z"
          stroke="url(#paint74_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.767677"
          d="M308.966 269.35C313.111 262.277 321.293 258.716 329.134 260.572L693.041 346.716C700.685 348.525 706.282 355.08 706.959 363.016L739.549 744.966C740.237 753.028 735.694 760.68 728.314 763.893L384.781 913.41C377.401 916.622 368.876 914.657 363.698 908.549L118.378 619.187C113.281 613.175 112.57 604.493 116.61 597.598L308.966 269.35Z"
          stroke="url(#paint75_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.777778"
          d="M310.178 269.072C314.445 262.071 322.694 258.653 330.507 260.651L692.062 353.082C699.672 355.027 705.153 361.676 705.694 369.617L731.72 751.234C732.271 759.306 727.595 766.877 720.158 769.958L374.935 912.973C367.497 916.054 359.005 913.938 353.932 907.739L114.114 614.69C109.123 608.591 108.561 599.905 112.717 593.085L310.178 269.072Z"
          stroke="url(#paint76_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.787879"
          d="M311.408 268.899C315.796 261.971 324.109 258.699 331.891 260.837L690.992 359.497C698.565 361.578 703.929 368.318 704.335 376.263L723.817 757.428C724.23 765.508 719.424 772.995 711.932 775.944L365.135 912.441C357.643 915.39 349.185 913.123 344.219 906.835L109.953 610.209C105.07 604.026 104.658 595.336 108.928 588.594L311.408 268.899Z"
          stroke="url(#paint77_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.79798"
          d="M312.652 268.828C317.159 261.976 325.534 258.851 333.283 261.129L689.828 365.958C697.362 368.173 702.608 375.003 702.879 382.95L715.841 763.544C716.116 771.63 711.181 779.031 703.636 781.846L355.38 911.814C347.836 914.63 339.415 912.213 334.558 905.837L105.894 605.743C101.119 599.477 100.856 590.787 105.239 584.124L312.652 268.828Z"
          stroke="url(#paint78_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.808081"
          d="M313.911 268.864C318.537 262.089 326.97 259.112 334.683 261.53L688.572 372.467C696.066 374.816 701.192 381.734 701.327 389.68L707.795 769.586C707.932 777.675 702.869 784.987 695.275 787.668L345.676 911.096C338.082 913.777 329.701 911.211 324.953 904.75L101.938 601.297C97.2734 594.951 97.1593 586.263 101.654 579.682L313.911 268.864Z"
          stroke="url(#paint79_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.818182"
          d="M315.182 269.001C319.925 262.308 328.414 259.479 336.088 262.036L687.222 379.019C694.673 381.501 699.678 388.504 699.678 396.447L699.678 775.549C699.678 783.638 694.489 790.86 686.847 793.406L336.021 910.285C328.379 912.831 320.041 910.116 315.404 903.572L98.0824 596.872C93.5294 590.446 93.564 581.763 98.1686 575.265L315.182 269.001Z"
          stroke="url(#paint80_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.828283"
          d="M316.463 269.244C321.321 262.633 329.863 259.955 337.497 262.65L685.779 385.613C693.184 388.227 698.067 395.315 697.932 403.252L691.494 781.434C691.356 789.521 686.043 796.649 678.355 799.059L326.419 909.384C318.732 911.794 310.439 908.931 305.914 902.305L94.3283 592.47C89.8878 585.967 90.0709 577.291 94.7842 570.878L316.463 269.244Z"
          stroke="url(#paint81_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.838384"
          d="M317.753 269.59C322.726 263.064 331.318 260.537 338.908 263.37L684.241 392.247C691.598 394.993 696.359 402.162 696.089 410.092L683.244 787.238C682.969 795.321 677.532 802.354 669.802 804.627L316.87 908.394C309.14 910.666 300.895 907.656 296.484 900.951L90.6749 588.092C86.3479 581.514 86.6793 572.847 91.4999 566.521L317.753 269.59Z"
          stroke="url(#paint82_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.848485"
          d="M319.048 270.041C324.133 263.602 332.773 261.229 340.317 264.198L682.606 398.921C689.914 401.797 694.55 409.047 694.145 416.966L674.927 792.963C674.515 801.039 668.957 807.974 661.187 810.109L307.374 907.316C299.604 909.451 291.409 906.294 287.114 899.511L87.1196 583.741C82.9073 577.09 83.3867 568.436 88.313 562.198L319.048 270.041Z"
          stroke="url(#paint83_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.858586"
          d="M320.348 270.595C325.544 264.245 334.228 262.025 341.723 265.13L680.876 405.63C688.132 408.636 692.642 415.963 692.103 423.871L666.547 798.604C665.997 806.671 660.32 813.506 652.512 815.502L297.934 906.15C290.126 908.146 281.984 904.843 277.805 897.985L83.6629 579.417C79.5662 572.695 80.1932 564.054 85.2239 557.907L320.348 270.595Z"
          stroke="url(#paint84_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.868687"
          d="M321.651 271.253C326.957 264.995 335.682 262.929 343.126 266.169L679.05 412.375C686.253 415.51 690.636 422.913 689.962 430.806L658.105 804.164C657.418 812.219 651.624 818.952 643.781 820.809L288.552 904.899C280.709 906.755 272.623 903.308 268.561 896.377L80.305 575.124C76.325 568.333 77.0994 559.709 82.2329 553.654L321.651 271.253Z"
          stroke="url(#paint85_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.878788"
          d="M322.956 272.015C328.369 265.851 337.133 263.941 344.523 267.315L677.128 419.153C684.275 422.416 688.53 429.893 687.722 437.77L649.604 809.64C648.779 817.682 642.87 824.31 634.994 826.027L279.228 903.564C271.353 905.28 263.325 901.689 259.382 894.687L77.0445 570.864C73.1822 564.005 74.1035 555.399 79.3383 549.438L322.956 272.015Z"
          stroke="url(#paint86_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.888889"
          d="M324.258 272.88C329.777 266.811 338.577 265.058 345.911 268.564L675.107 425.962C682.196 429.351 686.322 436.9 685.381 444.759L641.041 815.031C640.08 823.056 634.057 829.578 626.152 831.154L269.963 902.145C262.058 903.72 254.09 899.987 250.267 892.915L73.8787 566.636C70.1351 559.711 71.2029 551.127 76.5375 545.261L324.258 272.88L323.156 271.872L324.258 272.88Z"
          stroke="url(#paint87_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.89899"
          d="M325.559 273.848C331.182 267.877 340.014 266.28 347.29 269.919L672.99 432.8C680.02 436.315 684.016 443.934 682.941 451.772L632.423 820.336C631.326 828.343 625.191 834.756 617.258 836.19L260.76 900.644C252.828 902.078 244.923 898.203 241.221 891.064L70.8101 562.443C67.1862 555.455 68.3999 546.894 73.8327 541.126L325.559 273.848Z"
          stroke="url(#paint88_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.909091"
          d="M326.857 274.92C332.582 269.05 341.444 267.61 348.658 271.38L670.777 439.668C677.744 443.308 681.609 450.994 680.402 458.809L623.751 825.557C622.517 833.542 616.273 839.845 608.315 841.137L251.621 899.064C243.663 900.357 235.825 896.34 232.245 889.137L67.837 558.289C64.3336 551.239 65.6929 542.704 71.2222 537.035L326.857 274.92Z"
          stroke="url(#paint89_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.919192"
          d="M328.147 276.092C333.972 270.324 342.86 269.043 350.011 272.941L668.463 446.557C675.367 450.321 679.1 458.073 677.76 465.863L615.022 830.687C613.653 838.649 607.301 844.839 599.321 845.989L242.543 897.402C234.563 898.552 226.793 894.397 223.337 887.13L64.9558 554.17C61.5739 547.06 63.0783 538.554 68.7025 532.985L328.147 276.092Z"
          stroke="url(#paint90_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.929293"
          d="M329.43 277.368C335.354 271.703 344.265 270.581 351.35 274.608L666.053 453.473C672.891 457.359 676.491 465.174 675.019 472.937L606.242 835.731C604.738 843.667 598.28 849.743 590.281 850.75L233.532 895.664C225.532 896.671 217.833 892.377 214.501 885.05L62.169 550.093C58.9094 542.925 60.5582 534.45 66.2758 528.983L329.43 277.368Z"
          stroke="url(#paint91_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.939394"
          d="M330.704 278.745C336.725 273.186 345.656 272.224 352.673 276.378L663.546 460.41C670.315 464.417 673.781 472.294 672.178 480.028L597.412 840.686C595.773 848.594 589.212 854.553 581.196 855.417L224.587 893.848C216.57 894.712 208.944 890.281 205.738 882.896L59.4749 546.058C56.3384 538.834 58.1312 530.391 63.9403 525.028L330.704 278.745Z"
          stroke="url(#paint92_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.949495"
          d="M331.965 280.226C338.081 274.775 347.03 273.972 353.976 278.251L660.939 467.368C667.638 471.496 670.969 479.432 669.235 487.134L588.533 845.554C586.759 853.43 580.097 859.272 572.066 859.992L215.709 891.959C207.678 892.68 200.127 888.113 197.047 880.672L56.8709 542.067C53.8583 534.79 55.7945 526.382 61.6935 521.125L331.965 280.226Z"
          stroke="url(#paint93_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.959596"
          d="M333.214 281.804C339.422 276.463 348.385 275.82 355.258 280.224L658.233 474.343C664.862 478.589 668.056 486.582 666.191 494.251L579.606 850.328C577.699 858.171 570.938 863.893 562.894 864.469L206.9 889.994C198.856 890.571 191.384 885.87 188.431 878.375L54.3573 538.119C51.4695 530.791 53.5483 522.42 59.5355 517.269L333.214 281.804Z"
          stroke="url(#paint94_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.969697"
          d="M334.449 283.485C340.748 278.255 349.721 277.773 356.519 282.299L655.43 481.335C661.985 485.699 665.043 493.746 663.048 501.381L570.635 855.013C568.594 862.821 561.736 868.421 553.683 868.853L198.16 887.958C190.108 888.391 182.716 883.557 179.891 876.01L51.9335 534.219C49.1712 526.841 51.3921 518.51 57.4658 513.467L334.449 283.485Z"
          stroke="url(#paint95_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.979798"
          d="M335.666 285.264C342.054 280.147 351.035 279.825 357.755 284.473L652.527 488.339C659.006 492.82 661.927 500.92 659.802 508.516L561.618 859.605C559.445 867.375 552.492 872.851 544.433 873.139L189.491 885.848C181.432 886.137 174.123 881.172 171.427 873.576L49.597 530.365C46.9609 522.939 49.3232 514.65 55.4815 509.717L335.666 285.264Z"
          stroke="url(#paint96_linear_638_4873)"
          stroke-width="3"
        />
        <path
          opacity="0.989899"
          d="M336.867 287.144C343.341 282.142 352.327 281.981 358.967 286.748L649.527 495.358C655.929 499.954 658.711 508.105 656.458 515.662L552.561 864.106C550.256 871.836 543.211 877.187 535.148 877.331L180.895 883.671C172.832 883.816 165.609 878.72 163.042 871.077L47.3489 526.563C44.8398 519.091 47.3427 510.846 53.5839 506.024L336.867 287.144Z"
          stroke="url(#paint97_linear_638_4873)"
          stroke-width="3"
        />
        <path
          d="M338.046 289.123C344.605 284.237 353.592 284.237 360.151 289.123L646.427 502.387C652.75 507.097 655.393 515.296 653.011 522.812L543.462 868.515C541.026 876.202 533.891 881.426 525.827 881.426H172.37C164.306 881.426 157.171 876.202 154.735 868.515L45.1858 522.812C42.8042 515.296 45.4471 507.097 51.7694 502.387L338.046 289.123Z"
          stroke="url(#paint98_linear_638_4873)"
          stroke-width="3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_638_4873"
            x1="807.531"
            y1="702.238"
            x2="738.594"
            y2="223.852"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_638_4873"
            x1="799.136"
            y1="703.847"
            x2="738.867"
            y2="225.124"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_638_4873"
            x1="790.746"
            y1="705.382"
            x2="739.136"
            y2="226.487"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_638_4873"
            x1="782.365"
            y1="706.846"
            x2="739.404"
            y2="227.942"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_638_4873"
            x1="773.991"
            y1="708.236"
            x2="739.665"
            y2="229.486"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_638_4873"
            x1="765.626"
            y1="709.552"
            x2="739.917"
            y2="231.12"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_638_4873"
            x1="757.273"
            y1="710.796"
            x2="740.159"
            y2="232.844"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_638_4873"
            x1="748.93"
            y1="711.97"
            x2="740.387"
            y2="234.659"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_638_4873"
            x1="740.602"
            y1="713.071"
            x2="740.602"
            y2="236.562"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_638_4873"
            x1="732.289"
            y1="714.099"
            x2="740.8"
            y2="238.553"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_638_4873"
            x1="723.99"
            y1="715.059"
            x2="740.977"
            y2="240.634"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_638_4873"
            x1="715.709"
            y1="715.947"
            x2="741.134"
            y2="242.801"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_638_4873"
            x1="707.446"
            y1="716.766"
            x2="741.267"
            y2="245.057"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_638_4873"
            x1="699.201"
            y1="717.515"
            x2="741.373"
            y2="247.398"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_638_4873"
            x1="690.978"
            y1="718.195"
            x2="741.454"
            y2="249.824"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_638_4873"
            x1="682.776"
            y1="718.806"
            x2="741.503"
            y2="252.336"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_638_4873"
            x1="674.597"
            y1="719.35"
            x2="741.521"
            y2="254.932"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_638_4873"
            x1="666.442"
            y1="719.826"
            x2="741.506"
            y2="257.609"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_638_4873"
            x1="658.311"
            y1="720.236"
            x2="741.454"
            y2="260.369"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_638_4873"
            x1="650.207"
            y1="720.577"
            x2="741.365"
            y2="263.206"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_638_4873"
            x1="642.13"
            y1="720.856"
            x2="741.236"
            y2="266.127"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_638_4873"
            x1="634.082"
            y1="721.067"
            x2="741.066"
            y2="269.122"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_638_4873"
            x1="626.062"
            y1="721.215"
            x2="740.853"
            y2="272.197"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_638_4873"
            x1="618.073"
            y1="721.299"
            x2="740.595"
            y2="275.346"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_638_4873"
            x1="610.117"
            y1="721.32"
            x2="740.292"
            y2="278.569"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_638_4873"
            x1="602.192"
            y1="721.277"
            x2="739.939"
            y2="281.864"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_638_4873"
            x1="594.301"
            y1="721.166"
            x2="739.538"
            y2="285.223"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_638_4873"
            x1="586.444"
            y1="721.002"
            x2="739.085"
            y2="288.66"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint28_linear_638_4873"
            x1="578.623"
            y1="720.779"
            x2="738.58"
            y2="292.165"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint29_linear_638_4873"
            x1="570.838"
            y1="720.494"
            x2="738.02"
            y2="295.736"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint30_linear_638_4873"
            x1="563.091"
            y1="720.153"
            x2="737.406"
            y2="299.373"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint31_linear_638_4873"
            x1="555.382"
            y1="719.752"
            x2="736.736"
            y2="303.071"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint32_linear_638_4873"
            x1="547.712"
            y1="719.295"
            x2="736.007"
            y2="306.833"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint33_linear_638_4873"
            x1="540.084"
            y1="718.781"
            x2="735.222"
            y2="310.654"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint34_linear_638_4873"
            x1="532.496"
            y1="718.214"
            x2="734.374"
            y2="314.534"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint35_linear_638_4873"
            x1="524.949"
            y1="717.59"
            x2="733.466"
            y2="318.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint36_linear_638_4873"
            x1="517.446"
            y1="716.915"
            x2="732.497"
            y2="322.461"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint37_linear_638_4873"
            x1="509.988"
            y1="716.185"
            x2="731.466"
            y2="326.505"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint38_linear_638_4873"
            x1="502.572"
            y1="715.404"
            x2="730.37"
            y2="330.6"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint39_linear_638_4873"
            x1="495.203"
            y1="714.571"
            x2="729.211"
            y2="334.743"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint40_linear_638_4873"
            x1="487.88"
            y1="713.69"
            x2="727.987"
            y2="338.937"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint41_linear_638_4873"
            x1="480.604"
            y1="712.759"
            x2="726.698"
            y2="343.176"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint42_linear_638_4873"
            x1="473.375"
            y1="711.78"
            x2="725.342"
            y2="347.458"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint43_linear_638_4873"
            x1="466.195"
            y1="710.754"
            x2="723.92"
            y2="351.784"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint44_linear_638_4873"
            x1="459.064"
            y1="709.683"
            x2="722.432"
            y2="356.151"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint45_linear_638_4873"
            x1="451.984"
            y1="708.567"
            x2="720.876"
            y2="360.556"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint46_linear_638_4873"
            x1="444.951"
            y1="707.405"
            x2="719.25"
            y2="364.997"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint47_linear_638_4873"
            x1="437.972"
            y1="706.201"
            x2="717.559"
            y2="369.475"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint48_linear_638_4873"
            x1="431.045"
            y1="704.954"
            x2="715.8"
            y2="373.986"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint49_linear_638_4873"
            x1="424.171"
            y1="703.668"
            x2="713.972"
            y2="378.531"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint50_linear_638_4873"
            x1="417.349"
            y1="702.341"
            x2="712.075"
            y2="383.106"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint51_linear_638_4873"
            x1="410.581"
            y1="700.975"
            x2="710.11"
            y2="387.709"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint52_linear_638_4873"
            x1="403.865"
            y1="699.571"
            x2="708.075"
            y2="392.34"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint53_linear_638_4873"
            x1="397.208"
            y1="698.131"
            x2="705.975"
            y2="396.997"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint54_linear_638_4873"
            x1="390.602"
            y1="696.654"
            x2="703.803"
            y2="401.677"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint55_linear_638_4873"
            x1="384.054"
            y1="695.144"
            x2="701.564"
            y2="406.381"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint56_linear_638_4873"
            x1="377.563"
            y1="693.599"
            x2="699.258"
            y2="411.104"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint57_linear_638_4873"
            x1="371.127"
            y1="692.023"
            x2="696.883"
            y2="415.848"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint58_linear_638_4873"
            x1="364.749"
            y1="690.415"
            x2="694.441"
            y2="420.61"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint59_linear_638_4873"
            x1="358.43"
            y1="688.777"
            x2="691.932"
            y2="425.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint60_linear_638_4873"
            x1="352.167"
            y1="687.11"
            x2="689.355"
            y2="430.183"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint61_linear_638_4873"
            x1="345.964"
            y1="685.416"
            x2="686.711"
            y2="434.992"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint62_linear_638_4873"
            x1="339.819"
            y1="683.695"
            x2="684.002"
            y2="439.813"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint63_linear_638_4873"
            x1="333.733"
            y1="681.947"
            x2="681.226"
            y2="444.645"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint64_linear_638_4873"
            x1="327.706"
            y1="680.176"
            x2="678.383"
            y2="449.488"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint65_linear_638_4873"
            x1="321.74"
            y1="678.379"
            x2="675.477"
            y2="454.338"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint66_linear_638_4873"
            x1="315.834"
            y1="676.562"
            x2="672.506"
            y2="459.198"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint67_linear_638_4873"
            x1="309.987"
            y1="674.723"
            x2="669.47"
            y2="464.063"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint68_linear_638_4873"
            x1="304.202"
            y1="672.864"
            x2="666.371"
            y2="468.934"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint69_linear_638_4873"
            x1="298.475"
            y1="670.988"
            x2="663.207"
            y2="473.811"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint70_linear_638_4873"
            x1="292.812"
            y1="669.092"
            x2="659.982"
            y2="478.69"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint71_linear_638_4873"
            x1="287.209"
            y1="667.181"
            x2="656.695"
            y2="483.572"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint72_linear_638_4873"
            x1="281.668"
            y1="665.254"
            x2="653.347"
            y2="488.455"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint73_linear_638_4873"
            x1="276.187"
            y1="663.314"
            x2="649.936"
            y2="493.34"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint74_linear_638_4873"
            x1="270.769"
            y1="661.359"
            x2="646.465"
            y2="498.223"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint75_linear_638_4873"
            x1="265.412"
            y1="659.393"
            x2="642.934"
            y2="503.106"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint76_linear_638_4873"
            x1="260.116"
            y1="657.417"
            x2="639.344"
            y2="507.987"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint77_linear_638_4873"
            x1="254.883"
            y1="655.432"
            x2="635.695"
            y2="512.866"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint78_linear_638_4873"
            x1="249.71"
            y1="653.437"
            x2="631.986"
            y2="517.739"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint79_linear_638_4873"
            x1="244.601"
            y1="651.436"
            x2="628.222"
            y2="522.61"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint80_linear_638_4873"
            x1="239.552"
            y1="649.428"
            x2="624.399"
            y2="527.475"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint81_linear_638_4873"
            x1="234.566"
            y1="647.415"
            x2="620.521"
            y2="532.335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint82_linear_638_4873"
            x1="229.643"
            y1="645.398"
            x2="616.586"
            y2="537.187"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint83_linear_638_4873"
            x1="224.78"
            y1="643.38"
            x2="612.595"
            y2="542.034"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint84_linear_638_4873"
            x1="219.978"
            y1="641.359"
            x2="608.549"
            y2="546.872"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint85_linear_638_4873"
            x1="215.238"
            y1="639.338"
            x2="604.449"
            y2="551.703"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint86_linear_638_4873"
            x1="210.56"
            y1="637.318"
            x2="600.295"
            y2="556.525"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint87_linear_638_4873"
            x1="205.941"
            y1="635.3"
            x2="596.086"
            y2="561.338"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint88_linear_638_4873"
            x1="201.385"
            y1="633.284"
            x2="591.826"
            y2="566.141"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint89_linear_638_4873"
            x1="196.89"
            y1="631.275"
            x2="587.513"
            y2="570.936"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint90_linear_638_4873"
            x1="192.454"
            y1="629.268"
            x2="583.146"
            y2="575.717"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint91_linear_638_4873"
            x1="188.079"
            y1="627.268"
            x2="578.729"
            y2="580.488"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint92_linear_638_4873"
            x1="183.766"
            y1="625.277"
            x2="574.262"
            y2="585.248"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint93_linear_638_4873"
            x1="179.511"
            y1="623.295"
            x2="569.742"
            y2="589.998"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint94_linear_638_4873"
            x1="175.316"
            y1="621.32"
            x2="565.173"
            y2="594.732"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint95_linear_638_4873"
            x1="171.181"
            y1="619.357"
            x2="560.554"
            y2="599.456"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint96_linear_638_4873"
            x1="167.103"
            y1="617.404"
            x2="555.885"
            y2="604.163"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint97_linear_638_4873"
            x1="163.086"
            y1="615.466"
            x2="551.168"
            y2="608.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
          <linearGradient
            id="paint98_linear_638_4873"
            x1="159.127"
            y1="613.542"
            x2="546.401"
            y2="613.542"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#54C695" />
            <stop offset="1" stop-color="#D0D3DE" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>
