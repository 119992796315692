<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import OgS3FilePicker from './OgS3FilePicker.vue';

const { t } = useI18n();

const MAX_FILE_SIZE_IN_MB = 10;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
const IMAGE_FILE_TYPES = ['image/png', 'image/jpeg'];

function handleImagesValidation(images: File[]) {
  if (images.some((image) => !IMAGE_FILE_TYPES.includes(image.type))) {
    return t(
      'Upload failed. Only .png, .jpg and .jpeg images can be uploaded.',
    );
  }

  if (images.some((image) => image.size > MAX_FILE_SIZE_IN_BYTES)) {
    return t('Upload failed. Maximum allowed file size is {maxSizeInMB} MB.', {
      maxSizeInMB: MAX_FILE_SIZE_IN_MB,
    });
  }

  return null;
}
</script>

<template>
  <OgS3FilePicker
    :validate="handleImagesValidation"
    :accept="IMAGE_FILE_TYPES.join(', ')"
  />
</template>
