<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import { ApolloError } from '@apollo/client/core';
import { useRouter } from 'vue-router';
import {
  type TmSettingsMaterialityQuery,
  type UpdateEntityAssignmentInput,
  UserRole,
  type UserSubCategoryAssignmentInput,
  ReportingFrameworkEnum,
} from '@/__generated__/types';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import type { Project } from '@/components/pages/PgProjects/types';
import useUpdateEntityAssignmentMutation from '@/api/mutations/EntityAssignment/updateEntityAssignment.mutation';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';

import MlTabs from '@/components/molecules/MlTabs/MlTabs.vue';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import MlDialog from '@/components/molecules/MlDialog.vue';
import useUpdateUserSubcategoryAssignmentsMutation from '@/api/mutations/User/updateUserSubcategoryAssignments.mutation';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import OgRestrictionsModal from '@/components/organisms/OgRestrictionsModal/OgRestrictionsModal.vue';
import { transformToCategorySubcategoryPairs } from '@/utils/helpers/transformToCategorySubcategoryPairs';
import { TabName } from './types';
import TM_SETTINGS_MATERIALITY_QUERY from './TmSettingsMateriality.query';

const router = useRouter();

const props = withDefaults(
  defineProps<{
    isShown: boolean;
    project: Project;
    category: string;
    defaultTab: TabName;
    numberOfAvailableTopics?: number;
  }>(),
  {
    isShown: false,
    numberOfAvailableTopics: -1,
  },
);

const emit = defineEmits<TEmits>();
type TEmits = {
  (e: 'close'): void;
};

const { t } = useI18n();
const translateCategory = useCategoryTranslate();

const actionPanelTabs = computed(() => {
  if (isAdminOrSuperAdmin.value) {
    if (subcategoriesAssigned.value.length > 0) {
      return [TabName.TOPICS, TabName.REPORTERS];
    }

    return [TabName.TOPICS];
  }

  return [TabName.REPORTERS];
});
const activeTab = ref<TabName>(props.defaultTab);

watch(
  () => [props.defaultTab, props.category],
  () => {
    activeTab.value = props.defaultTab;
  },
);

const {
  mutate: updateUserSubcategoryAssignmentsMutation,
  loading: updateUserSubcategoryAssignmentsLoading,
  error: updateUserSubcategoryAssignmentsError,
} = useUpdateUserSubcategoryAssignmentsMutation({
  update: (store) => store.evict({ fieldName: 'getTeamUsers' }),
});

const applyUserSubcategoryAssigments = async () => {
  const payload: UserSubCategoryAssignmentInput[] = [];

  Object.values(userSubcategoryAssignmentsMap.value).forEach(
    (subcategoryMap) => {
      subcategoryMap.forEach((subcategoryData) => {
        if (subcategoryData.newUsersIds.length > 0) {
          subcategoryData.newUsersIds.forEach((newUserId) => {
            payload.push({
              assigneeId: newUserId,
              location: subcategoryData.location,
              role: subcategoryData.role,
              subcategory: subcategoryData.subcategory,
            });
          });
        } else {
          payload.push({
            assigneeId: '',
            location: subcategoryData.location,
            role: subcategoryData.role,
            subcategory: subcategoryData.subcategory,
          });
        }
      });
    },
  );

  try {
    await updateUserSubcategoryAssignmentsMutation({
      subcategoryAssignments: { userSubcategoryAssignmentInputs: payload },
    });
    notify({ text: t('Assignees have been updated.'), type: 'success' });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(updateUserSubcategoryAssignmentsError.value?.message);
  }
};

const {
  mutate: updateEntityAssignment,
  loading: loadingUpdateEntityAssignment,
} = useUpdateEntityAssignmentMutation({
  refetchQueries: [{ query: TM_SETTINGS_MATERIALITY_QUERY }],
  update: (store) => {
    store.evict({ fieldName: 'getCategoriesWithSubcategories' });
    store.evict({ fieldName: 'entityLocationSummaries' });
    store.evict({ fieldName: 'getMyDataPointRequests' });
  },
});

const { result, refetch } = useQuery<TmSettingsMaterialityQuery>(
  TM_SETTINGS_MATERIALITY_QUERY,
);

onMounted(() => refetch());

const { isAdminOrSuperAdmin } = useCurrentUser();

const teamMembers = computed(() => result.value?.getTeamUsers ?? []);

const hasTeamMembers = computed(() => teamMembers.value.length > 1);
const showRestrictionsModal = ref(false);

type TUserSubcategoryAssignment = UserSubCategoryAssignmentInput & {
  oldUsersIds: string[];
  newUsersIds: string[];
};

const userSubcategoryAssignmentsMap = ref<
  Record<UserRole, Map<string, TUserSubcategoryAssignment>>
>({
  [UserRole.SUPERADMIN]: new Map<string, TUserSubcategoryAssignment>(),
  [UserRole.ADMIN]: new Map<string, TUserSubcategoryAssignment>(),
  [UserRole.AUDITOR]: new Map<string, TUserSubcategoryAssignment>(),
  [UserRole.MANAGER]: new Map<string, TUserSubcategoryAssignment>(),
  [UserRole.CONTRIBUTOR]: new Map<string, TUserSubcategoryAssignment>(),
  [UserRole.NONE]: new Map<string, TUserSubcategoryAssignment>(),
});

const subcategories = computed(() =>
  transformToCategorySubcategoryPairs(
    result.value?.getCategoriesWithSubcategories || [],
  ),
);

const updateUserSubcategoryAssignmentsMap = (
  subcategory: string,
  data: TUserSubcategoryAssignment,
) => {
  if (!data.newUsersIds) {
    data.newUsersIds = [];
  }

  if (data.oldUsersIds.length === 0 && data.newUsersIds.length === 0) {
    userSubcategoryAssignmentsMap.value[data.role].delete(data.subcategory);
    return;
  }

  userSubcategoryAssignmentsMap.value[data.role].set(subcategory, data);
};

const assignUserByTopic = (
  topic: string,
  userRole: UserRole,
  userIds: string[],
) => {
  const topicSubcategories =
    availableESRSTopicsForAssignment.value
      .find((category) => category.topic === topic)
      ?.subcategories.map((s) => s.subcategory) ?? [];

  const newAssignedSubcategoriesForUser = [
    ...topicAssignments.value.filter((a) => a?.topic !== topic),
    ...userIds.map((userId) => ({
      userId,
      role: userRole,
      topic,
      category: props.category,
      locationId: props.project._id,
      isActive: true,
    })),
  ];
  topicAssignments.value = newAssignedSubcategoriesForUser;

  topicSubcategories.forEach((subcategory) => {
    const currentAssignment =
      userSubcategoryAssignmentsMap.value[selectedAssignment.value].get(
        subcategory,
      ) || {};

    updateUserSubcategoryAssignmentsMap(subcategory, {
      ...currentAssignment,
      newUsersIds: userIds,
      oldUsersIds: [], // todo
      location: props.project._id,
      role: userRole,
      subcategory,
    });
  });
};

const topicAssigments = ({
  topic,
  role,
  locationId,
  category,
  isActive,
}: {
  topic: string;
  role: UserRole;
  locationId: string;
  category: string;
  isActive: boolean;
}) => {
  return !!topicAssignments.value?.find(
    (topicAssignment) =>
      topicAssignment.role === role &&
      topicAssignment.topic === topic &&
      topicAssignment.locationId === locationId &&
      topicAssignment.category === category &&
      isActive === topicAssignment.isActive,
  );
};

const availableESRSTopicsForAssignment = computed(() => {
  return ESRSTopics.value
    .map((item) => ({
      ...item,
      subcategories: item.subcategories.filter((subcategory) =>
        subcategoriesAssigned.value.includes(subcategory.subcategory),
      ),
    }))
    .map((categoryGroup) => ({
      ...categoryGroup,
      subcategories: categoryGroup.subcategories.filter((subcategory) =>
        selectedSubcategoriesForm.value.includes(subcategory.subcategory),
      ),
    }))
    .filter((categoryGroup) => categoryGroup.subcategories.length > 0);
});

const populateUserSubcategoryAssignments = () => {
  userSubcategoryAssignmentsMap.value = {
    [UserRole.SUPERADMIN]: new Map(),
    [UserRole.ADMIN]: new Map(),
    [UserRole.AUDITOR]: new Map(),
    [UserRole.MANAGER]: new Map(),
    [UserRole.CONTRIBUTOR]: new Map(),
    [UserRole.NONE]: new Map(),
  };

  teamMembers.value.forEach((teamMember) => {
    teamMember.assignments.subcategories.forEach((subcategory) => {
      const isCurrentLocation = subcategory.location._id === props.project._id;

      const isCurrentCategory =
        subcategories.value.find((s) => {
          return s.subcategory === subcategory.subcategory;
        })?.category === props.category;

      if (isCurrentLocation && isCurrentCategory) {
        if (
          !userSubcategoryAssignmentsMap.value[subcategory.role].get(
            subcategory.subcategory,
          )
        ) {
          userSubcategoryAssignmentsMap.value[subcategory.role].set(
            subcategory.subcategory,
            {
              oldUsersIds: [teamMember._id],
              location: subcategory.location._id,
              role: subcategory.role,
              subcategory: subcategory.subcategory,
              newUsersIds: [teamMember._id],
            },
          );
        } else {
          const existingAssignment = userSubcategoryAssignmentsMap.value[
            subcategory.role
          ].get(subcategory.subcategory);
          if (existingAssignment) {
            existingAssignment.oldUsersIds.push(teamMember._id);
            existingAssignment.newUsersIds.push(teamMember._id);
          }
        }
      }
    });
  });
};

watch(teamMembers, populateUserSubcategoryAssignments, { immediate: true });

const assignableRoles = computed(() =>
  isAdminOrSuperAdmin.value
    ? [
        UserRole.SUPERADMIN,
        UserRole.ADMIN,
        UserRole.MANAGER,
        UserRole.CONTRIBUTOR,
      ]
    : [UserRole.CONTRIBUTOR],
);

type TTeamMemberOptions = {
  [UserRole.NONE]: Record<string, string>;
  [UserRole.MANAGER]: Record<string, string>;
  [UserRole.CONTRIBUTOR]: Record<string, string>;
  [UserRole.AUDITOR]: Record<string, string>;
  [UserRole.ADMIN]: Record<string, string>;
  [UserRole.SUPERADMIN]: Record<string, string>;
};

const teamMemberOptions = computed(
  () =>
    result.value?.getTeamUsers.reduce<TTeamMemberOptions>(
      (acc, teamMember) => {
        acc[teamMember.role][teamMember._id] =
          teamMember.firstName && teamMember.lastName
            ? `${teamMember.firstName} ${teamMember.lastName}`
            : `${teamMember.email}`;

        return acc;
      },
      {
        [UserRole.SUPERADMIN]: {},
        [UserRole.ADMIN]: {},
        [UserRole.AUDITOR]: {},
        [UserRole.MANAGER]: {},
        [UserRole.CONTRIBUTOR]: {},
        [UserRole.NONE]: {},
      },
    ) ?? ({} as TTeamMemberOptions),
);

const selectedAssignment = ref<UserRole>(UserRole.MANAGER);

watch(
  assignableRoles,
  () => {
    if (
      !assignableRoles.value.includes(selectedAssignment.value) &&
      assignableRoles.value[0]
    ) {
      // eslint-disable-next-line prefer-destructuring
      selectedAssignment.value = assignableRoles.value[0];
    }
  },
  { immediate: true },
);

const selectedSubcategoriesForm = ref<string[]>([]);

const availableSubcategories = computed(() => {
  return (
    subcategories.value
      .filter(
        (availableSubcategory) =>
          availableSubcategory.category === props.category,
      )
      .map((availableSubcategory) => availableSubcategory.subcategory) ?? []
  );
});

const ESRSTopics = computed(() => {
  const categoriesWithSubcategories = transformToCategorySubcategoryPairs(
    result.value?.getCategoriesWithSubcategories || [],
  );

  const groupedByTopic = categoriesWithSubcategories
    .filter(
      (availableSubcategory) =>
        availableSubcategory.category === props.category,
    )
    .reduce(
      (
        acc: {
          [topic: string]: {
            category: string;
            orderPosition: number;
            subcategory: string;
            topic: string;
          }[];
        },
        currentItem,
      ) => {
        const { topic } = currentItem;
        const existingGroup = acc[topic] || [];
        return { ...acc, [topic]: existingGroup.concat(currentItem) };
      },
      {},
    );

  return Object.keys(groupedByTopic).map((topic) => ({
    topic,
    subcategories: groupedByTopic[topic],
  }));
});

const assignedUsersCount = computed(() => {
  const roleToCount: Record<UserRole, number> = {
    [UserRole.SUPERADMIN]: 0,
    [UserRole.ADMIN]: 0,
    [UserRole.MANAGER]: 0,
    [UserRole.CONTRIBUTOR]: 0,
    [UserRole.AUDITOR]: 0,
    [UserRole.NONE]: 0,
  };

  teamMembers.value.forEach((user) => {
    if (
      user.assignments.subcategories.some(
        (subcategory) =>
          subcategory.location._id === props.project._id &&
          subcategoriesAssigned.value.includes(subcategory.subcategory),
      )
    ) {
      roleToCount[user.role] += 1;
    }
  });

  return roleToCount;
});

const selectedTopics = computed(() => {
  return result.value?.getOwnUser.entity.locations
    .find((l) => l._id === props.project._id)
    ?.assignments.categories.flatMap((c) =>
      c.subcategories.map((s) => s.subcategory),
    );
});

const numberOfSelectedTopics = ref<number>(selectedTopics.value?.length || -1);

const subcategoriesAssigned = computed(() => {
  if (!isAdminOrSuperAdmin.value) {
    return (
      result.value?.getOwnUser.assignments.subcategories
        .filter(
          ({ location, subcategory }) =>
            location._id === props.project._id &&
            availableSubcategories.value.includes(subcategory),
        )
        .map(({ subcategory }) => subcategory) ?? []
    );
  }

  return availableSubcategories.value.filter((availableSubcategory) =>
    result.value?.getOwnUser.entity.locations
      .find((l) => l._id === props.project._id)
      ?.assignments.categories.find(
        (categoryAssignment) => categoryAssignment.category === props.category,
      )
      ?.subcategories.find(
        (subcategoryAssignment) =>
          subcategoryAssignment.subcategory === availableSubcategory,
      ),
  );
});

watch(selectedTopics, (value) => {
  numberOfSelectedTopics.value = value?.length || -1;
});

watch(
  subcategoriesAssigned,
  () => {
    selectedSubcategoriesForm.value = [...subcategoriesAssigned.value];
  },
  { immediate: true },
);

const resolveRestrictionsForTopics = computed(
  () =>
    props?.numberOfAvailableTopics <= numberOfSelectedTopics.value &&
    props.numberOfAvailableTopics > 0,
);

function updateSubcategory(isChecked: boolean, subcategory: string) {
  if (resolveRestrictionsForTopics.value && isChecked) {
    showRestrictionsModal.value = true;
  }

  if (isChecked) {
    numberOfSelectedTopics.value += 1;
    selectedSubcategoriesForm.value.push(subcategory);
  } else {
    numberOfSelectedTopics.value -= 1;
    selectedSubcategoriesForm.value.splice(
      selectedSubcategoriesForm.value.findIndex((s) => s === subcategory),
      1,
    );
  }
}

const isAllChecked = computed(
  () =>
    availableSubcategories.value.length ===
      selectedSubcategoriesForm.value.length &&
    selectedSubcategoriesForm.value.length > 0,
);

function isAllCheckedForTopic(topic: string) {
  const subcategoriesForTopic = subcategories.value
    .filter(
      (subcategory) =>
        subcategory.topic === topic && subcategory.category === props.category,
    )
    .map((subcategory) => subcategory.subcategory);

  const selectedSubcategoriesForTopic = subcategoriesForTopic.filter(
    (subcategory) => selectedSubcategoriesForm.value.includes(subcategory),
  );

  return subcategoriesForTopic.length === selectedSubcategoriesForTopic.length;
}
const awaitingConfirmation = ref(false);

function toggleAllSubcategories(isChecked: boolean) {
  if (isChecked) {
    selectedSubcategoriesForm.value = [...availableSubcategories.value];
    numberOfSelectedTopics.value -= selectedSubcategoriesForm.value.length;
  } else {
    selectedSubcategoriesForm.value = [];
    numberOfSelectedTopics.value += selectedSubcategoriesForm.value.length;
  }
  if (resolveRestrictionsForTopics.value && isChecked) {
    showRestrictionsModal.value = true;
  }
}
function toggleAllSubcategoriesForTopic(isChecked: boolean, topic: string) {
  const subcategoriesForTopic = subcategories.value
    .filter(
      (subcategory) =>
        subcategory.topic === topic && subcategory.category === props.category,
    )
    .map((subcategory) => subcategory.subcategory);

  if (isChecked) {
    const newSubcategories = subcategoriesForTopic.filter(
      (subcategory) => !selectedSubcategoriesForm.value.includes(subcategory),
    );
    selectedSubcategoriesForm.value = [
      ...selectedSubcategoriesForm.value,
      ...newSubcategories,
    ];
    numberOfSelectedTopics.value += subcategoriesForTopic.length;
  } else {
    selectedSubcategoriesForm.value = selectedSubcategoriesForm.value.filter(
      (subcategory) => !subcategoriesForTopic.includes(subcategory),
    );
    numberOfSelectedTopics.value -= subcategoriesForTopic.length;
  }
  if (resolveRestrictionsForTopics.value && isChecked) {
    showRestrictionsModal.value = true;
  }
}

const { confirmViaDialog } = useConfirmViaDialog();

async function updateTopics() {
  const updateEntityAssignmentDto: UpdateEntityAssignmentInput = {
    category: props.category,
    subcategories: selectedSubcategoriesForm.value,
    isEnabled: true,
  };

  const subcategoriesAssignedPerCategory = subcategoriesAssigned.value;

  const hasUserRemovedSubcategories = !subcategoriesAssignedPerCategory
    .map((subcategory) => subcategory)
    .every((_subcategory) =>
      updateEntityAssignmentDto.subcategories?.includes(_subcategory),
    );

  const removedSubcategories = subcategoriesAssignedPerCategory.filter(
    (subcategory) =>
      !updateEntityAssignmentDto.subcategories?.includes(subcategory),
  );

  const removedSubcategoriesString = removedSubcategories
    .map((subcategory) => t(subcategory))
    .join(` ${t('and')} `);

  if (hasUserRemovedSubcategories) {
    awaitingConfirmation.value = true;
    const isConfirmed = await confirmViaDialog({
      title: t('Deselect Topic'),
      text: t(
        "You're about to deselect the topic(s) {removedSubcategoriesString}. All open question will be removed and assigned team members won't see them. Do you wish to continue?",
        { removedSubcategoriesString },
      ),
      confirmLabel: t('Save'),
      cancelLabel: t('Cancel'),
      confirmButtonVariant: 'destructive',
    });

    awaitingConfirmation.value = false;

    if (isConfirmed) {
      try {
        await updateEntityAssignment({
          locationId: props.project._id,
          updateEntityAssignmentInput: [updateEntityAssignmentDto],
        });
      } catch (e) {
        if (e instanceof ApolloError) {
          notify({
            type: 'error',
            text: t(e.message),
            duration: 10000,
          });
        }
      }
    }
  } else {
    await updateEntityAssignment({
      locationId: props.project._id,
      updateEntityAssignmentInput: [updateEntityAssignmentDto],
    });
  }
}

const reportingFramework = computed(
  () =>
    result.value?.getCategoriesWithSubcategories.map((c) => c.framework) ?? [],
);

const toggleAssignTopic = ({
  topic,
  role,
  locationId,
  category,
  userId,
  isActive,
}: {
  topic: string;
  role: UserRole;
  locationId: string;
  category: string;
  userId: string;
  isActive: boolean;
}) => {
  const existingTopics =
    topicAssignments.value?.filter(
      (existingTopic) =>
        existingTopic.role === role && existingTopic.category === category,
    ) || [];

  const isTopicExist = existingTopics.some(
    (existingTopic) =>
      existingTopic.locationId === locationId && existingTopic.topic === topic,
  );

  if (isTopicExist) {
    topicAssignments.value = existingTopics.filter(
      (existingTopic) =>
        !(
          existingTopic.locationId === locationId &&
          existingTopic.topic === topic
        ),
    );
  } else {
    const newTopic = { topic, role, locationId, category, userId, isActive };
    topicAssignments.value = [...existingTopics, newTopic];
  }
};

const topicAssignments = ref<
  {
    topic: string;
    role: UserRole;
    locationId: string;
    category: string;
    userId: string;
    isActive: boolean;
  }[]
>([]);

const topicAssigmentsOptions = (topic: string) => {
  return topicAssignments.value
    ?.filter((a) => a.topic === topic)
    .map((_a) => _a.userId);
};

watch(
  [
    () => result.value?.getTeamUsers,
    availableESRSTopicsForAssignment,
    () => props.project._id,
    () => props.category,
  ],
  ([newTeamUsers = [], newTopics, newProjectId, newCategory]) => {
    const userTopics: typeof topicAssignments.value = [];
    const subcategoryAssignments = newTeamUsers.flatMap((user) =>
      user.assignments.subcategories
        .filter((assignment) => assignment.location._id === newProjectId)
        .map((assignment) => ({
          subcategory: assignment.subcategory,
          role: assignment.role,
          locationId: assignment.location._id,
          userId: user._id,
        })),
    );
    newTopics.forEach((topic) => {
      const assignmentsPerSubcategory = topic.subcategories.map(
        (topicSubcategory) => {
          return subcategoryAssignments.filter((subcategoryAssignment) => {
            return (
              subcategoryAssignment.subcategory === topicSubcategory.subcategory
            );
          });
        },
      );
      if (assignmentsPerSubcategory.every((item) => item.length === 1)) {
        const users = new Set<string>();
        const roles = new Set<UserRole>();
        assignmentsPerSubcategory.forEach((item) => {
          users.add(item[0].userId);
          roles.add(item[0].role);
        });
        if (users.size === 1 && roles.size === 1) {
          userTopics.push({
            topic: topic.topic,
            role: Array.from(roles)[0],
            locationId: newProjectId,
            category: newCategory,
            userId: Array.from(users)[0],
            isActive: true,
          });
        }
      }
    });

    topicAssignments.value = userTopics;
  },
  { immediate: true },
);
</script>

<template>
  <MlDialog
    :isOpen="props.isShown && !awaitingConfirmation"
    :isTitleStyled="false"
    @close="emit('close')"
  >
    <template #title>
      <div class="py-2 px-6 text-gray-700">
        {{ t('Settings') }} - {{ translateCategory(props.category) }}
      </div>
    </template>

    <MlTabs
      :modelValue="activeTab"
      :tabs="actionPanelTabs"
      class="mb-1 ml-6"
      isEdgeToEdge
      @update:modelValue="activeTab = $event"
    />

    <div v-if="activeTab === TabName.TOPICS" class="px-6">
      <p class="mt-3 mb-5 text-sm text-gray-500">
        {{ t('Select relevant topics for which your team reports data.') }}
      </p>
      <template
        v-if="reportingFramework?.includes(ReportingFrameworkEnum.ESRS)"
      >
        <template
          v-for="(ESRSTopic, index) in ESRSTopics"
          :key="`ESRSTopic-${index}`"
        >
          <AtCheckbox
            v-if="
              ESRSTopic.subcategories.some(
                (s) => s.category !== 'carbon_accounting',
              )
            "
            :label="translateCategory(ESRSTopic.topic)"
            :checked="isAllCheckedForTopic(ESRSTopic.topic)"
            @toggleCheckbox="
              toggleAllSubcategoriesForTopic($event, ESRSTopic.topic)
            "
          />
          <AtCheckbox
            v-for="(subcategory, subindex) in ESRSTopic.subcategories"
            :key="`ESRSTopic-${index}-subcategory-${subindex}`"
            class="ml-6"
            :checked="
              selectedSubcategoriesForm.includes(subcategory.subcategory)
            "
            :label="
              translateCategory(subcategory.category, subcategory.subcategory)
            "
            @toggleCheckbox="updateSubcategory($event, subcategory.subcategory)"
          />
        </template>
      </template>
      <template v-else>
        <AtCheckbox
          :label="t('All')"
          data-cy="AtCheckboxAttTopics"
          :checked="isAllChecked"
          @toggleCheckbox="toggleAllSubcategories"
        />

        <AtCheckbox
          v-for="subcategory in availableSubcategories"
          :key="subcategory"
          class="ml-6"
          :checked="selectedSubcategoriesForm.includes(subcategory)"
          :label="translateCategory(category, subcategory)"
          @toggleCheckbox="updateSubcategory($event, subcategory)"
        />
      </template>
      <div
        v-if="
          props?.numberOfAvailableTopics >= numberOfSelectedTopics ||
          props.numberOfAvailableTopics < 0
        "
        class="mt-4 flex w-full justify-end"
      >
        <AtButton
          :loading="loadingUpdateEntityAssignment"
          data-cy="AtButtonUpdateTopics"
          @click.stop="updateTopics"
        >
          {{ t('Save') }}
        </AtButton>
      </div>
    </div>
    <div v-else>
      <div class="px-6">
        <p class="mt-3 mb-5 text-sm text-gray-500">
          <i18n-t
            keypath="Assign ownership to team members. You can add members to your team by clicking {teamSettings}."
          >
            <template #teamSettings>
              <span
                class="font-semibold mb-5 cursor-pointer hover:text-blue-600 hover:underline"
                @click="router.push({ name: 'settingsTeam' })"
                >{{ t('here') }}</span
              >
            </template>
          </i18n-t>
        </p>
        <div v-if="subcategoriesAssigned && hasTeamMembers">
          <AtProgress
            v-if="updateUserSubcategoryAssignmentsLoading"
            foreground
          />

          <MlTabs
            v-model="selectedAssignment"
            :tabs="assignableRoles"
            variant="boxed"
            :counterValues="assignedUsersCount"
          />
          <div
            v-if="reportingFramework?.includes(ReportingFrameworkEnum.ESRS)"
            class="flex flex-col justify-between text-gray-500"
          >
            <div
              v-for="(ESRSTopic, index) in availableESRSTopicsForAssignment"
              :key="`ESRSTopic-${index}`"
            >
              <AtCheckbox
                :label="`${`${translateCategory(ESRSTopic.topic)}: ${t('Assign all to one user')}`}`"
                :checked="
                  topicAssigments({
                    topic: ESRSTopic.topic,
                    role: UserRole[selectedAssignment],
                    locationId: props.project._id,
                    category: props.category,
                    isActive: true,
                  })
                "
                @toggleCheckbox="
                  toggleAssignTopic({
                    topic: ESRSTopic.topic,
                    role: UserRole[selectedAssignment],
                    locationId: props.project._id,
                    category: props.category,
                    userId:
                      topicAssignments?.find((a) => a.topic === ESRSTopic.topic)
                        ?.userId || '',
                    isActive: true,
                  })
                "
              />
              <template
                v-if="
                  topicAssigments({
                    topic: ESRSTopic.topic,
                    role: UserRole[selectedAssignment],
                    locationId: props.project._id,
                    category: props.category,
                    isActive: true,
                  })
                "
              >
                <div
                  class="grid grid-cols-2 border-b border-b-gray-200 px-6 py-2"
                >
                  <div />

                  <MlSelect
                    allowSelectNone
                    usePortal
                    multiple
                    :options="teamMemberOptions[selectedAssignment]"
                    :modelValue="topicAssigmentsOptions(ESRSTopic.topic)"
                    @update:modelValue="
                      assignUserByTopic(
                        ESRSTopic.topic,
                        UserRole[selectedAssignment],
                        $event,
                      )
                    "
                  />
                </div>
              </template>
              <template v-else>
                <div
                  v-for="subcategory in ESRSTopic.subcategories.filter((s) =>
                    subcategoriesAssigned.includes(s.subcategory),
                  )"
                  :key="subcategory.subcategory"
                  class="grid grid-cols-2 border-b border-b-gray-200 px-6 py-2"
                >
                  <div class="pt-2 flex flex-col items-end text-right pr-4">
                    <span>
                      {{
                        translateCategory(
                          subcategory.category,
                          subcategory.subcategory,
                        )
                      }}
                    </span>
                  </div>
                  <MlSelect
                    usePortal
                    multiple
                    :options="teamMemberOptions[selectedAssignment]"
                    :modelValue="
                      userSubcategoryAssignmentsMap[
                        UserRole[selectedAssignment]
                      ].get(subcategory.subcategory)?.newUsersIds
                    "
                    data-cy="MlSelectAssignment"
                    @update:modelValue="
                      updateUserSubcategoryAssignmentsMap(
                        subcategory.subcategory,
                        {
                          oldUsersIds:
                            userSubcategoryAssignmentsMap[
                              UserRole[selectedAssignment]
                            ].get(subcategory.subcategory)?.oldUsersIds ?? [],
                          newUsersIds: $event,
                          location: props.project._id,
                          role: UserRole[selectedAssignment],
                          subcategory: subcategory.subcategory,
                        },
                      )
                    "
                  />
                </div>
              </template>
            </div>
            <AtButton
              class="self-end mt-3"
              :loading="updateUserSubcategoryAssignmentsLoading"
              @click="() => applyUserSubcategoryAssigments()"
            >
              {{ t('Apply') }}
            </AtButton>
          </div>
          <div v-else class="flex flex-col justify-between text-gray-500">
            <div
              v-for="(subcategory, i) in subcategoriesAssigned"
              :key="subcategory || i"
              class="grid grid-cols-2 border-b border-b-gray-200 px-6 py-2"
            >
              <div
                class="flex items-center mr-5 md:truncate md:hover:overflow-visible md:hover:whitespace-normal"
              >
                {{ translateCategory(category, subcategory || '') }}
              </div>
              <div v-if="subcategory">
                <MlSelect
                  usePortal
                  multiple
                  :options="teamMemberOptions[selectedAssignment]"
                  :modelValue="
                    userSubcategoryAssignmentsMap[
                      UserRole[selectedAssignment]
                    ].get(subcategory)?.newUsersIds
                  "
                  data-cy="MlSelectAssignment"
                  @update:modelValue="
                    updateUserSubcategoryAssignmentsMap(subcategory, {
                      oldUsersIds:
                        userSubcategoryAssignmentsMap[
                          UserRole[selectedAssignment]
                        ].get(subcategory)?.oldUsersIds ?? [],
                      newUsersIds: $event,
                      location: props.project._id,
                      role: UserRole[selectedAssignment],
                      subcategory,
                    })
                  "
                />
              </div>
            </div>
            <AtButton
              class="self-end mt-3"
              :loading="updateUserSubcategoryAssignmentsLoading"
              @click="applyUserSubcategoryAssigments"
            >
              {{ t('Apply') }}
            </AtButton>
          </div>
        </div>
        <div
          v-else-if="!hasTeamMembers"
          class="flex flex-col items-center rounded bg-gray-50 p-10"
        >
          <h3 class="text-sm font-semibold">
            {{ t('No reporters yet') }}
          </h3>
          <p class="mt-1 text-center text-sm text-gray-500">
            <i18n-t keypath="Start by {invitingTeamMembers}">
              <template #invitingTeamMembers>
                <router-link
                  :to="{ name: 'settingsTeam' }"
                  variant="virgin"
                  class="underline hover:text-primary"
                >
                  {{ t('inviting team members') }}
                </router-link>
              </template>
            </i18n-t>
          </p>
        </div>
        <div
          v-else-if="!subcategoriesAssigned"
          class="flex flex-col items-center rounded bg-gray-50 p-10"
        >
          <h3 class="text-sm font-semibold">
            {{ t('No topics selected') }}
          </h3>
          <p
            v-rolefrom="UserRole.ADMIN"
            class="mt-1 text-center text-sm text-gray-500"
          >
            <i18n-t keypath="Start by {selectingTopics}">
              <template #selectingTopics>
                <AtButton
                  variant="link"
                  class="underline hover:text-primary"
                  @click.stop="activeTab = TabName.TOPICS"
                >
                  {{ t('selecting topics') }}
                </AtButton>
              </template>
            </i18n-t>
          </p>
        </div>
      </div>
    </div>
  </MlDialog>
  <OgRestrictionsModal
    teleportTo="body"
    :title="t('Topics limit reached')"
    :text="
      t(
        'You have reached the maximum number of topics in your current plan. Upgrade to to be able to add topics.',
      )
    "
    :isRevealed="showRestrictionsModal"
    @closeModal="showRestrictionsModal = false"
  />
</template>
