<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, watch, onMounted, computed, useTemplateRef } from 'vue';
import { SearchIcon } from '@heroicons/vue/outline';
import { useRoute, useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import { onClickOutside } from '@vueuse/core';
import AtCard from '@/components/atoms/AtCard.vue';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import {
  LibraryGuidanceDocumentEnum,
  RepositoryFileTypeEnum,
} from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtLoader from '@/components/atoms/AtLoader/AtLoader.vue';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import { useGenerateGuidanceAiSuggestion } from './composables/useGenerateGuidanceAiSuggestion';
import { useGuidances } from './composables/useGuidances';
import { useGuidanceDocumentDownload } from './composables/useGuidanceDocumentDownload';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const guidanceOpenedBase64Pdf = ref('');
const guidanceOpened = ref<(typeof guidances.value)[number]>();
const guidanceAiSuggestionSectionRef = ref<HTMLElement>();

const TmLibraryGuidanceViewRef = useTemplateRef<HTMLDivElement>(
  'TmLibraryGuidanceView',
);
onClickOutside(TmLibraryGuidanceViewRef, resetAiSearch);

/* TODO: find a better way than the watcher below.
 * Using guidanceAiSuggestionSectionRef.value?.innerHTML directly does not work.
 * Somehow the template does not listen on guidanceAiSuggestionSectionRef.value?.innerHTML
 * when it's directly used for conditional rendering.
 * Check shallow ref doc maybe
 */
const guidanceAiSuggestionSectionRefInnerHtml = ref('');
watch(guidanceAiSuggestionSectionRefInnerHtml, () => {
  if (guidanceAiSuggestionSectionRef.value) {
    guidanceAiSuggestionSectionRef.value.innerHTML =
      guidanceAiSuggestionSectionRefInnerHtml.value;
  }
});

const aiSearchTerm = ref('');
const aiGuidanceKeysFiltered = ref<LibraryGuidanceDocumentEnum[]>(
  Object.values(LibraryGuidanceDocumentEnum),
);
const {
  generateAiSuggestion,
  generateAiSuggestionLoading,
  generateAiSuggestionError,
} = useGenerateGuidanceAiSuggestion();

function resetAiSearch() {
  aiSearchTerm.value = '';
  aiGuidanceKeysFiltered.value = Object.values(LibraryGuidanceDocumentEnum);
}

const { guidances } = useGuidances();
const guidancesFiltered = computed(() =>
  guidances.value.filter((guidance) =>
    aiGuidanceKeysFiltered.value.includes(guidance.key),
  ),
);

watch(aiSearchTerm, () => {
  if (!aiSearchTerm.value) {
    guidanceAiSuggestionSectionRefInnerHtml.value = '';
    aiGuidanceKeysFiltered.value = Object.values(LibraryGuidanceDocumentEnum);
  }
});

watch(
  () => route.query.guidanceKey,
  (newGuidanceKey) => {
    if (newGuidanceKey?.toString()) {
      guidanceOpened.value = guidances.value.find(
        (guidance) => guidance.key.toString() === newGuidanceKey?.toString(),
      );
    } else {
      guidanceOpened.value = undefined;
    }
    if (!route.query.guidanceKey?.toString()) return;
    openGuidance(
      route.query.guidanceKey?.toString() as LibraryGuidanceDocumentEnum,
    );
  },
);

onMounted(() => {
  guidanceOpened.value = guidances.value.find(
    (guidance) =>
      guidance.key.toString() === route.query.guidanceKey?.toString(),
  );
  if (!route.query.guidanceKey?.toString()) return;
  openGuidance(
    route.query.guidanceKey?.toString() as LibraryGuidanceDocumentEnum,
  );
});

async function openGuidance(key: LibraryGuidanceDocumentEnum) {
  guidanceOpenedBase64Pdf.value = '';
  resetAiSearch();
  router.push({ name: 'libraryGuidances', query: { guidanceKey: key } });

  const { getFileData } = useGuidanceDocumentDownload(key);
  const fileData = await getFileData(RepositoryFileTypeEnum.PDF);
  guidanceOpenedBase64Pdf.value = fileData?.file ?? '';
}

async function handleGenerateGuidanceAiSuggestion() {
  const guidanceKeys = (
    route.query.guidanceKey?.toString()
      ? [route.query.guidanceKey?.toString()]
      : guidances.value.map((guidance) => guidance.key)
  ) as LibraryGuidanceDocumentEnum[];
  const aiQuery = aiSearchTerm.value;

  try {
    const result = await generateAiSuggestion(guidanceKeys, aiQuery);
    if (!guidanceAiSuggestionSectionRef.value) return;

    aiGuidanceKeysFiltered.value = result?.sourceGuidanceKeys ?? [];
    guidanceAiSuggestionSectionRefInnerHtml.value =
      result?.aiSuggestionHtml.replace(/†/g, ' ') ?? '';
  } catch (err) {
    if (generateAiSuggestionError.value)
      notify({
        type: 'error',
        text: t(generateAiSuggestionError.value.message),
      });
  }
}
</script>

<template>
  <div ref="TmLibraryGuidanceView">
    <AtProgress v-if="guidanceOpened && !guidanceOpenedBase64Pdf" foreground />

    <div class="grid grid-cols-1 w-full place-items-center">
      <div class="flex justify-center items-center my-3 w-full gap-x-3">
        <div v-if="guidanceOpened" class="flex w-full justify-end">
          <AtButton
            class="ml-auto"
            variant="outline"
            @click.stop="
              resetAiSearch();
              router.push({ name: 'libraryGuidances' });
            "
          >
            {{ t('Go back') }}
          </AtButton>
          <AtButton
            class="ml-2"
            :loading="guidanceOpened.download.loading.value"
            @click.stop="
              guidanceOpened.download.exportFile(RepositoryFileTypeEnum.PDF)
            "
          >
            {{ t('Download guidance') }}
          </AtButton>
        </div>
        <form
          v-else
          class="col-span-2 w-1/2 flex justify-between"
          @submit.prevent="handleGenerateGuidanceAiSuggestion"
        >
          <input
            v-model="aiSearchTerm"
            class="w-full outline-0 p-4 rounded-l-md border border-gray-200 border-r-0"
            :placeholder="
              guidanceOpened
                ? t('Search or ask a question about this file...')
                : t('Search or ask a question...')
            "
            type="text"
          />
          <button
            v-if="aiSearchTerm"
            class="cusor-pointer bg-blue-50 text-primary p-4 border border-primary rounded-r-md grid place-items-center"
            type="submit"
          >
            <AtLoader
              v-if="generateAiSuggestionLoading"
              class="bg-blue-50 absolute"
              size="sm"
            />
            <span :class="{ invisible: generateAiSuggestionLoading }">
              {{ t('SEARCH') }}
            </span>
          </button>
          <div v-else class="p-4 border border-gray-200 rounded-r-md">
            <SearchIcon class="w-5" />
          </div>
        </form>
      </div>
      <h3
        v-if="guidanceAiSuggestionSectionRefInnerHtml"
        class="self-start w-full font-bold mb-6 text-gray-900"
      >
        {{ t('Results summary:') }}
      </h3>
      <section
        ref="guidanceAiSuggestionSectionRef"
        class="p-12 w-full rounded mb-6 border border-primary bg-blue-50"
        :class="{ hidden: !guidanceAiSuggestionSectionRefInnerHtml }"
      />
      <div v-if="guidanceOpened && guidanceOpenedBase64Pdf" class="w-full flex">
        <iframe
          ref="iframeRef"
          :title="t(guidanceOpened.title)"
          class="w-full h-[75dvh]"
          :src="`data:application/pdf;base64,${guidanceOpenedBase64Pdf}`"
        />
      </div>
    </div>

    <h3
      v-if="!guidanceOpened && guidanceAiSuggestionSectionRefInnerHtml"
      class="self-start w-full font-bold my-6 text-gray-900"
    >
      {{ t('More on this:') }}
    </h3>
    <div
      v-if="!guidanceOpened"
      class="grid grid-cols-1 lg:grid-cols-2 gap-11 w-full"
    >
      <AtCard
        v-for="guidance in guidancesFiltered"
        :id="guidance.key"
        :key="guidance.key"
        :action="t('btn_open')"
        class="relative overflow-hidden"
        :title="guidance.title"
        @click="openGuidance(guidance.key)"
      >
        <div class="absolute top-6 right-6 mb-2">
          <AtBadge
            v-for="badge in guidance.badges"
            :key="badge.text"
            class="ml-2"
            :type="badge.type"
          >
            {{ badge.text }}
          </AtBadge>
        </div>
        <MlHtmlContent
          class="line-clamp-4 hover:line-clamp-none"
          :html="guidance.htmlDescription"
        />
      </AtCard>
    </div>
  </div>
</template>
