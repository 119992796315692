<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { DataPointTypeValueUnitEnum } from '@/__generated__/types';

const { t } = useI18n();

type TProps = {
  valueUnit: DataPointTypeValueUnitEnum;
  valueUnitDivisor?: DataPointTypeValueUnitEnum | null;
};

const props = defineProps<TProps>();
</script>
<template>
  <div>
    <span>
      {{ t(props.valueUnit) }}
    </span>
    <span
      v-if="
        props.valueUnitDivisor && props.valueUnit !== props.valueUnitDivisor
      "
    >
      / {{ t(props.valueUnitDivisor) }}
    </span>
  </div>
</template>
