<script setup lang="ts">
import { useLazyQuery, useQuery } from '@vue/apollo-composable';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import groupBy from 'lodash/groupBy';
import {
  UserRole,
  type OgCustomInsightFormQuery,
  type CreateInsightInput,
  type UpdateInsightInput,
  type CategoriesWithSubcategoriesQuery,
  DataPointTypeTargetActionEnum,
  DataPointTypeRefreshIntervalEnum,
  DataPointTypeValueUnitEnum,
  type OgCustomInsightFormDataPointTypesAQuery,
  // type OgCustomInsightFormDataPointTypesBQuery
} from '@/__generated__/types';
import useCreateInsightMutation from '@/api/mutations/DataPointType/createInsight.mutation';
import useUpdateInsightMutation from '@/api/mutations/DataPointType/updateInsight.mutation';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtSwitch from '@/components/atoms/AtSwitch/AtSwitch.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlSelectLevel3 from '@/components/molecules/MlSelectLevel3/MlSelectLevel3.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlInsight from '../MlInsight.vue';
import type { Crud, DataPointType } from '../types';
import OG_CUSTOM_INSIGHT_FORM_DATA_POINT_TYPES_A_QUERY from './OgCustomInsightFormDataPointTypesA.query';
// import OG_CUSTOM_INSIGHT_FORM_DATA_POINT_TYPES_B_QUERY from './OgCustomInsightFormDataPointTypesB.query';
import OG_CUSTOM_INSIGHT_FORM_QUERY from './OgCustomInsightForm.query';
import CATEGORIES_WITH_SUBCATEGORIES_QUERY from './OgCategoriesWithSubcategoires.query';

import type {
  CustomInsightFormData,
  CustomInsightEventPayload,
  DataPointTypeA,
  DataPointTypeB,
} from './types';

type Props = {
  state: Crud;
  insight?: DataPointType;
};
const props = defineProps<Props>();

const { t } = useI18n();
const translateCategory = useCategoryTranslate();

const { result: resultGetCategoriesWithSubcategories } =
  useQuery<CategoriesWithSubcategoriesQuery>(
    CATEGORIES_WITH_SUBCATEGORIES_QUERY,
  );

const { result: resultDataPointTypesA, refetch: refetchDataPointTypesA } =
  useQuery<OgCustomInsightFormDataPointTypesAQuery>(
    OG_CUSTOM_INSIGHT_FORM_DATA_POINT_TYPES_A_QUERY,
    { withExistingDataPoints: true },
    { fetchPolicy: 'network-only' },
  );

// const {
//   result: resultDataPointTypesB,
//   refetch: refetchDataPointTypesB,
// } = useQuery<OgCustomInsightFormDataPointTypesBQuery>(
//   OG_CUSTOM_INSIGHT_FORM_DATA_POINT_TYPES_B_QUERY, { withExistingDataPoints: true }, { fetchPolicy: 'network-only' },
// );

const {
  result: resultPreviewDataPoints,
  loading: loadingPreviewDataPoints,
  load: fetchPreviewDataPoints,
} = useLazyQuery<OgCustomInsightFormQuery>(OG_CUSTOM_INSIGHT_FORM_QUERY, null, {
  fetchPolicy: 'network-only',
});

const {
  mutate: createInsight,
  loading: createInsightLoading,
  error: createInsightError,
} = useCreateInsightMutation();
const {
  mutate: updateInsight,
  loading: updateInsightLoading,
  error: updateInsightError,
} = useUpdateInsightMutation();

onMounted(() => {
  refetchDataPointTypesA();
  // refetchDataPointTypesB();
});
const initialCustomInsightFormData: CustomInsightFormData = {
  title: '',
  refreshInterval: DataPointTypeRefreshIntervalEnum.NONE,
  dataPointTypeA: '',
  dataPointTypeB: '',
  targetAction: DataPointTypeTargetActionEnum.INCREASE,
  type: 'numeric',
};
const formData = ref({ ...initialCustomInsightFormData });

watch(
  () => resultDataPointTypesA.value,
  () => {
    reset();

    if (props.insight) {
      formData.value.title = props.insight.friendlyName;
      formData.value.dataPointTypeA = props.insight.formularDependencies[0]._id;
      formData.value.dataPointTypeB = props.insight.formularDependencies[1]._id;
      formData.value.refreshInterval = props.insight.refreshInterval;
      formData.value.targetAction = props.insight.targetAction ?? '';
      formData.value.type = props.insight.name.includes('__percent')
        ? 'percentage'
        : 'numeric';
    }
  },
);

const previewDataPointsFormatted = computed(() =>
  Object.values(
    groupBy(
      resultPreviewDataPoints.value?.previewInsight ?? [],
      (dp) => dp.location?._id,
    ),
  ).map((pdpGroup) =>
    pdpGroup.map((pdp) => ({
      ...pdp,
      dataPointType: {
        ...pdp.dataPointType,
        valueUnit:
          formData.value.type === 'numeric'
            ? selectedDataPointTypeA.value?.valueUnit
            : DataPointTypeValueUnitEnum.PERCENT,
        valueUnitDivisor:
          formData.value.type === 'numeric'
            ? selectedDataPointTypeB.value?.valueUnit
            : undefined,
        targetAction: formData.value
          .targetAction as DataPointTypeTargetActionEnum,
      },
    })),
  ),
);

const dataPointTypeOptionsA = computed(
  () =>
    resultDataPointTypesA.value?.getNumericDataPointTypes ??
    ([] as DataPointTypeA[]),
);
// const dataPointTypeOptionsB = computed(
//   () => resultDataPointTypesB.value?.getDenominatorNumericDataPointTypes ?? [] as DataPointTypeB[],
// );

watch(
  () => [
    formData.value.dataPointTypeA,
    formData.value.dataPointTypeB,
    formData.value.type,
  ],
  () => {
    if (!formData.value.dataPointTypeA || !formData.value.dataPointTypeB)
      return;

    fetchPreviewDataPoints(undefined, {
      previewInsightInput: {
        dataPointTypeAId: formData.value.dataPointTypeA,
        dataPointTypeBId: formData.value.dataPointTypeB,
        type: formData.value.type,
        targetAction: formData.value.targetAction,
      },
    });
  },
);

type Emits = {
  (e: 'update:isRevealed', isRevealed: boolean): void;
  (e: 'created', payload: CustomInsightEventPayload): void;
  (e: 'updated', payload: CustomInsightEventPayload): void;
};
const emit = defineEmits<Emits>();

const v$ = useVuelidate(
  {
    title: { required },
    refreshInterval: { required },
    dataPointTypeA: { required },
    dataPointTypeB: { required },
    targetAction: { required },
    type: { required },
  },
  formData,
);

function cancel() {
  reset();
  emit('update:isRevealed', false);
}

function reset() {
  formData.value = { ...initialCustomInsightFormData };
  v$.value.$reset();
}

const targetActionOptions = computed(() =>
  Object.keys(DataPointTypeTargetActionEnum).reduce(
    (acc, curr) => {
      return { ...acc, [curr]: t(curr) };
    },
    {} as Record<string, string>,
  ),
);

const isRepeating = ref(false);

async function handleCreateCustomInsight(repeat: boolean) {
  isRepeating.value = repeat;
  v$.value.$touch();

  if (v$.value.$error) return;

  const createInsightInput: CreateInsightInput = {
    title: formData.value.title,
    dataPointTypeAId: formData.value.dataPointTypeA,
    dataPointTypeBId: formData.value.dataPointTypeB,
    refreshInterval: selectedDataPointTypeA.value?.refreshInterval,
    targetAction: formData.value.targetAction as DataPointTypeTargetActionEnum,
    type: formData.value.type,
  };

  try {
    const customInsight = await createInsight({ createInsightInput });
    emit('created', {
      isRepeating: isRepeating.value,
      refreshIntervalSelected:
        customInsight?.data?.createInsight.refreshInterval,
    });
    notify({ type: 'success', text: t('Custom data point has been created.') });
    reset();
  } catch (err) {
    if (createInsightError)
      notify({
        type: 'error',
        text: t(`${createInsightError.value?.message}`),
      });
    else {
      notify({
        type: 'error',
        text: t('Something went wrong, try again later :(.'),
      });
    }
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

async function handleUpdateCustomInsight(repeat: boolean) {
  isRepeating.value = repeat;
  v$.value.$touch();

  if (v$.value.$error) return;

  const updateInsightInput: UpdateInsightInput = {
    _id: props.insight?._id,
    title: formData.value.title,
    dataPointTypeAId: formData.value.dataPointTypeA,
    dataPointTypeBId: formData.value.dataPointTypeB,
    refreshInterval: selectedDataPointTypeA.value?.refreshInterval,
    targetAction: formData.value.targetAction as DataPointTypeTargetActionEnum,
    type: formData.value.type,
  };

  try {
    const updatedInsight = await updateInsight({ updateInsightInput });
    emit('updated', {
      isRepeating: isRepeating.value,
      refreshIntervalSelected:
        updatedInsight?.data?.updateInsight.refreshInterval,
    });
    notify({ type: 'success', text: t('Insight has been updated.') });
    reset();
  } catch (err) {
    if (updateInsightError)
      notify({
        type: 'error',
        text: t(`${updateInsightError.value?.message}`),
      });
    else {
      notify({
        type: 'error',
        text: t('Something went wrong, try again later :(.'),
      });
    }
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

const selectedDataPointTypeA = computed(() =>
  dataPointTypeOptionsA.value.find(
    (dpt) => dpt._id === formData.value.dataPointTypeA,
  ),
);
const selectedDataPointTypeB = computed(() =>
  dataPointTypeOptionsA.value.find(
    (dpt) => dpt._id === formData.value.dataPointTypeB,
  ),
);

function createOptions(
  dataPointTypeOptions: DataPointTypeA[] | DataPointTypeB[],
) {
  return (
    resultGetCategoriesWithSubcategories.value
      ?.getCategoriesWithSubcategories || []
  )
    .map((category) => ({
      id: category.slug,
      title: translateCategory(category.slug),
      children: [...category.subcategories]
        .sort((a, b) => a.orderPosition - b.orderPosition)
        .map((subcategory) => ({
          id: subcategory.slug ?? '',
          title: translateCategory(category.slug, subcategory.slug) ?? '',
          children: dataPointTypeOptions
            .filter(
              (dpt) =>
                dpt.activeReportingFramework.category === category.slug &&
                dpt.activeReportingFramework.subcategory === subcategory.slug,
            )
            .map((dpt) => ({
              title: dpt.friendlyName,
              id: dpt._id,
            })),
        }))
        .filter((sub) => sub.children.length > 0),
    }))
    .filter((cat) => cat.children.length > 0);
}

const numeratorOptions = computed(() =>
  createOptions(dataPointTypeOptionsA.value),
);
const denominatorOptions = computed(() =>
  createOptions(dataPointTypeOptionsA.value),
);
</script>

<template>
  <div>
    <div class="rounded border p-3">
      <div class="grid grid-cols-12">
        <AtHeading
          type="h3"
          class="col-span-full mb-2 font-bold text-gray-900 lg:col-span-8"
        >
          {{ t('Insight') }}
        </AtHeading>
        <AtInput
          v-model="formData.title"
          wrapperClass="col-span-full lg:col-span-8"
          :label="t('Title')"
          :placeholder="t('Give your insight a short and comprehensive title.')"
        />
        <MlSelectLevel3
          v-model="formData.dataPointTypeA"
          wrapperClass="col-span-full lg:col-span-8 text-sm mt-2"
          :class="{
            'cursor-not-allowed': props.insight,
          }"
          :label="t('Numerator')"
          :placeholder="
            selectedDataPointTypeA?.friendlyName ?? t('Select a data point A')
          "
          :items="numeratorOptions"
          :emptyItemsMessage="
            t(
              'There is no data point with the same refresh interval as the denominator for this subcategory.',
            )
          "
          :disabled="props.state !== 'create'"
        />
        <div class="col-span-full mt-2 text-center text-sm lg:col-span-8">
          {{ t('per') }}
        </div>
        <MlSelectLevel3
          v-model="formData.dataPointTypeB"
          wrapperClass="col-span-full lg:col-span-8 text-sm"
          :class="{
            'cursor-not-allowed': props.insight,
          }"
          :label="t('Denominator')"
          :placeholder="
            selectedDataPointTypeB?.friendlyName ?? t('Select a data point B')
          "
          :items="denominatorOptions"
          :emptyItemsMessage="
            t(
              'There is no data point with the same refresh interval as the numerator for this subcategory.',
            )
          "
          :disabled="props.state !== 'create'"
        />
        <div class="col-span-full mt-2 flex flex-wrap lg:col-span-8">
          <MlSelect
            v-model="formData.targetAction"
            wrapperClass="w-60 mt-2 mr-auto"
            :options="targetActionOptions"
            label="Target action"
          />
          <AtSwitch
            v-model="formData.type"
            :optionA="'numeric'"
            :optionB="'percentage'"
            :disabled="
              loadingPreviewDataPoints ||
              createInsightLoading ||
              updateInsightLoading
            "
            wrapperClass="mt-2"
            :label="t('Number value')"
            :tooltip="
              t(
                'The numeric value shows you the result of A / B, whereas the percentage value shows the result (A / B * 100).',
              )
            "
            @update:modelValue="formData.type = $event as typeof formData.type"
          >
            <template #optionA>
              {{ t('Numeric') }}
            </template>
            <template #optionB>
              {{ t('Percentage') }}
            </template>
          </AtSwitch>
        </div>

        <div
          class="col-span-full mt-6 grid auto-cols-max grid-flow-col overflow-x-auto"
        >
          <template v-if="resultPreviewDataPoints">
            <MlInsight
              v-for="(previewDataPoints, i) in previewDataPointsFormatted"
              :key="previewDataPoints[0].location?._id"
              :dataPointsByProject="{
                project: previewDataPoints[0].location,
                dataPoints: previewDataPoints,
              }"
              :index="i"
              class="border-gray-200"
              :class="{
                'ml-9 border-l pl-9': !(i === 0),
              }"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="mt-20 flex justify-between">
      <AtButton variant="text" @click="cancel">
        {{ t('Cancel') }}
      </AtButton>

      <AtButton
        v-if="props.state === 'create'"
        v-rolefrom="UserRole.MANAGER"
        class="ml-auto"
        :disabled="v$.$error || v$.$invalid || createInsightLoading"
        :loading="createInsightLoading && !isRepeating"
        @click.stop="handleCreateCustomInsight(isRepeating)"
      >
        {{ props.state === 'create' ? t('Create') : t('Save') }}
      </AtButton>
      <AtButton
        v-else
        v-rolefrom="UserRole.MANAGER"
        class="ml-auto"
        :disabled="v$.$error || v$.$invalid || updateInsightLoading"
        :loading="updateInsightLoading && !isRepeating"
        @click.stop="handleUpdateCustomInsight(isRepeating)"
      >
        {{ t('Save') }}
      </AtButton>
    </div>
  </div>
</template>
