<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { PlusIcon } from '@heroicons/vue/solid';
import { UserRole } from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import type { Project } from '../types';
import { useDeleteProject } from '../common/composables';
import MlProjectsBreadcrumbs from '../common/MlProjectsBreadcrumbs.vue';
import MlProjectMenu from '../common/MlProjectMenu.vue';
import OgRenameProject from '../common/OgRenameProject.vue';

type Props = {
  activeProject: Project | null;
  allProjects: Project[];
  canAdd: boolean;
};

const props = defineProps<Props>();

defineEmits<{
  add: [];
}>();

const { t } = useI18n();

const deleteProject = useDeleteProject();

const isRenaming = ref(false);

const existingProjectsNames = computed(() =>
  props.allProjects.map((project) => project.name),
);

function handleRenameStart() {
  isRenaming.value = true;
}

function handleRenameClose() {
  isRenaming.value = false;
}

function handleCopy() {
  navigator.clipboard.writeText(window.location.href);
}

function handleDelete() {
  if (props.activeProject) {
    deleteProject(props.activeProject._id, props.activeProject.name);
  }
}
</script>

<template>
  <div class="pt-5 px-6 mb-6 flex justify-between gap-4">
    <div class="flex-0">
      <div class="mb-3 flex items-center space-x-6">
        <MlProjectsBreadcrumbs
          :activeProjectId="activeProject?._id ?? null"
          :allProjects="allProjects"
        >
          <template v-if="isRenaming">
            <OgRenameProject
              v-if="activeProject"
              :project="activeProject"
              :existingProjectsNames="existingProjectsNames"
              @close="handleRenameClose"
            />
          </template>
        </MlProjectsBreadcrumbs>

        <MlProjectMenu
          v-if="activeProject && !isRenaming"
          :canDelete="!activeProject.isHeadquarters"
          :canRenameSubcategory="false"
          @copy="handleCopy"
          @renameProject="handleRenameStart"
          @delete="handleDelete"
        />
      </div>

      <p class="text-sm text-gray-500">
        {{
          t(
            'Collect your sustainability data set in one place and collaborate with your team.',
          )
        }}
      </p>
    </div>
    <div class="flex-0">
      <AtButton
        v-rolefrom="UserRole.ADMIN"
        class="mb-2 ml-auto"
        :icon="PlusIcon"
        :disabled="canAdd"
        data-cy="createProjectButton"
        @click="$emit('add')"
      >
        {{ t('Add') }}
      </AtButton>
    </div>
  </div>
</template>
