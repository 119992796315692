import { computed, reactive, inject } from 'vue';
import merge from 'lodash/merge';
import type { PartialDeep } from 'type-fest';
import type { ChartData, ChartOptions } from 'chart.js';
import type { AnnotationPluginOptions } from 'chartjs-plugin-annotation';
import dayjs from '@/lib/dayjs/config';
import { i18n } from '@/lib/i18n';
import {
  type DataPoint,
  type DataPointType,
  type EntityKpi,
  ComparisonOperatorEnum,
  ComparisonValueTypeEnum,
  type DataPointTypeReportingFramework,
  ValueDataTypeEnum,
} from '@/__generated__/types';
import {
  resetTime,
  useReportingPeriod,
} from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import { getDisplayedFrameworks } from '@/utils/helpers/getDisplayedFrameworks';
import generateTotals from '@/utils/helpers/charts/generateTotals';
import { chartColors, colors as themeColors } from '@/styles/theme';
import usePinChartAdmin from './usePinChartAdmin';
import usePinChart from './usePinChart';
import type { ColorMap } from './types';

// TODO: move pin/unpin logic here

const totalsColors = [themeColors.rose['400'], themeColors.rose['600']];

const assignedColors: ColorMap[] = reactive([]);

const assignColor = (name: string) => {
  if (!assignedColors.find((item) => item.name === name)) {
    assignedColors.push({
      name,
      color: chartColors[assignedColors.length % chartColors.length],
    });
  }
};

const useChartProps = (props?: { adminType?: 'dashboard' | 'overview' }) => {
  const adminMode = inject(
    'adminMode',
    computed(() => false),
  );

  const { pinnedChartIDs, togglePinChart } = adminMode.value
    ? usePinChartAdmin({ type: props?.adminType })
    : usePinChart();

  const { dateRange: chartDateRange } = useReportingPeriod();

  const statefulChartOptions = computed<ChartOptions<'line'>>(() => ({
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            millisecond: 'MMM YYYY',
            second: 'MMM YYYY',
            minute: 'MMM YYYY',
            hour: 'MMM YYYY',
            day: 'MMM YYYY',
            week: 'MMM YYYY',
            month: 'MMM YYYY',
            quarter: 'MMM YYYY',
            year: 'YYYY',
          },
        },
        ticks: {
          source: 'data',
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          title(context) {
            return context.map((item) =>
              dayjs((item.raw as unknown as { x: number; y: number }).x).format(
                'D MMM YYYY',
              ),
            );
          },
        },
      },
    },
  }));

  const getChartProps = (
    dataPoints: (PartialDeep<
      Pick<
        DataPoint,
        | 'from'
        | 'to'
        | 'value'
        | 'displayValue'
        | 'displayValueUnit'
        | 'location'
        | 'entity'
        | 'dataPointType'
      >,
      { recurseIntoArrays: true }
    > & { isTotal?: boolean })[],
    dataPointType: PartialDeep<DataPointType, { recurseIntoArrays: true }>,
    kpis: Pick<
      EntityKpi,
      | 'comparisonValue'
      | 'startingDate'
      | 'dueDate'
      | 'comparisonValueType'
      | 'comparisonOperator'
      | 'startingValue'
    >[] = [],
    shouldGenerateTotals = true,
  ) => {
    dataPoints.forEach((dp) => assignColor(dp.location?.name ?? ''));

    const min = chartDateRange.value.from.clone();
    const max = chartDateRange.value.to.add(1, 'day');

    const startingValueDatapoints = [];
    const firstKpi = kpis.find((k) => k.startingValue !== null);
    if (
      firstKpi &&
      !dataPoints.find(
        (dp) =>
          dp.from &&
          firstKpi.startingDate &&
          resetTime(dayjs(dp.from)).isSame(
            resetTime(dayjs(firstKpi.startingDate)),
            'day',
          ),
      ) &&
      dataPoints.filter(
        ({ to, from }) =>
          resetTime(dayjs(to.replace('Z', ''))).isBetween(
            min,
            max,
            'day',
            '(]',
          ) || to === from,
      ).length <= 1
    ) {
      startingValueDatapoints.push({
        value: firstKpi.startingValue,
        from: resetTime(dayjs(firstKpi.startingDate))
          .add(1, 'day')
          .toISOString()
          .replace('Z', ''),
        to: resetTime(dayjs(firstKpi.startingDate))
          .add(1, 'day')
          .toISOString()
          .replace('Z', ''),
        location: {
          name: dataPoints[0]?.location?.name ?? '',
        },
      });
    }

    const sortedDataPoints = [...dataPoints, ...startingValueDatapoints].sort(
      (a, b) => (resetTime(dayjs(a.to)).isAfter(dayjs(b.to)) ? 1 : -1),
    );

    const dataPointsWithinDateRange = sortedDataPoints.filter(
      ({ to, from }) =>
        resetTime(dayjs(to.replace('Z', ''))).isBetween(
          min,
          max,
          'day',
          '(]',
        ) || to === from,
    );

    const dpSeparatedByLocation = dataPointsWithinDateRange.reduce<
      Record<string, typeof dataPoints>
    >((acc, dp) => {
      const entityOrLocationName = adminMode.value
        ? (dp.entity?.name ?? '')
        : (dp.location?.name ?? '');
      acc[entityOrLocationName] ??= [];
      acc[entityOrLocationName].push(dp);

      return acc;
    }, {});

    if (
      shouldGenerateTotals &&
      Object.keys(dpSeparatedByLocation).length > 1 &&
      dataPointType.valueDataType === ValueDataTypeEnum.NUMERIC
    ) {
      dpSeparatedByLocation.totals = generateTotals(dataPointsWithinDateRange);
    }

    const chartData: ChartData<'line'> = {
      datasets: Object.values(dpSeparatedByLocation).map(
        (locationDataPoints) => {
          const name = adminMode.value
            ? (locationDataPoints[0].entity?.name ?? '')
            : locationDataPoints[0].location?.name;
          const { isTotal } = locationDataPoints[0];

          const colorMap = assignedColors.find((item) => item.name === name);

          return {
            label: isTotal ? 'Total' : name,
            data: locationDataPoints.map((dp) => ({
              x: dayjs(dp.to.replace('Z', '')).subtract(1, 'day').valueOf(),
              y: dp.displayValue ?? dp.value,
            })),
            backgroundColor: isTotal ? totalsColors[0] : colorMap?.color[0],
            borderColor: isTotal ? totalsColors[1] : colorMap?.color[1],
            pointRadius: 4,
            pointHoverRadius: 8,
          };
        },
      ),
    };

    const kpiAnnotations = kpis.map((kpi, i) => {
      const dpSourceLocation = dpSeparatedByLocation.totals?.length
        ? dpSeparatedByLocation.totals
        : sortedDataPoints;
      const closestDataPointToKpiFrom = dpSourceLocation
        .sort((a, b) => {
          return (
            Math.abs(dayjs(kpi.startingDate).diff(a.to)) -
            Math.abs(dayjs(kpi.startingDate).diff(b.to))
          );
        })
        .at(0);

      const closestValue = Number(closestDataPointToKpiFrom?.value ?? 0);

      const getMax = () => {
        if (kpi.comparisonValueType === ComparisonValueTypeEnum.YES_NO)
          return 1;

        const numericValue = kpi.comparisonValue;
        const percentValue = (closestValue / 100) * kpi.comparisonValue;

        const value =
          kpi.comparisonValueType === ComparisonValueTypeEnum.NUMBER
            ? numericValue
            : percentValue;

        switch (kpi.comparisonOperator) {
          case ComparisonOperatorEnum.LT:
            return closestValue - value;
          case ComparisonOperatorEnum.GT:
            return closestValue + value;
          case ComparisonOperatorEnum.EQ:
          default:
            return value;
        }
      };

      let formattedGoal = '';

      switch (kpi.comparisonOperator) {
        case ComparisonOperatorEnum.LT:
          formattedGoal += '-';
          break;
        case ComparisonOperatorEnum.GT:
          formattedGoal += '+';
          break;
        case ComparisonOperatorEnum.EQ:
        default:
          formattedGoal += '==';
      }
      formattedGoal +=
        kpi.comparisonValueType === ComparisonValueTypeEnum.YES_NO
          ? 'Yes'
          : `${kpi.comparisonValue}`;
      if (kpi.comparisonValueType === ComparisonValueTypeEnum.PERCENT)
        formattedGoal += '%';

      return {
        drawTime: 'beforeDraw',
        label: {
          display: !!chartData.datasets.length,
          backgroundColor:
            chartColors[(chartColors.length - 1 - i) % chartColors.length][1],
          borderRadius: 4,
          color: 'white',
          content: `${i18n.global.t('Goal')}: ${formattedGoal}`,
          font: { size: 10 },
          position: 'end',
          yAdjust: (i + 1) * 30,
        },
        type: 'line',
        borderDash: [5],
        yMin: closestValue,
        yMax: getMax(),
        xMin: closestDataPointToKpiFrom?.to,
        xMax: kpi.dueDate,
        borderColor:
          chartColors[(chartColors.length - 1 - i) % chartColors.length][1],
        borderWidth: 3,
      };
    });

    let yAxisText = i18n.global.t(
      (dataPointsWithinDateRange[0]?.displayValueUnit ??
        dataPointType?.valueUnit) ||
        '',
    );

    if (dataPointType.valueUnitDivisor) {
      yAxisText += ` / ${i18n.global.t(dataPointType?.valueUnitDivisor || '')}`;
    }

    const chartOptions: ChartOptions<'line'> = {
      plugins: {
        legend: {
          display: true,
          labels: {
            pointStyle: 'rectRounded',
            usePointStyle: true,
          },
        },
        annotation: {
          annotations: kpiAnnotations,
        } as AnnotationPluginOptions,
      },
      scales: {
        y: {
          title: {
            display: !!dataPointType?.valueUnit,
            text: yAxisText,
          },
        },
      },
    };

    const events = {
      'onUpdate:pinned': () => togglePinChart(dataPointType._id ?? ''),
    };

    return {
      ...events,
      chartData,
      chartOptions: { ...merge(chartOptions, statefulChartOptions.value) },
      dataPointTypeName: dataPointType?.name || '',
      type: (dataPointType?.defaultChartType as 'line') || 'line', // TODO: remove casting once chart type is changed to enum
      title: dataPointType?.friendlyName || '',
      pinned: pinnedChartIDs.value.includes(dataPointType._id ?? ''),
      badges:
        ((dataPoints.length
          ? getDisplayedFrameworks(
              dataPoints[0].dataPointType
                ?.activeReportingFramework as Partial<DataPointTypeReportingFramework>,
              dataPoints[0].dataPointType
                ?.reportingFrameworks as Partial<DataPointTypeReportingFramework>[],
            )
          : dataPointType.reportingFrameworks
        )?.map(
          (_rf) =>
            `${i18n.global.t(_rf?.framework ?? '')}${`${_rf?.groups?.length}` ? `-${_rf?.groups?.join(',')}` : ''}`,
        ) as string[]) || [],
    };
  };

  return { chartDateRange, getChartProps };
};

export default useChartProps;
