import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  CreateDataPointRequestChatCommentMutation,
  CreateDataPointRequestChatCommentMutationVariables,
} from '@/__generated__/types';

export const CREATE_DATAPOINTREQUEST_CHAT_COMMENT = gql`
  mutation CreateDataPointRequestChatComment(
    $createDataPointRequestChatCommentInput: CreateDataPointRequestChatCommentInput!
  ) {
    createDataPointRequestChatComment(
      createDataPointRequestChatCommentInput: $createDataPointRequestChatCommentInput
    ) {
      _id

      chatComments {
        _id
        text
        updatedAt
        user {
          _id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  CreateDataPointRequestChatCommentMutation,
  CreateDataPointRequestChatCommentMutationVariables
>;

const useCreateDataPointRequestChatCommentMutation = (options?: TOptions) =>
  useMutation<
    CreateDataPointRequestChatCommentMutation,
    CreateDataPointRequestChatCommentMutationVariables
  >(CREATE_DATAPOINTREQUEST_CHAT_COMMENT, options);

export default useCreateDataPointRequestChatCommentMutation;
