<script setup lang="ts">
import { useMounted } from '@vueuse/core';

type TProps = {
  isShown: boolean;
};
const isMounted = useMounted();

const props = withDefaults(defineProps<TProps>(), {
  isShown: false,
});

const emit = defineEmits(['handleClick']);

defineOptions({ inheritAttrs: false });
</script>

<template>
  <Teleport v-if="isMounted" to="#topbar">
    <Transition>
      <div
        v-if="props.isShown"
        class="flex w-full justify-center p-2"
        data-cy="AtTopBar"
        v-bind="$attrs"
        @click.stop="emit('handleClick')"
      >
        <slot />
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="postcss" scoped>
.v-enter-active {
  transition: transform 200ms;
}

.v-enter-from {
  transform: translateY(-20px);
}
</style>
