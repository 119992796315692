<script setup lang="ts">
import { computed } from 'vue';
import {
  DataPointRequestStatusEnum,
  EntityKpiStatusEnum,
  TagColorEnum,
  RecommendationTagEnum,
} from '@/__generated__/types';
import { BadgeType, BadgeSize } from './types';

const { type, size } = defineProps<{
  type?: `${BadgeType | DataPointRequestStatusEnum | EntityKpiStatusEnum | TagColorEnum | RecommendationTagEnum}`;
  size?: `${BadgeSize}`;
}>();

const classObjectForType = computed(() => {
  switch (type) {
    case TagColorEnum.AMBER:
      return 'bg-amber-50 text-amber-500';
    case TagColorEnum.STONE:
      return 'bg-stone-50 text-stone-400';
    case TagColorEnum.INDIGO:
      return 'bg-indigo-50 text-indigo-500';
    case TagColorEnum.YELLOW:
    case DataPointRequestStatusEnum.DRAFT:
      return 'bg-yellow-50 text-yellow-700';
    case BadgeType.SUCCESS:
    case DataPointRequestStatusEnum.CLOSED:
    case DataPointRequestStatusEnum.ACCEPTED:
    case EntityKpiStatusEnum.ACHIEVED:
    case TagColorEnum.TEAL:
    case RecommendationTagEnum.COMPANY_SPECIFIC:
      return 'bg-teal-50 text-teal-600';
    case BadgeType.WARNING:
    case DataPointRequestStatusEnum.PENDING:
    case EntityKpiStatusEnum.ONGOING:
    case TagColorEnum.ORANGE:
    case RecommendationTagEnum.TEAM_ANSWER:
      return 'bg-orange-50 text-orange-400';
    case DataPointRequestStatusEnum.OVERDUE:
    case TagColorEnum.VIOLET:
      return 'bg-violet-50 text-violet-500';
    case BadgeType.ERROR:
    case DataPointRequestStatusEnum.REJECTED:
    case EntityKpiStatusEnum.MISSED:
    case TagColorEnum.ROSE:
    case BadgeType.DOWN:
      return 'bg-rose-50 text-rose-400';
    case BadgeType.NEUTRAL:
      return 'bg-gray-50 text-inactive-text text-gray-400';
    case BadgeType.CLEAR:
      return 'bg-white text-inactive-text';
    case BadgeType.UP:
    case TagColorEnum.EMERALD:
      return 'bg-emerald-50 text-emerald-500';
    case BadgeType.RIGHT:
      return 'bg-gray-50 text-gray-400';
    default:
      return 'bg-blue-50 text-blue-500';
  }
});

const classObjectForSize = computed(() => {
  switch (size) {
    case BadgeSize.MD:
      return 'py-2 px-5 text-lg rounded-lg';
    case BadgeSize.SM:
      return 'py-1 px-5 text-sm rounded-md';
    default:
      return 'py-0.5 px-2.5 text-xs rounded';
  }
});
</script>

<template>
  <span
    :class="[classObjectForType, classObjectForSize]"
    class="inline-flex items-center justify-center font-semibold"
    :data-cy="`AtBadge-${type}`"
  >
    <slot />
  </span>
</template>
