<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { notify } from '@kyvg/vue3-notification';
import type { OgCreateQuestionnaireQuery } from '@/__generated__/types';
import useCreateCustomQuestionnaireMutation from '@/api/mutations/CustomQuestionnaire/createQuestionnaire.mutation';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import { CheckboxSize } from '@/components/atoms/AtCheckbox/types';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import OgRestrictionsModal from '@/components/organisms/OgRestrictionsModal/OgRestrictionsModal.vue';
import OG_CREATE_QUESTIONNAIRE_QUERY from './OgCreateQuestionnaire.query';

export type Values = {
  name: string;
  category: string | null;
};

type QuestionnaireNameForm = {
  name: string;
  shouldNest: boolean;
  category: string;
};

const props = defineProps<{
  projectId: string;
}>();

const emit = defineEmits<{
  cancel: [];
}>();

const { t } = useI18n();
const router = useRouter();
const { restrictions } = useRestrictions();

const showRestrictionsModal = ref(false);

const { result, loading: isLoading } = useQuery<OgCreateQuestionnaireQuery>(
  OG_CREATE_QUESTIONNAIRE_QUERY,
);

const { mutate: createQuestionnaire, loading: isSaving } =
  useCreateCustomQuestionnaireMutation({
    update: (store) => {
      store.evict({
        id: 'ROOT_QUERY',
        fieldName: 'getAllCategoriesForEntity',
      });
    },
  });

const categories = computed(
  () => result.value?.getCustomCategoriesWithSubcategoriesForEntity ?? [],
);

watch(
  [
    () => restrictions.value?.numberOfQuestionnaires ?? -1,
    () => result.value?.getOrganizationQuestionnairesCount ?? 0,
  ],
  ([
    newNumberOfQuestionnairesRestriction,
    newOrganizationQuestionnaireCount,
  ]) => {
    if (
      newNumberOfQuestionnairesRestriction > -1 &&
      newOrganizationQuestionnaireCount >= newNumberOfQuestionnairesRestriction
    ) {
      showRestrictionsModal.value = true;
    }
  },
  { immediate: true },
);

const form = reactive<QuestionnaireNameForm>({
  name: '',
  shouldNest: false,
  category: '',
});

const validationRules = computed(() => ({
  name: {
    required: helpers.withMessage(
      t('Questionnaire name is required.'),
      required,
    ),
  },
  category: {
    requiredIf: helpers.withMessage(
      t('Category is required.'),
      requiredIf(() => form.shouldNest),
    ),
  },
}));
const v$ = useVuelidate(validationRules, form);

const isDisabled = computed(() => isLoading.value || isSaving.value);

const categoriesOptions = computed<Record<string, string>>(() => {
  return categories.value.reduce(
    (options, category) => ({
      ...options,
      [category._id]: category.name,
    }),
    {},
  );
});

function handleCancel() {
  emit('cancel');
}

async function handleSubmit() {
  try {
    const createdQuestionnaire = await createQuestionnaire({
      customQuestionnaireData: {
        questionnaireName: form.name!,
        category: form.shouldNest ? form.category : null,
        entityLocationId: props.projectId,
      },
    });
    router.push({
      name: 'projectsCustomQuestionnaireUpload',
      params: {
        customQuestionnaireId:
          createdQuestionnaire?.data?.createCustomQuestionnaire._id,
      },
    });
  } catch (error) {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
}
</script>

<template>
  <MlDialog
    :isOpen="!showRestrictionsModal"
    :closeOnOutside="false"
    @close="handleCancel"
  >
    <template #title>
      {{ t('Give this questionnaire a name') }}
    </template>

    <template #default="{ close }">
      <form class="pt-6" @submit.prevent="handleSubmit">
        <div class="mb-6">
          <AtInput
            v-model.trim="form.name"
            wrapperClass="w-96 mb-4"
            :label="t('Name')"
            :errors="v$.name.$errors"
            @blur="v$.name.$touch"
          />
          <template v-if="categories.length > 0">
            <div class="flex items-center space-x-2 mb-1">
              <AtCheckbox
                squared
                :checked="form.shouldNest"
                :size="CheckboxSize.XS"
                @toggleCheckbox="form.shouldNest = !form.shouldNest"
              >
                <template #label>
                  <p class="text-sm font-medium text-gray-700">
                    {{ t('Nest this questionnaire under:') }}
                  </p>
                </template>
              </AtCheckbox>
            </div>
            <MlSelect
              v-model="form.category"
              usePortal
              :disabled="!form.shouldNest"
              :options="categoriesOptions"
              :errors="v$.category.$errors"
              @blur="v$.category.$touch"
            />
          </template>
        </div>
        <div class="flex space-x-4 justify-between items-center">
          <div class="flex-0">
            <AtButton type="button" variant="outline" @click="close">
              {{ t('Cancel') }}
            </AtButton>
          </div>
          <div class="flex-0">
            <AtButton type="submit" :disabled="v$.$invalid || isDisabled">
              {{ t('Next') }}
            </AtButton>
          </div>
        </div>
      </form>
    </template>
  </MlDialog>
  <OgRestrictionsModal
    teleportTo="body"
    :title="t('Custom categories limit reached')"
    :text="
      t(
        'You have reached the maximum number of custom questionnaires in your current plan. Upgrade to to be able to add topics.',
      )
    "
    :isRevealed="showRestrictionsModal"
    @closeModal="handleCancel"
  />
</template>
