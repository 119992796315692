<script setup lang="ts">
import { computed, type Component, type HTMLAttributes } from 'vue';
import {
  type AsTag,
  SplitterResizeHandle,
  useForwardPropsEmits,
} from 'radix-vue';
import { DragHandleDots2Icon } from '@radix-icons/vue';
import type { PointerHitAreaMargins } from 'radix-vue/dist/Splitter/utils/registry';
import { cn } from '@/components/ui/utils';

/* vue compiler sfc error: https://github.com/radix-vue/shadcn-vue/issues/207
 * installing TS last version does not sovle the issue: https://github.com/radix-vue/shadcn-vue/issues/207#issuecomment-1912039593
 * Copy-paste each prop and emit manually instead.
 */
// const props = defineProps<SplitterResizeHandleProps & { class?: HTMLAttributes['class'], withHandle?: boolean }> {
const props = defineProps<{
  asChild?: boolean;
  as?: AsTag | Component;

  id?: string;
  /** Allow this much margin when determining resizable handle hit detection */
  hitAreaMargins?: PointerHitAreaMargins;
  /** Tabindex for the handle */
  tabindex?: number;
  /** Disable drag handle */
  disabled?: boolean;
  class?: HTMLAttributes['class'];
  withHandle?: boolean;
}>();

// const emits = defineEmits<SplitterResizeHandleEmits>{
const emits = defineEmits<{
  /** Event handler called when dragging the handler. */
  dragging: [isDragging: boolean];
}>();

const delegatedProps = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { class: _, ...delegated } = props;
  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <SplitterResizeHandle
    v-bind="forwarded"
    :class="
      cn(
        'relative flex w-px items-center justify-center bg-border after:absolute after:inset-y-0 after:left-1/2 after:w-1 after:-translate-x-1/2 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 [&[data-orientation=vertical]]:h-px [&[data-orientation=vertical]]:w-full [&[data-orientation=vertical]]:after:left-0 [&[data-orientation=vertical]]:after:h-1 [&[data-orientation=vertical]]:after:w-full [&[data-orientation=vertical]]:after:-translate-y-1/2 [&[data-orientation=vertical]]:after:translate-x-0 [&[data-orientation=vertical]>div]:rotate-90',
        props.class,
      )
    "
  >
    <template v-if="props.withHandle">
      <div
        class="z-10 flex h-4 w-3 items-center justify-center rounded-sm border bg-border"
      >
        <DragHandleDots2Icon class="h-2.5 w-2.5" />
      </div>
    </template>
  </SplitterResizeHandle>
</template>
