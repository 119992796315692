import { gql } from '@apollo/client/core';

export default gql`
  query TmInsightsView($adminMode: Boolean!) {
    getOwnUser {
      _id
      role
      email
    }

    entityLocationSummaries @skip(if: $adminMode) {
      _id
      name
    }

    getCurrentUserEntities @include(if: $adminMode) {
      _id
      name
    }

    getInsightDataPointTypes @skip(if: $adminMode) {
      _id
      ...insightDataPointTypes
    }

    getInsightDataPoints @skip(if: $adminMode) {
      _id
      value
      from
      to
      location {
        _id
        name
      }
      entity {
        _id
        name
      }
      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        formular
        targetAction
        formularDependencies {
          _id
          name
          friendlyName
        }
        valueUnitDivisor
        valueDataType
        refreshInterval
        activeReportingFramework {
          _id
          framework
          groups
          subcategory
        }
        reportingFrameworks {
          _id
          framework
          groups
          subcategory
        }
      }
    }

    getInsightDataPointTypesAdmin @include(if: $adminMode) {
      _id
      ...insightDataPointTypes
    }

    getInsightDataPointsAdmin @include(if: $adminMode) {
      value
      from
      to
      location {
        _id
        name
      }
      entity {
        _id
        name
      }
      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        formular
        targetAction
        formularDependencies {
          _id
          name
          friendlyName
        }
        valueUnitDivisor
        valueDataType
        refreshInterval
        activeReportingFramework {
          _id
          framework
          groups
          subcategory
        }
        reportingFrameworks {
          _id
          framework
          groups
          subcategory
        }
      }
    }
  }

  fragment insightDataPointTypes on DataPointType {
    _id
    name
    friendlyName
    formular
    refreshInterval
    targetAction
    formularDependencies {
      _id
      name
      friendlyName
    }
    reportingFrameworks {
      _id
      framework
      groups
      subcategory
    }
  }
`;
