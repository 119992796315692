<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import {
  CustomQuestionnaireStatus,
  type PgCustomQuestionnaireQuery,
  type PgCustomQuestionnaireQueryVariables,
} from '@/__generated__/types';
import OgCustomQuestionnaireHeader from './OgCustomQuestionnaireHeader/OgCustomQuestionnaireHeader.vue';
import PG_CUSTOM_QUESTIONNAIRE_QUERY from './PgCustomQuestionnaire.query';

type Props = {
  customQuestionnaireId?: string;
};

const props = defineProps<Props>();

const router = useRouter();

const timeoutId = ref<ReturnType<(typeof window)['setTimeout']> | null>(null);

const queryVariables = computed<PgCustomQuestionnaireQueryVariables>(() => ({
  id: props.customQuestionnaireId!,
}));
const { result, loading, refetch, onResult } = useQuery<
  PgCustomQuestionnaireQuery,
  PgCustomQuestionnaireQueryVariables
>(PG_CUSTOM_QUESTIONNAIRE_QUERY, queryVariables);

const customQuestionnaire = computed(
  () => result.value?.getCustomQuestionnaire ?? null,
);

watch(
  [loading, customQuestionnaire],
  ([newLoadingValue, newCustomQuestionnaireValue]) => {
    // Redirect to projects page if custom questionnaire doesn't exist.
    if (!newLoadingValue && !newCustomQuestionnaireValue) {
      router.push({
        name: 'projects',
      });
    }
  },
  { immediate: true },
);

function refreshData(status: CustomQuestionnaireStatus | null) {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value);
    timeoutId.value = null;
  }

  if (status === CustomQuestionnaireStatus.AI_PROCESSING) {
    timeoutId.value = setTimeout(() => {
      refetch();
    }, 15000); // 15 seconds
  }
}
onResult((queryResult) => {
  const status = queryResult.data?.getCustomQuestionnaire?.status ?? null;
  refreshData(status);
});
onBeforeUnmount(() => {
  if (timeoutId.value) {
    clearTimeout(timeoutId.value);
    timeoutId.value = null;
  }
});
</script>

<template>
  <OgCustomQuestionnaireHeader :customQuestionnaire="customQuestionnaire" />
  <div class="h-full flex flex-col justify-between">
    <router-view
      :isLoading="loading"
      :customQuestionnaire="customQuestionnaire"
    />
  </div>
</template>
