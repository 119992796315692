<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import useVuelidate from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import CheckCircleIcon from '@heroicons/vue/outline/CheckCircleIcon';
import DocumentDuplicateIcon from '@heroicons/vue/outline/DocumentDuplicateIcon';
import {
  type OgForwardingDialogQuery,
  TaxonomyAssessmentBusinessActivityObjectiveEnum,
  TaxonomyBusinessActivityDelegationTypeEnum,
} from '@/__generated__/types';
import useDelegateTaxonomyBusinessActivity from '@/api/mutations/Taxonomy/delegateTaxonomyBusinessActivity.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import OG_FORWARDING_DIALOG_QUERY from './OgForwardingDialog.query';

type Delegation = {
  _id: string;
  user: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  shareToken: string;
};

const props = defineProps<{
  type: TaxonomyBusinessActivityDelegationTypeEnum;
  assessmentId: string;
  businessActivityId: string;
  delegation: Delegation | null;
  objective?: TaxonomyAssessmentBusinessActivityObjectiveEnum;
}>();

const emit = defineEmits<{
  close: [];
}>();

const { t } = useI18n();

const isSuccess = ref(false);

const { result } = useQuery<OgForwardingDialogQuery>(
  OG_FORWARDING_DIALOG_QUERY,
);
const { mutate: delegate } = useDelegateTaxonomyBusinessActivity();

const internalForm = reactive<{ user: string | null }>({
  user: null,
});
const externalForm = reactive({
  email: '',
});

const internalV$ = useVuelidate(
  {
    user: {
      required: helpers.withMessage(t('User is required.'), required),
    },
  },
  internalForm,
);
const externalV$ = useVuelidate(
  {
    email: {
      required: helpers.withMessage(t('Email is required.'), required),
      email: helpers.withMessage(t('Email must be valid email.'), email),
    },
  },
  externalForm,
);

const teamMembersOptions = computed(() =>
  (result.value?.getTeamUsers || []).reduce<Record<string, string>>(
    (acc, user) => {
      acc[user.email] = `${user.firstName} ${user.lastName}`;
      return acc;
    },
    {},
  ),
);

const forwardingLink = computed(() =>
  props.delegation?.shareToken
    ? `${import.meta.env.VITE_APP_URL}/external-taxonomy-assessment/login/${props.delegation.shareToken}`
    : null,
);

watch(
  [() => props.delegation, teamMembersOptions],
  ([newDelegation, newTeamMembers]) => {
    if (newDelegation) {
      internalForm.user = newTeamMembers[newDelegation.user.email]
        ? newDelegation.user.email
        : null;
      externalForm.email = internalForm.user ? '' : newDelegation.user.email;
    } else {
      internalForm.user = null;
      externalForm.email = '';
    }
  },
  { immediate: true },
);

function handleClose() {
  emit('close');
}

async function handleSubmit(type: 'internal' | 'external') {
  try {
    await delegate({
      assessmentId: props.assessmentId,
      businessActivityId: props.businessActivityId,
      email: type === 'internal' ? internalForm.user! : externalForm.email,
      data: {
        type: props.type,
        objective: props.objective,
      },
    });
    isSuccess.value = true;
  } catch {
    notify({
      type: 'error',
      text: t('Something went wrong, try again later :(.'),
    });
  }
}

const isCopying = ref(false);

function handleCopyToClipboard() {
  if (forwardingLink.value) {
    navigator.clipboard.writeText(forwardingLink.value);
    isCopying.value = true;

    setTimeout(() => {
      isCopying.value = false;
    }, 100);
  }
}
</script>

<template>
  <MlDialog
    isOpen
    class="max-w-lg"
    :closeOnOutside="false"
    @close="handleClose"
  >
    <template #title>
      {{ t('Forward or assign the complete assessment') }}
    </template>
    <template #default>
      <div class="border-t mt-3 pt-3">
        <p class="mb-6">
          {{
            t(
              'Forward the complete assessment to a person outside of the Codio Impact platform, or assign them to a specific user.',
            )
          }}
        </p>
        <form
          class="flex items-start space-x-3 mb-4"
          @submit.prevent="handleSubmit('internal')"
        >
          <MlAutocomplete
            v-model="internalForm.user"
            :label="t('Assign to a user')"
            :placeholder="t('Enter name')"
            :options="teamMembersOptions"
            :errors="internalV$.user.$errors"
            @blur="internalV$.user.$touch"
          />
          <AtButton
            type="submit"
            class="mt-6 min-w-[80px]"
            :disabled="internalV$.$invalid"
          >
            {{ t('Assign') }}
          </AtButton>
        </form>
        <p class="mb-4 text-gray-500 italic pl-3">
          {{ t('or') }}
        </p>
        <form
          class="flex items-start space-x-3 mb-6"
          @submit.prevent="handleSubmit('external')"
        >
          <AtInput
            v-model="externalForm.email"
            wrapperClass="flex-1"
            :label="t('Forward by email')"
            :placeholder="t('Enter email address of the recipient')"
            type="email"
            :errors="externalV$.email.$errors"
            @blur="externalV$.email.$touch"
          />
          <AtButton
            type="submit"
            class="mt-6 min-w-[80px]"
            :disabled="externalV$.$invalid"
          >
            {{ t('Send') }}
          </AtButton>
        </form>
        <div
          v-if="forwardingLink"
          class="mb-5 col-span-4 flex cursor-pointer"
          @click="handleCopyToClipboard"
        >
          <div class="w-full">
            <AtInput
              v-model="forwardingLink"
              :label="t('Copy link')"
              disabled
              readOnly
            />
          </div>
          <AtButton
            variant="text"
            class="flex ml-2 cursor-pointer items-center justify-center text-gray-400 w-[65px] h-[38px] self-end"
            :loading="isCopying"
            :disabled="internalV$.$invalid && externalV$.$invalid"
          >
            <DocumentDuplicateIcon
              v-if="!isCopying"
              class="w-6 cursor-pointer"
            />
          </AtButton>
        </div>
        <div v-if="isSuccess" class="text-teal-600 flex col-span-4 mb-5">
          <CheckCircleIcon class="w-5 mr-2" />
          <span>
            {{ t('Email has been successfully sent.') }}
          </span>
        </div>
      </div>
    </template>
  </MlDialog>
</template>
