<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import clamp from 'lodash/clamp';
import type { ErrorObject } from '@vuelidate/core';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const props = defineProps<TProps>();
interface TProps {
  modelValue: number;
  stepsLength: number;
  isCurrentStepCompleted: boolean;
  globalMutationLoading: boolean;
  loading: boolean;
  currentStepIndex: number;
  validationErrors: ErrorObject[];
}

const emit = defineEmits<TEmits>();
interface TEmits {
  (e: 'updateCurrentStepIndex', step: number): void;
  (e: 'clickedFinish'): void;
}

const { t } = useI18n();

const finishIsDisabled = computed(
  () =>
    props.currentStepIndex + 1 === props.stepsLength &&
    !props.isCurrentStepCompleted,
);

const isLastStep = computed(
  () => props.currentStepIndex === props.stepsLength - 1,
);

function updateStep(increment: number) {
  emit(
    'updateCurrentStepIndex',
    clamp(props.modelValue + increment, 0, props.stepsLength),
  );
}

function goToGetStartedPage() {
  emit('clickedFinish');
}
</script>
<template>
  <section
    class="border-t border-gray-200 py-6 text-base font-normal leading-6 text-gray-400"
  >
    <AtButton
      v-show="currentStepIndex !== 0"
      class="px-6"
      variant="link"
      data-cy="linkPrev"
      @click.stop="updateStep(-1)"
    >
      {{ t('Previous') }}
    </AtButton>
    <span v-show="currentStepIndex !== 0"> | </span>
    <AtButton
      v-if="!isLastStep"
      class="px-6"
      variant="link"
      data-cy="linkNext"
      :disabled="props.globalMutationLoading || !!props.validationErrors.length"
      @click.stop="updateStep(1)"
    >
      {{ t('Next') }}
    </AtButton>
    <AtButton
      v-else
      class="px-6"
      variant="link"
      data-cy="linkFinish"
      :disabled="
        !loading &&
        (finishIsDisabled ||
          props.globalMutationLoading ||
          !!props.validationErrors.length)
      "
      :loading="loading"
      @click.stop="goToGetStartedPage"
    >
      {{ t('Finish') }}
    </AtButton>
  </section>
</template>
