<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import isNil from 'lodash/isNil';
import DownloadIcon from '@heroicons/vue/solid/DownloadIcon';
import ViewListIcon from '@heroicons/vue/outline/ViewListIcon';
import { DoubleMaterialitySurveyStatus } from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { useStore, useStoreMethods } from '../../store';
import AtEmptyCard from '../../AtEmptyCard.vue';
import MlInfo from '../../MlInfo.vue';
import OgImpactsTable from './OgImpactsTable.vue';
import OgRisksAndOpportunitiesTable from './OgRisksAndOpportunitiesTable.vue';
import OgResultCalculation, { type SaveEvent } from './OgResultCalculation.vue';
import OgFinalMaterialityTable from './OgFinalMaterialityTable.vue';
import OgSurveysTable from './OgSurveysTable.vue';

const { t } = useI18n();
const store = useStore();
const { addInitialResultCalculation } = useStoreMethods();

const isResultCalculationOpen = ref(false);

const showImpactsTable = computed(() => store.value.iroImpacts.length > 0);
const showRisksAndOpportunitiesTable = computed(
  () => store.value.iroRisksAndOpportunities.length > 0,
);
const showSurveysTable = computed(() =>
  store.value.surveys.some(
    (survey) => survey.status === DoubleMaterialitySurveyStatus.COMPLETED,
  ),
);

watch(
  () => store.value.resultCalculation,
  (newResultCalculation) => {
    if (
      isNil(newResultCalculation.internal) ||
      isNil(newResultCalculation.stakeholders)
    ) {
      addInitialResultCalculation();
    }
  },
  { immediate: true },
);

function handleResultCalculationToggle() {
  isResultCalculationOpen.value = !isResultCalculationOpen.value;
}

function handleResultCalculationSave(event: SaveEvent) {
  if (store.value) {
    store.value.resultCalculation.internal = event.internal;
    store.value.resultCalculation.stakeholders = event.stakeholders;

    handleResultCalculationToggle();
  }
}
</script>

<template>
  <MlInfo
    v-if="showImpactsTable || showRisksAndOpportunitiesTable"
    type="warning"
    class="mb-6"
  >
    {{
      t(
        'Please keep in mind that this is a cumulative score of your own rating and all stakeholder surveys. The impact of stakeholder survey results to the main score can be adjusted below, under “Result calculation”. If you want to learn more about our methodology, please go to the “Start > Guidance” site on the main overview.',
      )
    }}
  </MlInfo>

  <div class="flex justify-end items-center space-x-4 mb-4">
    <AtButton
      variant="outline"
      type="button"
      @click="handleResultCalculationToggle"
    >
      {{ t('Result calculation') }}
    </AtButton>
    <AtButton variant="outline" type="button" disabled :icon="DownloadIcon">
      {{ t('Download as Excel') }}
    </AtButton>
    <AtButton type="button" disabled :icon="DownloadIcon">
      {{ t('Download as PDF') }}
    </AtButton>
  </div>

  <OgFinalMaterialityTable
    v-if="showImpactsTable || showRisksAndOpportunitiesTable"
  />

  <AtEmptyCard
    v-if="!showImpactsTable"
    class="mb-8"
    variant="center"
    :icon="ViewListIcon"
    :title="t('Material impacts, risks and opportunities')"
  >
    <p>
      {{
        t(
          'Once you enter data in the Topics part, your assessment will show here.',
        )
      }}
    </p>
  </AtEmptyCard>
  <OgImpactsTable v-else />

  <AtEmptyCard
    v-if="!showRisksAndOpportunitiesTable"
    class="mb-6"
    variant="center"
    :icon="ViewListIcon"
    :title="t('Non-material impacts, risks and opportunities')"
  >
    <p>
      {{
        t(
          'Once you enter data in the Topics part, your assessment will show here.',
        )
      }}
    </p>
  </AtEmptyCard>
  <OgRisksAndOpportunitiesTable v-else />

  <OgSurveysTable v-if="showSurveysTable" />

  <OgResultCalculation
    v-if="isResultCalculationOpen"
    @close="handleResultCalculationToggle"
    @save="handleResultCalculationSave"
  />
</template>
