<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import type { OgProjectsQuery } from '@/__generated__/types';
import OG_PROJECTS_QUERY from '../OgProjects.query';
import OgProjectLayout from './OgProjectLayout.vue';

type Props = {
  project?: string;
};

defineProps<Props>();

const route = useRoute();

const { result } = useQuery<OgProjectsQuery>(OG_PROJECTS_QUERY);

const isList = computed(() => route.name === 'projectsProject');
const isCustomQuestionnaire = computed(() =>
  typeof route.name === 'string'
    ? route.name.startsWith('projectsCustomQuestionnaire')
    : false,
);
const projects = computed(() => result.value?.entityLocationSummaries || []);
</script>

<template>
  <router-view v-if="isList || isCustomQuestionnaire" />
  <OgProjectLayout v-else :projectId="project!" :projects="projects" />
</template>
