import { gql } from '@apollo/client/core';

export default gql`
  query GenerateGuidanceAiSuggestion(
    $aiGuidanceSuggestionInput: AiGuidanceSuggestionInput!
  ) {
    generateGuidanceAiSuggestion(
      aiGuidanceSuggestionInput: $aiGuidanceSuggestionInput
    ) {
      aiSuggestionHtml
      sourceGuidanceKeys
    }
  }
`;
