<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <ul class="list-disc px-2">
    <li>
      <span class="font-bold">{{ t('Percentage:') }}</span>
      {{
        t(
          "Measure the percentage progress to be achieved at the end date. For example: 'Increase female management members by 30%'. Add the start value '0' and target value '30'. For a percentage decrease, add a minus in front of the target value.",
        )
      }}
    </li>
    <li>
      <span class="font-bold">{{ t('Numeric:') }}</span>
      {{
        t(
          "Measure a fixed outcome as a (whole) number to be achieved at the end date, e.g. 'Screen 6 new suppliers using environmental criteria'. Here, 6 would be the target value.",
        )
      }}
    </li>
    <li>
      <span class="font-bold">{{ t('Achieved Yes / No:') }}</span>
      {{
        t(
          "This will simply allow you to mark the KPI as 'achieved / not achieved'. It works well for qualitative goals.",
        )
      }}
    </li>
  </ul>
</template>
