<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DotsHorizontalIcon, SearchIcon } from '@heroicons/vue/outline';
import { useTemplateRefsList } from '@vueuse/core';
import { notify } from '@kyvg/vue3-notification';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import cloneDeep from 'lodash/cloneDeep';
import { useMutationLoading, useQuery } from '@vue/apollo-composable';
import { ApolloError } from '@apollo/client/core';
import OgWorkspacesRestrictionsModal from '@/components/organisms/OgRestrictionsModal/OgWorkspacesRestrictionsModal.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlMenu, { AtMenuItem } from '@/components/molecules/MlMenu/MlMenu.vue';
import useCreateEntityMutation from '@/api/mutations/createEntity.mutation';
import useUpdateEntityMutation from '@/api/mutations/Entity/updateEntity.mutation';
import useDeleteEntityMutation from '@/api/mutations/deleteEntity.mutation';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import { UserRole, type TmSettingsEntitiesQuery } from '@/__generated__/types';
import OgCreateEntityModal from '@/components/organisms/OgCreateEntityModal/OgCreateEntityModal.vue';
import useAddEntityToUsersMutation from '@/api/mutations/User/addEntityToUsers.mutation';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import TM_SETTINGS_ENTITIES_QUERY from './TmSettingsEntities.query';

const { t } = useI18n();
const { confirmViaDialog } = useConfirmViaDialog();
const showRestrictionsModal = ref(false);

const { restrictions, loading: loadingRestrictions } = useRestrictions();

const { isAdminOrSuperAdmin, refetch: refetchCurrentUser } = useCurrentUser();

const { result, refetch } = useQuery<TmSettingsEntitiesQuery>(
  TM_SETTINGS_ENTITIES_QUERY,
);
const initialEntities = computed(
  () => result.value?.getCurrentUserEntities ?? [],
);

const {
  mutate: createEntity,
  loading: createEntityLoading,
  error: createEntityError,
} = useCreateEntityMutation();
const { mutate: updateEntity } = useUpdateEntityMutation();
const { mutate: deleteEntity } = useDeleteEntityMutation({
  update: (store) => {
    // Refresh old data.
    store.evict({ id: 'ROOT_QUERY', fieldName: 'getOwnUser' });
    store.evict({ id: 'ROOT_QUERY', fieldName: 'getCurrentUserEntities' });
  },
});

const {
  mutate: addEntityToUsersMutation,
  error: addEntityToUsersError,
  loading: addEntityToUsersLoading,
} = useAddEntityToUsersMutation();

const loading = useMutationLoading();

const searchValue = ref('');

const writableEntities = ref(cloneDeep(initialEntities.value ?? []));
watch(initialEntities, () => {
  writableEntities.value = cloneDeep(initialEntities.value);
});

const numberOfEntities = computed(() => initialEntities.value.length);

const isUnique = (name: string) =>
  writableEntities.value.filter((entity) => entity.name === name).length <= 1;
const v = useVuelidate(
  {
    writableEntities: {
      $each: helpers.forEach({
        name: {
          required: helpers.withMessage(
            t('The workspace must have a name.'),
            required,
          ),
          unique: helpers.withMessage(
            t('A workspace with this name already exists.'),
            isUnique,
          ),
        },
      }),
    },
  },
  { writableEntities },
);

const renamingInputIndex = ref(-1);
const entityInputs = useTemplateRefsList<typeof AtInput>();

const isCreatingEntity = computed(
  () => writableEntities.value.length > initialEntities.value.length,
);

const initiateNewEntity = async () => {
  if (
    restrictions.value &&
    restrictions.value.numberOfWorkspaces > 0 &&
    writableEntities.value.length >= restrictions.value?.numberOfWorkspaces &&
    writableEntities.value.length > 0
  ) {
    showRestrictionsModal.value = true;
    return;
  }

  searchValue.value = '';
  await nextTick();

  writableEntities.value.push({ name: '', _id: '' });
  initiateRename(initialEntities.value.length);
};

const initiateRename = (i: number) => {
  renamingInputIndex.value = i;
  nextTick(() => entityInputs.value[i].inputEl.focus());
};

const isRevealed = ref(false);
const createEntityName = ref('');

const saveRenameOrCreate = async (name: string, _id: string) => {
  v.value.$touch();

  if (v.value.$error) return;

  if (isCreatingEntity.value) {
    isRevealed.value = true;
    createEntityName.value = name;
  } else {
    await updateEntity({ entityInput: { name, _id } });

    v.value.$reset();
    refetchCurrentUser();
    await refetch();
    renamingInputIndex.value = -1;
  }
};

async function handleCreateEntity(usersIds?: string[]) {
  try {
    const newEntity = await createEntity({
      entityInput: { name: createEntityName.value },
    });
    const entityId = newEntity?.data?.createEntity._id;
    if (usersIds && entityId)
      await addEntityToUsersMutation({ usersIds, entityId });

    v.value.$reset();
    resetModalData();
    refetchCurrentUser();
    await refetch();
    renamingInputIndex.value = -1;
    notify({
      type: 'success',
      text: t('The workspace {workspaceName} has been created.', {
        workspaceName: newEntity?.data?.createEntity.name,
      }),
    });
  } catch (err) {
    resetModalData();
    if (createEntityError.value)
      notify({ type: 'error', text: t(createEntityError.value?.message) });
    if (addEntityToUsersError.value)
      notify({ type: 'error', text: t(addEntityToUsersError.value?.message) });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

const cancelRenameOrCreate = (i: number) => {
  if (isCreatingEntity.value) {
    writableEntities.value.pop();
  } else {
    writableEntities.value[i].name = initialEntities.value[i].name;
  }

  v.value.$reset();
  renamingInputIndex.value = -1;
};

const initiateDeletion = async (_id: string) => {
  const isConfirmed = await confirmViaDialog({
    title: t('Are you sure you want to delete this workspace?'),
    text: t('All associated data entries will be deleted.'),
    confirmLabel: t('Delete workspace'),
    confirmButtonVariant: 'destructive',
  });

  if (isConfirmed) {
    try {
      await deleteEntity({ entityInput: { _id } });
    } catch (e) {
      if (e instanceof ApolloError) {
        notify({
          type: 'error',
          text: t(e.message),
          duration: 10000,
        });
      }
    }

    refetch();
  }
};

function resetModalData() {
  isRevealed.value = false;
  createEntityName.value = '';
}
</script>

<template>
  <div class="px-6 pt-5">
    <OgCreateEntityModal
      v-if="isRevealed && isAdminOrSuperAdmin"
      :loading="createEntityLoading || addEntityToUsersLoading"
      :error="createEntityError"
      :isRevealed="isRevealed"
      :confirmDisabled="v.$invalid || !createEntityName"
      onlyAddUsersStep
      data-cy="OgCreateEntityModal"
      @confirm="handleCreateEntity"
      @cancel="resetModalData"
    />
    <section class="flex justify-between">
      <div>
        <h2 class="text-xl font-medium text-gray-900">
          {{ t('Workspaces') }}
        </h2>
        <p class="mt-3 text-sm text-gray-500">
          {{ t('Manage and edit your workspaces.') }}
        </p>
        <div class="flex py-6">
          <div class="text-center">
            <div class="text-4xl">
              {{ numberOfEntities }}
            </div>
            <p class="mt-1 text-sm">
              {{ t('Total workspaces') }}
            </p>
          </div>
        </div>
      </div>
      <AtButton
        class="fmt-2 h-9"
        data-cy="AtButtonAddEntity"
        :disabled="renamingInputIndex !== -1 && !loadingRestrictions"
        @click="initiateNewEntity"
      >
        {{ t('Add workspace') }}
      </AtButton>
    </section>
    <section>
      <AtInput
        v-model="searchValue"
        wrapperClass="w-64 m-4"
        :placeholder="t('Search')"
        :disabled="renamingInputIndex !== -1 || loading"
      >
        <template #icon>
          <SearchIcon />
        </template>
      </AtInput>
      <div
        v-for="(entity, i) in writableEntities.filter(({ name }) =>
          name?.includes(searchValue),
        )"
        :key="entity._id"
        class="border-b"
        :class="{ 'border-t': i === 0 }"
      >
        <div class="flex justify-between gap-4 p-6">
          <span
            v-show="renamingInputIndex !== i"
            class="py-2 sm:text-sm"
            data-cy="spanEntityName"
          >
            {{ entity.name }}
          </span>
          <AtInput
            v-show="renamingInputIndex === i"
            :ref="entityInputs.set"
            v-model="entity.name"
            data-cy="AtInputEntityName"
            wrapperClass="w-full sm:w-3/4 mr-4"
            :errors="
              // @ts-ignore
              v.$error ? v.writableEntities.$each.$message[i] : []
            "
          />
          <MlMenu data-cy="MlMenuRenameDelete">
            <button
              class="group"
              :disabled="renamingInputIndex !== -1 || loading"
              type="submit"
            >
              <DotsHorizontalIcon class="h-6 w-6 group-disabled:opacity-50" />
            </button>
            <template #menuItems>
              <AtMenuItem
                class="justify-center"
                data-cy="AtMenuItemRename"
                @click="initiateRename(i)"
              >
                {{ t('Rename') }}
              </AtMenuItem>
              <AtMenuItem
                v-rolefrom="UserRole.ADMIN"
                :disabled="writableEntities.length === 1"
                data-cy="AtMenuItemDelete"
                class="justify-center bg-error text-white hover:hover:bg-error hover:opacity-90"
                @click="initiateDeletion(entity._id)"
              >
                {{ t('Delete') }}
              </AtMenuItem>
            </template>
          </MlMenu>
        </div>

        <div v-if="renamingInputIndex === i" class="gap-6 px-6 pb-6">
          <AtButton
            variant="default"
            :loading="loading"
            :disabled="v.$error"
            data-cy="AtButtonSaveEntity"
            @click="saveRenameOrCreate(entity.name ?? '', entity._id)"
          >
            {{ t('Save') }}
          </AtButton>
          <AtButton
            class="ml-5"
            variant="outline"
            data-cy="AtButtonCancelAddEntity"
            @click="cancelRenameOrCreate(i)"
          >
            {{ t('Cancel') }}
          </AtButton>
        </div>
      </div>
    </section>
  </div>
  <OgWorkspacesRestrictionsModal
    :showModal="showRestrictionsModal"
    @closeModal="showRestrictionsModal = false"
  />
</template>
