import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdateEntitySettingsMutation,
  UpdateEntitySettingsMutationVariables,
} from '@/__generated__/types';

export const UPDATE_ENTITY_SETTINGS = gql`
  mutation UpdateEntitySettings(
    $updateEntitySettingsInput: UpdateEntitySettingsInput!
  ) {
    updateEntitySettings(
      updateEntitySettingsInput: $updateEntitySettingsInput
    ) {
      _id
      companyName
      industry
      locationOfHeadquarter
      nameOfHeadquarter
      numberOfEmployees

      dashboardPinnedDataPointTypes {
        _id

        dataPointType {
          _id
          name
          friendlyName
          defaultChartType
          valueUnit

          reportingFrameworks {
            _id
            framework
            groups
          }
        }
      }

      overviewPinnedDataPointTypes {
        _id

        dataPointType {
          _id
          name
          friendlyName
          defaultChartType
          valueUnit

          reportingFrameworks {
            _id
            framework
            groups
          }
        }
      }

      picture {
        _id
        downloadUrl
      }
    }
  }
`;

type TOptions = UseMutationOptions<
  UpdateEntitySettingsMutation,
  UpdateEntitySettingsMutationVariables
>;

const useUpdateEntitySettingsMutation = (options?: TOptions) =>
  useMutation<
    UpdateEntitySettingsMutation,
    UpdateEntitySettingsMutationVariables
  >(UPDATE_ENTITY_SETTINGS, options);

export default useUpdateEntitySettingsMutation;
