<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';
import type { SubTopicOptions } from '../../types';

const props = defineProps<{
  subTopics: SubTopicOptions[];
}>();

const emit = defineEmits<{
  created: [event: string];
}>();

const model = defineModel<string>({ required: true });

const { t } = useI18n();

const options = computed(() =>
  props.subTopics.reduce<Record<string, string>>((acc, item) => {
    acc[item.subTopic] = t(item.subTopic);
    return acc;
  }, {}),
);

function handleChange(event: string) {
  if (!props.subTopics.find((item) => item.subTopic === event)) {
    emit('created', event);
  }

  model.value = event;
}
</script>

<template>
  <MlAutocomplete
    :modelValue="model"
    :placeholder="t('Enter or select')"
    :options="options"
    canCreate
    hideSearchIcon
    @update:modelValue="handleChange"
  />
</template>
