<script setup lang="ts">
import { RouterLink } from 'vue-router';
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { UPGRADE_EMAIL } from '@/constants/upgradeEmail';

defineProps<{
  backToWorkspace?: boolean;
}>();

defineEmits(['closeModal']);

const { t } = useI18n();

const redirectToMail = () => {
  window.location.href = `mailto:${UPGRADE_EMAIL}`;
};
</script>

<template>
  <div class="flex items-center flex-col gap-y-4 pb-12 pt-5">
    <h1 class="uppercase text-gray-400 text-sm">
      <slot name="pageTitle" />
    </h1>

    <h2 class="text-gray-900 text-lg">
      <slot name="title" />
    </h2>

    <p class="text-center">
      <slot name="text" />
    </p>
    <AtButton
      class="mt-4"
      :class="{ 'mb-8': !backToWorkspace }"
      @click="() => redirectToMail()"
    >
      {{ t('Contact us') }}
    </AtButton>
    <RouterLink
      v-if="backToWorkspace"
      :to="{ name: 'home' }"
      class="text-info mb-8"
    >
      {{ t('Back to my workspace') }}
    </RouterLink>
    <div class="max-w-3xl">
      <slot name="image" />
    </div>
  </div>
</template>
