<script setup lang="ts">
//! Nested menus don't work properly yet.

import { ref } from 'vue';
import type { TPlacement } from './types';

const props = withDefaults(
  defineProps<{
    placement?: TPlacement;
    submenu?: boolean;
  }>(),
  {
    placement: 'bottom-start',
  },
);

const menu = ref();
defineExpose({
  menu,
});
</script>

<script lang="ts">
export { default as AtMenuItem } from './AtMenuItem.vue';
</script>

<template>
  <VDropdown
    ref="menu"
    class="ml-menu"
    data-cy="MlMenu"
    :distance="props.submenu ? 0 : 4"
    :placement="props.submenu ? 'right-start' : props.placement"
    :triggers="['click', props.submenu ? 'hover' : 'click']"
    :instantMove="props.submenu"
    v-bind="$attrs"
  >
    <slot />

    <template #popper="popperSlotProps">
      <div class="divide-y rounded-md ring-1 ring-black/5" data-cy="MlMenu">
        <slot name="menuItems" v-bind="popperSlotProps" />
      </div>
    </template>
  </VDropdown>
</template>
