<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import MlCard from '@/components/molecules/MlCard.vue';
import { ReportType } from '@/components/pages/PgAdmin/Home/TmReports/types';
import useEmissionFactorsCsv from '@/utils/composables/useEmissionFactorsCsv/useEmissionFactorsCsv';

const { t } = useI18n();
const emissionFactorsCsv = useEmissionFactorsCsv();

const reportTypes = computed(() => [
  {
    title: t('Emission Factors'),
    type: ReportType.EMISSION_FACTORS,
    description: t(
      'Get the complete list of emission factors that are used for calculations.',
    ),
    buttonText: t('Download emission factors'),
    action: () => emissionFactorsCsv.download(),
    loading: emissionFactorsCsv.loading.value,
  },
]);
</script>

<template>
  <div>
    <div class="mb-12 flex gap-6 w-96">
      <MlCard
        v-for="reportType in reportTypes"
        :key="reportType.type"
        :title="reportType.title"
        :description="reportType.description"
        :buttonText="reportType.buttonText"
        :loading="reportType.loading"
        @buttonClick="reportType.action()"
      />
    </div>
  </div>
</template>
