<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  Chart,
  BubbleController,
  PointElement,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  type ChartData,
  type ChartOptions,
} from 'chart.js';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import DataLabels from 'chartjs-plugin-datalabels';
import { Bubble } from 'vue-chartjs';
import { chartColors, colors } from '@/styles/theme';

type ChartOptionsPerType = {
  title: string;
  xAxisLabel: string;
  yAxisLabel: string;
  dashedLineColor: string;
  showLegend: boolean;
  showGrid: boolean;
  showChartSquares: boolean;
};

export type DataItem = {
  label: string;
  x: number;
  y: number;
};

Chart.register(
  BubbleController,
  PointElement,
  Legend,
  Title,
  Tooltip,
  LinearScale,
  AnnotationPlugin,
  DataLabels,
);
const boxColorRGB = (function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : null;
})(colors.gray['400']);

const props = defineProps<{
  type: 'impactMateriality' | 'financialMateriality' | 'doubleMateriality';
  data: DataItem[];
}>();

const { t } = useI18n();

const chartData = computed<ChartData<'bubble'>>(() => ({
  labels: [],
  datasets:
    props.data.length > 0
      ? props.data.map((item, index) => ({
          label: item.label,
          data: [
            {
              r: 10,
              x: item.x,
              y: item.y,
            },
          ],
          backgroundColor:
            chartColors[
              ((index % chartColors.length) + chartColors.length) %
                chartColors.length
            ][1],
        }))
      : [
          {
            label: '',
            data: [],
          },
        ],
}));

const chartOptionsPerType = computed<ChartOptionsPerType>(() => {
  switch (props.type) {
    case 'impactMateriality':
      return {
        title: t('Impact materiality'),
        xAxisLabel: t('Likelihood'),
        yAxisLabel: t('Severity'),
        dashedLineColor: colors.emerald['500'],
        showLegend: false,
        showGrid: false,
        showChartSquares: true,
      };
    case 'financialMateriality':
      return {
        title: t('Financial materiality'),
        xAxisLabel: t('Likelihood'),
        yAxisLabel: t('Potential magnitude'),
        dashedLineColor: colors.blue.dark,
        showLegend: false,
        showGrid: false,
        showChartSquares: true,
      };
    case 'doubleMateriality':
      return {
        title: t('Double materiality matrix'),
        xAxisLabel: t('Impact materiality'),
        yAxisLabel: t('Financial materiality'),
        dashedLineColor: colors.rose['400'],
        showLegend: props.data.length > 0,
        showGrid: true,
        showChartSquares: false,
      };
    default:
      throw new Error('Invalid type');
  }
});

const chartOptions = computed<ChartOptions<'bubble'>>(() => ({
  responsive: true,
  aspectRatio: 1,
  scales: {
    x: {
      type: 'linear',
      min: 0,
      max: 8,
      title: {
        display: true,
        text: chartOptionsPerType.value.xAxisLabel,
        align: 'center',
        font: {
          size: 14,
          weight: 'medium',
          style: 'italic',
          family: 'Inter',
          color: colors.gray['500'],
        },
      },
      ticks: {
        stepSize: 0.5,
        minRotation: 0,
        maxRotation: 0,
        callback(_, index) {
          if (index === 1) {
            return t('Low');
          }

          if (index === 8) {
            return t('Medium');
          }

          if (index === 15) {
            return t('High');
          }

          return '';
        },
        align: 'center',
      },
      grid: {
        display: chartOptionsPerType.value.showGrid,
        color(context) {
          return Number.isInteger(context.tick.value)
            ? 'rgba(0,0,0,0.1)'
            : 'rgb(255,255,255)';
        },
      },
      font: {
        size: 12,
        weight: 'medium',
        family: 'Inter',
        color: colors.gray['500'],
      },
    },
    y: {
      type: 'linear',
      min: 0,
      max: 8,
      title: {
        display: true,
        text: chartOptionsPerType.value.yAxisLabel,
        align: 'center',
        font: {
          size: 14,
          weight: 'medium',
          style: 'italic',
          family: 'Inter',
          color: colors.gray['500'],
        },
      },
      ticks: {
        stepSize: 0.5,
        minRotation: 90,
        labelOffset: -15,
        callback(_, index) {
          if (index === 1) {
            return t('Low');
          }

          if (index === 8) {
            return t('Medium');
          }

          if (index === 15) {
            return t('High');
          }

          return '';
        },
        font: {
          size: 12,
          weight: 'medium',
          family: 'Inter',
          color: colors.gray['500'],
        },
        align: 'center',
      },
      grid: {
        display: chartOptionsPerType.value.showGrid,
        color(context) {
          return Number.isInteger(context.tick.value)
            ? 'rgba(0,0,0,0.1)'
            : 'rgb(255,255,255)';
        },
      },
    },
  },
  plugins: {
    datalabels: {
      anchor: 'center',
      align: 'top',
      offset: 10,
      font: {
        size: 14,
        family: 'Inter',
        color: colors.gray['500'],
      },
      formatter(_, context) {
        const text = context.dataset.label ?? '';
        if (text.length <= 17) {
          return text;
        }

        return `${text.substring(0, 17)}...`;
      },
    },
    legend: {
      display: chartOptionsPerType.value.showLegend,
      position: 'right',
      align: 'center',
      labels: {
        usePointStyle: true,
        padding: 14,
        pointStyle: 'circle',
      },
    },
    title: {
      display: true,
      text: chartOptionsPerType.value.title,
      position: 'top',
      align: 'start',
      padding: {
        bottom: 24,
      },
      font: {
        size: 16,
        family: 'Inter',
        weight: 'normal',
        color: colors.gray['900'],
      },
    },
    annotation: {
      annotations: {
        line: {
          type: 'line',
          borderWidth: 3,
          curve: true,
          borderDash: [10, 10],
          xMin: 0,
          yMin: 5,
          xMax: 6,
          yMax: 0,
          borderColor: chartOptionsPerType.value.dashedLineColor,
          value: 1,
          endValue: 1,
        },
        boxBottomLeft: {
          display: chartOptionsPerType.value.showChartSquares,
          type: 'box',
          xMin: 0,
          xMax: 4,
          yMin: 0,
          yMax: 4,
          backgroundColor: `rgba(${boxColorRGB?.red},${boxColorRGB?.green},${boxColorRGB?.blue},0.05)`,
          borderWidth: 0,
        },
        boxBottomRight: {
          display: chartOptionsPerType.value.showChartSquares,
          type: 'box',
          xMin: 4,
          xMax: 8,
          yMin: 0,
          yMax: 4,
          backgroundColor: `rgba(${boxColorRGB?.red},${boxColorRGB?.green},${boxColorRGB?.blue},0.17)`,
          borderWidth: 0,
        },
        boxTopLeft: {
          display: chartOptionsPerType.value.showChartSquares,
          type: 'box',
          xMin: 0,
          xMax: 4,
          yMin: 4,
          yMax: 8,
          backgroundColor: `rgba(${boxColorRGB?.red},${boxColorRGB?.green},${boxColorRGB?.blue},0.12)`,
          borderWidth: 0,
        },
        boxTopRight: {
          display: chartOptionsPerType.value.showChartSquares,
          type: 'box',
          xMin: 4,
          xMax: 8,
          yMin: 4,
          yMax: 8,
          backgroundColor: `rgba(${boxColorRGB?.red},${boxColorRGB?.green},${boxColorRGB?.blue},0.24)`,
          borderWidth: 0,
        },
      },
    },
    tooltip: {
      callbacks: {
        label(context) {
          return context.label;
        },
      },
    },
  },
}));
</script>

<template>
  <Bubble
    class="relative mx-auto max-h-[500px] max-w-[500px]"
    :chartData="chartData"
    :chartOptions="chartOptions"
  />
</template>
