<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import groupBy from 'lodash/groupBy';
import { calculateSeverityForImpact } from '../../utils';
import { useStore } from '../../store';
import OgChart, { type DataItem } from './OgChart.vue';

function impactNotEmpty(value: {
  severity: number | null;
  likelihood: number | null;
}): value is { severity: number; likelihood: number } {
  return (
    typeof value.severity === 'number' && typeof value.likelihood === 'number'
  );
}

const { t } = useI18n();
const store = useStore();

const data = computed<DataItem[]>(() => {
  const iroImpactsByTopic = groupBy(
    store.value.iroImpacts,
    (iroImpact) => iroImpact.topicId,
  );
  return Object.entries(iroImpactsByTopic)
    .map(([topicId, iroImpacts]) => {
      const topic = store.value.topics.find((item) => item._id === topicId)!;

      const { severity, likelihood } = iroImpacts
        .map((iroImpact) => ({
          severity: calculateSeverityForImpact(
            iroImpact.scale,
            iroImpact.scope,
            iroImpact.irremediability,
            iroImpact.type,
          ),
          likelihood: iroImpact.likelihood,
        }))
        .reduce<{ severity: number | null; likelihood: number | null }>(
          (acc, item) => {
            if (
              typeof item.severity === 'number' &&
              typeof item.likelihood === 'number'
            ) {
              if (
                typeof acc.severity !== 'number' ||
                item.severity > acc.severity
              ) {
                acc.severity = item.severity;
              }

              if (
                typeof acc.likelihood !== 'number' ||
                item.likelihood > acc.likelihood
              ) {
                acc.likelihood = item.likelihood;
              }
            }

            return acc;
          },
          { severity: null, likelihood: null },
        );

      return {
        sustainabilityMatter: t(topic.subSubTopic) || t(topic.subTopic) || '',
        likelihood,
        severity,
      };
    })
    .filter<{
      severity: number;
      likelihood: number;
      sustainabilityMatter: string;
    }>((item) => impactNotEmpty(item))
    .map((item) => ({
      label: item.sustainabilityMatter,
      x: item.likelihood,
      y: item.severity,
    }));
});
</script>

<template>
  <OgChart type="impactMateriality" :data="data" />
</template>
