<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { boxed } from '@syncedstore/core';
import PlusIcon from '@heroicons/vue/solid/PlusIcon';
import {
  DoubleMaterialityDownloadDocumentEnum,
  DoubleMaterialityIroImpactType,
} from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlCommentBox from '@/components/molecules/MlCommentBox.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import { useDocumentDownload } from '../../../composables/useDocumentDownload';
import { useStore, useStoreMethods } from '../../../store';
import MlInfo from '../../../MlInfo.vue';
import OgDocument, { type UploadedDocument } from '../../../OgDocument.vue';
import OgTable from '../../../OgTable.vue';
import OgSupport from './OgSupport.vue';

type RowItem = {
  _id: string;
  index: number;
  topicId: string;
};

type ImpactTopic = {
  _id: string;
  topic: string;
  subTopic: string;
  subSubTopic: string;
};

const { t } = useI18n();
const router = useRouter();
const guidanceDownload = useDocumentDownload(
  DoubleMaterialityDownloadDocumentEnum.IMPACTS_SCORING,
);
const store = useStore();
const { addIroImpact, removeIroImpact, changeIroImpact, changeIroImpactType } =
  useStoreMethods();

const { currentUser } = useCurrentUser();

const headers = computed(() => [
  { text: t('Standard'), value: 'topic', columnClasses: 'w-[240px]' },
  { text: t('Sub-topic'), value: 'subTopic', columnClasses: 'w-[200px]' },
  {
    text: t('Sub-sub-topic'),
    value: 'subSubTopic',
    columnClasses: 'w-[200px]',
  },
  { text: t('Impact(s)'), value: 'impact' },
  { text: t('Type'), value: 'type', columnClasses: 'w-[280px]' },
]);
const items = computed<RowItem[]>(() => {
  const iroImpacts = store.value.iroImpacts.map((iroImpact, index) => ({
    _id: iroImpact._id,
    index,
    topicId: iroImpact.topicId,
  }));

  return store.value.topics
    .filter((topic) => topic.topic && topic.subTopic)
    .flatMap((topic) =>
      iroImpacts.filter((iroImpact) => iroImpact.topicId === topic._id),
    );
});
const topics = computed(() =>
  store.value.topics.reduce<Record<string, ImpactTopic>>((acc, topic) => {
    acc[topic._id.toString()] = {
      _id: topic._id.toString(),
      topic: `${topic.esrs} - ${t(topic.topic)}`,
      subTopic: t(topic.subTopic.toString()),
      subSubTopic: t(topic.subSubTopic.toString()),
    };

    return acc;
  }, {}),
);
const typeOptions = computed(() => ({
  [DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE]: t('Actual negative impact'),
  [DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE]: t(
    'Potential negative impact',
  ),
  [DoubleMaterialityIroImpactType.ACTUAL_POSITIVE]: t('Actual positive impact'),
  [DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE]: t(
    'Potential positive impact',
  ),
}));

function handleImpactChange(index: number, event: string) {
  changeIroImpact(index, event);
}

function handleTypeChange(
  index: number,
  event: DoubleMaterialityIroImpactType | null,
) {
  changeIroImpactType(index, event);
}

function handleRemoveRow(event: number) {
  removeIroImpact(event);
}

function handleAddRowClick(index: number, topicId: string) {
  addIroImpact(index, topicId);
}

function handleFileChange(index: number, event: UploadedDocument) {
  store.value.iroImpacts[index].document = boxed(event);
}
</script>

<template>
  <MlInfo type="warning" class="mb-4">
    <p>
      {{
        t(
          'An impact refers to the effect a company and its activities across the value chain has on sustainability matters, both positive and negative effects.',
        )
      }}
    </p>
    <p>
      {{
        t(
          'Define impacts for each of the sustainability matters you identified in the previous step (typically you should define up to 3 impacts per matter). Click “add rows” to add another impact for the same sustainability matter. If you cannot identify any impact for a sustainability matter, simply leave this empty for now.',
        )
      }}
    </p>
  </MlInfo>

  <OgTable :headers="headers" :items="items" @removeRow="handleRemoveRow">
    <template #item-topic="row: RowItem">
      <AtInput
        type="text"
        readonly
        disabled
        :value="topics[row.topicId].topic"
      />
    </template>
    <template #item-subTopic="row: RowItem">
      <AtInput
        type="text"
        readonly
        disabled
        :value="topics[row.topicId].subTopic"
      />
    </template>
    <template #item-subSubTopic="row: RowItem">
      <AtInput
        type="text"
        readonly
        disabled
        :value="topics[row.topicId].subSubTopic"
      />
    </template>
    <template #item-impact="row: RowItem">
      <AtInput
        :modelValue="store.iroImpacts[row.index].impact"
        type="text"
        :placeholder="t('Define your impact...')"
        @update:modelValue="handleImpactChange(row.index, $event)"
      />
    </template>
    <template #item-type="row: RowItem">
      <MlSelect
        :modelValue="store.iroImpacts[row.index].type ?? undefined"
        :disabled="!store.iroImpacts[row.index].impact"
        :options="typeOptions"
        @update:modelValue="handleTypeChange(row.index, $event)"
      />
    </template>
    <template #expand="row: RowItem">
      <div class="mb-4 flex justify-between items-center">
        <div class="flex space-x-2.5">
          <div>
            <button
              type="button"
              aria-label="add rows"
              class="flex text-gray-400 text-xs space-x-2 hover:text-primary"
              @click.prevent="handleAddRowClick(row.index, row.topicId)"
            >
              <PlusIcon class="h-4" />
              {{ t('Add rows') }}
            </button>
          </div>
          <OgDocument
            :userId="currentUser?._id!"
            :file="
              (store.iroImpacts[row.index].document ??
                null) as unknown as UploadedDocument
            "
            @update:file="handleFileChange(row.index, $event)"
          />
          <MlCommentBox
            v-model="store.iroImpacts[row.index].comment"
            iconSize="sm"
            class="text-xs text-gray-400"
          />
        </div>
        <OgSupport />
      </div>
    </template>
  </OgTable>

  <div class="mt-auto flex items-center justify-between">
    <div class="flex space-x-3">
      <AtButton
        type="button"
        variant="outline"
        @click="router.push({ name: 'doubleMaterialityTopicsSelection' })"
      >
        {{ t('Go back') }}
      </AtButton>
      <AtButton
        type="button"
        variant="outline"
        :loading="guidanceDownload.loading.value"
        @click="guidanceDownload.exportFile"
      >
        {{ t('Download guidance') }}
      </AtButton>
    </div>
    <AtButton
      type="button"
      @click="
        router.push({ name: 'doubleMaterialityIROsRisksAndOpportunities' })
      "
    >
      {{ t('Next') }}
    </AtButton>
  </div>
</template>
