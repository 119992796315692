import { computed, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import useUpdateUserMutation from '@/api/mutations/User/updateUser.mutation';
import { apolloClient } from '@/api/apollo/client';
import {
  getActiveEntity,
  setActiveEntity,
} from '@/utils/services/activeEntity';
import { getUserName } from '@/utils/helpers/getUserName';
import switchTranslation from '@/utils/helpers/switchTranslation';
import {
  GetStartedStatusEnum,
  UserRole,
  type UpdateUserInput,
  type UseCurrentUserQuery,
  type UseCurrentUserQueryVariables,
  UserLanguageEnum,
} from '@/__generated__/types';
import USE_CURRENT_USER_QUERY from './useCurrentUser.query';

export const currentUser = ref<UseCurrentUserQuery['getOwnUser']>();
export const authenticatedUser =
  ref<UseCurrentUserQuery['getAuthenticatedUser']>();
export const isGetStartedCompleted = ref(false);
export const getStartedSteps = ref<GetStartedStatusEnum[]>([]);
export const entityAssigmentTotals =
  ref<UseCurrentUserQuery['getTotalsForAssigments']>();

const getGetStartedSteps = (user?: UseCurrentUserQuery['getOwnUser']) => {
  switch (user?.role) {
    case UserRole.SUPERADMIN:
    case UserRole.ADMIN:
      return Object.values(GetStartedStatusEnum).filter((status) => {
        return (
          status !== GetStartedStatusEnum.GOALS &&
          status !== GetStartedStatusEnum.MATERIALITY
        );
      });
    case UserRole.MANAGER:
      return [GetStartedStatusEnum.PROFILE, GetStartedStatusEnum.TEAM];
    default:
      return [GetStartedStatusEnum.PROFILE];
  }
};

const useCurrentUser = () => {
  const { locale } = useI18n();
  const { t } = useI18n();

  const {
    result,
    loading: getUserLoading,
    error: getUserError,
    ...getUserReturn
  } = useQuery<UseCurrentUserQuery, UseCurrentUserQueryVariables>(
    USE_CURRENT_USER_QUERY,
  );

  const {
    mutate: updateUserMutation,
    loading: updateUserLoading,
    error: updateUserError,
  } = useUpdateUserMutation({
    refetchQueries: [{ query: USE_CURRENT_USER_QUERY }],
  });
  const loading = computed(
    () => getUserLoading.value || updateUserLoading.value,
  );
  const error = computed(() => getUserError.value || updateUserError.value);

  const currentUserPictureUrl = computed(
    () => currentUser.value?.picture?.downloadUrl,
  );
  const currentUserName = computed(() => getUserName(currentUser.value));
  const isCodioUser = computed(
    () =>
      authenticatedUser.value?.email.endsWith('@codioimpact.com') &&
      !authenticatedUser.value?.email.includes('+') &&
      !authenticatedUser.value?.email.includes('%'),
  );
  const currentUserHandle = computed(
    () => currentUser.value?.email?.split('@')[0],
  );
  const isAdminOrSuperAdmin = computed(
    () =>
      currentUser.value?.role === UserRole.ADMIN ||
      currentUser.value?.role === UserRole.SUPERADMIN,
  );
  const isAuditor = computed(
    () => currentUser.value?.role === UserRole.AUDITOR,
  );
  const isManager = computed(
    () => currentUser.value?.role === UserRole.MANAGER,
  );
  const isContributor = computed(
    () => currentUser.value?.role === UserRole.CONTRIBUTOR,
  );
  const getTotalsForAssigments = computed(
    () => result.value?.getTotalsForAssigments,
  );
  const activeEntity = computed(() => {
    const id = getActiveEntity();

    return (
      currentUser.value?.entities.find((entity) => entity._id === id) ??
      currentUser.value?.entity
    );
  });
  const assignedSubcategories = computed(() =>
    currentUser.value?.assignments.subcategories.map((sa) => sa.subcategory),
  );
  const getStartedStepsCompleted = computed(
    () => currentUser.value?.getStartedStepsCompleted ?? [],
  );

  watch(result, () => {
    currentUser.value = result.value?.getOwnUser;
    authenticatedUser.value = result.value?.getAuthenticatedUser;
    getStartedSteps.value = getGetStartedSteps(result.value?.getOwnUser);
    isGetStartedCompleted.value = getStartedSteps.value.every(
      (status: GetStartedStatusEnum) =>
        currentUser.value?.getStartedStepsCompleted.find(
          (step) => step === status,
        ),
    );
    entityAssigmentTotals.value = getTotalsForAssigments.value;
  });

  // In case the ID from getActiveEntity isn't set or valid, update it to the valid ID.
  watch(activeEntity, (newActiveEntity) => {
    if (newActiveEntity && getActiveEntity() !== newActiveEntity._id) {
      setActiveEntity(newActiveEntity._id);
    }
  });

  const updateCurrentUser = async (
    updateUserInput: Partial<UpdateUserInput>,
  ) => {
    const isImpersonated = !!window.localStorage.getItem(
      'X-Impersonate-As-Email',
    );
    if (isImpersonated && updateUserInput.language) {
      return switchTranslation(
        locale,
        updateUserInput.language.toUpperCase() as UserLanguageEnum,
      );
    }
    try {
      await updateUserMutation({ updateUserInput });
    } catch {
      notify({
        type: 'error',
        text: t('Something went wrong, try again later :('),
      });
    }
  };

  return {
    ...getUserReturn,
    isCodioUser,
    currentUser,
    authenticatedUser,
    currentUserPictureUrl,
    currentUserName,
    currentUserHandle,
    isAdminOrSuperAdmin,
    isAuditor,
    isManager,
    isContributor,
    activeEntity,
    assignedSubcategories,
    getStartedSteps,
    getStartedStepsCompleted,
    isGetStartedCompleted,
    entityAssigmentTotals,
    loading,
    error,
    updateCurrentUser,
  };
};

export const fetchCurrentUser = async () => {
  try {
    const result = await apolloClient.query<UseCurrentUserQuery>({
      query: USE_CURRENT_USER_QUERY,
    });

    currentUser.value = result.data.getOwnUser;
    authenticatedUser.value = result.data.getAuthenticatedUser;
    getStartedSteps.value = getGetStartedSteps(result.data.getOwnUser);
    isGetStartedCompleted.value = getStartedSteps.value.every(
      (status: GetStartedStatusEnum) =>
        currentUser.value?.getStartedStepsCompleted.find(
          (step) => step === status,
        ),
    );

    return {
      currentUser: result.data.getOwnUser,
      authenticatedUser: result.data.getAuthenticatedUser,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default useCurrentUser;
