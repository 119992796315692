<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, watchEffect, useAttrs, watch, computed } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { TrashIcon } from '@heroicons/vue/outline';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import MlStandardsBadges from '@/components/molecules/MlStandardsBadges.vue';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import stringToHashNumber from '@/utils/helpers/stringToHashNumber';
import { TagColorEnum, UserRole } from '@/__generated__/types';
import useAddDataPointTypeOverrideMutation from '@/api/mutations/EntityLocation/addDataPointTypeOverride.mutation';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlCombobox from '@/components/molecules/MlCombobox.vue';
import type {
  TDataPointTypeOverride,
  TQuestionDataTableItem,
  EntityTag,
} from '../../../types';

const { t } = useI18n();

type Props = {
  question: TQuestionDataTableItem;
  override?: TDataPointTypeOverride;
  entityTags?: EntityTag[];
};
const props = defineProps<Props>();

const tagQuery = ref('');
const tagsFormData = ref<{ name: string; color: TagColorEnum }[]>([]);
const tagOptions = ref<Record<string, string>>({});
const overrideTags = computed(
  () =>
    props.override?.tags?.map((tag) => ({
      name: tag.name,
      color: tag.color,
    })) ?? [],
);

const {
  mutate: addDataPointTypeOverride,
  loading: addDataPointTypeOverrideLoading,
  error: addDataPointTypeOverrideError,
} = useAddDataPointTypeOverrideMutation();

watchEffect(() => {
  tagOptions.value = {};
  for (const tag of props.entityTags ?? []) {
    tagOptions.value[tag.name] = tag.name;
  }
});

const attrs = useAttrs();
const isDisabled = ref<boolean>(attrs.disabled === true);

watch(
  overrideTags,
  () => {
    tagsFormData.value = overrideTags.value;
  },
  { immediate: true },
);

const palette = Object.keys(TagColorEnum);
const getRandomColor = (tagName: string) =>
  palette[stringToHashNumber(tagName ?? '') % palette.length];

function updateTagsFormData(value: string) {
  if (!value.length) {
    return;
  }
  if (tagsFormData.value?.find((tag) => tag.name === value)) {
    return;
  }
  const existingTag = props.entityTags?.find((tag) => tag.name === value);
  if (existingTag) {
    tagsFormData.value.push({
      name: existingTag.name,
      color: existingTag.color,
    });
  } else {
    tagsFormData.value.push({
      name: value,
      color: getRandomColor(value) as TagColorEnum,
    });
  }
  tagOptions.value[value] = value;
  tagQuery.value = '';
}

function removeTag(tagName: string) {
  tagsFormData.value = tagsFormData.value.filter((tag) => tag.name !== tagName);
}

// TODO: find out this "close" function type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleClose(close: any) {
  tagsFormData.value = [];
  close();
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function handleUpsertTags(close: any) {
  try {
    await addDataPointTypeOverride(
      {
        dataPointTypeOverrideInput: {
          dataPointTypeId: props.question.dpr.dataPointType._id,
          tags: tagsFormData.value,
        },
        entityLocationInput: {
          _id: props.question.dpr.location._id,
        },
      },
      {
        update: (store) =>
          store.evict({ fieldName: 'entityLocationSubcategorySummaries' }),
      },
    );

    notify({ text: t('Tags have been updated.'), type: 'success' });
    close();
  } catch (err) {
    if (addDataPointTypeOverrideError.value)
      notify({
        type: 'error',
        text: t(addDataPointTypeOverrideError.value?.message),
      });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
</script>

<template>
  <div @keyup.prevent @click.stop>
    <Popover>
      <PopoverButton class="outline-none">
        <slot name="popoverButton" />
      </PopoverButton>

      <transition
        enterActiveClass="transition duration-200 ease-out"
        enterFromClass="translate-y-1 opacity-0"
        enterToClass="translate-y-0 opacity-100"
        leaveActiveClass="transition duration-150 ease-in"
        leaveFromClass="translate-y-0 opacity-100"
        leaveToClass="translate-y-1 opacity-0"
      >
        <PopoverPanel
          v-slot="{ close }"
          class="bg-white z-20 absolute p-8 rounded shadow-xl w-[25%]"
        >
          <div class="mb-4">
            <p class="text-sm">
              {{ t('System tags') }}
            </p>
            <MlStandardsBadges
              :activeReportingFramework="
                question.dpr.dataPointType.activeReportingFramework
              "
              :reportingFrameworks="
                question.dpr.dataPointType.reportingFrameworks
              "
            />
          </div>

          <div>
            <p class="text-sm">
              {{ t('Custom tags') }}
            </p>
            <div class="grid grid-cols-4 gap-x-3">
              <div class="col-span-4 flex flex-wrap mb-2">
                <Popover v-for="(tag, i) in tagsFormData" :key="i">
                  <PopoverButton class="outline-none">
                    <AtBadge class="mr-2" :type="tag.color">
                      {{ tag.name }}
                    </AtBadge>
                  </PopoverButton>

                  <transition
                    enterActiveClass="transition duration-200 ease-out"
                    enterFromClass="translate-y-1 opacity-0"
                    enterToClass="translate-y-0 opacity-100"
                    leaveActiveClass="transition duration-150 ease-in"
                    leaveFromClass="translate-y-0 opacity-100"
                    leaveToClass="translate-y-1 opacity-0"
                  >
                    <PopoverPanel
                      v-slot="{ close: innerClose }"
                      class="bg-white z-20 absolute p-2 rounded shadow-xl"
                    >
                      <AtInput
                        v-model="tag.name"
                        @keyup.enter="innerClose()"
                        @click.prevent.stop="console.log('prevent propagation')"
                      />
                      <p
                        v-rolefrom="UserRole.ADMIN"
                        class="text-sm items-center flex border-b mx-2 py-3 cursor-pointer hover:text-primary"
                        @click.stop="removeTag(tag.name)"
                      >
                        <TrashIcon class="w-5 align-text-bottom" />
                        <span class="ml-1">
                          {{ t('Delete') }}
                        </span>
                      </p>
                      <div class="p-2">
                        <span class="text-gray-500 font-semibold text-sm">
                          {{ t('Colors') }}
                        </span>
                        <div v-for="color in palette" :key="color">
                          <AtBadge
                            class="cursor-pointer text-sm my-1 w-full"
                            :type="color as TagColorEnum"
                            @click.stop="tag.color = color as TagColorEnum"
                          >
                            {{ t(color) }}
                          </AtBadge>
                        </div>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover>
              </div>
              <div class="col-span-4">
                <span class="text-xs">
                  {{ t('Please select or create a new custom tag') }}
                </span>
                <MlCombobox
                  v-model="tagQuery"
                  class="self-end w-full"
                  :placeholder="t('Type here...')"
                  :options="tagOptions"
                  :disabled="isDisabled"
                  allowCreate
                  @update:modelValue="updateTagsFormData($event)"
                />
              </div>
            </div>
            <div class="flex items-center justify-between col-span-4 mt-6">
              <AtButton variant="outline" @click.stop="handleClose(close)">
                {{ t('Cancel') }}
              </AtButton>
              <AtButton
                :loading="addDataPointTypeOverrideLoading"
                @click.stop="handleUpsertTags(close)"
              >
                {{ t('Save') }}
              </AtButton>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>
