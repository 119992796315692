<script setup lang="ts">
import orderBy from 'lodash/orderBy';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  DoubleMaterialityIroImpactType,
  DoubleMaterialityIroRiskAndOpportunityType,
} from '@/__generated__/types';
import { notEmpty } from '@/utils/helpers/notEmpty';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import type { IroImpact, IroRiskAndOpportunity, Topic } from '../../types';
import {
  calculateIroImpactSurveysRating,
  calculateIroRiskAndOpportunitySurveysRating,
} from '../../utils';
import { useStore } from '../../store';
import OgTable from '../../OgTable.vue';

type RowItem = {
  _id: string;
  iroImpact: IroImpact | null;
  iroRiskAndOpportunity: IroRiskAndOpportunity | null;
  topic: Topic;
  totalScore: number | null;
};

const { t } = useI18n();
const store = useStore();
const { formatNumber } = useFormatNumber();

const headers = computed(() => [
  { text: t('Standard'), value: 'esrs', columnClasses: 'w-[200px]' },
  { text: t('Sustainability matter'), value: 'sustainabilityMatter' },
  { text: t('IRO'), value: 'iro' },
  { text: t('IRO type'), value: 'iroType', columnClasses: 'w-[665px]' },
  { text: t('Total score'), value: 'totalScore', columnClasses: 'w-[100px]' },
]);

const items = computed<RowItem[]>(() => {
  const impacts: RowItem[] = store.value.iroImpacts
    .map((iroImpact) => {
      const topic = store.value.topics.find(
        (item) => item._id === iroImpact.topicId,
      )!;

      if (!topic.topic || !topic.subTopic) {
        return null;
      }

      const totalScore = calculateIroImpactSurveysRating(
        iroImpact._id,
        store.value.surveys,
      );

      return {
        _id: iroImpact._id,
        topic,
        iroImpact,
        iroRiskAndOpportunity: null,
        totalScore,
      };
    })
    .filter((item) => notEmpty(item));

  const risksAndOpportunities: RowItem[] = store.value.iroRisksAndOpportunities
    .map((iroRiskAndOpportunity) => {
      const topic = store.value.topics.find(
        (item) => item._id === iroRiskAndOpportunity.topicId,
      )!;

      if (!topic.topic || !topic.subTopic) {
        return null;
      }

      const totalScore = calculateIroRiskAndOpportunitySurveysRating(
        iroRiskAndOpportunity._id,
        store.value.surveys,
      );

      return {
        _id: iroRiskAndOpportunity._id,
        topic,
        iroImpact: null,
        iroRiskAndOpportunity,
        totalScore,
      };
    })
    .filter((item) => notEmpty(item));

  return orderBy(
    [...impacts, ...risksAndOpportunities],
    [(item: RowItem) => item.topic.esrs],
    ['asc'],
  );
});
</script>

<template>
  <h3 class="text-lg font-medium mb-3">
    {{ t('Stakeholder survey results') }}
  </h3>
  <OgTable :headers="headers" :items="items" :isRowRemovable="false">
    <template #item-esrs="row: RowItem">
      <p>{{ row.topic.esrs }} {{ t(row.topic.topic) }}</p>
    </template>
    <template #item-sustainabilityMatter="row: RowItem">
      <p>
        {{ t(row.topic.subSubTopic) || t(row.topic.subTopic) || '' }}
      </p>
    </template>
    <template #item-iro="row: RowItem">
      <p>
        {{
          row.iroImpact?.impact ??
          row.iroRiskAndOpportunity?.riskAndOpportunity ??
          ''
        }}
      </p>
    </template>
    <template #item-iroType="row: RowItem">
      <p>
        <template
          v-if="
            row.iroImpact?.type ===
            DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE
          "
        >
          {{ t('Actual negative impact') }}
        </template>
        <template
          v-else-if="
            row.iroImpact?.type ===
            DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE
          "
        >
          {{ t('Potential negative impact') }}
        </template>
        <template
          v-else-if="
            row.iroImpact?.type ===
            DoubleMaterialityIroImpactType.ACTUAL_POSITIVE
          "
        >
          {{ t('Actual positive impact') }}
        </template>
        <template
          v-else-if="
            row.iroImpact?.type ===
            DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE
          "
        >
          {{ t('Potential positive impact') }}
        </template>
        <template
          v-else-if="
            row.iroRiskAndOpportunity?.type ===
            DoubleMaterialityIroRiskAndOpportunityType.OPPORTUNITY
          "
        >
          {{ t('Opportunity') }}
        </template>
        <template
          v-else-if="
            row.iroRiskAndOpportunity?.type ===
            DoubleMaterialityIroRiskAndOpportunityType.RISK
          "
        >
          {{ t('Risk') }}
        </template>
        <template v-else>
          {{ t('n/a') }}
        </template>
      </p>
    </template>
    <template #item-totalScore="row: RowItem">
      <p>
        {{
          typeof row.totalScore !== 'number'
            ? t('n/a')
            : formatNumber(row.totalScore, 0, 2)
        }}
      </p>
    </template>
  </OgTable>
</template>
