import { type TmSettingsMaterialityQuery } from '@/__generated__/types';

export const transformToCategorySubcategoryPairs = (
  categoriesWithSubcategories: TmSettingsMaterialityQuery['getCategoriesWithSubcategories'],
) => {
  const subcategories: {
    category: string;
    subcategory: string;
    orderPosition: number;
    topic: string;
  }[] = [];
  categoriesWithSubcategories.forEach((category) => {
    category.subcategories.forEach((subcategory) => {
      subcategories.push({
        category: category.slug,
        subcategory: subcategory.slug,
        orderPosition: subcategory.orderPosition,
        topic: subcategory.topic,
      });
    });
  });
  return subcategories.sort((a, b) => a.orderPosition - b.orderPosition);
};
