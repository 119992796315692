<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useMutationLoading, useQuery } from '@vue/apollo-composable';
import type {
  OgProjectHeaderQuery,
  OgProjectHeaderQueryVariables,
} from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import { useDeleteProject } from '../../common/composables';
import MlProjectsBreadcrumbs from '../../common/MlProjectsBreadcrumbs.vue';
import MlProjectMenu from '../../common/MlProjectMenu.vue';
import OgRenameProject from '../../common/OgRenameProject.vue';
import OgRenameSubcategory from '../../common/OgRenameSubcategory.vue';
import OG_PROJECT_HEADER_QUERY from './OgProjectHeader.query';

type Props = {
  projectId: string;
};

const props = defineProps<Props>();

const route = useRoute();

const { isAdminOrSuperAdmin } = useCurrentUser();
const deleteProject = useDeleteProject();

const isRenamingProject = ref(false);
const isRenamingSubcategory = ref(false);

const queryVariables = computed<OgProjectHeaderQueryVariables>(() => ({
  projectId: props.projectId,
}));

const { result, loading: projectsLoading } = useQuery<
  OgProjectHeaderQuery,
  OgProjectHeaderQueryVariables
>(OG_PROJECT_HEADER_QUERY, queryVariables);
const mutationsLoading = useMutationLoading();

const isLoading = computed(
  () => projectsLoading.value || mutationsLoading.value,
);

const activeProject = computed(() =>
  result.value?.entityLocationSummaries.find(
    (item) => props.projectId === item._id,
  ),
);
const allProjects = computed(() => result.value?.entityLocationSummaries || []);
const categorySlug = computed(() => route.query.category?.toString() || '');
const subcategorySlug = computed(
  () => route.query.subcategory?.toString() || '',
);
const existingProjectsNames = computed(() =>
  allProjects.value.map((project) => project.name),
);
const activeCategory = computed(
  () =>
    result.value?.getCategoriesWithSubcategories.find(
      (category) => category.slug === categorySlug.value,
    ) ?? null,
);
const activeSubcategory = computed(() => {
  const subcategory = activeCategory.value?.subcategories.find(
    (item) => item.slug === subcategorySlug.value,
  );

  if (subcategory && activeCategory.value) {
    return {
      _id: subcategory._id,
      name: subcategory.name,
      categoryId: activeCategory.value._id,
    };
  }

  return null;
});
const canRenameSubcategory = computed(() => {
  return (
    isAdminOrSuperAdmin.value &&
    (!!activeCategory.value?.organization ||
      !!activeCategory.value?.entity ||
      !!activeCategory.value?.location) &&
    !!activeSubcategory.value
  );
});
const existingSubcategoryNames = computed(
  () => activeCategory.value?.subcategories.map((item) => item.name) || [],
);

function handleRenameProjectStart() {
  isRenamingProject.value = true;
}

function handleRenameProjectClose() {
  isRenamingProject.value = false;
}

function handleRenameSubcategoryStart() {
  isRenamingSubcategory.value = true;
}

function handleRenameSubcategoryClose() {
  isRenamingSubcategory.value = false;
}

function handleCopy() {
  navigator.clipboard.writeText(window.location.href);
}

function handleDelete() {
  if (activeProject.value) {
    deleteProject(props.projectId, activeProject.value.name);
  }
}
</script>

<template>
  <MlProjectsBreadcrumbs
    :activeProjectId="projectId"
    :allProjects="allProjects"
    :category="categorySlug"
    :subcategory="subcategorySlug"
  >
    <template v-if="isRenamingProject" #project>
      <OgRenameProject
        v-if="activeProject"
        :project="activeProject"
        :existingProjectsNames="existingProjectsNames"
        @close="handleRenameProjectClose"
      />
    </template>
    <template v-if="isRenamingSubcategory" #subcategory>
      <OgRenameSubcategory
        v-if="activeSubcategory"
        :subcategory="activeSubcategory"
        :existingSubcategoryNames="existingSubcategoryNames"
        @close="handleRenameSubcategoryClose"
      />
    </template>
  </MlProjectsBreadcrumbs>

  <MlProjectMenu
    v-if="!isRenamingProject && !isRenamingSubcategory && activeProject"
    class="pt-2"
    :canDelete="!activeProject.isHeadquarters"
    :isDisabled="isLoading"
    :canRenameSubcategory="canRenameSubcategory"
    @copy="handleCopy"
    @renameProject="handleRenameProjectStart"
    @renameSubcategory="handleRenameSubcategoryStart"
    @delete="handleDelete"
  />
</template>
