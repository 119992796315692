<template>
  <div class="mt-2 border-b border-gray-200 pb-2 font-semibold">
    <a role="button">
      {{ t('Previously Reported Data') }}
    </a>
  </div>

  <div v-if="!isLoading">
    <template v-if="recentlyApprovedDatapoints.length > 0">
      <div
        v-for="datapoint in recentlyApprovedDatapoints"
        :key="datapoint._id"
        class="flex justify-between gap-3 border-b border-gray-200 py-2 text-gray-500"
      >
        <div>
          <AtDataPointRequestDate :from="datapoint.from" :to="datapoint.to" />
        </div>
        <div>
          <MlDataPointRequestValue :dataPointValueAndType="datapoint" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="my-2">
        <a role="button">
          {{ t('Previous data does not exist yet.') }}
        </a>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import sortBy from 'lodash/sortBy';
import { useI18n } from 'vue-i18n';
import MlDataPointRequestValue from '@/components/molecules/MlDataPointValue.vue';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import type {
  DataPointType,
  EntityLocation,
  TmPendingDataPointRequestsRecentlyApprovedQuery,
  TmPendingDataPointRequestsRecentlyApprovedQueryVariables,
} from '@/__generated__/types';
import { RECENTLY_APPROVED_DATAPOINTS } from '@/components/pages/PgApproval/TmPendingDataPointRequests.query';

const props = defineProps<{
  dataPointType: Pick<DataPointType, '_id'>;
  location: Pick<EntityLocation, '_id'>;
}>();

const { t } = useI18n();

const recentlyApprovedQueryVariables = computed(() => ({
  dataPointTypeIds: [props.dataPointType._id],
  locationId: props.location._id,
}));

const { result: recentlyApprovedResults, loading: isLoading } = useQuery<
  TmPendingDataPointRequestsRecentlyApprovedQuery,
  TmPendingDataPointRequestsRecentlyApprovedQueryVariables
>(RECENTLY_APPROVED_DATAPOINTS, recentlyApprovedQueryVariables, {
  fetchPolicy: 'network-only',
});

const recentlyApprovedDatapoints = computed(() =>
  sortBy(
    Array.from(
      recentlyApprovedResults.value?.getDataPointsByTypeAndLocation ?? [],
    ),
    (
      item: TmPendingDataPointRequestsRecentlyApprovedQuery['getDataPointsByTypeAndLocation'][number],
    ) => item.to,
  )
    .reverse()
    .slice(0, 4),
);
</script>
