import { computed, reactive, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import type {
  UseCategoryTranslateQuery,
  UseCategoryTranslateQueryVariables,
} from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import USE_CATEGORY_TRANSLATE_QUERY from './useCategoryTranslate.query';

type TranslationsMap = Record<
  string,
  {
    name: string;
    subcategories: Record<string, string>;
  }
>;

const translationsMap: { values: TranslationsMap } = reactive({
  values: {},
});

export function useCategoryTranslateSetup() {
  const { t, te } = useI18n();
  const { activeEntity } = useCurrentUser();

  const queryVariables = computed<UseCategoryTranslateQueryVariables>(() => ({
    entityId: activeEntity.value?._id ?? '',
  }));
  const { result } = useQuery<
    UseCategoryTranslateQuery,
    UseCategoryTranslateQueryVariables
  >(USE_CATEGORY_TRANSLATE_QUERY, queryVariables);

  const translations = computed(() => {
    const map: TranslationsMap = {};

    result.value?.getAllCategoriesForEntity.forEach((category) => {
      map[category.slug] = {
        name: te(category.slug) ? t(category.slug) : t(category.name),
        subcategories: {},
      };

      category.subcategories.forEach((subcategory) => {
        map[category.slug].subcategories[subcategory.slug] = te(
          subcategory.slug,
        )
          ? t(subcategory.slug)
          : t(subcategory.name);
      });
    });

    return map;
  });

  watch(
    translations,
    (newTranslations) => {
      translationsMap.values = newTranslations;
    },
    { immediate: true },
  );
}

export function useCategoryTranslate() {
  const { t } = useI18n();

  return (category: string | null | undefined, subcategory?: string | null) => {
    if (!category) {
      return t(subcategory ?? '');
    }

    if (!subcategory) {
      return translationsMap.values[category]?.name ?? t(category ?? '');
    }

    return (
      translationsMap.values[category]?.subcategories[subcategory] ??
      t(subcategory)
    );
  };
}
