<script setup lang="ts">
import { notify } from '@kyvg/vue3-notification';
import axios from 'axios';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useAddRepositoryFileMetadata from '@/api/mutations/RepositoryFile/addFileMetadata.mutation';
import useGenerateQuestionnaireFileUploadToken from '@/api/mutations/RepositoryFile/generateQuestionnaireFileUploadToken.mutation';
import MlFilePicker from '@/components/molecules/MlFilePicker/MlFilePicker.vue';
import OgQuestionnaireNuvoImporter from '@/components/pages/PgProjects/PgProject/PgCustomQuestionnaireUpload/OgQuestionnaireNuvoImporter.vue';

type Props = {
  customQuestionnaireId: string;
};

const MAX_FILE_SIZE_IN_MB = 10;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
const ALLOWED_FILE_TYPES = [
  'application/pdf', // .pdf
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
];

const props = defineProps<Props>();

const emit = defineEmits<{
  uploaded: [];
}>();

const { t } = useI18n();

const { mutate: generateFileUploadToken } =
  useGenerateQuestionnaireFileUploadToken();
const { mutate: addFileMetadata } = useAddRepositoryFileMetadata();

const file = ref<File[]>([]);

const isUploading = ref(false);
const uploadProgress = ref(0);
const uploadController = ref(new AbortController());

watch(file, async (newFileValue) => {
  if (newFileValue.length === 1) {
    const fileToUpload = newFileValue[0];

    // Upload file
    isUploading.value = true;

    const repositoryFile = await generateFileUploadToken({
      customQuestionnaireId: props.customQuestionnaireId,
    }).then((result) => result?.data?.generateQuestionnaireFileUploadToken);

    if (repositoryFile) {
      try {
        const uploadResult = await axios.put(
          repositoryFile.preSignedUrl || '',
          await fileToUpload.arrayBuffer(),
          {
            headers: { 'Content-Type': fileToUpload.type },
            onUploadProgress: ({ loaded, total }) => {
              if (total) {
                uploadProgress.value = Math.round((loaded / total) * 100);
              }
            },
            signal: uploadController.value.signal,
          },
        );

        if (uploadResult.status !== 200) {
          return;
        }

        await addFileMetadata({
          repositoryFile: {
            _id: repositoryFile._id,
            filename: fileToUpload.name,
            contentType: fileToUpload.type,
            filesize: fileToUpload.size,
            secretUpdateToken: repositoryFile.secretUpdateToken,
            type: 'questionnaire',
          },
        });

        emit('uploaded');
      } catch (error) {
        if (axios.isAxiosError(error) && error.name !== 'CanceledError') {
          notify({ type: 'error', text: error?.message });
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      } finally {
        isUploading.value = false;
        uploadProgress.value = 0;
      }
    }
  }
});

function handleFileValidation(files: File[]) {
  if (files.some((item) => !ALLOWED_FILE_TYPES.includes(item.type))) {
    return t('Upload failed. Only PDF and XLSX files can be uploaded.');
  }

  if (files.some((item) => item.size > MAX_FILE_SIZE_IN_BYTES)) {
    return t('Upload failed. Maximum allowed file size is {maxSizeInMB} MB.', {
      maxSizeInMB: MAX_FILE_SIZE_IN_MB,
    });
  }

  return null;
}

function handleUploadCancel() {
  uploadController.value.abort();
  uploadController.value = new AbortController();
}
</script>

<template>
  <OgQuestionnaireNuvoImporter
    :customQuestionnaireId="props.customQuestionnaireId"
    @uploaded="emit('uploaded')"
  />
  <MlFilePicker
    v-if="false"
    v-model="file"
    large
    :placeholder="t('Select or drop questionnaire')"
    :description="t('File up to 10 MB')"
    :accept="ALLOWED_FILE_TYPES.join(', ')"
    :validate="handleFileValidation"
    @uploadCancel="handleUploadCancel"
  />
</template>
