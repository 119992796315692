<script setup lang="ts">
import { ref, watchEffect, computed } from 'vue';
import { XIcon } from '@heroicons/vue/outline';
import { useI18n } from 'vue-i18n';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtTopBar from '@/components/atoms/AtTopBar.vue';
import type { Restriction } from '@/__generated__/types';
import { RestrictionsFeaturesEnum } from '@/__generated__/types';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import useUpdateRestrictionMutation from '@/api/mutations/Restriction/updateRestriction.mutation';
import AtCheckbox from '../atoms/AtCheckbox/AtCheckbox.vue';
import AtButton from '../atoms/AtButton/AtButton.vue';

const { t } = useI18n();
type TProps = {
  shown: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  shown: false,
});
const emit = defineEmits(['close']);

const { restrictions, loading: loadingRestrictions } = useRestrictions();

const { mutate: updateRestriction, loading: updateRestrictionMutationLoading } =
  useUpdateRestrictionMutation();
const restrictedFeatures = computed(
  () => restrictions.value?.restrictedFeatures ?? [],
);
const numberOfQuestionnaires = computed(
  () => restrictions.value?.numberOfQuestionnaires ?? 0,
);
const restrictionsForm = ref<
  Pick<Restriction, 'restrictedFeatures' | 'numberOfQuestionnaires'>
>({
  restrictedFeatures: [],
  numberOfQuestionnaires: 0,
});

watchEffect(() => {
  restrictionsForm.value.restrictedFeatures = restrictedFeatures.value;
  restrictionsForm.value.numberOfQuestionnaires = numberOfQuestionnaires.value;
});

async function handleSubmit() {
  try {
    updateRestriction({ restrictionInput: restrictionsForm.value });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
</script>

<template>
  <AtTopBar :isShown="props.shown" class="bg-rose-400">
    <div>
      <form @submit.prevent="handleSubmit">
        <AtCheckbox
          :checked="
            restrictionsForm.restrictedFeatures.includes(
              RestrictionsFeaturesEnum.TAXONOMY,
            )
          "
          label="Taxonomy restriction enabled"
          :disabled="loadingRestrictions"
          @toggleCheckbox="
            restrictionsForm.restrictedFeatures.includes(
              RestrictionsFeaturesEnum.TAXONOMY,
            )
              ? (restrictionsForm.restrictedFeatures =
                  restrictionsForm.restrictedFeatures.filter(
                    (feature) => feature !== RestrictionsFeaturesEnum.TAXONOMY,
                  ))
              : (restrictionsForm.restrictedFeatures = [
                  ...restrictionsForm.restrictedFeatures,
                  RestrictionsFeaturesEnum.TAXONOMY,
                ])
          "
        />
        <AtCheckbox
          :checked="
            restrictionsForm.restrictedFeatures.includes(
              RestrictionsFeaturesEnum.CALCULATORS,
            )
          "
          label="Calculators restriction enabled"
          :disabled="loadingRestrictions"
          @toggleCheckbox="
            restrictionsForm.restrictedFeatures.includes(
              RestrictionsFeaturesEnum.CALCULATORS,
            )
              ? (restrictionsForm.restrictedFeatures =
                  restrictionsForm.restrictedFeatures.filter(
                    (feature) =>
                      feature !== RestrictionsFeaturesEnum.CALCULATORS,
                  ))
              : (restrictionsForm.restrictedFeatures = [
                  ...restrictionsForm.restrictedFeatures,
                  RestrictionsFeaturesEnum.CALCULATORS,
                ])
          "
        />
        <AtCheckbox
          :checked="
            restrictionsForm.restrictedFeatures.includes(
              RestrictionsFeaturesEnum.DOUBLE_MATERIALITY,
            )
          "
          label="DMA restriction enabled"
          :disabled="loadingRestrictions"
          @toggleCheckbox="
            restrictionsForm.restrictedFeatures.includes(
              RestrictionsFeaturesEnum.DOUBLE_MATERIALITY,
            )
              ? (restrictionsForm.restrictedFeatures =
                  restrictionsForm.restrictedFeatures.filter(
                    (feature) =>
                      feature !== RestrictionsFeaturesEnum.DOUBLE_MATERIALITY,
                  ))
              : (restrictionsForm.restrictedFeatures = [
                  ...restrictionsForm.restrictedFeatures,
                  RestrictionsFeaturesEnum.DOUBLE_MATERIALITY,
                ])
          "
        />
        <AtInput
          v-model="restrictionsForm.numberOfQuestionnaires"
          type="number"
          :disabled="loadingRestrictions"
          :label="t('Number of questionnaires')"
        />
        <AtButton
          class="mt-2"
          :loading="updateRestrictionMutationLoading"
          @click.prevent="handleSubmit"
        >
          {{ t('Save') }}
        </AtButton>
      </form>
    </div>

    <div class="flex flex-rowd">
      <XIcon class="ml-6 h-6 w-6 cursor-pointer" @click="emit('close')" />
    </div>
  </AtTopBar>
</template>
