<script setup lang="ts">
import { computed } from 'vue';
import {
  type PgExternalTaxonomyAssessmentAnswerQuery,
  TaxonomyBusinessActivityDelegationTypeEnum,
  type UpdateExternallyTaxonomyAssessmentBusinessActivityInput,
} from '@/__generated__/types';
import OgSubstantialContribution from './OgSubstantialContribution.vue';
import OgDoNoSignificantHarm from './OgDoNoSignificantHarm.vue';
import OgMinimumSafeguards from './OgMinimumSafeguards.vue';

const props = defineProps<{
  isSaving: boolean;
  data: PgExternalTaxonomyAssessmentAnswerQuery['getTaxonomyBusinessActivityDelegation'];
}>();

const emit = defineEmits<{
  save: [input: UpdateExternallyTaxonomyAssessmentBusinessActivityInput];
}>();

const isSubstantialContribution = computed(
  () =>
    props.data.type ===
    TaxonomyBusinessActivityDelegationTypeEnum.SubstantialContribution,
);
const isDoNoSignificantHarm = computed(() =>
  [
    TaxonomyBusinessActivityDelegationTypeEnum.DoesNotHarmAdaptation,
    TaxonomyBusinessActivityDelegationTypeEnum.DoesNotHarmBiodiversity,
    TaxonomyBusinessActivityDelegationTypeEnum.DoesNotHarmCircular,
    TaxonomyBusinessActivityDelegationTypeEnum.DoesNotHarmPollution,
    TaxonomyBusinessActivityDelegationTypeEnum.DoesNotHarmMitigation,
    TaxonomyBusinessActivityDelegationTypeEnum.DoesNotHarmWater,
  ].includes(props.data.type),
);
const isMinimumSafeguards = computed(
  () =>
    props.data.type ===
    TaxonomyBusinessActivityDelegationTypeEnum.MinimumStandards,
);

function handleSave(
  input: UpdateExternallyTaxonomyAssessmentBusinessActivityInput,
) {
  emit('save', input);
}
</script>

<template>
  <OgSubstantialContribution
    v-if="isSubstantialContribution"
    :userId="data.userId"
    :isSaving="isSaving"
    :projectName="data.projectName"
    :activityName="data.activityName"
    :objective="data.objective!"
    :objectiveDescription="data.objectiveDescription!"
    @save="handleSave"
  />
  <OgDoNoSignificantHarm
    v-else-if="isDoNoSignificantHarm"
    :userId="data.userId"
    :isSaving="isSaving"
    :projectName="data.projectName"
    :activityName="data.activityName"
    :objective="data.objective!"
    :objectiveDescription="data.objectiveDescription!"
    @save="handleSave"
  />
  <OgMinimumSafeguards
    v-else-if="isMinimumSafeguards"
    :userId="data.userId"
    :isSaving="isSaving"
    :projectName="data.projectName"
    :activityName="data.activityName"
    :objective="data.objective!"
    :objectiveDescription="data.objectiveDescription!"
    @save="handleSave"
  />
</template>
