import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UnsuspendDataPointTypeByEntityMutation,
  UnsuspendDataPointTypeByEntityMutationVariables,
} from '@/__generated__/types';

export const UNSUSPENDED_DATAPOINT_TYPE_BY_ENTITY = gql`
  mutation UnsuspendDataPointTypeByEntity($dataPointTypeId: String!) {
    unsuspendDataPointTypeByEntity(dataPointTypeId: $dataPointTypeId) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  UnsuspendDataPointTypeByEntityMutation,
  UnsuspendDataPointTypeByEntityMutationVariables
>;

const useUnsuspendDataPointTypeByEntityMutation = (options?: TOptions) =>
  useMutation<
    UnsuspendDataPointTypeByEntityMutation,
    UnsuspendDataPointTypeByEntityMutationVariables
  >(UNSUSPENDED_DATAPOINT_TYPE_BY_ENTITY, options);

export default useUnsuspendDataPointTypeByEntityMutation;
