<script setup lang="ts">
import { computed, inject, onBeforeUnmount, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { notify } from '@kyvg/vue3-notification';
import { MinusIcon, PlusIcon } from '@heroicons/vue/solid';
import { TrashIcon } from '@heroicons/vue/outline';
import { useRoute } from 'vue-router';
import debounce from 'lodash/debounce';
import {
  DataPointRequestStatusEnum,
  type MlQuestionFormQuery,
  type MlQuestionFormQueryVariables,
  UserRole,
  ValueDataTypeEnum,
} from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import OgPdfS3FilePicker from '@/components/molecules/MlFilePicker/OgPdfS3FilePicker.vue';
import useAnswerDataPointRequestMutation from '@/api/mutations/DataPointRequest/answerDataPointRequest.mutation';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import useUnapproveDataPointRequestMutation from '@/api/mutations/DataPointRequest/unapproveDataPointRequest.mutation';
import useRequestReopenDataPointRequestMutation from '@/api/mutations/DataPointRequest/requestReopenDataPointRequest.mutation';
import AtInfoBox from '@/components/atoms/AtInfoBox/AtInfoBox.vue';
import { InfoBoxType } from '@/components/atoms/AtInfoBox/types';
import type { TElement as TElementEmissionSplitInput } from '@/components/atoms/EmissionSplitInput.vue';
import type { TElement as TElementSplitInput } from '@/components/atoms/SplitInput.vue';
import AtLoader from '@/components/atoms/AtLoader/AtLoader.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import useDeleteDataPointRequestMutation from '@/api/mutations/DataPointRequest/deleteDataPointRequest.mutation';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import useGenerateFileDownloadTokenMutation from '@/api/mutations/RepositoryFile/generateFileDownloadToken.mutation';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import type {
  TDataPointRequest,
  TFile,
  TDataPointTypeOverride,
  DataPointRequestWithValueSourceNames,
} from '../../../types';
import TM_DATA_ENTRY_QUERY from '../TmDataEntry.query';
import MlDataPointRequestInput from './MlQuestionInput.vue';
import ML_QUESTION_FORM_QUERY from './MlQuestionForm.query';

// IMPORTANT: each change on this component must be copied or adapted in MlExternalQuestionForm

const props = defineProps<{
  dataPointRequest: TDataPointRequest;
  dataPointRequestsWithValueSourceNames: DataPointRequestWithValueSourceNames[];
  isGroup: boolean;
  canBeDeleted?: boolean;
}>();

const route = useRoute();
const dprValueChanged = ref(false);
const { isAdminOrSuperAdmin } = useCurrentUser();

const emit = defineEmits<{
  (e: 'closeCollapse'): void;
}>();

const { t } = useI18n();

const suggestionFormularVariables = computed<MlQuestionFormQueryVariables>(
  () => ({
    id: props.dataPointRequest._id,
  }),
);
const { result: suggestionFormularResult } = useQuery<
  MlQuestionFormQuery,
  MlQuestionFormQueryVariables
>(ML_QUESTION_FORM_QUERY, suggestionFormularVariables, {
  fetchPolicy: 'network-only',
});

const {
  mutate: submitDPR,
  loading,
  error: answerDataPointRequestError,
} = useAnswerDataPointRequestMutation();
const {
  mutate: unapproveMutation,
  error: unapproveError,
  loading: unapproveLoading,
} = useUnapproveDataPointRequestMutation();
const {
  mutate: requestReopenDataPointRequestMutation,
  error: requestReopenDataPointRequestError,
  loading: requestReopenDataPointRequestLoading,
} = useRequestReopenDataPointRequestMutation();

const {
  mutate: deleteDataPointRequest,
  loading: deleteDataPointRequestLoading,
} = useDeleteDataPointRequestMutation({
  refetchQueries: [
    {
      query: TM_DATA_ENTRY_QUERY,
      variables: {
        categoryFilter: route.query.category,
        subcategoryFilter: route.query.subcategory,
        locationId: route.params.project,
      },
    },
  ],
});

const { confirmViaDialog } = useConfirmViaDialog();

const showDeleteDprDialog = async (
  dprId: string,
  interval: { from: string; to: string },
) => {
  const isConfirmed = await confirmViaDialog({
    title: t('Delete interval for this datapoint type'),
    text: t('Do you wish to delete the interval of {interval}?', {
      interval: getDPRDateString(interval.from, interval.to),
    }),
    confirmLabel: t('Delete'),
    cancelLabel: t('Cancel'),
    confirmButtonVariant: 'destructive',
  });

  if (isConfirmed) await deleteDataPointRequest({ dataPointRequestId: dprId });
};

const dpt = computed(() => props.dataPointRequest.dataPointType);
const isUpload = computed(
  () => dpt.value.valueDataType === ValueDataTypeEnum.UPLOAD,
);
const wasPreviouslySubmitted = computed(
  () =>
    !!props.dataPointRequest.file ||
    !!props.dataPointRequest.value ||
    (props.dataPointRequest.valueSource &&
      props.dataPointRequest.valueSource.length > 0 &&
      props.dataPointRequest.dataPointType.valueDataType ===
        ValueDataTypeEnum.TEXT),
);
const isAccepted = computed(
  () => props.dataPointRequest.status === DataPointRequestStatusEnum.ACCEPTED,
);

const isEditable = computed(() =>
  [
    DataPointRequestStatusEnum.OPEN,
    DataPointRequestStatusEnum.PENDING,
    DataPointRequestStatusEnum.REJECTED,
    DataPointRequestStatusEnum.OVERDUE,
    DataPointRequestStatusEnum.DRAFT,
  ].includes(props.dataPointRequest.status),
);

const isProofAllowed = computed(
  () => dpt.value.allowUploadProof && !isUpload.value,
);
const dataPointTypeOverrides = inject(
  'dataPointTypeOverrides',
  computed(() => [] as TDataPointTypeOverride[]),
);
const override = computed(() =>
  dataPointTypeOverrides.value.find(
    (_override) =>
      _override.datapointtype._id === props.dataPointRequest.dataPointType._id,
  ),
);
const isOverrideSplit = computed(() => override.value?.isSplit);

const dprFile = ref<TFile | undefined | null>(props.dataPointRequest.file);
const dprValue = ref(
  props.dataPointRequest.originalValue ??
    props.dataPointRequest.value ??
    suggestionFormularResult.value?.getDataPointRequest
      ?.suggestionFormularResult?.result ??
    null,
);

watch(suggestionFormularResult, (newSuggestionFormularResult) => {
  if (dprValueChanged.value) return;

  const newDprValue =
    props.dataPointRequest.originalValue ??
    props.dataPointRequest.value ??
    newSuggestionFormularResult?.getDataPointRequest?.suggestionFormularResult
      ?.result ??
    null;

  dprValue.value = dprValue.value ?? newDprValue;
});

const dprComment = ref(props.dataPointRequest.comment ?? '');

const isSubmitDisabled = computed(() => {
  const { valueDataType } = props.dataPointRequest.dataPointType;
  const { UPLOAD, NUMERIC, NUMERIC_SPLIT, EMISSIONS_SPLIT, TEXT_SPLIT } =
    ValueDataTypeEnum;

  const isFileInput = valueDataType === UPLOAD;
  const isOverrideNumericSplitInput =
    valueDataType === NUMERIC && isOverrideSplit.value;
  const isNumericSplitInput = valueDataType === NUMERIC_SPLIT;
  const isEmissionSplitInput = valueDataType === EMISSIONS_SPLIT;
  const isTextSplitInput = valueDataType === TEXT_SPLIT;

  const isEmpty =
    !isFileInput &&
    (Array.isArray(dprValue.value)
      ? !dprValue.value.length
      : !dprValue.value && dprValue.value !== 0) &&
    !props.dataPointRequest.valueSource?.length;

  const isFileEmpty = isFileInput && !dprFile.value;

  const isInvalid =
    !isOverrideNumericSplitInput &&
    !!v.value.$silentErrors.length &&
    !['tooLow', 'tooHigh'].includes(v.value.$silentErrors[0].$validator);

  let isEmptyHTMLText = false;
  if (typeof dprValue.value === 'string') {
    isEmptyHTMLText = !dprValue.value.replace(/<\/?[^>]+(>|$)/g, '').trim();
  }

  let hasIncompleteInput = false;
  if (Array.isArray(dprValue.value) && !!dprValue.value.length) {
    if (isEmissionSplitInput) {
      hasIncompleteInput = (
        dprValue.value as TElementEmissionSplitInput[]
      ).some(
        (dpr) =>
          !dpr.name ||
          dpr.value === null ||
          dpr.emissionFactor === undefined ||
          dpr.emissionFactor === null,
      );
    }
    if (isNumericSplitInput || isOverrideNumericSplitInput) {
      hasIncompleteInput = (dprValue.value as TElementSplitInput[]).some(
        (dpr) => !dpr.name || dpr.value === null,
      );
    }
    if (isTextSplitInput) {
      hasIncompleteInput = (dprValue.value as TElementSplitInput[]).some(
        (dpr) => !dpr.name || !dpr.value,
      );
    }
  }

  return (
    isEmpty || isFileEmpty || isInvalid || isEmptyHTMLText || hasIncompleteInput
  );
});

const hasMultipleDelegations = computed(
  () =>
    !!(
      props.dataPointRequest.delegations &&
      props.dataPointRequest.delegations?.length >= 2
    ),
);

const overrideInputType = computed(() => {
  const isAllowedValueDataType =
    props.dataPointRequest.dataPointType.valueDataType ===
      ValueDataTypeEnum.NUMERIC ||
    props.dataPointRequest.dataPointType.valueDataType ===
      ValueDataTypeEnum.TEXT;

  const isOpenStatus =
    props.dataPointRequest.status === DataPointRequestStatusEnum.OPEN;
  const hasValueSource = !!props.dataPointRequest.valueSource?.length;

  const hasMultipleValueSources =
    props.dataPointRequest.valueSource &&
    props.dataPointRequest.valueSource?.length >= 2;

  const shouldUseSplit =
    (isOverrideSplit.value && isOpenStatus && isAllowedValueDataType) ||
    (hasValueSource &&
      isAllowedValueDataType &&
      !hasMultipleDelegations.value) ||
    (hasValueSource &&
      isAllowedValueDataType &&
      hasMultipleDelegations.value &&
      hasMultipleValueSources); // show all values for users having access to all valuesources

  if (
    shouldUseSplit &&
    props.dataPointRequest.dataPointType.valueDataType ===
      ValueDataTypeEnum.NUMERIC
  ) {
    return ValueDataTypeEnum.NUMERIC_SPLIT;
  }
  if (
    shouldUseSplit &&
    props.dataPointRequest.dataPointType.valueDataType ===
      ValueDataTypeEnum.TEXT
  ) {
    return ValueDataTypeEnum.TEXT_SPLIT;
  }

  return props.dataPointRequest.dataPointType.valueDataType;
});

const dataPointTypeValueUnit = computed(
  () =>
    props.dataPointRequest.originalValueUnit ??
    override.value?.valueUnit ??
    props.dataPointRequest.dataPointType.valueUnit ??
    undefined,
);

const trimmedDprComment = computed(() =>
  dprComment.value.replace(/<\/?[^>]+(>|$)/g, '').trim(),
);

async function unapproveDataPointRequest(dataPointRequestId: string) {
  try {
    const response = await unapproveMutation({
      unapproveDataPointRequestInput: { dataPointRequestId },
    });
    const isSingleDPR =
      !response?.data?.unapproveDataPointRequest.childs.length;
    if (isSingleDPR && !props.isGroup) emit('closeCollapse');
    notify({ type: 'success', text: t('Your answer has been unapproved.') });
  } catch (err) {
    if (unapproveError.value)
      notify({ type: 'error', text: t(unapproveError.value?.message) });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

async function requestReopenDataPointRequest(dataPointRequestId: string) {
  try {
    const response = await requestReopenDataPointRequestMutation({
      reopenRequestedDataPointRequestInput: { dataPointRequestId },
    });
    const isSingleDPR =
      !response?.data?.requestReopenDataPointRequest.childs.length;
    if (isSingleDPR && !props.isGroup) emit('closeCollapse');
    notify({
      type: 'success',
      text: t('Your request to reopen the question has been sent.'),
    });
  } catch (err) {
    if (requestReopenDataPointRequestError.value)
      notify({
        type: 'error',
        text: t(requestReopenDataPointRequestError.value?.message),
      });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
const isMultiDelegationEditMode = computed(
  () =>
    props.dataPointRequest.delegations &&
    props.dataPointRequest.delegations.length >= 2 &&
    props.dataPointRequest.dataPointType.valueDataType ===
      ValueDataTypeEnum.TEXT_LONG &&
    props.dataPointRequest.valueSource?.length &&
    isAdminOrSuperAdmin,
);

async function handleSubmitDPR(dpr: TDataPointRequest, draft = false) {
  try {
    const response = await submitDPR({
      answerDataPointRequestDto: {
        ...(draft ? { isDraft: true } : {}),
        dataPointRequestId: dpr._id,
        value: dprValue.value,
        comment: trimmedDprComment.value.length
          ? dprComment.value
          : trimmedDprComment.value,
        fileId: dprFile.value?._id,
        ...(dataPointTypeValueUnit.value
          ? {
              valueUnit: dataPointTypeValueUnit.value,
            }
          : {}),
        ...(overrideInputType.value === ValueDataTypeEnum.NUMERIC_SPLIT ||
        overrideInputType.value === ValueDataTypeEnum.TEXT_SPLIT ||
        dpr.dataPointType.valueDataType === ValueDataTypeEnum.NUMERIC_SPLIT ||
        dpr.dataPointType.valueDataType === ValueDataTypeEnum.TEXT_SPLIT ||
        dpr.dataPointType.valueDataType === ValueDataTypeEnum.EMISSIONS_SPLIT ||
        isMultiDelegationEditMode.value
          ? {
              value: undefined,
              valueSource: !Array.isArray(dprValue.value)
                ? undefined
                : dprValue.value,
            }
          : {}),
      },
    });

    if (draft) {
      notify({
        type: 'success',
        text: t('Your answer has been saved as draft.'),
      });
    } else {
      const isSingleDPR = !response?.data?.answerDataPointRequest.childs.length;
      if (isSingleDPR && !props.isGroup) emit('closeCollapse');
      notify({ type: 'success', text: t('Your answer has been submitted.') });
      dprValueChanged.value = false;
    }
  } catch (err) {
    if (answerDataPointRequestError.value)
      notify({
        type: 'error',
        text: t(answerDataPointRequestError.value?.message),
      });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

const handleInputEnter = () => {
  if (isAccepted.value) {
    return unapproveDataPointRequest(props.dataPointRequest._id);
  }
  if (!isAccepted.value) {
    return handleSubmitDPR(props.dataPointRequest);
  }
  if (isAccepted.value && !props.dataPointRequest.isReopenRequestPending) {
    return requestReopenDataPointRequest(props.dataPointRequest._id);
  }
};

const v = useVuelidate();

const dprUpdateWarningShown = computed(() => {
  return (
    dprValueChanged.value &&
    props.dataPointRequest.status !== DataPointRequestStatusEnum.OPEN &&
    props.dataPointRequest.childs?.some(
      (child) => child.status !== DataPointRequestStatusEnum.OPEN,
    )
  );
});
watch(dprValue, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    dprValueChanged.value = true;
  }
});

const isSubmitImmediate = computed(
  () =>
    ValueDataTypeEnum.CHOICE ===
    props.dataPointRequest.dataPointType.valueDataType,
);

const commentBoxOpenItem = inject('commentBoxOpenItem', ref(''));
const filePickerBoxOpenItem = inject('filePickerBoxOpenItem', ref(''));

function toggleCommentBox(dprId: string) {
  if (commentBoxOpenItem.value === dprId) {
    commentBoxOpenItem.value = '';
    return;
  }
  commentBoxOpenItem.value = dprId;
  filePickerBoxOpenItem.value = '';
}

function toggleFilePicker(dprId: string) {
  if (filePickerBoxOpenItem.value === dprId) {
    filePickerBoxOpenItem.value = '';
    return;
  }
  filePickerBoxOpenItem.value = dprId;
  commentBoxOpenItem.value = '';
}

const isFilePickerShown = computed(
  () => props.dataPointRequest._id === filePickerBoxOpenItem.value,
);
const isCommentBoxOpen = computed(
  () => props.dataPointRequest._id === commentBoxOpenItem.value,
);

const hasSplitInput = computed(
  () =>
    [
      ValueDataTypeEnum.EMISSIONS_SPLIT,
      ValueDataTypeEnum.NUMERIC_SPLIT,
      ValueDataTypeEnum.TEXT_SPLIT,
    ].includes(props.dataPointRequest.dataPointType.valueDataType) ||
    [
      ValueDataTypeEnum.EMISSIONS_SPLIT,
      ValueDataTypeEnum.NUMERIC_SPLIT,
      ValueDataTypeEnum.TEXT_SPLIT,
    ].includes(overrideInputType.value) ||
    isOverrideSplit.value,
);

const { mutate: generateFileDownloadURLMutation } =
  useGenerateFileDownloadTokenMutation();
const downloadFileAction = async (fileId: string) => {
  const url = await generateFileDownloadURLMutation({
    repositoryFileId: fileId,
  });

  window.open(url?.data?.generateFileDownloadToken);
};

const handleSubmitDraftDPRDebounced = debounce(() => {
  if (!isSubmitImmediate.value && dprValueChanged.value && isEditable.value) {
    handleSubmitDPR(props.dataPointRequest, true);
  }
}, 10000);
watch(dprValue, handleSubmitDraftDPRDebounced);

onBeforeUnmount(() => {
  handleSubmitDraftDPRDebounced.cancel();

  if (!isSubmitImmediate.value && dprValueChanged.value && isEditable.value) {
    handleSubmitDPR(props.dataPointRequest, true);
  }
});
</script>

<template>
  <form
    class="flex flex-col h-full items-start justify-between pl-9 pr-3 gap-4 pb-3"
  >
    <AtInfoBox v-if="dprUpdateWarningShown" :type="InfoBoxType.Warning">
      {{
        t(
          "By clicking 'Update', your follow-up questions will change. Any responses you've entered in conditional fields will be removed.",
        )
      }}
    </AtInfoBox>
    <div class="flex w-full items-center">
      <MlDataPointRequestInput
        v-model="dprValue"
        v-model:file="dprFile"
        class="!w-full"
        :dataPointRequest="dataPointRequest"
        :disabled="!isEditable || loading"
        :valueUnit="dataPointTypeValueUnit"
        :valueUnitDivisor="dpt.valueUnitDivisor ?? undefined"
        :override="override"
        :onEnter="handleInputEnter"
        :overrideInputType="overrideInputType"
        :dataPointRequestsWithValueSourceNames="
          dataPointRequestsWithValueSourceNames
        "
        :suggestionFormular="suggestionFormularResult?.getDataPointRequest"
        @update:modelValue="
          isSubmitImmediate ? handleSubmitDPR(dataPointRequest) : false
        "
      />
      <AtLoader v-if="isSubmitImmediate && loading" class="ml-2" size="sm" />
    </div>
    <div class="flex gap-x-4">
      <div
        v-if="hasSplitInput"
        :id="`actionAddRow_${dataPointRequest._id}`"
        class="shrink-0"
      />
      <div
        v-if="!isAccepted && isProofAllowed && !hasMultipleDelegations"
        class="shrink-0"
      >
        <button
          aria-label="add document"
          class="flex text-gray-400 text-xs hover:text-primary items-center"
          type="button"
          @click.prevent.stop="toggleFilePicker(dataPointRequest._id)"
        >
          <MinusIcon v-if="isFilePickerShown" class="h-4 mr-2" />
          <PlusIcon v-else class="h-4 mr-2" />
          {{ t('Add document') }}
        </button>
      </div>
      <button
        v-if="!isAccepted && !hasMultipleDelegations"
        aria-label="add comment"
        type="button"
        class="flex cursor-pointer items-center text-gray-400 hover:text-primary"
        :class="{ 'pointer-events-none': isSubmitDisabled }"
        @click.prevent="toggleCommentBox(dataPointRequest._id)"
      >
        <PlusIcon
          v-if="!isCommentBoxOpen"
          :class="{ 'text-gray-200': isSubmitDisabled }"
          class="w-4"
        />
        <MinusIcon
          v-else
          :class="{ 'text-gray-200': isSubmitDisabled }"
          class="w-4"
        />
        <span
          class="ml-2 text-xs whitespace-nowrap"
          :class="{ 'text-gray-200': isSubmitDisabled }"
        >
          {{ t('Add comment') }}
        </span>
      </button>
    </div>
    <OgPdfS3FilePicker
      v-if="isFilePickerShown"
      v-model="dprFile"
      v-model:uploading="loading"
      class="row-span-2 max-w-xs"
      :placeholder="t('Select or drop proof document')"
      showRecentFiles
      compact
    />
    <div v-show="isCommentBoxOpen">
      <p class="text-xs text-gray-700">
        <i18n-t
          keypath="Leave a comment for explanation that is going to be shown on the {homeResults} page."
        >
          <template #homeResults>
            <router-link
              :to="{ name: 'homeResults' }"
              variant="virgin"
              class="text-primary underline"
            >
              {{ t('Results') }}
            </router-link>
          </template>
        </i18n-t>
      </p>
      <MlTextarea v-model="dprComment" :placeholder="t('Enter a comment.')" />
    </div>

    <AtInfoBox v-if="isAccepted && dataPointRequest.isReopenRequestPending">
      <template #default>
        <p class="text-xs text-gray-700">
          <i18n-t
            keypath="Your request to reopen this datapoint has been forwarded to the appropriate approver. {resendReopenRequest}"
          >
            <template #resendReopenRequest>
              <AtButton
                variant="link"
                class="text-primary underline"
                @click.prevent="
                  requestReopenDataPointRequest(dataPointRequest._id)
                "
              >
                {{ t('Click here to resend it.') }}
              </AtButton>
            </template>
          </i18n-t>
        </p>
      </template>
    </AtInfoBox>

    <span v-if="isAccepted && dprComment" class="flex text-xs">
      <span>{{ '*' }}</span>
      <MlHtmlContent
        class="col-auto line-clamp-4 hover:line-clamp-none"
        :html="dprComment"
      />
    </span>

    <span
      v-if="dprFile"
      class="flex text-xs underline cursor-pointer"
      @click="downloadFileAction(dprFile._id)"
    >
      {{ dprFile.filename }}
    </span>

    <AtButton
      v-if="
        !isAccepted &&
        ValueDataTypeEnum.CHOICE !==
          dataPointRequest.dataPointType.valueDataType
      "
      class="row-span-2 ml-auto -mt-2"
      :class="{ '': !isGroup }"
      :disabled="isSubmitDisabled"
      :loading="loading"
      data-cy="MlQuestionForm-submitButton"
      @click.prevent="handleSubmitDPR(dataPointRequest)"
    >
      <template
        v-if="
          props.dataPointRequest.status === DataPointRequestStatusEnum.DRAFT
        "
      >
        {{ t('Submit draft') }}
      </template>
      <template v-else>
        {{ wasPreviouslySubmitted ? t('Update') : t('Submit') }}
      </template>
    </AtButton>
    <AtButton
      v-if="isAccepted"
      v-rolefrom="UserRole.MANAGER"
      class="row-span-2 ml-auto -mt-6"
      :class="{ '': !isGroup }"
      :loading="unapproveLoading"
      :error="!!unapproveError"
      @click.prevent="unapproveDataPointRequest(dataPointRequest._id)"
    >
      {{ t('Undo Approval') }}
    </AtButton>
    <AtButton
      v-if="isAccepted && !dataPointRequest.isReopenRequestPending"
      v-role="[UserRole.CONTRIBUTOR]"
      class="row-span-2 ml-auto -mt-6"
      :class="{ '': !isGroup }"
      :loading="requestReopenDataPointRequestLoading"
      :error="!!requestReopenDataPointRequestError"
      @click.prevent="requestReopenDataPointRequest(dataPointRequest._id)"
    >
      {{ t('Request to reopen') }}
    </AtButton>
    <AtIconButton
      v-if="canBeDeleted"
      v-rolefrom="UserRole.MANAGER"
      :icon="TrashIcon"
      class="w-7 text-gray-400 order-5 ml-auto"
      :title="t('Delete request')"
      :disabled="deleteDataPointRequestLoading"
      @click.prevent.stop="
        showDeleteDprDialog(dataPointRequest._id, {
          from: dataPointRequest.from,
          to: dataPointRequest.to,
        })
      "
    />
  </form>
</template>
