<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import {
  calculateSeverityForImpact,
  calculateTotalScoreForImpact,
  calculateTotalScoreForRiskAndOpportunity,
} from '../../utils';
import { useStore } from '../../store';
import OgChart, { type DataItem } from './OgChart.vue';

const { t } = useI18n();
const store = useStore();

const data = computed<DataItem[]>(() => {
  const impactTotalScorePerTopic = mapValues(
    groupBy(store.value.iroImpacts, (iroImpact) => iroImpact.topicId),
    (iroImpacts) => {
      return Math.max(
        ...iroImpacts.map((iroImpact) => {
          return (
            calculateTotalScoreForImpact(
              calculateSeverityForImpact(
                iroImpact.scale,
                iroImpact.scope,
                iroImpact.irremediability,
                iroImpact.type,
              ),
              iroImpact.likelihood,
              iroImpact.type,
            ) ?? 0
          );
        }),
      );
    },
  );

  const riskAndOpportunityTotalScorePerTopic = mapValues(
    groupBy(
      store.value.iroRisksAndOpportunities,
      (iroRiskAndOpportunity) => iroRiskAndOpportunity.topicId,
    ),
    (iroRisksAndOpportunities) => {
      return Math.max(
        ...iroRisksAndOpportunities.map((iroRiskAndOpportunity) => {
          return (
            calculateTotalScoreForRiskAndOpportunity(
              iroRiskAndOpportunity.likelihood,
              iroRiskAndOpportunity.potentialMagnitude,
            ) ?? 0
          );
        }),
      );
    },
  );

  const result: DataItem[] = [];
  Object.keys(impactTotalScorePerTopic).forEach((key) => {
    if (
      impactTotalScorePerTopic[key] &&
      riskAndOpportunityTotalScorePerTopic[key]
    ) {
      const topic = store.value.topics.find((item) => item._id === key)!;

      result.push({
        label: t(topic.subSubTopic) || t(topic.subTopic) || '',
        x: impactTotalScorePerTopic[key],
        y: riskAndOpportunityTotalScorePerTopic[key],
      });
    }
  });

  return result;
});
</script>

<template>
  <OgChart type="doubleMateriality" :data="data" />
</template>
