import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  RequestHelpForExternalTaxonomyMutation,
  RequestHelpForExternalTaxonomyMutationVariables,
} from '@/__generated__/types';

const REQUEST_HELP_FOR_EXTERNAL_TAXONOMY_MUTATION = gql`
  mutation requestHelpForExternalTaxonomy(
    $email: String!
    $shareToken: String!
    $message: String!
    $name: String!
    $phone: String!
  ) {
    requestHelpForExternalTaxonomy(
      email: $email
      shareToken: $shareToken
      message: $message
      name: $name
      phone: $phone
    )
  }
`;

type TOptions = UseMutationOptions<
  RequestHelpForExternalTaxonomyMutation,
  RequestHelpForExternalTaxonomyMutationVariables
>;

const useRequestHelpForExternalTaxonomy = (options?: TOptions) =>
  useMutation<
    RequestHelpForExternalTaxonomyMutation,
    RequestHelpForExternalTaxonomyMutationVariables
  >(REQUEST_HELP_FOR_EXTERNAL_TAXONOMY_MUTATION, options);

export default useRequestHelpForExternalTaxonomy;
