import tailwindColors from 'tailwindcss/colors';

export const colors = {
  primary: {
    DEFAULT: tailwindColors.blue['600'],
    text: tailwindColors.white,
    dark: tailwindColors.blue['800'],
    light: tailwindColors.blue['200'],
  },
  white: {
    text: tailwindColors.white,
    DEFAULT: tailwindColors.white,
    dark: tailwindColors.white,
    light: tailwindColors.white,
  },
  gray: {
    50: tailwindColors.gray['50'],
    100: tailwindColors.gray['100'],
    200: tailwindColors.gray['200'],
    400: tailwindColors.gray['400'],
    500: tailwindColors.gray['500'],
    700: tailwindColors.gray['700'],
    900: tailwindColors.gray['900'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.gray['400'],
    dark: tailwindColors.gray['600'],
    light: tailwindColors.gray['200'],
  },
  blue: {
    50: tailwindColors.blue['50'],
    500: tailwindColors.blue['500'],
    600: tailwindColors.blue['600'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.blue['600'],
    dark: tailwindColors.blue['800'],
    light: tailwindColors.blue['200'],
  },
  orange: {
    50: tailwindColors.orange['50'],
    400: tailwindColors.orange['400'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.orange['400'],
    dark: tailwindColors.orange['400'],
    light: tailwindColors.orange['50'],
  },
  rose: {
    50: tailwindColors.rose['50'],
    400: tailwindColors.rose['400'],
    600: tailwindColors.rose['600'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.rose['600'],
    dark: tailwindColors.rose['800'],
    light: tailwindColors.rose['400'],
  },
  teal: {
    50: tailwindColors.teal['50'],
    400: tailwindColors.teal['400'],
    600: tailwindColors.teal['600'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.teal['600'],
    dark: tailwindColors.teal['600'],
    light: tailwindColors.teal['50'],
  },
  violet: {
    50: tailwindColors.violet['50'],
    500: tailwindColors.violet['500'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.violet['500'],
    dark: tailwindColors.violet['500'],
    light: tailwindColors.violet['50'],
  },
  emerald: {
    50: tailwindColors.emerald['50'],
    500: tailwindColors.emerald['500'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.emerald['500'],
    dark: tailwindColors.emerald['500'],
    light: tailwindColors.emerald['50'],
  },
  amber: {
    50: tailwindColors.amber['50'],
    500: tailwindColors.amber['500'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.amber['500'],
    dark: tailwindColors.amber['500'],
    light: tailwindColors.amber['50'],
  },
  indigo: {
    50: tailwindColors.indigo['50'],
    500: tailwindColors.indigo['500'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.indigo['500'],
    dark: tailwindColors.indigo['500'],
    light: tailwindColors.indigo['50'],
  },
  yellow: {
    50: '#f4e9d8',
    700: tailwindColors.yellow['700'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.yellow['700'],
    dark: tailwindColors.yellow['700'],
    light: tailwindColors.yellow['50'],
  },
  stone: {
    50: tailwindColors.stone['50'],
    400: tailwindColors.stone['400'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.stone['400'],
    dark: tailwindColors.stone['400'],
    light: tailwindColors.stone['50'],
  },
  environment: {
    DEFAULT: '#467787',
    // needed for buttons
    text: '#467787',
    dark: '#467787',
    light: '#467787',
  },
  social: {
    DEFAULT: '#208BA8',
    // needed for buttons
    text: '#208BA8',
    dark: '#208BA8',
    light: '#208BA8',
  },
  governance: {
    DEFAULT: '#B3A556',
    // needed for buttons
    text: '#B3A556',
    dark: '#B3A556',
    light: '#B3A556',
  },
  general_disclosures: {
    DEFAULT: '#7B9A9A',
    // needed for buttons
    text: '#7B9A9A',
    dark: '#7B9A9A',
    light: '#7B9A9A',
  },
  error: {
    // same as rose!
    50: tailwindColors.rose['50'],
    400: tailwindColors.rose['400'],
    600: tailwindColors.rose['600'],
    // needed for buttons
    text: tailwindColors.white,
    DEFAULT: tailwindColors.rose['600'],
    dark: tailwindColors.rose['800'],
    light: tailwindColors.rose['400'],
  },
} as const;

export const avatarColors = {
  gray: colors.gray,
  blue: colors.blue,
  orange: colors.orange,
  rose: colors.rose,
  teal: colors.teal,
  violet: colors.violet,
  emerald: colors.emerald,
  indigo: colors.indigo,
  yellow: colors.yellow,
};

export const daisyColors = {
  primary: colors.blue['600'],
  dark: colors.blue['600'],
  info: colors.blue['600'],
  success: colors.emerald['500'],
  error: colors.rose['600'],
  warning: colors.amber['500'],
};

export const chartColors = [
  [colors.blue['50'], colors.blue['500']],
  [colors.orange['50'], colors.orange['400']],
  [colors.rose['50'], colors.rose['400']],
  [colors.teal['50'], colors.teal['600']],
  [colors.emerald['50'], colors.emerald['500']],
  [colors.amber['50'], colors.amber['500']],
  [colors.violet['50'], colors.violet['500']],
  [colors.indigo['50'], colors.indigo['500']],
  [colors.yellow['50'], colors.yellow['700']],
  [colors.stone['50'], colors.stone['400']],
];
