import { gql } from '@apollo/client/core';

const USE_PIN_CHART_QUERY = gql`
  query UsePinChart {
    getOwnUser {
      _id
      pinnedDataPointTypes {
        _id
        entity {
          _id
        }
        dataPointType {
          _id
        }
      }
    }
  }
`;

export default USE_PIN_CHART_QUERY;
