import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  RequestHelpMutation,
  RequestHelpMutationVariables,
} from '@/__generated__/types';

const REQUEST_HELP_MUTATION = gql`
  mutation requestHelp(
    $message: String!
    $phoneOrEmail: String!
    $type: HelpTypeEnum!
  ) {
    requestHelp(message: $message, phoneOrEmail: $phoneOrEmail, type: $type)
  }
`;

type TOptions = UseMutationOptions<
  RequestHelpMutation,
  RequestHelpMutationVariables
>;

const useRequestHelp = (options?: TOptions) =>
  useMutation<RequestHelpMutation, RequestHelpMutationVariables>(
    REQUEST_HELP_MUTATION,
    options,
  );

export default useRequestHelp;
