<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import groupBy from 'lodash/groupBy';
import { ReportingFrameworkEnum } from '@/__generated__/types';
import ESGGoal from '@/components/atoms/ESGGoal.vue';
import type { TGoalCommon } from '../../../types';

const props = defineProps<{
  modelValue?: TGoalCommon;
  goals: TGoalCommon[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', goal?: TGoalCommon): void;
}>();

const { t } = useI18n();

const activeGroup = ref<ReportingFrameworkEnum | null>(null);
const activeGroupOptions = computed(() => [
  {
    group: ReportingFrameworkEnum.GRI,
    label: t('Sustainable Development Goals (SDGs)'),
  },
  {
    group: ReportingFrameworkEnum.ESRS,
    label: t('European Sustainability Reporting Standards (CSRD/ ESRS)'),
  },
]);

const goalGroups = computed(() =>
  groupBy(props.goals, (goal) => goal.reportingFramework),
);

function handleActiveGroupClick(value: ReportingFrameworkEnum) {
  activeGroup.value = value;
}
</script>

<template>
  <p class="mt-2 mb-4 text-sm">
    {{
      t(
        'First select the framework, and then a specific goal you wish to contribute to.',
      )
    }}
  </p>
  <div class="flex space-x-6 items-center mb-8">
    <div
      v-for="option in activeGroupOptions"
      :key="option.group"
      class="px-1 py-2 text-center text-sm w-60 shadow-[0_0_14px_0_rgba(0,0,0,0.25)] cursor-pointer rounded-lg hover:bg-gray-50 hover:text-blue-600"
      :class="{
        'bg-gray-50 text-blue-600': option.group === activeGroup,
        'bg-white': option.group !== activeGroup,
      }"
      @click="handleActiveGroupClick(option.group)"
    >
      {{ option.label }}
    </div>
  </div>
  <div
    v-if="activeGroup && goalGroups[activeGroup]"
    class="flex flex-wrap gap-4"
  >
    <button
      v-for="goal in goalGroups[activeGroup]"
      :key="goal._id"
      type="submit"
      :class="{
        'opacity-50': modelValue && goal._id !== modelValue?._id,
        'drop-shadow-xl': modelValue && goal._id === modelValue?._id,
      }"
      @click="
        emit(
          'update:modelValue',
          goal._id === modelValue?._id ? undefined : goal,
        )
      "
    >
      <ESGGoal :size="24" :goal="goal" />
    </button>
  </div>
</template>
