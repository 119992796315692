<script setup lang="ts">
import { type RouteRecordRaw, useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import getRouteRoleRequirements from '@/utils/helpers/getRouteRoleRequirements';
// import type { EntityLocation } from '@/__generated__/types';
import MlSubPageNavTabs from './MlSubPageNavTabs.vue';
import { SubNavVariant } from './types';

type Props = {
  parentRouteName?: string;
  // locations?: Partial<EntityLocation>[]
  variant?: `${SubNavVariant}`;
};
const emit = defineEmits<TEmits>();
const props = withDefaults(defineProps<Props>(), {
  parentRouteName: undefined,
  variant: 'default',
  locations: undefined,
});
type TEmits = {
  (e: 'itemClick'): void;
};

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const transformChild = (record: RouteRecordRaw) => ({
  ...record,
  roles: getRouteRoleRequirements(router, record.path),
  label: record.meta?.label ?? '',
  value: String(record?.name),
});
type TChild = ReturnType<typeof transformChild>;

const children = computed(
  () =>
    (props.parentRouteName
      ? router
          .getRoutes()
          .find((_route) => _route.name === props.parentRouteName)
          ?.children.filter(filterByIsShown)
          .map(transformChild)
      : router.currentRoute.value.matched[0]?.children
          .filter(filterByIsShown)
          .map(transformChild)) ?? [],
);

const filterByIsShown = (record: RouteRecordRaw) =>
  !record.meta?.isShown || record.meta?.isShown();
</script>

<template>
  <MlSubPageNavTabs
    v-if="
      children.length
      // || props.locations?.length
    "
    :items="children"
    :variant="props.variant"
  >
    <!-- <template #default>
      <nav
        v-if="props.locations"
        class="flex max-h-[360px] flex-col overflow-y-auto"
      >
        <router-link
          v-for="location in props.locations"
          :key="location._id"
          class="p-2 font-normal hover:text-primary"
          activeClass="bg-blue-50 text-primary"
          :to="{
            name: 'projectsProjectDataEntry',
            params: {
              project: location._id,
            },
          }"
        >
          {{ location.name }}
        </router-link>
      </nav>
    </template> -->
    <template #tab="{ item, activeClass }">
      <router-link
        v-rolefrom="
          //@ts-ignore
          item.meta?.roleFrom
        "
        v-role="
          //@ts-ignore
          item.meta?.roles
        "
        :to="{ name: item.value }"
        class="block rounded-md p-2 font-normal text-gray-900 hover:text-primary"
        :class="{
          'rounded-none': props.variant === 'floating',
        }"
        :data-cy="`router-link-${item.value}`"
        :activeClass="activeClass"
        :ariaExpanded="false"
        @click="emit('itemClick')"
      >
        <span class="break-words">
          {{ t((item as TChild).meta?.label ?? '') }}
          <sup>
            {{ (item as TChild).meta?.labelSuperscript }}
          </sup>
        </span>
      </router-link>

      <MlSubPageNavTabs
        v-if="
          item.children &&
          route.meta?.category === (item as TChild).meta?.category
        "
        class="w-auto border-none !py-0 px-3"
        :items="
          (item as TChild).children
            ?.filter(filterByIsShown)
            .map(transformChild) ?? []
        "
      >
        <template #tab="{ item: subItem }">
          <router-link
            v-rolefrom="
              //@ts-ignore
              subItem.meta?.roleFrom
            "
            v-role="
              //@ts-ignore
              subItem.meta?.roles
            "
            :to="{ name: subItem.value }"
            class="block rounded-md p-2 text-gray-900 hover:text-primary"
            :data-cy="`router-link-${subItem.value}`"
            :activeClass="activeClass"
            :ariaExpanded="false"
            @click="emit('itemClick')"
          >
            <span class="break-words">
              {{ t((subItem as TChild).meta?.label ?? '') }}
            </span>
          </router-link>
        </template>
      </MlSubPageNavTabs>
    </template>
  </MlSubPageNavTabs>
</template>
