<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { DownloadIcon, EyeIcon } from '@heroicons/vue/outline';
import {
  UserLanguageEnum,
  type TmLibrarySourcesQuery,
} from '@/__generated__/types';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import fileImg from '@/assets/file.svg';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import OgDataTableControls from '@/components/organisms/OgDataTable/OgDataTableControls.vue';
import dayjs from '@/lib/dayjs/config';
import useGenerateFileDownloadTokenMutation from '@/api/mutations/RepositoryFile/generateFileDownloadToken.mutation';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import TM_LIBRARY_SOURCES_QUERY from './TmLibrarySources.query';
import { useSourceDocumentDownload } from './composables/useSourceDocumentDownload';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const { result, loading: queryLoading } = useQuery<TmLibrarySourcesQuery>(
  TM_LIBRARY_SOURCES_QUERY,
  { fetchPolicy: 'network-only' },
);

const headers = computed(() => [
  { text: t('Document'), value: 'itemName', sortable: true, filterable: true },
  {
    text: t('Language'),
    value: 'itemLanguage',
    sortable: true,
    filterable: true,
  },
  {
    text: t('Category'),
    value: 'itemCategory',
    sortable: true,
    filterable: true,
  },
  { text: t('Module'), value: 'itemModule', sortable: true, filterable: true },
  { text: t('Date'), value: 'itemDate', sortable: true, filterable: true },
  { text: '', value: 'action' },
]);

const sourceFiles = computed(
  () => result.value?.getRepositorySourceFiles ?? [],
);
const sourceFileOpened = ref<(typeof sourceFiles.value)[number]>();
const sourceFileOpenedDownloadToken = ref('');
const { exportFile, loading } = useSourceDocumentDownload();

const items = computed(() =>
  sourceFiles.value.map((file) => {
    return {
      itemName: file.name,
      itemFileName: file.filename,
      itemLanguage: file.language,
      itemModule: file.module,
      itemCategory: file.category,
      itemPath: file.filepath,
      itemDate: file.date,
      onDownloadClick: () => {
        return exportFile(file.filename, file.filepath ?? '');
      },
      onOpenClick: async () => {
        return openSourceFile(file._id);
      },
    };
  }),
);
const filteredAndSortedItems = ref(items.value);
watch(items, () => {
  filteredAndSortedItems.value = items.value;
});

function reset() {
  sourceFileOpened.value = undefined;
  sourceFileOpenedDownloadToken.value = '';
}

watch(
  () => route.query.sourceFileId,
  (newSourceFileId) => {
    if (newSourceFileId?.toString()) {
      sourceFileOpened.value = sourceFiles.value.find(
        (sourceFile) => sourceFile._id === newSourceFileId?.toString(),
      );
    } else {
      sourceFileOpened.value = undefined;
    }
    if (!route.query.sourceFileId?.toString()) return;
    openSourceFile(sourceFileOpened.value?._id ?? '');
  },
);

onMounted(() => {
  sourceFileOpened.value = sourceFiles.value.find(
    (sourceFile) => sourceFile._id === route.query.sourceFileId?.toString(),
  );
  if (!route.query.sourceFileId?.toString()) return;
  openSourceFile(sourceFileOpened.value?._id ?? '');
});

const { mutate: generateFileDownloadURLMutation } =
  useGenerateFileDownloadTokenMutation();
async function openSourceFile(fileId: string) {
  sourceFileOpenedDownloadToken.value = '';
  const mutationResult = await generateFileDownloadURLMutation({
    repositoryFileId: fileId,
    repositoryFileType: 'source',
  });
  router.push({ name: 'librarySources', query: { sourceFileId: fileId } });
  sourceFileOpenedDownloadToken.value =
    mutationResult?.data?.generateFileDownloadToken ?? '';
}
</script>

<template>
  <div>
    <AtProgress
      v-if="sourceFileOpened && !sourceFileOpenedDownloadToken"
      foreground
    />
    <template v-if="!queryLoading">
      <MlEmptyStateCard
        v-if="!items.length"
        :title="t('No files yet')"
        :description="
          t('Start by entering data and uploading proof documents.')
        "
        :buttonText="t('Enter data')"
        @buttonClick="router.push({ name: 'projects' })"
      >
        <img class="mb-7" :src="fileImg" alt="Graph" />
      </MlEmptyStateCard>

      <div v-else-if="sourceFileOpened">
        <div
          v-if="sourceFileOpened"
          class="flex w-full col-span-1 justify-end my-4"
        >
          <AtButton
            class="ml-auto"
            variant="outline"
            @click.stop="
              router.push({ name: 'librarySources' });
              reset();
            "
          >
            {{ t('Go back') }}
          </AtButton>
          <AtButton
            class="ml-2"
            :loading="loading"
            @click.stop="
              items.find(
                (item) => item.itemFileName === sourceFileOpened?.filename,
              )?.onDownloadClick
            "
          >
            {{ t('Download source document') }}
          </AtButton>
        </div>
        <div v-if="sourceFileOpenedDownloadToken" class="w-full flex">
          <iframe
            ref="iframeRef"
            :title="sourceFileOpened.name ?? ''"
            class="w-full h-[75dvh]"
            :src="sourceFileOpenedDownloadToken"
          />
        </div>
      </div>

      <template v-else>
        <Teleport to="#libraryLayoutControls">
          <div class="flex items-end">
            <OgDataTableControls
              v-if="items.length > 0"
              v-model="filteredAndSortedItems"
              :headers="headers"
              :items="items"
            />
          </div>
        </Teleport>
        <OgDataTable
          :headers="headers"
          controlsHidden
          variant="dark-gray"
          wrapperClass="!py-0"
          :items="filteredAndSortedItems"
        >
          <template #item-itemName="{ itemName, onOpenClick }">
            <button class="hover:text-primary" @click.stop="onOpenClick()">
              {{ itemName }}
            </button>
          </template>
          <template #item-itemLanguage="{ itemLanguage }">
            {{
              itemLanguage === UserLanguageEnum.EN ? t('English') : t('German')
            }}
          </template>
          <template #item-itemCategory="{ itemCategory }">
            {{ t(itemCategory) }}
          </template>
          <template #item-itemModule="{ itemModule }">
            {{ t(itemModule) }}
          </template>
          <template #item-itemDate="{ itemDate }">
            {{ dayjs(itemDate).format('YYYY-MM-DD') }}
          </template>
          <template #item-action="{ onDownloadClick, onOpenClick }">
            <div class="text-gray-700 flex gap-x-4">
              <button
                :title="t('Download')"
                class="h-5 w-5 hover:text-primary hover:scale-105 cursor-pointer"
                @click.stop="onDownloadClick()"
              >
                <DownloadIcon class="h-5 w-5" />
              </button>
              <button
                :title="t('Open')"
                class="h-5 w-5 hover:text-primary hover:scale-105 cursor-pointer"
                @click.stop="onOpenClick()"
              >
                <EyeIcon class="h-5 w-5" />
              </button>
            </div>
          </template>
        </OgDataTable>
      </template>
    </template>
  </div>
</template>
