import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DeleteCustomQuestionnaireQuestionMutation,
  DeleteCustomQuestionnaireQuestionMutationVariables,
} from '@/__generated__/types';

export const DELETE_QUESTION = gql`
  mutation DeleteCustomQuestionnaireQuestion(
    $customQuestionnaireId: String!
    $customQuestionnaireQuestionId: String!
  ) {
    deleteCustomQuestionnaireQuestion(
      customQuestionnaireId: $customQuestionnaireId
      customQuestionnaireQuestionId: $customQuestionnaireQuestionId
    )
  }
`;

type TOptions = UseMutationOptions<
  DeleteCustomQuestionnaireQuestionMutation,
  DeleteCustomQuestionnaireQuestionMutationVariables
>;

const useDeleteCustomQuestionnaireQuestionMutation = (options?: TOptions) =>
  useMutation<
    DeleteCustomQuestionnaireQuestionMutation,
    DeleteCustomQuestionnaireQuestionMutationVariables
  >(DELETE_QUESTION, options);

export default useDeleteCustomQuestionnaireQuestionMutation;
