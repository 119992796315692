import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdateEntityMutation,
  UpdateEntityMutationVariables,
} from '@/__generated__/types';

export const UPDATE_ENTITY = gql`
  mutation UpdateEntity($entityInput: EntityInput!) {
    updateEntity(entityInput: $entityInput) {
      _id
      name
    }
  }
`;

type TOptions = UseMutationOptions<
  UpdateEntityMutation,
  UpdateEntityMutationVariables
>;

const useUpdateEntityMutation = (options?: TOptions) =>
  useMutation<UpdateEntityMutation, UpdateEntityMutationVariables>(
    UPDATE_ENTITY,
    options,
  );

export default useUpdateEntityMutation;
