import { gql } from '@apollo/client/core';

export default gql`
  query AiSummaryQuery(
    $from: String!
    $to: String!
    $dataPointType: String!
    $locations: [String!]!
  ) {
    generateDataPointSummary(
      from: $from
      to: $to
      dataPointType: $dataPointType
      locations: $locations
    )
  }
`;
