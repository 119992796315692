import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdateUserSubcategoryAssignmentsMutation,
  UpdateUserSubcategoryAssignmentsMutationVariables,
} from '@/__generated__/types';

export const UPDATE_USER_SUBCATEGORY_ASSIGNMENTS = gql`
  mutation updateUserSubcategoryAssignments(
    $subcategoryAssignments: UserUpdateSubcategoryAssignmentsInput!
  ) {
    updateSubcategoryAssignments(
      subcategoryAssignments: $subcategoryAssignments
    )
  }
`;

type TOptions = UseMutationOptions<
  UpdateUserSubcategoryAssignmentsMutation,
  UpdateUserSubcategoryAssignmentsMutationVariables
>;

const useUpdateUserSubcategoryAssignmentsMutation = (options?: TOptions) =>
  useMutation<
    UpdateUserSubcategoryAssignmentsMutation,
    UpdateUserSubcategoryAssignmentsMutationVariables
  >(UPDATE_USER_SUBCATEGORY_ASSIGNMENTS, options);

export default useUpdateUserSubcategoryAssignmentsMutation;
