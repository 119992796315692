<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import OgActionPanel from '@/components/organisms/OgActionPanel/OgActionPanel.vue';
import {
  type PgTaxonomyAssessmentFinaliseQuery,
  type PgTaxonomyAssessmentFinaliseQueryVariables,
} from '@/__generated__/types';
import AtFinancialValuesWarning from '../../AtFinancialValuesWarning.vue';
import OgProjectTable from '../../PgResults/OgProjectTable.vue';
import PG_TAXONOMY_ASSESSMENT_FINALISE_QUERY from './PgTaxonomyAssessmentFinalise.query';

const props = defineProps<{
  projectId: string;
}>();

const emit = defineEmits<{
  close: [];
}>();

const { t } = useI18n();
const router = useRouter();

const { result } = useQuery<
  PgTaxonomyAssessmentFinaliseQuery,
  PgTaxonomyAssessmentFinaliseQueryVariables
>(PG_TAXONOMY_ASSESSMENT_FINALISE_QUERY, { _id: String(props.projectId) });

const project = computed(() => result.value?.getTaxonomyProject);

const showWarning = computed(() =>
  project.value?.assessments.some((assessment) => {
    return (
      assessment.turnoverCompleteLater ||
      assessment.capexCompleteLater ||
      assessment.opexCompleteLater
    );
  }),
);

function handleClose() {
  emit('close');
}
</script>

<template>
  <OgActionPanel
    v-if="project?.assessments"
    isOpened
    hasPaddingX
    is4xlPanel
    @closePanel="handleClose"
  >
    <template #title>
      {{
        t(
          'You have completed the Taxonomy calculation. According to the information provided, here is your overview:',
        )
      }}
    </template>
    <div class="flex-1 mt-6">
      <AtFinancialValuesWarning
        v-if="showWarning"
        class="mb-8"
        type="assessment"
      />
      <OgProjectTable :project="project" />
    </div>
    <div class="flex justify-end gap-4">
      <AtButton
        variant="outline"
        @click="router.push({ name: 'taxonomyOverview' })"
      >
        {{ t('Go back to Overview') }}
      </AtButton>
      <AtButton @click="handleClose">
        {{ t('Close') }}
      </AtButton>
    </div>

    <!-- <div class="">
      <AtButton
        :loading="rejectLoading"
        :error="!!rejectError"
        @click="withHandler(rejectMutation, selectedDataPointRequest!._id)"
      >
        {{ t('Download as PDF') }}
      </AtButton>
      <AtButton
        class="ml-4"
        data-cy="approveDataPointRequest"
        :loading="approveLoading"
        :error="!!approveError"
        @click="isResultPanelOpen = false"
      >
        {{ t('Close') }}
      </AtButton>
    </div> -->
  </OgActionPanel>
</template>
