import type { ServerResponse } from 'http';
import { SentryLink } from 'apollo-link-sentry';
import * as Sentry from '@sentry/vue';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { router } from '@/router';
import { getActiveEntityHeaders } from '@/utils/services/activeEntity';

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_API_URL,
});

const sentryLink = new SentryLink({
  uri: import.meta.env.VITE_API_URL,
  attachBreadcrumbs: {
    includeError: true,
    includeQuery: true,
    includeVariables: true,
  },
});

const graphAuthMiddleware = setContext((operation, previousContext) => ({
  ...previousContext,
  headers: {
    ...previousContext.headers,
    Authorization: `Bearer ${localStorage.getItem('apiKey')}`,
    ...(localStorage.getItem('X-Impersonate-As-Email')
      ? {
          'X-Impersonate-As-Email': localStorage.getItem(
            'X-Impersonate-As-Email',
          ),
        }
      : {}),
    ...getActiveEntityHeaders(),
  },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      Sentry.captureMessage(error.message, {
        level: 'error',
        contexts: {
          apolloGraphQLError: {
            error,
            message: error.message,
            extensions: error.extensions,
          },
        },
      });
    });
  }

  if (networkError) {
    Sentry.captureMessage(networkError.message, {
      level: 'error',
      contexts: {
        apolloNetworkError: {
          error: networkError,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          extensions: (networkError as any)?.extensions,
        },
      },
    });
  }
  if (
    (graphQLErrors?.[0].extensions.originalError as ServerResponse)
      ?.statusCode === 401
  ) {
    router.push('/logout');
  }
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, graphAuthMiddleware, sentryLink, httpLink]),
  cache,
});
