<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { TButtons } from '@/components/organisms/types';
// import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import type {
  SearchBy,
  ServerSortOptions,
  TServerDataTableHeader,
  TDataTableSort,
  TDataTableItem,
  FilterBy,
  TDataTableFilter,
} from './types';
import MlFilterMenu from './MlServerFilterMenu.vue';
import MlSortMenu from './MlSortMenu.vue';

type Props = {
  searchBy: SearchBy;
  filterBy: FilterBy;
  sortOptions: ServerSortOptions;
  headers: TServerDataTableHeader[];
  hideSort?: boolean;
  hideFilters?: boolean;
  itemsSelected?: TDataTableItem[];
  buttons?: TButtons<TDataTableItem[]>;
};

const props = withDefaults(defineProps<Props>(), {
  hideSort: false,
  hideFilters: false,
  itemsSelected: undefined,
  buttons: undefined,
});

const emit = defineEmits<{
  'update:sortOptions': [value: ServerSortOptions];
  'update:searchBy': [value: SearchBy];
  'update:filterBy': [value: FilterBy];
}>();

const { t } = useI18n();

const sort = computed<TDataTableSort>(() => ({
  by: props.sortOptions.sortBy || '',
  type: props.sortOptions.sortType || '',
}));
const filters = computed<TDataTableFilter[]>(() =>
  props.filterBy.map((item) => ({
    field: item.field,
    criteria: item.criteria,
  })),
);

const itemsSelectedCount = computed(() => props.itemsSelected?.length ?? 0);
const itemsSelectedCountText = computed(
  () =>
    `${itemsSelectedCount.value} ${itemsSelectedCount.value === 1 ? t('item selected') : t('items selected')}`,
);

// Search is disabled for now.
// function handleSearchByChange(event: string) {
//   emit('update:searchBy', event);
// }

function handleSortChange(event: TDataTableSort) {
  emit('update:sortOptions', {
    sortBy: event.by || undefined,
    sortType: event.type || undefined,
  });
}

function handleFiltersChange(event: TDataTableFilter[]) {
  emit(
    'update:filterBy',
    event.map((item) => ({
      field: item.field,
      criteria: item.criteria,
    })),
  );
}
</script>

<template>
  <div class="flex justify-between">
    <div class="flex items-center gap-4 w-full">
      <div
        v-if="itemsSelectedCount > 0 && buttons"
        class="flex gap-x-2 ml-auto items-center"
      >
        <p v-if="itemsSelectedCount > 0" class="text-sm">
          {{ itemsSelectedCountText }}
        </p>
        <template v-if="buttons?.secondary">
          <AtButton
            v-for="(button, idx) in Array.isArray(buttons?.secondary)
              ? buttons?.secondary
              : [buttons?.secondary]"
            :key="idx"
            class="text-destructive pr-0"
            type="submit"
            :icon="button.icon"
            variant="text"
            :disabled="button.disabled"
            :loading="button.loading"
            @click="button.onClick(itemsSelected)"
          >
            {{ button.text }}
          </AtButton>
        </template>
        <template v-if="buttons?.primary">
          <AtButton
            v-for="(button, idx) in Array.isArray(buttons?.primary)
              ? buttons?.primary
              : [buttons?.primary]"
            :key="idx"
            variant="text"
            class="pr-0"
            :icon="button.icon"
            type="submit"
            :disabled="button.disabled"
            :loading="button.loading"
            @click="button.onClick(itemsSelected)"
          >
            {{ button.text }}
          </AtButton>
        </template>
        <template v-if="buttons?.tertiary">
          <AtButton
            v-for="(button, idx) in Array.isArray(buttons?.tertiary)
              ? buttons?.tertiary
              : [buttons?.tertiary]"
            :key="idx"
            class="text-primary pr-0"
            type="submit"
            :icon="button.icon"
            variant="text"
            :disabled="button.disabled"
            :loading="button.loading"
            @click="button.onClick(itemsSelected)"
          >
            {{ button.text }}
          </AtButton>
        </template>
      </div>
      <MlSortMenu
        v-if="!hideSort"
        :modelValue="sort"
        :headers="headers"
        @update:modelValue="handleSortChange"
      />
      <MlFilterMenu
        v-if="!hideFilters"
        :modelValue="filters"
        :headers="headers"
        @update:modelValue="handleFiltersChange"
      />
    </div>
  </div>
</template>

<style scoped lang="postcss"></style>
