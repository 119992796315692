import { gql } from '@apollo/client/core';

export default gql`
  query PgTaxonomyResults {
    getTaxonomyProjects {
      _id
      name

      assessments {
        _id

        status

        turnover
        turnoverCompleteLater
        capex
        capexCompleteLater
        opex
        opexCompleteLater
        alignedTurnover
        eligibleTurnover
        nonEligibleTurnover

        alignedCapex
        eligibleCapex
        nonEligibleCapex

        alignedOpex
        eligibleOpex
        nonEligibleOpex

        businessActivities {
          _id

          activity {
            _id
            number
            name
          }

          activityTurnover
          activityOpex
          activityCapex

          activityFit

          alignedTurnover
          eligibleTurnover

          alignedCapex
          eligibleCapex

          alignedOpex
          eligibleOpex

          minimumStandardsRespected
        }
      }
    }
  }
`;
