import {
  ReportingFrameworkEnum,
  type UseCurrentUserQuery,
  ValueDataTypeEnum,
} from '@/__generated__/types';
import { CreateDatapointTypeReportingFrameworkEnum } from '../TmCustomDataPointTypeModal/types';
import type { Framework } from './types';

type Entity = Pick<
  UseCurrentUserQuery['getOwnUser']['entity'],
  'reportingFrameworks'
>;

function getPrimaryReportingFrameworkName(entity: Entity) {
  if (entity.reportingFrameworks.length === 0) {
    return ReportingFrameworkEnum.ONBOARDING;
  }

  const standards: Partial<Record<ReportingFrameworkEnum, boolean>> = {
    [ReportingFrameworkEnum.DNK]: entity.reportingFrameworks.includes(
      ReportingFrameworkEnum.DNK,
    ),
    [ReportingFrameworkEnum.GRI]: entity.reportingFrameworks.includes(
      ReportingFrameworkEnum.GRI,
    ),
    [ReportingFrameworkEnum.ESRS]: entity.reportingFrameworks.includes(
      ReportingFrameworkEnum.ESRS,
    ),
  };

  if (standards.DNK) {
    return ReportingFrameworkEnum.DNK;
  }

  if (standards.ESRS && !standards.GRI) {
    return ReportingFrameworkEnum.ESRS;
  }

  return ReportingFrameworkEnum.GRI;
}

export const isTypeForm: Record<
  'text' | 'numeric' | 'choice' | 'numericSplit' | 'textSplit',
  (type: ValueDataTypeEnum) => boolean
> = {
  text: (type) =>
    [ValueDataTypeEnum.TEXT, ValueDataTypeEnum.TEXT_LONG].includes(type),
  numeric: (type) => [ValueDataTypeEnum.NUMERIC].includes(type),
  choice: (type) =>
    [ValueDataTypeEnum.CHOICE, ValueDataTypeEnum.MULTIPLE_CHOICE].includes(
      type,
    ),
  numericSplit: (type) => [ValueDataTypeEnum.NUMERIC_SPLIT].includes(type),
  textSplit: (type) => [ValueDataTypeEnum.TEXT_SPLIT].includes(type),
};

export function calculateFrameworks(
  framework: Framework,
): ReportingFrameworkEnum[] {
  if (
    framework ===
    CreateDatapointTypeReportingFrameworkEnum.CERTIFICATION_DISCLOSURE
  ) {
    return [
      ReportingFrameworkEnum.CDP,
      ReportingFrameworkEnum.ECOVADIS,
      ReportingFrameworkEnum.EMAS,
      ReportingFrameworkEnum.CUSTOM,
    ];
  }

  if (!framework || framework === ReportingFrameworkEnum.CUSTOM) {
    return [ReportingFrameworkEnum.CUSTOM];
  }

  return [framework, ReportingFrameworkEnum.CUSTOM];
}

export function getReportingFrameworks(
  entity: Entity,
  framework: Framework,
  frameworkGroup: string,
) {
  const primaryReportingFrameworkName =
    getPrimaryReportingFrameworkName(entity);
  const reportingFrameworks = calculateFrameworks(framework);

  if (reportingFrameworks.includes(primaryReportingFrameworkName)) {
    return {
      activeReportingFramework: {
        framework: primaryReportingFrameworkName,
        groups: frameworkGroup ? [frameworkGroup] : [],
      },
      reportingFrameworks: reportingFrameworks.map((item) => ({
        framework: item,
        groups:
          frameworkGroup && item !== ReportingFrameworkEnum.CUSTOM
            ? [frameworkGroup]
            : [],
      })),
    };
  }

  if (reportingFrameworks.includes(ReportingFrameworkEnum.CUSTOM)) {
    return {
      activeReportingFramework: {
        framework: ReportingFrameworkEnum.CUSTOM,
        groups: [],
      },
      reportingFrameworks: reportingFrameworks.map((item) => ({
        framework: item,
        groups:
          frameworkGroup && item !== ReportingFrameworkEnum.CUSTOM
            ? [frameworkGroup]
            : [],
      })),
    };
  }

  return null;
}
