<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ClipboardListIcon from '@heroicons/vue/outline/ClipboardListIcon';
import MlInfo from '@/components/pages/Modules/DoubleMateriality/MlInfo.vue';
import { useStore } from '../../store';
import AtEmptyCard from '../../AtEmptyCard.vue';
import OgRecommendedESRS from './OgRecommendedESRS.vue';

const { t } = useI18n();
const store = useStore();

const showTable = computed(() => {
  return Object.keys(store.value.recommendedESRS).length > 0;
});
</script>

<template>
  <MlInfo v-if="showTable" type="warning" class="mb-6">
    <p class="mb-4">
      {{
        /* eslint-disable vue/max-len */
        t(
          `These are all ESRS disclosure requirements that your company will have to report, based on applicable laws or the results of your double materiality assessment. Most of these disclosures are mandatory according to your DMA results and cannot be altered. However, those marked with a blue checkmark are only required if you've identified a relevant sustainability matter within that standard and deemed it material. For example, if you identified "emissions" as a material sustainability matter under the "E1 Climate Change" standard, you must report "E1-6 - Gross Scopes 1, 2, 3 and Total GHG Emissions.".`,
        )
      }}
    </p>
    <p>
      {{
        t(
          "Please review the blue checkmarked disclosures and uncheck any that don't correspond to a relevant material sustainability matter.",
        )
      }}
    </p>
  </MlInfo>

  <p class="mb-4 font-bold">
    {{ t('Based on your assessment, the following ESRS categories apply:') }}
  </p>

  <AtEmptyCard
    v-if="!showTable"
    class="mb-8"
    variant="center"
    :icon="ClipboardListIcon"
    :title="t('No results yet')"
  >
    <p>
      {{
        t(
          'Once you enter data in the Topics part, your assessment will show here.',
        )
      }}
    </p>
  </AtEmptyCard>

  <OgRecommendedESRS v-else />
</template>
