<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref, type HTMLAttributes, computed } from 'vue';
import { ChevronDownIcon, ChevronRightIcon, XIcon } from '@heroicons/vue/solid';
import { useI18n } from 'vue-i18n';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';

const { t } = useI18n();

type TProps = {
  wrapperClass?: HTMLAttributes['class'];
  placeholder?: string;
  modelValue: string;
  emptyItemsMessage?: string;
  label?: string;
  tooltip?: string;
  class?: HTMLAttributes['class'];
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  items: {
    id: string;
    title: string;
    children?: {
      id: string;
      title: string;
      children?: {
        id: string;
        title: string;
      }[];
    }[];
  }[];
};
const props = defineProps<TProps>();

const emit = defineEmits(['update:modelValue']);
const isFirstLevelOpen = ref(false);
const isSecondLevelOpen = ref(false);
const isThirdLevelOpen = ref(false);
const selectedItemTitle = ref('');

const closeDropdown = () => {
  secondLevelchildIdSelected.value = '';
  thirdLevelchildIdSelected.value = '';
  isFirstLevelOpen.value = false;
  isSecondLevelOpen.value = false;
  isThirdLevelOpen.value = false;
};

const firstLevel = ref<HTMLElement>();
const secondLevel = ref<HTMLElement>();
const thirdLevel = ref<HTMLElement>();
onClickOutside(firstLevel, closeDropdown);
onClickOutside(secondLevel, closeDropdown);
onClickOutside(thirdLevel, closeDropdown);

const secondLevelchildIdSelected = ref<unknown>('');
const thirdLevelchildIdSelected = ref<unknown>('');

const secondLevelItemsFiltered = computed(
  () =>
    props.items.find((item) => item.id === secondLevelchildIdSelected.value)
      ?.children,
);

const thirdLevelItemsFiltered = computed(
  () =>
    secondLevelItemsFiltered.value?.find(
      (item) => item.id === thirdLevelchildIdSelected.value,
    )?.children,
);

function selectSecondLevelChild(id: unknown) {
  isSecondLevelOpen.value = true;
  isThirdLevelOpen.value = false;
  secondLevelchildIdSelected.value = id;
}

function selectThirdLevelChild(id: unknown) {
  isThirdLevelOpen.value = true;
  thirdLevelchildIdSelected.value = id;
}

function selectItemValue(item: string, title: string) {
  emit('update:modelValue', item);
  selectedItemTitle.value = title;

  isFirstLevelOpen.value = false;
  isSecondLevelOpen.value = false;
  isThirdLevelOpen.value = false;
}

function toggleDropdown() {
  isThirdLevelOpen.value = false;
  isSecondLevelOpen.value = false;
  isFirstLevelOpen.value = !isFirstLevelOpen.value;
}

defineOptions({ inheritAttrs: false });
</script>

<template>
  <div :class="['relative w-full', props.wrapperClass]">
    <div class="w-full">
      <h6
        v-if="props.label"
        class="mb-1 flex text-sm font-medium text-gray-700"
      >
        {{ t(props.label) }}
        <AtTooltipIcon
          v-if="props.tooltip"
          class="ml-1 cursor-pointer"
          :tooltip="props.tooltip"
          :triggers="['click', 'touch']"
          autoHide
        />
      </h6>
      <button
        class="!h-[34px] flex w-full items-center justify-between rounded-md border border-gray-400 pl-3 pr-2 py-1.5 font-medium text-gray-700 hover:bg-gray-200 focus:outline-none truncate"
        :class="props.class"
        :disabled="props.disabled"
        @click.prevent="toggleDropdown"
      >
        <span
          v-if="props.modelValue && selectedItemTitle"
          class="text-sm truncate"
          >{{ t(selectedItemTitle) }}</span
        >
        <span v-else class="text-sm font-normal opacity-50 truncate">{{
          t(props.placeholder ?? '')
        }}</span>
        <ChevronDownIcon
          v-show="isFirstLevelOpen && !selectedItemTitle"
          class="ml-2 w-5 shrink-0 text-gray-400"
        />
        <ChevronRightIcon
          v-show="!isFirstLevelOpen && !selectedItemTitle"
          class="ml-2 w-5 shrink-0 text-gray-400"
        />
        <XIcon
          v-if="selectedItemTitle"
          class="ml-2 w-4 shrink-0 text-gray-400"
          @click.stop.prevent="selectItemValue('', '')"
        />
      </button>

      <!-- Dropdown menu -->
      <div
        v-show="isFirstLevelOpen"
        ref="dropdownMenu"
        class="absolute w-full bg-transparent"
        :class="{
          '!w-full': props.size === 'sm',
          '!w-[150%]': props.size === 'md',
          '!w-[300%]': props.size === 'lg',
        }"
      >
        <div class="grid grid-cols-3 mb-10">
          <!-- First level -->
          <div
            ref="firstLevel"
            class="flex flex-col z-50 max-h-60 overflow-y-auto rounded-lg border border-gray-400 bg-white shadow-lg"
          >
            <template v-for="item in props.items" :key="item.id">
              <div class="flex">
                <button
                  class="inline-flex w-full items-center justify-between px-4 py-2 text-left text-gray-900 hover:bg-blue-50 focus:outline-none"
                  :class="{
                    'bg-blue-50': secondLevelchildIdSelected === item.id,
                  }"
                  @focus="
                    item.children?.length ? selectSecondLevelChild(item.id) : {}
                  "
                  @mouseenter="
                    item.children?.length ? selectSecondLevelChild(item.id) : {}
                  "
                  @click.prevent.stop="
                    !item.children?.length
                      ? selectItemValue(item.id, item.title)
                      : {}
                  "
                >
                  <div>
                    {{ t(String(item.title)) }}
                  </div>
                  <ChevronRightIcon v-show="item.children" class="w-4" />
                </button>
              </div>
            </template>
          </div>
          <!-- Second level -->
          <div
            v-show="isSecondLevelOpen"
            ref="secondLevel"
            class="z-50 max-h-60 overflow-y-auto rounded-lg border border-gray-400 bg-white shadow-lg"
          >
            <template
              v-for="secondLevelchild in secondLevelItemsFiltered"
              :key="secondLevelchild.id"
            >
              <div class="flex">
                <button
                  class="inline-flex w-full items-center justify-between px-4 py-2 text-left text-gray-900 hover:bg-blue-50 focus:outline-none"
                  :class="{
                    'bg-blue-50':
                      thirdLevelchildIdSelected === secondLevelchild.id,
                  }"
                  @focus="
                    secondLevelchild.children?.length
                      ? selectThirdLevelChild(secondLevelchild.id)
                      : {}
                  "
                  @mouseenter="
                    secondLevelchild.children?.length
                      ? selectThirdLevelChild(secondLevelchild.id)
                      : {}
                  "
                  @click.prevent.stop="
                    !secondLevelchild.children?.length
                      ? selectItemValue(
                          secondLevelchild.id,
                          secondLevelchild.title,
                        )
                      : {}
                  "
                >
                  <div>
                    {{ t(String(secondLevelchild.title)) }}
                  </div>
                  <ChevronRightIcon
                    v-show="secondLevelchild.children"
                    class="w-4"
                  />
                </button>
              </div>
            </template>
          </div>
          <!-- Third level -->
          <div
            v-show="isThirdLevelOpen"
            ref="thirdLevel"
            class="z-50 max-h-60 overflow-y-auto rounded-lg border border-gray-400 bg-white shadow-lg"
          >
            <div v-if="thirdLevelItemsFiltered?.length">
              <template
                v-for="thirdLevelChild in thirdLevelItemsFiltered"
                :key="thirdLevelChild.id"
              >
                <button
                  class="w-full px-4 py-2 text-left text-gray-900 hover:bg-blue-50 focus:outline-none"
                  @click.stop="
                    selectItemValue(thirdLevelChild.id, thirdLevelChild.title)
                  "
                >
                  <div>
                    {{ t(thirdLevelChild.title) }}
                  </div>
                </button>
              </template>
            </div>
            <div
              v-else-if="props.emptyItemsMessage"
              class="inline-flex px-4 py-2 text-error"
            >
              {{ t(props.emptyItemsMessage) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
