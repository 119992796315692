<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import isNil from 'lodash/isNil';
import {
  TaxonomyAssessmentStatusEnum,
  type TaxonomyProject,
} from '@/__generated__/types';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import { BadgeType } from '@/components/atoms/AtBadge/types';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';

type Data = {
  badgeLabel: string;
  badgeType: BadgeType | 'OPEN';
  text: string | null;
};

const props = defineProps<{
  taxonomyProject: TaxonomyProject;
}>();

const emit = defineEmits<{
  click: [];
}>();

const { t } = useI18n();

const assessment = computed(() => props.taxonomyProject.assessments[0] ?? null);

const isCompleted = computed(() => {
  switch (assessment.value?.status) {
    case TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_DOCUMENTS:
    case TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED:
    case TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED_FINAL:
      return true;
    default:
      return false;
  }
});

const pendingAnswer = computed(() => {
  if (assessment.value) {
    return assessment.value.businessActivities.some((businessActivity) => {
      if (
        businessActivity.substantialContributionDelegation &&
        isNil(businessActivity.objective)
      ) {
        return true;
      }

      if (
        businessActivity.minimumStandardsDelegation &&
        isNil(businessActivity.minimumStandardsRespected)
      ) {
        return true;
      }

      return (
        [
          'adaptation',
          'mitigation',
          'water',
          'circular',
          'pollution',
          'biodiversity',
        ] as const
      ).some((key) => {
        return (
          businessActivity.doesNotHarm[`${key}Delegation`] &&
          isNil(businessActivity.doesNotHarm[key])
        );
      });
    });
  }

  return false;
});

const data = computed<Data>(() => {
  if (isCompleted.value) {
    return {
      badgeLabel: t('Completed'),
      badgeType: BadgeType.SUCCESS,
      text: getDPRDateString(assessment.value.updatedAt),
    };
  }

  if (pendingAnswer.value) {
    return {
      badgeLabel: t('Pending answer'),
      badgeType: BadgeType.DOWN,
      text: null,
    };
  }

  if (assessment.value) {
    return {
      badgeLabel: t('In progress'),
      badgeType: BadgeType.WARNING,
      text: null,
    };
  }

  return {
    badgeLabel: t('Open'),
    badgeType: 'OPEN',
    text: null,
  };
});

function handleCellClick() {
  emit('click');
}
</script>

<template>
  <div class="cursor-pointer" @click.stop="handleCellClick">
    <div v-if="data.text">
      <AtBadge class="mr-1 py-0 text-xs font-light" :type="data.badgeType">
        {{ data.badgeLabel }}
      </AtBadge>
      <div>
        {{ data.text }}
      </div>
    </div>
    <AtBadge v-else class="mr-1 py-0 text-xs font-light" :type="data.badgeType">
      {{ data.badgeLabel }}
    </AtBadge>
  </div>
</template>
