<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const { t } = useI18n();

function handleSupportToggle() {
  //
}
</script>

<template>
  <AtButton
    type="button"
    variant="text"
    class="hover:!bg-blue-50"
    @click="handleSupportToggle"
  >
    {{ t('Support') }}
  </AtButton>
</template>
