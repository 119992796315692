import { gql } from '@apollo/client/core';

export default gql`
  query PgTaxonomyProjects {
    getTaxonomyProjects {
      _id
      name

      assignees {
        _id
        firstName
        lastName
      }

      assessments {
        _id

        status

        updatedAt

        documents {
          _id
          file {
            _id
            filename
            downloadUrl
            filesize
          }
        }

        turnover
        turnoverCompleteLater
        alignedTurnover
        eligibleTurnover
        nonEligibleTurnover
        capexCompleteLater
        opexCompleteLater

        businessActivities {
          _id
          activity {
            _id
            name
          }
          taxonomyFit
          activityTurnover
          eligibleTurnover
          alignedTurnover

          objective
          substantialContributionDelegation {
            _id
          }
          minimumStandardsRespected
          minimumStandardsDelegation {
            _id
          }
          doesNotHarm {
            _id
            adaptation
            adaptationDelegation {
              _id
            }
            mitigation
            mitigationDelegation {
              _id
            }
            water
            waterDelegation {
              _id
            }
            circular
            circularDelegation {
              _id
            }
            pollution
            pollutionDelegation {
              _id
            }
            biodiversity
            biodiversityDelegation {
              _id
            }
          }
        }
      }
    }
  }
`;
