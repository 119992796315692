import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  SuspendDataPointTypeByEntityMutation,
  SuspendDataPointTypeByEntityMutationVariables,
} from '@/__generated__/types';

export const SUSPEND_DATAPOINT_TYPE_BY_ENTITY = gql`
  mutation SuspendDataPointTypeByEntity($dataPointTypeId: String!) {
    suspendDataPointTypeByEntity(dataPointTypeId: $dataPointTypeId) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  SuspendDataPointTypeByEntityMutation,
  SuspendDataPointTypeByEntityMutationVariables
>;

const useSuspendDataPointTypeByEntityMutation = (options?: TOptions) =>
  useMutation<
    SuspendDataPointTypeByEntityMutation,
    SuspendDataPointTypeByEntityMutationVariables
  >(SUSPEND_DATAPOINT_TYPE_BY_ENTITY, options);

export default useSuspendDataPointTypeByEntityMutation;
