import { gql } from '@apollo/client/core';

export default gql`
  query TmReports($date: DateTime!) {
    getOrganizationQuestionnaires {
      _id
      entity {
        _id
        name
      }
      name
      category {
        _id
      }
    }

    getOldestESGDataPoint(date: $date) {
      _id
      createdAt
    }

    getReportingProgress(adminMode: true) {
      pending
      open
      accepted
      rejected
      overDue
      total
      entity {
        _id
        name
      }

      location {
        _id
        name
      }
    }

    getOwnUser {
      _id
      entity {
        _id
        reportingFrameworks
        entitySettings {
          _id
          companyName
        }
      }
    }
  }
`;
