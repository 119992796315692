<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import type { OgConnectGoalStepQuery } from '@/__generated__/types';
import AtCreateKPIModalTitle from '../../AtCreateKPIModalTitle.vue';
import type { TGoalCommon, TTargetCommon } from '../../../types';
import OgGoalSelect from './OgGoalSelect.vue';
import OgTargetSelect from './OgTargetSelect.vue';
import OG_CONNECT_GOAL_STEP from './OgConnectGoalStep.query';

const { t } = useI18n();

const props = defineProps<{
  goal?: TGoalCommon;
  goals: TGoalCommon[];
  target?: TTargetCommon;
  dataPointTypeId?: string;
}>();

const emit = defineEmits<{
  (e: 'update:goal', goal?: TGoalCommon): void;
  (e: 'update:target', target?: TTargetCommon): void;
}>();

const { result } = useQuery<OgConnectGoalStepQuery>(OG_CONNECT_GOAL_STEP);

const goalsTargets = computed(() =>
  [...(result.value?.getTargets ?? [])]
    .filter(({ goal }) => goal._id === props.goal?._id)
    .sort((a, b) => Number(b.isRecommended) - Number(a.isRecommended)),
);

const selectedDataPointType = computed(() =>
  result.value?.getEsgDataPointTypes.find(
    ({ _id }) => _id === props.dataPointTypeId,
  ),
);
</script>

<template>
  <div>
    <AtCreateKPIModalTitle
      class="mt-12"
      :title="t('Connect to goal')"
      :subtitle="
        t(
          'Connect your chosen datapoint to more general targets and Sustainable Development Goals.',
        )
      "
    />

    <p class="mt-4 text-sm font-semibold">
      {{ t(selectedDataPointType?.friendlyName ?? '') }}
    </p>
    <p class="mt-4 text-sm text-info">
      {{ t(selectedDataPointType?.question ?? '') }}
    </p>

    <p class="mt-8 text-sm font-semibold">
      {{ t('Select the framework and goal') }}
    </p>
    <OgGoalSelect
      :goals="props.goals"
      :modelValue="props.goal"
      @update:modelValue="(emit('update:goal', $event), emit('update:target'))"
    />
    <OgTargetSelect
      :modelValue="props.target"
      :targets="goalsTargets"
      :isGoalSelected="!!props.goal"
      @update:modelValue="emit('update:target', $event)"
    />
  </div>
</template>
