<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import AtCounter from '@/components/atoms/AtCounter.vue';
import type { TabsVariant } from './types';

export type Tab = { name: string; value: string };

const { t } = useI18n();
interface TProps {
  modelValue?: Tab | string;
  tabs: Tab[] | string[];
  variant?: `${TabsVariant}`;
  isEdgeToEdge?: boolean;
  counterValues?: Record<string, number>;
  hasWideBorderBottom?: boolean;
}

const props = withDefaults(defineProps<TProps>(), {
  modelValue: undefined,
  variant: 'bordered',
  isEdgeToEdge: false,
  counterValues: undefined,
  hasWideBorderBottom: true,
});
const emit = defineEmits(['update:modelValue']);

const tabsClass = computed(() => ({
  'tabs-boxed inline-block w-full justify-between flex':
    props.variant === 'boxed',
  'border-b': props.variant === 'bordered' && props.hasWideBorderBottom,
}));

const activeTab = computed(() =>
  typeof props.modelValue === 'string'
    ? props.modelValue
    : props.modelValue?.value,
);
const tabValues = computed(() =>
  props.tabs.map((tab) => (typeof tab === 'string' ? tab : tab.value)),
);
const getTabName = (i: number) => {
  const tab = props.tabs[i];
  return typeof tab === 'string' ? tab : tab.name;
};
</script>
<template>
  <div class="tabs" :class="tabsClass">
    <button
      v-for="(tabValue, i) in tabValues"
      :key="tabValue"
      class="tab"
      :class="{
        'tab-active': tabValue === activeTab,
        'first:pl-0 last:pr-0': props.isEdgeToEdge,
      }"
      type="submit"
      @click.stop="emit('update:modelValue', props.tabs[i])"
    >
      <span
        :class="{
          'translate-y-0.5 border-b-2': props.variant === 'bordered',
          'text-xs': props.variant === 'boxed',
          'border-transparent':
            tabValue !== activeTab && props.variant === 'bordered',
          'border-primary text-primary':
            tabValue === activeTab && props.variant === 'bordered',
        }"
      >
        <slot :tab="tabs[i]">
          {{ t(getTabName(i)) }}
        </slot>
      </span>
      <AtCounter
        v-if="props.counterValues"
        :count="props.counterValues[tabValue] ?? 0"
        class="ml-1.5"
        :accent="tabValue === activeTab"
      />
    </button>
  </div>
</template>
