import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import mapValues from 'lodash/mapValues';
import { ValueDataTypeEnum } from '@/__generated__/types';
import dayjs from '@/lib/dayjs/config';
import { getDisplayedFrameworks } from '@/utils/helpers/getDisplayedFrameworks';
import type {
  DataPoint,
  DataPointSummaryAdmin,
  DataPointType,
  PreparedPerDataPointTypeItem,
  PreparedPerDate,
  PreparedPerDateSublevelItem,
  PreparedStandardDataPoint,
  Project,
  SublevelItem,
} from '../types';
import { sortDataPointTypes } from './prepareData';
import { getProjectsTrees } from './projectsTree';
import { getDataPointsProjectsTrees } from './dataPointsProjectsTrees';
import { calculate, summarize, summarizeCategories } from './summarize';

function prepareCategories(dataPoints: DataPoint[]) {
  const result: PreparedPerDateSublevelItem['categories'] = [];

  dataPoints.forEach((dataPoint) => {
    if (
      dataPoint.valueSource &&
      dataPoint.valueSource.length > 0 &&
      (!dataPoint.dataPointRequest?.delegations ||
        dataPoint.dataPointRequest?.delegations.length < 2)
    ) {
      dataPoint.valueSource.forEach((valueSource) => {
        const categoryName = valueSource.name;
        let existingCategory = result.find(
          (item) => item.name === categoryName,
        );
        if (!existingCategory) {
          existingCategory = {
            isUncategorized: false,
            name: categoryName,
            values: {},
          };
          result.push(existingCategory);
        }
        existingCategory.values[`${dataPoint.from}-${dataPoint.to}`] = {
          ...valueSource,
          valueUnit: dataPoint.valueUnit,
        };
      });
    } else {
      let existingCategory = result.find((item) => item.isUncategorized);
      if (!existingCategory) {
        existingCategory = {
          isUncategorized: true,
          name: '',
          values: {},
        };
        result.push(existingCategory);
      }
      existingCategory.values[`${dataPoint.from}-${dataPoint.to}`] = {
        name: '',
        value: dataPoint.value,
        valueUnit: dataPoint.valueUnit,
        originalValue: dataPoint.value,
        originalValueUnit: dataPoint.valueUnit,
      };
    }
  });

  return result;
}

function prepareProjectItem(
  project: SublevelItem,
  dates: PreparedPerDate['dates'],
  dataPointType: DataPointType,
  showCategories: boolean,
): PreparedPerDateSublevelItem {
  const categories = showCategories
    ? prepareCategories(project.dataPoints)
    : [];

  const canSummarize =
    !!dataPointType.summarizingMethod &&
    dataPointType.valueDataType === ValueDataTypeEnum.NUMERIC;

  let dataPoints: PreparedPerDateSublevelItem['dataPoints'];
  if (categories.length > 0 || project.dataPoints.length === 0) {
    dataPoints = null;
  } else {
    dataPoints = mapValues(
      groupBy(
        project.dataPoints,
        (dataPoint) => `${dataPoint.from}-${dataPoint.to}`,
      ),
      (dateDataPoints) => {
        // In case data point values are numeric and there is a summarizingM method, calculate the value from all data points.
        if (canSummarize) {
          const sum = dateDataPoints.reduce(
            (acc, dataPoint) => acc + dataPoint.value,
            0,
          );
          const count = dateDataPoints.length;

          return {
            dataPointType,
            value: calculate(sum, count, dataPointType.summarizingMethod!),
            calculation: {
              sum,
              count,
            },
          } as PreparedStandardDataPoint;
        }

        return {
          dataPointType,
          value: dateDataPoints[0].value,
          calculation: null,
        } as PreparedStandardDataPoint;
      },
    );
  }

  let sublevels: PreparedPerDateSublevelItem['sublevels'];
  if (project.sublevels && project.sublevels.length > 0) {
    sublevels = project.sublevels.map((sublevel) => {
      return prepareProjectItem(sublevel, dates, dataPointType, showCategories);
    });
  } else {
    sublevels = null;
  }

  let total: PreparedPerDateSublevelItem['total'];
  let categoriesTotal: PreparedPerDateSublevelItem['categoriesTotal'];
  if (sublevels && canSummarize) {
    total = dates.reduce<Record<string, PreparedStandardDataPoint>>(
      (values, date) => {
        const [sum, count] = summarize(
          sublevels as PreparedPerDateSublevelItem[],
          date.key,
        );

        return {
          ...values,
          [date.key]: {
            dataPointType,
            value: calculate(sum, count, dataPointType.summarizingMethod!),
            calculation: {
              sum,
              count,
            },
          },
        };
      },
      {},
    );
    categoriesTotal = showCategories
      ? orderBy(
          Object.entries(summarizeCategories(sublevels, dates)).map(
            ([name, item]) => ({
              name,
              isUncategorized: item.isUncategorized,
              isFullTotal: item.isFullTotal,
              values: mapValues(item.values, ({ sum, count }) => ({
                unit: item.unit,
                value: calculate(sum, count, dataPointType.summarizingMethod!),
              })),
            }),
          ),
          [
            (item) => item.isFullTotal,
            (item) => item.isUncategorized,
            (item) => item.name,
          ],
          ['asc', 'asc', 'asc'],
        )
      : null;
    if (categoriesTotal?.length === 0) {
      categoriesTotal = null;
    }
  } else {
    total = null;
    categoriesTotal = null;
  }

  return {
    id: project.id,
    name: project.name,
    dataPoints,
    total,
    categories,
    sublevels,
    categoriesTotal,
  };
}

export function getPerDataPointType(
  allDataPoints: DataPoint[],
  projects: Project[],
  summaryDataPoints: DataPointSummaryAdmin[],
) {
  const projectsTrees = getProjectsTrees(projects);

  const dataPointsPerDataPointTypes = groupBy(
    allDataPoints,
    (dataPoint) => dataPoint.dataPointType._id,
  );
  const preparedPerDataPointTypes = mapValues(
    dataPointsPerDataPointTypes,
    (dataPoints) => {
      const { dataPointType } = dataPoints[0];

      const dataPointsPerProject = getDataPointsProjectsTrees(
        projectsTrees,
        dataPoints,
        true,
      );

      const frameworks = getDisplayedFrameworks(
        dataPointType.activeReportingFramework,
        dataPointType.reportingFrameworks,
      );

      const filteredSummaryDataPoints = summaryDataPoints.filter(
        (dp) => dp.dataPointType._id === dataPointType._id,
      );

      return {
        type: dataPointType,
        frameworks,
        allDataPoints: dataPoints,
        summaryDataPoints: filteredSummaryDataPoints,
        dataPointsPerProject,
      } as PreparedPerDataPointTypeItem;
    },
  );

  return sortDataPointTypes(Object.values(preparedPerDataPointTypes));
}

export function getPerDate(
  projects: SublevelItem[],
  dataPointType: DataPointType,
  showCategories: boolean,
) {
  const result: PreparedPerDate = {
    dates: [],
    projects: [],
  };

  const alreadyAddedDates: Record<string, number> = {};
  const getDates = (
    dataPoints: DataPoint[],
    sublevels: SublevelItem[] | null,
  ) => {
    dataPoints.forEach((dataPoint) => {
      const key = `${dataPoint.from}-${dataPoint.to}`;
      if (!Object.prototype.hasOwnProperty.call(alreadyAddedDates, key)) {
        result.dates.push({
          key,
          year: dayjs(dataPoint.from).format('YYYY'),
          from: dataPoint.from || null,
          to: dataPoint.to || null,
        });
        alreadyAddedDates[key] = result.dates.length - 1;
      }
    });
    if (sublevels) {
      sublevels.forEach((sublevel) => {
        getDates(sublevel.dataPoints, sublevel.sublevels);
      });
    }
  };
  projects.forEach((project) => {
    getDates(project.dataPoints, project.sublevels);
  });

  result.projects = projects.map((project) =>
    prepareProjectItem(project, result.dates, dataPointType, showCategories),
  );

  return result;
}
