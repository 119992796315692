import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  AddRepositoryFileMetadataMutation,
  AddRepositoryFileMetadataMutationVariables,
} from '@/__generated__/types';

export const ADD_REPOSITORY_FILE_METADATA = gql`
  mutation AddRepositoryFileMetadata(
    $repositoryFile: UpdateRepositoryFileInput!
  ) {
    addFileMetadata(repositoryFile: $repositoryFile) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  AddRepositoryFileMetadataMutation,
  AddRepositoryFileMetadataMutationVariables
>;

const useAddRepositoryFileMetadata = (options?: TOptions) =>
  useMutation<
    AddRepositoryFileMetadataMutation,
    AddRepositoryFileMetadataMutationVariables
  >(ADD_REPOSITORY_FILE_METADATA, options);

export default useAddRepositoryFileMetadata;
